import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_USER_LOGIN = "FETCH_USER_LOGIN";
export const FETCH_USER_LOGIN_SUCCESS = "FETCH_USER_LOGIN_SUCCESS";
export const FETCH_USER_LOGIN_FAILURE = "FETCH_USER_LOGIN_FAILURE";
export const CLEAR_USER_LOGIN = "CLEAR_USER_LOGIN";

export const fetchUserLogin = (id) => ({
  type: FETCH_USER_LOGIN,
  id,
});

export const fetchUserLoginSuccess = (data) => ({
  type: FETCH_USER_LOGIN_SUCCESS,
  data,
});

export const fetchUserLoginFailure = () => ({
  type: FETCH_USER_LOGIN_FAILURE,
});

export const clearUserLogin = () => ({
  type: CLEAR_USER_LOGIN,
});

export const getUserLogin = (id,handleCallback) => {
  return (dispatch) => {
    dispatch(fetchUserLogin());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(`/user/admin/`, { id: id }, token)
          .then((res) => {
            handleCallback && handleCallback(true,res.data);
            dispatch(fetchUserLoginSuccess(res.data));
            return res;
          })
          .catch((err) => { 
            handleCallback && handleCallback(false,null);
            dispatch(fetchUserLoginFailure(err)) 
          });
      } catch (err) {
        console.log(err);
      }
    });
  };
};
