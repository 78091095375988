import { FETCH_ATTENDANCE_DETAIL, FETCH_ATTENDANCE_DETAIL_SUCCESS, FETCH_ATTENDANCE_DETAIL_FAILURE, CLEAR_ATTENDANCE_DETAIL } from '../actions';

const initialState = {
  attendance_detail: {},
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  //console.log(action)
  switch(action.type) {
    case FETCH_ATTENDANCE_DETAIL:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_ATTENDANCE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        attendance_detail: action.data
      };
    case FETCH_ATTENDANCE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        attendance_detail: {}
      };
    case CLEAR_ATTENDANCE_DETAIL:
      return initialState;
    default:
      return state;
  }
}