import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_FORM_DETAILS = "FETCH_FORM_DETAILS";
export const FETCH_FORM_DETAILS_SUCCESS = "FETCH_FORM_DETAILS_SUCCESS";
export const FETCH_FORM_DETAILS_FAILURE = "FETCH_FORM_DETAILS_FAILURE";

export const fetchFormdetail = (id) => ({
  type: FETCH_FORM_DETAILS,
  id,
});

export const fetchFormdetailSuccess = (data) => ({
  type: FETCH_FORM_DETAILS_SUCCESS,
  data,
});

export const fetchFormdetailFailure = (err) => ({
  type: FETCH_FORM_DETAILS_FAILURE,
  err,
});

export const getFormDetail = (id, size) => {
  return (dispatch) => {
    dispatch(fetchFormdetail());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/form/details/", { id: id, size: size }, token)
          .then((res) => {
            dispatch(fetchFormdetailSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchFormdetailFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
