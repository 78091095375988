import { FETCH_ROOM, FETCH_ROOM_SUCCESS, FETCH_ROOM_FAILURE } from "../actions";

const initialState = {
  room: {},
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROOM:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        room: action.data,
      };
    case FETCH_ROOM_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        room: {},
      };
    default:
      return state;
  }
};
