import React from "react";
import { Form, Message } from "semantic-ui-react";

const RenderCheckbox = (props) => {
  //console.log(props)
  return (
    <div>
      <Form.Checkbox
        onChange={(event, data) => props.onChange("" + data.checked)}
        label={props.label}
        defaultChecked={props.value}
        //checked={props.values.length !== 0 && props.values.includes("KNSLmqsAF7gWWXdudWGM")}
      />
      {props.isError && props.isTouched && (
        <Message visible error content={props.isError} />
      )}
    </div>
  );
};

export default RenderCheckbox;
