import React from "react";
import { Image, List } from "semantic-ui-react";
import Pf_Default from "../assets/images/ic_account.png";

const PeopleList = (props) => {
  return (
    <List.Item style={{ border: "1px solid #DADADA" }}>
      <Image
        avatar
        src={props.img === undefined ? Pf_Default : props.img}
        style={{
          width: 48,
          height: 48,
          marginTop: "11px",
          marginLeft: "20px",
          border: "2px solid #4075FF",
        }}
        as={"img"}
        onError={(e) => (e.target.src = Pf_Default)}
      />
      <Image
        avatar
        src={
          props.children_photos === undefined
            ? Pf_Default
            : props.children_photos[0] === "http://"
            ? Pf_Default
            : props.children_photos[0]
        }
        className="child"
        style={{ border: "2px solid #FFF", overFlow: "none" }}
        as={"img"}
        onError={(e) => (e.target.src = Pf_Default)}
      />
      <List.Content style={{}}>
        <List.Header
          style={{
            fontSize: "16px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "22px",
            color: "#000",
            paddingTop: "22px",
            paddingLeft: "17px",
          }}
        >
          {props.name}
          <span as="h3" style={{ color: "gray" }}>
            ・{props.children_names[0]}'s {props.relationship}
          </span>
        </List.Header>
      </List.Content>
    </List.Item>
  );
};
export default PeopleList;
