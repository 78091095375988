import React, { Component } from "react";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Image,
  Message,
  Modal,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Formik, Field, Form } from "formik";
import { SIGNUP_ROUTE, PASSWORD_RESET_ROUTE, LOGIN_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import logo from "../../assets/images/logo1px.png";
import RenderInput from "../../components/TextInput";
import RenderPasswordInput from "../../components/PasswordInput";
import { isRequired, isEmail } from "../../validators";
import { changePasswordActions,setActiveUserActions } from "../../store/actions";

class ChangePasswordScreen extends Component {
    state = {
        isLoading: false,
        message: "",
        current_password:"",
        email:"",
    };

    componentDidMount(){
        this.setState({
            current_password:this.props.location.state.current_password,
            email: this.props.location.state.email,
        })
    }

    handleSubmit = (values) =>{
        console.log("HHHHH")
        const { confirm_password, new_password, old_password } = values;
        if(old_password === this.state.current_password && confirm_password === new_password){
            this.setState({
                isLoading:true,
            })
            this.props.changePassword(this.state.email,values.new_password,(status)=>{
                
                if(status === true){
                    localStorage.setItem("isChangePassword",true);
                    this.props.dispatch(
                        push(LOGIN_ROUTE)
                    )
                }else{
                    this.setState({
                        message:"You can't change password. Try again, please!"
                    });
                }
                this.setState({
                    isLoading:false,
                })
            })
        }else{
            if(old_password === this.state.current_password){
                this.setState({
                    message:"Current password is incorrect!"
                })
            }else{
                if(confirm_password === new_password){
                    this.setState({
                        message:"New password and confirm password don't match"
                    })
                }
            }

        }
    }

    render() {
        return (
            <Grid textAlign="center" className="authFom" verticalAlign="middle">
                <Grid.Column
                    style={{
                        maxWidth: 400,
                        maxHeight: 530,
                        overflow: "auto",
                        background: "#f9fbff",
                        borderRadius: 20,
                        padding: "20px 40px",
                    }}
                >
                <Header as="h2" color="teal" textAlign="center">
                    <Image src={logo} />
                </Header>

                <br />

                <Grid.Row>
                    <Grid.Column width={16}>
                    <Header as="h3" floated="left">
                        Change Password
                    </Header>
                    </Grid.Column>
                </Grid.Row>
                <br />
                <Formik
                    initialValues={{ confirm_password: "", new_password: "" ,old_password:""}}
                    onSubmit={this.handleSubmit}
                >
                    {(formikProps) => {
                    return (
                        <Form>
                        <Header as="h4" textAlign="left">
                            Current password
                        </Header>
                        <Field
                            name="old_password"
                            type="password"
                            component={RenderPasswordInput}
                            onChange={formikProps.handleChange("old_password")}
                            validate={isRequired}
                            isTouched={formikProps.touched.old_password}
                            isError={formikProps.errors.old_password}
                        />

                        <Header as="h4" textAlign="left">
                            New password
                        </Header>
                        <Field
                            name="new_password"
                            type="password"
                            component={RenderPasswordInput}
                            onChange={formikProps.handleChange("new_password")}
                            validate={isRequired}
                            isTouched={formikProps.touched.new_password}
                            isError={formikProps.errors.new_password}
                        />

                        <Header as="h4" textAlign="left">
                            Confirm password
                        </Header>
                        <Field
                            name="confirm_password"
                            type="password"
                            component={RenderPasswordInput}
                            onChange={formikProps.handleChange("confirm_password")}
                            validate={isRequired}
                            isTouched={formikProps.touched.confirm_password}
                            isError={formikProps.errors.confirm_password}
                        />

                        <div>
                            {this.state.message ? (
                            <Message style={{ color: "red" }}>
                                {this.state.message}
                            </Message>
                            ) : null}
                        </div>
                        <Button
                            content="Update password"
                            primary
                            fluid
                            size="large"
                            type="submit"
                            loading={this.state.isLoading}
                        />
                        </Form>
                    );
                    }}
                </Formik>
                <Grid divided="vertically">
                    <Grid.Row columns={2}>
                    <Grid.Column
                        textAlign="left"
                        width={6}
                        style={{ padding: 0, display: "none" }}
                    >
                        <RoutingButton
                        to={SIGNUP_ROUTE}
                        className="simpleRouter"
                        //btnText="Sign up"
                        />
                    </Grid.Column>

                    <Grid.Column textAlign="center" width={16} style={{ padding: 0 }}>
                        {/* <RoutingButton
                        to={PASSWORD_RESET_ROUTE}
                        className="simpleRouter"
                        btnText="Forgot password"
                        /> */}
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    changePassword:(email,password,handleCallback)=>{
        dispatch(
            changePasswordActions(email,password,handleCallback)
        )
    },
    setActiveUserActions:()=>{
        dispatch(
            setActiveUserActions()
        )
    },
    dispatch: dispatch,
  });

export default connect(null,mapDispatchToProps)(ChangePasswordScreen);
