import { FETCH_QUICKACCESS, FETCH_QUICKACCESS_SUCCESS, FETCH_QUICKACCESS_FAILURE } from '../actions';

const initialState = {
  quickaccess: [],
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_QUICKACCESS:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_QUICKACCESS_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        quickaccess: action.data
      };
    case FETCH_QUICKACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        participants: []
      };
    default:
      return state;
  }
}