import  React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Button, Icon } from 'semantic-ui-react';

export const RoutingButton = (props) => {
  const { disabled, to, dispatch} = props
  const click = () => {
    if(props.clearUser !== undefined){
      dispatch(props.clearUser())
    }
    dispatch(push(to))
  }
  return (
    <div>
      <Button
        onClick={disabled ? undefined : click}
        className={props.className}
        active={props.active}
        size='big'
        basic
        >
        {props.showIcon ? <Icon name='chevron left' className="backChevron"/> : null}
        {props.btnText}
      </Button>
    </div>
  );
};

RoutingButton.displayName = 'RoutingButton';

export default connect()(RoutingButton);
