import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Icon,
  Message,
  Dimmer,
  Loader,
  Pagination,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { getMoreUsers } from "../../store/actions";

const RenderListBox = (props) => {
  const viewModal = () => {
    let modalUser = {};
    modalUser.img = props.img;
    modalUser.name = props.name;
    // modalUser.centreType =
    //   props.centre_list.length === 1 ? props.centername[0] : "All Centre";
    // modalUser.centreType =
    //   props.centre && props.centre.length == 6
    //     ? "All centre"
    //     : props.centre.length > 1 && props.centre.length != 6
    //     ? centreNameMoreThanOne.length == 1 && centreNameMoreThanOne[0].name
    //     : props.centre[0].name;
    modalUser.centreType =
      props.centre && props.centre.length > 1
        ? "All centre"
        : props.centre[0].name;
    modalUser.email = props.email;
    props.handleModalShow(modalUser);
  };

  // let centreNameCon;
  // if (localStorage.getItem("adminType") == "hq") {
  //   centreNameCon = props.centre_list.filter(
  //     (e) => e.value == props.centresgg.centre_id
  //   );
  // }
  // let centreNameMoreThanOne;
  // if (props.centre.length > 1 && props.centre.length != 6) {
  //   centreNameMoreThanOne = props.centre.filter(
  //     (c) => c.id == props.centresgg.centre_id
  //   );
  // }

  return (
    <Segment className="listBox">
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}>
            <p
              style={{
                marginTop: 8,
                fontWeight: "normal",
                color: "#065CA4",
                width: 100,
              }}
            >
              {" "}
              <div>
                {props.employee_no ? (
                  props.employee_no
                ) : (
                  <div
                    style={{
                      justifyContent: "center",
                      fontWeight: "bold",
                      textAlign: "center",
                      display: "flex",
                    }}
                  >
                    -
                  </div>
                )}
              </div>
              {/*  {props.employee_no}
               {props.is_activeModify} */}
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}>
              {/* {props.selectedCenter !== "all" ? (
                props.selectedCenter
              ) : props.centername ? (
                props.centername
              ) : (
                <div
                  style={{
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "flex",
                    marginLeft: 50,
                  }}
                >
                  -
                </div>
              )} */}
              {localStorage.getItem("adminType") == "hq" && (
                <>
                  {props.centre && props.centre.length > 1
                    ? "All centre"
                    : props.centre && props.centre[0].name}

                  {/* {centreNameCon.length > 0 ? (
                    centreNameCon[0].text
                  ) : (
                    <div
                      style={{
                        justifyContent: "flex-start",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "flex",
                        marginLeft: 50,
                      }}
                    >
                      -
                    </div>
                  )} */}
                </>
              )}
              {localStorage.getItem("adminType") == "centre" && (
                <>
                  {" "}
                  {props.centre && props.centre.length > 1
                    ? "All centre"
                    : props.centre[0].name}
                </>
              )}
              {/* {centreNameCon ? (
                centreNameCon[0].text
              ) : (
                <div
                  style={{
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "flex",
                    marginLeft: 50,
                  }}
                >
                  -
                </div>
              )} */}
            </p>
          </Grid.Column>
          <Grid.Column width={3}>
            <p
              style={{
                marginTop: 8,
                fontWeight: "normal",
                color: "#1B1A1A",
              }}
            >
              {props.name}
            </p>
          </Grid.Column>
          <Grid.Column width={3}>
            <p
              className="fullWidth text-turncation nofontWeight text-primary1"
              style={{ marginTop: 8 }}
            >
              {props.email ? (
                <>{props.email}</>
              ) : (
                <div
                  style={{
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "flex",
                    marginLeft: 50,
                  }}
                >
                  -
                </div>
              )}
            </p>
          </Grid.Column>
          <Grid.Column width={3}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}>
              {props.phone ? (
                <>{props.phone}</>
              ) : (
                <div
                  style={{
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "flex",
                    marginLeft: 50,
                  }}
                >
                  -
                </div>
              )}
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <p
              className={
                props.active === 1 ? "status-acitve" : "status-inacitve"
              }
            >
              {props.active === 1 ? "Enable" : "Disable"}
            </p>
          </Grid.Column>

          <Grid.Column width={1} className="centerText">
            <div className="ui simple dropdown item">
              <Icon
                style={{ marginTop: 8, color: "gray" }}
                name="ellipsis vertical"
              />
              <div className="menu">
                <div onClick={() => viewModal()} className="item">
                  View
                </div>
                {/* <div
                  onClick={() => props.handleModalShow(props.id)}
                  className="item"
                >
                  View
                </div> */}
                <div
                  onClick={() =>
                    props.onSelect(props.id, localStorage.getItem("user_type"))
                  }
                  className="item"
                >
                  Edit
                </div>
                <div
                  className="item"
                  onClick={() => props.handleDisable(props.id, props.active)}
                >
                  {props.active === 1 ? "Disable" : "Enable"}
                </div>
                {/* <div className="item">Notify</div> */}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
class AdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  componentDidMount() {
    this.setState({
      activePage: 1,
    });
  }

  changeActive = () => {
    this.setState({
      activePage: 1,
    });
  };

  componentWillUpdate(nextProp, nextState) {
    if (nextState.activePage != localStorage.getItem("activePage")) {
      this.setState({
        activePage: 1,
      });
    }
  }
  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage - 1;
    // console.log("this2", this.props.values);
    localStorage.setItem("activePage", activePage);
    localStorage.setItem("nextPage", true);
    this.setState({ activePage });
    if (localStorage.getItem("adminType") == "centre") {
      this.props.getMoreUsers(
        page,
        this.props.values.user_type,
        localStorage.getItem("centre_Id"),
        "",
        ""
      );
    } else {
      this.props.getMoreUsers(
        page,
        this.props.values.user_type,
        this.props.values.centre_id,
        "",
        ""
        // localStorage.getItem("class_id_user")
      );
    }
  };
  render() {
    const { selectedCenter, isloading, firstloading, haveData } = this.props;
    //console.log(this.props.filtersUsers[0].centre_id[0])
    return (
      <Grid>
        <Grid.Row>
          {!firstloading && (
            <Dimmer active={isloading} inverted>
              <Loader inverted />
            </Dimmer>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Grid className="activityTitle">
              <Grid.Row>
                <Grid.Column width={2}>
                  <Header as="h4" color="grey">
                    Employee ID
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h4" color="grey">
                    Centre
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Name
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Business Email
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Business Contact no
                  </Header>
                </Grid.Column>
                <Grid.Column width={1}>
                  <Header as="h4" color="grey">
                    Status
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {this.props.haveError ? (
              <Message
                header="Error loading users"
                content="Error loading users. Please try again."
                negative
              />
            ) : this.props.filtersUsers.length === 0 && !isloading ? (
              <Message
                header="No user found"
                content=" There is no user in this section."
                warning
              />
            ) : (
              haveData && (
                <>
                  {this.props.filtersUsers.map((usr) => (
                    <RenderListBox
                      {...usr}
                      key={usr.id}
                      onSelect={this.props.handleOnSelect}
                      centername={
                        this.props.centre_list !== undefined &&
                        this.props.centre_list.map((data) => {
                          // if (data.key === usr.centre_id[0]) {
                          //   return data.text;
                          // }
                          return data.text;
                        })
                      }
                      handleDisable={this.props.handleDisable}
                      active={usr.is_active}
                      centre_list={this.props.centre_list}
                      handleModalShow={this.props.handleModalShow}
                      selectedCenter={selectedCenter}
                      centresgg={this.props.values}
                    />
                  ))}
                  <Pagination
                    activePage={this.state.activePage}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={3}
                    totalPages={this.props.usersTotalPage}
                    floated="right"
                    onPageChange={this.handlePaginationChange}
                  />
                </>
              )
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  usersNextpage: state.users.usersNextpage,
  usersTotalPage: state.users.usersTotalPage,
});

const mapDispatchToProps = (dispatch) => ({
  getMoreUsers: (nextPage, user_type, centre_id, class_id) =>
    dispatch(getMoreUsers(nextPage, user_type, centre_id, class_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
