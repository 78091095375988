import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const PUSH_NOTI = "PUSH_NOTI";
export const PUSH_NOTI_SUCCESS = "PUSH_NOTI_SUCCESS";
export const PUSH_NOTI_FAIL = "PUSH_NOTI_FAIL";

export const logIn = (authInfo) => ({
  type: LOGIN,
  authInfo,
});

export const logOut = () => ({
  type: LOGOUT,
});

export const handleLogin = (authInfo) => {
  return (dispatch, getState) => {
    dispatch(logIn(authInfo));
  };
};

export const handleLogout = () => {
  return (dispatch) => {
    dispatch(logOut());
  };
};

export const pushToken = (token) => {
  return (dispatch) => {
    const formData = new FormData();
    return firebase
      .reloadAuth()
      .then((res) => {
        formData.append("user_id", res.uid);
        formData.append("token", token);
        const tokenData = res.ya === null ? res.za : res.ya;

        api
          .post("/user/push_token/", formData, tokenData)
          .then((res) => res)
          .catch((err) => console.log("err", err));
      })
      .catch((err) => console.log("err", err));
  };
};
