import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_MERCHANDISES = "FETCH_MERCHANDISES";
export const FETCH_MERCHANDISES_SUCCESS = "FETCH_MERCHANDISES_SUCCESS";
export const FETCH_MERCHANDISES_FAILURE = "FETCH_MERCHANDISES_FAILURE";

export const fetchMerchandises = () => ({
  type: FETCH_MERCHANDISES,
});

export const fetchMerchandisesSuccess = (data) => ({
  type: FETCH_MERCHANDISES_SUCCESS,
  data,
});

export const fetchMerchandisesFailure = (err) => ({
  type: FETCH_MERCHANDISES_FAILURE,
  err,
});

export const getMerchandises = (merchandise_type_id) => {
  return (dispatch) => {
    dispatch(fetchMerchandises());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        if (merchandise_type_id !== undefined) {
          api
            .get(
              "/merchandise/list/",
              { merchandise_type_id: "" + merchandise_type_id },
              token
            )
            .then((res) => {
              dispatch(fetchMerchandisesSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchMerchandisesFailure(err)));
        } else {
          api
            .get("/merchandise/list/", null, token)
            .then((res) => {
              dispatch(fetchMerchandisesSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchMerchandisesFailure(err)));
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
};
