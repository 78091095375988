import React, { Component } from "react";
import {
  Header,
  Modal,
  Image,
  TransitionablePortal,
  Grid,
  Segment,
  Message,
  Dimmer,
  Loader,
  Dropdown,
} from "semantic-ui-react";
import Xclose from "../../assets/images/Xclose.svg";
import ic_threedots from "../../assets/images/ic_threedots.svg";
import usr from "../../assets/images/usr.png";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import _ from "lodash";

const usrimg = {
  border: "2px solid #4075FF",
  borderRadius: 50,
  width: 80,
  height: 80,
  display: "flex",
  justifyContent: "center",
  // marginTop:'30px',
  alignItems: "center",
  marginBottom: "20px",
};

const paymentTypes = [];

class RCModal extends Component {
  state = {
    dispatch: "",
    open: false,
    size: "tiny",
    list: [],
    loading: false,
    testDropDown: [
      { key: "completed", text: "Paid", value: "completed" },
      {
        key: "cancelled",
        text: "Cancel",
        value: "cancelled",
      },
    ],
  };

  handlePaymentVerification = (e, res, payment_id) => {
    let formdata = new FormData();
    this.props.loaderReFetch(true);
    formdata.append("id", payment_id);
    formdata.append("status", res.value);
    this.setState({
      open: !this.state.open,
    });
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/paynow/approve/", formdata, token).then((res) => {
          console.log("resting", res);
          this.props.reFetchDonorList();
          this.props.loaderReFetch(false);
        });
      } catch (err) {
        this.props.loaderReFetch(false);
        console.log(err);
      }
    });
  };

  openmodal = () => {
    this.setState({ open: !this.state.open });
    this.setState({ loading: true });
    firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/donation/",
            { parent_id: this.props.parentID, filtered_by: "parent_id" },
            token
          )
          .then((res) => {
            this.setState({ loading: false });

            this.setState({ list: res.data });
          })
          .catch((err) => console.log(err));
      } catch (err) {
        this.setState({ loading: false });
        console.log(err);
      }
    });
  };

  componentDidMount() {
    let id = this.props.parentID;
  }

  closemodal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { isloading, parental = [], details } = this.props;

    const { list } = this.state;

    // console.log(" payment_method", this.props.payment_method);
    // console.log(" status", this.props.status);
    // console.log("selectedDonationValue2", this.props.selectedDonationValue);

    const dollar = _.reduce(
      list.donations,
      (total, vote) => {
        let dataAmount =
          vote.amount == null ? parseInt(0) : parseInt(vote.amount);
        return total + dataAmount;
      },
      0
    );

    const chal = [];
    {
      _.map(details.children_names, (ch) => chal.push(ch));
    }
    return (
      <div>
        <Image
          src={ic_threedots}
          style={{ width: 24, height: 24, cursor: "pointer" }}
          onClick={() => this.openmodal()}
        />

        <TransitionablePortal
          open={this.state.open}
          transition={{ animation: "scale", duration: 400 }}
        >
          <Modal
            size={this.state.size}
            open={this.state.open}
            onClose={() => this.closemodal()}
            style={{ height: !this.state.loading && 380 }}
          >
            {this.state.loading ? (
              <Loader inverted active />
            ) : (
              <>
                <Modal.Header style={{ border: 0 }}>
                  <div align="right">
                    <Image
                      src={Xclose}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.closemodal()}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      marginLeft: "12px",
                    }}
                  >
                    {" "}
                    Donation Record
                  </div>
                </Modal.Header>
                <div
                  style={{
                    border: "1px solid #DADADA",

                    paddingTop: 10,
                    margin: "0px 20px",
                    borderRadius: 5,
                  }}
                >
                  {this.props.status.toLowerCase() == "payment verification" &&
                    this.props.payment_method == "paynow" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: 10,
                          padding: "0 5px",
                        }}
                      >
                        <Dropdown
                          name="payment_verification_id"
                          onChange={(e, res) => {
                            this.handlePaymentVerification(
                              e,
                              res,
                              this.props.payment_id
                            );
                          }}
                          placeholder={this.props.status}
                          selectOnBlur={false}
                          options={this.state.testDropDown}
                          //value={localStorage.getItem("user_type")}
                          selection
                          fluid
                          style={{
                            width: "196px",
                            height: "44px",
                            marginRight: 10,
                          }}
                          value={this.props.status}
                          className="payment-verification-dropdown"
                        />
                      </div>
                    )}
                  {this.props.status.toLowerCase() !=
                    "payment verification" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: 10,
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          background:
                            this.props.status.toLowerCase() == "paid" ||
                            this.props.status.toLowerCase() == "completed"
                              ? "#7CD227"
                              : this.props.status.toLowerCase() == "cancelled"
                              ? "#F66460"
                              : this.props.status.toLowerCase() ==
                                "payment verification"
                              ? "#4075FF"
                              : null,
                          width: "fit-content",
                          textAlign: "center",
                          padding: "5px 10px",
                          color:
                            this.props.status.toLowerCase() == "paid" ||
                            this.props.status.toLowerCase() == "cancelled" ||
                            this.props.status.toLowerCase() ==
                              "payment verification" ||
                            this.props.status.toLowerCase() == "completed"
                              ? "#fff"
                              : "#000",
                          fontWeight: 500,
                          fontSize: 12,
                          borderRadius: 99999,
                        }}
                      >
                        {this.props.status}
                      </p>
                    </div>
                  )}

                  <Modal.Content
                    style={
                      {
                        // paddingBottom: 25
                      }
                    }
                  >
                    <Segment
                      style={{
                        border: "1px solid #4075ff",
                        background: "#f9fbff",
                        padding: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Image
                              src={!list.thumbnail ? usr : list.thumbnail}
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                border: "2px solid #4075FF",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    color: "black",
                                    marginLeft: 10,
                                  }}
                                >
                                  <b>{details.parent_name} </b>
                                </span>
                                <span
                                  style={{ color: "gray", fontWeight: 700 }}
                                >
                                  ・
                                </span>
                                <div style={{ display: "inline" }}>
                                  <span
                                    style={{
                                      color: "gray",
                                      width: 200,
                                      paddingLeft: 1,
                                      fontWeight: 700,
                                    }}
                                  >
                                    <b>
                                      {" "}
                                      {chal}'s {details.parent_relationship}
                                    </b>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "block",
                                  color: "#939494",
                                  fontSize: 12,
                                  marginLeft: 10,
                                }}
                              >
                                {this.props.date}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ color: "#4075FF", fontWeight: 700 }}>
                          ${this.props.amount}
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: 30 }}>
                        <div>
                          <div style={{ fontWeight: "bold", marginBottom: 13 }}>
                            Transaction reference code
                          </div>
                          <div>
                            {this.props.transaction_ref
                              ? this.props.transaction_ref
                              : "-"}
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: 30,
                            paddingLeft: 30,
                            borderLeft: "1px solid #4075FF",
                          }}
                        >
                          <div style={{ fontWeight: "bold", marginBottom: 13 }}>
                            Email
                          </div>

                          <div>{details.parent_email}</div>
                        </div>
                      </div>

                      {/* <Grid columns={2} divided>
                        <Grid.Column>
                          <Image
                            src={!list.thumbnail ? usr : list.thumbnail}
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              border: "2px solid #4075FF",
                            }}
                          />
                          <span
                            style={{
                              color: "black",
                              marginTop: "40px",
                            }}
                          >
                            <b>{list.parent_name} </b>
                          </span>
                          <span style={{ color: "gray" }}>
                            <b>
                              {" "}
                              - {chal}'s . {list.parent_relationship}
                            </b>
                          </span>
                        </Grid.Column>
                        <Grid.Column>
                          <Grid.Column>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: 30,
                                color: "#4075FF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              ${!dollar ? 0 : dollar}
                            </span>
                            <br></br>
                            <span
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <b>Total</b>
                            </span>
                          </Grid.Column>
                        </Grid.Column>
                      </Grid>
                    */}
                    </Segment>
                    {/* <Grid
                      className="activityTitle2"
                      style={{ padding: "0 30px" }}
                    >
                      <Grid.Row>
                        <Grid.Column width={5}>
                          <Header as="h5" color="grey">
                            Date
                          </Header>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Header as="h5" color="grey">
                            Donation Title
                          </Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Header as="h5" color="grey">
                            Donation
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid.Row>
                      <Dimmer active={isloading} inverted>
                        <Loader inverted />
                      </Dimmer>
                    </Grid.Row>
                    {list.length === 0 ? (
                      <Message
                        header="No donation history"
                        content=" There is no donor in this section."
                        warning
                      />
                    ) : (
                      list.donations.map((data, index) => {
                        // console.log("Data",data);
                        return (
                          <Segment
                            className="forms-listbox"
                            key={index}
                            style={{ padding: "0 30px" }}
                          >
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={5} verticalAlign="middle">
                                  <div style={{ marginTop: 13 }}>
                                    {data["date"]} ddd
                                  </div>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="middle">
                                  <div style={{ marginTop: 13 }}>
                                    {data["title"]}
                                  </div>
                                </Grid.Column>
                                <Grid.Column width={3} verticalAlign="middle">
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 16,
                                      color: "#4075FF",
                                      marginTop: 13,
                                    }}
                                  >
                                    ${data["amount"]}
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        );
                      })
                    )} */}
                  </Modal.Content>
                </div>
              </>
            )}
          </Modal>
        </TransitionablePortal>
      </div>
    );
  }
}

export default RCModal;
