import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const FETCH_GRAPH = "FETCH_GRAPH";
export const FETCH_GRAPH_SUCCESS = "FETCH_GRAPH_SUCCESS";
export const FETCH_GRAPH_FAILURE = "FETCH_GRAPH_FAILTURE";

export const fetchGraph = () => ({
  type: FETCH_GRAPH,
});

export const fetchGraphSuccess = (data) => ({
  type: FETCH_GRAPH_SUCCESS,
  data,
});

export const fetchGraphFailure = (err) => ({
  type: FETCH_GRAPH_FAILURE,
  err,
});

export const getGraph = (start_date, end_date, id) => {
  return (dispatch) => {
    dispatch(fetchGraph());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/donation/donation_graph",
            { from_date: start_date, to_date: end_date, id: id },
            token
          )
          .then((res) => {
            dispatch(fetchGraphSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchGraphFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
