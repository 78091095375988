import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Grid, Image, Message } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import ic_time from "../assets/images/ic_time.svg";

const RenderTimePicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className="formMargin">
            <DatePicker
              selected={props.value}
              onChange={props.onChange}
              //startDate={startDate}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              minTime={props.minTime === undefined ? null : props.minTime}
              maxTime={props.maxTime === undefined ? null : props.maxTime}
              dateFormat={
                props.dateFormat === undefined ? "hh:mm:ss" : props.dateFormat
              }
            />
            <div className="input-iconnn">
              <h5>{props.fromtoProps}</h5>
            </div>
            <div className="input-iconnnn">
              <Image src={ic_time}></Image>
            </div>
          </div>
          {props.value < new Date() && props.isTouched && (
            <Message visible error content={props.isError} />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RenderTimePicker;
