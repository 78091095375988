import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Card,
  Feed,
  Image,
  Modal,
  List,
  Dimmer,
  Loader,
  Dropdown,
  TransitionablePortal,
  Message,
  Pagination,
  GridRow,
  Divider,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import RenderButton from "../../components/Button";
import {
  MERCHANDISE_CREATE_ROUTE,
  MERCHANDISE_DETAIL_ROUTE,
  MERCHANDISE_EDIT_ROUTE,
  MERCHANDISE_TYPE_CREATE_ROUTE,
  MERCHANDISE_ROUTE,
} from "../../routes";
import {
  getMerchandises,
  getMerchandiseTypes,
  getRecentOrders,
  getMerchandiseDetail,
  getMerchandiseAll,
} from "../../store/actions";
import ic_threedots from "../../assets/images/ic_threedots.svg";
import Xclose from "../../assets/images/Xclose.svg";
import RenderDatePicker from "../../components/DatePicker";
import moment from "moment";
import RenderLoader from "../../components/Loader";
import Photo_Default from "../../assets/images/Photo.png";
import Pf_Default from "../../assets/images/ic_account.png";
import PlaceHolder from "../../assets/images/placeholder_image.png";
import api from "../../config/api";
import { firebase } from "../../config/firebase";

const paymentTypes = [
  { key: "completed", text: "Paid", value: "completed" },
  {
    key: "cancelled",
    text: "Cancel",
    value: "cancelled",
  },
];

const RenderListBox = (props) => {
  return (
    <Segment className="listBox">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={2}
            style={{ width: "95px", height: "112px", marginLeft: "10px" }}
          >
            <Image
              src={props.img[0] !== "" ? props.img[0] : Photo_Default}
              style={{
                display: "inline",
                width: "95px",
                height: "112px",
                borderRadius: "8px",
                border: "1px solid #DADADA",
                objectFit: "cover",
              }}
              as={"img"}
              onError={(e) => (e.target.src = Photo_Default)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <p style={styles.nameWrapper}>
                { window.innerWidth < 1168 && props.name.length >=60
                ? `${props.name.slice(0,60)}...`
                : window.innerWidth < 1294 && props.name.length >= 95
                  ? `${props.name.slice(0,95)}...`
                  : props.name
              }
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header as="h4">{props.amount}</Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header as="h4">${props.price}</Header>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingLeft:0,paddingRight:0 }}>
            <p
              className={
                props.status === "In Stock"
                  ? "status-acitve"
                  : "status-inacitve"
              }
            >
              {props.status}
            </p>
          </Grid.Column>
          <Grid.Column width={1}>
            <Header as="h4">{props.orders_count}</Header>
          </Grid.Column>
          <Grid.Column width={1} className="centerText">
            <div className="ui simple dropdown item">
              <Image
                src={ic_threedots}
                style={{ width: 24, height: 24, cursor: "pointer" }}
              />
              <div className="menu">
                <div
                  onClick={() => props.handleOnSelect(props.id)}
                  className="item"
                >
                  View
                </div>
                <div
                  onClick={() =>
                    props.dispatch(
                      push(`${MERCHANDISE_EDIT_ROUTE}/${props.id}`)
                    )
                  }
                  className="item"
                >
                  Edit
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const RenderCard = (props) => {
  // console.log("recent order detail");
  // console.log("props", props);
  let priceExtractor = props.details.find(
    (result) => result.order_id === props.id
  );
  return (
    <div style={{ marginBottom: 17 }}>
      <Card
        style={{ borderRadius: 8 }}
        onClick={() => props.toggleModal(props.id)}
      >
        <Card.Content>
          <Feed>
            <Feed.Event>
              <Feed.Label
              // image={props.ordered_by.img}
              // style={{
              //   marginTop: "10px",
              //   border: "2px solid #4075FF",
              //   borderRadius: "100%",
              // }}
              >
                <Image
                  src={props.ordered_by.img}
                  style={{
                    borderRadius: "100%",
                    width: "36px",
                    height: "36px",
                    border: "2px solid #4075FF",
                    marginTop: "10px",
                  }}
                />
              </Feed.Label>
              <Feed.Content className="recentOrder">
                <Feed.Summary>{props.ordered_by.name}</Feed.Summary>
                {/* <Feed.Date
                  content={props.created_at}
                  style={{ margin: "1px 0", fontSize: 12 }}
                /> */}
                <div className="date" style={{ margin: "1px 0", fontSize: 12 }}>
                  {moment(props.created_at).format("YYYY MMM D HH:mm")}
                </div>

                <label>${props.total}</label>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Card.Content>
      </Card>
    </div>
  );
};

class MerchandiseList extends Component {
  state = {
    isModalOpen: false,
    popupData: null,
    merchandise_type_id: "",
    start_date: new Date(moment().subtract(7, "days").format("YYYY-MM-DD")),
    end_date: new Date(),
    activePage: 1,
    merchandise_type: "All",
  };

  componentDidMount() {
    let startDate = moment(this.state.start_date).format("YYYY-MM-DD");
    let endDate = moment(this.state.end_date).format("YYYY-MM-DD");
    let page = this.state.activePage - 1;
    localStorage.setItem("start-date", startDate);
    localStorage.setItem("end-date", endDate);
    this.props.getMerchandises();
    this.props.getMerchandiseTypes();
    this.props.getRecentOrders(startDate, endDate);
    this.props.getMerchandiseAll(this.state.merchandise_type, page);
  }

  handleFilter = (e, res) => {
    this.props.getMerchandiseAll(res.value, 0);
    this.props.getMerchandises(res.value);
    this.setState({ merchandise_type: res.value, activePage: 1 });
  };

  handlePaymentVerification = (e, res, data) => {
    let formdata = new FormData();
    formdata.append("id", data.payment.id);
    formdata.append("status", res.value);

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/paynow/approve/", formdata, token).then((res) => {
          // if (res.message === "Success") {
          //   console.log(res);
          //   this.props.dispatch(push(MERCHANDISE_ROUTE));
          // } else {
          //   alert(`${res.message}`);
          // }

          this.setState({
            isModalOpen: !this.state.isModalOpen,
            popupData: null,
          });

          this.props.getRecentOrders(
            moment(this.state.start_date).format("YYYY-MM-DD"),
            moment(this.state.end_date).format("YYYY-MM-DD")
          );
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  handleRoute = (route) => {
    this.props.dispatch(push(route));
  };

  handleOnChange = () => {
    console.log("handleOnChange hit");
  };

  toggleModal = (id) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
    let popupDataExtractor = this.props.recentorders.find(
      (result) => result.id === id
    );
    this.setState({
      popupData: popupDataExtractor,
    });
  };

  getStartDate = (date) => {
    if (date > this.state.end_date) {
      return alert("From Date must be less than To Date.");
    }
    let startDate = moment(date).format("YYYY-MM-DD");
    localStorage.setItem("start-date", startDate);
    this.setState({
      start_date: date,
    });
    let endDate =
      localStorage.getItem("end-date") !== undefined
        ? localStorage.getItem("end-date")
        : startDate;
    if (endDate === null) endDate = startDate;
    // console.log("start", startDate);
    // console.log("end", endDate);

    this.props.getRecentOrders(startDate, endDate);
  };

  getEndDate = (date) => {
    if (date < this.state.start_date) {
      return alert("To Date must be greater than From Date.");
    }
    let endDate = moment(date).format("YYYY-MM-DD");
    localStorage.setItem("end-date", endDate);
    this.setState({
      end_date: date,
    });
    let startDate =
      localStorage.getItem("start-date") !== undefined
        ? localStorage.getItem("start-date")
        : endDate;
    if (startDate === null) startDate = endDate;
    // console.log("start", startDate);
    // console.log("end", endDate);
    if (date < new Date(startDate)) {
      // console.log("less than");
      return alert("To Date must be greater than From Date.");
    }
    this.props.getRecentOrders(startDate, endDate);
  };

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage - 1;
    this.setState({ activePage });
    this.props.getMerchandiseAll(this.state.merchandise_type, page);
  };

  render() {
    const {
      merchandiseAll,
      merchandises = [],
      merchandisetypes = [],
      isloading,
      recentorders = [],
      dispatch,
      handleOnSelect,
      recentorderLoading,
    } = this.props;
    // console.log("props", this.props);
    const { isModalOpen, popupData, activePage } = this.state;
    let types = [{ key: null, text: "All", value: "All" }];
    merchandisetypes.map((data) => {
      types.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    // console.log("{popupData.id}", popupData);
    // let recentOrder = [];
    // recentorders.map((data) => {
    //   if (Object.keys(data.payment).length !== 0) {
    //     recentOrder.push(data);
    //   }
    // });
    return (
      <Grid className="fullHeight merchandise">
        <RenderLoader isloading={isloading} size="big" />
        <Grid.Row>
          {/* <Grid.Column width={11}> */}
          <Grid.Column 
            // width={window.innerWidth < 1020 ? 16 : 11}
            width={
              window.innerWidth < 850 ? 16 : window.innerWidth < 1020 ? 16 : 11
            }
          >
            <Grid>
              <Grid.Column floated="left" width={8}>
                <Header as="h3">Merchandise</Header>
                <Dropdown
                  name="merchandise_type_id"
                  onChange={this.handleFilter}
                  // placeholder="All"
                  defaultValue={this.state.merchandise_type}
                  selectOnBlur={false}
                  options={types}
                  //value={localStorage.getItem("user_type")}
                  selection
                  fluid
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid.Column>
              <Grid.Column
                width={8}
                style={{
                  // backgroundColor: "whitesmoke",
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  // justifyContent:
                  //   window.innerWidth > 1200 ? "space-evenly" : "space-between",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    width:
                      window.innerWidth < 900
                        ? 130
                        : window.innerWidth < 1070
                        ? 150
                        : 180,
                  }}
                >
                  <RenderButton
                    className="primaryBtn"
                    fluid={true}
                    btnText="Add Item"
                    showIcon={true}
                    onClick={() =>
                      this.props.dispatch(push(`${MERCHANDISE_CREATE_ROUTE}`))
                    }
                  />
                </div>
                <div
                  style={{
                    width:
                      window.innerWidth < 900
                        ? 130
                        : window.innerWidth < 1070
                        ? 150
                        : 180,
                  }}
                >
                  <RenderButton
                    className="primaryBtn"
                    fluid={true}
                    btnText="Add Type"
                    showIcon={true}
                    onClick={() =>
                      this.props.dispatch(
                        push(`${MERCHANDISE_TYPE_CREATE_ROUTE}`)
                      )
                    }
                  />
                </div>
              </Grid.Column>
            </Grid>
            <Grid className="listBoxTitle">
              <Grid.Row>
                <Grid.Column width={2} style={{ marginLeft: "30px" }}>
                  <Header as="h4" color="grey">
                    Image
                  </Header>
                </Grid.Column>
                <Grid.Column width={4} style={{ marginLeft: "-5px" }}>
                  <Header as="h4" color="grey">
                    Item
                  </Header>
                </Grid.Column>
                <Grid.Column width={2} style={{ marginLeft: "-15px" }}>
                  <Header as="h4" color="grey">
                    Stock Available
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h4" color="grey">
                    Price
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Status
                  </Header>
                </Grid.Column>
                <Grid.Column width={1} style={{ paddingLeft: 0 }}>
                  <Header as="h4" color="grey">
                    Order
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {merchandises.map((merchandise) => (
              <RenderListBox
                {...merchandise}
                dispatch={dispatch}
                handleOnSelect={handleOnSelect}
                key={merchandise.id}
              />
            ))}
            {isloading ||
            merchandises.length === 0 ||
            merchandiseAll === 1 ? null : (
              <Pagination
                activePage={activePage}
                firstItem={null}
                lastItem={null}
                siblingRange={3}
                totalPages={merchandiseAll}
                floated="right"
                onPageChange={this.handlePaginationChange}
              />
            )}
          </Grid.Column>

          <Grid.Column
            width={
              window.innerWidth < 850 ? 16 : window.innerWidth < 1020 ? 16 : 5
            }
            className=" addwhitebg"
            style={{
              background: "white",
              width: "50% !important",
              float: "right",

              right: 0,
              height: "100%",
            }}
          >
            <div
              style={{
                paddingLeft: window.innerWidth < 1020 && 40,
                width: window.innerWidth < 1020 ? "50%" : "100%",
                transform: window.innerWidth < 1020 && "translateY(-40px)",
              }}
            >
              <Header as="h4" style={{ fontSize: 20 }}>
                Recent Order
              </Header>

              <Grid>
                <Grid.Column 
                  computer={8} mobile={16} tablet={8}
                  style={{ minWidth: 170 }}
                  >
                  <RenderDatePicker
                    onChange={this.getStartDate}
                    dateFormat={"MM/dd/yyyy"}
                    selected={this.state.start_date}
                    value={this.state.start_date}
                    rc_order_style={true}
                  />
                  <div className="input-iconnn">
                    <h5
                      style={{
                        marginTop: "-22px",
                        fontSize: 12,
                        marginRight: 10,
                      }}
                    >
                      From
                    </h5>
                  </div>
                </Grid.Column>
                <Grid.Column
                  computer={8} mobile={16} tablet={8}
                  style={{ minWidth: 170 }}
                  className="to"
                >
                  <RenderDatePicker
                    onChange={this.getEndDate}
                    dateFormat={"MM/dd/yyyy"}
                    selected={this.state.end_date}
                    value={this.state.end_date}
                    rc_order_style2={true}
                  />
                  <div className="input-iconnn">
                    <h5
                      style={{
                        marginTop: "-22px",
                        fontSize: 12,
                        marginRight: 10,
                      }}
                    >
                      To
                    </h5>
                  </div>
                </Grid.Column>

                <Grid.Row>
                  <RenderLoader isloading={recentorderLoading} />
                </Grid.Row>
              </Grid>
              {/* {recentOrder.length === 0 && !recentorderLoading && !isloading ? ( */}
              {recentorders.length === 0 &&
              !recentorderLoading &&
              !isloading ? (
                <Message
                  header="No orders found"
                  content="There is no order in this period."
                  warning
                />
              ) : (
                recentorders.map((card) => (
                  <RenderCard
                    {...card}
                    toggleModal={this.toggleModal}
                    isModalOpen={isModalOpen}
                    recentorderLoading={recentorderLoading}
                    haveData={this.props.haveRecentOrderData}
                    key={card.id}
                  />
                ))
              )}
            </div>
          </Grid.Column>

          {popupData !== null && (
            <TransitionablePortal
              open={isModalOpen}
              transition={{ animation: "scale", duration: 400 }}
            >
              <Modal
                open={isModalOpen}
                size="tiny"
                className="orderedDetailPopup"
                style={{ width: "35%" }}
                onClose={() =>
                  this.setState({
                    isModalOpen: !this.state.isModalOpen,
                    popupData: null,
                  })
                }
              >
                {console.log("popup data", popupData)}
                {console.log(
                  "payment method",
                  popupData.payment.payment_method
                )}
                {console.log("payment status", popupData.payment.status)}
                <label>Recent Order</label>
                <div align="right" className="closeBtn">
                  <Image
                    src={Xclose}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({
                        isModalOpen: !this.state.isModalOpen,
                        popupData: null,
                      })
                    }
                  />
                </div>

                <div
                  className="recentOrderDetail"
                  style={{ position: "relative", top: "10px" }}
                >
                  <Card
                    style={{
                      position: "relative",
                      top: "5px",
                      height: "40px",
                      display: "inline",
                    }}
                  >
                    <div>
                      <label
                        style={{
                          fontSize: "14px",
                          lineHeight: "19px",
                          fontWeight: 600,
                          fontFamily: "Open Sans",
                          verticalAlign: "middle",
                          fontStyle: "normal",
                          position: "relative",
                          top:
                            popupData.payment.status === "payment_verification"
                              ? "20px"
                              : "6.5px",
                          // top: "6.5px",
                          left: "15px",
                        }}
                      >
                        Order ID {popupData.id}
                      </label>
                      <label style={{ float: "right" }}>
                        {/* <div
                          style={{
                            backgroundColor: "#D1EAFF",
                            padding: "5px 10px",
                            fontStyle: "normal",
                            fontFamily: "Open Sans",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "19.07px",
                            borderRadius: "16px",
                            position: "relative",
                            top: "1px",
                            right: "13px",
                          }}
                        >
                          {popupData.payment.status === "pending"
                            ? "Pending"
                            : "Paid"}
                        </div> */}

                        {popupData.payment.status === "payment_verification" ? (
                          <Dropdown
                            name="payment_verification_id"
                            onChange={(e, res) =>
                              this.handlePaymentVerification(e, res, popupData)
                            }
                            placeholder="Payment Verification"
                            selectOnBlur={false}
                            options={paymentTypes}
                            //value={localStorage.getItem("user_type")}
                            selection
                            fluid
                            style={{
                              width: "196px",
                              height: "44px",
                              marginRight: 10,
                            }}
                            className="payment-verification-dropdown"
                          />
                        ) : popupData.payment.status === "completed" ? (
                          <div
                            style={{
                              backgroundColor: "#D1EAFF",
                              padding: "5px 10px",
                              fontStyle: "normal",
                              fontFamily: "Open Sans",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "19.07px",
                              borderRadius: "16px",
                              position: "relative",
                              top: "1px",
                              right: "13px",
                            }}
                          >
                            Paid
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#b00020",
                              color: "white",
                              padding: "5px 10px",
                              fontStyle: "normal",
                              fontFamily: "Open Sans",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "19.07px",
                              borderRadius: "16px",
                              position: "relative",
                              top: "1px",
                              right: "13px",
                            }}
                          >
                            Cancel
                          </div>
                        )}
                        {/* {popupData.payment.status === "pending" && "Pending"}
                          {popupData.payment.status &&
                            popupData.payment.status.toLowerCase() ===
                              "cancelled" &&
                            "Cancelled"} */}
                      </label>
                    </div>
                  </Card>
                  <Card
                    style={{
                      height: "131px",
                      border: "1px solid #4075FF",
                      backgroundColor: "#F9FBFF",
                    }}
                  >
                    <div>
                      <Feed>
                        <Feed.Event
                          style={{ position: "relative", top: "10px" }}
                        >
                          <Feed.Label>
                            <Image
                              src={
                                popupData.ordered_by.img !== ""
                                  ? popupData.ordered_by.img
                                  : Pf_Default
                              }
                              style={{
                                display: "inline",
                                width: "36px",
                                height: "36px",
                                borderRadius: "20px",
                                position: "relative",
                                top: "8px",
                                left: "10px",
                                border: "2px solid #065CA4",
                              }}
                              as={"img"}
                              onError={(e) => (e.target.src = Pf_Default)}
                            />
                          </Feed.Label>
                          {popupData.ordered_by !== undefined && (
                            <Feed.Content style={{ display: "inline-block" }}>
                              <p
                                style={{
                                  float: "right",
                                  position: "relative",
                                  right: "15px",
                                  color: "#4075FF",
                                  fontFamily: "Open Sans",
                                  fontStyle: "normal",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  lineHeight: "19px",
                                }}
                              >
                                $ {popupData.total}
                              </p>
                              <Feed.Summary style={{ paddingTop: "5px" }}>
                                {popupData.ordered_by.name}
                              </Feed.Summary>
                              <Feed.Date
                                content={popupData.ordered_by.created_at}
                                style={{ margin: "4px 0", fontSize: 10 }}
                              />
                            </Feed.Content>
                          )}
                        </Feed.Event>
                      </Feed>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "block",
                          position: "relative",
                          top: "20px",
                        }}
                      >
                        <div
                          style={{
                            float: "left",
                            position: "relative",
                            left: "15px",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: "8px",
                              fontFamily: "Open Sans",
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Transaction Reference Code
                          </p>
                          <p
                            style={{
                              marginBottom: "0px",
                              fontFamily: "Open Sans",
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "19px",
                            }}
                          >
                            code {popupData.payment.transaction_ref}
                          </p>
                        </div>
                        <div
                          style={{
                            borderLeft: "1px solid #4075FF",
                            height: "40px",
                            float: "left",
                            position: "relative",
                            left: "30px",
                          }}
                        />
                        <div
                          style={{
                            float: "left",
                            position: "relative",
                            left: "45px",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: "8px",
                              fontFamily: "Open Sans",
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Email
                          </p>
                          <p
                            style={{
                              marginBottom: "0px",
                              fontFamily: "Open Sans",
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "19px",
                            }}
                          >
                            {popupData.ordered_by.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "250px",
                      height: "auto",
                    }}
                  >
                    {popupData.details !== undefined &&
                      popupData.details.map((detail) => {
                        return (
                          <List verticalAlign="middle" divided key={detail.id}>
                            <List.Item>
                              <List.Content
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <Image
                                  avatar
                                  src={
                                    detail.merchandise.img[0] == ""
                                      ? PlaceHolder
                                      : detail.merchandise.img[0]
                                  }
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "8px",
                                    border: "1px solid #DADADA",
                                    marginBottom: 17,
                                    marginRight: 17,
                                    float: "left",
                                  }}
                                />
                                <ul className="orderInfo">
                                  <li
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      lineHeight: "19px",
                                    }}
                                  >
                                    {detail.merchandise.name}
                                  </li>
                                  <li
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      lineHeight: "19px",
                                      position: "relative",
                                      top: "5px",
                                    }}
                                  >
                                    Size:&nbsp;{detail.merchandise.size}
                                  </li>
                                  <li
                                    style={{
                                      color: "#4075FF",
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      lineHeight: "19px",
                                      position: "relative",
                                      top: "17px",
                                    }}
                                  >
                                    ${detail.price}
                                  </li>
                                </ul>
                                <div
                                  className="graytxt"
                                  style={{
                                    position: "relative",
                                    bottom: "25px",
                                    float: "right",
                                  }}
                                >
                                  <p
                                    style={{
                                      float: "left",
                                      marginBottom: "0px",
                                      position: "relative",
                                      right: "30px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    X{detail.amount}
                                  </p>
                                  {detail.status ===
                                  "Pending Payment" ? null : (
                                    <div
                                      style={{
                                        float: "right",
                                      }}
                                    >
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          textAlign: "end",
                                          position: "relative",
                                          bottom:
                                            detail.status === "Ordered"
                                              ? "0px"
                                              : "9px",
                                        }}
                                        className={
                                          detail.status === "Ordered"
                                            ? "ordered"
                                            : "delivered"
                                        }
                                      >
                                        {detail.status}
                                      </p>
                                      {detail.status === "Ordered" ? null : (
                                        <p
                                          style={{
                                            textAlign: "end",
                                            position: "relative",
                                            bottom: "7px",
                                            fontFamily: "Open Sans",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "16px",
                                            color: "#939494",
                                          }}
                                        >
                                          {detail.last_updated}
                                        </p>
                                      )}
                                      {/* {detail.status !== "Delivered" ? (
                                      <p
                                      content={popupData.created_at}
                                      style={{
                                        fontSize: 10,
                                        color: "#939494",
                                      }}
                                      />
                                    ) : null} */}
                                    </div>
                                  )}
                                </div>
                              </List.Content>
                            </List.Item>
                          </List>
                        );
                      })}
                  </div>
                </div>
              </Modal>
            </TransitionablePortal>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

const styles = {
  nameWrapper: {
    height: "105%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
  },
};

const mapStateToProps = (state) => ({
  merchandises: state.merchandiseAll.merchandiseAll.data,
  merchandiseAll: state.merchandiseAll.merchandiseAll.total_pages,
  isloading: state.merchandiseAll.loading,
  haveData: state.merchandiseAll.data,
  haveError: state.merchandiseAll.err,
  merchandisetypes: state.merchandisetypes.merchandisetypes.data,
  recentorders: state.recentorders.recentOrders.data,
  recentorderLoading: state.recentorders.loading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMerchandises: (id) => dispatch(getMerchandises(id)),
  getMerchandiseAll: (merchandise_type, activePage) =>
    dispatch(getMerchandiseAll(merchandise_type, activePage)),
  getMerchandiseTypes: () => dispatch(getMerchandiseTypes()),
  getRecentOrders: (start_date, end_date) =>
    dispatch(getRecentOrders(start_date, end_date)),
  getMerchandiseDetail: (id) => dispatch(getMerchandiseDetail(id)),
  handleOnSelect: (id) => {
    dispatch(push(`${MERCHANDISE_DETAIL_ROUTE}/${id}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchandiseList);
