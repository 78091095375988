import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Menu, Image } from "semantic-ui-react";
import {
  DASHBOARD_ROUTE,
  ACTIVITY_ROUTE,
  USERMANAGEMENT_ROUTE,
  ATTENDANCE_ROUTE,
  MERCHANDISE_ROUTE,
  FORM_ROUTE,
  DONATION_ROUTE,
  NOTIFICATION_ROUTE,
  CALENDAR_ROUTE,
  COMMS_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
  APP_MAINTENANCE_ROUTE,
  NEWS_FEED_ROUTE,
} from "../routes";
import logo from "../assets/images/logo.png";
import MenuItem from "./MenuItem";
import ic_dashboard from "../assets/images/ic_dashboard.svg";
import ic_merchandise from "../assets/images/ic_merchandise.svg";
import ic_events from "../assets/images/ic_events.svg";
import ic_report from "../assets/images/ic_report.svg";
import ic_coin from "../assets/images/ic_coin.svg";
import ic_newsfeed from "../assets/images/004-newspaper.svg";
import ic_app_maintenance from "../assets/images/ic_app_maintenance.svg";
import ic_attendance from "../assets/images/ic_attendance.svg";
import ic_staff from "../assets/images/ic_staff.svg";
import Vectoric_bell from "../assets/images/Vectoric_bell.svg";
import ic_calendar from "../assets/images/ic_calendar.svg";
import ic_comms from "../assets/images/ic_comms.svg";
import { NavLink, Link, Redirect, Router } from "react-router-dom";
import Dashboard from "../views/dashboard";
import PaymentSuccess from "../views/staticpages/PaymentSuccess";
import { Route, Switch } from "react-router";
import PaymentError from "../views/staticpages/PaymentError";
import { push } from "react-router-redux";
import { firebase, db } from "../config/firebase";
import api from "../config/api";
import { getRoomlist } from "../store/actions";
import { getCurrentLoginUser } from "../store/actions";
import { useMediaQuery } from "react-responsive";

let isBurgerClick = false;

const Navigation = ({
  children,
  selected,
  getRoomlist,
  room,
  userid,
  userDetail,
  getCurrentLoginUser,
}) => {
  var isNewMessageNav;
  const [burgerMargin, setBurgerMargin] = useState();
  useEffect(() => {
    const margin = window.innerWidth > 1153 ? 240 : 0;
    setBurgerMargin(margin);
  }, []);

  useEffect(() => {
    db.collection("comms")
      .where("receiver", "==", localStorage.getItem("user_Id"))
      .onSnapshot((snapshot) => {
        getRoomlist(localStorage.getItem("user_Id"), false);
      });
    getCurrentLoginUser(userid, "admin");
  }, [selected]);

  let checkUnReadMessage = (room) => {
    let index = room.findIndex((d) => d.unread_count != 0 && !isNewMessageNav);
    return index != -1;
  };

  isNewMessageNav = checkUnReadMessage(room);

  const toggleMenu = () => {
    isBurgerClick = true;
    const mainContentWidth = document.getElementById("main-content-width");
    const sideMenu = document.getElementById("side-menu");
    const sideMenuWidth = sideMenu.offsetWidth;
    const modifiedWidth = sideMenuWidth === 240 ? "0px" : "240px";
    const modifiedPadding = sideMenuWidth === 240 ? "0px" : "22px";

    let modifiedBurgerMargin = sideMenuWidth === 240 ? 0 : 240;
    setBurgerMargin(modifiedBurgerMargin);
    sideMenu.setAttribute(
      "style",
      `width: ${modifiedWidth} !important; padding: ${modifiedPadding}; display: block`
    );
    // mainContentWidth.setAttribute("style", `margin-left: ${modifiedWidth};`);
    mainContentWidth.setAttribute("style", `margin-left: 0px;`);
  };

  function CloseMenu() {
    if (window.innerWidth < 1153 && !isBurgerClick) {
      const mainContentWidth = document.getElementById("main-content-width");
      const sideMenu = document.getElementById("side-menu");
      setBurgerMargin(0);
      sideMenu.setAttribute(
        "style",
        `width: 0px !important; padding: 0px; display: block`
      );
      mainContentWidth.setAttribute("style", `margin-left: 0px;`);
    }
    isBurgerClick = false;
  }

  if (userDetail) {
    return (
      <div onClick={CloseMenu}>
        <Menu
          vertical
          style={{ borderRadius: 0 }}
          className="main-nav-content-width sidebarMenu"
          id="side-menu"
        >
          <Link to={DASHBOARD_ROUTE}>
            <Image className="logo" src={logo} />
          </Link>
          <MenuItem to={DASHBOARD_ROUTE} active={selected === "dashboard"}>
            <Image
              src={ic_dashboard}
              verticalAlign="middle"
              className="ui middle aligned image sidebar-icon"
            />{" "}
            <span>Dashboard</span>
          </MenuItem>
          <MenuItem to={ACTIVITY_ROUTE} active={selected === "activity"}>
            <Image
              src={ic_events}
              verticalAlign="middle"
              className="sidebar-icon"
            />{" "}
            <span>Events</span>
          </MenuItem>
          <MenuItem
            to={USERMANAGEMENT_ROUTE}
            active={selected === "user_management"}
          >
            <Image
              src={ic_staff}
              verticalAlign="middle"
              className="sidebar-icon"
            />{" "}
            User Management
          </MenuItem>
          <MenuItem to={ATTENDANCE_ROUTE} active={selected === "attendance"}>
            <Image
              src={ic_attendance}
              verticalAlign="middle"
              className="sidebar-icon"
            />{" "}
            Attendance
          </MenuItem>
          <MenuItem to={CALENDAR_ROUTE} active={selected === "calendar"}>
            <Image
              src={ic_calendar}
              verticalAlign="middle"
              className="sidebar-icon"
            />{" "}
            <span>Calendar</span>
          </MenuItem>
          <MenuItem to={COMMS_ROUTE} active={selected == "comms"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Image
                  src={ic_comms}
                  verticalAlign="middle"
                  className="sidebar-icon"
                />{" "}
                <span>Comms</span>
              </div>
              {isNewMessageNav && (
                <div
                  style={{
                    background: "#F66460",
                    width: 10,
                    height: 10,
                    marginTop: 5,
                    borderRadius: 50,
                  }}
                ></div>
              )}
            </div>
          </MenuItem>
          {userDetail.admin_type === "hq" && (
            <>
              <MenuItem to={DONATION_ROUTE} active={selected === "donation"}>
                <Image
                  src={ic_coin}
                  verticalAlign="middle"
                  className="sidebar-icon"
                />{" "}
                <span>Call for Donation</span>
              </MenuItem>
              <MenuItem
                to={MERCHANDISE_ROUTE}
                active={selected === "merchandise"}
              >
                <Image
                  src={ic_merchandise}
                  verticalAlign="middle"
                  className="sidebar-icon"
                />{" "}
                <span>Merchandise</span>
              </MenuItem>
              <MenuItem to={FORM_ROUTE} active={selected === "forms"}>
                <Image
                  src={ic_report}
                  verticalAlign="middle"
                  className="sidebar-icon"
                />{" "}
                <span>Forms</span>
              </MenuItem>
              <MenuItem to={NEWS_FEED_ROUTE} active={selected == "newsfeed"}>
                <Image
                  src={ic_newsfeed}
                  verticalAlign="middle"
                  className="sidebar-icon"
                />{" "}
                Newsfeed
              </MenuItem>
            </>
          )}
          <MenuItem to={NOTIFICATION_ROUTE} active={selected == "notification"}>
            <Image
              src={Vectoric_bell}
              verticalAlign="middle"
              className="sidebar-icon"
            />{" "}
            Notification
          </MenuItem>
          {userDetail.is_super === 1 ? (
            <MenuItem
              to={APP_MAINTENANCE_ROUTE}
              active={selected === "app-maintenance"}
            >
              <Image
                src={ic_app_maintenance}
                verticalAlign="middle"
                className="sidebar-icon"
              />{" "}
              App Maintenance
            </MenuItem>
          ) : null}
        </Menu>
        {selected === "" && (
          <Redirect to={DASHBOARD_ROUTE} component={Dashboard} />
        )}
        <div className="main-content-width" id="main-content-width">
          <div style={styles.burgerContainer}>
            <div
              id="burger-menu"
              className="burger-menu"
              onClick={toggleMenu}
              style={{
                marginLeft: burgerMargin,
              }}
            >
              <svg
                width="24"
                height="72"
                viewBox="0 0 24 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0L17.9595 4.53713C21.5115 5.43447 24 8.62989 24 12.2934V59.7066C24 63.3701 21.5115 66.5655 17.9595 67.4629L0 72V0Z"
                  fill="#E8EDFC"
                />
              </svg>
              <svg
                style={{ position: "absolute", left: 6, top: 27 }}
                width="13"
                height="18"
                viewBox="0 0 13 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="16" width="13" height="2" rx="1" fill="#4075FF" />
                <rect y="8" width="13" height="2" rx="1" fill="#4075FF" />
                <rect width="13" height="2" rx="1" fill="#4075FF" />
              </svg>
              {isNewMessageNav && <span style={styles.notiDot}></span>}
            </div>
          </div>
          {children}
        </div>
      </div>
    );
  }
  return null;
};

const styles = {
  notiDot: {
    background: "#F66460",
    width: 8,
    height: 8,
    borderRadius: 50,
    position: "absolute",
    left: 14,
    top: 40,
  },
  burgerContainer: {
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
};

const mapStateToProps = (state) => ({
  userDetail: state.currentLoginUser.currentLoginUser.data,
  room: state.room.room.data !== undefined ? state.room.room.data : [],
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
  getRoomlist: (senderid, refresh) => dispatch(getRoomlist(senderid, refresh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
