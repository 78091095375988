import React, { Component } from "react";
import {
  Grid,
  Header,
  Button,
  Image,
  Dimmer,
  Loader,
  ItemMeta,
} from "semantic-ui-react";
import { Formik, Field, Form, FieldArray } from "formik";
import RenderInput from "../../components/TextInput";
import DisabledTextInput from "../../components/DisabledTextInput";
import RenderTextArea from "../../components/RenderTextArea";
import MultipleTextInput from "../../components/MultipleTextInput";
import FormDropDown from "../../components/FormDropDown";
import RenderImage from "../../components/Image";
import RenderDropdown from "../../components/DropDown";
import { isRequired } from "../../validators";
import RoutingButton from "../../components/RoutingButton";
import RenderRadioGroup from "../../components/RadioGroup";
import { FORM_ROUTE } from "../../routes";
import Xclose from "../../assets/images/Xclose.svg";
import DonationDatePicker from "../../components/DonationDateFrom";
import DonationDateTo from "../../components/DonationDateTo";
import FormTime from "../../components/FormTime";
import FormTimeTo from "../../components/FormTimeTo";
import RenderFile from "../../components/File";
import moment from "moment";
import { firebase } from "../../config/firebase";
import { getCentersClasses, getFormType } from "../../store/actions";
import api from "../../config/api";
import ic_attach from "../../assets/images/ic_attach.svg";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import defaultimage from "../../assets/images/defaultimage.png";
import { Input, Dropdown } from "semantic-ui-react";
import { findIndex } from "lodash-es";
import TextInputForm from "../../components/TextInputForm";
import { Icon } from "semantic-ui-react";
import el from "date-fns/esm/locale/el/index.js";

const roles = [
  {
    value: "facilitator",
    label: "Facilitator",
  },
  {
    value: "parent",
    label: "Parent",
  },
];

var date = new Date();

let dfrom = date;
let dto = date;
let file = "";

const linearScaleOption1 = [
  { key: 0, text: "0", value: 0 },
  { key: 1, text: "1", value: 1 },
];
const linearScaleOption2 = [
  { key: 2, text: "2", value: 2 },
  { key: 3, text: "3", value: 3 },
  { key: 4, text: "4", value: 4 },
  { key: 5, text: "5", value: 5 },
  { key: 6, text: "6", value: 6 },
  { key: 7, text: "7", value: 7 },
  { key: 8, text: "8", value: 8 },
  { key: 9, text: "9", value: 9 },
  { key: 10, text: "10", value: 10 },
];

class FormEdit extends Component {
  getAllCentres = () => {
    const { centerclasses = [] } = this.props;
    let allCentres = [];

    centerclasses.map((data) => {
      allCentres.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    allCentres.push({
      key: "all",
      text: "All",
      value: "all",
    });
    return allCentres.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  getAllClasses = (centre_id) => {
    const { centerclasses = [] } = this.props;
    let allClasses = [];

    if (centre_id === "all") {
      allClasses.push(
        { key: "all", text: "All", value: "all" },
        { key: "p1", text: "P1", value: "p1" },
        { key: "p2", text: "P2", value: "p2" },
        { key: "p3", text: "P3", value: "p3" },
        { key: "p4", text: "P4", value: "p4" },
        { key: "p5", text: "P5", value: "p5" },
        { key: "p6", text: "P6", value: "p6" }
      );
    } else {
      if (centre_id === "" || centre_id === "all") {
        centerclasses.map((ce) => {
          ce.class.map((cl) => {
            allClasses.push({
              key: cl.id,
              text: cl.name,
              value: cl.id,
            });
          });
        });
      } else {
        let tempClasses = centerclasses.filter((x) => x.id === centre_id)[0]
          .class;
        tempClasses.map((xx) => {
          allClasses.push({
            key: xx.id,
            text: xx.name,
            value: xx.id,
          });
        });
      }
    }

    return allClasses.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      Element: JSON.parse(this.props.location.state.data.form_settings),
      Multi: JSON.parse(this.props.location.state.data.form_settings),
      Date: [],
      File: [],
      AddQuestion: JSON.parse(this.props.location.state.data.form_settings),
      increment: JSON.parse(this.props.location.state.data.form_settings)
        .length,
      datefrom: new Date(this.props.location.state.data.valid_from_date),
      dateto: new Date(this.props.location.state.data.valid_to_date),
      timefrom: new Date(),
      timeto: new Date(),
      previewUrl: this.props.location.state.data.img,
      createdby: "",
      isloading: false,
      Rows: JSON.parse(this.props.location.state.data.form_settings),
      Columns: JSON.parse(this.props.location.state.data.form_settings),
      FormSettings: JSON.parse(this.props.location.state.data.form_settings),
    };
  }

  getFormTypes = () => {
    const { formtypes = [] } = this.props;
    let allFormTypes = [];
    formtypes.map((x) => {
      allFormTypes.push({
        key: x.id,
        text: x.name,
        value: x.id,
      });
    });
    return allFormTypes;
  };

  componentDidMount() {
    this.props.getCentersClasses();
    this.props.getFormType();

    dfrom = moment(this.props.location.state.data.valid_from_date).format(
      "YYYY-MM-DD"
    );
    dto = moment(this.props.location.state.data.valid_to_date).format(
      "YYYY-MM-DD"
    );
    let addquestions = [];
    this.state.AddQuestion.map((t, idx) => {
      addquestions.push({ key: idx, type: t.type });
    });
    this.setState({ AddQuestion: addquestions });

    let elements = [];
    this.state.Element.map((e, idx) => {
      elements.push({
        type: e.type,
        key: idx,
        data: e.data,
        option: [],
        mandatory: e.mandatory,
        title: e.title,
        linear1: e.linear1,
        linear2: e.linear2,
        label1: e.label1,
        label2: e.label2,
      });
    });

    this.setState({ Element: elements });
    let multi = [];

    // this.state.Multi.map((m, idx) => {
    //   multi.push({ type: m.type, key: idx, option: [...m.option] });
    // });
    // this.setState({ Multi: multi });
    let rows = [];
    let cols = [];
    this.state.FormSettings.forEach((item, index) => {
      if (
        item.type === "Multiple choice" ||
        item.type === "Checkbox" ||
        item.type === "Dropdown"
      ) {
        item.option.forEach((itemData, itemIndex) => {
          multi.push({
            key: index,
            data: itemData,
            label: itemIndex + 1,
            value: MultipleTextInput,
          });
        });
      }
      if (item.type === "Multiple choice grid") {
        item.rows.forEach((rowData, rowIndex) => {
          rows.push({
            key: index,
            data: rowData,
            label: rowIndex + 1,
            value: MultipleTextInput,
          });
        });
        item.cols.forEach((rowData, rowIndex) => {
          cols.push({
            key: index,
            data: rowData,
            label: rowIndex + 1,
            value: MultipleTextInput,
          });
        });
      }
    });
    // console.log("rows", rows);
    // console.log("cols", cols);
    // console.log("multi", multi);
    this.setState({ Rows: rows, Columns: cols, Multi: multi });
    // con;
    firebase
      .reloadAuth()
      .then((data) => {
        this.setState({ createdby: data.uid });
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.data) {
      this.setState({
        previewUrl: this.props.data.img,
      });
    }
  }
  handleAdd = (index, value) => {
    this.setState({ increment: this.state.increment + 1 }, () => {
      if (value === "1") {
        this.setState(
          (prevState) => ({
            AddQuestion: [
              ...prevState.AddQuestion,
              { key: this.state.increment },
            ],
            Element: [
              ...prevState.Element,
              {
                type: "Short answer",
                key: this.state.increment,
                value: RenderInput,
                data: "",
                option: [],
                mandatory: false,
                title: "",
              },
            ],
          }),
          () => {
            console.log("aa", this.state.AddQuestion);
            console.log("ee", this.state.Element);
          }
        );
      } else if (value === "2") {
        // this.setState(prevState=>({
        //     AddQuestion: [...prevState.AddQuestion[index], {"ShortAnswer": ''}]
        // }),()=>{
        //     console.log(this.state.AddQuestion)
        // });
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };

        el[index] = {
          type: "Short answer",
          key: this.state.increment,
          value: DisabledTextInput,
          data: "",
          option: [],
          mandatory: false,
          title: "",
        };
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "3") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Paragraph",
          key: this.state.increment,
          value: TextInputForm,
          data: "",
          option: [],
          mandatory: false,
          title: "",
        };
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "4") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        // let mul=this.state.Multi.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Multiple choice",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
          mandatory: false,
          title: "",
        };
        for (let i = 0; i < 10; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Multi: [
              ...pre.Multi,
              {
                key: this.state.increment,
                value: MultipleTextInput,
                data: "",
                label: "Item " + op,
                dex: op,
                ty: "Multiple choice",
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "5") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Checkbox",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
          mandatory: false,
          title: "",
        };
        for (let i = 0; i < 10; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Multi: [
              ...pre.Multi,
              {
                key: this.state.increment,
                value: MultipleTextInput,
                data: "",
                label: "Item " + op,
                dex: op,
                ty: "Checkbox",
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "6") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Dropdown",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
          mandatory: false,
          title: "",
        };
        for (let i = 0; i < 10; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Multi: [
              ...pre.Multi,
              {
                key: this.state.increment,
                value: MultipleTextInput,
                data: "",
                label: "Item " + op,
                dex: op,
                ty: "Dropdown",
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "7") {
        // const newQues=this.state.AddQuestion.slice();
        // newQues[index] = {"FileUpload":''};
        // this.setState({AddQuestion: newQues});
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "File upload",
          key: this.state.increment,
          value: RenderInput,
          mandatory: false,
          title: "",
        };
        this.setState({
          AddQuestion: newQues,
          Element: el,
          Multi: [
            ...this.state.Multi,
            {
              key: this.state.increment,
              value: RenderInput,
              data: "",
              label: "Size",
              dex: 1,
              ty: "Dropdown",
            },
          ],
        });
      } else if (value === "8") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment, type: "Linear scale" };
        el[index] = {
          type: "Linear scale",
          key: this.state.increment,
          value: RenderInput,
          mandatory: false,
          title: "",
          linear1: 1,
          linear2: 5,
          label1: "",
          label2: "",
        };
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "9") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = {
          key: this.state.increment,
          type: "Multiple choice grid",
        };
        el[index] = {
          type: "Multiple choice grid",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
          mandatory: false,
          title: "",
        };
        for (let i = 0; i < 2; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Rows: [
              ...pre.Rows,
              {
                key: this.state.increment,
                value: MultipleTextInput,
                data: "",
                label: op,
              },
            ],
          }));
        }
        for (let i = 0; i < 3; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Columns: [
              ...pre.Columns,
              {
                key: this.state.increment,
                value: MultipleTextInput,
                data: "",
                label: op,
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Date",
          key: this.state.increment,
          value: RenderInput,
          mandatory: false,
          title: "",
        };
        this.setState({
          AddQuestion: newQues,
          Element: el,
          // Multi: [...this.state.Multi, {key: this.state.increment, value: DonationDatePicker}]
        });
      }
    });
  };

  handleRemove = (key, idx) => {
    const remQues = this.state.AddQuestion;
    const remElement = this.state.Element;
    const remMulti = this.state.Multi;
    const result = remQues
      .slice(0, idx)
      .concat(remQues.slice(idx + 1, remQues.length));
    const newInput = remElement.filter((item) => item["key"] !== key);
    // const newMulti = remMulti.filter(item=> !idx.indexOf(item));
    // above statement remove the array by index.
    // console.log(newMulti);
    this.setState({ AddQuestion: result, Element: newInput });
  };

  FDchange = (date) => {
    if (date < this.state.dateto) {
      dfrom = moment(date).format("YYYY-MM-DD");
      this.setState({ datefrom: date });
    } else {
      alert("date from must less than date to");
    }
  };
  TDchange = (date) => {
    if (date > this.state.datefrom) {
      dto = moment(date).format("YYYY-MM-DD");
      this.setState({ dateto: date });
    } else {
      alert("date to must be greater than date from");
    }
  };

  handleElement = (index, value) => {
    let elm = this.state.Element.slice();
    if (elm[index].value === RenderFile) {
    } else {
      elm[index].data = value;
      this.setState({ Element: elm });
    }
  };

  handleLinear = (index, value, linear) => {
    let elm = this.state.Element.slice();
    elm[index][linear] = value;
    this.setState({ Element: elm });
  };

  handleLinearLabel = (index, value, label) => {
    let elm = this.state.Element.slice();
    elm[index][label] = value;
    this.setState({ Element: elm });
  };

  handleMultiOne = (index, dex, value) => {
    let mul = this.state.Multi.slice();
    mul[index].option[dex] = value;
    this.setState({ Multi: mul });
  };

  handleMulti = (index, value) => {
    // console.log("handleMulti");
    // console.log("index", index);
    // console.log("value", value);
    let mul = this.state.Multi.slice();
    mul[index].data = value;
    // console.log("mul", mul);
    this.setState({ Multi: mul });
  };

  onChange = (event) => {
    let reader = new FileReader();
    file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    this.setState({ imgs: event.target.files[0] });
  };

  handleRows = (index, value) => {
    let rows = this.state.Rows.slice();
    rows[index].data = value;
    this.setState({ Rows: rows });
  };

  handleColumns = (index, value) => {
    let cols = this.state.Columns.slice();
    cols[index].data = value;
    this.setState({ Columns: cols });
  };

  handleRowsAdd = (index) => {
    const filterArrays = this.state.Rows.filter(
      (item) => item.key === index.key
    );
    if (filterArrays.length === 10) return;
    this.setState({
      Rows: [
        ...this.state.Rows,
        {
          key: index.key,
          data: "",
          label: filterArrays.length + 1,
          value: MultipleTextInput,
        },
      ],
    });
  };

  handleColumnsAdd = (index) => {
    const filterArrays = this.state.Columns.filter(
      (item) => item.key === index.key
    );
    if (filterArrays.length === 10) return;
    this.setState({
      Columns: [
        ...this.state.Columns,
        {
          key: index.key,
          data: "",
          label: filterArrays.length + 1,
          value: MultipleTextInput,
        },
      ],
    });
  };

  handleSubmit = (values) => {
    let fd = new FormData();
    let elem = this.state.Element;
    let mu = this.state.Multi;
    let rowsState = this.state.Rows;
    let colsState = this.state.Columns;
    let options = [];
    let rows = [];
    let cols = [];
    let result = [];

    elem.forEach((e) => {
      options = [];
      mu.forEach((m) => {
        if (m.key === e.key) {
          // if (m.data) {
          options = [...options, m.data];
          // }
          // if (m.option) {
          //   options = [...options, ...m.option];
          // }
        }
      });
      rowsState.forEach((item) => {
        if (item.key === e.key) {
          rows = [...rows, item.data];
        }
      });
      colsState.forEach((item) => {
        if (item.key === e.key) {
          cols = [...cols, item.data];
        }
      });
      result = [...result, { ...e, option: options, rows, cols }];
    });
    if (!this.props.location.state.data.id) {
      return alert("id is required");
    }
    if (dfrom < dto) {
      fd.append("id", this.props.location.state.data.id);
      fd.append("name", values.title);
      fd.append("description", values.description);
      fd.append("form_type_id", values.form_type_id);
      fd.append("valid_from_date", dfrom);
      fd.append("valid_from_time", "00:00");
      fd.append("valid_to_time", "00:00");
      fd.append("valid_to_date", dto);
      fd.append("centre_id", values.centre_id);
      fd.append("class_id", values.class_id);
      fd.append("role", values.user_type);
      fd.append("img", file);
      fd.append("created_by", this.state.createdby);
      if (result.length > 0) {
        fd.append("form_settings", JSON.stringify(result));
      } else {
        fd.append(
          "form_settings",
          this.props.location.state.data.form_settings
        );
      }

      this.setState({ isloading: true });
      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api.post("/form/", fd, token).then((res) => {
            if (res.message === "Success") {
              this.props.dispatch(push(FORM_ROUTE));
              this.setState({ isloading: false });
            } else {
              alert(`${res.message}`);
              this.setState({ isloading: false });
            }
          });
        } catch (err) {
          console.log(err);
          this.setState({ isloading: false });
        }
      });
    } else {
      alert("invalid date");
    }
  };

  render() {
    console.log("render state", this.state);
    const { data } = this.props.location.state;

    return (
      <Grid className="fullHeight">
        <Grid.Row>
          <Grid.Column width={16}>
            <Dimmer active={this.state.isloading} inverted>
              <Loader size="big" />
            </Dimmer>
            <RoutingButton
              to={FORM_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <Grid className="form-subcontainer">
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className="form-inner-container">
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h2" floated="left">
                          Edit Form
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <div style={{ marginTop: 36 }}>
                      <Formik
                        initialValues={{
                          title: data.name,
                          description: data.description,
                          user_type: data.role,
                          image: data.img,
                          FD: "",
                          TD: "",
                          TF: "",
                          TT: "",
                          centre_id:
                            data.centre_id.length === 1
                              ? data.centre_id[0]
                              : "all",
                          class_id:
                            data.class_id.length === 1
                              ? data.class_id[0]
                              : "all",
                          form_type_id: data.form_type_id,
                          regDate: "",
                          scfacheckbox: "",
                          AddQuestion: JSON.parse(data.form_settings),
                          Dropdown: [],
                          Question: [""],
                        }}
                        onSubmit={this.handleSubmit}
                      >
                        {(formikProps) => {
                          return (
                            <Form>
                              <Grid>
                                <label className="formLabel">
                                  <b>Send to</b>
                                </label>
                                <Grid.Row style={{ marginLeft: 15 }}>
                                  <Field
                                    name="user_type"
                                    component={RenderRadioGroup}
                                    options={roles}
                                    // onClick={this.onChange}
                                    onChange={formikProps.handleChange(
                                      "user_type"
                                    )}
                                    validate={isRequired}
                                    isTouched={formikProps.touched.user_type}
                                    isError={formikProps.errors.user_type}
                                    value={formikProps.values.user_type}
                                  />
                                </Grid.Row>
                              </Grid>

                              <Grid>
                                <Grid.Row columns={2}>
                                  {localStorage.getItem("adminType") ==
                                  "centre" ? null : (
                                    <Grid.Column>
                                      <label className="formLabel">
                                        <b>Centre</b>
                                      </label>
                                      <div style={{ height: 10 }}></div>
                                      <Field
                                        name="centre_id"
                                        component={RenderDropdown}
                                        options={this.getAllCentres()}
                                        placeholder="Choose centre"
                                        // onClick={this.onChange}
                                        onChange={formikProps.handleChange(
                                          "centre_id"
                                        )}
                                        validate={isRequired}
                                        isTouched={
                                          formikProps.touched.centre_id
                                        }
                                        isError={formikProps.errors.centre_id}
                                        value={formikProps.values.centre_id}
                                      />
                                    </Grid.Column>
                                  )}

                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Class</b>
                                    </label>
                                    <div style={{ height: 10 }}></div>
                                    <Field
                                      name="class_id"
                                      placeholder="Choose class"
                                      component={RenderDropdown}
                                      options={this.getAllClasses(
                                        formikProps.values.centre_id
                                      )}
                                      // onClick={this.onChange}
                                      onChange={formikProps.handleChange(
                                        "class_id"
                                      )}
                                      isTouched={formikProps.touched.class_id}
                                      isError={formikProps.errors.class_id}
                                      value={formikProps.values.class_id}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>

                              {/* <Grid>
                                <Grid.Row columns={1}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Form Type</b>
                                    </label>
                                    <div style={{ height: 10 }}></div>
                                    <Field
                                      name="form_type_id"
                                      placeholder="Select form type"
                                      component={RenderDropdown}
                                      options={this.getFormTypes()}
                                      // onClick={this.onChange}
                                      onChange={formikProps.handleChange(
                                        "form_type_id"
                                      )}
                                      validate={isRequired}
                                      isTouched={
                                        formikProps.touched.form_type_id
                                      }
                                      isError={formikProps.errors.form_type_id}
                                      value={formikProps.values.form_type_id}
                                      disabled={true}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid> */}

                              <Grid>
                                <Grid.Row columns={2}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Period Date</b>
                                    </label>
                                    <Field
                                      name="FD"
                                      component={DonationDatePicker}
                                      onChange={(date) => this.FDchange(date)}
                                      // validate={isRequired}
                                      isTouched={formikProps.touched.FD}
                                      isError={formikProps.errors.FD}
                                      previewDate={this.state.datefrom}
                                    />
                                  </Grid.Column>
                                  <Grid.Column>
                                    <div style={{ height: 20 }}></div>
                                    <Field
                                      name="TD"
                                      component={DonationDateTo}
                                      onChange={(date) => this.TDchange(date)}
                                      // validate={isRequired}
                                      isTouched={formikProps.touched.TD}
                                      isError={formikProps.errors.TD}
                                      previewDateTo={this.state.dateto}
                                    />
                                  </Grid.Column>
                                  {/* </Grid.Row> */}
                                </Grid.Row>
                              </Grid>

                              {/* <Grid divided='vertically'> */}

                              {/* </Grid> */}

                              <label className="formLabel">
                                <b>Image</b>
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="image"
                                  component={RenderImage}
                                  //onChange={formikProps.handleChange('image')}
                                  onChange={this.onChange}
                                  isTouched={formikProps.touched.image}
                                  isError={formikProps.errors.image}
                                  previewUrl={this.state.previewUrl}
                                />
                              </div>

                              <label className="formLabel">
                                <b>Title</b>
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="title"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange("title")}
                                  validate={isRequired}
                                  placeholder="Travel Declaration Form"
                                  isTouched={formikProps.touched.title}
                                  isError={formikProps.errors.title}
                                  value={formikProps.values.title}
                                />
                              </div>

                              <label className="formLabel">
                                <b>Description</b>
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="description"
                                  component={RenderTextArea}
                                  onChange={formikProps.handleChange(
                                    "description"
                                  )}
                                  validate={isRequired}
                                  placeholder="Description"
                                  isTouched={formikProps.touched.description}
                                  isError={formikProps.errors.description}
                                  value={formikProps.values.description}
                                />
                              </div>

                              <FieldArray
                                name="AddQuestion"
                                render={(arrayHelpers) => (
                                  <div>
                                    {this.state.AddQuestion.map((Qu, index) => (
                                      <div
                                        key={index}
                                        className="form-question-container"
                                      >
                                        <div className="ph16">
                                          <Grid divided="vertically">
                                            <Grid.Row
                                              columns={3}
                                              verticalAlign="middle"
                                            >
                                              <Grid.Column width={10}>
                                                <Field
                                                  name="Question"
                                                  component={RenderInput}
                                                  onChange={(e) => {
                                                    let elements =
                                                      this.state.Element;
                                                    elements = elements.map(
                                                      (item) => {
                                                        if (
                                                          item.key === Qu.key
                                                        ) {
                                                          return {
                                                            ...item,
                                                            data: e.target
                                                              .value,
                                                          };
                                                        } else
                                                          return {
                                                            ...item,
                                                          };
                                                      }
                                                    );
                                                    this.setState({
                                                      Element: elements,
                                                    });
                                                  }}
                                                  placeholder={"Question text"}
                                                  istouched={
                                                    formikProps.touched.title
                                                  }
                                                  iserror={
                                                    formikProps.errors.title
                                                  }
                                                  value={
                                                    this.state.Element[index]
                                                      .data
                                                  }
                                                />
                                              </Grid.Column>
                                              <Grid.Column
                                                align="right"
                                                width={6}
                                                style={{ paddingLeft: 0 }}
                                              >
                                                <div style={{ marginTop: -18 }}>
                                                  <Field
                                                    name="centers"
                                                    component={FormDropDown}
                                                    onChange={this.handleAdd.bind(
                                                      this,
                                                      index
                                                    )}
                                                    text={Qu.type}
                                                    // validate={isRequired}
                                                    placeholder="Select"
                                                    isTouched={
                                                      formikProps.touched
                                                        .centers
                                                    }
                                                  />
                                                </div>
                                              </Grid.Column>
                                            </Grid.Row>
                                          </Grid>
                                        </div>
                                        {this.state.Element.map((et, idx) => {
                                          let isRequire = false;
                                          if (et["key"] === Qu["key"]) {
                                            isRequire =
                                              et.type === "Multiple choice"
                                                ? false
                                                : et.type === "Checkbox"
                                                ? false
                                                : et.type === "Dropdown"
                                                ? false
                                                : et.type ===
                                                  "Multiple choice grid"
                                                ? false
                                                : true;
                                          }
                                          if (
                                            isRequire &&
                                            Qu.type !== "Linear scale"
                                          ) {
                                            return (
                                              <div
                                                className="formMargin ph16"
                                                style={{
                                                  marginTop: -2,
                                                }}
                                              >
                                                <label
                                                  htmlFor=""
                                                  style={{
                                                    display: "inline-block",
                                                    marginBottom: 14,
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {et.type}
                                                </label>
                                                <Field
                                                  name="Question"
                                                  component={
                                                    et.key === Qu["key"] &&
                                                    DisabledTextInput
                                                  }
                                                  onChange={(e) =>
                                                    this.handleElement(
                                                      idx,
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder={"Answer text"}
                                                  istouched={
                                                    formikProps.touched.title
                                                  }
                                                  iserror={
                                                    formikProps.errors.title
                                                  }
                                                  title={et.type}
                                                  // value={et.data}
                                                  disabled={true}
                                                />
                                              </div>
                                            );
                                          }
                                        })}

                                        {Qu.type === "Multiple choice" ||
                                        Qu.type === "Checkbox" ||
                                        Qu.type === "Dropdown" ? (
                                          <div className="mb25 ph16 fs16 fw600">
                                            Ans
                                          </div>
                                        ) : null}
                                        {/* {console.log("state", this.state)}
                                        {console.log("qu", Qu)} */}
                                        {Qu.type === "Multiple choice grid" && (
                                          <div className="pl16 flex mb23">
                                            <div className="whalf">
                                              <div className="fw600 fs16 mb14">
                                                Rows
                                              </div>
                                              {this.state.Rows.map(
                                                (item, index) => {
                                                  // console.log("item", item);
                                                  // console.log("qu", Qu);
                                                  if (
                                                    item["key"] === Qu["key"]
                                                  ) {
                                                    return (
                                                      <div className="flex flex-align-center mb16">
                                                        <div className="w21">
                                                          {item.label}.
                                                        </div>
                                                        <div className="multi pr15">
                                                          <Field
                                                            name="Question"
                                                            component={
                                                              item.value
                                                            }
                                                            onChange={(e) =>
                                                              this.handleRows(
                                                                index,
                                                                e.target.value
                                                              )
                                                            }
                                                            placeholder="enter value"
                                                            value={item.data}
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                              <span
                                                className="text-blue fw600 pointer"
                                                onClick={() =>
                                                  this.handleRowsAdd(Qu)
                                                }
                                              >
                                                + Add
                                              </span>
                                            </div>
                                            <div className="whalf">
                                              <div className="fw600 fs16 mb14">
                                                Columns
                                              </div>
                                              {this.state.Columns.map(
                                                (item, index) => {
                                                  if (
                                                    item["key"] === Qu["key"]
                                                  ) {
                                                    return (
                                                      <div className="flex flex-align-center mb16">
                                                        <div className="w20 h20 circle border-light3"></div>
                                                        <div className="multi pl9 pr15">
                                                          <Field
                                                            name="Question"
                                                            component={
                                                              item.value
                                                            }
                                                            onChange={(e) =>
                                                              this.handleColumns(
                                                                index,
                                                                e.target.value
                                                              )
                                                            }
                                                            placeholder="enter value"
                                                            value={item.data}
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                              <span
                                                className="text-blue fw600 pointer"
                                                onClick={() =>
                                                  this.handleColumnsAdd(Qu)
                                                }
                                              >
                                                + Add
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        {/* Here Working... */}
                                        {this.state.Multi.map((map, index) => {
                                          if (map["key"] === Qu["key"]) {
                                            let shape = "";
                                            this.state.Element.forEach(
                                              (item) => {
                                                if (item.key === map["key"]) {
                                                  shape =
                                                    item.type ===
                                                    "Multiple choice"
                                                      ? "circle"
                                                      : item.type === "Checkbox"
                                                      ? "square"
                                                      : "";
                                                }
                                              }
                                            );
                                            // console.log("map", map);
                                            return (
                                              <div className="ph16 flex flex-align-center mb20">
                                                {shape !== "" && (
                                                  <div
                                                    className={`${shape} w20 h20 border-light mr13`}
                                                  ></div>
                                                )}

                                                <div className="multi">
                                                  <Field
                                                    name="Question"
                                                    component={
                                                      map.key === Qu["key"] &&
                                                      map.value
                                                    }
                                                    onChange={(e) =>
                                                      this.handleMulti(
                                                        index,
                                                        e.target.value
                                                      )
                                                    }
                                                    // validate={isRequired}
                                                    placeholder="enter value"
                                                    value={map.data}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}

                                        {/* for linear scale */}
                                        {Qu.type === "Linear scale" && (
                                          <div className="ph16 mt-7 mb32">
                                            <div className="flex flex-align-center">
                                              <div>
                                                <Dropdown
                                                  name="linear"
                                                  placeholder="Select"
                                                  selection
                                                  options={linearScaleOption1}
                                                  onChange={(e, data) =>
                                                    this.handleLinear(
                                                      index,
                                                      data.value,
                                                      "linear1"
                                                    )
                                                  }
                                                  style={{ width: 103 }}
                                                  fluid
                                                  value={
                                                    this.state.Element[index]
                                                      .linear1
                                                  }
                                                />
                                              </div>
                                              <div className="mh24 fs16 fw600">
                                                to
                                              </div>
                                              <div>
                                                <Dropdown
                                                  name="centers"
                                                  placeholder="Select"
                                                  selection
                                                  options={linearScaleOption2}
                                                  onChange={(e, data) =>
                                                    this.handleLinear(
                                                      index,
                                                      data.value,
                                                      "linear2"
                                                    )
                                                  }
                                                  style={{ width: 103 }}
                                                  fluid
                                                  value={
                                                    this.state.Element[index]
                                                      .linear2
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="flex mt19 flex-align-center">
                                              <div className="mr8 text-gray">
                                                {
                                                  this.state.Element[index]
                                                    .linear1
                                                }
                                              </div>
                                              <div className="multi">
                                                <Input
                                                  fluid
                                                  placeholder="Label (Optional)"
                                                  onChange={(e, data) =>
                                                    this.handleLinearLabel(
                                                      index,
                                                      data.value,
                                                      "label1"
                                                    )
                                                  }
                                                  value={
                                                    this.state.Element[index]
                                                      .label1
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="flex mt16 flex-align-center">
                                              <div className="mr8 text-gray">
                                                {
                                                  this.state.Element[index]
                                                    .linear2
                                                }
                                              </div>
                                              <div className="multi">
                                                <Input
                                                  fluid
                                                  placeholder="Label (Optional)"
                                                  onChange={(e, data) =>
                                                    this.handleLinearLabel(
                                                      index,
                                                      data.value,
                                                      "label2"
                                                    )
                                                  }
                                                  value={
                                                    this.state.Element[index]
                                                      .label2
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                        <div className="ph16 form-question-mandatory-container">
                                          <div className="flex flex-align-center">
                                            <input
                                              type="checkbox"
                                              className="w20 h20 mr20 pointer"
                                              checked={
                                                this.state.Element[index] !==
                                                  undefined &&
                                                this.state.Element[index]
                                                  .mandatory === true
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                let elements =
                                                  this.state.Element;
                                                elements = elements.map(
                                                  (item) => {
                                                    if (item.key === Qu.key) {
                                                      let manda =
                                                        !item.mandatory;

                                                      return {
                                                        ...item,
                                                        mandatory: manda,
                                                      };
                                                    } else
                                                      return {
                                                        ...item,
                                                      };
                                                  }
                                                );

                                                this.setState({
                                                  Element: elements,
                                                });
                                              }}
                                            />
                                            <span className="fs16 fw600">
                                              Require a response in each row
                                            </span>
                                          </div>
                                          <div
                                            className={
                                              index === 0 &&
                                              this.state.AddQuestion.length ===
                                                1
                                                ? ""
                                                : "pointer"
                                            }
                                            style={{ color: "#4075ff" }}
                                            onClick={() => {
                                              if (
                                                index === 0 &&
                                                this.state.AddQuestion
                                                  .length === 1
                                              )
                                                return;
                                              this.handleRemove(Qu.key, index);
                                            }}
                                          >
                                            <Icon
                                              name="trash alternate outline"
                                              size="large"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              />
                              {
                                this.props.location.state.data.submitted_count == 0 && <div className="form-question-add-link-container">
                                <div></div>
                                <div
                                  className="form-question-add-link"
                                  onClick={() => {
                                    this.handleAdd(0, "1");
                                  }}
                                >
                                  + Add Question
                                </div>
                              </div>
                              }
                              <div className="fullWidthCenterAlign">
                                <Button
                                  content="Publish"
                                  primary
                                  size="large"
                                  type="submit"
                                  className="submitBtn"
                                  loading={this.state.isloading}
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    centerclasses: state.centersClasses.centers_classes.data,
    formtypes: state.formtypeState.formtypes,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
  getFormType: () => dispatch(getFormType()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FormEdit);
