import { CLEAR_STUDENT_LISTS, FETCH_STUDENT_LISTS,FETCH_STUDENT_LISTS_FAILURE,FETCH_STUDENT_LISTS_SUCCESS, } from '../actions/studentlists';

const initialState = {
  studentlists: [],
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_STUDENT_LISTS:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_STUDENT_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        studentlists: action.data
      };
    case FETCH_STUDENT_LISTS_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        studentlists: []
      };
    case CLEAR_STUDENT_LISTS:
      return initialState;
    default:
      return state;
  }
}