import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_DONOR = "FETCH_DONOR";
export const FETCH_DONOR_SUCCESS = "FETCH_DONOR_SUCCESS";
export const FETCH_DONOR_FAILURE = "FETCH_DONOR_FAILURE";

export const fetchDonor = (donation_id) => ({
  type: FETCH_DONOR,
  payload: donation_id,
});

export const fetchDonorSuccess = (data, donorLists) => ({
  type: FETCH_DONOR_SUCCESS,
  data,
});

export const fetchDonorFailure = () => ({
  type: FETCH_DONOR_FAILURE,
});

export const getAllDonorlist = (donation_id) => {
  return (dispatch) => {
    dispatch(fetchDonor());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/donation",
            { filtered_by: "donation_id", id: donation_id },
            token
          )
          .then((res) => {
            let donorLists = [];
            // console.log("res", res);
            // res.data.donors.forEach(function (obj) {
            //   var id = obj.parent_id;

            //   if (!this[id]) donorLists.push((this[id] = obj));
            //   else this[id].amount += obj.amount;
            // }, Object.create(null));

            dispatch(fetchDonorSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchDonorFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
