import {
  FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_SUCCESS,
  FETCH_SCHEDULES_CALENDAR_VIEW,
  FETCH_SCHEDULES_CALENDAR_VIEW_SUCCESS,
  FETCH_SCHEDULES_CALENDAR_VIEW_FAILURE,
} from "../actions";

const initialState = {
  schedulescalendarViews: [],
  smallschedulescalendarViews: [],
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULES_CALENDAR_VIEW:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_SCHEDULES_CALENDAR_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        schedulescalendarViews: action.data,
      };
    case FETCH_SCHEDULES_CALENDAR_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        schedulescalendarViews: [],
      };
    case FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        smallschedulescalendarViews: action.data.data,
      };
    default:
      return state;
  }
};
