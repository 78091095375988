import React, { Component } from "react";
import { Grid, Message } from "semantic-ui-react";
import { Field } from "formik";

class RenderMultipleCheckboxGroup extends Component {
  render() {
    const { field, selected, options } = this.props;
    const selectedVal =
      field.value || selected || (options[0] !== undefined && options[0].value);
    return (
      <Grid>
        {this.props.options.map((data, ix) => (
          <Grid.Column key={ix}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Field
                style={{ width: 24, height: 24, cursor: "pointer" }}
                type="checkbox"
                name="centre_ids"
                value={data.value}
              />
              <span style={{ marginLeft: 6 }}>{data.label}</span>
            </div>
          </Grid.Column>
        ))}
        {this.props.isError && this.props.isTouched && (
          <Message visible error content={this.props.isError} />
        )}
      </Grid>
    );
  }
}
export default RenderMultipleCheckboxGroup;
