import React from "react";
import { Input, Message } from "semantic-ui-react";

const RenderInputCentre = (props, { ...form }) => {
  return (
    <div
      style={{
        marginBottom: 20,
        lineHeight: 40,
      }}
    >
      <Input
        textAlign={"center"}
        onChange={props.onChange}
        placeholder={props.placeholder}
        type={props.type}
        fluid
        size="small"
        value={props.value}
        min={props.min}
        className={props.styles && "range_input"}
      />
      {props.isError && props.isTouched && (
        <Message visible error content={props.isError} />
      )}
    </div>
  );
};

export default RenderInputCentre;
