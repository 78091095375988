import { FETCH_FORM_LIST,FETCH_FORM_LIST_FAILURE,FETCH_FORM_LIST_SUCCESS } from '../actions/formlist';

const initialState = {
  formlists: [],
  nextUrl:'',
  total_pages:0,
  is_loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_FORM_LIST:
      return {
        ...state,
        is_loading: true,
        err: null,
        haveData: false
      };
    case FETCH_FORM_LIST_SUCCESS:
      return {
        ...state,
        is_loading: false,   
        haveData: true,     
        formlists: action.data,
        nextUrl:action.next,
        total_pages:action.total_pages,
      };
    case FETCH_FORM_LIST_FAILURE:
      return {
        ...state,
        is_loading: false,
        haveData: false,
        err: action.error,
        formlists: [],
        nextUrl:'',
        total_pages:0,
      };
    default:
      return state;
  }
}