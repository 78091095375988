import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_MERCHANDISE_TYPE = "FETCH_MERCHANDISE_TYPE";
export const FETCH_MERCHANDISE_TYPE_SUCCESS = "FETCH_MERCHANDISE_TYPE_SUCCESS";
export const FETCH_MERCHANDISE_TYPE_FAILURE = "FETCH_MERCHANDISE_TYPE_FAILURE";
export const CLEAR_MERCHANDISE_TYPE = "CLEAR_MERCHANDISE_TYPE";

export const fetchMerchandise = (id) => ({
  type: FETCH_MERCHANDISE_TYPE,
  id,
});

export const fetchMerchandiseSuccess = (data) => ({
  type: FETCH_MERCHANDISE_TYPE_SUCCESS,
  data,
});

export const fetchMerchandiseFailure = () => ({
  type: FETCH_MERCHANDISE_TYPE_FAILURE,
});

export const clearMerchandise = () => ({
  type: CLEAR_MERCHANDISE_TYPE,
});

export const getMerchandiseType = (id) => {
  return (dispatch) => {
    dispatch(fetchMerchandise());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/merchandise/types/", { id: id }, token)
          .then((res) => {
            dispatch(fetchMerchandiseSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchMerchandiseFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
