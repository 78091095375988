import React, { Component } from "react";
import { Grid, Button, Modal } from "semantic-ui-react";
import { CALENDAR_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import defaultimage from "../../assets/images/defaultimage.png";
import ScheduleForm from "./ScheduleForm";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import api from "../../config/api";
import Exclamation from "../../assets/images/exclamation.png";
import {
  getCentersClasses,
  getScheduleColors,
  getScheduleTypes,
} from "../../store/actions";
import moment from "moment";
import { firebase } from "../../config/firebase";
import { months, days, types, dateTypes } from "../custom.js";

let meal_Index = 0;
let mealList = "";
let centre_Index = 0;
let class_ids = "";
let centres_ids = "";

class ScheduleCreate extends Component {
  state = {
    previewUrl: defaultimage,
    radioList: [
      {
        value: "morningshift",
        label: "Morning shift",
      },
      {
        value: "nightshift",
        label: "Night shift",
      },
      {
        value: "fullday",
        label: "Full day",
      },
    ],
    sessions: [
      { value: "morning", label: "Morning Session" },
      { value: "night", label: "Night Session" },
      { value: "full", label: "Full Session" },
    ],
    type: [
      { value: "subject", label: "Subject" },
      { value: "meal", label: "Meal" },
      { value: "centre_closed", label: "Centre Closed" },
    ],
    centre_id: "",
    from_time: new Date(),
    to_time: new Date(),
    Loading: false,
    modal: false,
    formikValues: null,
    mealVlaue: null,
    duplicateModal: false,
    duplicateMessage: "",
    dateCheck: false,
    centreClosed: false,
    centreClosedMgs: "",
  };

  componentDidMount() {
    this.props.getCentersClasses();
    this.props.getScheduleColors();
    this.props.getScheduleTypes();
  }

  AddScheduleItem = (values, meal_list) => {
    if (meal_list.length > meal_Index) {
      let mealData = new FormData();
      mealData.append("name", meal_list[meal_Index].title);
      mealData.append("img_url", meal_list[meal_Index].img);
      mealData.append("thumbnail", meal_list[meal_Index].img);

      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api.post("/schedules/schedule_item/", mealData, token).then((res) => {
            if (res.message === "success") {
              mealList += (mealList === "" ? "" : ",") + res.data.id;
              meal_Index = meal_Index + 1;
              this.AddScheduleItem(values, meal_list);
            } else {
              alert(`${res.message}`);
            }
          });
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      let mealData = new FormData();
      mealData.append("name", values.meal_title);
      mealData.append("img_url", values.meal_img);
      mealData.append("thumbnail", values.meal_img);

      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api.post("/schedules/schedule_item/", mealData, token).then((res) => {
            if (res.message === "success") {
              mealList += (mealList === "" ? "" : ",") + res.data.id;
              this.centreClassHandler(values, meal_list);
            } else {
              alert(`${res.message}`);
            }
          });
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  AddSchedule = (values) => {
    // const centre = this.props.centerclasses.filter(
    //   (center) => center.id === values.centre_ids
    // );
    // let class_ids = [];

    // values.class_ids.forEach((class_id) => {
    //   const temp_class_ids = centre[0].class.filter(
    //     (centre_class) => centre_class.name === class_id
    //   );
    //   class_ids.push(temp_class_ids[0].id);
    // });
    // values.class_ids = class_ids;
    // let center = this.props.centerclasses.find(
    //   (data) => data.id === values.centre_ids
    // );
    // let classID = center.class.find((data) => data.name === values.class_ids);
    //const {from_time, to_time} = this.state;
    // values.centre_ids = this.state.centre_id;
    // formdata.append("class_ids", classID.id);
    values.image = this.state.previewUrl;
    let formdata = new FormData();
    values.scfa = values.scfa === "true" ? 1 : 0;
    this.setState({
      isSaving: true,
      hasError: false,
    });
    formdata.append("centre_ids", centres_ids);
    formdata.append("class_ids", class_ids);
    if (values.dateTypes === "routine") {
      formdata.append("occurrence", values.dateTypes);
      formdata.append("days", values.days);
      formdata.append("months", values.months);
      formdata.append(
        "from_time",
        values.from_time.getHours() +
          ":" +
          values.from_time.getMinutes() +
          ":" +
          values.from_time.getSeconds()
      );
      formdata.append(
        "to_time",
        values.to_time.getHours() +
          ":" +
          values.to_time.getMinutes() +
          ":" +
          values.to_time.getSeconds()
      );
    }
    if (values.dateTypes === "specific_time") {
      formdata.append("occurrence", values.dateTypes);

      formdata.append(
        "from_time",
        values.specific_time_from.getHours() +
          ":" +
          values.specific_time_from.getMinutes() +
          ":" +
          values.specific_time_from.getSeconds()
      );
      formdata.append(
        "to_time",
        values.specific_time_to.getHours() +
          ":" +
          values.specific_time_to.getMinutes() +
          ":" +
          values.specific_time_to.getSeconds()
      );
      // formdata.append(
      //   "from_date",
      //   moment(values.specific_from).format("YYYY-MM-DD")
      // );
      // formdata.append(
      //   "to_date",
      //   moment(values.specific_to).format("YYYY-MM-DD")
      // );
      formdata.append("date_lists", values.specific_date.toString());
    }
    formdata.append(
      "title",
      values.type === "7BFBdPuRsBkFoxlDnqaP" ? "" : values.title
    );
    if (values.type === "7BFBdPuRsBkFoxlDnqaP") {
      formdata.append("description", values.description);
    }
    formdata.append("schedule_type_id", values.type);
    if (values.type === "V6tRBBDYhArEcVVjY9oT") {
      formdata.append(
        "icon_img",
        "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yMjAyMS0wNi0wM1QxMDoyMToyN1o=.png"
      );
    }
    if (values.type !== "V6tRBBDYhArEcVVjY9oT") {
      formdata.append("icon_img", values.icon_img);
    }
    formdata.append("scfa", values.scfa);
    if (values.type === "V6tRBBDYhArEcVVjY9oT") {
      formdata.append("schedule_item_ids", mealList);
    }
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        api.post("/schedules/schedule/", formdata, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              isSaving: false,
              hasError: false,
              Loading: false,
            });
            this.props.dispatch(push(CALENDAR_ROUTE));
          } else if (
            res.message ===
            "from_date and from_time should be later than the current datetime."
          ) {
            this.setState({
              isSaving: false,
              hasError: true,
              Loading: false,
              dateCheck: true,
            });
          } else if (res.message.includes("centre closure")) {
            this.setState({
              isSaving: false,
              hasError: true,
              Loading: false,
              centreClosed: true,
              centreClosedMgs: res.message,
            });
          } else {
            this.setState({
              isSaving: false,
              hasError: true,
              Loading: false,
              duplicateModal: true,
              duplicateMessage: res.message,
            });
            // alert(`${res.message}`);
            // window.location.reload();
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  centreClassHandler = (values, meal_list) => {
    if (values.centre_ids.length > centre_Index) {
      const centre = this.props.centerclasses.find(
        (centre) => centre.id === values.centre_ids[centre_Index]
      );
      if (centre) {
        values.class_ids.forEach((classes) => {
          const find_class = centre.class.find(
            (class_find) => class_find.name === classes
          );
          class_ids += (class_ids === "" ? "" : ",") + find_class.id;
        });
        centre_Index = centre_Index + 1;
        this.centreClassHandler(values, meal_list);
      }
    } else {
      values.centre_ids.forEach((data) => {
        centres_ids += (centres_ids === "" ? "" : ",") + data;
      });
      if (values.type === "7BFBdPuRsBkFoxlDnqaP") {
        this.centreClosureCheck(values, meal_list);
      } else {
        this.AddSchedule(values, meal_list);
      }
    }
  };

  centreClosureCheck = (values, meal_list) => {
    let formData = new FormData();
    formData.append("centre_ids", centres_ids);
    formData.append("class_ids", class_ids);
    if (values.dateTypes === "routine") {
      formData.append("months", values.months);
      formData.append("days", values.days);
    } else {
      formData.append("date_lists", values.specific_date.toString());
      // formData.append(
      //   "from_date",
      //   moment(values.specific_from).format("YYYY-MM-DD")
      // );
      // formData.append(
      //   "to_date",
      //   moment(values.specific_to).format("YYYY-MM-DD")
      // );
    }
    formData.append("schedule_type_id", values.type);
    formData.append("occurrence", values.dateTypes);
    formData.append("description", values.description);

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        api.post("/schedules/check_closure", formData, token).then((res) => {
          if (
            res.message.has_schedules === true &&
            res.message.existing_closure === true
          ) {
            this.setState({ closureExistModal: true });
          } else if (
            res.message.has_schedules === true &&
            res.message.existing_closure === false
          ) {
            this.setState({
              closureConfirm: true,
              formikValues: values,
              mealVlaue: meal_list,
            });
          } else {
            this.AddSchedule(values, meal_list);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  handleSubmit = (values, meal_list) => {
    this.setState({ Loading: true });
    class_ids = "";
    centres_ids = "";
    centre_Index = 0;
    mealList = "";
    meal_Index = 0;
    if (values.dateTypes === "routine") {
      if (
        values.centre_ids !== "" &&
        values.class_ids !== "" &&
        values.days !== "" &&
        values.months !== "" &&
        values.type !== "" &&
        values.to_time !== ""
      ) {
        if (values.type === "V6tRBBDYhArEcVVjY9oT") {
          if (meal_list.length > 0 && values.title !== "") {
            this.AddScheduleItem(values, meal_list);
          } else if (meal_list.length === 0 && values.title !== "") {
            if (values.meal_img !== "" && values.meal_title !== "") {
              let mealData = new FormData();
              mealData.append("name", values.meal_title);
              mealData.append("img_url", values.meal_img);
              mealData.append("thumbnail", values.meal_img);

              return firebase.reloadAuth().then((res) => {
                try {
                  const token = res.ya === null ? res.za : res.ya;

                  api
                    .post("/schedules/schedule_item/", mealData, token)
                    .then((res) => {
                      if (res.message === "success") {
                        mealList += (mealList === "" ? "" : ",") + res.data.id;
                        this.centreClassHandler(values, meal_list);
                      } else {
                        alert(`${res.message}`);
                      }
                    });
                } catch (err) {
                  console.log(err);
                }
              });
            } else {
              this.setState({ Loading: false, modal: true });
            }
          } else {
            this.setState({ Loading: false, modal: true });
          }
        } else if (values.type === "7BFBdPuRsBkFoxlDnqaP") {
          this.centreClassHandler(values, meal_list);
        } else {
          if (values.icon_img !== "" && values.title !== "") {
            this.centreClassHandler(values, meal_list);
          } else {
            this.setState({ Loading: false, modal: true });
          }
        }
      } else {
        this.setState({ Loading: false, modal: true });
      }
    } else {
      if (
        values.centre_ids !== "" &&
        values.class_ids !== "" &&
        values.specific_date !== "" &&
        values.specific_time_from !== "" &&
        values.specific_time_to !== ""
      ) {
        if (values.type === "V6tRBBDYhArEcVVjY9oT") {
          if (meal_list.length > 0 && values.title !== "") {
            this.AddScheduleItem(values, meal_list);
          } else if (meal_list.length === 0 && values.title !== "") {
            if (values.meal_img !== "" && values.meal_title !== "") {
              let mealData = new FormData();
              mealData.append("name", values.meal_title);
              mealData.append("img_url", values.meal_img);
              mealData.append("thumbnail", values.meal_img);

              return firebase.reloadAuth().then((res) => {
                try {
                  const token = res.ya === null ? res.za : res.ya;

                  api
                    .post("/schedules/schedule_item/", mealData, token)
                    .then((res) => {
                      if (res.message === "success") {
                        mealList += (mealList === "" ? "" : ",") + res.data.id;
                        this.centreClassHandler(values, meal_list);
                      } else {
                        alert(`${res.message}`);
                      }
                    });
                } catch (err) {
                  console.log(err);
                }
              });
            } else {
              this.setState({ Loading: false, modal: true });
            }
          } else {
            this.setState({ Loading: false, modal: true });
          }
        } else if (values.type === "7BFBdPuRsBkFoxlDnqaP") {
          this.centreClassHandler(values, meal_list);
        } else {
          if (values.icon_img !== "" && values.title !== "") {
            this.centreClassHandler(values, meal_list);
          } else {
            this.setState({ Loading: false, modal: true });
          }
        }
      } else {
        this.setState({ Loading: false, modal: true });
      }
    }
  };

  centreClosuerConfirm = () => {
    this.setState({ closureConfirm: false });
    this.AddSchedule(this.state.formikValues, this.state.mealVlaue);
  };

  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  getcentreId = (value) => {
    this.setState({
      centre_id: value,
    });
  };

  getfromTime = (from_time) => {
    let fromTime = moment(from_time).format("hh:mm:ss");
    this.setState({
      from_time: from_time,
    });
  };

  gettoTime = (to_time) => {
    let toTime = moment(to_time).format("hh:mm:ss");
    this.setState({
      to_time: to_time,
    });
  };

  render() {
    const {
      centerclasses = [],
      scheduleColors = [],
      scheduletypes = [],
    } = this.props;
    let centers = [];
    centerclasses.map((data) => {
      centers.push({
        key: data.id,
        label: data.name,
        value: data.id,
      });
    });
    let type = [];
    const typeSort = scheduletypes.sort((a, b) => {
      return b.name.localeCompare(a.name);
    });
    typeSort.map((data) => {
      type.push({
        key: data.id,
        label: data.name,
        value: data.id,
      });
    });
    let goldObj = {},
      classes = [];
    goldObj = centerclasses.find(
      (result) => result.id === this.state.centre_id || centers[0].value
    );
    goldObj !== undefined &&
      goldObj.class.map((data) => {
        classes.push({
          key: data.id,
          label: data.name,
          value: data.name,
        });
      });
    return (
      <Grid className="fullHeight">
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={CALENDAR_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <Modal
              dimmer={"blurring"}
              open={this.state.closureExistModal}
              style={{ width: "25%" }}
              onClose={() =>
                this.setState({ closureExistModal: false, Loading: false })
              }
            >
              <Modal.Header>Duplicate Centre Closure!</Modal.Header>
              <Modal.Content>
                Choose another date. This date is already exist!
              </Modal.Content>
              <Modal.Actions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "120px",
                    borderRadius: "20px",
                    backgroundColor: "#4075ff",
                    marginLeft: "0px",
                  }}
                  positive
                  onClick={() =>
                    this.setState({ closureExistModal: false, Loading: false })
                  }
                >
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              dimmer={"blurring"}
              open={this.state.centreClosed}
              style={{ width: "25%" }}
              onClose={() =>
                this.setState({
                  centreClosed: false,
                  Loading: false,
                  centreClosedMgs: "",
                })
              }
            >
              <Modal.Header>Centre closure existed!</Modal.Header>
              <Modal.Content>
                You cannot create with this date! {this.state.centreClosedMgs}
              </Modal.Content>
              <Modal.Actions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "120px",
                    borderRadius: "20px",
                    backgroundColor: "#4075ff",
                    marginLeft: "0px",
                  }}
                  positive
                  onClick={() =>
                    this.setState({
                      centreClosed: false,
                      Loading: false,
                      centreClosedMgs: "",
                    })
                  }
                >
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              dimmer={"blurring"}
              open={this.state.dateCheck}
              style={{ width: "25%" }}
              onClose={() =>
                this.setState({ dateCheck: false, Loading: false })
              }
            >
              <Modal.Header>Schedule creation is not successful!</Modal.Header>
              <Modal.Content>
                From date should be later than today date! Cannot create
                schedule with today date!
              </Modal.Content>
              <Modal.Actions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "120px",
                    borderRadius: "20px",
                    backgroundColor: "#4075ff",
                    marginLeft: "0px",
                  }}
                  positive
                  onClick={() =>
                    this.setState({ dateCheck: false, Loading: false })
                  }
                >
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              dimmer={"blurring"}
              open={this.state.duplicateModal}
              style={{ width: "25%" }}
              onClose={() =>
                this.setState({ duplicateModal: false, Loading: false })
              }
            >
              <Modal.Header>Duplicate schedule!</Modal.Header>
              <Modal.Content>
                There is an existing schedule, you have to delete it before
                could proceed to create new schedule.
              </Modal.Content>
              <Modal.Actions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  positive
                  style={{
                    width: "120px",
                    borderRadius: "20px",
                    backgroundColor: "#4075ff",
                    marginLeft: "0px",
                  }}
                  onClick={() =>
                    this.setState({ duplicateModal: false, Loading: false })
                  }
                >
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              dimmer={"blurring"}
              open={this.state.closureConfirm}
              style={{ width: "25%", height: 430 }}
              onClose={() =>
                this.setState({ closureConfirm: false, Loading: false })
              }
            >
              <img
                src={Exclamation}
                style={{
                  width: "20%",
                  textAlign: "center",
                  position: "relative",
                  left: "40%",
                  top: "50px",
                }}
              ></img>
              <h1
                style={{
                  textAlign: "center",
                  marginTop: "85px",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "24px",
                  lineHeight: "33px",
                }}
              >
                Are you sure?
              </h1>
              <p
                style={{
                  textAlign: "center",
                  width: "65%",
                  position: "relative",
                  left: "19%",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "19px",
                }}
              >
                There is existing schedules available for the day, please kindly
                help to confirm your action. You can't undo this action.
              </p>
              <Button
                positive
                style={{
                  position: "relative",
                  left: "12%",
                  width: "37%",
                  top: "50px",
                  borderRadius: "20px",
                  height: "48px",
                  backgroundColor: "#FFF",
                  border: "1px solid #4075FF",
                  color: "#4075FF",
                }}
                onClick={() =>
                  this.setState({ closureConfirm: false, Loading: false })
                }
              >
                Cancel
              </Button>
              <Button
                positive
                style={{
                  position: "relative",
                  left: "13%",
                  width: "37%",
                  top: "50px",
                  borderRadius: "20px",
                  height: "48px",
                  backgroundColor: "#4075FF",
                }}
                onClick={this.centreClosuerConfirm}
              >
                Yes
              </Button>
            </Modal>
            <Modal
              dimmer={"blurring"}
              open={this.state.modal}
              style={{ width: "25%" }}
              onClose={() => this.setState({ modal: false })}
            >
              <Modal.Header>All Fields are required!</Modal.Header>
              <Modal.Content>
                Please check the form again and fill all the fields!
              </Modal.Content>
              <Modal.Actions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "120px",
                    borderRadius: "20px",
                    backgroundColor: "#4075ff",
                    marginLeft: "0px",
                  }}
                  positive
                  onClick={() => this.setState({ modal: false })}
                >
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
            <ScheduleForm
              handleSubmit={this.handleSubmit}
              options={this.state.radioList}
              centers={centers}
              months={months}
              dateTypes={dateTypes}
              days={days}
              sessions={this.state.sessions}
              type={type}
              onChange={this.onChange}
              previewUrl={this.state.previewUrl}
              formTitle={"Add Schedule"}
              classes={classes}
              Loading={this.state.Loading}
              getcentreId={this.getcentreId}
              gettoTime={this.gettoTime}
              getfromTime={this.getfromTime}
              from_time={this.state.from_time}
              to_time={this.state.to_time}
              scheduleColors={scheduleColors}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  centerclasses: state.centersClasses.centers_classes.data,
  isloading: state.centersClasses.loading,
  haveError: state.centersClasses.err,
  haveData: state.centersClasses.haveData,
  scheduleColors: state.scheduleColors.schedulesColors.data,
  scheduletypes: state.scheduleTypes.scheduleTypes.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
  getScheduleColors: () => dispatch(getScheduleColors()),
  getScheduleTypes: () => dispatch(getScheduleTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCreate);
