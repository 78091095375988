import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_SCHEDULE_ITEM = "FETCH_SCHEDULE_ITEM";
export const FETCH_SCHEDULE_ITEM_SUCCESS = "FETCH_SCHEDULE_ITEM_SUCCESS";
export const FETCH_SCHEDULE_ITEM_FAILURE = "FETCH_SCHEDULE_ITEM_FAILURE";
export const CLEAR_SCHEDULE_ITEM = "CLEAR_SCHEDULE_ITEM";

export const fetchScheduleItem = (id) => ({
  type: FETCH_SCHEDULE_ITEM,
  id,
});

export const fetchScheduleItemSuccess = (data) => ({
  type: FETCH_SCHEDULE_ITEM_SUCCESS,
  data,
});

export const fetchScheduleItemFailure = () => ({
  type: FETCH_SCHEDULE_ITEM_FAILURE,
});

export const clearScheduleItem = () => ({
  type: CLEAR_SCHEDULE_ITEM,
});

export const getScheduleItem = (id) => {
  return (dispatch) => {
    dispatch(fetchScheduleItem());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/schedules/schedule_item/", { id: id }, token)
          .then((res) => {
            dispatch(fetchScheduleItemSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchScheduleItemFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
