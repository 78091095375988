import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Icon,
  Message,
  Dimmer,
  Loader,
  Pagination,
} from "semantic-ui-react";
import mark from "../../assets/images/mark.png";
import { getMoreUsers } from "../../store/actions";
import { connect } from "react-redux";

const RenderListBox = (props) => {
  return (
    <Segment className="listBox">
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#065CA4" }}>
              {" "}
              {props.name}
            </p>
          </Grid.Column>
          <Grid.Column width={4}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}>
              {props.school}
            </p>
          </Grid.Column>
          <Grid.Column width={3}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}>
              {props.dob == "undefined" ? (
                <div style={{ fontWeight: "bold", marginLeft: 25 }}>-</div>
              ) : props.dob ? (
                props.dob
              ) : (
                <div style={{ fontWeight: "bold", marginLeft: 25 }}>-</div>
              )}
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}>
              {/* {props.selectedCenter !== "All"
                ? props.selectedCenter
                : props.centername} */}
              {props.centre
                ? props.centre[0].name
                  ? props.centre[0].name
                  : "-"
                : "-"}
            </p>
          </Grid.Column>
          <Grid.Column width={1}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}>
              {props.scfa == 0 ? "-" : <img src={mark} />}
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <p
              className={
                props.active === 1 ? "status-acitve" : "status-inacitve"
              }
            >
              {props.active === 1 ? "Enable" : "Disable"}
            </p>
          </Grid.Column>

          <Grid.Column width={1} className="centerText">
            <div className="ui simple dropdown item">
              <Icon
                style={{ marginTop: 8, color: "gray" }}
                name="ellipsis vertical"
              />
              <div className="menu">
                {/* <div
                  onClick={() => props.handleModalShow(props.id)}
                  className="item"
                >
                  View
                  </div> */}
                <div
                  onClick={() =>
                    props.onSelect(props.id, localStorage.getItem("user_type"))
                  }
                  className="item"
                >
                  Edit
                </div>
                <div
                  className="item"
                  onClick={() => props.handleDisable(props.id, props.is_active)}
                >
                  {props.is_active == 1 ? "Disable" : "Enable"}
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
class StudentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }
  componentDidMount() {
    this.setState({
      activePage: 1,
    });
  }
  componentWillUpdate(nextProp, nextState) {
    if (nextState.activePage != localStorage.getItem("activePage")) {
      this.setState({
        activePage: 1,
      });
    }
  }
  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage - 1;
    this.setState({ activePage });
    localStorage.setItem("activePage", activePage);
    localStorage.setItem("nextPage", true);
    if (localStorage.getItem("adminType") == "centre") {
      this.props.getMoreUsers(
        page,
        this.props.values.user_type,
        localStorage.getItem("centre_Id"),
        this.props.values.class_id
      );
    } else {
      this.props.getMoreUsers(
        page,
        this.props.values.user_type,
        this.props.values.centre_id,
        this.props.values.class_id
      );
    }
  };

  render() {
    const { selectedCenter, isloading, haveData, firstloading } = this.props;
    return (
      <Grid>
        <Grid.Row>
          {!firstloading && (
            <Dimmer active={isloading} inverted>
              <Loader inverted />
            </Dimmer>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Grid className="activityTitle">
              <Grid.Row>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Name
                  </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Header as="h4" color="grey">
                    School
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    DOB
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h4" color="grey">
                    Centre
                  </Header>
                </Grid.Column>
                <Grid.Column width={1}>
                  <Header as="h4" color="grey">
                    SCFA
                  </Header>
                </Grid.Column>
                <Grid.Column width={1}>
                  <Header as="h4" color="grey">
                    Status
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {this.props.haveError ? (
              <Message
                header="Error loading users"
                content="Error loading users. Please try again."
                negative
              />
            ) : this.props.filtersUsers.length === 0 && !isloading ? (
              <Message
                header="No user found"
                content=" There is no user in this section."
                warning
              />
            ) : (
              haveData && (
                <>
                  {this.props.filtersUsers.map((usr) => (
                    <RenderListBox
                      {...usr}
                      key={usr.id}
                      onSelect={this.props.handleOnSelect}
                      centername={
                        this.props.centre_list !== undefined &&
                        this.props.centre_list.map((data) => {
                          if (
                            usr &&
                            usr.centre_id &&
                            data.key === usr.centre_id[0]
                          ) {
                            return data.text;
                          } else {
                            return data.text;
                          }
                        })
                      }
                      handleDisable={this.props.handleDisable}
                      active={usr.is_active}
                      handleModalShow={this.props.handleModalShow}
                      selectedCenter={selectedCenter}
                    />
                  ))}
                  <Pagination
                    activePage={this.state.activePage}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={3}
                    totalPages={this.props.usersTotalPage}
                    floated="right"
                    onPageChange={this.handlePaginationChange}
                  />
                </>
              )
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  usersNextpage: state.users.usersNextpage,
  usersTotalPage: state.users.usersTotalPage,
});

const mapDispatchToProps = (dispatch) => ({
  getMoreUsers: (nextPage, user_type, centre_id, class_id) =>
    dispatch(getMoreUsers(nextPage, user_type, centre_id, class_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentList);
