import api from "../../config/api";
import { firebase } from "../../config/firebase";
import dateFormat from "dateformat";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const fetchNotifications = (
  start_date,
  end_date,
  class_id,
  centre_id,
  role,
  size
) => ({
  type: FETCH_NOTIFICATIONS,
  payload: {
    start_date,
    end_date,
    centre_id,
    class_id,
    role,
    size,
  },
});

export const fetchNotificationsSuccess = (data) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  data,
});

export const fetchNotificationsFailure = () => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
});

export const getNotifications = (
  start_date,
  end_date,
  centre_id,
  class_id,
  role
) => {
  if (role === "All" || role === "") {
    role = "";
  }
  if (class_id === null || class_id === undefined || class_id === "") {
    if (class_id === "All" || class_id === "" || class_id === "All Class") {
      class_id = "";
    } else if (localStorage.getItem("class_id") === null) {
      class_id = "";
    } else {
      class_id = localStorage.getItem("class_id");
    }
  }
  if (localStorage.getItem("adminType") === "centre") {
    centre_id = localStorage.getItem("centre_Id");
  } else if (centre_id === null || centre_id === undefined) {
    if (centre_id === "All" || centre_id === "" || centre_id === "All Centre") {
      centre_id = "";
    } else if (localStorage.getItem("centre_id") === null) {
      centre_id = "";
    } else {
      centre_id = localStorage.getItem("centre_id");
    }
  }
  if (role == undefined || role == null) {
    if (localStorage.getItem("role") === null) {
      role = "";
    } else {
      role = localStorage.getItem("role");
    }
  }
  return (dispatch) => {
    dispatch(fetchNotifications());
    return firebase.reloadAuth().then((res) => {
      // console.log("local", localStorage.getItem("centre_Id"));
      // console.log("api >", {
      //   start_date: start_date,
      //   end_date: end_date,
      //   centre_id: centre_id,
      //   class_id: class_id,
      //   role: role,
      // });

      try {
        if (
          (start_date === undefined || start_date === null) &&
          (end_date === undefined || end_date === null)
        ) {
          let date = new Date();
          start_date =
            localStorage.getItem("start_date") !== null
              ? localStorage.getItem("start_date")
              : dateFormat(
                  new Date(date.getFullYear(), date.getMonth(), 0),
                  "yyyy-mm-dd"
                );
          end_date =
            localStorage.getItem("end_date") !== null
              ? localStorage.getItem("end_date")
              : dateFormat(
                  new Date(date.getFullYear(), date.getMonth() + 1, 0),
                  "yyyy-mm-dd"
                );
          //centre_id = localStorage.getItem('centre_id')!==null? localStorage.getItem('center_id'):"8XbfLsbWOz09wfRh2Lxb";
        }
        // console.log(start_date, end_date, centre_id, class_id, role);
        const token = res.ya === null ? res.za : res.ya;

        if (role === "admin") {
          api
            .get(
              "/notification/",
              {
                start_date: "" + start_date,
                end_date: "" + end_date,
                centre_id: "" + centre_id,
                role: "" + role,
              },
              token
            )
            .then((res) => {
              dispatch(fetchNotificationsSuccess(res));

              return res;
            })
            .catch((err) => dispatch(fetchNotificationsFailure(err)));
        } else if (class_id !== undefined) {
          api
            .get(
              "/notification/",
              {
                start_date: "" + start_date,
                end_date: "" + end_date,
                centre_id: "" + centre_id,
                class_id: "" + class_id,
                role: "" + role,
              },
              token
            )
            .then((res) => {
              dispatch(fetchNotificationsSuccess(res));

              return res;
            })
            .catch((err) => dispatch(fetchNotificationsFailure(err)));
        } else if (centre_id !== undefined) {
          api
            .get(
              "/notification/",
              {
                start_date: "" + start_date,
                end_date: "" + end_date,
                centre_id: "" + centre_id,
                role: "" + role,
              },
              token
            )
            .then((res) => {
              dispatch(fetchNotificationsSuccess(res));
              //console.log(res)
              return res;
            })
            .catch((err) => dispatch(fetchNotificationsFailure(err)));
        } else {
          api
            .get(
              "/notification/",
              {
                start_date: "" + start_date,
                end_date: "" + end_date,
                role: "" + role,
              },
              token
            )
            .then((res) => {
              dispatch(fetchNotificationsSuccess(res));
              //console.log(res)
              return res;
            })
            .catch((err) => dispatch(fetchNotificationsFailure(err)));
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
};
