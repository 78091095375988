import api from "../../config/api";
import { firebase } from "../../config/firebase";
import { clearStudent } from "./student";
export const FETCH_USERS_COUNT = "FETCH_USERS_COUNT";
export const FETCH_USERS_COUNT_SUCCESS = "FETCH_USERS_COUNT_SUCCESS";
export const FETCH_USERS_COUNT_FAILURE = "FETCH_USERS_COUNT_FAILURE";

export const fetchUsersCount = (centre_id, class_id) => ({
  type: FETCH_USERS_COUNT,
  payload: {
    centre_id,
    class_id,
  },
});

export const fetchUsersCountSuccess = (data) => ({
  type: FETCH_USERS_COUNT_SUCCESS,
  data,
});

export const fetchUsersCountFailure = () => ({
  type: FETCH_USERS_COUNT_FAILURE,
});

export const getUsersCount = (user_type, centre_id, class_id) => {
  if (centre_id == "all") {
    centre_id = "";
  }
  if (class_id == "All Classes") {
    class_id = "";
  }

  // console.log("centre", centre_id, class_id);
  return (dispatch) => {
    dispatch(fetchUsersCount());
    return firebase.reloadAuth().then((res) => {
      try {
        if (class_id === undefined && centre_id === null) {
          centre_id = "";
          class_id = "";
        }
        const token = res.ya === null ? res.za : res.ya;

        // api
        //   .get(
        //     "/user/count/",
        //     {
        //       class_id:
        //         "" +
        //         (user_type === "admin" ||
        //           user_type === "facilitator" ||
        //           class_id === undefined)
        //           ? ""
        //           : centre_id === "all"
        //           ? "all"
        //           : class_id,
        //       centre_id: "" + centre_id,
        //     },
        //     token
        //   )
        api
          .get(
            "/user/count/",
            {
              class_id: class_id,
              centre_id: centre_id,
            },
            token
          )
          .then((res) => {
            // console.log("resCount", res);
            dispatch(fetchUsersCountSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchUsersCountFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
