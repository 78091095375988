import React, { Component } from "react";
import {
  Grid,
  Segment,
  Header,
  Card,
  Feed,
  Image,
  Dimmer,
  Loader,
  Message,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { getMerchandiseDetail } from "../../store/actions";
import { MERCHANDISE_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import ic_staff from "../../assets/images/Vector.png";
import Photo_Default from "../../assets/images/Photo.png";

const RenderListBox = (props) => {
  const { merchandiseDetail } = props;
  return (
    <Segment
      className="listBox"
      style={{
        backgroundColor: "#F9FBFF",
        border: "1px solid #4075FF",
        borderRadius: "8px",
      }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={3}
            style={{
              overflow: "hidden",
              marginLeft: "10px",
              width: "95px",
              height: "112px",
              borderRadius: "8px",
              marginTop: "7px",
            }}
          >
            <Image
              src={
                merchandiseDetail.img[0] !== ""
                  ? merchandiseDetail.img[0]
                  : Photo_Default
              }
              style={{
                borderRadius: "8px",
                width: "95px",
                height: "112px",
                border: "1px solid #4075FF",
              }}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <p>{merchandiseDetail.name}</p>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header as="h4">{merchandiseDetail.amount}</Header>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header as="h4">${merchandiseDetail.price}</Header>
          </Grid.Column>
          <Grid.Column width={3}>
            <p
              className={
                merchandiseDetail.status === "In Stock"
                  ? "status-acitve"
                  : "status-inacitve"
              }
            >
              {merchandiseDetail.status}
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <Header as="h4">{merchandiseDetail.orders_count}</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const RenderOrderedListBox = (props) => {
  // console.log(props);
  return props.orderedlist.map((order) => {
    let status = order.status;
    const statusBackgroundColor =
      status.toLowerCase() === "paid"
        ? "#7CD227"
        : status.toLowerCase() === "cancelled"
        ? "#F66460"
        : status.toLowerCase() === "payment verification"
        ? "#4075FF"
        : "#4075FF";

    return (
      <Card style={{ borderRadius: 0 }}>
        <Card.Content>
          <Feed>
            <Feed.Event>
              <Feed.Label>
                <Image
                  src={order.ordered_by.img}
                  style={{
                    borderRadius: "100%",
                    width: "36px",
                    height: "36px",
                    border: "2px solid #4075FF",
                    marginTop: "10px",
                  }}
                />
              </Feed.Label>
              <Feed.Content
                className="recentOrder"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Feed.Summary>
                    {order.ordered_by.name}{" "}
                    <span style={{ color: "#F66460" }}>
                      - Order ID: {order.order_id}
                    </span>
                  </Feed.Summary>
                  <Feed.Date
                    content={order.created_at}
                    style={{ margin: "1px 0", fontSize: 12 }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      marginRight: 48,
                      borderRadius: 14,
                      padding: "3px 12px",
                      color: "white",
                      fontSize: 12,
                      backgroundColor: statusBackgroundColor,
                    }}
                  >
                    {order.status}
                  </div>
                  <div>
                    <label>X{order.amount}</label>
                  </div>
                </div>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Card.Content>
      </Card>
    );
  });
};

class MerchandiseDetail extends Component {
  componentDidMount() {
    this.props.getMerchandiseDetail();
  }

  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { merchandise, isloading, haveData } = this.props;

    return (
      <Grid className="fullHeight merchandise">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={MERCHANDISE_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            {haveData && (
              <Grid className="subContainer">
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div
                      style={{
                        width: window.innerWidth < 850 ? 660 : 760,
                        margin: "auto",
                      }}
                    >
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as="h2" floated="left">
                            {this.props.formTitle}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid className="listBoxTitle">
                        <Grid.Row>
                          <Grid.Column width={3} style={{ marginLeft: "30px" }}>
                            <Header as="h4" color="grey">
                              Image
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={4}
                            style={{ marginLeft: "-10px" }}
                          >
                            <Header as="h4" color="grey">
                              Item
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={2}
                            style={{ marginLeft: "-15px" }}
                          >
                            <Header as="h4" color="grey">
                              Stock Available
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={2}>
                            <Header as="h4" color="grey">
                              Price
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <Header as="h4" color="grey">
                              Status
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={1} style={{ paddingLeft: 0 }}>
                            <Header as="h4" color="grey">
                              Order
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <RenderListBox merchandiseDetail={merchandise} />
                      <Grid
                        style={{ marginTop: 30, marginBottom: 0 }}
                        className="listBoxTitle2"
                      >
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <p
                              style={{
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontSize: "18px",
                                fontFamily: "Open Sans",
                              }}
                            >
                              Ordered
                            </p>
                          </Grid.Column>
                          <Grid.Column
                            width={2}
                            style={{
                              position: "relative",
                              left: "14px",
                              top: "3px",
                            }}
                          >
                            <Image
                              src={ic_staff}
                              verticalAlign="middle"
                              className="sidebar-icon"
                              style={{ position: "relative", right: "12px" }}
                            />
                            <span>{merchandise.orders.length}</span>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      {merchandise.orders.length === 0 ? (
                        <Message
                          header="No orders found"
                          content="There is no order in this period."
                          warning
                        />
                      ) : (
                        <div className="multiContentListBox">
                          <RenderOrderedListBox
                            orderedlist={merchandise.orders}
                          />
                        </div>
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  merchandise: state.merchandise.merchandise.data,
  isloading: state.merchandise.loading,
  haveData: state.merchandise.haveData,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getMerchandiseDetail: (id) =>
    dispatch(getMerchandiseDetail(props.match.params.id, 10)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchandiseDetail);
