import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_EVENTS = "FETCH_EVENTS";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

export const fetchEvents = (start_date, end_date, filtered_by, reg_status) => ({
  type: FETCH_EVENTS,
  payload: {
    start_date,
    end_date,
    filtered_by,
    reg_status,
  },
});

export const fetchEventsSuccess = (data) => ({
  type: FETCH_EVENTS_SUCCESS,
  data,
});

export const fetchEventsFailure = (err) => ({
  type: FETCH_EVENTS_FAILURE,
  err,
});

export const getEvents = (start_date, end_date, filtered_by, reg_status) => {
  return (dispatch) => {
    dispatch(fetchEvents());
    return firebase.reloadAuth().then((res) => {
      try {
        // if ((start_date===undefined||start_date===null )&& (end_date===undefined||end_date===null) && (filtered_by===undefined||filtered_by===null)){
        //   let date= new Date()
        //   start_date =dateFormat( new Date(date.getFullYear(),date.getMonth(),1),"yyyy-mm-dd");
        //   end_date = dateFormat( new Date(date.getFullYear(),date.getMonth()+1,1),"yyyy-mm-dd");
        //   filtered_by ='admin_view'

        // }//https://asia-southeast2-morningstar-dev.cloudfunctions.net/events/
        // if (
        //   reg_status === undefined &&
        //   start_date === undefined &&
        //   end_date === undefined
        // ) {
        //   api
        //     .get("/events", null, res.za)
        //     .then((res) => {
        //       dispatch(fetchEventsSuccess(res));
        //       return res;
        //     })
        //     .catch((err) => dispatch(fetchEventsFailure(err)));
        // } else if (reg_status !== undefined || reg_status !== undefined) {
        //   // console.log(res.za);
        //   api
        //     .get(
        //       "/events",
        //       {
        //         from_date: "" + start_date,
        //         to_date: "" + end_date,
        //         filtered_by: "" + filtered_by,
        //         reg_status: "" + reg_status,
        //       },
        //       res.za
        //     )
        //     .then((res) => {
        //       dispatch(fetchEventsSuccess(res));
        //       return res;
        //     })
        //     .catch((err) => dispatch(fetchEventsFailure(err)));
        // } else if (start_date !== undefined && end_date !== undefined) {
        //   api
        //     .get(
        //       "/events",
        //       { from_date: "" + start_date, to_date: "" + end_date },
        //       res.za
        //     )
        //     .then((res) => {
        //       dispatch(fetchEventsSuccess(res));
        //       return res;
        //     })
        //     .catch((err) => dispatch(fetchEventsFailure(err)));
        // } else {
        //   api
        //     .get("/events", null, res.za)
        //     .then((res) => {
        //       dispatch(fetchEventsSuccess(res));
        //       return res;
        //     })
        //     .catch((err) => dispatch(fetchEventsFailure(err)));
        // }
        const token = res.ya === null ? res.za : res.ya;

        // console.log(token);

        api
          .get(
            "/events/",
            {
              from_date: start_date,
              to_date: end_date,
              filtered_by: filtered_by,
              reg_status: reg_status,
            },
            token
          )
          .then((res) => {
            dispatch(fetchEventsSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchEventsFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
