import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import RenderInput from "../../components/TextInput";
import { isRequired } from "../../validators";
import RenderLoader from "../../components/Loader";
import RenderImage from "../../components/Image";

class ScheduleItemForm extends Component {
  render() {
    const {
      initialValues,
      isEdit,
      isSaving,
      handleSubmit,
      handleUpdate,
    } = this.props;
    return (
      <div style={{ marginTop: 36 }}>
        <RenderLoader isloading={isSaving} />
        <Formik
          initialValues={
            isEdit
              ? initialValues
              : {
                  name: "",
                }
          }
          onSubmit={isEdit ? handleUpdate : handleSubmit}
        >
          {(formikProps) => {
            return (
              <Form>
                <label className="formLabel">
                  {isEdit ? "Edit" : "Create"} Schedule Item
                </label>
                <div className="formMargin">
                  <Field
                    name="name"
                    component={RenderInput}
                    onChange={formikProps.handleChange("name")}
                    validate={isRequired}
                    placeholder="Schedule Name"
                    isTouched={formikProps.touched.name}
                    isError={formikProps.errors.name}
                    value={formikProps.values.name}
                  />
                </div>

                <Grid divided="vertically">
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Field
                        name="img_url"
                        component={RenderImage}
                        onChange={this.props.onChangeImgurl}
                        accept="image/*"
                        previewUrl={
                          isEdit
                            ? this.props.previewUrlImg !== ""
                              ? this.props.previewUrlImg
                              : formikProps.values.img_url
                            : this.props.previewUrlImg
                        }
                      />
                    </Grid.Column>
                    {/*<Grid.Column>
                      <Field
                        name="thumbnail"
                        component={RenderImage}
                        onChange={this.props.onChangeThumbnail}
                        accept="image/*"
                        previewUrl={this.props.previewUrlThumbnail}
                        value={isEdit ? initialValues.thumbnail : this.props.previewUrlThumbnail}
                      />
                    </Grid.Column>*/}
                  </Grid.Row>
                </Grid>

                <br />
                <div className="fullWidthCenterAlign">
                  <Button
                    content="Submit"
                    primary
                    size="large"
                    type="submit"
                    className="submitBtn"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default ScheduleItemForm;
