import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Grid, Image } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import ic_calendar from "../assets/images/ic_calendar1.svg";

class DonationDateTo extends Component {
  state = {
    endDate: new Date(),
  };
  render() {
    const { onChange, value } = this.props;
    const previewDateTo =
      this.props.previewDateTo !== undefined ? this.props.previewDateTo : value;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div className="formMargin">
              <DatePicker
                selected={previewDateTo}
                onChange={onChange}
                selectsStart
                startDate={this.state.endDate}
                // dateFormat="MM/dd/yyyy"
                dateFormat="yyyy MMM dd"
              />
              <div className="input-iconnn">
                <h5>To</h5>
              </div>
              <div className="input-iconnnn">
                <Image src={ic_calendar}></Image>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default DonationDateTo;
