import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { ACTIVITY_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import defaultimage from "../../assets/images/defaultimage.png";
import ActivityForm from "./ActivityForm";
import { connect } from "react-redux";
import { getCentersClasses } from "../../store/actions";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import RenderLoader from "../../components/Loader";
import moment from "moment";

class ActivityCreate extends Component {
  state = {
    previewUrl: defaultimage,
    radioList: [
      {
        value: "morningshift",
        label: "Morning shift",
      },
      {
        value: "nightshift",
        label: "Night shift",
      },
      {
        value: "fullday",
        label: "Full day",
      },
    ],
    imgReq: true,
    EventTimeErrorMessage: true,
    fromTimeErrorMessage: false,
    toTimeErrorMessage: false,
    showRemoveImageIcon: false,
    showRemovePdfIcon: false,
    pdfFileCheck: false,
    pdfFile: null,
    defaultPdfFileName: "Upload PDF file",
    pdfFileTitle: "",
    submitLoading: false,
    regToDateErrorMessage: false,
  };

  componentDidMount() {
    this.props.getCentersClasses();
  }

  removeImageHandle = () => {
    this.setState({
      img_url: null,

      previewUrl: defaultimage,
      imgReq: true,
      showRemoveImageIcon: false,
    });
  };

  handleSubmit = (values) => {
    let errorCount = 0;
    if (this.state.pdfFile && this.state.pdfFile.type !== "application/pdf") {
      errorCount+=1;
      return this.setState({
        pdfFileCheck: true,
      });
    } else {
      this.setState({
        pdfFileCheck: false,
      });
    }

    if (!values.from_time) {
      errorCount+=1;
      return this.setState({
        fromTimeErrorMessage: true,
      });
    } else {
      this.setState({
        fromTimeErrorMessage: false,
      });
    }

    if (!values.to_time) {
      errorCount+=1;
      return this.setState({
        toTimeErrorMessage: true,
      });
    } else {
      this.setState({
        toTimeErrorMessage: false,
      });
    }

    if (values.from_time.getTime() > values.to_time.getTime()) {
      errorCount+=1;
      return this.setState({
        EventTimeErrorMessage: false,
      });
    } else {
      this.setState({
        EventTimeErrorMessage: true,
      });
    }
    if(values.event_date.getTime() < values.reg_to_date.getTime()){
      errorCount+=1;
      // return this.setState({
      //   regToDateErrorMessage: true,
      // })
    }
    if (!this.state.img_url) {
      errorCount+=1;
      return this.setState({
        imgReq: false,
      });
    } else {
      this.setState({
        imgReq: true,
        showRemoveImageIcon: false,
      });
    }
    if (values.max_number.includes("-")) {
      errorCount+=1;
      return alert("Max number of participant must be positive number. ");
    }
    if (errorCount>0) return 

    let formdata = new FormData();
    this.setState({
      submitLoading: true,
      isSaving: true,
      hasError: false,
    });

    let class_ids = [];

    const centre_ids = this.props.centerclasses.map((item) => item.id);

    if (values.centre_id === "all" && values.class_id !== "all") {
      this.props.centerclasses.forEach((item) => {
        item.class.forEach((element) => {
          if (element.name.toLowerCase() === values.class_id) {
            class_ids.push(element.id);
          }
        });
      });
      class_ids[0] = `[${class_ids[0]}`;
      class_ids[class_ids.length - 1] = `${class_ids[class_ids.length - 1]}]`;
    } else if (values.centre_id === "all" && values.class_id === "all") {
      this.props.centerclasses.forEach((item) => {
        item.class.forEach((element) => {
          class_ids.push(element.id);
        });
      });
    }

    formdata.append("title", values.title);
    formdata.append("description", values.description);
    formdata.append(
      "centre_ids",
      values.centre_id === "all"
        ? JSON.stringify(centre_ids)
        : [values.centre_id]
    );
    formdata.append(
      "class_ids",
      values.centre_id === "all" ? JSON.stringify(class_ids) : [values.class_id]
    );
    formdata.append("scfa", values.scfa === "true" ? 1 : 0);

    formdata.append(
      "img_url",
      values.img_url === "" || values.img_url == null
        ? this.state.img_url
        : values.img_url
    );
    formdata.append("event_file", this.state.pdfFile);
    formdata.append("event_file_name", this.state.pdfFileTitle.slice(0, -4));
    formdata.append(
      "reg_to_date",
      moment(values.reg_to_date).format("YYYY-MM-DD")
    );
    // values.register_link !== "" &&
    //   values.register_link !== undefined &&
    //   values.register_link !== null &&
    //   formdata.append("register_link", values.register_link);
    formdata.append("register_link", values.register_link);

    formdata.append("max_vacancy", values.max_number);
    formdata.append("date", moment(values.event_date).format("YYYY-MM-DD"));
    formdata.append("from_time", moment(values.from_time).format("HH:mm:ss"));
    formdata.append("to_time", moment(values.to_time).format("HH:mm:ss"));

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/events/event/", formdata, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              submitLoading: false,
              isSaving: false,
              hasError: false,
            });
            this.props.dispatch(push(ACTIVITY_ROUTE));
          } else {
            this.setState({
              submitLoading: false,
              isSaving: false,
              hasError: true,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  onChange = (event) => {
    let file = event.target.files[0];

    this.setState({
      img_url: file,
      previewUrl: URL.createObjectURL(file),
      imgReq: true,
      showRemoveImageIcon: true,
    });
  };

  onChangePdf = (e) => {
    let file = e.target.files[0];
    // console.log("file", file);
    if (file) {
      this.setState({
        pdfFile: file,
        defaultPdfFileName: file.name,
        showRemovePdfIcon: true,
        pdfFileTitle: file.name,
      });
    } else {
      this.setState({
        pdfFile: null,
        defaultPdfFileName: "Upload PDF file",
        pdfFileCheck: false,
        showRemovePdfIcon: false,
        pdfFileTitle: "",
      });
    }
  };

  deletePdf = () => {
    this.setState({
      pdfFile: null,
      defaultPdfFileName: "Upload PDF file",
      pdfFileCheck: false,
      showRemovePdfIcon: false,
      pdfFileTitle: "",
    });
  };

  onChangeTime = (getTime) => {
    let time = moment(getTime).format("hh:mm:ss");
    this.setState({
      time: time,
    });
  };

  render() {
    const { haveData, centerclasses = [], isloading } = this.props;
    const { isSaving } = this.state;
    let centers = [],
      classes = [],
      goldObj = {};
    centerclasses.map((data) => {
      centers.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    return (
      <Grid className="fullHeight">
        <RenderLoader isloading={this.state.submitLoading} size="big" />
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={ACTIVITY_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <ActivityForm
              handleSubmit={this.handleSubmit}
              options={this.state.radioList}
              onChange={this.onChange}
              previewUrl={this.state.previewUrl}
              img_url={this.state.img_url}
              formTitle={"Add New Event"}
              centers={centers}
              centerclasses={centerclasses}
              onChangeTime={this.onChangeTime}
              imgReq={this.state.imgReq}
              EventTimeErrorMessage={this.state.EventTimeErrorMessage}
              fromTimeErrorMessage={this.state.fromTimeErrorMessage}
              toTimeErrorMessage={this.state.toTimeErrorMessage}
              removeImageHandle={this.removeImageHandle}
              showRemoveImageIcon={this.state.showRemoveImageIcon}
              pdfFileCheck={this.state.pdfFileCheck}
              onChangePdf={this.onChangePdf}
              defaultPdfFileName={this.state.defaultPdfFileName}
              deletePdf={this.deletePdf}
              pdfFile={this.state.pdfFile}
              showRemovePdfIcon={this.state.showRemovePdfIcon}
              pdfFileTitle={this.state.pdfFileTitle}
              submitLoading={this.state.submitLoading}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  centerclasses: state.centersClasses.centers_classes.data,
  isloading: state.centersClasses.loading,
  haveData: state.centersClasses.haveData,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ActivityCreate);
