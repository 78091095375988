import React, { Component } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
// import RenderCheckbox from '../../components/CheckBox';
import RoutingButton from "../../components/RoutingButton";
import { DONATION_ROUTE } from "../../routes";
import DonationForm from "./DonationForm";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import { connect } from "react-redux";
import moment from "moment";
import ic_attach from "../../assets/images/ic_attach.svg";
import defaultimage from "../../assets/images/defaultimage.png";
import S3 from "react-aws-s3";
import base64encode from "@wmakeev/base64encode";

let dateFromSend = moment(new Date()).format("YYYY-MM-DD");
let dateToSend = "";
const config = {
  bucketName: "morning-star-dev",
  dirName: "videos",
  region: "ap-southeast-1",
  //DEV
  // accessKeyId: "AKIAQFC7EOAWNZBPI7M6",
  // secretAccessKey: "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",
  //QA
  // accessKeyId: "AKIAQFC7EOAWMK5PYOKQ",
  // secretAccessKey: "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",
  accessKeyId: "AKIAQFC7EOAWH7DDHKEY",
  secretAccessKey: "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",
};

class AddDonation extends Component {
  state = {
    media: [
      { value: "Image", label: "Image" },
      { value: "Video", label: "Video" },
    ],
    previewUrl: defaultimage,
    previewVideoUrl: null,
    previewDate: new Date(),
    previewDateTo: new Date(),
    timeFrom: "",
    timeTo: "",
    imgs: null,
    date: null,
    submitting: false,
    video: null,
  };

  handleSubmit = (values) => {
    if (this.state.imgs === null && this.state.video !== null) {
      // video shi yin
      const vName = this.state.video.name.split(".");
      const filename =
        vName[0] + "-" + moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
      const final_filename = base64encode(filename);
      console.log(final_filename);
      const VType = this.state.video.type.split("/");
      const VName = final_filename + "." + VType[1];
      let file = this.state.video;
      this.setState({ submitting: true });
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, VName)
        .then((data) => {
          console.log(data);
          this.setState(
            {
              video: `https://d2t2athllyh1ku.cloudfront.net/${data.key}`,
              submitting: true,
            },
            () => {
              if (values.Totalneeds.length > 6) {
                alert("Totalneeds should be 6 characters or less");
              } else if (this.state.previewDate < this.state.previewDateTo) {
                let formdata = new FormData();
                formdata.append("title", values.title);
                formdata.append("description", values.description);
                formdata.append("duration", "50");
                formdata.append("range", values.ranges);
                formdata.append("total_needs", values.Totalneeds);
                formdata.append("from_date", dateFromSend);
                formdata.append("to_date", dateToSend);
                formdata.append("from_time", "00:00:00");
                formdata.append("to_time", "00:00:00");
                formdata.append("video_url", this.state.video);

                console.log(...formdata);

                return firebase.reloadAuth().then((res) => {
                  try {
                    const token = res.ya === null ? res.za : res.ya;

                    api.post("/donation", formdata, token).then((res) => {
                      if (res.message === "success") {
                        this.setState({ submitting: false });
                        this.props.dispatch(push(DONATION_ROUTE));
                      } else {
                        alert(`${res.message}`);
                        this.setState({ submitting: false });
                      }
                    });
                  } catch (err) {
                    console.log(err);
                    this.setState({ submitting: false });
                  }
                });
              } else {
                alert("date to must greater than date from");
              }
            }
          );
        })
        .catch((err) => console.log(err));
    } else if (this.state.imgs !== null && this.state.video === null) {
      // image shi yin
      if (values.Totalneeds.length > 6) {
        alert("Totalneeds should be 6 characters or less");
      } else if (this.state.previewDate < this.state.previewDateTo) {
        let formdata = new FormData();
        formdata.append("title", values.title);
        formdata.append("description", values.description);
        formdata.append("duration", "50");
        formdata.append("range", values.ranges);
        formdata.append("total_needs", values.Totalneeds);
        formdata.append("from_date", dateFromSend);
        formdata.append("to_date", dateToSend);
        formdata.append("from_time", "00:00:00");
        formdata.append("to_time", "00:00:00");
        formdata.append(
          "img_url",
          values.link === "" ? this.state.imgs : values.link
        );

        return firebase.reloadAuth().then((res) => {
          try {
            const token = res.ya === null ? res.za : res.ya;

            api.post("/donation", formdata, token).then((res) => {
              if (res.message === "success") {
                this.setState({ submitting: false });
                this.props.dispatch(push(DONATION_ROUTE));
              } else {
                alert(`${res.message}`);
                this.setState({ submitting: false });
              }
            });
          } catch (err) {
            console.log(err);
            this.setState({ submitting: false });
          }
        });
      } else {
        alert("date to must greater than date from");
      }
    } else if (values.link !== "") {
      // link shi yin
      let formdata = new FormData();
      formdata.append("title", values.title);
      formdata.append("description", values.description);
      formdata.append("duration", "50");
      formdata.append("range", values.ranges);
      formdata.append("total_needs", values.Totalneeds);
      formdata.append("from_date", dateFromSend);
      formdata.append("to_date", dateToSend);
      formdata.append("from_time", "00:00:00");
      formdata.append("to_time", "00:00:00");
      formdata.append("img_url", values.link);

      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api.post("/donation", formdata, token).then((res) => {
            if (res.message === "success") {
              this.setState({ submitting: false });
              this.props.dispatch(push(DONATION_ROUTE));
            } else {
              alert(`${res.message}`);
              this.setState({ submitting: false });
            }
          });
        } catch (err) {
          console.log(err);
          this.setState({ submitting: false });
        }
      });
    } else {
      const vName = this.state.video.name.split(".");
      const filename =
        vName[0] + "-" + moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
      const final_filename = base64encode(filename);
      console.log(final_filename);
      const VType = this.state.video.type.split("/");
      const VName = final_filename + "." + VType[1];
      let file = this.state.video;
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, VName)
        .then((data) => {
          console.log(data);
          this.setState(
            {
              video: `https://d2t2athllyh1ku.cloudfront.net/${data.key}`,
            },
            () => {
              if (values.Totalneeds.length > 6) {
                alert("Totalneeds should be 6 characters or less");
              } else if (this.state.previewDate < this.state.previewDateTo) {
                let formdata = new FormData();
                formdata.append("title", values.title);
                formdata.append("description", values.description);
                formdata.append("duration", "50");
                formdata.append("range", values.ranges);
                formdata.append("total_needs", values.Totalneeds);
                formdata.append("from_date", dateFromSend);
                formdata.append("to_date", dateToSend);
                formdata.append("from_time", "00:00:00");
                formdata.append("to_time", "00:00:00");
                formdata.append(
                  "img_url",
                  values.link === "" ? this.state.imgs : values.link
                );
                formdata.append("video_url", this.state.video);

                console.log(...formdata);
                return firebase.reloadAuth().then((res) => {
                  try {
                    const token = res.ya === null ? res.za : res.ya;

                    api.post("/donation", formdata, token).then((res) => {
                      if (res.message === "success") {
                        this.setState({ submitting: false });
                        this.props.dispatch(push(DONATION_ROUTE));
                      } else {
                        alert(`${res.message}`);
                        this.setState({ submitting: false });
                      }
                    });
                  } catch (err) {
                    console.log(err);
                    this.setState({ submitting: false });
                  }
                });
              } else {
                alert("date to must greater than date from");
              }
            }
          );
        })
        .catch((err) => console.log(err));
    }
  };

  onChange = (event) => {
    console.log(event);
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    this.setState({ imgs: event.target.files[0] });
  };

  handleChange = (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({
        previewVideoUrl: URL.createObjectURL(event.target.files[0]),
        video: event.target.files[0],
      });
    }
  };

  onDateFromChange = (date) => {
    if (date < this.state.previewDateTo) {
      dateFromSend = moment(date).format("YYYY-MM-DD");
      this.setState({ previewDate: date });
    } else {
      alert("date from must be less than date to");
    }
  };
  onDateToChange = (date) => {
    if (date > this.state.previewDate) {
      dateToSend = moment(date).format("YYYY-MM-DD");
      this.setState({ previewDateTo: date });
    } else {
      alert("date to must be greater than date from");
    }
  };
  render() {
    return (
      <Grid className="fullHeight">
        <Grid.Row>
          <Grid.Column width={16}>
            <Dimmer active={this.state.submitting} inverted>
              <Loader size="big" />
            </Dimmer>
            <RoutingButton
              to={DONATION_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <DonationForm
              handleSubmit={this.handleSubmit}
              options={this.state.media}
              onChange={this.onChange}
              handleChange={this.handleChange}
              previewUrl={this.state.previewUrl}
              previewVideoUrl={this.state.previewVideoUrl}
              previewDate={this.state.previewDate}
              previewDateTo={this.state.previewDateTo}
              formtitle={"Add New Donation"}
              onDateChange={this.onDateFromChange}
              onDateToChange={this.onDateToChange}
              isEdit={false}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});
export default connect(mapDispatchToProps)(AddDonation);
