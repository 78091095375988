import {
  FETCH_MERCHANDISE_ALL,
  FETCH_MERCHANDISE_ALL_SUCCESS,
  FETCH_MERCHANDISE_ALL_FAILURE,
} from "../actions";

const initialState = {
  merchandiseAll: [],
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANDISE_ALL:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_MERCHANDISE_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        merchandiseAll: action.data,
      };
    case FETCH_MERCHANDISE_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        merchandiseAll: [],
      };
    default:
      return state;
  }
};
