import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_SCHEDULES = "FETCH_SCHEDULES";
export const FETCH_SCHEDULES_SUCCESS = "FETCH_SCHEDULES_SUCCESS";
export const FETCH_SCHEDULES_FAILURE = "FETCH_SCHEDULES_FAILURE";

export const fetchSchedules = () => ({
  type: FETCH_SCHEDULES,
});

export const fetchSchedulesSuccess = (data) => ({
  type: FETCH_SCHEDULES_SUCCESS,
  data,
});

export const fetchSchedulesFailure = () => ({
  type: FETCH_SCHEDULES_FAILURE,
});

export const getSchedules = () => {
  return (dispatch) => {
    dispatch(fetchSchedules());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/schedules/", null, token)
          .then((res) => {
            dispatch(fetchSchedulesSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchSchedulesFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
