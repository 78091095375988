import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_DONATION = "FETCH_DONATION";
export const FETCH_DONATION_SUCCESS = "FETCH_DONATION_SUCCESS";
export const FETCH_DONATION_FAILURE = "FETCH_DONATION_FAILURE";

export const fetchDonation = (id) => ({
  type: FETCH_DONATION,
  id,
});

export const fetchDonationSuccess = (data) => ({
  type: FETCH_DONATION_SUCCESS,
  data,
});

export const fetchDonationFailure = () => ({
  type: FETCH_DONATION_FAILURE,
});

export const getAllDonationlist = (isInitializing, handleCallback) => {
  return (dispatch) => {
    dispatch(fetchDonation());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/donation", null, token)
          .then((res) => {
            // localStorage.setItem("donation_id",res.data[0].donation_id);
            if (isInitializing === true) {
              handleCallback(res.data);
            }
            dispatch(fetchDonationSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchDonationFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
