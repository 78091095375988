import React from "react";
import { Input, Message, Image } from "semantic-ui-react";
import defaultimage from "../assets/images/defaultimage.png";

const imgCont = {
  width: "80%",
  height: "35px",
  /*line-height: 242px!important;*/
  textAlign: "center",
  // verticalAlign: middle;
  backgroundSize: "contain",
  borderRadius: "8px",
};
class CommsImage extends React.Component {
  state = {
    previewUrl: defaultimage,
  };
  render() {
    //const previewUrl = this.state.previewUrl;
    const { isEdit, value, onChange } = this.props;
    const previewUrl = this.props.previewUrl || value;
    const defaultpreviewUrl =
      previewUrl !== "" ? previewUrl : this.state.previewUrl;
    return (
      <div>
        <div
          className="imgCont"
          style={{ background: this.props.isVideo && "rgba(0,0,0, .1)" }}
        >
          <label
            className="fileLabel"
            style={{
              backgroundImage: "url(" + defaultpreviewUrl + ")",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              border: !this.props.isVideo && "1px solid #fff",
              marginTop: this.props.isVideo && 5,
              width: this.props.isVideo && 23,
              height: this.props.isVideo && 23,
            }}
          >
            <Input
              ref={this.inputRef}
              type="file"
              onChange={onChange}
              // accept="image/*"
            />
          </label>
        </div>
        {this.props.isError && this.props.isTouched && (
          <Message visible error content={this.props.isError} />
        )}
      </div>
    );
  }

  onChange = (event) => {
    alert("hi");
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };
}
export default CommsImage;
