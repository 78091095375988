import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const FETCH_DASHBOARD_ACTIVITY = "FETCH_DASHBOARD_ACTIVITY";
export const FETCH_DASHBOARD_ACTIVITY_SUCCESS =
  "FETCH_DASHBOARD_ACTIVITY_SUCCESS";
export const FETCH_DASHBOARD_ACTIVITY_FAILUTE =
  "FETCH_DASHBOARD_ACTIVITY_FAILURE";

export const fetchDashboardActivity = () => ({
  type: FETCH_DASHBOARD_ACTIVITY,
});

export const fetchDashboardActivitySuccess = (data) => ({
  type: FETCH_DASHBOARD_ACTIVITY_SUCCESS,
  data,
});

export const fetchDashboardActivityFailure = (err) => ({
  type: FETCH_DASHBOARD_ACTIVITY_FAILUTE,
  err,
});

export const getDashboardActivity = (start_date, end_date) => {
  return (dispatch) => {
    dispatch(fetchDashboardActivity());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/dashboard/coming_events",
            { from_date: start_date, to_date: end_date },
            token
          )
          .then((res) => {
            dispatch(fetchDashboardActivitySuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchDashboardActivityFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
