import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const SET_ACTIVE_USER_SUCCESS = "SET_ACTIVE_USER_SUCCESS";
export const SET_ACTIVE_USER_FAILURE = "SET_ACTIVE_USER_FAILURE";


export const setActiveUser = () => ({
  type: SET_ACTIVE_USER,
});

export const setActiveUserSuccess = () => ({
  type: SET_ACTIVE_USER_SUCCESS,
});

export const setActiveUserFailure = (err) => ({
  type: SET_ACTIVE_USER_FAILURE,
  err,
});

export const setActiveUserActions = (id) => {
    var formData = new FormData();
    formData.append('id',id);
    formData.append('is_new',0);
    formData.append('is_active',1);

    return (dispatch) => {
        dispatch(setActiveUser());
        return firebase.reloadAuth().then((res) => {
            try {
                const token = res.ya === null ? res.za : res.ya;

                api
                    .post("/user/admin/", formData,token)
                    .then((res) => {
                        dispatch(setActiveUserSuccess())
                    })
                    .catch((err) =>{
                        dispatch(setActiveUserFailure(err));
                    } )
                } catch (err) {
                    console.log(err);
                }
            });
    };
};
