import React, { Component } from "react";
import { Grid, Message, Radio } from "semantic-ui-react";

class RenderRadioButton extends Component {
  render() {
    return (
      <Grid style={{ width: "100%", marginBottom: "10px" }}>
        {this.props.options.map((data, idx) => (
          <Grid.Column
            key={idx}
            style={{ width: "40%" }}
            className="radio_button"
          >
            <Radio
              onChange={(event, data) => this.props.onChange("" + data.value)}
              value={data.value}
              checked={
                this.props.value !== undefined &&
                this.props.value.includes(data.value)
              }
              key={idx}
              disabled={
                this.props.isEdit
                  ? data.value !== this.props.value
                    ? true
                    : false
                  : false
              }
              label={data.label}
            />
          </Grid.Column>
        ))}
        {this.props.isError && this.props.isTouched && (
          <Message
            visible
            error
            content={this.props.isError}
            style={{ width: "100%" }}
          />
        )}
      </Grid>
    );
  }
}

export default RenderRadioButton;
