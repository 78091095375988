import {
  FETCH_DASHBOARD_NOTI_SUCCESS,
  FETCH_DASHBOARD_NOTI_FAILURE,
  FETCH_DASHBOARD_NOTI,
} from "../actions";

const initialState = {
  dashboardNoti: [],
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_NOTI:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_DASHBOARD_NOTI_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        dashboardNoti: action.data,
      };
    case FETCH_DASHBOARD_NOTI_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        dashboardNoti: [],
      };
    default:
      return state;
  }
};
