import React from "react";
import { Button, Icon, Image } from "semantic-ui-react";
import plus_Icon from "../assets/images/plus_Icon.png";

const RenderButton = (props) => (
  <Button
    color="vk"
    onClick={props.onClick}
    className={props.className}
    active={props.active}
    fluid={props.fluid}
  >
    {props.showIcon ? <Image src={plus_Icon} /> : null}
    {props.btnText}
  </Button>
);

export default RenderButton;
