import React, { Component } from "react";
import { Radio, Grid, Message } from "semantic-ui-react";

class RenderRadioGroup extends Component {
  render() {
    return (
      <div>
      <Grid divided="vertically">
        <Grid.Row columns={2}>
          {this.props.options.map((data, ix) => (
            <Grid.Column key={ix} className="radio-toolbar">
              <label>
                <Radio
                  onChange={(event, data) =>
                    this.props.onChange("" + data.value)
                  }
                  onClick={this.props.onClick}
                  value={data.value}
                  checked={data.value === this.props.field.value}
                  key={ix}
                  disabled={this.props.disabled}
                />
                <span>{data.label}</span>
              </label>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      {this.props.isError && this.props.isTouched && (
            <Message visible error content={this.props.isError} />
          )}
      </div>
    );
  }
}
export default RenderRadioGroup;
