import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_EVENT = "FETCH_EVENT";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_FAILURE = "FETCH_EVENT_FAILURE";

export const fetchEvent = (id) => ({
  type: FETCH_EVENT,
  id,
});

export const fetchEventSuccess = (data) => ({
  type: FETCH_EVENT_SUCCESS,
  data,
});

export const fetchEventFailure = () => ({
  type: FETCH_EVENT_FAILURE,
});

export const getEventDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchEvent());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/events/event/", { id: id }, token)
          .then((res) => {
            dispatch(fetchEventSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchEventFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
