import React from "react";
import { Input, Message } from "semantic-ui-react";

const MultipleTextInput = (props, { ...form }) => {
  return (
    <>
      <Input
        onChange={props.onChange}
        placeholder={props.placeholder}
        type={props.type}
        fluid
        size="small"
        value={props.value}
        min={props.min}
        step={props.step}
        maxLength={props.maxLength}
        max={props.max}
        disabled={props.disabled}
      />
      {props.isError && props.isTouched && (
        <Message visible error content={props.isError} />
      )}
    </>
  );
};

export default MultipleTextInput;
