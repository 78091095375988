import validator from 'validator'

export const isRequired = value =>
  value === undefined ||
  value === '' ||
  (Array.isArray(value) && value.length === 0)
    ? 'This information is required'
    : undefined

export const isEmail = value =>
  value === undefined || value === '' || (Array.isArray(value) && value.length === 0)
  ?
    'This information is required'
    :
      value && !validator.isEmail(value)
      ?
        'Please use a valid email address'
        : undefined

export const isvalidPassword = value =>
  value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/.test(value)
    ? 'Password must be: at least one uppercase character, one digit and minimum length of 6 characters'
    : undefined

export const isPhone = value =>
    value === undefined || value === '' || (Array.isArray(value) && value.length === 0)
    ?
      'This information is required'
      :
        value && !/^\+65(6|8|9)\d{7}$/.test(value)
        ?
        'Phone number must be: start +65  and number only, length of 8 characters'
          : undefined
    
    // export const isID = value =>
    //       value === undefined || value === '' || (Array.isArray(value) && value.length === 0)
    //       ?
    //         'This information is required'
    //         :
    //           value && !/^\d{10}$/.test(value)
    //           ?
    //           'Phone number must be: start +65  and number only, length of 8 characters'
    //             : undefined
     
    