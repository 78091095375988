import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import RenderInput from "../../components/TextInput";
import { isRequired } from "../../validators";
import RenderLoader from "../../components/Loader";

class ScheduleTypeForm extends Component {
  render() {
    const { initialValues, isEdit, isSaving, handleSubmit, handleUpdate } = this.props;
    return (
      <div style={{ marginTop: 36 }}>
        <RenderLoader isloading={isSaving}/>
        <Formik
          initialValues={
            isEdit
              ? initialValues
              : {
                  name: "",
                }
          }
          onSubmit={isEdit ? handleUpdate : handleSubmit }
        >
          {(formikProps) => {
            return (
              <Form>
                <label className="formLabel">{isEdit ? 'Edit' : 'Create'} Schedule Item</label>
                <div className="formMargin">              
                  <Field
                    name="name"
                    component={RenderInput}
                    onChange={formikProps.handleChange("name")}
                    validate={isRequired}
                    placeholder="Schedule Name"
                    isTouched={formikProps.touched.name}
                    isError={formikProps.errors.name}
                    value={formikProps.values.name}
                  />
                </div>
                <br />
                <div className="fullWidthCenterAlign">
                  <Button
                    content="Submit"
                    primary
                    size="large"
                    type="submit"
                    className="submitBtn"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default ScheduleTypeForm;
