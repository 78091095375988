import api from "../../config/api";
export const CHECK_SHOULD_LOGIN = "CHECK_SHOULD_LOGIN";
export const CHECK_SHOULD_LOGIN_SUCCESS = "CHECK_SHOULD_LOGIN_SUCCESS";
export const CHECK_SHOULD_LOGIN_FAILURE = "CHECK_SHOULD_LOGIN_FAILURE";

export const fetchCheckShouldLogin = () => ({
  type: CHECK_SHOULD_LOGIN,
});

export const fetchCheckShouldLoginSuccess = () => ({
  type: CHECK_SHOULD_LOGIN_SUCCESS,
});

export const fetchCheckShouldLoginFailure = (err) => ({
  type: CHECK_SHOULD_LOGIN_FAILURE,
  err,
});

export const checkUserShouldLogin = (email,handleCallback) => {
    var formData = new FormData();
    formData.append('email',email);
    return (dispatch) => {
        dispatch(fetchCheckShouldLogin());
        return api
            .post("/user/login_check/", formData)
            .then((res) => {
                handleCallback && handleCallback(true,res);
                dispatch(fetchCheckShouldLoginSuccess())
            })
            .catch((err) =>{
                handleCallback && handleCallback(false,null);
                dispatch(fetchCheckShouldLoginFailure(err));
            } )
    };
};
