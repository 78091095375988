import React, { Component } from "react";
import {
  Grid,
  Dimmer,
  Loader,
  Header,
  List,
  TransitionablePortal,
  Modal,
  Image,
  Message,
  Icon,
} from "semantic-ui-react";
import { CALENDAR_ROUTE, SCHEDULE_TYPE_CREATE_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import ScheduleTypeForm from "./ScheduleTypeForm";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import { connect } from "react-redux";
import {
  getScheduleType,
  getScheduleTypes,
  clearScheduleType,
} from "../../store/actions";
import Xclose from "../../assets/images/Xclose.svg";
import RenderButton from "../../components/Button";

const ScheduleTypeList = (props) => {
  return (
    <List animated verticalAlign="middle" className="simpleList">
      <List.Item>
        <List.Content floated="right">
          <Icon
            name="edit outline"
            style={{ color: "#4075FF" }}
            onClick={() => props.handleEdit(props.id)}
          />
        </List.Content>
        <List.Content>{props.name}</List.Content>
      </List.Item>
    </List>
  );
};

class ScheduleTypeCreate extends Component {
  state = {
    isSaving: false,
    hasError: false,
    isEidtModalOpen: false,
    isEdit: false,
    isAddModalOpen: false,
  };

  componentDidMount() {
    this.props.getScheduleTypes();
  }

  handleSubmit = (values) => {
    this.setState({
      isSaving: true,
    });
    let formdata = new FormData();
    formdata.append("name", values.name);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/schedules/schedule_type/", formdata, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              isSaving: false,
              isAddModalOpen: false,
            });
            this.props.getScheduleTypes();
            this.props.dispatch(push(SCHEDULE_TYPE_CREATE_ROUTE));
          } else {
            this.setState({
              isSaving: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  handleUpdate = (values) => {
    const { scheduletype } = this.props;
    this.setState({
      isSaving: true,
    });
    let formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("id", scheduletype.data.id);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/schedules/schedule_type/", formdata, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              isSaving: false,
              isEidtModalOpen: false,
            });
            this.props.getScheduleTypes();
            this.props.dispatch(push(SCHEDULE_TYPE_CREATE_ROUTE));
            this.props.clearScheduleType(scheduletype.data.id);
          } else {
            this.setState({
              isSaving: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  toggleAddModal = (id) => {
    this.setState({
      isAddModalOpen: !this.state.isAddModalOpen,
    });
  };

  toggleEditModal = (id) => {
    this.setState({
      isEidtModalOpen: !this.state.isEidtModalOpen,
    });
  };

  handleEdit = (id) => {
    this.props.clearScheduleType(id);
    this.props.getScheduleType(id);
    this.setState({
      isEidtModalOpen: !this.state.isEidtModalOpen,
      isEdit: true,
    });
  };

  render() {
    const {
      scheduletypes = [],
      scheduletype = {},
      isloading,
      haveData,
    } = this.props;
    const { isEdit, isSaving, isEidtModalOpen, isAddModalOpen } = this.state;
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={CALENDAR_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />

            <Grid className="subContainer">
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ width: "50%", margin: "auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h2" floated="left">
                          {this.props.formTitle}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <RenderButton
                      className="primaryBtn autoWidth"
                      btnText="Add Schedule Type"
                      showIcon={true}
                      onClick={this.toggleAddModal}
                    />

                    <h4 className="formLabel" style={{ marginBottom: 22 }}>
                      Schedule Type List
                    </h4>
                    {scheduletypes.map((type) => {
                      return (
                        <ScheduleTypeList
                          {...type}
                          handleEdit={this.handleEdit}
                        />
                      );
                    })}

                    {/* add pupup */}
                    <TransitionablePortal
                      open={isAddModalOpen}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        open={isAddModalOpen}
                        size="tiny"
                        className="orderedDetailPopup"
                      >
                        <div align="right" className="closeBtn">
                          <Image
                            src={Xclose}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleAddModal()}
                          />
                        </div>
                        <br />
                        <ScheduleTypeForm
                          handleSubmit={this.handleSubmit}
                          scheduletypes={scheduletypes}
                          isSaving={isSaving}
                        />
                      </Modal>
                    </TransitionablePortal>

                    {/* edit pupup */}
                    <TransitionablePortal
                      open={isEidtModalOpen}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        open={isEidtModalOpen}
                        size="tiny"
                        className="orderedDetailPopup"
                      >
                        <div align="right" className="closeBtn">
                          <Image
                            src={Xclose}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleEditModal()}
                          />
                        </div>
                        <br />
                        {haveData ? (
                          <ScheduleTypeForm
                            initialValues={
                              scheduletype !== undefined && scheduletype.data
                            }
                            isEdit={isEdit}
                            isSaving={isSaving}
                            handleUpdate={this.handleUpdate}
                          />
                        ) : (
                          <Message icon>
                            <Icon name="circle notched" loading />
                            <Message.Content>
                              <Message.Header>Just one second</Message.Header>
                              We are fetching that content for you.
                            </Message.Content>
                          </Message>
                        )}
                      </Modal>
                    </TransitionablePortal>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  scheduletypes: state.scheduleTypes.scheduleTypes.data,
  isloading: state.scheduleTypes.loading,
  scheduletype: state.scheduleType.scheduletype,
  //istypeloading: state.merchandisetype.loading,
  haveData: state.scheduleType.haveData,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getScheduleTypes: () => dispatch(getScheduleTypes()),
  getScheduleType: (id) => dispatch(getScheduleType(id)),
  clearScheduleType: (id) => dispatch(clearScheduleType(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTypeCreate);
