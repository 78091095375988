import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CLEAR_USER,
} from "../actions";

const initialState = {
  user: {},
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  //console.log(action)
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        user: action.data,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        user: {},
      };
    case CLEAR_USER:
      return initialState;
    default:
      return state;
  }
};
