import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_FORM_TYPE = "FETCH_FORM_TYPE";
export const FETCH_FORM_TYPE_SUCCESS = "FETCH_FORM_TYPE_SUCCESS";
export const FETCH_FORM_TYPE_FAILURE = "FETCH_FORM_TYPE_FAILURE";

export const fetchFormType = () => ({
  type: FETCH_FORM_TYPE,
});

export const fetchFormTypeSuccess = (data) => ({
  type: FETCH_FORM_TYPE_SUCCESS,
  data,
});

export const fetchFormTypeFailure = (err) => ({
  type: FETCH_FORM_TYPE_FAILURE,
  err,
});

export const getFormType = () => {
  return (dispatch) => {
    dispatch(fetchFormType());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/form/types/", null, token)
          .then((res) => {
            dispatch(fetchFormTypeSuccess(res.data));
            return res;
          })
          .catch((err) => dispatch(fetchFormTypeFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
