import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_QUICKACCESS = "FETCH_QUICKACCESS";
export const FETCH_QUICKACCESS_SUCCESS = "FETCH_QUICKACCESS_SUCCESS";
export const FETCH_QUICKACCESS_FAILURE = "FETCH_QUICKACCESS_FAILURE";
export const CLEAR_QUICKACCESS = "CLEAR_QUICKACCESS";

export const fetchQuickaccess = (user_id, user_role) => ({
  type: FETCH_QUICKACCESS,
  payload: {
    user_id,
    user_role,
  },
});

export const fetchQuickaccessSuccess = (data) => ({
  type: FETCH_QUICKACCESS_SUCCESS,
  data,
});

export const fetchQuickaccessFailure = () => ({
  type: FETCH_QUICKACCESS_FAILURE,
});

export const clearQuickaccess = () => ({
  type: CLEAR_QUICKACCESS,
});

export const getQuickaccess = (user_id, user_role) => {
  // console.log("user_id", user_id, "user_role", user_role);
  return (dispatch) => {
    dispatch(fetchQuickaccess());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        //https://asia-southeast2-morningstar-dev.cloudfunctions.net/dashboard/quickaccess/
        api
          .get(
            "/dashboard/quickaccess/",
            { user_id: user_id, user_role: user_role },
            token
          )
          .then((res) => {
            // console.log("rrrrr", res);
            dispatch(fetchQuickaccessSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchQuickaccessFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
