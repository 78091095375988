import React, { useState, useEffect } from "react";
import { Dropdown, Message } from "semantic-ui-react";
import DropdownV2 from "react-dropdown";
import "react-dropdown/style.css";
import ArrowDown from "../assets/images/arrow_down.png";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { firebase } from "../config/firebase";
import api from "../config/api";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: " 1px solid #DADADA",
    minHeight: 45,
    boxShadow: "none",
    borderRadius: "5px",
    "&:hover": {
      borderColor: "#DADADA",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#4075FF",
    border: "none",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#dadada",
      fontSize: 13,
      fontWeight: "500",
      border: "none",
    };
  },
};
const RenderDropdown = (props) => {
  return (
    <div>
      {props.multiple ? (
        <div>
          <Select
            isMulti
            name="colors"
            options={props.options}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => props.onChange(e)}
            placeholder="Choose Class"
            styles={customStyles}
            value={props.value}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
          {/* <DropdownV2
            // options={props.options}
            options={props.options.filter(
              (elem) => !props.values.find(({ value }) => elem.value == value)
            )}
            placeholder={
              filteredOptions.length == 1 ? "No Items" : props.placeholder
            }
            isMulti={true}
            onChange={(e) => {
              console.log("e", e);
              props.onChange(e);
              let filteredOp = props.options.filter(
                (elem) => !props.values.find(({ value }) => elem.value == value)
              );

              setfilteredOptions(filteredOp);
            }}
            arrowClosed={
              // <span className="arrow-closed">
              //
              // </span>
              <span
                style={{
                  borderColor: "1px solid red",
                  position: "absolute",
                  top: 7,
                  right: 10,
                  // zIndex: 9999,
                }}
              >
                <img src={ArrowDown} />
              </span>
            }
            arrowOpen={
              <span
                style={{
                  borderColor: "1px solid red",
                  position: "absolute",
                  top: 7,
                  right: 10,
                  zIndex: 9999,
                }}
              >
                <img src={ArrowDown} />
              </span>
            }
            className="dropdown2"
          /> */}
        </div>
      ) : props.moreAsyncMultiple ? (
        <AsyncPaginate
          isMulti
          name="colors"
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(e) => props.onChange(e)}
          placeholder="Choose Child Name"
          styles={customStyles}
          value={props.value}
          loadOptions={props.loadStudentOptions}
          defaultOptions={true}
          // cacheUniqs={[
          //   props.selectedCentreId,
          //   localStorage.getItem("class_id"),
          // ]}
          additional={{
            page: 1,
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      ) : (
        <Dropdown
          search
          selection
          options={props.options}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          onChange={(event, data) => {
            // console.log("data", data);
            props.onChange("" + data.value);
            if (props.resetCheck) props.resetfacacc();
          }}
          fluid
          disabled={props.disabled}
          value={typeof props.value === "object" ? props.value[0] : props.value}
          style={{ height: "auto" }}
        />
      )}
      {}
      {props.isError && props.isTouched && (
        <Message visible error content={props.isError} />
      )}
    </div>
  );
};

export default RenderDropdown;
