import api from "../../config/api";
import { getRoomlist } from "./room";

import { firebase } from "../../config/firebase";
export const FETCH_MESSAGE = "FETCH_MESSAGE";
export const FETCH_MESSAGE_SUCCESS = "FETCH_MESSAGE_SUCCESS";
export const FETCH_MESSAGE_FAILURE = "FETCH_MESSAGE_FAILURE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_FAILURE = "ADD_MESSAGE_FAILURE";
export const LOADMORE_MESSAGE = "LOADMORE_MESSAGE";
export const LOADMORE_MESSAGE_SUCCESS = "LOADMORE_MESSAGE_SUCCESS";
export const LOADMORE_MESSAGE_FAILURE = "LOADMORE_MESSAGE_FAILURE";
export const READ_MESSAGE_SUCCESS = "READ_MESSAGE_SUCCESS";
export const REFRESH_MESSAGE = "REFRESH_MESSAGE";
// export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const fetchMessage = (id) => ({
  type: FETCH_MESSAGE,
  id,
});

export const fetchMessageSuccess = (data, refresh) => ({
  type: FETCH_MESSAGE_SUCCESS,
  data,
  refresh,
});

export const fetchMessageFailure = () => ({
  type: FETCH_MESSAGE_FAILURE,
});

export const _addMessage = (id) => ({
  type: ADD_MESSAGE,
  id,
});

export const addMessageSuccess = (data) => {
  return {
    type: ADD_MESSAGE_SUCCESS,
    data,
  };
};

export const addMessageFail = () => ({
  type: ADD_MESSAGE_FAILURE,
});

export const _loadmoreMessage = (id) => ({
  type: LOADMORE_MESSAGE,
  id,
});

export const loadMoreMessageSuccess = (data) => {
  return {
    type: LOADMORE_MESSAGE_SUCCESS,
    data,
  };
};

export const loadMoreMessageFail = () => ({
  type: LOADMORE_MESSAGE_FAILURE,
});

export const refreshMessage = () => ({
  type: REFRESH_MESSAGE,
});

export const getMessage = (id, sender_id, size, isNext, nextUrl, refresh) => {
  if (isNext) {
    return (dispatch) => {
      dispatch(_loadmoreMessage());

      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          fetch(api.apiRoot + nextUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((res) => {
              dispatch(loadMoreMessageSuccess(res));
              localStorage.setItem("room_id", res.room_id);
              localStorage.setItem("receiver_id", id);
              return res;
            })
            .catch((err) => dispatch(fetchMessageFailure(err)));
        } catch (err) {
          console.log(err);
        }
      });
    };
  } else {
    return (dispatch) => {
      if (refresh) {
        dispatch(fetchMessage());
      }
      return firebase.reloadAuth().then((res) => {
        // console.log('token', res)
        try {
          const token = res.ya === null ? res.za : res.ya;

          if (size != null) {
            api
              .get("/comms", { receiver: id, sender: sender_id, size }, token)
              .then((res) => {
                dispatch(fetchMessageSuccess(res, refresh));

                localStorage.setItem("room_id", res.room_id);
                localStorage.setItem("receiver_id", id);

                return res;
              })
              .catch((err) => dispatch(fetchMessageFailure(err)));
          } else {
            api
              .get("/comms", { receiver: id, sender: sender_id }, token)
              .then((res) => {
                dispatch(fetchMessageSuccess(res, refresh));
                localStorage.setItem("room_id", res.room_id);
                localStorage.setItem("receiver_id", id);
                return res;
              })
              .catch((err) => dispatch(fetchMessageFailure(err)));
          }
        } catch (err) {
          console.log(err);
        }
      });
    };
  }
};

export const addMessage = (data) => {
  return (dispatch) => {
    if (data) {
      dispatch(addMessageSuccess(data));
    } else {
      dispatch(addMessageFail());
    }
  };
};
export const newMessage = (data) => {
  return (dispatch) => {
    if (data) {
      dispatch(addMessageSuccess(data));
    } else {
      dispatch(addMessageFail());
    }
  };
};

export const readCommsAllMessage = (room_id, sender) => {
  return (dispatch) => {
    let user_ID = localStorage.getItem("user_Id");
    var fd = new FormData();
    fd.append("room_id", room_id);
    fd.append("user_id", user_ID);
    return firebase.reloadAuth().then((res) => {
      const token = res.ya === null ? res.za : res.ya;

      api
        .post("/comms/read/", fd, token)
        .then((d) => {
          getRoomlist(localStorage.getItem("user_Id"));
          return d;
        })
        .catch((err) => console.log("comms_read-err", err));
    });
  };
};
