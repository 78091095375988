import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_DONATIONHISTORY = "FETCH_DONATIONHISTORY";
export const FETCH_DONATIONHISTORY_SUCCESS = "  FETCH_DONATIONHISTORY_SUCCESS";
export const FETCH_DONATIONHISTORY_FAILURE = "  FETCH_DONATIONHISTORY_FAILURE";

export const fetchDonationHistory = (id) => ({
  type: FETCH_DONATIONHISTORY,
  id,
});

export const fetchDonationHistorySuccess = (data) => ({
  type: FETCH_DONATIONHISTORY_SUCCESS,
  data,
});

export const fetchDonationHistoryFailure = () => ({
  type: FETCH_DONATIONHISTORY_FAILURE,
});

export const getDonationHistorylist = (parent_id) => {
  return (dispatch) => {
    dispatch(fetchDonationHistory());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/donation/details/",
            {
              filtered_by: "parent_id",
              parent_id: parent_id,
              from_date: "2021-01-10",
              to_date: "2021-02-10",
            },
            token
          )
          .then((res) => {
            dispatch(fetchDonationHistorySuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchDonationHistoryFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
