import {
  FETCH_HOLIDAYS,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_HOLIDAYS_FAILURE,
} from "../actions";

const initialState = {
  holidays: [],
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOLIDAYS:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        holidays: action.data,
      };
    case FETCH_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        holidays: [],
      };
    default:
      return state;
  }
};
