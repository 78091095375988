import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_SCHEDULE_TYPE = "FETCH_SCHEDULE_TYPE";
export const FETCH_SCHEDULE_TYPE_SUCCESS = "FETCH_SCHEDULE_TYPE_SUCCESS";
export const FETCH_SCHEDULE_TYPE_FAILURE = "FETCH_SCHEDULE_TYPE_FAILURE";
export const CLEAR_SCHEDULE_TYPE = "CLEAR_SCHEDULE_TYPE";

export const fetchScheduleType = (id) => ({
  type: FETCH_SCHEDULE_TYPE,
  id,
});

export const fetchScheduleTypeSuccess = (data) => ({
  type: FETCH_SCHEDULE_TYPE_SUCCESS,
  data,
});

export const fetchScheduleTypeFailure = () => ({
  type: FETCH_SCHEDULE_TYPE_FAILURE,
});

export const clearScheduleType = () => ({
  type: CLEAR_SCHEDULE_TYPE,
});

export const getScheduleType = (id) => {
  return (dispatch) => {
    dispatch(fetchScheduleType());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/schedules/schedule_type/", { id: id }, token)
          .then((res) => {
            dispatch(fetchScheduleTypeSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchScheduleTypeFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
