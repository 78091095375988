import { FETCH_SCHEDULE, FETCH_SCHEDULE_SUCCESS, FETCH_SCHEDULE_FAILURE, CLEAR_SCHEDULE } from '../actions';

const initialState = {
  schedule: {},
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_SCHEDULE:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        schedule: action.data
      };
    case FETCH_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        schedule: {}
      };
    case CLEAR_SCHEDULE:
      return initialState;
    default:
      return state;
  }
}