import { floor } from "lodash";
import React from "react";
import Charts from "../components/MinChart";

const option = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          offsetGridLines: false,
          color: "rgba(0, 0, 0, 0)",
        },
        display: false,
        stacked: true,
      },
    ],
    yAxes: [
      {
        gridLines: {
          offsetGridLines: false,
          color: "rgba(0, 0, 0, 0)",
        },
        display: false,
        stacked: true,
      },
    ],
  },
};
const Card = (props) => {
  return (
    <div className="longcard">
      <div className="content">
        <div className="cardTitle">{props.title}</div>
        <p className="cardNumber" style={props.style}>
          {props.number}
        </p>
        <div className="cardbottom">
          <img style={{ width: "16px", height: "16px" }} src={props.image} />
          <span className="cardtext"> {props.text} </span>
        </div>
        <div>
          <div className="chart">
            <Charts type={"Bar"} data={props.data} option={option} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
