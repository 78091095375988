import React, { Component } from "react";
import {
  Input,
  Grid,
  Header,
  Button,
  Card,
  Image,
  Icon,
} from "semantic-ui-react";
import { Formik, Field, Form, FieldArray } from "formik";
import RenderInput from "../../components/TextInput";
import RenderInputCentre from "../../components/TextInputCentre";
import RenderTextArea from "../../components/RenderTextArea";
import RenderImage from "../../components/Image";
import { isRequired } from "../../validators";
import RenderRadioGroup from "../../components/DonationRadioGroup";
import ic_add from "../../assets/images/ic_add.svg";
import DonationDatePicker from "../../components/DonationDateFrom";
import DonationDateTo from "../../components/DonationDateTo";
import RenderFile from "../../components/File";
import ReactPlayer from "react-player";
import corssImage from "../../assets/images/X.svg";

class DonationForm extends Component {
  state = {
    error: "",
  };
  handleAdd = (formikProps, val) => {
    formikProps.handleChange("activityDate", val);
    console.log(formikProps.values.activityDate);
  };
  handleChange = (val) => {
    console.log(val);
  };

  render() {
    return (
      <Grid className="fullHeight">
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="form-subcontainer">
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ width: "50%", margin: "auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h2" floated="left">
                          {this.props.formtitle}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <div style={{ marginTop: 36 }}>
                      <Formik
                        initialValues={{
                          title: "",
                          description: "",
                          image: "",
                          dateFrom: this.props.previewDate,
                          dateTo: this.props.previewDateTo,
                          Totalneeds: "",
                          link: "",
                          media_type: "Image",
                          ranges: [""],
                        }}
                        onSubmit={this.props.handleSubmit}
                      >
                        {(formikProps, setFieldValue) => {
                          return (
                            <Form>
                              <Grid>
                                <Grid.Row columns={1}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Title</b>
                                    </label>
                                    <div className="formMargin">
                                      <Field
                                        name="title"
                                        component={RenderInput}
                                        onChange={formikProps.handleChange(
                                          "title"
                                        )}
                                        validate={isRequired}
                                        placeholder="Donate an Education for Children"
                                        isTouched={formikProps.touched.title}
                                        isError={formikProps.errors.title}
                                        value={formikProps.values.title}
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={1}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Description</b>
                                    </label>
                                    <div className="formMargin">
                                      <Field
                                        name="description"
                                        component={RenderTextArea}
                                        onChange={formikProps.handleChange(
                                          "description"
                                        )}
                                        validate={isRequired}
                                        placeholder="Description"
                                        isTouched={
                                          formikProps.touched.description
                                        }
                                        isError={formikProps.errors.description}
                                        value={formikProps.values.description}
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={2}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Date</b>
                                    </label>
                                    <Field
                                      name="dateFrom"
                                      component={DonationDatePicker}
                                      onChange={(date) =>
                                        this.props.onDateChange(date)
                                      }
                                      isTouched={
                                        formikProps.touched.activityDate
                                      }
                                      isError={formikProps.errors.activityDate}
                                      previewDate={this.props.previewDate}
                                    />
                                  </Grid.Column>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>&nbsp;</b>
                                    </label>
                                    <Field
                                      name="dateTo"
                                      component={DonationDateTo}
                                      onChange={(date) =>
                                        this.props.onDateToChange(date)
                                      }
                                      previewDateTo={this.props.previewDateTo}
                                    />
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={1}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Total needs</b>
                                    </label>
                                    <div className="formMargin">
                                      <Field
                                        name="Totalneeds"
                                        type="number"
                                        min="0"
                                        max="1000000"
                                        maxLength="8"
                                        step="0.01"
                                        component={RenderInput}
                                        onChange={formikProps.handleChange(
                                          "Totalneeds"
                                        )}
                                        validate={isRequired}
                                        placeholder="SGD $"
                                        isTouched={
                                          formikProps.touched.Totalneeds
                                        }
                                        isError={formikProps.errors.Totalneeds}
                                        value={formikProps.values.Totalneeds}
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>

                              <label className="formLabel">
                                <b>Range</b>
                              </label>
                              <div className="formMargin"></div>
                              <FieldArray
                                name="ranges"
                                render={(arrayHelpers) => {
                                  {
                                    return (
                                      <Grid>
                                        {formikProps.values.ranges.length > 0 &&
                                          formikProps.values.ranges.map(
                                            (range, idx) => (
                                              <Grid.Column
                                                width={3}
                                                style={{
                                                  paddingRight: 0,
                                                  position: "relative",
                                                }}
                                              >
                                                <Field
                                                  name={`ranges.${idx}`}
                                                  component={RenderInputCentre}
                                                  onChange={formikProps.handleChange(
                                                    `ranges.${idx}`
                                                  )}
                                                  value={
                                                    formikProps.values.ranges[
                                                      idx
                                                    ]
                                                  }
                                                  styles={true}
                                                />
                                                {/* dudud */}
                                                {formikProps.values.ranges
                                                  .length > 1 && (
                                                  <div
                                                    onClick={() =>
                                                      arrayHelpers.remove(idx)
                                                    }
                                                    style={{
                                                      position: "absolute",
                                                      top: 27,
                                                      right: 3,
                                                      width: 18,
                                                      height: 18,
                                                    }}
                                                  >
                                                    <Image src={corssImage} />
                                                  </div>
                                                )}
                                              </Grid.Column>
                                            )
                                          )}
                                        <div
                                          style={{
                                            paddingLeft: 2,
                                            paddingRight: 0,
                                            paddingTop: 25,
                                            width: "25px",
                                            height: "40px",
                                          }}
                                        >
                                          <Image
                                            src={ic_add}
                                            // onClick={() => push("")}
                                            onClick={() =>
                                              arrayHelpers.push("")
                                            }
                                          />
                                        </div>
                                      </Grid>
                                    );
                                  }
                                }}
                              />

                              <label className="formLabel">
                                <b>Media</b>
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="media_type"
                                  component={RenderRadioGroup}
                                  options={this.props.options}
                                  onChange={formikProps.handleChange(
                                    "media_type"
                                  )}
                                  validate={isRequired}
                                  isTouched={formikProps.touched.media_type}
                                  isError={formikProps.errors.media_type}
                                  value={formikProps.values.media_type}
                                />
                              </div>

                              <label className="formLabel">
                                <b>
                                  {formikProps.values.media_type === "Video"
                                    ? "Upload Video"
                                    : "Upload Image"}
                                </b>
                              </label>
                              <div className="formMargin">
                                {formikProps.values.media_type === "Video" ? (
                                  <div>
                                    <label
                                      style={{
                                        width: "150px",
                                        display: "inline-block",
                                        padding: "8px 12px",
                                        cursor: "pointer",
                                        borderRadius: "4px",
                                        backgroundColor: "#41CEC3",
                                        fontSize: "14px",
                                        marginBottom: "10px",
                                        color: "#fff",
                                      }}
                                    >
                                      <span aria-hidden="true">
                                        <Icon name="upload" /> Upload Video
                                      </span>
                                      <input
                                        name="video"
                                        onChange={this.props.handleChange}
                                        type="file"
                                        accept="video/*"
                                        id="video"
                                        style={{
                                          marginBottom: "10px",
                                          display: "none",
                                        }}
                                      />
                                    </label>
                                    {this.props.previewVideoUrl !== null && (
                                      <ReactPlayer
                                        style={{
                                          maxWidth: "485px",
                                          maxHeight: "270px",
                                        }}
                                        url={
                                          this.props.previewVideoUrl !==
                                            undefined &&
                                          this.props.previewVideoUrl
                                        }
                                        controls={true}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <Field
                                    name="image"
                                    component={RenderImage}
                                    onChange={this.props.onChange}
                                    accept="image/*"
                                    previewUrl={this.props.previewUrl}
                                    isTouched={formikProps.touched.image}
                                    isError={formikProps.errors.image}
                                  />
                                )}
                              </div>
                              {formikProps.values.media_type !== "Video" && (
                                <div>
                                  <label className="formLabel">
                                    <b>Or input the image link</b>
                                  </label>
                                  <div className="formMargin">
                                    <Field
                                      name="link"
                                      component={RenderInput}
                                      onChange={formikProps.handleChange(
                                        "link"
                                      )}
                                      isTouched={formikProps.touched.link}
                                      isError={formikProps.errors.link}
                                      placeholder="https://"
                                      value={formikProps.values.link}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="fullWidthCenterAlign">
                                <Button
                                  content="Publish"
                                  primary
                                  size="large"
                                  type="submit"
                                  className="submitBtn"
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default DonationForm;
