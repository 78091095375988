import React from "react";
import { Dropdown, Message } from "semantic-ui-react";

const options = [
  // { key: 1, text: "Add Question", value: 1, icon: "plus", color: "blue" },
  { key: 2, text: "Short answer", value: 2, icon: "align left", color: "blue" },
  { key: 3, text: "Paragraph", value: 3, icon: "bars", color: "blue" },
  { key: 4, text: "Multiple Choice", value: 4, icon: "radio", color: "blue" },
  { key: 5, text: "Checkboxes", value: 5, icon: "check square", color: "blue" },
  {
    key: 6,
    text: "Dropdown",
    value: 6,
    icon: "caret square down",
    color: "blue",
  },
  { key: 7, text: "File upload", value: 7, icon: "upload", color: "blue" },
  {
    key: 8,
    text: "Linear scale",
    value: 8,
    icon: "arrows alternate horizontal",
    color: "blue",
  },
  {
    key: 9,
    text: "Multiple choice grid",
    value: 9,
    icon: "th",
    color: "blue",
  },
  {
    key: 10,
    text: "Date",
    value: 10,
    icon: "calendar alternate outline",
    color: "blue",
  },
];

const FormDropDown = (props) => {
  return (
    <div>
      <Dropdown
        selection
        options={options}
        placeholder={props.placeholder}
        onBlur={(event, data) => null}
        onChange={(event, data) => props.onChange("" + data.value)}
        text={props.text && props.text}
        fluid
      />
      {props.isError && props.isTouched && (
        <Message visible error content={props.isError} />
      )}
    </div>
  );
};

export default FormDropDown;
