import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Grid,Image } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import ic_calendar from '../assets/images/ic_calendar1.svg';
import { da } from "date-fns/locale";
import dateFormat from 'dateformat';


const RenderPairDatePicker = (props) => {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
        <div className="formMargin">
          <DatePicker
            selected={props.isEdit?new Date(props.value):new Date(props.value)}
            //Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today)
            //onChange={(event,date) =>props.onChange('', date,console.log(date))}
            onChange={props.onChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            //value={startDate}
            // dateFormat="MM/dd/yyyy"
            dateFormat="yyyy MMM dd"
            placeholderText="from"
            />
            <div className="input-iconnnn">
              <Image src={ic_calendar}></Image>
            </div>
        </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RenderPairDatePicker;
