import React from "react";
import { Grid, Image, Loader } from "semantic-ui-react";
import ic_Payment_Success from "../../assets/images/ic_Payment_Successful.svg";
import RenderButton from "../../components/Button";
import { DASHBOARD_ROUTE } from "./../../routes";
import { firebase } from "../../config/firebase";
import api from "../../config/api";

class PaySuccess extends React.Component {
  constructor() {
    super();
    this.state = {
      payment: [],
      ref_type: "",
      data: null,
      isLoading: true,
    };
  }
  handleOK() {}
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    const ref_type = query.get("ref_type");
    console.log("ref_type", ref_type);
    this.setState({
      ref_type,
    });
    // var fd = new FormData();
    // fd.append("token", token);
    // return firebase.reloadAuth().then((res) => {
    //   const token = res.ya === null ? res.za : res.ya;
    //   api
    //     .post("/paypal/complete_payment", fd, token)
    //     .then((data) => {
    //       console.log("dataaaa", data);
    //       this.setState({
    //         data: data,
    //         isLoading: false,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //       this.setState({
    //         isLoading: false,
    //       });
    //     });
    // });

    // const query = new URLSearchParams(this.props.location.search);
    // const ref_type = query.get("ref_type");
    // this.setState({
    //   ref_type: ref_type,
    // });
  }
  capitalizeFirstLetter(string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    return (
      <>
        {/* {this.state.isLoading ? (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              zIndex: 1000,
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <Loader active size="huge" />
          </div>
        ) : ( */}
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            zIndex: 1000,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div style={{ marginBottom: "15px" }}>
            <Image src={ic_Payment_Success} />
          </div>
          <div align="center" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: 24, fontWeight: 700, marginBottom: 17 }}>
              {this.capitalizeFirstLetter(this.state.ref_type)} {""}
              complete!
            </p>
            <p style={{ width: 282, fontWeight: 600 }}>
              Thank you for your great generosity! We, at Morning Star, greatly
              appreciate your
            </p>
          </div>
          <div style={{ width: 311, marginTop: window.innerHeight / 6 }}>
            <RenderButton
              floated="right"
              to={DASHBOARD_ROUTE}
              className="primaryBtn"
              fluid={true}
              btnText="Done"
              showIcon={false}
              onClick={this.handleOK}
            />
          </div>
          {/* <Grid.Row>
            <div
              style={{
                display: "block",
                margin: "auto",
                backgroundColor: "white",
              }}
            >
              <div align="center" style={{ marginBottom: "15px" }}>
                <Image src={ic_Payment_Success} />
              </div>
              <div align="center" style={{ marginBottom: "15px" }}>
                <p style={{ fontSize: 24, fontWeight: 700, marginBottom: 17 }}>
                  Donation Complete!
                </p>
                <p style={{ width: 282, fontWeight: 600 }}>
                  Thank you for your great generosity! We, at Morning Star,
                  greatly appreciate your donation.
                </p>
              </div>
              <div>
                <RenderButton
                  floated="right"
                  className="primaryBtn"
                  fluid={true}
                  btnText="Done"
                  showIcon={false}
                  onClick={this.handleOK}
                />
              </div>
            </div>
          </Grid.Row>
         */}
        </div>
        {/* )} */}
      </>
    );
  }
}
export default PaySuccess;
