import React, { useState, useEffect } from "react";
import { Route } from "react-router";
import {
  FORM_CREATE_ROUTE,
  FORM_ROUTE,
  FORM_VIEW_ROUTE,
  FORM_EDIT_ROUTE,
  FORM_DUPLICATE_ROUTE,
} from "../../routes";
import Formdash from "./Formdash";
import CreateForm from "./Formcreate";
import Formview from "./Formview";
import FormEdit from "./FormEdit";
import FormDuplicate from "./FormDuplicate";
import { firebase } from "../../config/firebase";
import { getCurrentLoginUser } from "../../store/actions";
import { connect } from "react-redux";
import AccessDeniedPage from "../error/AccessDeniedPage";

export const Forms = (props) => {
  const [isAccess, setIsAccess] = useState(true);
  useEffect(() => {
    firebase.reloadAuth().then((res) => {
      props.getCurrentLoginUser(res.uid, "admin");
      if (props.userDetail.admin_type !== "hq") {
        setIsAccess(false);
      } else {
        setIsAccess(true);
      }
    });
  }, []);

  if (isAccess) {
    return (
      <React.Fragment>
        <Route exact path={FORM_EDIT_ROUTE} component={FormEdit} />
        <Route exact path={FORM_ROUTE} component={Formdash} />
        <Route exact path={FORM_CREATE_ROUTE} component={CreateForm} />
        <Route exact path={FORM_VIEW_ROUTE} component={Formview} />
        <Route exact path={FORM_DUPLICATE_ROUTE} component={FormDuplicate} />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Route exact path={FORM_EDIT_ROUTE} component={AccessDeniedPage} />
      <Route exact path={FORM_ROUTE} component={AccessDeniedPage} />
      <Route exact path={FORM_CREATE_ROUTE} component={AccessDeniedPage} />
      <Route exact path={FORM_VIEW_ROUTE} component={AccessDeniedPage} />
      <Route exact path={FORM_DUPLICATE_ROUTE} component={AccessDeniedPage} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userDetail: state.currentLoginUser.currentLoginUser.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
