import React, { Component } from "react";
import {
  Grid,
  Header,
  Button,
  Segment,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { Formik, Field, Form, FieldArray } from "formik";
import RenderInput from "../../components/TextInput";
import RenderCheckbox from "../../components/CheckBox";
import { isRequired } from "../../validators";
import RenderRadioGroup from "../../components/RadioGroup";
import RenderTimePicker from "../../components/TimePicker";
import RenderMultipleDatePicker from "../../components/Multiple_DatePicker";
import RenderCheckboxGroup from "../../components/RenderCheckboxGroup";
import RenderMultipleRadioGroup from "../../components/MultipleRadioGroup";
import RenderMultipleRadioGroupMonth from "../../components/MultipleRadioGroupMonth";
import RenderMultipleRadioGroupDay from "../../components/MultipleRadioGroupDay";
import RenderMultipleCheckboxGroup from "../../components/RenderMultipleCheckboxGroup";
import RenderRadioButton from "../../components/radioButton";
import CrossInfo from "../../assets/images/crossinfo.png";
import RenderImage from "../../components/Image";
import defaultimage from "../../assets/images/defaultimage.png";
import checkIn from "../../assets/images/checkIn.png";
import morningGame from "../../assets/images/morningGame.png";
import breakfast from "../../assets/images/breakfast.png";
import reading from "../../assets/images/reading.png";
import homework from "../../assets/images/homework.png";
import lunch from "../../assets/images/lunch.png";
import checkOut from "../../assets/images/checkOut.png";
import nap from "../../assets/images/nap.png";
import { initial, values } from "lodash-es";
import { id } from "date-fns/locale";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import RenderDatePicker from "../../components/DatePicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

let title_edit = "";
let img_edit = "";

class ScheduleForm extends Component {
  state = {
    previewUrl: defaultimage,
    previewUrlArr: [],
    centre_id: "",
    classActivities: [
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/MjUtc2xlZXAyMDIxLTA2LTA0VDA5OjQzOjUyWg%3D%3D.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/MjUtc2xlZXAyMDIxLTA2LTA0VDA5OjQzOjUyWg%3D%3D.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/R3JvdXA%3D.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/R3JvdXA%3D.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yMjAyMS0wNi0wM1QxMDoyMToyN1o=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yMjAyMS0wNi0wM1QxMDoyMToyN1o=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/R3JvdXAgKDEp.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/R3JvdXAgKDEp.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/R3JvdXAgKDMp.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/R3JvdXAgKDMp.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/VmVjdG9yICgxKQ%3D%3D.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/VmVjdG9yICgxKQ%3D%3D.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/VmVjdG9yICgyKQ%3D%3D.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/VmVjdG9yICgyKQ%3D%3D.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/VmVjdG9yICgzKQ%3D%3D.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons/VmVjdG9yICgzKQ%3D%3D.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/dGVhbXdvcmsgMTIwMjEtMDYtMDJUMDc6NDY6NTZa.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/dGVhbXdvcmsgMTIwMjEtMDYtMDJUMDc6NDY6NTZa.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/WE1MSUQgODI5MjAyMS0wNi0wM1QxMDoyMjoyOFo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/WE1MSUQgODI5MjAyMS0wNi0wM1QxMDoyMjoyOFo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDEpMjAyMS0wNi0wMVQxOTozNjozNlo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDEpMjAyMS0wNi0wMVQxOTozNjozNlo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDIpMjAyMS0wNi0wMVQxOTozNjozNlo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDIpMjAyMS0wNi0wMVQxOTozNjozNlo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yMjAyMS0wNi0wMlQwNzo0ODoyOVo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yMjAyMS0wNi0wMlQwNzo0ODoyOVo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDMpMjAyMS0wNi0wMVQxOTozNjozNlo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDMpMjAyMS0wNi0wMVQxOTozNjozNlo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgMjE5MjAyMS0wNi0wMlQwNzo0NTo1NFo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgMjE5MjAyMS0wNi0wMlQwNzo0NTo1NFo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDQpMjAyMS0wNi0wMlQwNzo0MzozOVo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDQpMjAyMS0wNi0wMlQwNzo0MzozOVo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDUpMjAyMS0wNi0wMlQwNzo0NDowOFo=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDUpMjAyMS0wNi0wMlQwNzo0NDowOFo=.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yICgxKTIwMjEtMDYtMDJUMDc6NDc6MjFa.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yICgxKTIwMjEtMDYtMDJUMDc6NDc6MjFa.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yICgyKTIwMjEtMDYtMDJUMDc6NDg6MDFa.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yICgyKTIwMjEtMDYtMDJUMDc6NDg6MDFa.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDYpCTIwMjEtMDYtMDJUMDc6NDQ6Mzda.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDYpCTIwMjEtMDYtMDJUMDc6NDQ6Mzda.png",
      },
      {
        label:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDcpMjAyMS0wNi0wMlQwNzo0NToyN1o=.png",
        value:
          "https://d2t2athllyh1ku.cloudfront.net/images/icons_/R3JvdXAgKDcpMjAyMS0wNi0wMlQwNzo0NToyN1o=.png",
      },
    ],
    // from_time: new Date(),
    // to_time: new Date(),
    from_time: null,
    to_time: null,
    DLoading: true,
    img_Preview: null,
    title: "",
    meal_id: "",
    img: "",
    meal_list: [],
    meal_list_edit: [],
    meal_delete: [],
  };

  componentDidMount() {
    if (this.props.isEdit) {
      this.props.scheduleItems.find((data) => {
        this.props.initialValues.schedule_item_ids.find((sch) => {
          if (data.id === sch) {
            let find = this.state.meal_list.find((data) => data.id === sch);
            if (!find) {
              this.state.meal_list_edit.push(data);
            }
            if (
              this.state.meal_list.length <
              this.props.initialValues.schedule_item_ids.length - 1
            ) {
              if (!find) {
                this.state.meal_list.push({
                  id: data.id,
                  img: data.img_url,
                  title: data.name,
                });
              }
            } else {
              this.setState({
                title: data.name,
                img: data.img_url,
                meal_id: data.id,
              });
            }
          }
        });
      });
    }
  }

  AddMeal = (formiks) => {
    if (formiks.values.meal_title !== "" && formiks.values.meal_img !== "") {
      if (this.props.isEdit) {
        this.state.meal_list.push({
          id:
            this.state.meal_id === ""
              ? this.state.meal_list.length + 1
              : this.state.meal_id,
          img: formiks.values.meal_img,
          title: formiks.values.meal_title,
        });
        formiks.setFieldValue("meal_title", "");
        formiks.setFieldValue("meal_img", "");
        formiks.setFieldValue("meal_id", "");
        formiks.setFieldValue("meal_change", "");
        this.setState({
          meal_id: "",
          title: "",
          img: "",
        });
      } else {
        this.state.meal_list.push({
          id:
            this.state.meal_id === ""
              ? this.state.meal_list.length + 1
              : this.state.meal_id,
          img: formiks.values.meal_img,
          title: formiks.values.meal_title,
        });
        formiks.setFieldValue("meal_title", "");
        formiks.setFieldValue("meal_img", "");
        formiks.setFieldValue("meal_id", "");
        formiks.setFieldValue("meal_change", "");
      }
    }
    // if (this.state.img !== "" && this.state.title !== "") {
    //   this.setState({ img_Preview: null, img: "", title: "" });
    //   this.state.meal_list.push({
    //     id: this.state.meal_list.length + 1,
    //     img: this.state.img,
    //     title: this.state.title,
    //   });
    // }
  };

  changeMeal = (name, id, e) => {
    if (name === "title") {
      let title_change = this.state.meal_list.slice();
      title_change[id].title = e.target.value;
      this.setState({ meal_list: title_change });
    }
    if (name === "img") {
      let img_change = this.state.meal_list.slice();
      img_change[id].img = e.target.files[0];
      this.setState({ meal_list: img_change });
    }
  };

  Remove = (id) => {
    const delete_data = this.state.meal_list_edit.find(
      (data) => data.id === id
    );
    if (delete_data) {
      this.state.meal_delete.push(delete_data);
    }
    const filter = this.state.meal_list.filter((data) => data.id !== id);
    this.setState({
      meal_list: filter,
    });
    if (this.props.isEdit) {
      const filter = this.state.meal_list_edit.filter((data) => data.id !== id);
      this.setState({
        meal_list_edit: filter,
      });
    }
  };

  _onChangeForMultiple(class_id, formik) {
    if (
      formik.values.class_ids.filter((classID) => classID === class_id)
        .length === 1
    ) {
      formik.values.class_ids = formik.values.class_ids.filter(
        (classID) => classID !== class_id
      );
    } else {
      formik.values.class_ids.push(class_id);
    }
  }
  _onChangeForMultipleMonth(value, formik) {
    if (formik.values.months.filter((month) => month === value).length === 1) {
      formik.values.months = formik.values.months.filter(
        (month) => month !== value
      );
    } else {
      formik.values.months.push(value);
    }
  }
  _onChangeForMultipleDay(value, formik) {
    if (formik.values.days.filter((day) => day === value).length === 1) {
      formik.values.days = formik.values.days.filter((day) => day !== value);
    } else {
      formik.values.days.push(value);
    }
  }

  render() {
    const {
      // centers = [],
      from_time,
      to_time,
      scheduleColors = [],
      centerclasses,
      isEdit,
      initialValues,
      loading,
      Loading,
      classes = [],
      scheduleItems,
    } = this.props;
    // console.log(centerclasses);
    // console.log(initialValues);
    // console.log(this.state.meal_list_edit);
    // console.log(this.state.meal_list);
    let centers = this.props.centers;
    let adminType = localStorage.getItem("adminType");
    if (adminType === "centre") {
      let centre_id = localStorage.getItem("centre_Id");
      centers = centers.filter((center) => center.key === centre_id);
    }
    const { classActivities } = this.state;
    let Edit_value = {};
    if (isEdit) {
      let center = centerclasses.find((data) =>
        initialValues.centre_ids.find((ini) => {
          if (data.id === ini) {
            return data;
          }
        })
      );
      // console.log("initialvalues ",initialValues.centre_ids[0])
      // initialValues.centre_ids = initialValues.centre_ids[0];

      // console.log('initial values', initialValues)

      let classN = [];
      center.class.find((data) => {
        initialValues.class_ids.find((ini) => {
          if (data.id === ini) {
            classN.push(data.name);
          }
          Edit_value = {
            id: initialValues.id,
            centre_ids: initialValues.centre_ids,
            class_ids: classN,
            months: initialValues.months,
            days: initialValues.days,
            from_time: new Date(initialValues.from_time),
            to_time: new Date(initialValues.to_time),
            type: initialValues.schedule_type_id,
            title: initialValues.title,
            description: initialValues.description,
            icon_img: initialValues.icon_img,
            scfa: initialValues.scfa,
          };
        });
      });
    }
    return (
      <Grid className="subContainer">
        <Dimmer active={Loading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ width: "570px", margin: "auto" }}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h2" floated="left">
                    {this.props.formTitle}
                  </Header>
                </Grid.Column>
                {/* {console.log(title_edit)} */}
              </Grid.Row>

              <div style={{ marginTop: 36 }}>
                <Formik
                  enableReinitialize={isEdit ? true : false}
                  initialValues={
                    isEdit
                      ? {
                          ...Edit_value,
                          meal_title: this.state.title,
                          meal_img: this.state.img,
                          meal_id: this.state.meal_id,
                          dateTypes: initialValues.occurrence,
                          specific_date:
                            initialValues.occurrence === "specific_time"
                              ? initialValues.date_lists
                              : "",
                          // specific_to:
                          //   initialValues.occurrence === "specific_time"
                          //     ? new Date(initialValues.to_date)
                          //     : "",
                          specific_time_from:
                            initialValues.occurrence === "specific_time"
                              ? new Date(initialValues.from_time)
                              : "",
                          specific_time_to:
                            initialValues.occurrence === "specific_time"
                              ? new Date(initialValues.to_time)
                              : "",
                        }
                      : {
                          centre_ids: [],
                          class_ids: [],
                          months: [],
                          days: [],
                          from_time: "",
                          to_time: "",
                          title: "",
                          icon_img: "",
                          type: "",
                          scfa: 0,
                          meal_img: "",
                          meal_title: "",
                          description: "",
                          dateTypes: "routine",
                          specific_date: "",
                          specific_time_from: "",
                          specific_time_to: "",
                          //schedule_item_ids: "",
                          //schedule_type_id: ""
                        }
                  }
                >
                  {(formikProps) => {
                    // console.log("formik ", formikProps.values);
                    return (
                      <Form>
                        <label className="formLabel">Centers</label>

                        <div className="formMargin centers_schedule">
                          <Field
                            name="centre_ids"
                            // component={RenderCheckboxGroup}
                            component={RenderMultipleCheckboxGroup}
                            options={centers}
                            validate={isRequired}
                            onChange={formikProps.handleChange("centre_ids")}
                            isTouched={formikProps.touched.centre_ids}
                            isError={formikProps.errors.centre_ids}
                            value={formikProps.values.centre_ids}
                          />
                        </div>
                        <label className="formLabel">Class</label>
                        <div className="formMargin class_schedule">
                          <Field
                            name="class_ids"
                            // component={RenderRadioGroup}
                            component={RenderMultipleRadioGroup}
                            options={classes.sort((a, b) =>
                              a.label !== b.label
                                ? a.label < b.label
                                  ? -1
                                  : 1
                                : 0
                            )}
                            onChange={formikProps.handleChange("class_ids")}
                            validate={isRequired}
                            isTouched={formikProps.touched.class_ids}
                            isError={formikProps.errors.class_ids}
                            value={formikProps.values.class_ids}
                            formikProps={formikProps}
                            _onChangeForMultiple={this._onChangeForMultiple}
                          />
                        </div>
                        <div className="formMargin class_schedule">
                          <Field
                            name="dateTypes"
                            component={RenderRadioButton}
                            options={this.props.dateTypes}
                            onChange={formikProps.handleChange("dateTypes")}
                            validate={isRequired}
                            isTouched={formikProps.touched.dateTypes}
                            isError={formikProps.errors.dateTypes}
                            value={formikProps.values.dateTypes}
                            isEdit={isEdit}
                          />
                        </div>
                        {/* {console.log(formikProps.values)} */}
                        {formikProps.values.dateTypes !== "routine" && (
                          <div>
                            <Grid divided="vertically">
                              <Grid.Row columns={1} style={{ height: "150px" }}>
                                <Grid.Column>
                                  <label className="formLabel slot_schedule">
                                    Date
                                  </label>
                                  <div className="formMargin specific">
                                    <Field
                                      name="specific_date"
                                      component={RenderMultipleDatePicker}
                                      dateformat="YYYY-MM-DD"
                                      onChange={(time) => {
                                        console.log(time);
                                        formikProps.setFieldValue(
                                          "specific_date",
                                          time
                                        );
                                      }}
                                      validate={isRequired}
                                      isTouched={
                                        formikProps.touched.specific_date
                                      }
                                      isError={formikProps.errors.specific_date}
                                      value={formikProps.values.specific_date}
                                    />
                                  </div>
                                </Grid.Column>

                                {/* <Grid.Column>
                                  <label className="formLabel slot_schedule">
                                    To
                                  </label>
                                  <div className="formMargin specific">
                                    <Field
                                      name="specific_to"
                                      component={RenderDatePicker}
                                      dateFormat="yyyy MMM dd"
                                      onChange={(time) => {
                                        formikProps.setFieldValue(
                                          "specific_to",
                                          time
                                        );
                                      }}
                                      validate={isRequired}
                                      isTouched={
                                        formikProps.touched.specific_to
                                      }
                                      isError={formikProps.errors.specific_to}
                                      value={formikProps.values.specific_to}
                                    />
                                  </div>
                                </Grid.Column> */}
                              </Grid.Row>
                            </Grid>
                            <Grid.Row row={16}>
                              <label className="formLabel slot_schedule">
                                Slot
                              </label>
                            </Grid.Row>
                            <Grid divided="vertically">
                              <Grid.Row columns={2}>
                                <Grid.Column>
                                  <div className="formMargin specific specific_slot">
                                    <Field
                                      name="specific_time_from"
                                      dateFormat="hh:mm a"
                                      component={RenderTimePicker}
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        7
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        23
                                      )}
                                      onChange={(time) => {
                                        formikProps.setFieldValue(
                                          "specific_time_from",
                                          time
                                        );
                                      }}
                                      validate={isRequired}
                                      isTouched={
                                        formikProps.touched.specific_time_from
                                      }
                                      isError={
                                        formikProps.errors.specific_time_from
                                      }
                                      value={
                                        formikProps.values.specific_time_from
                                      }
                                    />
                                  </div>
                                </Grid.Column>

                                <Grid.Column>
                                  <div className="formMargin specific specific_slot">
                                    <Field
                                      name="specific_time_to"
                                      component={RenderTimePicker}
                                      dateFormat="hh:mm a"
                                      onChange={(time) => {
                                        formikProps.setFieldValue(
                                          "specific_time_to",
                                          time
                                        );
                                      }}
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        7
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        23
                                      )}
                                      validate={isRequired}
                                      isTouched={
                                        formikProps.touched.specific_time_to
                                      }
                                      isError={
                                        formikProps.errors.specific_time_to
                                      }
                                      value={
                                        formikProps.values.specific_time_to
                                      }
                                    />
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </div>
                        )}
                        {formikProps.values.dateTypes === "routine" && (
                          <div>
                            <label className="formLabel">Month</label>
                            <div className="formMargin month_schedule">
                              <Field
                                name="months"
                                // component={RenderRadioGroup}
                                component={RenderMultipleRadioGroupMonth}
                                options={this.props.months}
                                onChange={formikProps.handleChange("months")}
                                validate={isRequired}
                                isTouched={formikProps.touched.months}
                                isError={formikProps.errors.months}
                                value={formikProps.values.months}
                                formikProps={formikProps}
                                _onChangeForMultipleMonth={
                                  this._onChangeForMultipleMonth
                                }
                              />
                            </div>

                            <label className="formLabel">Day</label>
                            <div className="formMargin day_schedule">
                              <Field
                                name="days"
                                // component={RenderRadioGroup}
                                component={RenderMultipleRadioGroupDay}
                                options={this.props.days}
                                onChange={formikProps.handleChange("days")}
                                validate={isRequired}
                                isTouched={formikProps.touched.days}
                                isError={formikProps.errors.days}
                                value={formikProps.values.days}
                                formikProps={formikProps}
                                _onChangeForMultipleDay={
                                  this._onChangeForMultipleDay
                                }
                              />
                            </div>

                            {/*<label className="formLabel">Sessions</label>
                        <div className="formMargin session_schedule">
                          <Field
                            name="session"
                            component={RenderRadioGroup}
                            options={this.props.sessions}
                            onChange={formikProps.handleChange("session")}
                            //validate={isRequired}
                            isTouched={formikProps.touched.session}
                            isError={formikProps.errors.session}
                          />
                        </div>*/}
                            <Grid.Row row={16}>
                              <label className="formLabel slot_schedule">
                                Slot
                              </label>
                            </Grid.Row>
                            <Grid divided="vertically">
                              <Grid.Row columns={2}>
                                <Grid.Column>
                                  <div className="formMargin">
                                    <Field
                                      name="from_time"
                                      component={RenderTimePicker}
                                      dateFormat="hh:mm a"
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        7
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        23
                                      )}
                                      onChange={(time) => {
                                        this.setState({
                                          from_time: time,
                                        });
                                      }}
                                      validate={isRequired}
                                      isTouched={formikProps.touched.from_time}
                                      isError={
                                        this.state.from_time > new Date()
                                          ? undefined
                                          : formikProps.errors.from_time
                                      }
                                      fromtoProps="From"
                                      value={
                                        isEdit
                                          ? this.state.from_time !== null
                                            ? (formikProps.values.from_time =
                                                this.state.from_time)
                                            : formikProps.values.from_time
                                          : this.state.from_time !== null
                                          ? (formikProps.values.from_time =
                                              this.state.from_time)
                                          : new Date()
                                      }
                                      // value={
                                      //   isEdit
                                      //     ? this.state.from_time > new Date()
                                      //       ? (formikProps.values.from_time =
                                      //           this.state.from_time)
                                      //       : new Date(formikProps.values.from_time)
                                      //     : this.state.from_time > new Date()
                                      //     ? (formikProps.values.from_time =
                                      //         this.state.from_time)
                                      //     : this.state.from_time
                                      // }
                                    />
                                  </div>
                                </Grid.Column>

                                <Grid.Column>
                                  <div className="formMargin">
                                    <Field
                                      name="to_time"
                                      component={RenderTimePicker}
                                      onChange={(time) => {
                                        this.setState({
                                          to_time: time,
                                        });
                                      }}
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        7
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        23
                                      )}
                                      validate={isRequired}
                                      dateFormat="hh:mm a"
                                      isTouched={formikProps.touched.to_time}
                                      isError={
                                        this.state.to_time > new Date()
                                          ? undefined
                                          : formikProps.errors.to_time
                                      }
                                      fromtoProps="To"
                                      value={
                                        isEdit
                                          ? this.state.to_time !== null
                                            ? (formikProps.values.to_time =
                                                this.state.to_time)
                                            : formikProps.values.to_time
                                          : this.state.to_time !== null
                                          ? (formikProps.values.to_time =
                                              this.state.to_time)
                                          : new Date()
                                      }
                                      // value={
                                      //   isEdit
                                      //     ? this.state.to_time > new Date()
                                      //       ? (formikProps.values.to_time =
                                      //           this.state.to_time)
                                      //       : new Date(formikProps.values.to_time)
                                      //     : this.state.to_time > new Date()
                                      //     ? (formikProps.values.to_time =
                                      //         this.state.to_time)
                                      //     : this.state.to_time
                                      // }
                                    />
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </div>
                        )}
                        {/*<label className="formLabel">Choose Color</label>
                        <div className="formMargin class_schedule">
                          <Field
                            name="class_ids"
                            component={RenderRadioGroup}
                            options={scheduleColors}
                            onChange={formikProps.handleChange("class_ids")}
                            validate={isRequired}
                            isTouched={formikProps.touched.class_ids}
                            isError={formikProps.errors.class_ids}
                            isColorListChkBox={true}
                          />
                        </div>*/}
                        <label className="formLabel">Type</label>
                        <div className="formMargin type_schedule">
                          <Field
                            name="type"
                            component={RenderRadioGroup}
                            options={this.props.type}
                            onChange={formikProps.handleChange("type")}
                            validate={isRequired}
                            isTouched={formikProps.touched.type}
                            isError={formikProps.errors.type}
                            value={formikProps.values.type}
                          />
                        </div>
                        {formikProps.values.type !== "" &&
                          formikProps.values.type !==
                            "7BFBdPuRsBkFoxlDnqaP" && (
                            <div>
                              <label className="formLabel">Title</label>
                              <div className="formMargin">
                                <Field
                                  name="title"
                                  placeholder="Title"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange("title")}
                                  validate={isRequired}
                                  isTouched={formikProps.touched.title}
                                  isError={formikProps.errors.title}
                                  value={formikProps.values.title}
                                />
                              </div>
                            </div>
                          )}
                        {formikProps.values.type === "knuZrNEdOqQ2M6bF8RUv" && (
                          <div className="formMargin icon_img_schedule">
                            <Field
                              name="icon_img"
                              component={RenderRadioGroup}
                              options={classActivities}
                              onChange={formikProps.handleChange("icon_img")}
                              validate={isRequired}
                              isTouched={formikProps.touched.icon_img}
                              isError={formikProps.errors.icon_img}
                              isimgPicker={true}
                              value={formikProps.values.icon_img}
                            />
                          </div>
                        )}
                        {formikProps.values.type === "V6tRBBDYhArEcVVjY9oT" && (
                          <div>
                            {/* {console.log(formikProps.values)} */}
                            <label className="formLabel">Item</label>
                            {this.state.meal_list.length === 0
                              ? null
                              : this.state.meal_list.map((data, id) => (
                                  <div key={id}>
                                    <div className="formMargin meal-list">
                                      <img
                                        onClick={() => this.Remove(data.id)}
                                        style={{
                                          float: "right",
                                          width: "24px",
                                          marginTop: "25px",
                                          height: "24px",
                                          marginRight: "10px",
                                        }}
                                        className=""
                                        src={CrossInfo}
                                      ></img>
                                      <Grid>
                                        <Grid.Row>
                                          <Grid.Column width={3}>
                                            <div className="image">
                                              <Field
                                                name={"img"}
                                                component={RenderImage}
                                                accept="image/*"
                                                disable="true"
                                                type="file"
                                                size="small"
                                                onChange={(e) =>
                                                  this.changeMeal("img", id, e)
                                                }
                                                previewUrl={
                                                  isEdit
                                                    ? typeof data.img !==
                                                      "string"
                                                      ? URL.createObjectURL(
                                                          data.img
                                                        )
                                                      : data.img
                                                    : URL.createObjectURL(
                                                        data.img
                                                      )
                                                }
                                              />
                                            </div>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={13}
                                            style={{ marginTop: "20px" }}
                                          >
                                            <Field
                                              name={"title"}
                                              placeholder="Title"
                                              component={RenderInput}
                                              onChange={(e) =>
                                                this.changeMeal("title", id, e)
                                              }
                                              value={
                                                isEdit
                                                  ? data.name === undefined
                                                    ? data.title
                                                    : data.name
                                                  : data.title
                                              }
                                            />
                                          </Grid.Column>
                                        </Grid.Row>
                                      </Grid>
                                    </div>
                                  </div>
                                ))}
                            <div>
                              <div className="formMargin meal-list">
                                <img
                                  onClick={() => {
                                    if (this.props.isEdit) {
                                      const delete_meal =
                                        this.state.meal_list_edit.find(
                                          (data) =>
                                            data.id ===
                                            formikProps.values.meal_id
                                        );
                                      this.state.meal_delete.push(delete_meal);
                                    }
                                    formikProps.setFieldValue("meal_id", "");
                                    formikProps.setFieldValue("meal_title", "");
                                    formikProps.setFieldValue("meal_img", "");
                                  }}
                                  style={{
                                    float: "right",
                                    marginTop: "25px",
                                    height: "24px",
                                    marginRight: "25px",
                                  }}
                                  className=""
                                />
                                <Grid>
                                  <Grid.Row>
                                    <Grid.Column width={3}>
                                      <div className="image">
                                        <Field
                                          name={"img"}
                                          component={RenderImage}
                                          accept="image/*"
                                          type="file"
                                          validate={isRequired}
                                          isTouched={formikProps.touched.img}
                                          isError={
                                            this.state.img === undefined
                                              ? "Please add an image!"
                                              : undefined
                                          }
                                          onChange={(e) =>
                                            formikProps.setFieldValue(
                                              "meal_img",
                                              e.currentTarget.files[0]
                                            )
                                          }
                                          isEdit={isEdit}
                                          size="small"
                                          previewUrl={
                                            isEdit
                                              ? formikProps.values.meal_img ===
                                                ""
                                                ? this.state.previewUrl
                                                : typeof formikProps.values
                                                    .meal_img === "string"
                                                ? formikProps.values.meal_img
                                                : URL.createObjectURL(
                                                    formikProps.values.meal_img
                                                  )
                                              : formikProps.values.meal_img ===
                                                ""
                                              ? this.state.previewUrl
                                              : URL.createObjectURL(
                                                  formikProps.values.meal_img
                                                )
                                          }
                                        />
                                      </div>
                                    </Grid.Column>
                                    {/* {console.log(this.state.title)} */}
                                    <Grid.Column
                                      width={13}
                                      style={{ marginTop: "20px" }}
                                    >
                                      <Field
                                        name="meal_title"
                                        placeholder="Title"
                                        component={RenderInput}
                                        onChange={formikProps.handleChange(
                                          "meal_title"
                                        )}
                                        value={formikProps.values.meal_title}
                                        validate={isRequired}
                                        isTouched={formikProps.touched.title}
                                        isError={
                                          this.state.title === undefined
                                            ? formikProps.errors.title
                                            : undefined
                                        }
                                      />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </div>
                            </div>
                            {/* {console.log(this.state.meal_list)} */}
                            <p
                              style={{
                                color: "#4075FF",
                                fontSize: "16px",
                                fontWeight: "600",
                                float: "right",
                                marginTop: "-18px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.AddMeal(formikProps)}
                            >
                              {" "}
                              Add
                            </p>
                          </div>
                          // <FieldArray
                          //   name="meal_list"
                          //   render={(arrayHelpers) => (
                          //     <div>
                          //       <label className="formLabel">Item</label>
                          //       {formikProps.values.meal_list.map(
                          //         (sent, index) => {
                          //           return (
                          //             <div key={index}>
                          //               <div className="formMargin meal-list">
                          //                 {formikProps.values.meal_list
                          //                   .length !== 1 ? (
                          //                   <img
                          //                     onClick={() =>
                          //                       arrayHelpers.remove(index)
                          //                     }
                          //                     style={{
                          //                       float: "right",
                          //                       width: "24px",
                          //                       marginTop: "25px",
                          //                       height: "24px",
                          //                       marginRight: "10px",
                          //                     }}
                          //                     className=""
                          //                     src={CrossInfo}
                          //                   ></img>
                          //                 ) : (
                          //                   <img
                          //                     onClick={() =>
                          //                       alert(
                          //                         "can not remove this item!"
                          //                       )
                          //                     }
                          //                     style={{
                          //                       float: "right",
                          //                       width: "24px",
                          //                       marginTop: "25px",
                          //                       height: "24px",
                          //                       marginRight: "10px",
                          //                     }}
                          //                     className=""
                          //                     src={CrossInfo}
                          //                   ></img>
                          //                 )}

                          //                 <Grid>
                          //                   <Grid.Row>
                          //                     <Grid.Column width={3}>
                          //                       <div className="image">
                          //                         <Field
                          //                           name={`meal_list[${index}].imgs`}
                          //                           component={RenderImage}
                          //                           accept="image/*"
                          //                           type="file"
                          //                           onChange={formikProps.handleChange(
                          //                             `meal_list[${index}].imgs`
                          //                           )}
                          //                           isEdit={isEdit}
                          //                           size="small"
                          //                           previewUrl={
                          //                             formikProps.values
                          //                               .meal_list[index]
                          //                               .imgs == "" ||
                          //                             formikProps.values
                          //                               .meal_list[index]
                          //                               .imgs === undefined
                          //                               ? this.state.previewUrl
                          //                               : `meal_list[${index}].imgs`
                          //                           }
                          //                         />
                          //                       </div>
                          //                     </Grid.Column>
                          //                     <Grid.Column
                          //                       width={13}
                          //                       style={{ marginTop: "20px" }}
                          //                     >
                          //                       <Field
                          //                         name={`meal_list[${index}].title`}
                          //                         placeholder="Title"
                          //                         component={RenderInput}
                          //                         onChange={formikProps.handleChange(
                          //                           `meal_list[${index}].title`
                          //                         )}
                          //                         validate={isRequired}
                          //                         isTouched={
                          //                           formikProps.touched.title
                          //                         }
                          //                         isError={
                          //                           formikProps.errors.title
                          //                         }
                          //                         value={
                          //                           formikProps.values
                          //                             .meal_list[`${index}`]
                          //                             .title
                          //                         }
                          //                       />
                          //                     </Grid.Column>
                          //                   </Grid.Row>
                          //                 </Grid>
                          //               </div>
                          //             </div>
                          //           );
                          //         }
                          //       )}
                          //       <p
                          //         style={{
                          //           color: "#4075FF",
                          //           fontSize: "16px",
                          //           fontWeight: "600",
                          //           float: "right",
                          //           marginTop: "-18px",
                          //         }}
                          //         onClick={() => arrayHelpers.push({})}
                          //       >
                          //         {" "}
                          //         Add
                          //       </p>
                          //     </div>
                          //   )}
                          // />
                        )}
                        {formikProps.values.type === "7BFBdPuRsBkFoxlDnqaP" && (
                          <div>
                            <label className="formLabel">Description</label>
                            <div className="formMargin">
                              <Field
                                name="description"
                                placeholder="Description"
                                component={RenderInput}
                                onChange={formikProps.handleChange(
                                  "description"
                                )}
                                validate={isRequired}
                                isTouched={formikProps.touched.description}
                                isError={formikProps.errors.description}
                                value={formikProps.values.description}
                              />
                            </div>
                          </div>
                        )}
                        <Field
                          name="scfa"
                          component={RenderCheckbox}
                          onChange={formikProps.handleChange("scfa")}
                          validate={isRequired}
                          isTouched={formikProps.touched.scfa}
                          isError={formikProps.errors.scfa}
                          value={formikProps.values.scfa}
                          label="SCFA"
                        />
                        <br />
                        <div className="fullWidthCenterAlign">
                          {isEdit && (
                            <Button
                              content="Delete Schedule"
                              type="button"
                              primary
                              size="large"
                              onClick={() =>
                                this.props.handleDelete(initialValues.id)
                              }
                              className="DelBtn"
                              style={{ marginRight: "20px" }}
                            />
                          )}
                          <Button
                            content="Save"
                            type="button"
                            primary
                            size="large"
                            onClick={() =>
                              this.props.handleSubmit(
                                formikProps.values,
                                this.state.meal_list,
                                this.state.meal_list_edit,
                                this.state.meal_delete
                              )
                            }
                            className="submitBtn"
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ScheduleForm;
