import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const FETCH_ATTENDANCE_DATA = "FETCH_ATTENDANCE_DATA";
export const FETCH_ATTENDANCE_DATA_SUCCESS = "FETCH_ATTENDANCE_DATA_SUCCESS";
export const FETCH_ATTENDANCE_DATA_FAILURE = "FETCH_ATTENDANCE_DATA_FAILURE";

export const fetchAttendanceData = (
  start_date,
  end_date,
  centre_id,
  class_id,
  scfa,
  page
) => ({
  type: FETCH_ATTENDANCE_DATA,
  payload: {
    start_date,
    end_date,
    centre_id,
    class_id,
    scfa,
    page,
  },
});

export const fetchAttendanceDataSuccess = (data) => ({
  type: FETCH_ATTENDANCE_DATA_SUCCESS,
  data,
});

export const fetchAttendanceDataFailure = () => ({
  type: FETCH_ATTENDANCE_DATA_FAILURE,
});

export const getAttendanceData = (
  start_date,
  end_date,
  centre_id,
  class_id,
  scfa,
  page
) => {
  return (dispatch) => {
    dispatch(fetchAttendanceData());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/attendance/",
            {
              start_date: start_date,
              end_date: end_date,
              centre_id: centre_id,
              class_id: class_id,
              scfa: scfa,
              page: page,
            },
            token
          )
          .then((res) => {
            dispatch(fetchAttendanceDataSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchAttendanceDataFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
