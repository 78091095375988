import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_ROOM = "FETCH_ROOM";
export const FETCH_ROOM_SUCCESS = "FETCH_ROOM_SUCCESS";
export const FETCH_ROOM_FAILURE = "FETCH_ROOM_FAILURE";
export const CLEAR_ADMIN = "CLEAR_ADMIN";

export const fetchRoom = (id) => ({
  type: FETCH_ROOM,
  id,
});

export const fetchRoomSuccess = (data) => ({
  type: FETCH_ROOM_SUCCESS,
  data,
});

export const fetchRoomFailure = () => ({
  type: FETCH_ROOM_FAILURE,
});

export const getRoomlist = (senderid, refresh) => {
  return (dispatch) => {
    if (refresh) {
      dispatch(fetchRoom());
    }

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/comms/rooms/", { sender: res.uid }, token)
          .then((res) => {
            dispatch(fetchRoomSuccess(res));
            // console.log("res", res);
            return res;
          })
          .catch((err) => dispatch(fetchRoomFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
