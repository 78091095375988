import {
  FETCH_DASHBOARD_DONATION,
  FETCH_DASHBOARD_DONATION_SUCCESS,
  FETCH_DASHBOARD_DONATION_FAILUTE,
} from "../actions";

const initialState = {
  dashboardDonation: [],
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DONATION:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_DASHBOARD_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: false,
        dashboardDonation: action.data,
      };
    case FETCH_DASHBOARD_DONATION_FAILUTE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        dashboardDonation: [],
      };
    default:
      return state;
  }
};
