import S3 from "react-aws-s3";

const config = {
  bucketName: "morning-star-dev",
  dirName: "videos",
  region: "ap-southeast-1",
  accessKeyId: "AKIAQFC7EOAWOA7CPMO7",
  secretAccessKey: "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",
};

const ReactS3Client = new S3(config);
const cloudfrontUrl = "https://d2t2athllyh1ku.cloudfront.net/";

export { ReactS3Client, cloudfrontUrl };
