import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_CURRENT_LOGIN_USER = "FETCH_CURRENT_LOGIN_USER";
export const FETCH_CURRENT_LOGIN_USER_SUCCESS =
  "FETCH_CURRENT_LOGIN_USER_SUCCESS";
export const FETCH_CURRENT_LOGIN_USER_FAILURE =
  "FETCH_CURRENT_LOGIN_USER_FAILURE";
export const CLEAR_CURRENT_LOGIN_USER = "CLEAR_CURRENT_LOGIN_USER";

export const fetchCurrentLoginUser = (id) => ({
  type: FETCH_CURRENT_LOGIN_USER,
  id,
});

export const fetchCurrentLoginUserSuccess = (data) => ({
  type: FETCH_CURRENT_LOGIN_USER_SUCCESS,
  data,
});

export const fetchCurrentLoginUserFailure = () => ({
  type: FETCH_CURRENT_LOGIN_USER_FAILURE,
});

export const clearCurrentLoginUser = () => ({
  type: CLEAR_CURRENT_LOGIN_USER,
});

export const getCurrentLoginUser = (id, selectedusrtype) => {
  return (dispatch) => {
    dispatch(fetchCurrentLoginUser());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(`/user/${"" + selectedusrtype}/`, { id: id }, token)
          .then((res) => {
            // console.log("doing", res);
            dispatch(fetchCurrentLoginUserSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchCurrentLoginUserFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
