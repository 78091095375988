import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

export default function Charts ({type, data, option}) {
  // console.log(type);
  if(type==="Bar"){
    return(
      <Bar 
      data={data} 
      height={160}
      options={option} />
  );
  }
  else if(type==="Line"){
    return(
      <Line 
      data={data} 
      height={240}
      options={option} />
    );
  }
  else{
    return(
      <div>No Data</div>
    )
  }
    

}