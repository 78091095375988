import React, { Component } from "react";
import { Radio, Grid, Message, Image } from "semantic-ui-react";
import { Field } from "formik";

const boxStyle = {
  backgroundColor: "#E6F1FC",
  border: "1px solid #4075FF",
  borderRadius: 8,
  boxSizing: "border-box",
};
class MultipleRadioGroupMonth extends Component {
  constructor(props) {
    super(props);
    let months = {
      Jan: false,
      Feb: false,
      Mar: false,
      Apr: false,
      May: false,
      June: false,
      July: false,
      Aug: false,
      Sep: false,
      Oct: false,
      Nov: false,
      Dec: false,
    };
    props.value.forEach((item) => {
      switch (item) {
        case "Jan":
          months.Jan = true;
          break;
        case "Feb":
          months.Feb = true;
          break;
        case "Mar":
          months.Mar = true;
          break;
        case "Apr":
          months.Apr = true;
          break;
        case "May":
          months.May = true;
          break;
        case "Jun":
          months.June = true;
          break;
        case "Jul":
          months.July = true;
          break;
        case "Aug":
          months.Aug = true;
          break;
        case "Sep":
          months.Sep = true;
          break;
        case "Oct":
          months.Oct = true;
          break;
        case "Nov":
          months.Nov = true;
          break;
        case "Dec":
          months.Dec = true;
          break;
      }
    });
    this.state = {
      Jan: months.Jan,
      Feb: months.Feb,
      Mar: months.Mar,
      Apr: months.Apr,
      May: months.May,
      June: months.June,
      July: months.July,
      Aug: months.Aug,
      Sep: months.Sep,
      Oct: months.Oct,
      Nov: months.Nov,
      Dec: months.Dec,
    };
    props.value.forEach((item) => {
      switch (item) {
        case "Jan":
          months.Jan = true;
          break;
        case "Feb":
          months.Feb = true;
          break;
        case "Mar":
          months.Mar = true;
          break;
        case "Apr":
          months.Apr = true;
          break;
        case "May":
          months.May = true;
          break;
        case "Jun":
          months.June = true;
          break;
        case "Jul":
          months.July = true;
          break;
        case "Aug":
          months.Aug = true;
          break;
        case "Sep":
          months.Sep = true;
          break;
        case "Oct":
          months.Oct = true;
          break;
        case "Nov":
          months.Nov = true;
          break;
        case "Dec":
          months.Dec = true;
          break;
      }
    });
    this.state = {
      Jan: months.Jan,
      Feb: months.Feb,
      Mar: months.Mar,
      Apr: months.Apr,
      May: months.May,
      June: months.June,
      July: months.July,
      Aug: months.Aug,
      Sep: months.Sep,
      Oct: months.Oct,
      Nov: months.Nov,
      Dec: months.Dec,
    };
  }

  _activeBlock(label) {
    this.setState({
      [label]: !this.state[label],
    });
  }

  render() {
    return (
      <div>
        <Grid divided="vertically">
          <Grid.Row columns={3}>
            {this.props.options.map((data, ix) => {
              //   console.log(data);
              let styleBox = {};
              if (this.state[data.label]) {
                styleBox = boxStyle;
              }
              return (
                <Grid.Column key={ix} className={"radio-toolbar"}>
                  <div
                    style={styleBox}
                    onClick={() => {
                      this._activeBlock(data.label);
                      this.props._onChangeForMultipleMonth(
                        data.value,
                        this.props.formikProps
                      );
                    }}
                  >
                    <span>{data.label}</span>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
        {this.props.isError && this.props.isTouched && (
          <Message visible error content={this.props.isError} />
        )}
      </div>
    );
  }
}
export default MultipleRadioGroupMonth;
