import React, { useState, useEffect } from "react";
import { Route } from "react-router";
import { APP_MAINTENANCE_ROUTE } from "../../routes";
import MaintenanceDash from "./MaintenanceDash";
import { firebase } from "../../config/firebase";
import { getCurrentLoginUser } from "../../store/actions";
import { connect } from "react-redux";
import AccessDeniedPage from "../error/AccessDeniedPage";

function AppMaintenance(props) {
  const [isAccess, setIsAccess] = useState(true);

  useEffect(() => {
    firebase.reloadAuth().then((res) => {
      props.getCurrentLoginUser(res.uid, "admin");
      console.log(props.userDetail);
      if (props.userDetail.is_super === 0) {
        setIsAccess(false);
      } else {
        setIsAccess(true);
      }
    });
  }, []);

  if (isAccess) {
    return (
      <Route exact path={APP_MAINTENANCE_ROUTE} component={MaintenanceDash} />
    );
  }
  return (
    <Route exact path={APP_MAINTENANCE_ROUTE} component={AccessDeniedPage} />
  );
}

const mapStateToProps = (state) => ({
  userDetail: state.currentLoginUser.currentLoginUser.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMaintenance);
