import React, { Component } from 'react';
import {arrayInsert} from 'redux-form';
import { Button,Radio, Grid, Checkbox, Form } from 'semantic-ui-react';

class RenderCheckboxGroup extends Component {
  render() {   
    return (
      <Grid divided='vertically'>
        <Grid.Row >
          {
            this.props.options.map((data,ix) =>{   
              let isChk = this.props.value !== undefined && this.props.value.includes(data.value);  
              return(
            <Grid.Column key={ix} className="radio-toolbar" style={{width: '153px'}} >
              <label>
              <Form.Field
                  control="input"
                  type="checkbox"
                  onChange={this.props.onChange}
                  value={data.value}             
                  />
                  {
                    isChk ? <span className="checked">{data.label}</span> : <span>{data.label}</span>
                  }      
              </label>
            </Grid.Column>)}
          )}
        </Grid.Row>
      </Grid>
    )
  }
}
export default RenderCheckboxGroup;
// const RenderPButtonGroup = (props) => {
//   return (
//     <Grid>
//       <Grid.Row columns={2}>
//         <div className="classGp">
//         {
//           props.options.map((data, ix) => { 
//             let isChk = props.value !== undefined && props.value.includes(data.value);
//             return (
//               <Grid.Column key={ix} className="radio-toolbar" >
//                 <label>
//                  <Form.Field
//                   control="input"
//                   type="checkbox"
//                   onChange={props.onChange}
//                   value={data.value}             
//                   />
//                   {
//                     isChk ? <span className="checked">{data.text}</span> : <span>{data.text}</span>
//                   }                 
//                  </label>
//               </Grid.Column>           
//             )
//           })
//         }</div>
//       </Grid.Row>
//     </Grid>
//   );
// };