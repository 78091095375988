import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const FETCH_MERCHANDISE_ALL = "FETCH_MERCHANDISE_ALL";
export const FETCH_MERCHANDISE_ALL_SUCCESS = "FETCH_MERCHANDISE_ALL_SUCCESS";
export const FETCH_MERCHANDISE_ALL_FAILURE = "FETCH_MERCHANDISE_ALL_FAILURE";

export const fetchMerchandiseAll = () => ({
  type: FETCH_MERCHANDISE_ALL,
});

export const fetchMerchandiseAllSuccess = (data) => ({
  type: FETCH_MERCHANDISE_ALL_SUCCESS,
  data,
});

export const fetchMerchandiseAllFailure = (err) => ({
  type: FETCH_MERCHANDISE_ALL_FAILURE,
  err,
});

export const getMerchandiseAll = (merchandise_type, activePage) => {
  if (merchandise_type === "All") {
    merchandise_type = "";
  }
  return (dispatch) => {
    dispatch(fetchMerchandiseAll());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        if (merchandise_type === null) {
          api
            .get("/merchandise/web_list/", { page: activePage }, token)
            .then((res) => {
              dispatch(fetchMerchandiseAllSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchMerchandiseAllFailure(err)));
        } else {
          api
            .get(
              "/merchandise/web_list/",
              {
                merchandise_type_id: merchandise_type,
                page: activePage,
              },
              token
            )
            .then((res) => {
              dispatch(fetchMerchandiseAllSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchMerchandiseAllFailure(err)));
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
};
