import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";


export const changePassword = () => ({
  type: CHANGE_PASSWORD,
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (err) => ({
  type: CHANGE_PASSWORD_FAILURE,
  err,
});

export const changePasswordActions = (email,password,handleCallback) => {
    var formData = new FormData();
    formData.append('email',email);
    formData.append('password',password);

    return (dispatch) => {
        dispatch(changePassword());
        return firebase.reloadAuth().then((res) => {
            try {
                const token = res.ya === null ? res.za : res.ya;

                console.log(token);

                api
                    .post("/user/change_password/", formData,token)
                    .then((res) => {
                        handleCallback && handleCallback(true);
                        dispatch(changePasswordSuccess())
                    })
                    .catch((err) =>{
                        console.log(err);
                        handleCallback && handleCallback(false);
                        dispatch(changePasswordFailure(err));
                    } )
                } catch (err) {
                    console.log(err);
                    handleCallback && handleCallback(false);
                }
            });
    };
};
