import newsfeed from "../../views/newsfeed";
import {
  GET_NEWSFEED,
  GET_NEWSFEED_FAILURE,
  GET_NEWSFEED_SUCCESS,
  LOADMORE_NEWSFEED,
  LOADMORE_NEWSFEED_FAILURE,
  LOADMORE_NEWSFEED_SUCCESS,
} from "../actions/get_newsfeed";

const initialState = {
  newsfeed: false,
  loading: true,
  err: null,
  haveData: false,
  nextUrl: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWSFEED:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
        newsfeed: [],
      };

    case GET_NEWSFEED_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        newsfeed: [...action.data.data],
        nextUrl: action.data.next,
      };

    case GET_NEWSFEED_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        newsfeed: [],
      };
    case LOADMORE_NEWSFEED:
      return {
        ...state,
        newsfeed: [...state.newsfeed],
        loading: false,
        haveData: true,
        nextUrl: null,
      };
    case LOADMORE_NEWSFEED_SUCCESS:
      return {
        ...state,
        loading: false,
        newsfeed: [...state.newsfeed, ...action.data.data],
        haveData: true,
        nextUrl: action.data.next,
      };
    case LOADMORE_NEWSFEED_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        newsfeed: [...state.newsfeed],
        err: action.err,
      };
    default:
      return state;
  }
};
