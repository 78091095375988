import React from 'react';
import { Button, Grid, Header, Image, Message } from 'semantic-ui-react';
import { Formik, Field, Form } from "formik";
import {LOGIN_ROUTE} from '../../routes';
import RoutingButton from '../../components/RoutingButton';
import logo from '../../assets/images/logo.png';
import RenderInput from '../../components/TextInput';
import RenderCheckboxGroup from '../../components/RenderCheckboxGroup';
import { isRequired, isEmail } from '../../validators';

const handleSubmit = (values) => {
  console.log(values);
  console.log("signup hit")
}

const SignUp = () => {
  const [chkboxList, setChkboxList] = React.useState([
      {
        value: "pdpa consent",
        label: "PDPA Consent"
      },
      {
        value: "privacy_policy",
        label: "Privacy and Policy"
      }
  ]);

  return(
    <Grid textAlign='center' className="authFom" verticalAlign='middle'>
      <Grid.Column>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={LOGIN_ROUTE}
              className="backBtn"
              btnText="Sign up"
              showIcon={true}
            />
          </Grid.Column>
        </Grid.Row>
        <br/>
        <Formik
          initialValues={{ email: "", password: "", confirmpassword: "", chkboxdata: ""  }}
          onSubmit={handleSubmit}
          >
          {
            formikProps => {
              return(
                <Form>
                  <Header as='h4' textAlign='left'>Email</Header>
                  <Field
                    name="email"
                    component={RenderInput}
                    onChange={formikProps.handleChange('email')}
                    validate={isEmail}
                    isTouched={formikProps.touched.email}
                    isError={formikProps.errors.email}
                    />

                  <Header as='h4' textAlign='left'>Password</Header>
                  <Field
                    name="password"
                    type="password"
                    component={RenderInput}
                    onChange={formikProps.handleChange('password')}
                    validate={isRequired}
                    isTouched={formikProps.touched.password}
                    isError={formikProps.errors.password}
                    />

                  <Header as='h4' textAlign='left'>Confirm Password</Header>
                  <Field
                    name="confirmpassword"
                    type="password"
                    component={RenderInput}
                    onChange={formikProps.handleChange('confirmpassword')}
                    validate={isRequired}
                    isTouched={formikProps.touched.confirmpassword}
                    isError={formikProps.errors.confirmpassword}
                    />

                    <Message>
                      <Field
                        name="chkboxdata"
                        component={RenderCheckboxGroup}
                        options={chkboxList}
                        onChange={formikProps.handleChange('chkboxdata')}
                        validate={isRequired}
                        isTouched={formikProps.touched.chkboxdata}
                        isError={formikProps.errors.chkboxdata}
                        />
                    </Message>
                  <Button content='Sign up' primary fluid size='large' type="submit"/>
                </Form>
              )
            }
          }
        </Formik>
      </Grid.Column>
    </Grid>
  )
}

export default SignUp
