import React from "react";
import { Button, Grid, Header,Icon } from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import { LOGIN_ROUTE } from "../../routes";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import RoutingButton from "../../components/RoutingButton";
import RenderInput from "../../components/TextInput";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { isEmail } from "../../validators";
import { Link } from 'react-router-dom';

const handleSubmit = (values) => {};

const PasswordReset = (props) => (
  <Grid textAlign="center" className="authFom" verticalAlign="middle">
    <Grid.Column style={{maxWidth: 400, height: 470}}>
      <div style={{paddingLeft:'20px'}}>
      <Grid.Row>   
        <Grid.Column width={16}>
          <Header as="h3" floated="left">
            <Link to={LOGIN_ROUTE}>
              <Icon name='chevron left' className="backChevron"/>
            </Link>Forgot password
          </Header>
          {/* <RoutingButton
            to={LOGIN_ROUTE}
            className="backBtn"
            showIcon={true}
          /> */}
        </Grid.Column>
      </Grid.Row>
      </div>
      <br />
      <div style={{padding:'0px 40px'}}>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as="h5" textAlign="left">
            Please enter your email address below. You will receive a link to
            reset your password.
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values) => {
          console.log(values);
          const email = values.email;
          var formData = new FormData();
          formData.append("email", email);
          api
            .post("/user/forgot_password/", formData)
            .then((res) => {
              if (res.message === "Success") {
                console.log("Successful");
                // console.log(props);
                props.dispatch(push(LOGIN_ROUTE));
              } else {
                let message = res.message;
                alert(message);
              }
              console.log(res);
            })
            .catch((err) => console.log(err));
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <Header as="h4" textAlign="left">
                Email
              </Header>
              <Field
                name="email"
                component={RenderInput}
                onChange={formikProps.handleChange("email")}
                validate={isEmail}
                isTouched={formikProps.touched.email}
                isError={formikProps.errors.email}
              />
              <Button
                content="submit"
                primary
                fluid
                size="large"
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
      </div>
    </Grid.Column>
  </Grid>
);

export default connect()(PasswordReset);
