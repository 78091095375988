import { connect } from "react-redux";
import React, { Component, createRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Segment,
  Grid,
  Header,
  Message,
  Icon,
  Modal,
  Dropdown,
  Dimmer,
  Loader,
  Checkbox,
  Button,
  Card,
  Feed,
  Image,
  Divider,
  Container,
  Input,
  TextArea,
} from "semantic-ui-react";
import { getAllNewsfeed } from "../../store/actions/get_newsfeed";
import Exclamation from "../../assets/images/exclamation.png";
import InfiniteScroll from "react-infinite-scroll-component";
import placeholderImage from "../../assets/images/placeholder_image.png";
import ic_add_image_file from "../../assets/images/ic_add_image_file.svg";
import { firebase } from "../../config/firebase";
import moment from "moment";
import base64encode from "@wmakeev/base64encode";
import close from "../../assets/images/close.png";
import { postNewsfeeds } from "../../store/actions/postNewsfeed";
import placeholderImageProfile from "../../assets/images/ic_account.png";
import RenderNewsFeed from "../../components/newFeedImgandVd";
import ReactPlayer from "react-player";
import { thisStringValue } from "es-abstract";
import { width } from "dom-helpers";
import { arrayPop } from "redux-form";
import api from "../../config/api";
import Nonewfeed from "../../assets/images/Group 221.png";
import { ReactS3Client, cloudfrontUrl } from "../../config/awsConfig";

class NewsfeedDash extends Component {
  constructor(props) {
    super(props);
    this.imgFile = React.createRef();
  }

  state = {
    file: null,
    fileShow: ic_add_image_file,
    title: "",
    description: "",
    priority: false,
    is_posting: false,
    deletePost: false,
    deleteLoading: false,
    deleteId: null,
    permissionDenied: false,
    seeMore: false,
  };

  componentDidMount() {
    this.props.getAllNewsfeed(0, 10, false, "", true);
  }

  _handleSubmit = () => {
    console.log("handleSubmit...");
    if (
      this.state.title !== "" &&
      this.state.description !== "" &&
      this.state.file !== null
    ) {
      this.setState({
        is_posting: true,
      });
      if (this.state.file.type.includes("video")) {
        const vName = this.state.file.name.split(".");
        const filename =
          vName[0] + "-" + moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
        const final_filename = base64encode(filename);
        const VType = this.state.file.type.split("/");
        const VName = final_filename + "." + VType[1];
        let file = this.state.file;
        ReactS3Client.uploadFile(file, VName).then((data) => {
          this.setState(
            {
              video: `${cloudfrontUrl}${data.key}`,
            },
            () => {
              let formData = new FormData();
              formData.append("title", this.state.title);
              formData.append("description", this.state.description);
              formData.append("video_url", this.state.video);
              // formData.append("thumbnail_url", " ");
              formData.append(
                "high_priority",
                this.state.priority === true ? 1 : 0
              );
              return firebase.reloadAuth().then((res) => {
                try {
                  const token = res.ya === null ? res.za : res.ya;
                  api
                    .post("/newsfeeds/newsfeed", formData, token)
                    .then((res) => {
                      if (res.message === "success") {
                        this.props.getAllNewsfeed(0, 10, false, "", true);
                        this.setState({
                          is_posting: false,
                          title: "",
                          description: "",
                          priority: false,
                          file: null,
                          fileShow: ic_add_image_file,
                        });
                      } else {
                        console.log(res);
                      }
                    });
                } catch (err) {
                  console.log(err);
                }
              });
            }
          );
        });
      } else {
        console.log("not video...");
        let formData = new FormData();
        formData.append("title", this.state.title);
        formData.append("description", this.state.description);
        formData.append("img_url", this.state.file);
        formData.append("high_priority", this.state.priority === true ? 1 : 0);
        return firebase.reloadAuth().then((res) => {
          try {
            const token = res.ya === null ? res.za : res.ya;
            api.post("/newsfeeds/newsfeed", formData, token).then((res) => {
              if (res.message === "success") {
                this.setState({
                  title: "",
                  description: "",
                  priority: false,
                  file: null,
                  fileShow: ic_add_image_file,
                  is_posting: false,
                });
                this.props.getAllNewsfeed(0, 10, false, "", true);
              } else {
                console.log(res);
              }
            });
          } catch (err) {
            console.log(err);
          }
        });
      }
      //   this.props.postNewsfeed(
      //     this.state.title,
      //     this.state.description,
      //     this.state.file,
      //     undefined,
      //     undefined,
      //     (status) => {
      //       this.setState({
      //         title: "",
      //         description: "",
      //         priority: false,
      //         file: null,
      //         fileShow: ic_add_image_file,
      //         is_posting: false,
      //       });
      //     }
      //   );
      // if (this.state.file.type.includes("image")) {
      //   console.log(this.state.file.type);
      // } else {
      //   console.log("video");
      // }
    }
  };

  _renderPriority = (item) => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>{/* <p>{item.post_owner}</p> */}</Grid.Column>
          <Grid.Column>
            <p>{item.post_owner}</p>
            <div
              style={{
                backgroundColor: "#4075FF",
                color: "white",
                borderRadius: 30,
                paddingTop: 3,
                paddingBottom: 3,
                paddingRight: 5,
                paddingLeft: 5,
                alignSelf: "center",
                width: 95,
              }}
            >
              High Priority
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  fileChange = (e) => {
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].type.includes("image")) {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            fileShow: reader.result,
            file: file,
          });
        };
        reader.readAsDataURL(file);
      } else {
        this.setState({
          fileShow: URL.createObjectURL(e.target.files[0]),
          file: e.target.files[0],
        });
      }
    }
    // this.setState({
    //   file: e.target.files[0],
    //   fileShow: URL.createObjectURL(e.target.files[0]),
    // });
  };

  deletePost = () => {
    this.setState({
      deleteLoading: true,
    });
    let delForm = new FormData();
    delForm.append("id", this.state.deleteId);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        api.del("/newsfeeds/newsfeed/", delForm, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              deletePost: false,
              deleteId: null,
              deleteLoading: false,
            });
            this.props.getAllNewsfeed(0, 10, false, "", true);
          } else if (res.message === "Permission denied to delete this post.") {
            this.setState({
              deletePost: false,
              deleteId: null,
              deleteLoading: false,
              permissionDenied: true,
            });
          } else {
            console.log(res);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  truncate = (str) => {
    return str.length > 10 ? str.substring(0, 11) + "..." : str;
  };

  render() {
    const { newsfeed = [], hasmoreNewsfeed } = this.props;
    return (
      <Grid
        width={16}
        className="fullHeight1"
        style={{ backgroundColor: "#F9FBFF", height: "auto" }}
      >
        <Modal
          open={this.state.permissionDenied}
          style={{ width: "25%" }}
          onClose={() => this.setState({ permissionDenied: false })}
        >
          <Modal.Header>Permission Denied!</Modal.Header>
          <Modal.Content>
            Deleting post is not successful! You can only delete your own post!
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              positive
              style={{
                width: "120px",
                borderRadius: "20px",
                backgroundColor: "#4075ff",
                marginLeft: "0px",
              }}
              onClick={() => this.setState({ permissionDenied: false })}
            >
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.deletePost}
          style={{
            width: "25%",
            height:
              window.innerWidth >= 1600
                ? 410
                : window.innerWidth >= 1500
                ? 400
                : window.innerWidth >= 1400
                ? 370
                : window.innerWidth >= 1250
                ? 370
                : 370,
          }}
          onClose={() => this.setState({ deletePost: false, deleteId: null })}
        >
          <Dimmer active={this.state.deleteLoading} inverted>
            <Loader inverted size={"big"} />
          </Dimmer>
          <img
            src={Exclamation}
            style={{
              width: "20%",
              textAlign: "center",
              position: "relative",
              left: "40%",
              top: "50px",
            }}
          ></img>
          <h1
            style={{
              textAlign: "center",
              marginTop: "85px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "33px",
            }}
          >
            Delete the post
          </h1>
          <p
            style={{
              textAlign: "center",
              width: "65%",
              position: "relative",
              left: "19%",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "19px",
            }}
          >
            Are you sure you want to permanently delete the post?
          </p>
          <Button
            positive
            style={{
              position: "relative",
              left: "12%",
              width: "37%",
              top: "50px",
              borderRadius: "20px",
              height: "48px",
              backgroundColor: "#FFF",
              border: "1px solid #4075FF",
              color: "#4075FF",
            }}
            onClick={() => this.setState({ deletePost: false, deleteId: null })}
          >
            Cancel
          </Button>
          <Button
            positive
            style={{
              position: "relative",
              left: "13%",
              width: "37%",
              top: "50px",
              borderRadius: "20px",
              height: "48px",
              backgroundColor: "#4075FF",
            }}
            onClick={this.deletePost}
          >
            Yes
          </Button>
        </Modal>
        <Grid.Row className="padding">
          <Grid.Column
            className="disable-scrollbars padding0"
            style={{
              overflowY: "scroll",
              flexDirection: "column",
              height: "100vh",
              flex: 1,
            }}
          >
            <div style={{ margin: "50px 50px 35px 50px" }}>
              <h1 floated="left" className="commsHeader">
                Newsfeed
              </h1>
            </div>
            <Dimmer active={this.state.is_posting} inverted>
              <Loader inverted size={"middle"} />
            </Dimmer>
            <Grid.Row
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                display: "flex",
                margin: "0px 50px",
                height: "auto",
              }}
            >
              <Grid style={{ width: "336px", padding: "20px 0px" }}>
                <Grid.Row>
                  <Grid.Column width={1} verticalAlign="middle">
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        borderRadius: 20,
                        backgroundColor: "#4075FF",
                      }}
                    ></div>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <p style={{ fontWeight: "bold", fontSize: 16 }}>
                      Create new post
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column
                    width={1}
                    verticalAlign="middle"
                    style={{ paddingTop: 2 }}
                  >
                    <Checkbox
                      checked={this.state.priority}
                      onChange={() => {
                        this.setState({
                          priority: !this.state.priority,
                        });
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <p>High priority</p>
                  </Grid.Column>
                </Grid.Row>
                <p style={{ fontSize: 14 }}>
                  For your image/video to fit perfectly in the landscape ratio,
                  we recommend uploading it in 16:9 ratio (1920x1080p).
                </p>
                {this.state.file !== null && (
                  <div style={{ height: "0px", width: "100%" }}>
                    <div
                      style={{
                        backgroundColor: "#4075FF",
                        position: "relative",
                        zIndex: 100,
                        float: "right",
                        left: 1,
                        top: 0,
                        width: 40,
                        height: 40,
                        padding: 5,
                        borderTopRightRadius: 10,
                      }}
                      onClick={() =>
                        this.setState({
                          file: null,
                          fileShow: ic_add_image_file,
                        })
                      }
                    >
                      <Image
                        src={close}
                        verticalAlign="middle"
                        style={{
                          height: 14,
                          width: 14,
                          marginLeft: "8px",
                          marginTop: "4px",
                        }}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {/* <RenderNewsFeed
                  name="image or video"
                  onChange={this.props.onChange}
                  previewUrl={this.props.previewUrl}
                /> */}
                  {this.state.file === null ||
                  this.state.file.type.includes("image") ? (
                    <label
                      className="fileLabel2"
                      style={{
                        backgroundImage: "url(" + this.state.fileShow + ")",

                        backgroundRepeat: "no-repeat",
                        backgroundSize:
                          this.state.fileShow === ic_add_image_file
                            ? "20%"
                            : "cover",
                        backgroundPositionX:
                          this.state.fileShow === ic_add_image_file && "55%",
                        backgroundPositionY:
                          this.state.fileShow === ic_add_image_file && "55%",
                        backgroundColor: "#F9FBFF",
                      }}
                    >
                      <input
                        type="file"
                        id="file"
                        previewUrl={this.state.fileShow}
                        accept="image/png, image/gif, image/jpeg, video/mp4,video/x-m4v,video/x-ms-wmv,video/*"
                        style={{ display: "none" }}
                        onChange={(e) => this.fileChange(e)}
                      />
                    </label>
                  ) : (
                    this.state.file !== null &&
                    this.state.file.type.includes("video") && (
                      <div className="fileLabel3">
                        <ReactPlayer
                          url={this.state.fileShow}
                          controls={true}
                          width="100%"
                          height="20vh"
                          light={true}
                        >
                          <input
                            type="file"
                            id="file"
                            previewUrl={this.state.fileShow}
                            accept="image/png, image/gif, image/jpeg, video/mp4,video/x-m4v,video/*"
                            style={{ display: "none" }}
                            onChange={(e) => this.fileChange(e)}
                          />
                        </ReactPlayer>
                      </div>
                    )
                  )}

                  {/* <div
                        style={{
                            // paddingTop:30,
                            // paddingBottom:30,
                            // paddingRight:50,
                            // paddingLeft:50,
                            height: "25vh",
                    width: "100%",
                    borderWidth:
                      this.state.image_file !== null &&
                      this.state.image_file !== undefined
                        ? 0
                        : 1,
                    borderRadius: 10,
                    borderStyle: "dashed",
                    borderColor: "blue",
                  }}
                  onClick={() =>
                    this.state.image_file !== null &&
                    this.state.image_file !== undefined
                      ? console.log("hello")
                      : this.imgFile.current.click()
                  }
                >
                  {this.state.image_file !== null &&
                  this.state.image_file !== undefined ? (
                    <div style={{ height: "100%", width: "100%" }}>
                      <div
                        style={{
                          backgroundColor: "blue",
                          position: "absolute",
                          zIndex: 100,
                          right: 40,
                          padding: 5,
                          borderTopRightRadius: 10,
                        }}
                        onClick={() =>
                          this.setState({
                            image_file: null,
                          })
                        }
                      >
                        <Image
                          src={close}
                          verticalAlign="middle"
                          style={{
                            height: 10,
                            width: 10,
                          }}
                        />
                      </div>
                      <Image
                        src={URL.createObjectURL(this.state.image_file)}
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: 10,
                        }}
                      />
                    </div>
                  ) : (
                    <Image
                      src={ic_add_image_file}
                      style={{ height: 23, width: 23 }}
                    />
                  )}
                </div> */}
                </div>
                <div style={{ height: 20 }} />
                <div style={{ width: "100%" }}>
                  <p>Title</p>
                  <Input
                    style={{ width: "100%", borderRadius: 8 }}
                    value={this.state.title}
                    onChange={(event) =>
                      this.setState({
                        title: event.target.value,
                      })
                    }
                  />
                </div>

                <div style={{ height: 20 }} />
                <div style={{ width: "100%" }}>
                  <p>Description</p>
                  <TextArea
                    style={{
                      width: "100%",
                      borderColor: "#DADADA",
                      resize: "none",
                      borderRadius: 8,
                      padding: 16,
                    }}
                    rows={14}
                    value={this.state.description}
                    onChange={(event) =>
                      this.setState({
                        description: event.target.value,
                      })
                    }
                  />
                </div>

                <Grid.Row style={{ marginTop: "25px" }}>
                  <Grid.Column width={8}>
                    <Button
                      basic
                      color={"blue"}
                      style={{
                        borderRadius: 20,
                        height: 48,
                        width: "100%",
                        lineHeight: "19px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        this.setState({
                          title: "",
                          description: "",
                          priority: false,
                          file: null,
                          fileShow: ic_add_image_file,
                        });
                        toast.success("🦄 Wow so easy!", {
                          position: "bottom-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Button
                      disabled={
                        this.state.title === "" &&
                        this.state.description === "" &&
                        this.state.image_file === null
                      }
                      style={{
                        borderRadius: 20,
                        backgroundColor:
                          this.state.title !== "" &&
                          this.state.description !== "" &&
                          this.state.image_file !== null
                            ? "#4075FF"
                            : "#7D7979",
                        color: "white",
                        height: 48,
                        width: "100%",
                      }}
                      onClick={this._handleSubmit}
                    >
                      Post
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column
            className="disable-scrollbars padding0 "
            style={{
              overflowY: "scroll",
              width: "375px",
              height: "100vh",
              display: "inline-flex",
              right: 0,
              // border: "1px soild #DCDDDF",
              border: "1px solid #dcdddf",
            }}
          >
            <Grid
              className="noBottomMargin "
              style={{ margin: "0px 0px", height: "100vh", width: "100%" }}
            >
              <Grid.Row
                style={{
                  height: "78px",
                  padding: 0,
                  zIndex: 1,
                  position: "sticky",
                  top: 0,
                }}
              >
                <Grid.Column
                  style={{
                    width: "100%",
                    padding: "0px",
                    backgroundColor: "rgb(64, 117, 255)",
                  }}
                >
                  <h1
                    floated="left"
                    className="commsHeader"
                    style={{
                      color: "white",
                      fontSize: "18px",
                      padding: "28px 25px",
                    }}
                  >
                    Newsfeed
                  </h1>
                </Grid.Column>
              </Grid.Row>
              <Dimmer
                active={this.props.loading || this.state.deleteLoading}
                inverted
                // active={true}
              >
                <Loader inverted size={"big"} />
              </Dimmer>

              {this.props.loading === false && newsfeed.length === 0 ? (
                <Grid.Row
                  style={{
                    height: "92vh",
                    padding: 0,
                    backgroundColor: "#F9FBFF",
                  }}
                >
                  <Grid.Column style={{ padding: 0, height: "92.2vh" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        top: "325px",
                      }}
                    >
                      <img src={Nonewfeed} />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <Grid.Row
                  id="scrollableDiv"
                  style={{
                    padding: "20px 15px 0px 15px",
                    backgroundColor: "#F9FBFF",
                    overflowX: "hidden",
                    height: "92vh",
                  }}
                  className="scrollHidden newsFeed_Sroll"
                >
                  <Grid.Column style={{ padding: 0 }}>
                    {newsfeed && (
                      <InfiniteScroll
                        // className="infinite-scroll"
                        dataLength={newsfeed.length}
                        scrollableTarget="scrollableDiv"
                        loader={
                          <div
                            style={{
                              backgroundColor: "#F9FBFF",
                              height: "0px",
                            }}
                          >
                            <Loader
                              active
                              style={{ position: "relative", top: 40 }}
                              size="large"
                            />
                          </div>
                        }
                        next={() => {
                          if (hasmoreNewsfeed !== "") {
                            this.props.getAllNewsfeed(
                              0,
                              10,
                              true,
                              hasmoreNewsfeed,
                              false
                            );
                          }
                        }}
                        // hasMore={true}
                        hasMore={
                          this.props.loading === true || hasmoreNewsfeed === ""
                            ? false
                            : true
                        }
                      >
                        <div style={{ backgroundColor: "#F9FBFF" }}>
                          {newsfeed &&
                            newsfeed
                              .sort(
                                (a, b) =>
                                  new Date(b.created_ts) -
                                  new Date(a.created_ts)
                              )
                              .map((nf, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="card newsfeedCard"
                                    style={{ marginBottom: 10 }}
                                  >
                                    {nf.video_url === "" ? (
                                      <Image
                                        src={
                                          nf.img_url !== undefined &&
                                          nf.img_url !== ""
                                            ? nf.img_url
                                            : placeholderImage
                                        }
                                        onError={(e) =>
                                          (e.target.src = placeholderImage)
                                        }
                                        style={{
                                          // height: "192px",
                                          // window.innerWidth >= 1500
                                          //   ? 550
                                          //   : window.innerWidth >= 1450
                                          //   ? 500
                                          //   : window.innerWidth >= 1400
                                          //   ? 450
                                          //   : window.innerWidth >= 1250
                                          //   ? 400
                                          //   : window.innerWidth >= 1150
                                          //   ? 350
                                          //   : window.innerWidth >= 1100
                                          //   ? 350
                                          //   : window.innerWidth >= 1050
                                          //   ? 300
                                          //   : 150,
                                          // width: "100%",
                                          aspectRatio: "16/9",
                                          borderTopLeftRadius: 5,
                                          borderTopRightRadius: 5,
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          borderRadius: "unset",
                                          aspectRatio: "16/9",
                                        }}
                                        className="videoAs"
                                      >
                                        <ReactPlayer
                                          url={nf.video_url}
                                          className="preview"
                                          style={{
                                            borderRadius: "unset",
                                          }}
                                          width="auto"
                                          height="auto"
                                          controls={true}
                                          // height={
                                          //   window.innerWidth >= 1500
                                          //     ? 550
                                          //     : window.innerWidth >= 1450
                                          //     ? 500
                                          //     : window.innerWidth >= 1400
                                          //     ? 450
                                          //     : window.innerWidth >= 1250
                                          //     ? 400
                                          //     : window.innerWidth >= 1150
                                          //     ? 350
                                          //     : window.innerWidth >= 1100
                                          //     ? 350
                                          //     : window.innerWidth >= 1050
                                          //     ? 300
                                          //     : 150
                                          // }
                                          light={
                                            nf.thumbnail_url !== ""
                                              ? nf.thumbnail_url
                                              : true
                                          }
                                        ></ReactPlayer>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        paddingTop: 10,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      <Grid>
                                        <Grid.Row>
                                          <Grid.Column width={14}>
                                            <Feed>
                                              <Feed.Event>
                                                <Feed.Label>
                                                  <Image
                                                    src={nf.profile_img}
                                                    style={{
                                                      borderRadius: "100%",
                                                      width: "36px",
                                                      height: "36px",
                                                      border:
                                                        "2px solid #4075FF",
                                                      marginTop: "2px",
                                                    }}
                                                  />
                                                </Feed.Label>

                                                <Feed.Content>
                                                  <Feed.Summary>
                                                    <p
                                                      style={{
                                                        marginRight: 10,
                                                        display: "inline",
                                                        textOverflow:
                                                          "ellipsis",
                                                        fontFamily: "Open Sans",
                                                        fontSize: "14px",
                                                        fontStyle: "normal",
                                                        lineHeight: "19px",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {nf.high_priority === 1
                                                        ? this.truncate(
                                                            nf.post_owner
                                                          )
                                                        : nf.post_owner}
                                                    </p>
                                                    {nf.high_priority === 1 && (
                                                      <label
                                                        style={{
                                                          position: "relative",
                                                          backgroundColor:
                                                            "#4075FF",
                                                          borderRadius: "14px",
                                                          color: "#ffff",
                                                          padding: "5px 17px",
                                                          fontSize: "12px",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          lineHeight: "18px",
                                                          fontStyle: "normal",
                                                        }}
                                                      >
                                                        High priority
                                                      </label>
                                                    )}
                                                  </Feed.Summary>

                                                  {/* <div className="ui simple dropdown item">
                                                                                        <Icon
                                                                                            style={{ color: "#C4C4C4" }}
                                                                                            name="ellipsis vertical"
                                                                                            size="large"
                                                                                        />
                                                                                        <div className="menu">
                                                                                            <div
                                                                                                // onClick={}
                                                                                                className="item"
                                                                                            >
                                                                                                Edit
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}
                                                </Feed.Content>
                                              </Feed.Event>
                                            </Feed>
                                          </Grid.Column>
                                          <Grid.Column width={2}>
                                            <div
                                              className="ui simple dropdown item"
                                              style={{
                                                float: "right",
                                              }}
                                            >
                                              <Icon
                                                style={{
                                                  color: "#C4C4C4",
                                                  marginTop: "10px",
                                                  marginRight: "-14px",
                                                }}
                                                name="ellipsis vertical"
                                                size="large"
                                              />
                                              <div
                                                className="menu"
                                                style={{
                                                  position: "absolute",
                                                  left: "-70px",
                                                  padding: "10px 0px",
                                                  borderRadius: "8px",
                                                  boxShadow:
                                                    "0px 2px 8px rgba(0, 0, 0, 0.15)",
                                                }}
                                              >
                                                <div
                                                  onClick={() => {
                                                    this.setState({
                                                      deleteId: nf.id,
                                                      deletePost: true,
                                                    });
                                                  }}
                                                  className="item"
                                                >
                                                  Delete post
                                                </div>
                                              </div>
                                            </div>
                                          </Grid.Column>
                                        </Grid.Row>
                                      </Grid>
                                      <div style={{ marginTop: 15 }}>
                                        <p
                                          style={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            lineHeight: "16px",
                                            fontFamily: "Open Sans",
                                            fontStyle: "normal",
                                            color: "#939494",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {moment(nf.created_ts).format(
                                            "DD MMMM YYYY hh:mm"
                                          )}
                                        </p>
                                      </div>
                                      <div>
                                        <p
                                          style={{
                                            fontFamily: "Open Sans",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            lineHeight: "22px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {nf.title}
                                        </p>
                                      </div>
                                      <div>
                                        <p
                                          style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            display: "inline",
                                            fontFamily: "Open Sans",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            lineHeight: "19px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {nf.description.length > 150 &&
                                          !this.state.seeMore
                                            ? `${nf.description.slice(
                                                0,
                                                150
                                              )}...`
                                            : nf.description}
                                        </p>
                                        {nf.description.length > 150 && (
                                          <label
                                            style={{
                                              display: "inline-block",
                                              position: "relative",
                                              cursor: "pointer",
                                              fontFamily: "Open Sans",
                                              fontSize: "14px",
                                              paddingLeft: "5px",
                                              fontStyle: "normal",
                                              lineHeight: "22px",
                                              fontWeight: "bold",
                                              color: "#4075FF",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                seeMore: !this.state.seeMore,
                                              });
                                            }}
                                          >
                                            See{" "}
                                            {!this.state.seeMore
                                              ? "more"
                                              : "less"}
                                          </label>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      </InfiniteScroll>
                    )}
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsfeed: state.newsfeedState.newsfeed,
    isNext: state.newsfeedState.isNext,
    loading: state.newsfeedState.loading,
    isLoadMore: state.newsfeedState.isLoadMore,
    hasmoreNewsfeed: state.newsfeedState.nextUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllNewsfeed: (page, pagesize, isNext, nextUrl, refresh) =>
      dispatch(getAllNewsfeed(page, pagesize, isNext, nextUrl, refresh)),
    postNewsfeed: (
      title,
      description,
      img_url,
      video_url,
      thumbnail_url,
      handleCallback
    ) =>
      dispatch(
        postNewsfeeds(
          title,
          description,
          img_url,
          video_url,
          thumbnail_url,
          handleCallback
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsfeedDash);
