import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_SCHEDULE_TYPES = "FETCH_SCHEDULE_TYPES";
export const FETCH_SCHEDULE_TYPES_SUCCESS = "FETCH_SCHEDULE_TYPES_SUCCESS";
export const FETCH_SCHEDULE_TYPES_FAILURE = "FETCH_SCHEDULE_TYPES_FAILURE";

export const fetchScheduleTypes = () => ({
  type: FETCH_SCHEDULE_TYPES,
});

export const fetchScheduleTypesSuccess = (data) => ({
  type: FETCH_SCHEDULE_TYPES_SUCCESS,
  data,
});

export const fetchScheduleTypesFailure = () => ({
  type: FETCH_SCHEDULE_TYPES_FAILURE,
});

export const getScheduleTypes = () => {
  return (dispatch) => {
    dispatch(fetchScheduleTypes());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/schedules/schedule_types", null, token)
          .then((res) => {
            dispatch(fetchScheduleTypesSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchScheduleTypesFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
