import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_CENTRES_CLASSES = "FETCH_CENTRES_CLASSES";
export const FETCH_CENTRES_CLASSES_SUCCESS = "FETCH_CENTRES_CLASSES_SUCCESS";
export const FETCH_CENTRES_CLASSES_FAILURE = "FETCH_CENTRES_CLASSES_FAILURE";

export const fetchCentersClasses = () => ({
  type: FETCH_CENTRES_CLASSES,
});

export const fetchCentersClassesSuccess = (data) => ({
  type: FETCH_CENTRES_CLASSES_SUCCESS,
  data,
});

export const fetchCentersClassesFailure = (err) => ({
  type: FETCH_CENTRES_CLASSES_FAILURE,
  err,
});

export const getCentersClasses = () => {
  return (dispatch) => {
    dispatch(fetchCentersClasses());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/user/centre/", null, token)
          .then((res) => {
            dispatch(fetchCentersClassesSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchCentersClassesFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
