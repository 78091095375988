import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_ATTENDANCE_DETAIL = "FETCH_ATTENDANCE_DETAIL";
export const FETCH_ATTENDANCE_DETAIL_SUCCESS =
  "FETCH_ATTENDANCE_DETAIL_SUCCESS";
export const FETCH_ATTENDANCE_DETAIL_FAILURE =
  "FETCH_ATTENDANCE_DETAIL_FAILURE";
export const CLEAR_ATTENDANCE_DETAIL = "CLEAR_ATTENDANCE_DETAIL";

export const fetchAttendancedetail = (student_id, start_date, end_date) => ({
  type: FETCH_ATTENDANCE_DETAIL,
  student_id,
  start_date,
  end_date,
});

export const fetchAttendancedetailSuccess = (data) => ({
  type: FETCH_ATTENDANCE_DETAIL_SUCCESS,
  data,
});

export const fetchAttendancedetailFailure = () => ({
  type: FETCH_ATTENDANCE_DETAIL_FAILURE,
});

export const clearAttendancedetail = () => ({
  type: CLEAR_ATTENDANCE_DETAIL,
});

export const getAttendancedetail = (student_id, start_date, end_date) => {
  return (dispatch) => {
    dispatch(fetchAttendancedetail());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/attendance/student/",
            {
              student_id: student_id,
              start_date: start_date,
              end_date: end_date,
            },
            token
          )
          .then((res) => {
            dispatch(fetchAttendancedetailSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchAttendancedetailFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
