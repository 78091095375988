import { FETCH_MERCHANDISE, FETCH_MERCHANDISE_SUCCESS, FETCH_MERCHANDISE_FAILURE } from '../actions';

const initialState = {
  merchandise: {},
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_MERCHANDISE:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_MERCHANDISE_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        merchandise: action.data
      };
    case FETCH_MERCHANDISE_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        merchandise: {}
      };
    default:
      return state;
  }
}