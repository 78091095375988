import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";

const devTools =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : null;

const createAppStore = (initialState, history) => {
  return createStore(
    createRootReducer(history),

    initialState,
    process.env.NODE_ENV === "development"
      ? compose(applyMiddleware(thunk, routerMiddleware(history)), devTools)
      : compose(applyMiddleware(thunk, routerMiddleware(history)))
  );
};

export default createAppStore;
