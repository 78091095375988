import React, { Component } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { MERCHANDISE_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import {
  getMerchandiseDetail,
  getMerchandiseTypes,
  getHQAdmin,
} from "../../store/actions";
import MerchandiseForm from "./MerchandiseForm";
import { connect } from "react-redux";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import defaultimage from "../../assets/images/defaultimage.png";
import RenderLoader from "../../components/Loader";
import { forEach } from "lodash-es";

class MerchandiseEdit extends Component {
  state = {
    previewUrl: defaultimage,
    count: null,
    imgs: [],
    previewUrlArr: [],
    onselectVal: [],
  };

  componentDidMount() {
    this.props.getMerchandiseDetail();
    this.props.getMerchandiseTypes();
    let centre_id = "8XbfLsbWOz09wfRh2Lxb";
    let user_type = "admin";
    let admin_type = "hq";

    this.props.getMerchandiseTypes();
    this.props.getHQAdmin(user_type, centre_id, admin_type);
  }

  handleSubmit = (values, img) => {
    console.log("values", values);
    console.log("img", img);
    if (
      values.name !== "" &&
      values.description !== "" &&
      values.price !== "" &&
      values.amount !== "" &&
      values.merchandise_type_id !== "" &&
      img.length > 0
    ) {
      let formdata = new FormData();
      this.setState({
        isSaving: true,
        hasError: false,
      });

      img.forEach((item, index) => {
        console.log(typeof item);
        formdata.append([`img${index + 1}`], item);
      });

      const size = values.size.replace(/[&\/\\#,+()$@~%.!~^`'":*?<>{}]/g, "");
      values.amount = values.amount;
      formdata.append("id", this.props.match.params.id);
      formdata.append("name", values.name);
      formdata.append("description", values.description);

      formdata.append("price", values.price);
      formdata.append("size", size);
      formdata.append(
        "amount",
        this.state.count !== null ? this.state.count : values.amount
      );
      formdata.append("merchandise_type_id", values.merchandise_type_id);
      formdata.append("created_by", values.created_by);
      formdata.append("number_of_images", img.length);
      console.log("form data", ...formdata);
      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api.post("/merchandise/", formdata, token).then((res) => {
            if (res.message === "Success") {
              console.log(res);
              this.props.dispatch(push(MERCHANDISE_ROUTE));
            } else {
              alert(`${res.message}`);
            }
          });
        } catch (err) {
          console.log(err);
        }
      });
    }
  };
  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        //previewUrl: reader.result,
        imgs: [...this.state.imgs, file],
      });
    };
    reader.readAsDataURL(file);
  };

  getCount = (count) => {
    this.setState({
      count: count,
    });
  };

  getOnselectVal = (value) => {
    this.setState({
      onselectVal: value,
    });
  };

  render() {
    // console.log("merchandise edit render...");
    const {
      haveData,
      isloading,
      merchandise,
      merchandisetypes = [],
      hqadmin = [],
    } = this.props;
    let types = [];
    let hqAdmin = [];
    merchandisetypes.map((data) => {
      types.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    hqadmin.data !== undefined &&
      hqadmin.data.map((hqadmdata) => {
        hqAdmin.push({
          key: hqadmdata,
          text: hqadmdata.name,
          value: hqadmdata.id,
        });
      });
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={MERCHANDISE_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            {haveData && (
              <MerchandiseForm
                handleSubmit={this.handleSubmit}
                onChange={this.onChange}
                initialValues={merchandise}
                formTitle={"Edit Item"}
                isEdit={true}
                types={types}
                getCount={this.getCount}
                count={this.state.count}
                hqAdmin={hqAdmin}
                imgs={this.state.imgs}
                getOnselectVal={this.getOnselectVal}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  merchandise: state.merchandise.merchandise.data,
  isloading: state.merchandise.loading,
  haveData: state.merchandise.haveData,
  merchandisetypes: state.merchandisetypes.merchandisetypes.data,
  hqadmin: state.users.user,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getMerchandiseDetail: () =>
    dispatch(getMerchandiseDetail(props.match.params.id, 10)),
  getMerchandiseTypes: () => dispatch(getMerchandiseTypes()),
  getHQAdmin: (user_type, centre_id, admin_type) =>
    dispatch(getHQAdmin(user_type, centre_id, admin_type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MerchandiseEdit);
