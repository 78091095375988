import React from "react";
import { Input, Message, Image } from "semantic-ui-react";
import defaultimage from "../assets/images/ic_add_image_file.svg";

class RenderImage extends React.Component {
  state = {
    previewUrl: defaultimage,
  };
  render() {
    const { isEdit, value, onChange } = this.props;
    const previewUrl = this.props.previewUrl || value;
    const defaultpreviewUrl =
      previewUrl !== "" ? previewUrl : this.state.previewUrl;

    return (
      <div>
        {defaultpreviewUrl !== undefined && (
          <label
            className="fileLabel2"
            style={{
              backgroundImage: "url(" + defaultpreviewUrl + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "20%",
              backgroundPositionX: "55%",
              backgroundPositionY: "55%",
              backgroundColor: "#F9FBFF",
            }}
          >
            <input
              type="file"
              id="file"
              accept="image/png, image/gif, image/jpeg, image/*, video/mp4,video/x-m4v,video/*"
              ref={this.inputRef}
              style={{ display: "none" }}
              onChange={onChange}
            />
          </label>
        )}
        {this.props.isError && this.props.isTouched && (
          <Message visible error content={this.props.isError} />
        )}
      </div>
    );
  }

  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };
}
export default RenderImage;
