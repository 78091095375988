import React, { Component } from "react";
import { Radio, Grid, Message, Image } from "semantic-ui-react";

class RenderRadioGroup extends Component {
  render() {
    return (
      <div>
        <Grid divided="vertically">
          <Grid.Row columns={3}>
            {this.props.options.map((data, ix) => {
              return (
                <Grid.Column
                  key={ix}
                  className={
                    this.props.isimgPicker
                      ? "radio-toolbar imgbar"
                      : `radio-toolbar ${this.props.chgBg ? "activeBg" : null}`
                  }
                >
                  <label>
                    <Radio
                      onChange={(event, data) =>
                        this.props.onChange("" + data.value)
                      }
                      onClick={this.props.onClick}
                      value={data.value}
                      checked={
                        this.props.value !== undefined &&
                        this.props.value.includes(data.value)
                      }
                      key={ix}
                      disabled={this.props.disabled}
                    />
                    {this.props.isimgPicker ? (
                      <span>
                        <Image
                          src={data.value}
                          style={{
                            width: "44px",
                            height: "44px",
                            padding: 10,
                            borderRadius: 8,
                          }}
                        />
                      </span>
                    ) : this.props.isColorListChkBox ? (
                      <span style={{ backgroundColor: data.hex_color_code }}>
                        {data.label}
                      </span>
                    ) : (
                      <span>{data.label}</span>
                    )}
                  </label>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
        {this.props.isError && this.props.isTouched && (
          <Message visible error content={this.props.isError} />
        )}
      </div>
    );
  }
}
export default RenderRadioGroup;
