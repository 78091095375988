import React, { Component } from "react";
import {
  Grid,
  Dimmer,
  Loader,
  Header,
  List,
  TransitionablePortal,
  Modal,
  Image,
  Message,
  Icon,
} from "semantic-ui-react";
import { MERCHANDISE_ROUTE, MERCHANDISE_TYPE_CREATE_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import MerchandiseTypeForm from "./MerchandiseTypeForm";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import { connect } from "react-redux";
import {
  getMerchandiseType,
  getMerchandiseTypes,
  clearMerchandise,
} from "../../store/actions";
import Xclose from "../../assets/images/Xclose.svg";
import RenderButton from "../../components/Button";
import ExclamationIcon from "../../components/ExclamationIcon";

const MerchandiseTypeList = (props) => {
  return (
    <List animated verticalAlign="middle" className="simpleList">
      <List.Item>
        <List.Content floated="right">
          <Icon
            name="edit outline"
            style={{ color: "#4075FF", cursor: "pointer" }}
            onClick={() => props.handleEdit(props.id)}
          />
          <Icon
            name="remove"
            style={{ color: "#f88278", cursor: "pointer" }}
            onClick={() => props.handleModal(props.id)}
          />
        </List.Content>
        <List.Content>{props.name}</List.Content>
      </List.Item>
    </List>
  );
};

class MerchandiseTypeCreate extends Component {
  state = {
    isSaving: false,
    hasError: false,
    isEidtModalOpen: false,
    isEdit: false,
    isAddModalOpen: false,
    showModal: false,
    currentId: null,
    localLoading: false,
  };

  componentDidMount() {
    this.props.getMerchandiseTypes();
  }

  handleSubmit = (values) => {
    this.setState({
      isSaving: true,
    });
    let formdata = new FormData();
    formdata.append("name", values.name);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/merchandise/types/", formdata, token).then((res) => {
          if (res.message === "Success") {
            this.setState({
              isSaving: false,
              isAddModalOpen: false,
            });
            this.props.getMerchandiseTypes();
            this.props.dispatch(push(MERCHANDISE_TYPE_CREATE_ROUTE));
          } else {
            this.setState({
              isSaving: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  handleUpdate = (values) => {
    const { merchandisetype } = this.props;
    this.setState({
      isSaving: true,
    });
    let formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("id", merchandisetype.id);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/merchandise/types/", formdata, token).then((res) => {
          if (res.message === "Success") {
            this.setState({
              isSaving: false,
              isEidtModalOpen: false,
            });
            this.props.getMerchandiseTypes();
            this.props.dispatch(push(MERCHANDISE_TYPE_CREATE_ROUTE));
            this.props.clearMerchandise(merchandisetype.id);
          } else {
            this.setState({
              isSaving: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  toggleAddModal = (id) => {
    this.setState({
      isAddModalOpen: !this.state.isAddModalOpen,
    });
  };

  toggleEditModal = (id) => {
    this.setState({
      isEidtModalOpen: !this.state.isEidtModalOpen,
    });
  };

  handleEdit = (id) => {
    this.props.clearMerchandise(id);
    this.props.getMerchandiseType(id);
    this.setState({
      isEidtModalOpen: !this.state.isEidtModalOpen,
      isEdit: true,
    });
  };

  handleModal = (id) => {
    this.setState({ showModal: true, currentId: id });
  };

  handleDelete = () => {
    this.setState({ showModal: false, localLoading: true });
    let formdata = new FormData();
    formdata.append("id", this.state.currentId);

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.del("/merchandise/types/", formdata, token).then((res) => {
          if (res.message === "Success") {
            this.setState({
              localLoading: false,
            });
            this.props.getMerchandiseTypes();
          } else {
            this.setState({
              localLoading: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  render() {
    const {
      merchandisetypes = [],
      merchandisetype = {},
      isloading,
      haveData,
    } = this.props;
    const { isEdit, isSaving, isEidtModalOpen, isAddModalOpen, localLoading } =
      this.state;
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading || localLoading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={MERCHANDISE_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />

            <Grid className="subContainer">
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ width: "50%", margin: "auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h2" floated="left">
                          {this.props.formTitle}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <RenderButton
                      className="primaryBtn autoWidth"
                      btnText="Add Merchandise Type"
                      showIcon={true}
                      onClick={this.toggleAddModal}
                    />

                    <h4 className="formLabel" style={{ marginBottom: 22 }}>
                      Merchandise Type List
                    </h4>
                    {merchandisetypes.map((type) => {
                      return (
                        <MerchandiseTypeList
                          {...type}
                          handleEdit={this.handleEdit}
                          handleModal={this.handleModal}
                        />
                      );
                    })}

                    <Modal
                      onClose={() => this.setState({ showModal: false })}
                      onOpen={() => this.setState({ showModal: true })}
                      open={this.state.showModal}
                      // trigger={<Button>Show Modal</Button>}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: 500,
                        // height: 305,
                      }}
                    >
                      <Modal.Content>
                        <div
                          style={{
                            color: "#F3B329",
                            textAlign: "center",
                            marginTop: 63,
                          }}
                        >
                          <ExclamationIcon width="120" height="120" />
                        </div>
                        <div
                          style={{
                            fontSize: 24,
                            fontWeight: "700",
                            textAlign: "center",
                            marginTop: 40,
                          }}
                        >
                          Do you want to remove?
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 40,
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            onClick={() => this.setState({ showModal: false })}
                            style={{
                              width: 195,
                              height: 48,
                              borderRadius: 9999,
                              border: "1px solid #4075FF",
                              display: "grid",
                              placeItems: "center",
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#4075FF",
                              cursor: "pointer",
                              marginRight: 11,
                            }}
                          >
                            Cancel
                          </div>
                          <div
                            onClick={this.handleDelete}
                            style={{
                              width: 195,
                              height: 48,
                              borderRadius: 9999,
                              display: "grid",
                              placeItems: "center",
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#fff",
                              backgroundColor: " #4075FF",
                              cursor: "pointer",
                            }}
                          >
                            Yes
                          </div>
                        </div>
                      </Modal.Content>
                    </Modal>

                    {/* add pupup */}
                    <TransitionablePortal
                      open={isAddModalOpen}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        open={isAddModalOpen}
                        size="tiny"
                        className="orderedDetailPopup"
                      >
                        <div align="right" className="closeBtn">
                          <Image
                            src={Xclose}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleAddModal()}
                          />
                        </div>
                        <br />
                        <MerchandiseTypeForm
                          handleSubmit={this.handleSubmit}
                          merchandisetypes={merchandisetypes}
                          isSaving={isSaving}
                          handleUpdate={this.handleUpdate}
                        />
                      </Modal>
                    </TransitionablePortal>

                    {/* edit pupup */}
                    <TransitionablePortal
                      open={isEidtModalOpen}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        open={isEidtModalOpen}
                        size="tiny"
                        className="orderedDetailPopup"
                      >
                        <div align="right" className="closeBtn">
                          <Image
                            src={Xclose}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleEditModal()}
                          />
                        </div>
                        <br />
                        {haveData ? (
                          <MerchandiseTypeForm
                            //handleSubmit={this.handleSubmit}
                            //merchandisetypes={merchandisetypes}
                            initialValues={merchandisetype}
                            isEdit={isEdit}
                            isSaving={isSaving}
                            handleUpdate={this.handleUpdate}
                          />
                        ) : (
                          <Message icon>
                            <Icon name="circle notched" loading />
                            <Message.Content>
                              <Message.Header>Just one second</Message.Header>
                              We are fetching that content for you.
                            </Message.Content>
                          </Message>
                        )}
                      </Modal>
                    </TransitionablePortal>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  merchandisetypes: state.merchandisetypes.merchandisetypes.data,
  isloading: state.merchandisetypes.loading,
  merchandisetype: state.merchandisetype.merchandisetype.data,
  istypeloading: state.merchandisetype.loading,
  haveData: state.merchandisetype.haveData,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMerchandiseTypes: () => dispatch(getMerchandiseTypes()),
  getMerchandiseType: (id) => dispatch(getMerchandiseType(id)),
  clearMerchandise: (id) => dispatch(clearMerchandise(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchandiseTypeCreate);
