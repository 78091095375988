import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_PARTICIPANTS = "FETCH_PARTICIPANTS";
export const FETCH_PARTICIPANTS_SUCCESS = "FETCH_PARTICIPANTS_SUCCESS";
export const FETCH_PARTICIPANTS_FAILURE = "FETCH_PARTICIPANTS_FAILURE";

export const fetchParticipants = (id) => ({
  type: FETCH_PARTICIPANTS,
  id,
});

export const fetchParticipantsSuccess = (data) => ({
  type: FETCH_PARTICIPANTS_SUCCESS,
  data,
});

export const fetchParticipantsFailure = (err) => ({
  type: FETCH_PARTICIPANTS_FAILURE,
  err,
});

export const getParticipants = (event_id, filtered_by) => {
  return (dispatch) => {
    dispatch(fetchParticipants());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/events/event_participant/",
            { event_id: event_id, filtered_by: filtered_by },
            token
          )
          .then((res) => {
            dispatch(fetchParticipantsSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchParticipantsFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
