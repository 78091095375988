import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Icon,
  Modal,
  Dropdown,
  Message,
  Dimmer,
  Loader,
  Pagination,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { USER_CREATE_ROUTE } from "../../routes";
import {
  getCentersClasses,
  getAttendace,
  getAttendancedetail,
  getMoreAttenadance,
  getAttendanceData,
} from "../../store/actions";
import LongCard from "../../components/LongCard";
import { isSameDay } from "date-fns";
import Cross from "../../assets/images/cross.png";
import Arrow from "../../assets/images/arrow_att.png";
import "../../App.css";
import mark from "../../assets/images/mark.png";
import moment from "moment";
import usr from "../../assets/images/usr.png";
import adminicon from "../../assets/images/ic_Admin.svg";
import parenticon from "../../assets/images/ic_Parents.svg";
import facilitatoricon from "../../assets/images/ic_Facilitator.svg";
import studenticon from "../../assets/images/ic_Students.svg";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import { CSVLink } from "react-csv";

//calender

import { enGB } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { toInteger } from "lodash";

const months = [
  { key: 1, text: "January", value: 1 },
  { key: 2, text: "February", value: 2 },
  { key: 3, text: "March", value: 3 },
  { key: 4, text: "April", value: 4 },
  { key: 5, text: "May", value: 5 },
  { key: 6, text: "June", value: 6 },
  { key: 7, text: "July", value: 7 },
  { key: 8, text: "August", value: 8 },
  { key: 9, text: "September", value: 9 },
  { key: 10, text: "October", value: 10 },
  { key: 11, text: "November", value: 11 },
  { key: 12, text: "December", value: 12 },
];

let csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];
let filename = "attendance.csv";

let export_data = {
  attendance: {
    absent: "83%",
    absent_change: "-17%",
    present: "0%",
    present_change: "0%",
  },
  number_of_operation_days: 6,
  message: "Success",
  data: [
    {
      scan_id: "zNmN5tShf",
      last_updated: "2021 Jan 22 03:27:34",
      centre_id: ["8XbfLsbWOz09wfRh2Lxb"],
      id: "wSrCPMgMC0nmYx0JfH6o",
      img: "https://d2t2athllyh1ku.cloudfront.net/images/YnVzaW5lc3Mtd2FsbHBhcGVyLTI1NjB4MTQ0MC5qcGctMjAyMS0wMS0xNCAxNDoyNzoxMC41OTQzMTg=.jpg",
      natus:
        "gAAAAABgufJSAz9ldU9KOLHgVcy1F5tLR0IJpxazDIpvDcN0zz39cQZP0KAEYkZxHk1Fiz6gxHzbRjh2N85Rp3nMIE71F90EVA==",
      created_at: "2021 Jan 14 22:27:10",
      is_active: 1,
      parent_id: [],
      dob: "2013 Jan 01",
      name: "StuNew1",
      scfa: 0,
      sf_id: "",
      natus_hash: "d41d8cd98f00b204e9800998ecf8427e",
      class_id: ["SZ7hNewftpmXbhIcQkqR"],
      school: "schoolnew",
      parent: [],
      attendance: {
        total: 6,
        present: 0,
        absent: 5,
        undefined: 1,
        percent: "0%",
      },
    },
    {
      sf_id: "",
      centre_id: ["8XbfLsbWOz09wfRh2Lxb"],
      scfa: 0,
      last_updated: "2021 Jan 20 13:59:32",
      id: "CppWEmyyXTYBk0YIVakG",
      name: "newstudent1",
      school: "newschool",
      natus:
        "gAAAAABgufJQOfZXyuZVpT9rxq6cl1_cDAAuZVyY53I-YNLRAxW2M-_J6-eoKCqlyfBrg1iRff8owt9mRhmnXPqld-jQ9bZ64g==",
      parent_id: [],
      class_id: ["SZ7hNewftpmXbhIcQkqR"],
      natus_hash: "d41d8cd98f00b204e9800998ecf8427e",
      is_active: 1,
      dob: "2013 Jan 04 03:07:55",
      created_at: "2021 Jan 14 22:23:46",
      scan_id: "Qb4kryTdF",
      img: "https://d2t2athllyh1ku.cloudfront.net/images/YnVzaW5lc3Mtd2FsbHBhcGVyLTI1NjB4MTQ0MC5qcGctMjAyMS0wMS0xNCAxNDoyMzo0NS45MDUyMjI=.jpg",
      parent: [],
      attendance: {
        total: 6,
        present: 0,
        absent: 5,
        undefined: 1,
        percent: "0%",
      },
    },
  ],
};

const initialState = {
  exportLoading: false,
  Modal: false,
  setDate: "",
  start_date: new Date(),
  end_date: new Date(),
  from_date: moment(new Date()).clone().startOf("month").format("YYYY-MM-DD"),
  to_date: moment(new Date()).clone().endOf("month").format("YYYY-MM-DD"),
  selected_date: new Date().getMonth() + 1,
  scfa_list: [
    { key: "1", value: 3, text: "All" },
    { key: "2", value: 1, text: "SCFA" },
    { key: "3", value: 0, text: "Not SCFA" },
  ],
  activePage: 1,
  scfa: 3,
  centre_id: localStorage.getItem("atten_center"),
  class_id: localStorage.getItem("atten_class"),
  Classes: [],
  export_data: [],
};
const validURL = (str) => {
  if (str.match("https://")) {
    return true;
  } else {
    return false;
  }
};
const ListBox = (props) => {
  return (
    <Segment className="listBox width-900">
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <div className="flex flex-align-center">
              <img
                className="listimage"
                style={{ border: "2px solid #4075FF" }}
                src={
                  props.img === undefined
                    ? ""
                    : validURL(props.img) === true
                    ? props.img
                    : studenticon
                }
                alt={props.name}
              />
              <div className="pl10 fw400 text-primary1 text-truncation-850">
                {props.name}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <p
              style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}
              className="fullWidth text-truncation-850"
            >
              {props.parent.map((data) => {
                if (data.id === props.parent_id[0]) {
                  return data.name;
                }
              })}{" "}
            </p>
          </Grid.Column>
          <Grid.Column width={3}>
            <p
              style={{
                marginTop: 8,
                fontWeight: "normal",
                color: "#1B1A1A",
              }}
              className="fullWidth text-truncation-850"
            >
              {props.school}
            </p>
          </Grid.Column>
          <Grid.Column width={1}>
            <p
              style={{
                marginLeft: "25px",

                fontWeight: "normal",
                color: "#1B1A1A",
                fontSize: 24,
              }}
            >
              {props.scfa === 0 ? "-" : <img src={mark} />}
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <p
              style={{
                fontSize: "24px",
                fontWeight: props.attendance.present ? "600" : "normal",
                color: props.attendance.present && "#7CD227",
                marginLeft: "25px",
              }}
            >
              {!props.attendance.present ? "-" : props.attendance.present}
            </p>
          </Grid.Column>
          <Grid.Column width={1}>
            <p
              style={{
                fontSize: "24px",
                fontWeight: props.attendance.absent ? "600" : "normal",
                color: props.attendance.absent && "#F3B329",
              }}
            >
              {!props.attendance.absent ? "-" : props.attendance.absent}
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <p
              style={{
                position: "absolute",
                left: "60px",
                fontSize: "24px",
                fontWeight: "600",
                color: "#4075FF",
              }}
            >
              {props.attendance.percent}
            </p>
          </Grid.Column>
          <Grid.Column width={1} className="centerText">
            <div className="ui simple dropdown item">
              <Icon
                style={{ marginTop: 8, color: "gray", float: "right" }}
                name="ellipsis vertical"
              />
              <div className="menu">
                <div
                  onClick={() => props.handleModalShow(props.id)}
                  className="item"
                >
                  View
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.props.getCentersClasses();
    this.props.getAttendanceData(
      this.state.from_date,
      this.state.to_date,
      this.state.centre_id,
      this.state.class_id,
      "",
      this.state.activePage - 1
    );
  }
  handleModalShow = (id) => {
    this.props.getAttendancedetail(
      id,
      this.state.from_date,
      this.state.to_date
    );
    this.setState({ Modal: true, ModalData: false, student_id: id });
  };
  handleModalClose = () => {
    this.setState({ Modal: false });
  };
  handleOnChange = () => {
    // console.log("handleOnChange hit")
  };
  onChangeDate = (e, data) => {
    var tempMonth = data.value > 10 ? data.value : "0" + data.value;
    const tempDateString =
      moment().format("YYYY") + "-" + tempMonth + "-" + "01";
    const startDateOfMonth = moment(tempDateString)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDateOfMonth = moment(tempDateString)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");

    this.setState({
      selected_date: data.value,
      from_date: startDateOfMonth,
      to_date: endDateOfMonth,
    });
    this.props.getAttendanceData(
      startDateOfMonth,
      endDateOfMonth,
      this.state.centre_id,
      this.state.class_id,
      this.state.scfa === 3 ? "" : this.state.scfa,
      this.state.activePage - 1
    );
    // this.setState(
    //   { start_date: date, selected_date: date },
    //   () =>
    //     // console.log(date),
    //     this.props.getAttendace(
    //       dateFormat(date, "yyyy-mm-dd"),
    //       dateFormat(
    //         new Date(date.getFullYear(), date.getMonth() + 1, 0),
    //         "yyyy-mm-dd"
    //       ),
    //       this.state.centre_id,
    //       this.state.class_id,
    //       this.state.scfa
    //     ),
    //   localStorage.setItem("start_date", dateFormat(date, "yyyy-mm-dd")),
    //   localStorage.setItem(
    //     "end_date",
    //     dateFormat(
    //       new Date(date.getFullYear(), date.getMonth() + 1, 0),
    //       "yyyy-mm-dd"
    //     )
    //   )
    // );
  };

  handleFilter = (e, res) => {
    // console.log(res);
    const { centerclasses = [] } = this.props;
    let goldObj = {};
    this.setState(
      {
        [res.name]: res.value,
      },
      () => {
        let values = {};
        values.centre_id = this.state.centre_id;
        values.class_id = this.state.class_id;
        values.scfa = this.state.scfa;
        goldObj = centerclasses.find(
          (result) => result.id === values.centre_id
        );
        if (goldObj) {
          let class_data = goldObj.class.sort((a, b) =>
            a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
          );
          if (res.name === "centre_id") {
            this.setState(
              {
                class_id: class_data[0].id,
                activePage: 1,
              },
              () => {
                this.props.getAttendanceData(
                  this.state.from_date,
                  this.state.to_date,
                  this.state.centre_id,
                  this.state.class_id,
                  this.state.scfa === 3 ? "" : this.state.scfa,
                  this.state.activePage - 1
                );
              }
            );
          }
          if (res.name === "class_id") {
            this.setState(
              {
                activePage: 1,
              },
              () => {
                this.props.getAttendanceData(
                  this.state.from_date,
                  this.state.to_date,
                  this.state.centre_id,
                  this.state.class_id,
                  this.state.scfa === 3 ? "" : this.state.scfa,
                  this.state.activePage - 1
                );
              }
            );
          }
          if (res.name === "scfa") {
            this.setState(
              {
                activePage: 1,
              },
              () => {
                this.props.getAttendanceData(
                  this.state.from_date,
                  this.state.to_date,
                  this.state.centre_id,
                  this.state.class_id,
                  this.state.scfa === 3 ? "" : this.state.scfa,
                  this.state.activePage - 1
                );
              }
            );
          }
        } else {
          this.props.getAttendanceData(
            this.state.from_date,
            this.state.to_date,
            this.state.centre_id,
            this.state.class_id,
            this.state.scfa === 3 ? "" : this.state.scfa,
            this.state.activePage - 1
          );
        }
      }
    );

    // this.setState(
    //   {
    //     [res.name]: res.value,
    //     start_date: localStorage.getItem("start_date"),
    //     end_date: localStorage.getItem("end_date"),
    //   },
    //   () => {
    //     let values = {};
    //     values.centre_id = this.state.centre_id;
    //     res.name === "centre_id"
    //       ? (values.class_id = undefined)
    //       : (values.class_id = this.state.class_id);
    //     values.scfa = this.state.scfa;
    //     this.props.getAttendace(
    //       this.state.start_date,
    //       this.state.end_date,
    //       localStorage.getItem("centre_id")
    //         ? localStorage.getItem("centre_id")
    //         : values.centre_id,
    //       values.class_id,
    //       values.scfa
    //     );
    //     // localStorage.setItem("centre_id", values.centre_id);
    //     localStorage.setItem("class_id", values.class_id);
    //   }
    // );
  };

  handleRoute = () => {
    this.props.dispatch(push(USER_CREATE_ROUTE));
  };

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    let page = activePage - 1;
    this.setState({ activePage });
    this.props.getAttendanceData(
      this.state.from_date,
      this.state.to_date,
      this.state.centre_id,
      this.state.class_id,
      this.state.scfa === 3 ? "" : this.state.scfa,
      page
    );
  };
  checkRole = (title) => {
    if (title) {
      if (title.toLowerCase() == "parent") {
        return parenticon;
      } else if (title.toLowerCase() == "mother") {
        return parenticon;
      } else if (title.toLowerCase() == "father") {
        return parenticon;
      } else if (title.toLowerCase().includes("admin")) {
        return adminicon;
      } else if (title.toLowerCase() == "facilitator") {
        return facilitatoricon;
      } else if (title.toLowerCase() == "student") {
        return studenticon;
      } else {
        return usr;
      }
    } else {
      return usr;
    }
  };

  exportAttendance = (centers) => {
    const start_date = this.state.from_date;
    const end_date = moment(start_date).endOf("month").format("YYYY-MM-DD");
    const centre_id = this.state.centre_id;
    const class_id = this.state.class_id;
    const scfa = this.state.scfa;

    this.setState({ exportLoading: true });

    firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        let body = {
          start_date,
          end_date,
          centre_id,
          class_id,
        }
        if(scfa !== 3){
          body["scfa"] = scfa
        }
        console.log(body);
        api
          .get(
            "/attendance/export_attendance/",
            body,
            token
          )
          .then((res) => {
            console.log("res ", res);
            console.log("centre_id", centre_id);
            const centre_name =
              centre_id === "all"
                ? "All Centres"
                : centers.filter((center) => {
                    if (center.key === centre_id) return center.text;
                    return "";
                  })[0].text;
            filename =
              moment(start_date).format("MMMM") +
              "_" +
              centre_name +
              "_attendance.csv";
            csvData = [
              ["month", "centre_name", "operation_days"],
              [
                moment(start_date).format("MMMM"),
                centre_name,
                res.number_of_operation_days,
              ],
              [
                "name",
                "parent",
                "school",
                "attendance",
                "absent",
                "scfa",
                "attendance_rate",
              ],
            ];
            const data =
              res.data.length === 0
                ? []
                : res.data.map((item) => {
                    return [
                      item.name,
                      item.parent.length === 0 ? "" : item.parent[0].name,
                      item.school,
                      item.attendance.present,
                      item.attendance.absent,
                      item.scfa,
                      item.attendance.percent,
                    ];
                  });
            csvData = [...csvData, ...data];

            const download = document.getElementById("csv-download");
            window.setTimeout(() => download.click(), 100);
            this.setState({ exportLoading: false });
          })
          .catch((err) => {
            console.log("Error 1");
            console.log("err", err);
            this.setState({ exportLoading: false });
          });
      } catch (err) {
        console.log("Error 2");
        console.log(err);
        this.setState({ exportLoading: false });
      }
    });
  };
  render() {
    const {
      centerclasses = [],
      notifications = [],
      isloading,
      haveData,
      attendance,
      attendanceisloading,
      attendanceDetailList,
      attendancehaveData,
      attendancehaveError,
      attendancePercentage,
    } = this.props;

    // console.log(centerclasses);
    const modifiers = {
      //disabled: date => getDay(date) === 6, // Disables Saturdays
      highlight: (date) => {
        let data = attendanceDetailList.details.filter((data) => {
          return data.status === "present";
        });
        let dat = data.map((data) => {
          return data.date;
        });
        return dat.some((selected) => isSameDay(new Date(selected), date));
      },
      absentdate: (date) => {
        let data = attendanceDetailList.details.filter((data) => {
          return data.status === "absent";
        });
        let dat = data.map((data) => {
          return data.date;
        });
        return dat.some((selected) => isSameDay(new Date(selected), date));
      },
    };
    const modifiersClassNames = {
      highlight: "-highlight",
      absentdate: "-absentdate",
    };

    let centers = [],
      classes = [{ key: "", text: "All", value: "all" }],
      // classes = [{ key: "", text: "All", value: "All" }],
      goldObj = {},
      activeCenterObj = {},
      activeCenterId = "",
      present_total = 0.0,
      absent_total = 0.0,
      // export_data = [],
      attendancedetail = [];
    if (localStorage.getItem("adminType") === "centre") {
      let d = centerclasses.find(
        (result) => result.id === localStorage.getItem("centre_Id")
      );
      if (d) {
        centers.push({
          key: d.id,
          text: d.name,
          value: d.id,
        });
      }
    } else {
      centers.push({
        key: "",
        text: "All",
        value: "all",
      });
      centerclasses.map((data) => {
        centers.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
    }
    // centerclasses.map((data) => {
    //   centers.push({
    //     key: data.id,
    //     text: data.name,
    //     value: data.id,
    //   });
    // });
    goldObj = centerclasses.find(
      (result) => result.id === this.state.centre_id
      // (result) => result.id === localStorage.getItem("centre_id")
    );
    goldObj !== undefined &&
      goldObj.class.map((data) => {
        classes.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });

    activeCenterId =
      localStorage.getItem("centre_Id") !== null
        ? localStorage.getItem("centre_Id")
        : localStorage.getItem("centre_Id", this.state.centre_id);
    activeCenterObj = centers.find((result) => result.value === activeCenterId);
    attendance.length !== 0 &&
      attendance.map((data) => {
        absent_total =
          parseFloat(absent_total) +
          parseFloat(toInteger(data.attendance.absent) / 30);
        present_total = present_total + toInteger(data.attendance.present) / 30;
        //console.log('attend',present_total)
      });
    absent_total = ((absent_total / attendance.length) * 100).toFixed(1);
    present_total = ((present_total / attendance.length) * 100).toFixed(1);
    //console.log('attend',toInteger((present_total/attendance.length) *100))
    // console.log('list', absent_total)
    attendance.length !== 0 &&
      attendance.map((data) => {
        // export_data.push({
        //   name: data.name,
        //   parent: data.parent.name,
        //   school: data.school,
        //   attendance: data.attendance.present,
        //   absent: data.attendance.absent,
        //   scfa: data.scfa,
        //   attendance_rate: data.attendance.percent,
        // });
      });

    attendance.length !== 0 &&
      this.state.student_id !== undefined &&
      (attendancedetail = attendance.find(
        (data) => data.id === this.state.student_id
      ));
    const presentdata = {
      labels: ["", "", "", "", "", "", "", ""],
      datasets: [
        {
          label: "B",
          fill: false,
          //backgroundColor: 'gray',
          barThickness: 10,
          data: [40, 70, 50, 57, 88, 70, 60, 0],
        },
        {
          label: "A",
          fill: false,
          backgroundColor: "#7CD227",
          barThickness: 10,
          data: [0, 0, 0, 0, 0, 0, 0, toInteger(present_total)],
        },
      ],
    };
    const absentdata = {
      labels: ["", "", "", "", "", "", "", ""],
      datasets: [
        {
          label: "B",
          fill: false,
          //backgroundColor: 'gray',
          barThickness: 10,
          data: [40, 70, 50, 57, 88, 70, 60, 0],
        },
        {
          label: "A",
          fill: false,
          backgroundColor: "#F2AB55",
          barThickness: 10,
          data: [0, 0, 0, 0, 0, 0, 0, toInteger(absent_total)],
        },
      ],
    };
    // console.log("centers", centers);
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>

        {attendanceDetailList === undefined ? (
          <Loader inverted size={"big"} />
        ) : (
          <Modal className="calenderModal" open={this.state.Modal}>
            <img
              onClick={() => this.handleModalClose()}
              className="img"
              src={Cross}
            ></img>
            {attendancedetail !== undefined && (
              <Modal.Content>
                <Dimmer active={attendanceisloading} inverted>
                  <Loader inverted />
                </Dimmer>
                <div style={{ marginTop: "20px", martinBottom: "10px" }}>
                  <img
                    style={{
                      width: "36px",
                      height: "36px",
                      border: "2px solid #4075FF",
                      boxSizing: "border-box",
                      borderRadius: "20px",
                    }}
                    size="medium"
                    src={
                      attendancedetail.img === undefined
                        ? ""
                        : validURL(attendancedetail.img) === true
                        ? attendancedetail.img
                        : studenticon
                    }
                  />
                  <span className="name">{attendancedetail.name}</span>
                  {attendancedetail.scfa === 1 && (
                    <span className="tabColor">
                      {" "}
                      <p
                        className="ui centered"
                        style={{
                          color: "#4075FF",
                          fontSize: "14px",
                          fontWeight: "600px",
                          marginLeft: "-20px",
                        }}
                      >
                        SCFA
                      </p>{" "}
                      <i
                        style={{
                          position: "absolute",
                          color: "#4075FF",
                          top: "5px",
                          right: "10px",
                        }}
                        className="check icon"
                      ></i>{" "}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    border: "1px solid #DADADA",
                    padding: "25px",
                    marginTop: "20px",
                    width: "400px",
                    height: "420px",
                  }}
                >
                  <DatePickerCalendar
                    date={new Date(this.state.from_date)}
                    modifiersClassNames={modifiersClassNames}
                    absentdata={this.absentdata}
                    locale={enGB}
                    modifiers={modifiers}
                    adjustDateOnChange
                  />
                </div>
              </Modal.Content>
            )}
          </Modal>
        )}
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="noBottomMargin">
              <Grid.Column floated="left" width={5}>
                <Header as="h3" floated="left">
                  Attendance
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                width={3}
                style={{ marginTop: "10px" }}
              >
                <button
                  disabled={this.state.exportLoading}
                  style={{ width: "100%", border: "none" }}
                  className="bgBlue text-white text-ac br20 pv13 pointer"
                  onClick={() => this.exportAttendance(centers)}
                >
                  {this.state.exportLoading ? "Loading..." : "Export"}
                </button>
              </Grid.Column>
              <CSVLink
                id="csv-download"
                data={csvData}
                filename={filename}
                style={{ display: "none" }}
              >
                Download me
              </CSVLink>
            </Grid>
            <Grid>
              <Grid.Column style={{ width: "45%" }}>
                <LongCard
                  title="Total Attendance"
                  image={Arrow}
                  style={{ color: "#7CD227" }}
                  number={(isNaN(present_total) ? "0.0" : present_total) + "%"}
                  text={
                    attendancePercentage && attendancePercentage.present_change
                  }
                  data={presentdata}
                />
              </Grid.Column>
              <Grid.Column style={{ width: "45%" }} floated="right">
                <LongCard
                  title="Total Absence"
                  image={Arrow}
                  number={(isNaN(absent_total) ? "0.0" : absent_total) + "%"}
                  style={{ color: "#F2AB55" }}
                  text={
                    attendancePercentage && attendancePercentage.absent_change
                  }
                  data={absentdata}
                ></LongCard>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column
                width={window.innerWidth < 880 ? 4 : 3}
                // width={localStorage.getItem("adminType") === "hq" ? 3 : 0}
              >
                <Dropdown
                  name="centre_id"
                  options={centers.sort((a, b) =>
                    a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                  )}
                  onChange={this.handleFilter}
                  placeholder="Choose Centre"
                  // defaultValue={localStorage.getItem("centre_id")}
                  value={this.state.centre_id}
                  selection
                  fluid
                  // style={{
                  //   display:
                  //     localStorage.getItem("adminType") === "hq"
                  //       ? "block"
                  //       : "none",
                  // }}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Dropdown
                  name="class_id"
                  options={classes.sort((a, b) =>
                    a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                  )}
                  onChange={this.handleFilter}
                  placeholder="Choose Class"
                  // defaultValue={localStorage.getItem("class_id")}
                  value={this.state.class_id}
                  //defaultValue={localStorage.getItem("centre_id")}
                  selection
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Dropdown
                  name="scfa"
                  options={this.state.scfa_list}
                  onChange={this.handleFilter}
                  value={this.state.scfa}
                  placeholder="Choose SCFA"
                  selection
                  fluid
                />
              </Grid.Column>
              <Grid.Column floated="right" width={3}>
                {/* <DatePicker
                  selected={this.state.selected_date}
                  onChange={this.onChangeDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  value={this.state.selected_date}
                  dateFormat="MMM"
                  showMonthYearPicker
                /> */}
                <Dropdown
                  name="month"
                  onChange={this.onChangeDate}
                  customStyle={{ float: "right", fontWeight: "bold" }}
                  options={months}
                  value={this.state.selected_date}
                  selection
                  fluid
                />
                {/* <div className="input-iconnnn">
                   <Image src={ic_calendar}></Image> 
                  <Icon className="chevron down" color="blue" />
                </div> */}
              </Grid.Column>
            </Grid>

            <div className="attendance-list-container scrollbar-hidden">
              <Grid className="activityTitle width-900">
                <Dimmer active={attendanceisloading && !isloading} inverted>
                  <Loader inverted />
                </Dimmer>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Header as="h4" color="grey">
                      Student
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Header as="h4" color="grey">
                      Parent
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Header as="h4" color="grey">
                      School
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Header as="h4" color="grey">
                      SCFA
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Header as="h4" color="grey">
                      Attendance
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Header
                      as="h4"
                      color="grey"
                      style={{ position: "absolute", left: "-10px" }}
                    >
                      Absence
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Header
                      as="h4"
                      color="grey"
                      style={{ position: "absolute", left: "-40px" }}
                    >
                      Attendance rate
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              {attendancehaveError ? (
                <Message
                  header="Error loading users"
                  content="Error loading users. Please try again."
                  negative
                />
              ) : attendance.length === 0 && !attendanceisloading ? (
                <Message
                  header="No user found"
                  content=" There is no user in this section."
                  warning
                />
              ) : (
                attendancehaveData && (
                  <>
                    {attendance.map((data) => (
                      <ListBox
                        {...data}
                        key={data.id}
                        handleModalShow={this.handleModalShow}
                        state={this.state}
                        parentDetail={
                          data.parent[0] !== undefined ? data.parent[0] : []
                        }
                      />
                    ))}
                    {isloading ||
                    this.props.attendanceTotalPage === 1 ? null : (
                      <Pagination
                        activePage={this.state.activePage}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={3}
                        totalPages={this.props.attendanceTotalPage}
                        floated="right"
                        onPageChange={this.handlePaginationChange}
                      />
                    )}
                  </>
                )
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  centerclasses: state.centersClasses.centers_classes.data,
  ActiveToken: state.firebase.auth.stsTokenManager.accessToken,
  isloading: state.centersClasses.loading,
  haveError: state.centersClasses.err,
  haveData: state.centersClasses.haveData,
  attendance:
    state.attendance_Data.attendance_Data.data !== undefined
      ? state.attendance_Data.attendance_Data.data
      : [],
  attendanceisloading: state.attendance_Data.loading,
  attendancehaveData: state.attendance_Data.haveData,
  attendancehaveError: state.attendance_Data.err,
  attendanceDetailList: state.attendanceDetail.attendance_detail.data,
  attendancePercentage: state.attendance_Data.attendancePercentage,
  attendanceTotalPage: state.attendance_Data.attendanceTotalPage,
});
const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
  getAttendancedetail: (student_id, start_date, end_date) =>
    dispatch(getAttendancedetail(student_id, start_date, end_date)),
  getMoreAttenadance: (link, nextpage) => {
    dispatch(getMoreAttenadance(link, nextpage));
  },
  getAttendanceData: (start, end, centre_id, class_id, scfa, page) =>
    dispatch(getAttendanceData(start, end, centre_id, class_id, scfa, page)),
  getAttendace: (start_date, end_date, centre_id, class_id, scfa) =>
    dispatch(getAttendace(start_date, end_date, centre_id, class_id, scfa)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
