import React from "react";
import { Message } from "semantic-ui-react";
import CounterInput from "react-counter-input";

const RenderCounterInput = (props) => {
  return (
    <div>
      <div className="counterInput">
        <CounterInput
          onCountChange={props.onChange}
          type={props.type}
          fluid
          min={0}
          count={props.value !== "" ? props.value: 0}
          value={props.value}
        />
      </div>
      {!props.value && (props.isError && props.isTouched) && <Message visible error content={props.isError}/>}
    </div>
  );
};

export default RenderCounterInput;
