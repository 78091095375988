import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_SCHEDULE_ITEMS = "FETCH_SCHEDULE_ITEMS";
export const FETCH_SCHEDULE_ITEMS_SUCCESS = "FETCH_SCHEDULE_ITEMS_SUCCESS";
export const FETCH_SCHEDULE_ITEMS_FAILURE = "FETCH_SCHEDULE_ITEMS_FAILURE";

export const fetchScheduleItems = () => ({
  type: FETCH_SCHEDULE_ITEMS,
});

export const fetchScheduleItemsSuccess = (data) => ({
  type: FETCH_SCHEDULE_ITEMS_SUCCESS,
  data,
});

export const fetchScheduleItemsFailure = () => ({
  type: FETCH_SCHEDULE_ITEMS_FAILURE,
});

export const getScheduleItems = () => {
  return (dispatch) => {
    dispatch(fetchScheduleItems());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/schedules/schedule_items", null, token)
          .then((res) => {
            dispatch(fetchScheduleItemsSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchScheduleItemsFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
