import React, { Component } from "react";
import {
  Grid,
  Header,
  TransitionablePortal,
  Modal,
  Image,
  Message,
  Icon,
} from "semantic-ui-react";
import { CALENDAR_ROUTE, SCHEDULE_ITEM_CREATE_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import ScheduleItemForm from "./ScheduleItemForm";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import { connect } from "react-redux";
import {
  getScheduleItem,
  getScheduleItems,
  clearScheduleItem,
} from "../../store/actions";
import Xclose from "../../assets/images/Xclose.svg";
import RenderButton from "../../components/Button";
import RenderLoader from "../../components/Loader";
import defaultimage from "../../assets/images/defaultimage.png";

const ScheduleItemList = (props) => {
  //console.log(props)
  return (
    <Grid divided="vertically schedult_item_list">
      <label>{props.name}</label>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Image src={props.img_url} />
        </Grid.Column>
        {/*<Grid.Column>
          <Image src={props.thumbnail}/>
        </Grid.Column>*/}
        <Grid.Column>
          <Icon
            name="edit outline"
            style={{ color: "#4075FF" }}
            onClick={() => props.handleEdit(props.id)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

class ScheduleItemCreate extends Component {
  state = {
    isSaving: false,
    hasError: false,
    isEidtModalOpen: false,
    isEdit: false,
    isAddModalOpen: false,
    previewUrlImg: "",
    previewUrlThumbnail: defaultimage,
    imagefile: null,
    thumbnailfile: null,
  };

  componentDidMount() {
    this.props.getScheduleItems();
  }

  handleSubmit = (values) => {
    this.setState({
      isSaving: true,
    });

    let formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("img_url", this.state.imagefile);
    formdata.append("thumbnail", this.state.thumbnailfile);
    console.log(...formdata);
    //return;
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        api.post("/schedules/schedule_item/", formdata, token).then((res) => {
          if (res.message === "success") {
            console.log(res);
            this.setState({
              isSaving: false,
              isAddModalOpen: false,
            });
            this.props.getScheduleItems();
            this.props.dispatch(push(SCHEDULE_ITEM_CREATE_ROUTE));
          } else {
            this.setState({
              isSaving: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  handleUpdate = (values) => {
    const { scheduleitem } = this.props;
    this.setState({
      isSaving: true,
    });
    let formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("id", scheduleitem.data.id);
    formdata.append("img_url", this.state.imagefile);
    //formdata.append("thumbnail_url", this.state.thumbnailfile);
    console.log(...formdata);
    console.log("upd");
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        api.post("/schedules/schedule_item/", formdata, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              isSaving: false,
              isEidtModalOpen: false,
            });
            this.props.getScheduleItems();
            this.props.dispatch(push(SCHEDULE_ITEM_CREATE_ROUTE));
            this.props.clearScheduleItem(scheduleitem.data.id);
          } else {
            this.setState({
              isSaving: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  toggleAddModal = (id) => {
    this.setState({
      isAddModalOpen: !this.state.isAddModalOpen,
    });
  };

  toggleEditModal = (id) => {
    this.setState({
      isEidtModalOpen: !this.state.isEidtModalOpen,
    });
  };

  handleEdit = (id) => {
    this.props.clearScheduleItem(id);
    this.props.getScheduleItem(id);
    this.setState({
      isEidtModalOpen: !this.state.isEidtModalOpen,
      isEdit: true,
    });
  };

  onChangeThumbnail = (event, res) => {
    let file = event.target.files[0];
    this.setState({
      thumbnailfile: file,
      previewUrlThumbnail: URL.createObjectURL(file),
    });
  };

  onChangeImgurl = (event) => {
    let file = event.target.files[0];
    console.log(file);
    this.setState({
      imagefile: file,
      previewUrlImg: URL.createObjectURL(file),
    });
  };

  render() {
    const {
      scheduleitems = [],
      scheduleitem = {},
      isloading,
      haveData,
    } = this.props;
    const { isEdit, isSaving, isEidtModalOpen, isAddModalOpen } = this.state;
    //console.log(this.props)
    //console.log(scheduleitem)
    return (
      <Grid className="fullHeight">
        <RenderLoader isloading={isloading} size="big" />
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={CALENDAR_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />

            <Grid className="subContainer">
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ width: "50%", margin: "auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h2" floated="left">
                          {this.props.formTitle}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <RenderButton
                      className="primaryBtn autoWidth"
                      btnText="Add Schedule Item"
                      showIcon={true}
                      onClick={this.toggleAddModal}
                    />
                    <h4 className="formLabel" style={{ marginBottom: 22 }}>
                      Schedule Item List
                    </h4>
                    <br />
                    {scheduleitems.length === 0 ? (
                      <Message
                        header="No Schedule Item found"
                        content="There is no schedult item in this period."
                        warning
                      />
                    ) : (
                      scheduleitems.map((type) => {
                        return (
                          <ScheduleItemList
                            {...type}
                            handleEdit={this.handleEdit}
                          />
                        );
                      })
                    )}

                    {/* add pupup */}
                    <TransitionablePortal
                      open={isAddModalOpen}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        open={isAddModalOpen}
                        size="tiny"
                        className="orderedDetailPopup"
                      >
                        <div align="right" className="closeBtn">
                          <Image
                            src={Xclose}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleAddModal()}
                          />
                        </div>
                        <br />
                        <ScheduleItemForm
                          handleSubmit={this.handleSubmit}
                          isSaving={isSaving}
                          previewUrlImg={
                            this.state.previewUrlImg !== ""
                              ? this.state.previewUrlImg
                              : defaultimage
                          }
                          previewUrlThumbnail={this.state.previewUrlThumbnail}
                          onChangeImgurl={this.onChangeImgurl}
                          onChangeThumbnail={this.onChangeThumbnail}
                        />
                      </Modal>
                    </TransitionablePortal>

                    {/* edit pupup */}
                    <TransitionablePortal
                      open={isEidtModalOpen}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        open={isEidtModalOpen}
                        size="tiny"
                        className="orderedDetailPopup"
                      >
                        <div align="right" className="closeBtn">
                          <Image
                            src={Xclose}
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleEditModal()}
                          />
                        </div>
                        <br />
                        {haveData ? (
                          <ScheduleItemForm
                            initialValues={
                              scheduleitem !== undefined && scheduleitem.data
                            }
                            isEdit={isEdit}
                            isSaving={isSaving}
                            handleUpdate={this.handleUpdate}
                            previewUrlImg={
                              this.state.previewUrlImg ||
                              scheduleitem.data.img_url
                            }
                            previewUrlThumbnail={this.state.previewUrlThumbnail}
                            onChangeImgurl={this.onChangeImgurl}
                            onChangeThumbnail={this.onChangeThumbnail}
                          />
                        ) : (
                          <Message icon>
                            <Icon name="circle notched" loading />
                            <Message.Content>
                              <Message.Header>Just one second</Message.Header>
                              We are fetching that content for you.
                            </Message.Content>
                          </Message>
                        )}
                      </Modal>
                    </TransitionablePortal>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  scheduleitems: state.scheduleItems.scheduleItems.data,
  isloading: state.scheduleItems.loading,
  scheduleitem: state.scheduleItem.scheduleitem,
  haveData: state.scheduleItem.haveData,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getScheduleItems: () => dispatch(getScheduleItems()),
  getScheduleItem: (id) => dispatch(getScheduleItem(id)),
  clearScheduleItem: (id) => dispatch(clearScheduleItem(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleItemCreate);
