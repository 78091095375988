import React, { Component } from "react";
import { Grid, Header, Button } from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import RenderInput from "../../components/TextInput";
import RenderTextArea from "../../components/RenderTextArea";
import RenderDropdown from "../../components/DropDown";
import RenderImage from "../../components/Image";
import RenderCheckbox from "../../components/CheckBox";
import { isRequired } from "../../validators";
import RenderRadioGroup from "../../components/RadioGroup";
import RenderDatePicker from "../../components/DatePicker";
import RenderTimePicker from "../../components/TimePicker";
import moment from "moment";
import { faTruckMonster } from "@fortawesome/free-solid-svg-icons";
import Image_Delete from "../../assets/images/event_exit.svg";
import CrossImgIcon from "../../assets/images/cross.png";
import PdfFileIcon from "../../assets/images/pdffile.svg";
import Exclamation from "../../assets/images/exclamation.svg";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import ReactTooltip from "react-tooltip";
import { firebase } from "../../config/firebase";
import { getCurrentLoginUser } from "../../store/actions";
import { connect } from "react-redux";

class ActivityForm extends Component {
  state = {
    centre_id: "",
    regtime: null,
    eventDate: null,
    from_time: "",
    to_time: "",
    eventCon: false,
    errorMax_number: false,
    errorMax_message: "",
    errorEventTime: false,
    errorEventTime_message: "",
    effect: "float",
    condition: false,
  };

  componentDidMount() {
    firebase.reloadAuth().then((res) => {
      this.props.getCurrentLoginUser(res.uid, "admin");
    });
  }

  getAllCentres = () => {
    if (this.props.userDetail.admin_type === "centre") {
      return [
        {
          key: this.props.userDetail.centre[0].id,
          text: this.props.userDetail.centre[0].name,
          value: this.props.userDetail.centre[0].id,
        },
      ];
    }
    const { centerclasses = [] } = this.props;
    let allCentres = [];

    centerclasses.map((data) => {
      allCentres.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    allCentres.push({
      key: "all",
      text: "All",
      value: "all",
    });
    return allCentres.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  getAllClasses = (centre_id) => {
    const { centerclasses = [] } = this.props;
    let allClasses = [];

    if (centre_id === "all") {
      allClasses.push(
        { key: "all", text: "All", value: "all" },
        { key: "p1", text: "P1", value: "p1" },
        { key: "p2", text: "P2", value: "p2" },
        { key: "p3", text: "P3", value: "p3" },
        { key: "p4", text: "P4", value: "p4" },
        { key: "p5", text: "P5", value: "p5" },
        { key: "p6", text: "P6", value: "p6" }
      );
    } else {
      if (centre_id === "" || centre_id === "all") {
        centerclasses.map((ce) => {
          ce.class.map((cl) => {
            allClasses.push({
              key: cl.id,
              text: cl.name,
              value: cl.id,
            });
          });
        });
      } else {
        let tempClasses = centerclasses.filter((x) => x.id == centre_id)[0]
          .class;
        tempClasses.map((xx) => {
          allClasses.push({
            key: xx.id,
            text: xx.name,
            value: xx.id,
          });
        });
      }
    }
    return allClasses.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  render() {
    const { isEdit, initialValues, centers, centerclasses } = this.props;
    const { centre_id } = this.state;
    let classes = [],
      goldObj = {},
      selectedCentreId = "";
    if (isEdit) {
      selectedCentreId = centre_id !== "" ? centre_id : initialValues.centre_id;
      goldObj = centerclasses.find((result) => result.id === selectedCentreId);
      goldObj !== undefined &&
        goldObj.class.map((data) => {
          classes.push({
            key: data.id,
            text: data.name,
            value: data.id,
          });
        });
    } else {
      goldObj = centerclasses.find((result) => result.id === centre_id);
      goldObj !== undefined &&
        goldObj.class.map((data) => {
          classes.push({
            key: data.id,
            text: data.name,
            value: data.id,
          });
        });
    }
    return (
      <Grid className="subContainer" style={{ paddingBottom: 100 }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ width: "50%", margin: "auto" }}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h2" floated="left">
                    {this.props.formTitle}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <div style={{ marginTop: 36 }}>
                <Formik
                  initialValues={
                    isEdit
                      ? initialValues
                      : {
                          title: "",
                          description: "",
                          centre_id: "",
                          class_id: "",
                          img_url: null,
                          //shiftwork: "",
                          //activityDate: "",
                          //activityTime: "",
                          register_link: "",
                          reg_to_date: "",
                          scfa: 0,
                          event_date: "",
                          max_number: null,
                          from_time: "",
                          to_time: "",
                          pdfFile: "here",
                        }
                  }
                  onSubmit={this.props.handleSubmit}
                >
                  {(formikProps) => {
                    console.log(formikProps);
                    formikProps.values.reg_to_date = this.state.reg_to_date;
                    return (
                      <Form>
                        <label className="formLabel">Title</label>
                        <div className="formMargin">
                          <Field
                            name="title"
                            component={RenderInput}
                            onChange={formikProps.handleChange("title")}
                            validate={isRequired}
                            placeholder="Activity Title"
                            isTouched={formikProps.touched.title}
                            isError={formikProps.errors.title}
                            value={formikProps.values.title}
                          />
                        </div>

                        <label className="formLabel">Description</label>
                        <div className="formMargin">
                          <Field
                            name="description"
                            component={RenderTextArea}
                            onChange={formikProps.handleChange("description")}
                            validate={isRequired}
                            placeholder="Description"
                            isTouched={formikProps.touched.description}
                            isError={formikProps.errors.description}
                            value={formikProps.values.description}
                          />
                        </div>

                        <Grid style={{ marginTop: 0 }}>
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Centres</label>
                              <div className="formMargin">
                                <Field
                                  name="centre_id"
                                  component={RenderDropdown}
                                  onChange={(id) => {
                                    this.setState({
                                      centre_id: id,
                                    });
                                    formikProps.values.class_id = "";
                                  }}
                                  validate={isRequired}
                                  placeholder="Choose Center"
                                  isTouched={formikProps.touched.centre_id}
                                  isError={
                                    this.state.centre_id !== ""
                                      ? undefined
                                      : formikProps.errors.centre_id
                                  }
                                  //value={this.state.centre_id !== "" ? this.state.centre_id : formikProps.values.centre_id}
                                  value={
                                    this.state.centre_id !== ""
                                      ? (formikProps.values.centre_id =
                                          this.state.centre_id)
                                      : formikProps.values.centre_id
                                  }
                                  options={this.getAllCentres()}
                                  selection
                                />
                              </div>
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">Class</label>
                              <div className="formMargin">
                                <Field
                                  name="class_id"
                                  component={RenderDropdown}
                                  onChange={formikProps.handleChange(
                                    "class_id"
                                  )}
                                  validate={isRequired}
                                  placeholder="Choose Center"
                                  isTouched={formikProps.touched.class_id}
                                  isError={formikProps.errors.class_id}
                                  value={formikProps.values.class_id}
                                  options={this.getAllClasses(
                                    formikProps.values.centre_id
                                  )}
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Event Date</label>
                              <div className="specific">
                                <Field
                                  name="event_date"
                                  component={RenderDatePicker}
                                  dateFormat="yyyy MMM dd"
                                  onChange={(time) => {
                                    // console.log("time", time);
                                    this.setState({
                                      eventDate: time,
                                    });
                                  }}
                                  // dateFormat="YYYY MMM DD"
                                  validate={isRequired}
                                  isTouched={formikProps.touched.event_date}
                                  isError={                                    
                                    this.state.eventDate < new Date() &&
                                    "Event Date must be greater than today date!"
                                  }
                                  // isError={
                                  //   this.state.eventDate.getTime() <
                                  //   this.state.regtime.getTime()
                                  //     ? "Must be greater than registration date"
                                  //     : this.state.eventDate > new Date()
                                  //     ? formikProps.errors.event_date
                                  //     : formikProps.errors.event_date
                                  // }
                                  // isError={
                                  //   !this.state.eventDate &&
                                  //   "This inforimation is required"
                                  // }
                                  //value={isEdit ? formikProps.values.reg_to_date : formikProps.values.reg_to_date=this.state.regtime}
                                  value={
                                    isEdit
                                      ? this.state.eventDate > new Date()
                                        ? (formikProps.values.event_date =
                                            this.state.eventDate)
                                        : new Date(
                                            formikProps.values.event_date
                                          )
                                      : this.state.eventDate > new Date()
                                      ? (formikProps.values.event_date =
                                          this.state.eventDate)
                                      : this.state.eventDate
                                  }
                                  // fromtoProps="From"
                                  {...formikProps}
                                />
                              </div>
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">
                                Max number of participant
                              </label>
                              <div className="formMargin">
                                <Field
                                  component={RenderInput}
                                  name="max_number"
                                  onChange={formikProps.handleChange(
                                    "max_number"
                                  )}
                                  validate={(value) => {
                                    if (!value) {
                                      return this.setState({
                                        errorMax_number: true,
                                        errorMax_message:
                                          "This information is required",
                                      });
                                    }

                                    if (/^\d{0,}$/.test(value)) {
                                      return this.setState({
                                        errorMax_number: false,
                                        errorMax_message:
                                          "Number must be positive",
                                      });
                                    } else {
                                      return this.setState({
                                        errorMax_number: true,
                                        errorMax_message:
                                          "Number must be positive",
                                      });
                                    }
                                  }}
                                  isTouched={formikProps.touched.max_number}
                                  isError={
                                    this.state.errorMax_number
                                      ? this.state.errorMax_message
                                      : formikProps.errors.max_number
                                  }
                                  value={formikProps.values.max_number}
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>

                        <Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Event Time</label>
                              <div className="formMargin">
                                <RenderTimePicker
                                  fromtoProps="From"
                                  minTime={setHours(
                                    setMinutes(new Date(), 0),
                                    7
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 0),
                                    23
                                  )}
                                  onChange={(time) =>
                                    this.setState({
                                      from_time: time,
                                    })
                                  }
                                  value={
                                    (formikProps.values.from_time =
                                      this.state.from_time)
                                  }
                                  dateFormat="hh:mm a"
                                  minTime={setHours(
                                    setMinutes(new Date(), 0),
                                    7
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 0),
                                    23
                                  )}
                                />
                                {this.props.fromTimeErrorMessage && (
                                  <div className="visible ui error message">
                                    This information is required.
                                  </div>
                                )}
                                {/* <Field
                                  name="from_time"
                                  component={RenderTimePicker}
                                  onChange={(time) => {
                                    this.setState({
                                      from_time: time,
                                    });
                                  }}
                                  validate={isRequired}
                                  isTouched={formikProps.touched.from_time}
                                  isError={
                                    !this.state.from_time
                                      ? "This information is required"
                                      : "Please set after the current time"
                                  }
                                  fromtoProps="From"
                                  value={
                                    isEdit
                                      ? this.state.from_time > new Date()
                                        ? (formikProps.values.from_time =
                                            this.state.from_time)
                                        : new Date(formikProps.values.from_time)
                                      : this.state.from_time
                                      ? (formikProps.values.from_time =
                                          this.state.from_time)
                                      : (formikProps.values.from_time =
                                          this.state.from_time)
                                  }
                                /> */}
                              </div>
                            </Grid.Column>
                            <Grid.Column>
                              <div
                                class="ui hidden divider"
                                style={{ marginTop: 14 }}
                              ></div>
                              <div className="">
                                <RenderTimePicker
                                  fromtoProps="To"
                                  minTime={setHours(
                                    setMinutes(new Date(), 0),
                                    7
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 0),
                                    23
                                  )}
                                  onChange={(time) =>
                                    this.setState({
                                      to_time: time,
                                    })
                                  }
                                  value={
                                    (formikProps.values.to_time =
                                      this.state.to_time)
                                  }
                                  dateFormat="hh:mm a"
                                  minTime={setHours(
                                    setMinutes(new Date(), 0),
                                    7
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 0),
                                    23
                                  )}
                                />
                                {this.props.fromTimeErrorMessage && (
                                  <div className="visible ui error message">
                                    This information is required.
                                  </div>
                                )}
                                {/* <Field
                                  name="to_time"
                                  component={RenderTimePicker}
                                  onChange={(time) => {
                                    this.setState({
                                      to_time: time,
                                    });
                                  }}
                                  validate={(value) => {
                                    if (!value) {
                                      return this.setState({
                                        errorEventTime: true,
                                        errorEventTime_message:
                                          "This information is required",
                                      });
                                    }
                                    if (
                                      this.state.from_time.getTime() >
                                      value.getTime()
                                    ) {
                                      return this.setState({
                                        errorEventTime: true,
                                        errorEventTime_message:
                                          "Event 'to time' must be greater than 'from time",
                                      });
                                    }
                                  }}
                                  isTouched={formikProps.touched.to_time}
                                  isError={
                                    // this.state.to_time > new Date()
                                    //   ? undefined
                                    //   : this.state.to_time >
                                    //     this.state.from_time
                                    //   ? "Event to time must be greater than from time"
                                    //   : formikProps.errors.to_time
                                    this.state.from_time &&
                                    this.state.to_time &&
                                    this.state.from_time.getTime() >
                                      this.state.to_time.getTime()
                                      ? "Event 'to time' must be greater than 'from time'"
                                      : !this.state.to_time
                                      ? "This information is required"
                                      : "Please set after the current time"
                                  }
                                  fromtoProps="To"
                                  value={
                                    isEdit
                                      ? this.state.to_time > new Date()
                                        ? (formikProps.values.to_time =
                                            this.state.to_time)
                                        : new Date(formikProps.values.to_time)
                                      : this.state.to_time > new Date()
                                      ? (formikProps.values.to_time =
                                          this.state.to_time)
                                      : this.state.to_time
                                  }
                                /> */}
                                {!this.props.EventTimeErrorMessage && (
                                  <div className="visible ui error message">
                                    Event 'to time' must be greater than 'from
                                    time'
                                  </div>
                                )}
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>

                        {/*
                        <label className="formLabel">Shift work</label>
                        <div className="formMargin">
                          <Field
                            name="shiftwork"
                            component={RenderRadioGroup}
                            options={this.props.options}
                            onChange={formikProps.handleChange("shiftwork")}
                            validate={isRequired}
                            isTouched={formikProps.touched.shiftwork}
                            isError={formikProps.errors.shiftwork}
                          />
                        </div>*/}

                        {/*<Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Activity Date</label>
                              <Field
                                name="activityDate"
                                component={RenderPairDatePicker}
                                onChange={formikProps.handleChange(
                                  "activityDate"
                                )}
                                //validate={isRequired}
                                isTouched={formikProps.touched.activityDate}
                                isError={formikProps.errors.activityDate}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">Activity Time</label>
                              <Field
                                name="activityTime"
                                component={RenderPairTimePicker}
                                onChange={formikProps.handleChange(
                                  "activityTime"
                                )}
                                //validate={isRequired}
                                isTouched={formikProps.touched.activityTime}
                                isError={formikProps.errors.activityTime}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>*/}

                        <Grid.Row>
                          <Grid.Column>
                            <label className="formLabel">
                              Closing date for Registration
                            </label>
                            <div className="formMargin specific">
                              <Field
                                name="reg_to_date"
                                component={RenderDatePicker}
                                dateFormat="yyyy MMM dd"
                                onChange={(date) => {
                                  this.setState({
                                    reg_to_date: date,
                                  });
                                }}
                                // dateFormat="YYYY MMM DD"
                                validate={isRequired}
                                isTouched={formikProps.touched.reg_to_date}
                                isError={
                                  ! this.state.reg_to_date
                                  ? "This information is required."
                                  : this.state.eventDate 
                                    ? this.state.eventDate.getTime() < this.state.reg_to_date.getTime()
                                      ? "Must be less than registration date."
                                      : false
                                    : false                                  
                                  // this.state.regtime > new Date()
                                  //   ? undefined
                                  //   : formikProps.errors.reg_to_date
                                  // !this.state.regtime
                                  //   ? "This information is required"
                                  //   : this.state.eventDate &&
                                  //     this.state.regtime &&
                                  //     this.state.eventDate.getTime() <
                                  //       this.state.regtime.getTime()
                                  //   ? "Must be less than registration date"
                                  //   : !this.state.regtime &&
                                  //     "This information is required"
                                }
                                //isError={formikProps.errors.reg_to_date}
                                //value={isEdit ? formikProps.values.reg_to_date : formikProps.values.reg_to_date=this.state.regtime}
                                value={
                                  isEdit
                                    ? this.state.reg_to_date > new Date()
                                      ? (formikProps.values.reg_to_date =
                                          this.state.reg_to_date)
                                      : new Date(formikProps.values.reg_to_date)
                                    : this.state.regtime > new Date()
                                    ? (formikProps.values.reg_to_date =
                                        this.state.reg_to_date)
                                    : this.state.reg_to_date
                                }
                                // fromtoProps="From"
                                {...formikProps}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column></Grid.Column>
                        </Grid.Row>

                        <label
                          className="formLabel"
                          style={{ position: "relative" }}
                        >
                          Attachments
                        </label>
                        <img
                          src={Exclamation}
                          width="20px"
                          height="20px"
                          style={{
                            position: "relative",
                            top: 3,
                            marginLeft: 10,
                          }}
                          data-for="main"
                          data-tip="Only allowed to attached file in .pdf format."
                          data-iscapture="true"
                        />
                        <ReactTooltip
                          id="main"
                          place="right"
                          type="info"
                          effect="float"
                          multiline={true}
                        />
                        <div
                          className="formMargin"
                          style={{
                            position: "relative",
                            marginBottom: this.props.pdfFileCheck ? 60 : 80,
                            background: "#F9FBFF",
                            color: "#4075FF",
                            width: window.innerWidth / 2.7,
                            height: 40,
                            borderRadius: 8,
                            border: " 1px solid #DADADA",
                            paddingLeft: 20,
                            paddingRight: 10,
                          }}
                        >
                          <div>
                            <input
                              type="file"
                              onChange={this.props.onChangePdf}
                              style={{
                                display: "none",
                              }}
                              id="pdfFile"
                              accept=".pdf"
                            />
                          </div>
                          <label
                            l
                            for="pdfFile"
                            style={{
                              position: "absolute",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                position: "relative",
                              }}
                            >
                              <img
                                src={PdfFileIcon}
                                style={{ marginTop: 5, marginRight: 20 }}
                              />
                              <div
                                style={{
                                  marginTop: 10,
                                  borderBottom: "1px solid #4075FF",
                                }}
                              >
                                {" "}
                                {this.props.defaultPdfFileName.length > 50
                                  ? `${this.props.defaultPdfFileName.substring(
                                      0,
                                      50
                                    )}...`
                                  : this.props.defaultPdfFileName}
                                {}
                              </div>
                            </div>
                          </label>
                          {this.props.showRemovePdfIcon && (
                            <div
                              style={{
                                position: "absolute",
                                right: 10,
                                top: 8,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.deletePdf();
                                document.getElementById("pdfFile").value = "";
                              }}
                            >
                              <img
                                src={CrossImgIcon}
                                width="24px"
                                height="24px"
                              />
                            </div>
                          )}
                        </div>
                        {this.props.pdfFileCheck && (
                          <div className="visible ui error message">
                            PDF file only allowed
                          </div>
                        )}
                        <label className="formLabel">Image</label>
                        <div
                          className="formMargin"
                          style={{ position: "relative", marginTop: 20 }}
                        >
                          <Field
                            name="img_url"
                            component={RenderImage}
                            onChange={this.props.onChange}
                            isTouched={formikProps.touched.image}
                            isError={
                              !this.props.imgReq
                                ? "This information is required"
                                : formikProps.errors.image
                            }
                            validate={isRequired}
                            previewUrl={
                              isEdit
                                ? this.props.previewUrl !== ""
                                  ? this.props.previewUrl
                                  : formikProps.values.img_url
                                : this.props.previewUrl
                            }
                          />
                          {this.props.showRemoveImageIcon && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                zIndex: 999999,
                                background: "#4075FF",
                                right: 0,
                                width: 40,
                                height: 40,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderTopRightRadius: 10,
                                cursor: "pointer",
                              }}
                              onClick={this.props.removeImageHandle}
                            >
                              <img src={Image_Delete} />
                            </div>
                          )}

                          {!this.props.imgReq && (
                            <div className="visible ui error message">
                              This information is required
                            </div>
                          )}
                        </div>

                        <label className="formLabel">
                          Please enter the link if the registration is outside
                          of the APP
                        </label>
                        <div className="formMargin">
                          <Field
                            name="register_link"
                            component={RenderInput}
                            // validate={isRequired}
                            onChange={formikProps.handleChange("register_link")}
                            isTouched={formikProps.touched.register_link}
                            isError={formikProps.errors.register_link}
                            placeholder="https://"
                            value={formikProps.values.register_link}
                          />
                        </div>

                        {/*<Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">
                                Registration Date
                              </label>
                              <Field
                                name="regDate"
                                component={RenderPairDatePicker}
                                onChange={formikProps.handleChange("regDate")}
                                //validate={isRequired}
                                isTouched={formikProps.touched.regDate}
                                isError={formikProps.errors.regDate}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">
                                Registration Time
                              </label>
                              <Field
                                name="regTime"
                                component={RenderPairTimePicker}
                                onChange={formikProps.handleChange("regTime")}
                                //validate={isRequired}
                                isTouched={formikProps.touched.regTime}
                                isError={formikProps.errors.regTime}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>*/}

                        <Field
                          name="scfa"
                          component={RenderCheckbox}
                          onChange={formikProps.handleChange("scfa")}
                          //validate={isRequired}
                          isTouched={formikProps.touched.scfa}
                          isError={formikProps.errors.scfa}
                          value={formikProps.values.scfa === 1}
                          label="SCFA"
                        />
                        <br />

                        <div className="fullWidthCenterAlign">
                          <Button
                            content="Submit"
                            primary
                            size="large"
                            type="submit"
                            className="submitBtn"
                            disabled={this.props.submitLoading}
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.currentLoginUser.currentLoginUser.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityForm);
