import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_CHART = "FETCH_CHART";
export const FETCH_CHART_SUCCESS = "FETCH_CHART_SUCCESS";
export const FETCH_CHART_FAILURE = "FETCH_CHART_FAILURE";

export const fetchChart = (form_type_id) => ({
  type: FETCH_CHART,
  form_type_id,
});

export const fetchChartSuccess = (data) => ({
  type: FETCH_CHART_SUCCESS,
  data,
});

export const fetchChartFailure = (err) => ({
  type: FETCH_CHART_FAILURE,
  err,
});

export const getFormChart = (id, status, isDetail) => {
  return (dispatch) => {
    dispatch(fetchChart());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        if (isDetail) {
          api
            .get("/form/stats/", { form_id: id }, token)
            .then((res) => {
              // console.log("success")
              dispatch(fetchChartSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchChartFailure(err)));
        } else {
          api
            .get("/form/stats/", { form_type_id: id, status: status }, token)
            .then((res) => {
              // console.log("success")
              dispatch(fetchChartSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchChartFailure(err)));
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
};
