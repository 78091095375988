import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const FETCH_DASHBOARD_DONATION = "FETCH_DASHBOARD_DONATION";
export const FETCH_DASHBOARD_DONATION_SUCCESS =
  "FETCH_DASHBOARD_DONATION_SUCCESS";
export const FETCH_DASHBOARD_DONATION_FAILUTE =
  "FETCH_DASHBOARD_DONATION_FAILURE";

export const fetchDashboardDonation = () => ({
  type: FETCH_DASHBOARD_DONATION,
});

export const fetchDashboardDonationSuccess = (data) => ({
  type: FETCH_DASHBOARD_DONATION_SUCCESS,
  data,
});

export const fetchDashboardDonationFailure = (err) => ({
  type: FETCH_DASHBOARD_DONATION_FAILUTE,
  err,
});

export const getDashboardDonation = (start_date, end_date) => {
  return (dispatch) => {
    dispatch(fetchDashboardDonation());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/dashboard/call_donation",
            { from_date: start_date, to_date: end_date },
            token
          )
          .then((res) => {
            dispatch(fetchDashboardDonationSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchDashboardDonationFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
