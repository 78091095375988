import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Modal } from "semantic-ui-react";
import { CHANGE_PASSWORD, DASHBOARD_ROUTE } from "../../routes";
import { firebase } from "../../config/firebase";
import LoginForm from "./LoginForm";
import { checkUserShouldLogin, handleLogin, setActiveUserActions } from "../../store/actions";
import RenderLoader from "../../components/Loader";
import { getUserLogin } from "../../store/actions/getLoginUser";


class Login extends React.Component {
  state = {
    errmsg: "",
    isLoading: false,
  };

  handleSubmit = (values) => {
    const { dispatch, handleLogin, checkUserShouldLogin,getUserLogin } = this.props;
    this.setState({
      isLoading: true,
    });
    localStorage.setItem("isLogout", true);
    checkUserShouldLogin(values.email,(status,data)=>{
      if(status === true){
        if(data.data.should_login === true){
          try {
            firebase
              .auth()
              .signInWithEmailAndPassword(values.email, values.password)
              .then((res) => {
                // console.log(res);
                let isChangePassword = localStorage.getItem("isChangePassword");
                // // console.log()
                // console.log(typeof isChangePassword);
                getUserLogin(res.user.uid,(status,resData)=>{
                  if(status === true){
                    if(resData.is_new === 1){
                        if(isChangePassword === "true"){
                          this.props.setActiveUserActions(res.user.uid);
                          localStorage.setItem("isLogout", false);
                          localStorage.setItem("uid", res.user.uid);
                          dispatch(push(DASHBOARD_ROUTE));
                        }else{
                          dispatch(push({
                            pathname: CHANGE_PASSWORD,
                            state: {
                              current_password:values.password,
                              email:values.email,
                            }
                          }));
                          localStorage.setItem("isLogout", true);
                        }
                      
                    }else{
                      // this.props.setActiveUserActions(res.user.id);
                      localStorage.setItem("isLogout", false);
                      localStorage.setItem("uid", res.user.uid);
                      dispatch(push(DASHBOARD_ROUTE));
                    }
                  }else{
                    console.log("GET USER LOGIN ERRORS");
                  }
                  this.setState({
                    isLoading: false,
                  });
                })
                // dispatch(push(DASHBOARD_ROUTE));
              })
              .catch((err) => {
                this.setState({
                  isLoading: false,
                  errmsg: err.message,
                });
              });
          } catch (err) {
            console.log(err);
          }
        }else{
          
          this.setState({
            errmsg:data.message,
            isLoading: false,
          });
        }
      }else{
        this.setState({
          isLoading: false,
        });
      }
    })
    
    
  };

  render() {
    const { errmsg, isLoading } = this.state;
    const { auth } = this.props;
    return (
      <LoginForm
        isLoading={isLoading}
        message={errmsg}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase,
  chkStatefromLogin: state,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogin: () => {
    dispatch(handleLogin());
  },
  checkUserShouldLogin:(email,handleCallback) => {
    dispatch(checkUserShouldLogin(email,handleCallback))
  },
  getUserLogin:(id,handleCallback)=> {
    dispatch(getUserLogin(id,handleCallback))
  },
  setActiveUserActions:(id)=>{
        dispatch(
            setActiveUserActions(id)
        )
    },
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
