import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_STUDENT_LISTS = "FETCH_STUDENT_LISTS";
export const FETCH_STUDENT_LISTS_SUCCESS = "FETCH_STUDENT_LISTS_SUCCESS";
export const FETCH_STUDENT_LISTS_FAILURE = "FETCH_STUDENT_LISTS_FAILURE";
export const CLEAR_STUDENT_LISTS = "CLEAR_STUDENT_LISTS";

export const fetchStudentLists = (id) => ({
  type: FETCH_STUDENT_LISTS,
  id,
});

export const fetchStudentListsSuccess = (data) => ({
  type: FETCH_STUDENT_LISTS_SUCCESS,
  data,
});

export const fetchStudentListsFailure = () => ({
  type: FETCH_STUDENT_LISTS_FAILURE,
});

export const clearStudentLists = () => ({
  type: CLEAR_STUDENT_LISTS,
});

export const getStudentLists = (centre_id, class_id) => {
  return (dispatch) => {
    dispatch(fetchStudentLists());
    if (centre_id == undefined || class_id == undefined) {
      dispatch(clearStudentLists());
    }
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/user/student_list/",
            { centre_id: centre_id, class_id: class_id },
            token
          )
          .then((res) => {
            dispatch(fetchStudentListsSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchStudentListsFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
