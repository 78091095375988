import React, { Component } from "react";
import { Checkbox, Grid, Message } from "semantic-ui-react";

class RenderCheckboxGroup extends Component {
  render() {
    const { field, selected, options } = this.props;
    const selectedVal =
      field.value || selected || (options[0] !== undefined && options[0].value);
    return (
      <Grid>
        {this.props.options.map((data, ix) => (
          <Grid.Column key={ix}>
            <Checkbox
              onChange={(event, data) => this.props.onChange("" + data.value)}
              value={data.value}
              checked={this.props.value.includes(data.value)}
              key={ix}
              label={data.label}
            />
          </Grid.Column>
        ))}
        {this.props.isError && this.props.isTouched && (
          <Message
            visible
            error
            content={this.props.isError}
            style={{ width: "100%" }}
          />
        )}
      </Grid>
    );
  }
}
export default RenderCheckboxGroup;
