import React, { Component } from "react";
import {
  Grid,
  Header,
  Message,
  Dimmer,
  Loader,
  Modal,
  Button,
  Image,
  List,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { ACTIVITY_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import RenderButton from "../../components/Button";
import ActivityForm from "./ActivityForm";
import ActivityEditForm from "./ActivityEditForm";
import defaultimage from "../../assets/images/defaultimage.png";
import PeopleList from "../../components/PeopleList";
import {
  getEventDetail,
  getCentersClasses,
  getParticipants,
  getEvents,
} from "../../store/actions";
import RenderLoader from "../../components/Loader";
import Activity_Logo from "../../assets/images/ActivityEditLogo.png";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import moment from "moment";
import SuccessMark from "../../components/SuccessMark";

class ActivityEdit extends Component {
  state = {
    isEdit: false,
    isNotiLoading: false,
    NotiOrMsg: "",
    NotiStatus: null,
    isModal: false,
    previewUrl: "",
    radioList: [
      {
        value: "morningshift",
        label: "Morning shift",
      },
      {
        value: "nightshift",
        label: "Night shift",
      },
      {
        value: "fullday",
        label: "Full day",
      },
    ],
    class_id: "",
    img_url: null,
    pdfFileCheck: false,
    pdfFile: null,
    defaultPdfFileName: "Upload PDF file",
    showRemovePdfIcon: false,
    pdfFileTitle: "",
    showModal: false,
    customMessage: "",
    up: true,
    low: false,
    sentTo: "register",
    sentNotiLoading: false,
  };

  componentDidMount() {
    this.props.getEventDetail();
    this.props.getCentersClasses();
    this.props.getParticipants();
    this.props.getEvents();
    // if (
    //   this.props.event &&
    //   this.props.event.event_file_name &&
    //   this.props.event.event_file_name
    // ) {
    //   this.setState({
    //     pdfFileTitle: this.props.event.event_file_name,
    //     defaultPdfFileName: this.props.event.event_file_name,
    //   });
    // } else {
    //   this.setState({
    //     pdfFileTitle: "",
    //     defaultPdfFileName: "Upload PDF file",
    //   });
    // }
  }

  changePdfTitle = (name) => {
    if (name) {
      this.setState({
        defaultPdfFileName: name,
        pdfFileTitle: name,
      });
    } else {
      this.setState({
        pdfFileTitle: "",
        defaultPdfFileName: "Upload PDF file",
      });
    }
  };
  handleSubmit = (values) => {
    const reg_to_date = moment(values.reg_to_date).format("YYYY-MM-DD");
    const date = moment(values.date).format("YYYY-MM-DD");
    const from_time = moment(values.from_time).format("HH:mm:ss");
    const to_time = moment(values.to_time).format("HH:mm:ss");

    let formdata = new FormData();
    this.setState({
      isSaving: true,
      hasError: false,
    });
    if (this.state.pdfFile && this.state.pdfFile.type !== "application/pdf") {
      return this.setState({
        pdfFileCheck: true,
      });
    } else {
      this.setState({
        pdfFileCheck: false,
      });
    }

    const centre_ids = this.props.centerclasses.map((item) => item.id);
    let class_ids = [];

    if (values.centre_ids === "all" && values.class_ids !== "all") {
      this.props.centerclasses.forEach((item) => {
        item.class.forEach((element) => {
          if (element.name.toLowerCase() === values.class_ids) {
            class_ids.push(element.id);
          }
        });
      });
      class_ids[0] = `[${class_ids[0]}`;
      class_ids[class_ids.length - 1] = `${class_ids[class_ids.length - 1]}]`;
    } else if (values.centre_ids === "all" && values.class_ids === "all") {
      this.props.centerclasses.forEach((item) => {
        item.class.forEach((element) => {
          class_ids.push(element.id);
        });
      });
    }

    formdata.append("date", date);
    formdata.append("id", this.props.match.params.id);
    formdata.append("title", values.title);
    formdata.append("description", values.description);
    formdata.append("max_vacancy", values.max_vacancy);
    formdata.append(
      "centre_ids",
      values.centre_ids === "all"
        ? JSON.stringify(centre_ids)
        : [values.centre_ids]
    );
    formdata.append(
      "class_ids",
      values.centre_ids === "all"
        ? JSON.stringify(class_ids)
        : [values.class_ids]
    );
    formdata.append("scfa", values.scfa === "true" ? 1 : 0);
    formdata.append("from_time", from_time);
    formdata.append("to_time", to_time);
    formdata.append(
      "img_url",
      this.state.img_url !== null ? this.state.img_url : values.img_url
    );
    formdata.append("event_file", this.state.pdfFile);
    formdata.append("event_file_name", this.state.pdfFileTitle.slice(0, -4));

    formdata.append("reg_to_date", reg_to_date);
    values.register_link !== "" &&
      values.register_link !== undefined &&
      values.register_link !== null &&
      formdata.append("register_link", values.register_link);
    // formdata.append("register_link", values.register_link);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/events/event/", formdata, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              isSaving: false,
              hasError: false,
            });
            this.props.dispatch(push(ACTIVITY_ROUTE));
          } else {
            this.setState({
              isSaving: false,
              hasError: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  onChangePdf = (e) => {
    let file = e.target.files[0];

    if (file) {
      this.setState({
        pdfFile: file,
        defaultPdfFileName: file.name,
        showRemovePdfIcon: true,
        pdfFileTitle: file.name,
      });
    }
  };

  checkPdfFileExist = () => {
    this.setState({
      showRemovePdfIcon: true,
    });
  };

  deletePdf = () => {
    this.setState({
      pdfFile: null,
      defaultPdfFileName: "Upload PDF file",
      pdfFileCheck: false,
      showRemovePdfIcon: false,
      pdfFileTitle: "",
    });
  };

  Send = (values) => {
    this.setState({ isNotiLoading: true });
    let notiData = new FormData();
    notiData.append("title", values.title);
    notiData.append("message", values.message);
    notiData.append("receiver", JSON.stringify(values.receiver));
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/notification/", notiData, token).then((res) => {
          if (res.message === "Success") {
            this.setState({
              isModal: true,
              isNotiLoading: false,
              NotiStatus: "Success",
            });
          } else {
            this.setState({
              isModal: true,
              isNotiLoading: false,
              NotiStatus: "Fail",
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  sendNotification = () => {
    if (this.state.customMessage.trim() === "") {
      return alert("Message should not be empty.");
    }
    this.setState({ sentNotiLoading: true });
    let formData = new FormData();

    if (this.state.sentTo === "all") {
      const receiver = [
        {
          centre_id: this.props.event.centre_ids,
          class_id: this.props.event.class_ids,
          role: ["parent"],
        },
      ];
      formData.append("title", this.props.event.title);
      formData.append("message", this.state.customMessage.trim());
      formData.append("push_msg", this.state.customMessage.trim());
      formData.append("receiver", JSON.stringify(receiver));
      formData.append("ref_type", "event");
      formData.append("ref_id", this.props.event.id);
    } else {
      formData.append("push_msg", this.state.customMessage);
      formData.append("ref_type", "event");
      formData.append("event_id", this.props.event.id);
    }

    this.setState({ isNotiLoading: true });

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        const eventPush = this.state.sentTo === "register" ? "event_push/" : "";
        if (this.state.sentTo === "register") {
          formData.append("sender", res.uid);
        }
        api.post(`/notification/${eventPush}`, formData, token).then((res) => {
          if (res.message === "Success") {
            this.setState({
              sentNotiLoading: false,
              showModal: false,
              isModal: true,
              isNotiLoading: false,
              NotiOrMsg:
                this.state.sentTo === "register" ? "message" : "notification",
              NotiStatus: "Success",
              customMessage: "",
              sentTo: "register",
            });
          } else {
            this.setState({
              sentNotiLoading: false,
              showModal: false,
              isModal: true,
              isNotiLoading: false,
              NotiStatus: "Fail",
              customMessage: "",
              sentTo: "register",
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        img_url: file,
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const {
      event = {},
      haveData,
      centerclasses = [],
      isloading,
      participants = [],
      events = [],
    } = this.props;
    const { isEdit, isSaving, isNotiLoading } = this.state;
    let centers = [],
      classes = [],
      goldObj = {},
      selectedCentre = {};
    centerclasses.map((data) => {
      centers.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });

    if (event.centre_ids) {
      if (event.centre_ids.length === 1) {
        goldObj = centerclasses.find(
          (result) => result.id === event.centre_ids[0]
        );
        goldObj !== undefined &&
          goldObj.class.map((data) => {
            classes.push({
              key: data.id,
              text: data.name,
              value: data.id,
            });
          });
      }

      if (event.centre_ids.length > 1) {
        goldObj = { name: "All Centres" };
        if (event.centre_ids.length === event.class_ids.length) {
          centerclasses[0].class.forEach((classItem) => {
            event.class_ids.forEach((item) => {
              if (item === classItem.id)
                goldObj = { ...goldObj, className: classItem.name };
            });
          });
        } else goldObj = { ...goldObj, className: "All Classes" };
      }
    }

    selectedCentre = classes.find(
      (result) => result.key === event.class_ids[0]
    );
    if (isEdit) {
      return (
        <Grid className="fullHeight">
          <Dimmer active={isloading || isSaving} inverted>
            <Loader inverted size={"big"} />
          </Dimmer>
          <Grid.Row>
            <Grid.Column width={16}>
              <RoutingButton
                to={ACTIVITY_ROUTE}
                className="backBtn"
                btnText="Back"
                showIcon={true}
              />
              {haveData && (
                <ActivityEditForm
                  isSaving={isSaving}
                  handleSubmit={this.handleSubmit}
                  options={this.state.radioList}
                  onChange={this.onChange}
                  previewUrl={this.state.previewUrl || event.img_url}
                  formTitle={"Edit Event"}
                  initialValues={event}
                  isEdit={true}
                  centers={centers}
                  centerclasses={centerclasses}
                  pdfFileCheck={this.state.pdfFileCheck}
                  onChangePdf={this.onChangePdf}
                  defaultPdfFileName={this.state.defaultPdfFileName}
                  deletePdf={this.deletePdf}
                  pdfFile={this.state.pdfFile}
                  showRemovePdfIcon={this.state.showRemovePdfIcon}
                  changePdfTitle={this.changePdfTitle}
                  checkPdfFileExist={this.checkPdfFileExist}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else {
      return (
        <Grid className="fullHeight">
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid className="noBottomMargin">
                <Grid.Column floated="left" width={16}>
                  <RoutingButton
                    to={ACTIVITY_ROUTE}
                    className="backBtn"
                    btnText="Back"
                    showIcon={true}
                  />

                  <Grid className="subContainer">
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <div style={{ width: "50%", margin: "auto" }}>
                          <Grid.Row>
                            <Grid.Column width={13}>
                              <Header
                                as="h4"
                                floated="left"
                                style={{
                                  fontFamily: "Open Sans",
                                  fontSize: "20px",
                                  fontStyle: "normal",
                                  fontWeight: "bold",
                                  lineHeight: "27px",
                                  color: "#000",
                                  position: "relative",
                                  top: "10px",
                                }}
                              >
                                Edit Event
                              </Header>
                            </Grid.Column>

                            <Grid.Column
                              floated="right"
                              width={3}
                              style={{
                                position: "absolute",
                                right: 26,
                                width: 125,
                              }}
                            >
                              <RenderButton
                                floated="right"
                                className="primaryBtn"
                                fluid={true}
                                btnText="Edit"
                                onClick={() => this.setState({ isEdit: true })}
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <div style={{ marginTop: 36 }}>
                            <label className="formLabel">Title</label>
                            <div className="formMargin">{event.title}</div>

                            <label className="formLabel">Description</label>
                            <div className="formMargin">
                              {event.description}
                            </div>

                            <Grid divided="vertically">
                              <Grid.Row columns={2}>
                                <Grid.Column style={{ marginBottom: 0 }}>
                                  <label className="formLabel">Centres</label>
                                  <div>
                                    {goldObj !== undefined && goldObj.name}
                                  </div>
                                </Grid.Column>
                                <Grid.Column style={{ marginBottom: 0 }}>
                                  <label className="formLabel">Class</label>
                                  {selectedCentre && (
                                    <div>{selectedCentre.text}</div>
                                  )}
                                  {goldObj.className && (
                                    <div>{goldObj.className}</div>
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>

                            {/*<label className="formLabel">Shift work</label>
                            <div className="formMargin">Ｎight shift</div>

                            <Grid divided="vertically">
                              <Grid.Row columns={2}>
                                <Grid.Column>
                                  <label className="formLabel">
                                    Activity Date
                                  </label>
                                  <div className="formMargin">2020 Mar 01</div>
                                </Grid.Column>
                                <Grid.Column>
                                  <label className="formLabel">
                                    Activity Time
                                  </label>
                                  <div className="formMargin">
                                    13:00 ~ 14:00
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>*/}

                            <Grid divided="vertically">
                              <Grid.Row columns={1}>
                                <Grid.Column>
                                  <label className="formLabel">
                                    Closing date for Registration
                                  </label>
                                  <div className="formMargin">
                                    {moment(event.reg_to_date).format(
                                      "YYYY MMM DD"
                                    )}
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>

                            <Dimmer
                              active={isloading || isSaving || isNotiLoading}
                              inverted
                            >
                              <Loader inverted size={"big"} />
                            </Dimmer>

                            <label className="formLabel">Image</label>
                            <div
                              className="formMargin"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="imgContainer"
                                style={{
                                  backgroundImage:
                                    "url(" + event.img_url ||
                                    defaultimage + ")",
                                  width: "100%",
                                  height: "400px",
                                  marginTop: "20px",
                                }}
                              >
                                {/*<img
                                  className="removeBtn"
                                  src={close}
                                  alt="removeBtn"
                                />*/}
                              </div>
                            </div>
                            {event.register_link === "http://" ? null : (
                              <div>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Open Sans",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "19px",
                                  }}
                                >
                                  Outside link o
                                </p>
                                <p
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    textDecoration: "underline",
                                    color: "#4075FF",
                                    marginBottom: "50px",
                                  }}
                                >
                                  <a
                                    onClick={() =>
                                      window.open(event.register_link)
                                    }
                                  >
                                    {event.register_link}
                                  </a>
                                </p>
                              </div>
                            )}

                            <Modal
                              onClose={() =>
                                this.setState({ showModal: false })
                              }
                              onOpen={() => this.setState({ showModal: true })}
                              open={this.state.showModal}
                              // trigger={<Button>Show Modal</Button>}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: 501,
                                paddingTop: 48,
                                paddingLeft: 8,
                                paddingRight: 8,
                              }}
                            >
                              <Modal.Content style={{ padding: 0 }}>
                                <Dimmer
                                  active={this.state.sentNotiLoading}
                                  inverted
                                >
                                  <Loader inverted size={"big"} />
                                </Dimmer>
                                <div
                                  style={{
                                    fontSize: 24,
                                    fontWeight: "700",
                                  }}
                                >
                                  Sent Notification
                                </div>
                                <textarea
                                  style={{
                                    width: "100%",
                                    height: 162,
                                    marginTop: 21,
                                    padding: "17px 15px 17px 15px",
                                    border: "1px solid #DADADA",
                                    borderRadius: 8,
                                    resize: "none",
                                  }}
                                  onChange={(event) => {
                                    this.setState({
                                      customMessage: event.target.value,
                                    });
                                  }}
                                  value={this.state.customMessage}
                                ></textarea>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "19px 0px 15px 0px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="sentTo"
                                    value="register"
                                    id="up"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: 13,
                                    }}
                                    onChange={(event) => {
                                      this.setState({
                                        sentTo: event.target.value,
                                        up: true,
                                        low: false,
                                      });
                                    }}
                                    checked={this.state.up}
                                  />
                                  <label for="up">
                                    Send email to registered user in the event
                                  </label>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="sentTo"
                                    value="all"
                                    id="low"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: 13,
                                    }}
                                    onChange={(event) => {
                                      this.setState({
                                        sentTo: event.target.value,
                                        up: false,
                                        low: true,
                                      });
                                    }}
                                    checked={this.state.low}
                                  />
                                  <label for="low">
                                    {/* Send to all user (classes) to sign up for
                                    the event */}
                                    Send to the targeted users to sign up to the
                                    event
                                  </label>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: 40,
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      this.setState({ showModal: false })
                                    }
                                    style={{
                                      width: 195,
                                      height: 48,
                                      borderRadius: 20,
                                      border: "1px solid #4075FF",
                                      display: "grid",
                                      placeItems: "center",
                                      fontSize: 14,
                                      fontWeight: "600",
                                      color: "#4075FF",
                                      cursor: "pointer",
                                      marginRight: 11,
                                    }}
                                    disabled={this.state.sentNotiLoading}
                                  >
                                    Cancel
                                  </div>
                                  <div
                                    onClick={this.sendNotification}
                                    style={{
                                      width: 195,
                                      height: 48,
                                      borderRadius: 20,
                                      display: "grid",
                                      placeItems: "center",
                                      fontSize: 14,
                                      fontWeight: "600",
                                      color: "#fff",
                                      backgroundColor: " #4075FF",
                                      cursor: "pointer",
                                    }}
                                    disabled={this.state.sentNotiLoading}
                                  >
                                    Sent
                                  </div>
                                </div>
                              </Modal.Content>
                            </Modal>
                            <Modal
                              size={"mini"}
                              style={{
                                zIndex: 2,
                                width: "375px",
                                // height: "287px",
                                height: 340,
                                position: "relative",
                                left: "5%",
                              }}
                              open={
                                this.state.isNotiLoading === true
                                  ? false
                                  : this.state.isModal
                              }
                              onClose={() => this.setState({ isModal: false })}
                            >
                              <Modal.Content style={{ height: "287px" }}>
                                <Modal.Header
                                  style={{
                                    fontFamily: "Open Sans",
                                    fontWeight: "700",
                                    fontStyle: "normal",
                                    fontSize: "24px",
                                    lineHeight: "32.68px",
                                    textAlign: "center",
                                    marginBottom: "30px",
                                  }}
                                >
                                  Send {this.state.NotiStatus}
                                </Modal.Header>
                                <div
                                  style={{ color: "#7ecb20" }}
                                  className="centerText"
                                >
                                  <SuccessMark width={120} height={120} />
                                </div>
                                <p className="centerText mt14" style={{}}>
                                  Your {this.state.NotiOrMsg} is{" "}
                                  {this.state.NotiStatus === "Success"
                                    ? "sent successfully!"
                                    : "falied!"}
                                </p>
                                <div
                                  style={{
                                    textAlign: "center",
                                    position: "relative",
                                    top: "5%",
                                  }}
                                >
                                  <Button
                                    style={{
                                      color: "#fff",
                                      width: "236px",
                                      height: "48px",
                                      backgroundColor: "#4075FF",
                                      borderRadius: "20px",
                                    }}
                                    onClick={() =>
                                      this.setState({ isModal: false })
                                    }
                                  >
                                    OK
                                  </Button>
                                </div>
                              </Modal.Content>
                            </Modal>

                            <label
                              className="formLabel"
                              style={{
                                fontSize: "18px",
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                lineHeight: "25px",
                                color: "#1B1A1A",
                              }}
                            >
                              Participate in
                            </label>
                            <label
                              style={{
                                backgroundColor: "#4075FF",
                                width: "45px",
                                height: "22px",
                                fontFamily: "Open Sans",
                                zIndex: "-999px",
                                marginLeft: "10px",
                                paddingRight: "5px",
                                color: "#FFF",
                                fontSize: "16px",
                              }}
                            >
                              <img
                                src={Activity_Logo}
                                style={{
                                  width: "16.33px",
                                  height: "14px",
                                  marginLeft: "5px",
                                }}
                              />{" "}
                              {participants.length}
                            </label>
                            <span
                              style={{
                                float: "right",
                                textDecoration: "underline",
                                fontWeight: 600,
                                fontStyle: "normal",
                                fontSize: "14px",
                                lineHeight: "21px",
                                marginRight: "10px",
                                color: "#4075FF",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.setState({ showModal: true });
                              }}
                            >
                              Sent Notification
                            </span>

                            <div
                              style={{
                                marginTop: "5px",
                                marginBottom: "50px",
                              }}
                            >
                              {participants.length === 0 ? (
                                <Message
                                  header="No participant found"
                                  content="There is no participant in this period."
                                  warning
                                />
                              ) : (
                                <List
                                  style={{
                                    height: "400px",
                                    overflowY: "auto",
                                    border: "1px solid #DADADA",
                                  }}
                                >
                                  {participants !== undefined &&
                                    participants.map((participant) => (
                                      <PeopleList {...participant} />
                                    ))}
                                </List>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  event: state.event.event.data,
  isloading: state.event.loading,
  haveData: state.event.haveData,
  centerclasses: state.centersClasses.centers_classes.data,
  participants: state.participants.participants.data,
  isparticipantsloading: state.participants.loading,
  haveparticipantData: state.participants.haveData,
  events: state.events.events !== undefined ? state.events.events.data : [],
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getEventDetail: () => dispatch(getEventDetail(props.match.params.id, 10)),
  getCentersClasses: () => dispatch(getCentersClasses()),
  getParticipants: (event_id, filtered_by) =>
    dispatch(getParticipants(props.match.params.id, "event_id")),
  getEvents: () => dispatch(getEvents()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ActivityEdit);
