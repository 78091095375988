import {
  FETCH_MESSAGE,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MESSAGE_FAILURE,
  CLEAR_ADMIN,
  addMessageSuccess,
  addMessageFail,
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  LOADMORE_MESSAGE_SUCCESS,
  REFRESH_MESSAGE,
} from "../actions";

const initialState = {
  gmessage: [],
  loading: false,
  err: null,
  haveData: false,
  hasMoreMessage: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MESSAGE:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
        gmessage: [],
      };
    case FETCH_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        gmessage: [...action.data.data],
        nextMessageUrl: action.data.next,
      };
    case FETCH_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        gmessage: [],
      };

    case ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        room_id: action.data.room_id,
        gmessage: [...state.gmessage, action.data],
        haveData: true,
        //  gmessage: {
        //    room_id: action.data.room_id
        //   ,message:action.data.message ,
        //   next: action.data.next,
        //   data: [...action.data.data,...state.gmessage.data] }
      };
    case REFRESH_MESSAGE:
      return {
        ...state,
        gmessage: [],
      };
    case LOADMORE_MESSAGE_SUCCESS:
      return {
        ...state,
        gmessage: [...state.gmessage, ...action.data.data],
        haveData: true,
        nextMessageUrl: action.data.next,
      };
    case CLEAR_ADMIN:
      return initialState;
    default:
      return state;
  }
};
