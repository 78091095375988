import Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";

const firebaseConfig = {
  //DEV
  // apiKey: "AIzaSyAyREEgGB455cId5Mop_Y19naj2kktb6ok",
  // authDomain: "morningstar-dev.firebaseapp.com",
  // databaseURL: "https://morningstar-dev.firebaseio.com",
  // projectId: "morningstar-dev",
  // storageBucket: "morningstar-dev.appspot.com",
  // messagingSenderId: "235223802944",
  // appId: "1:235223802944:web:609ef78f7629ca2badaba5",
  // measurementId: "G-QZ9WP4BBW5",

  //QA
  // apiKey: "AIzaSyCtQZrxpPNcJ0HMowGngf5AVGFMOp9Bqmo",
  // authDomain: "morningstar-qa.firebaseapp.com",
  // databaseURL: "https://morningstar-qa.firebaseio.com",
  // projectId: "morningstar-qa",
  // storageBucket: "morningstar-qa.appspot.com",
  // messagingSenderId: "249499896833",
  // appId: "1:249499896833:web:d90b6e3ee164bd4a1a0400",
  // measurementId: "G-T3KV52DZD9",

  apiKey: "AIzaSyD1d1D8inyzIG1f39K5ep_SK6XkVwSsSV0",
  authDomain: "morningstar-89ffa.firebaseapp.com",
  //databaseURL: "https://morningstar-qa.firebaseio.com",
  projectId: "morningstar-89ffa",
  storageBucket: "morningstar-89ffa.appspot.com",
  messagingSenderId: "268581013911",
  appId: "1:268581013911:web:e192179bdd684905e8dcd2",
  measurementId: "G-PQYGH7GFSB"
};

// Initialize Firebase
const firebase = Firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const messaging = firebase.messaging();
const db = firebase.firestore();

// messaging.onMessage((payload) => {
//   console.log("payload", payload);
// });

export { firebase, storage, messaging, db };
