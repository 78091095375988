import { parseWithOptions } from "date-fns/fp";
import {propertyOf} from "lodash";
import React, { Component } from "react";
import { Radio, Grid, Form } from "semantic-ui-react";

const RenderPButtonGroup = (props) => {
  return (
    <Grid>
      <Grid.Row columns={2}>
        <div className="classGp">
        {
          props.options.map((data, ix) => { 
            let isChk = props.value !== undefined && props.value.includes(data.value);
            return (
              <Grid.Column key={ix} className="radio-toolbar" >
                <label>
                 <Form.Field
                  control="input"
                  type="checkbox"
                  onChange={props.onChange}
                  value={data.value}             
                  />
                  {
                    isChk ? <span className="checked">{data.text}</span> : <span>{data.text}</span>
                  }                 
                 </label>
              </Grid.Column>           
            )
          })
        }</div>
      </Grid.Row>
    </Grid>
  );
};
export default RenderPButtonGroup;
