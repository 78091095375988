import React, { Component } from "react";
import { Grid, Loader, Dimmer } from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { USERMANAGEMENT_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import userimage from "../../assets/images/userimage.png";
import Userfrom from "./UserForm";
import { firebase } from "../../config/firebase";
import { getCentersClasses } from "../../store/actions";
import RenderLoader from "../../components/Loader";
import api from "../../config/api";

class UserCreate extends Component {
  state = {
    previewUrl: userimage,
    imagefile: null,
    isloading: false,
    centre_id: "",
    radioList: [
      {
        value: "centre",
        label: "Admin",
      },
      {
        value: "facilitator",
        label: "Facilitator",
      },
      {
        value: "parent",
        label: "Parent",
      },
      {
        value: "student",
        label: "Student",
      },
    ],
    isSaving: false,
    hasError: false,
    isSubmitProcessing: false,
    imgError: false,
    adminNameError: false,
    adminNameMessage: "",
  };

  componentDidMount() {
    // this.props.getCentersClasses();

    let user_type = "student";
    let centre_id = "";
    //user_type = localStorage.getItem("user_type") !== null ? localStorage.getItem("user_type") : localStorage.setItem("user_type", this.state.user_type);
    centre_id =
      localStorage.getItem("centre_id") !== null
        ? localStorage.getItem("centre_id")
        : localStorage.setItem("centre_id", this.state.centreid);
    //this.props.getUsers(user_type, centre_id);
    // this.props.getClasses(centre_id);
  }
  handleFilter = (e, res) => {};
  handleSubmit = (values) => {
    this.setState({
      adminNameError: false,
    });
    var arr = [];
    var arrfac = [];
    values.children_id.map((v) => arr.push(v.value));
    values.facilitor_id.map((v) => arrfac.push(v.value));

    this.setState({ isSubmitProcessing: false });
    values.img = this.state.previewUrl;
    let user_type =
      localStorage.getItem("user_type") == "centre"
        ? "admin"
        : localStorage.getItem("user_type");

    this.setState({
      isSaving: true,
      hasError: false,
    });
    if (!/^[a-z A-Z \d]{0,}$/.test(values.name)) {
      return this.setState({
        adminNameError: true,
      });
    }
    if (this.state.imagefile == null) {
      return this.setState({ imgError: true });
    }

    this.setState({ imgError: false });
    let formdata = new FormData();
    formdata.append("name", values.name);
    // formdata.append("centre_id", values.centre_id);
    formdata.append("img", this.state.imagefile);
    user_type !== "student" && formdata.append("email", values.email);
    user_type !== "student" &&
      user_type !== "student" &&
      formdata.append("phone", values.phone);
    (user_type === "admin" || user_type === "facilitator") &&
      formdata.append("employee_no", values.employee_no);
    // user_type !== "admin" && formdata.append("class_id", values.class_id);
    user_type === "admin" &&
      formdata.append(
        "admin_type",
        values.centre_id === "all" ? "hq" : values.admin_type
      );
    // user_type === "admin" &&
    //   formdata.append("centre_id", localStorage.getItem("centre_id"));
    user_type === "admin" && formdata.append("centre_id", values.centre_id);
    user_type === "student" && formdata.append("centre_id", values.centre_id);
    user_type === "student" && formdata.append("class_id", values.class_id);

    user_type === "facilitator" &&
      localStorage.getItem("adminType") == "hq" &&
      formdata.append("centre_id", values.centre_id);

    user_type === "facilitator" &&
      localStorage.getItem("adminType") == "centre" &&
      formdata.append("centre_id", localStorage.getItem("centre_Id"));

    // user_type === "facilitator" && formdata.append("class_id", values.class_id);
    user_type === "facilitator" &&
      formdata.append("class_id", JSON.stringify(arrfac));
    // user_type === "parent" &&
    //   formdata.append("relationship", values.relationship);
    user_type === "parent" &&
      formdata.append("children_id", JSON.stringify(arr));
    user_type === "student" && formdata.append("school", values.school);
    user_type === "student" &&
      formdata.append("dob", localStorage.getItem("dob"));
    // user_type === "student" && formdata.append("student_no", values.student_no);
    user_type === "student" &&
      formdata.append("scfa", values.scfa === "true" ? 1 : 0);
    // user_type === "parent" &&
    //   formdata.append("scfa", values.scfa === "true" ? 1 : 0);

    return firebase.reloadAuth().then((res) => {
      try {
        this.setState({ isloading: true });
        const token = res.ya === null ? res.za : res.ya;

        api.post("/user/" + user_type + "/", formdata, token).then((res) => {
          //{"message": "name, centre_id, admin_type, email, phone, img, employee_no are required.", "data": []}

          if (res.message === "Success") {
            this.setState({ isSubmitProcessing: false });
            this.setState({ isloading: false });
            this.props.dispatch(push(USERMANAGEMENT_ROUTE));
          } else {
            this.setState({ isSubmitProcessing: false });
            this.setState({ isloading: false });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        this.setState({ isSubmitProcessing: false });
        console.log(err);
      }
    });
  };

  onChange = (event, res) => {
    //localStorage.setItem('centre_id',event.target.value)
    console.log("e", event);
    let file = event.target.files[0];
    this.setState({
      imagefile: file,
      previewUrl: URL.createObjectURL(file),
      imgError: false,
    });
  };

  render() {
    const { centerclasses = [], isloading } = this.props;
    let user_type = localStorage.getItem("user_type");
    let centers = [],
      classes = [],
      students = [],
      goldObj = {};
    //activeCenterObj = {},
    //activeCenterId = "";

    centerclasses.map((data) => {
      centers.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    localStorage.setItem("centre_list", JSON.stringify(centerclasses));
    goldObj = centerclasses.find(
      (result) => result.id === localStorage.getItem("centre_id")
    );
    goldObj !== undefined &&
      goldObj.class.map((data) => {
        classes.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
    //activeCenterId =
    //  localStorage.getItem("centre_id") !== null
    //    ? localStorage.getItem("centre_id")
    //    : localStorage.getItem("centre_id", this.state.centre_id);
    //activeCenterObj = centers.find((result) => result.value === activeCenterId);
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={USERMANAGEMENT_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <Userfrom
              isSubmitProcessing={this.state.isSubmitProcessing}
              handleSubmit={this.handleSubmit}
              options={this.state.radioList}
              onChange={this.onChange}
              isloading={this.state.isloading}
              previewUrl={this.state.previewUrl}
              formTitle={"Add User"}
              centers={centers}
              classes={classes}
              imgError={this.state.imgError}
              adminNameError={this.state.adminNameError}
              adminNameMessage={this.state.adminNameMessage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  centerclasses: state.centersClasses.centers_classes.data,
  usersList: state.users.user !== undefined ? state.users.user.data : [],
  haveData: state.centersClasses.haveData,
  isloading: state.centersClasses.loading,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
