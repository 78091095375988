import React from "react";
import { Input, Message } from "semantic-ui-react";

const RenderProfileImgUpload = (props) => {
  return (
    <>
      <div>
        <div
          className="profileimgContainer"
          style={{
            backgroundImage: "url(" + props.previewUrl + ")",
          }}
        >
          <label className="fileLabel">
            <Input
              //ref={this.inputRef}
              type="file"
              onChange={props.onChange}
              accept="image/*"
            />
            <span>
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{position: 'relative', top: -5 }}
              >
                <path
                  d="M16.2142 2.46154H13.5717L13.264 1.53846C13.1157 1.09026 12.8298 0.700269 12.4469 0.424038C12.064 0.147807 11.6038 -0.000582136 11.1317 1.71632e-06H7.32985C6.85787 -0.000358931 6.3978 0.148127 6.01508 0.424336C5.63237 0.700544 5.3465 1.09041 5.19815 1.53846L4.88985 2.46154H2.24738C1.65154 2.46219 1.08029 2.69918 0.658963 3.1205C0.237638 3.54183 0.000651552 4.11308 0 4.70892V13.7551C0.00130253 14.3505 0.238576 14.9211 0.659831 15.3419C1.08109 15.7627 1.65197 15.9994 2.24738 16H16.2166C16.812 15.9987 17.3827 15.7614 17.8034 15.3402C18.2242 14.9189 18.4609 14.348 18.4615 13.7526V4.70646C18.4602 4.11105 18.223 3.54043 17.8017 3.11963C17.3805 2.69884 16.8096 2.46219 16.2142 2.46154ZM17.2308 13.7526C17.2304 14.0221 17.1232 14.2805 16.9327 14.4711C16.7421 14.6617 16.4837 14.7689 16.2142 14.7692H2.24738C1.97786 14.7689 1.71947 14.6617 1.52889 14.4711C1.33831 14.2805 1.23109 14.0221 1.23077 13.7526V4.70646C1.23175 4.43737 1.33924 4.17961 1.52976 3.98956C1.72027 3.79951 1.97829 3.69263 2.24738 3.69231H5.33354C5.46264 3.69221 5.58845 3.65151 5.69314 3.57597C5.79784 3.50043 5.87613 3.39387 5.91692 3.27139L6.36554 1.92554C6.4329 1.72311 6.56232 1.54704 6.73542 1.42233C6.90852 1.29761 7.1165 1.23059 7.32985 1.23077H11.1317C11.3451 1.23054 11.5532 1.29761 11.7263 1.42246C11.8995 1.5473 12.0288 1.72356 12.096 1.92616L12.5446 3.27139C12.5854 3.39387 12.6637 3.50043 12.7684 3.57597C12.8731 3.65151 12.9989 3.69221 13.128 3.69231H16.2142C16.4837 3.69264 16.7421 3.79985 16.9327 3.99043C17.1232 4.18101 17.2304 4.4394 17.2308 4.70892V13.7526Z"
                  fill="white"
                />
                <path
                  d="M9.23074 4.30774C8.31791 4.30774 7.42557 4.57843 6.66657 5.08557C5.90758 5.59272 5.31601 6.31354 4.96668 7.15689C4.61736 8.00024 4.52596 8.92824 4.70404 9.82354C4.88213 10.7188 5.3217 11.5412 5.96717 12.1867C6.61265 12.8322 7.43503 13.2717 8.33033 13.4498C9.22562 13.6279 10.1536 13.5365 10.997 13.1872C11.8403 12.8379 12.5612 12.2463 13.0683 11.4873C13.5754 10.7283 13.8461 9.83596 13.8461 8.92312C13.8448 7.69945 13.3581 6.52626 12.4929 5.66099C11.6276 4.79572 10.4544 4.30904 9.23074 4.30774ZM9.23074 12.3077C8.56133 12.3077 7.90695 12.1092 7.35035 11.7373C6.79375 11.3654 6.35994 10.8368 6.10377 10.2184C5.84759 9.5999 5.78057 8.91937 5.91116 8.26282C6.04176 7.60627 6.36411 7.00319 6.83746 6.52984C7.31081 6.05649 7.91389 5.73414 8.57044 5.60354C9.22699 5.47295 9.90752 5.53997 10.526 5.79615C11.1444 6.05232 11.673 6.48614 12.0449 7.04273C12.4169 7.59933 12.6154 8.25371 12.6154 8.92312C12.6144 9.82048 12.2575 10.6808 11.6229 11.3153C10.9884 11.9499 10.1281 12.3068 9.23074 12.3077Z"
                  fill="white"
                />
                <path
                  d="M15.3847 6.15386C15.7245 6.15386 16.0001 5.87835 16.0001 5.53848C16.0001 5.19861 15.7245 4.9231 15.3847 4.9231C15.0448 4.9231 14.7693 5.19861 14.7693 5.53848C14.7693 5.87835 15.0448 6.15386 15.3847 6.15386Z"
                  fill="white"
                />
              </svg>
            </span>
          </label>
        </div>
      </div>
      <div>
        {props.isError && props.isTouched && (
          <Message visible error content="Profile image is required" />
        )}
      </div>
    </>
  );
};
export default RenderProfileImgUpload;
