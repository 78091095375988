import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Message,
  Icon,
  Modal,
  Dropdown,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { getCentersClasses, getNotifications } from "../../store/actions";
import { NOTIFICATION_CREATE_ROUTE } from "../../routes";
import RenderButton from "../../components/Button";
import Cross from "../../assets/images/crossinfo.png";
import { usersTypeNoti } from "../custom.js";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import moment from "moment";

const months = [
  { key: 1, text: "January", value: 1 },
  { key: 2, text: "February", value: 2 },
  { key: 3, text: "March", value: 3 },
  { key: 4, text: "April", value: 4 },
  { key: 5, text: "May", value: 5 },
  { key: 6, text: "June", value: 6 },
  { key: 7, text: "July", value: 7 },
  { key: 8, text: "August", value: 8 },
  { key: 9, text: "September", value: 9 },
  { key: 10, text: "October", value: 10 },
  { key: 11, text: "November", value: 11 },
  { key: 12, text: "December", value: 12 },
];

// let TranslateMessage = "";
const initialState = {
  Modal: false,
  start_date: new Date(),
  end_date: new Date(),
  selected_date:
    localStorage.getItem("start_date") === null
      ? new Date()
      : new Date(localStorage.getItem("start_date")),
  notiid: null,
  searchResults: null,
  Noti_Data: [],
  TranslateMessage: "",
};
var count = 0;
const RenderListBox = (props) => {
  return (
    <Segment className="listBox">
      <Grid>
        <Grid.Row onClick={() => props.handleModalShow(props.id)}>
          <Grid.Column width={3}>
            <p style={{ marginTop: 8, fontWeight: "normal", color: "#1B1A1A" }}>
              {" "}
              {/* {props.created_at} */}
              {props.created_at.split(" ")[2]} {props.created_at.split(" ")[1]}{" "}
              {props.created_at.split(" ")[0]}{" "}
              {props.created_at.split(" ")[3].split(":")[0]}
              {":"}
              {props.created_at.split(" ")[3].split(":")[1]}
            </p>
          </Grid.Column>
          <Grid.Column width={13}>
            <p style={{ marginTop: 8, fontWeight: "600", color: "#1B1A1A" }}>
              {props.message}
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    let user_type = "";
    let centre_id = "";
    let date = new Date();
    // this.setState(
    //   {
    //     start_date:
    //       localStorage.getItem("start_date") !== null
    //         ? localStorage.getItem("start_date")
    //         : dateFormat(
    //             new Date(date.getFullYear(), date.getMonth(), 0),
    //             "yyyy-mm-dd"
    //           ),
    //     end_date:
    //       localStorage.getItem("end_date") !== null
    //         ? localStorage.getItem("end_date")
    //         : dateFormat(
    //             new Date(date.getFullYear(), date.getMonth() + 1, 0),
    //             "yyyy-mm-dd"
    //           ),
    //   },
    //   () => {
    //     this.props.getNotifications(this.state.start_date, this.state.end_date);
    //   }
    // );
    this.setState(
      {
        start_date: new Date().getMonth(),
        end_date: new Date().getMonth(),
      },
      () => {
        let currentMonth = new Date().getMonth()+1;
        let tempM = currentMonth > 10 ? currentMonth : "0" + currentMonth;

        this.setState({
          selected_date: currentMonth,
          start_date: currentMonth,
        });
        const tempDateString =
          moment().format("YYYY") + "-" + tempM + "-" + "01";
        const startDateOfMonth = moment(tempDateString)
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfMonth = moment(tempDateString)
          .clone()
          .endOf("month")
          .format("YYYY-MM-DD");
        localStorage.setItem("start_date", startDateOfMonth);
        localStorage.setItem("end_date", endDateOfMonth);
        if (localStorage.getItem("adminType") == "centre") {
          this.props.getNotifications(
            startDateOfMonth,
            endDateOfMonth,
            localStorage.getItem("center_Id")
          );
        } else {
          this.props.getNotifications(startDateOfMonth, endDateOfMonth);
        }
      }
    );
    let class_id = localStorage.getItem("centre_id");
    user_type =
      localStorage.getItem("user_type") !== null
        ? localStorage.getItem("user_type")
        : localStorage.setItem("user_type", this.state.user_type);
    centre_id =
      localStorage.getItem("centre_id") !== null
        ? localStorage.getItem("centre_id")
        : localStorage.setItem("centre_id", this.state.centreid);
    this.props.getCentersClasses();
    //this.props.getNotifications(this.state.start_date, this.state.end_date);
    //this.props.getUsersCount(class_id, centre_id)
    localStorage.removeItem("centre_id");
  }
  handleFilter = (e, res) => {
    this.setState(
      {
        [res.name]: res.value,
        start_date: localStorage.getItem("start_date"),
        end_date: localStorage.getItem("end_date"),
      },
      () => {
        let values = {};
        values.user_type = this.state.user_type;
        values.centre_id = this.state.centre_id;
        values.role = this.state.role;
        values.class_id = res.name === "centre_id" ? "" : this.state.class_id;
        if (values.class_id == "All Class") {
          values.class_id = "";
          localStorage.setItem("class_id", values.class_id);
        }

        this.props.getNotifications(
          this.state.start_date,
          this.state.end_date,
          values.centre_id,
          values.class_id,
          values.role
        );
        //this.props.getUsersCount(values.centre_id, values.class_id)
        localStorage.setItem("user_type", values.user_type);
        if (values.role) {
          localStorage.setItem("role", values.role);
        }
        if (values.centre_id) {
          localStorage.setItem("centre_id", values.centre_id);
        }
        if (values.class_id) {
          localStorage.setItem("class_id", values.class_id);
        }
      }
    );
  };

  onChangeDate = (date) => {
    this.setState(
      { start_date: date, selected_date: date },
      () =>
        this.props.getNotifications(
          dateFormat(date, "yyyy-mm-dd"),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
          null
        ),
      localStorage.setItem("start_date", dateFormat(date, "yyyy-mm-dd")),
      localStorage.setItem(
        "end_date",
        dateFormat(
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
          "yyyy-mm-dd"
        )
      )
    );
  };

  onChangeMonth = (e, data) => {
    var tempMonth = data.value > 10 ? data.value : "0" + data.value;

    this.setState({ selected_date: data.value, start_date: data.value });
    const tempDateString =
      moment().format("YYYY") + "-" + tempMonth + "-" + "01";
    const startDateOfMonth = moment(tempDateString)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDateOfMonth = moment(tempDateString)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");

    this.props.getNotifications(startDateOfMonth, endDateOfMonth, null);

    localStorage.setItem("start_date", startDateOfMonth);
    localStorage.setItem("end_date", endDateOfMonth);
    // this.props.getEvents(startDateOfMonth, endDateOfMonth, "admin_view", "all");
  };

  searchProduct = (e) => {
    if (!e.target.value) {
      this.setState({
        searchResults: null,
      });
      return;
    }
    let results = this.props.notifications.filter(
      (noti) =>
        noti.message
          .toLowerCase()
          .trim()
          .indexOf(e.target.value.toLowerCase().trim()) !== -1
    );
    this.setState({
      searchResults: results,
    });
  };
  handleModalShow = (id) => {
    count = 0;
    console.log("id", id);
    console.log("props notis", this.props.notifications);
    this.setState({
      notiid: id,
    });
    const filter_Data = [];
    let filter = this.props.notifications.find((data) => data.id === id);
    console.log("filter", filter);
    filter.receiver.map((data) => {
      let find = filter_Data.find((da) => da.id === data.centre_id);
      let classLength = this.props.centerclasses.find(
        (dat) => dat.id === data.centre_id
      );

      if (!find) {
        filter_Data.push({
          id: data.centre_id,
          Class_Data: data.class_id,
          role: data.role,
        });
      }
      if (find && find.Class_Data.length < classLength.class.length) {
        data.class_id.map((dat) => {
          find.Class_Data.push(dat);
        });
      }
    });

    this.setState({ Noti_Data: filter_Data, Modal: true });
  };
  handleModalClose = () => {
    this.setState({ Modal: false, TranslateMessage: "" });
  };
  handleRoute = () => {
    this.props.dispatch(push(NOTIFICATION_CREATE_ROUTE));
  };

  Translation = (data) => {
    let msg =
      "你是照亮夜晚黑暗的月光 你是乾 的土地的雨 你是使我的心活著的呼吸 哈哈，老公，你在奉承我方面變得更好了";
    if (this.state.TranslateMessage === "")
      this.setState({
        TranslateMessage: msg,
      });
    else this.setState({ TranslateMessage: "" });
  };

  render() {
    const {
      centerclasses = [],
      notifications = [],
      isloading,
      isnotiloading,
      haveData,
      notihaveData,
      haveError,
      notihaveError,
      notiDetail,
      userDetail,
    } = this.props;

    let users = [],
      centers = [],
      classes = [],
      goldObj = {},
      filternoti = [],
      notification_list =
        this.state.searchResults === null
          ? notifications
          : this.state.searchResults,
      activeCenterObj = {},
      activeCenterId = "",
      user_type = localStorage.getItem("user_type");

    if (localStorage.getItem("adminType") === "centre") {
      let d = centerclasses.find(
        (result) => result.id === localStorage.getItem("centre_Id")
      );
      if (d) {
        centers.push({
          key: d.id,
          text: d.name,
          value: d.id,
        });
      }

      // centers.push({
      //   key: userDetail.centre[0].id,
      //   text: userDetail.centre[0].name,
      //   value: userDetail.centre[0].id,
      // });
    } else {
      centerclasses.map((data) => {
        centers.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
    }
    usersTypeNoti.map((data) => {
      users.push({
        key: data.id,
        text: data.value,
        value: data.name,
      });
    });
    goldObj = centerclasses.find(
      //(result) => result.id === this.state.centre_id
      (result) => result.id === localStorage.getItem("centre_id")
    );
    goldObj !== undefined &&
      goldObj.class.map((data) => {
        classes.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
    classes.push({
      key: "",
      text: "All Class",
      value: "All Class",
    });
    filternoti = notifications.find((data) => data.id === this.state.notiid);

    // activeCenterId =
    //   localStorage.getItem("centre_id") !== null
    //     ? localStorage.getItem("centre_id")
    //     : localStorage.getItem("centre_id", this.state.centre_id);
    activeCenterId = localStorage.getItem("centre_id");
    activeCenterObj = centers.find((result) => result.value === activeCenterId);
    // filternoti !== undefined &&
    //   goldObj !== undefined &&
    //   console.log(filternoti, goldObj.class);
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        {filternoti !== undefined && (
          <Modal className="infodetail" open={this.state.Modal}>
            <img
              onClick={() => this.handleModalClose()}
              className="img"
              src={Cross}
            ></img>
            <p className="title">Notification Detail</p>
            <Modal.Content>
              <Segment
                style={{
                  width: "541px",
                  height: "112px",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {console.log("state noti data", this.state.Noti_Data)}
                {this.state.Noti_Data.map((data) => {
                  let total = 0;
                  return (
                    <Grid.Column width={2}>
                      <div style={{ display: "flex" }}>
                        <div
                          // className="centre"
                          // style={{
                          //   width: `${100 + data.Class_Data.length * 30}px`,
                          //   // float: "right",
                          // }}
                          style={{
                            width: " fit-content",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: 3,
                            paddingLeft: 5,
                            paddingRight: 5,

                            background:
                              "linear-gradient(0deg, #4075ff, #4075ff), #ffffff",
                            borderRadius: 20,
                            // float: "right",
                          }}
                        >
                          <p
                            // className="centretext"
                            style={{
                              width: "70px",
                              height: "16px",
                              fontFamily: " Open Sans",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "23px",
                              textAlign: "center",
                              color: "#ffffff",
                            }}
                          >
                            {data.id.length > 1
                              ? "All Centres"
                              : centerclasses.find(
                                  (centre) => centre.id === data.id[0]
                                ).name}{" "}
                          </p>
                          {data.Class_Data.map((cla, index) => {
                            let classlist = centerclasses.find(
                              (cen) => cen.id === data.id[0]
                            );
                            let va = "";

                            classlist.class.map((ele) => {
                              total = total + 5;
                              if (ele.id === cla) {
                                va = ele.name;
                                if (data.id.length > 1) {
                                  count++;
                                }

                                return (
                                  <span
                                    className="class"
                                    style={{
                                      backgroundColor:
                                        va === "P1"
                                          ? "#7CD227"
                                          : va === "P2"
                                          ? "#C697DF"
                                          : "#F3B329",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "white",
                                        fontWeight: "600px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {va}
                                    </p>
                                  </span>
                                );
                              }
                            });
                            if (va !== "") {
                              console.log("1#####");

                              const marginRight =
                                data.id.length > 1
                                  ? index === 0
                                    ? 30
                                    : count === 2
                                    ? 180
                                    : 180 + 150 * (count - 2)
                                  : 30;

                              return (
                                <>
                                  <span
                                    style={{
                                      backgroundColor:
                                        va === "P1"
                                          ? "#7CD227"
                                          : va === "P2"
                                          ? "#C697DF"
                                          : va === "P3"
                                          ? "#F3B329"
                                          : va === "P4"
                                          ? "#41CEC3"
                                          : va === "P5"
                                          ? "#F66460"
                                          : "#11AEE3",
                                      borderRadius: 50,
                                      width: 24,
                                      height: 24,
                                      textAlign: "center",
                                      paddingTop: 4,
                                      marginRight: 6,
                                      marginLeft: 10,
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "white",
                                        fontWeight: "600px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {va}
                                    </p>
                                  </span>
                                </>
                              );
                            }
                          })}
                        </div>
                        <div style={{}}>
                          <div
                            style={{
                              // position: "absolute",
                              // marginLeft: `${
                              //   -45 + data.Class_Data.length * 30
                              // }px`,
                              // left: "170px",
                              width: "200px",
                              marginTop: 5,
                              color: "#4075FF",
                              fontWeight: "bold",
                              fontSize: "14px",
                              marginLeft: 6,
                            }}
                          >
                            {console.log("data", data)}
                            {/* {data.role.map((element) => {
                            return <span>{element} &nbsp;</span>;
                          })} */}
                            {data.role.admin === 1 && <span>admin &nbsp;</span>}
                            {data.role.facilitator === 1 && (
                              <span>facilitator &nbsp;</span>
                            )}
                            {data.role.parent === 1 && (
                              <span>parent &nbsp;</span>
                            )}
                          </div>
                        </div>
                        <div
                          className="ui simple dropdown item"
                          style={{
                            // float: "right",
                            position: "absolute",
                            right: 20,
                          }}
                        >
                          <Icon
                            style={{
                              color: "gray",
                              // float: "right",
                              marginTop: "5px",
                              marginRight: 30,
                              position: "absolute",
                              left: 0,
                            }}
                            name="ellipsis vertical"
                          />
                          <div
                            className="menu"
                            style={{
                              // float: "left",
                              position: "absolute",
                              marginTop: 20,
                              top: 50,
                              left: -80,
                              zIndex: 99999999999,
                            }}
                          >
                            <div
                              className="item"
                              onClick={() =>
                                this.Translation(filternoti.message)
                              }
                            >
                              Translate
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                      <br />
                    </Grid.Column>
                  );
                })}

                {/* {filternoti.receiver.map((data) => (
                  <Grid.Column width={2}>
                    <div className="centre">
                      <p className="centretext">
                        {centerclasses.map((centre) => {
                          if (centre.id === data.centre_id) return centre.name;
                        })}
                      </p>

                      {data.class_id.map((cla) => {
                        let classlist = centerclasses.find(
                          (cen) => cen.id === data.centre_id
                        );
                        let va = "";
                        classlist.class.forEach((ele) => {
                          if (ele.id === cla) {
                            va = ele.name;
                            return (
                              <span
                                className="class"
                                style={{
                                  backgroundColor:
                                    va === "P1"
                                      ? "#7CD227"
                                      : va === "P2"
                                      ? "#C697DF"
                                      : "#F3B329",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "white",
                                    fontWeight: "600px",
                                    textAlign: "center",
                                  }}
                                >
                                  {va}
                                </p>
                              </span>
                            );
                          }
                        });
                        return (
                          <span
                            className="class"
                            style={{
                              backgroundColor:
                                va === "P1"
                                  ? "#7CD227"
                                  : va === "P2"
                                  ? "#C697DF"
                                  : va === "P3"
                                  ? "#F3B329"
                                  : va === "P4"
                                  ? "#41CEC3"
                                  : va === "P5"
                                  ? "#F66460"
                                  : "#11AEE3",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                color: "white",
                                fontWeight: "600px",
                                textAlign: "center",
                              }}
                            >
                              {va}
                            </p>
                          </span>
                        );
                      })}
                    </div>
                    <div style={{}}>
                      <div
                        style={{
                          position: "absolute",
                          left: "170px",
                          width: "200px",
                          marginTop: "5px",
                          color: "#4075FF",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {data.role.map((element) => {
                          return <span>{element} &nbsp;</span>;
                        })}
                      </div>
                    </div>
                    <br />
                    <br />
                  </Grid.Column>
                ))} */}
              </Segment>
              <Segment
                style={{
                  width: "541px",
                  height: "140px",
                  marginTop: "-14px",
                  padding: "22px",
                  overflow: "auto",
                }}
              >
                <p className="datetext">
                  {/* {filternoti.created_at} */}
                  {filternoti.created_at.split(" ")[2]}{" "}
                  {filternoti.created_at.split(" ")[1]}{" "}
                  {filternoti.created_at.split(" ")[0]}{" "}
                  {filternoti.created_at.split(" ")[3].split(":")[0]}
                  {":"}
                  {filternoti.created_at.split(" ")[3].split(":")[1]}
                </p>
                <br />
                <br />
                <p className="text"> {filternoti.message}</p>
              </Segment>
              <Segment
                style={{
                  width: "541px",
                  height: "135px",
                  marginTop: "-14px",
                  padding: "22px",
                  overflow: "auto",
                }}
              >
                <p className="text"> {this.state.TranslateMessage}</p>
              </Segment>
            </Modal.Content>
          </Modal>
        )}
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="noBottomMargin">
              <Grid.Column floated="left" width={5}>
                <Header as="h3" floated="left">
                  Notification
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                width={window.innerWidth < 850 ? 4 : 3}
              >
                <RenderButton
                  floated="right"
                  className="primaryBtn"
                  fluid={true}
                  btnText="Add new"
                  showIcon={true}
                  onClick={this.handleRoute}
                />
              </Grid.Column>
            </Grid>
            <Grid>
              {/* <Grid.Column
                //  floated="right"
                width={3}
              > */}
              <Grid.Column width={window.innerWidth < 850 ? 4 : 3}>
                <Dropdown
                  name="month"
                  onChange={this.onChangeMonth}
                  customStyle={{ float: "right", fontWeight: "bold" }}
                  placeholder="Choose Month"
                  options={months}
                  value={parseInt(this.state.selected_date)}
                  selection
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={window.innerWidth < 850 ? 4 : 3}>
                <Dropdown
                  name="role"
                  options={users}
                  onChange={this.handleFilter}
                  placeholder="Choose role"
                  defaultValue={localStorage.getItem("role")}
                  //value={localStorage.getItem("centre_id")}
                  selection
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={window.innerWidth < 850 ? 4 : 3}>
                <Dropdown
                  name="centre_id"
                  options={centers.sort((a, b) =>
                    a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                  )}
                  onChange={this.handleFilter}
                  placeholder="Choose Centre"
                  // defaultValue={localStorage.getItem("centre_id")}
                  //value={localStorage.getItem("centre_id")}
                  selection
                  fluid
                />
              </Grid.Column>
              <Grid.Column width={window.innerWidth < 850 ? 4 : 3}>
                <Dropdown
                  name="class_id"
                  options={classes.sort((a, b) =>
                    a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                  )}
                  onChange={this.handleFilter}
                  placeholder="Choose Class"
                  defaultValue={localStorage.getItem("centre_id")}
                  //value={localStorage.getItem("centre_id")}
                  selection
                  disabled={
                    localStorage.getItem("role") === "admin" ? true : false
                  }
                  fluid
                  disabled={
                    localStorage.getItem("role") === "admin" ? true : false
                  }
                />
              </Grid.Column>
              <Grid.Column floated="right" width={4}>
                <div className="ui icon input search" style={{ width: "100%" }}>
                  <i className="search icon"></i>
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={this.searchProduct}
                  />
                </div>
              </Grid.Column>
            </Grid>
            <Dimmer active={isnotiloading && !isloading} inverted>
              <Loader inverted />
            </Dimmer>
            {notihaveError ? (
              <Message
                header="Error loading notifcation"
                content="Error loading notifcation. Please try again."
                negative
              />
            ) : notification_list !== undefined &&
              notification_list.length === 0 &&
              !isnotiloading ? (
              //alert(notifications.length),
              <Grid>
                <Grid.Column width={16}>
                  <Message
                    header="No Notification"
                    content=" There is no notification  in this section."
                    warning
                  />
                </Grid.Column>
              </Grid>
            ) : (
              notihaveData &&
              notification_list !== undefined &&
              notification_list.map((data) => (
                <RenderListBox
                  {...data}
                  key={data.id}
                  handleModalShow={this.handleModalShow}
                  //onSelect={this.props.handleOnSelect}
                  // handleModalShow={this.props.handleModalShow}
                />
              ))
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  userDetail: state.user.user.data,
  centerclasses: state.centersClasses.centers_classes.data,
  ActiveToken: state.firebase.auth.stsTokenManager.accessToken,
  isloading: state.centersClasses.loading,
  haveError: state.centersClasses.err,
  haveData: state.centersClasses.haveData,
  notifications:
    state.notifications.notifications !== undefined
      ? state.notifications.notifications.data
      : [],
  notihaveData: state.notifications.haveData,
  isnotiloading: state.notifications.loading,
  notihaveError: state.notifications.err,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
  //getUser: (id, user_type) => dispatch(getUser(id, user_type)),
  // handleOnSelect: (id, user_type) => {
  //   localStorage.setItem("id", id);
  //   dispatch(push(`${USERMANAGEMENT_ROUTE}/edit/${user_type}/${id}`));
  // },
  getNotifications: (start_date, end_date, centre_id, class_id, role) =>
    dispatch(getNotifications(start_date, end_date, centre_id, class_id, role)),
  //getUsersCount: (class_id, centre_id) => dispatch(getUsersCount(class_id, centre_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
