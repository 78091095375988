import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const GET_NEWSFEED = "GET_NEWSFEED";
export const GET_NEWSFEED_SUCCESS = "GET_NEWSFEED_SUCCESS";
export const GET_NEWSFEED_FAILURE = "GET_NEWSFEED_FAILURE";
export const LOADMORE_NEWSFEED = "LOADMORE_NEWSFEED";
export const LOADMORE_NEWSFEED_SUCCESS = "LOADMORE_NEWSFEED_SUCCESS";
export const LOADMORE_NEWSFEED_FAILURE = "LOADMORE_NEWSFEED_FAILURE";

export const getNewsfeed = (page) => ({
  type: GET_NEWSFEED,
  page: page,
});

export const getNewsfeedSuccess = (res) => ({
  type: GET_NEWSFEED_SUCCESS,
  data: res,
});

export const getNewsfeedFailure = () => ({
  type: GET_NEWSFEED_FAILURE,
});

export const getMoreNewsFeed = () => ({
  type: LOADMORE_NEWSFEED,
});

export const getMoreNewsFeedSuccess = (data) => ({
  type: LOADMORE_NEWSFEED_SUCCESS,
  data,
});

export const getMoreNewsFeedFailure = () => ({
  type: LOADMORE_NEWSFEED_FAILURE,
});

export const getAllNewsfeed = (page, pagesize, isNext, nextUrl, refresh) => {
  if (isNext) {
    return (dispatch) => {
      dispatch(getMoreNewsFeed());
      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;
          api
            .get(`${nextUrl}`, null, token)
            .then((res) => {
              dispatch(getMoreNewsFeedSuccess(res));
              return res;
            })
            // fetch(api.apiRoot + nextUrl, {
            //   method: "GET",
            //   headers: {
            //     Authorization: `Bearer ${token}`,
            //     "Content-Type": "application/json",
            //   },
            // })
            //   .then((res) => res.json())
            //   .then((res) => {
            //     dispatch(getMoreNewsFeedSuccess(res));
            //     return res;
            //   })
            .catch((err) => dispatch(getMoreNewsFeedFailure(err)));
        } catch (err) {
          console.log(err);
        }
      });
    };
  } else {
    return (dispatch) => {
      dispatch(getNewsfeed(page));
      return firebase.reloadAuth().then((res) => {
        // console.log(res);
        try {
          const token = res.ya === null ? res.za : res.ya;
          api
            .get(`/newsfeeds/?page=${page}&pagesize=${pagesize}`, null, token)
            .then((res) => {
              dispatch(getNewsfeedSuccess(res));
              return res;
            })
            .catch((err) => dispatch(getNewsfeedFailure()));
        } catch (err) {
          console.log(err);
        }
      });
    };
  }
};
