import React, { Component } from "react";
import {
  Grid,
  Image,
  Label,
  // Button,
} from "semantic-ui-react";
// import { push } from 'react-router-redux';
import ic_Payment_error from "../../assets/images/ic_Payment_error.svg";
import RenderButton from "../../components/Button";

class PayFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref_type: "",
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const ref_type = query.get("ref_type");
    this.setState({
      ref_type: ref_type,
    });
  }
  capitalizeFirstLetter(string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleTry() {}
  render() {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          zIndex: 1000,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          <Image src={ic_Payment_error} />
        </div>
        <div align="center" style={{ marginBottom: "15px" }}>
          <p style={{ fontSize: 24, fontWeight: 700, marginBottom: 17 }}>
            {this.capitalizeFirstLetter(this.state.ref_type)} failed
          </p>
          <p style={{ width: 164, fontWeight: 600 }}>
            Your transaction cannot be completed
          </p>
        </div>
        <div style={{ width: 311, marginTop: window.innerHeight / 6 }}>
          <RenderButton
            floated="right"
            className="primaryBtn"
            fluid={true}
            btnText="Try again"
            showIcon={false}
            onClick={this.handleOK}
          />
        </div>
      </div>
    );
  }
}

export default PayFailed;
