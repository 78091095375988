import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Grid, Image, Message } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import ic_calendar from "../assets/images/ic_calendar1.svg";

const RenderMultipleDatePicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div
            className={`formMargin ${props.rc_order_style && "rc_form"}`}
            style={{ border: "1px solid #c0c4d6", borderRadius: "5px" }}
          >
            <DatePicker
              multiple
              onChange={props.onChange}
              value={props.value}
              format={
                props.dateformat === undefined ? "DD/MM/YYYY" : props.dateformat
              }
              plugins={[<DatePanel />]}
            />
            {props.fromtoProps != undefined && props.fromtoProps != "" && (
              <div
                className={`input-ic-commom left ${
                  props.rc_order_style2 && "a_l"
                }`}
              >
                <h5>{props.fromtoProps}</h5>
              </div>
            )}
            <div className="input-ic-commom right">
              <Image
                src={ic_calendar}
                style={{
                  marginTop: props.rc_order_style
                    ? 1
                    : props.rc_order_style2
                    ? 1
                    : null,
                  width:
                    (props.rc_order_style && 13) ||
                    (props.rc_order_style2 && 13),
                  right: props.rc_order_style && -5,
                }}
              ></Image>
            </div>
          </div>
          {props.isError && props.isTouched && (
            <Message visible error content={props.isError} />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RenderMultipleDatePicker;
