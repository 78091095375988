export const DASHBOARD_ROUTE = "/dashboard";
export const ACTIVITY_ROUTE = "/activity";
export const USERMANAGEMENT_ROUTE = "/user_management";
export const USER_CREATE_ROUTE = "/user_management/add";
export const ATTENDANCE_ROUTE = "/attendance";
export const ACTIVITY_CREATE_ROUTE = "/activity/add";
export const LOGIN_ROUTE = "/";
export const SIGNUP_ROUTE = "/signup";
export const PASSWORD_RESET_ROUTE = "/password_reset";
export const MERCHANDISE_ROUTE = "/merchandise";
export const MERCHANDISE_CREATE_ROUTE = "/merchandise/add";
export const NOTIFICATION_ROUTE = "/notification";
export const NOTIFICATION_CREATE_ROUTE = "/notification/add";
export const FORM_ROUTE = "/forms";
export const FORM_CREATE_ROUTE = "/forms/add";
export const FORM_VIEW_ROUTE = "/forms/view";
export const FORM_EDIT_ROUTE = "/forms/editform/:id";
export const FORM_DUPLICATE_ROUTE = "/forms/duplicate";
export const DONATION_ROUTE = "/donation";
export const ADDDONATION_ROUTE = "/donation/add";
export const COMMS_ROUTE = "/comms";
export const EDITDONATION_ROUTE = "/donation/edit";
export const CALENDAR_ROUTE = "/calendar";
export const SCHEDULE_CREATE_ROUTE = "/calendar/add/schedule";
export const MERCHANDISE_DETAIL_ROUTE = "/merchandise/detail";
export const MERCHANDISE_EDIT_ROUTE = "/merchandise/edit";
export const MERCHANDISE_TYPE_CREATE_ROUTE = "/merchandise/type/add";
export const SCHEDULE_TYPE_CREATE_ROUTE = "/calendar/add/scheduletype";
export const SCHEDULE_ITEM_CREATE_ROUTE = "/calendar/add/scheduleitem";
export const ERROR_ROUTE = "/error";
export const PAYMENT_FAILED_ROUTE = "/error/failed";
export const PAYMENT_SUCCESS_ROUTE = "/error/success";
export const GENERAL_ERROR_ROUTE = "/errorpage";
export const APP_MAINTENANCE_ROUTE = "/app-maintenance";
export const NEWS_FEED_ROUTE = "/newsfeed";
export const CHANGE_PASSWORD = "/changepassword";