import React, { Component } from "react";
import { Grid, Header, Button, Card, Image } from "semantic-ui-react";
import { Formik, Field, Form, FieldArray } from "formik";
import RenderInput from "../../components/TextInput";
import RenderTextArea from "../../components/RenderTextArea";
import RenderImage from "../../components/Image";
import { isRequired } from "../../validators";
import RenderCounterInput from "../../components/CounterInput";
import Xclose from "../../assets/images/Xclose.svg";
import close from "../../assets/images/close.png";
import RenderDropdown from "../../components/DropDown";
import defaultimage from "../../assets/images/defaultimage.png";

class MerchandiseForm extends Component {
  state = {
    previewUrl: defaultimage,
    previewUrlArr: [],
    imgs: [],
  };

  componentDidMount() {
    if (this.props.isEdit) {
      this.props.initialValues.img.forEach((data) => {
        this.state.imgs.push(data);
      });
      this.setState({ loading: true });
    }
  }

  removeImg = (idx) => {
    let imgs = [...this.state.imgs];
    this.setState({
      imgs: this.state.imgs.filter((img, ix) => ix !== idx),
      previewUrlArr: this.state.previewUrlArr.filter((url, ix) => ix !== idx),
    });
    this.props.getOnselectVal(imgs);
  };

  render() {
    const { isEdit, initialValues, types, hqAdmin } = this.props;
    const { previewUrlArr } = this.state;

    return (
      <Grid className="subContainer">
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ width: "50%", margin: "auto" }}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h2" floated="left">
                    {this.props.formTitle}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <div style={{ marginTop: 36 }}>
                <Formik
                  initialValues={
                    isEdit
                      ? initialValues
                      : {
                          name: "",
                          description: "",
                          img: [""],
                          price: "",
                          size: "",
                          amount: "",
                          created_by: "",
                          merchandise_type_id: "",
                        }
                  }
                >
                  {(formikProps) => {
                    return (
                      <Form>
                        <label className="formLabel">Merchandise Types</label>
                        <div className="formMargin">
                          <Field
                            name="merchandise_type_id"
                            component={RenderDropdown}
                            onChange={formikProps.handleChange(
                              "merchandise_type_id"
                            )}
                            validate={isRequired}
                            placeholder="Choose Merchandise Type"
                            isTouched={formikProps.touched.merchandise_type_id}
                            isError={formikProps.errors.merchandise_type_id}
                            options={types}
                            value={formikProps.values.merchandise_type_id}
                          />
                        </div>

                        <label className="formLabel">Name</label>
                        <div className="formMargin">
                          <Field
                            name="name"
                            component={RenderInput}
                            onChange={formikProps.handleChange("name")}
                            validate={isRequired}
                            placeholder="Name"
                            isTouched={formikProps.touched.name}
                            isError={formikProps.errors.name}
                            value={formikProps.values.name}
                          />
                        </div>

                        <label className="formLabel">Description</label>
                        <div className="formMargin">
                          <Field
                            name="description"
                            component={RenderTextArea}
                            onChange={formikProps.handleChange("description")}
                            validate={isRequired}
                            placeholder="Description"
                            isTouched={formikProps.touched.description}
                            isError={formikProps.errors.description}
                            value={formikProps.values.description}
                          />
                        </div>
                        <label className="formLabel">Image</label>
                        <div className="formMargin">
                          <Card.Group itemsPerRow={3}>
                            {this.state.imgs.length === 0
                              ? null
                              : this.state.imgs.map((data, idx) => (
                                  <Card key={idx}>
                                    <Field
                                      component={RenderImage}
                                      accept="image/*"
                                      type="file"
                                      previewUrl={
                                        isEdit
                                          ? data.type === undefined
                                            ? data
                                            : URL.createObjectURL(data)
                                          : URL.createObjectURL(data)
                                      }
                                    />
                                    <Image
                                      src={close}
                                      verticalAlign="middle"
                                      onClick={() => {
                                        // console.log("on click...");
                                        // console.log(this.state.imgs);
                                        var array = [...this.state.imgs]; // make a separate copy of the array
                                        var index = array.indexOf(data);
                                        // console.log("index", index);
                                        // console.log(
                                        //   "arr length",
                                        //   this.state.imgs.length
                                        // );
                                        if (this.state.imgs.length === 1)
                                          return alert(
                                            "There must be at least one image."
                                          );
                                        if (index !== -1) {
                                          array.splice(index, 1);
                                          this.setState({ imgs: array });
                                        }
                                      }}
                                    />
                                  </Card>
                                ))}
                            {this.state.imgs.length > 2 ? null : (
                              <Card>
                                <Field
                                  name="img"
                                  component={RenderImage}
                                  accept="image/*"
                                  onChange={(e) => {
                                    let imgs = [...this.state.imgs];
                                    imgs.push(e.currentTarget.files[0]);
                                    this.setState({
                                      imgs,
                                    });
                                  }}
                                  previewUrl={this.state.previewUrl}
                                />
                              </Card>
                            )}
                          </Card.Group>
                          {/* <FieldArray name="img">
                          {({ remove, push }) => (
                            <Card.Group itemsPerRow={3}>
                                {formikProps.values.img.length > 0 &&
                                  formikProps.values.img.map((image, idx) => {
                                    return (
                                      <Card key={idx}>
                                        <Field
                                          name={`img.${idx}`}
                                          component={RenderImage}
                                          accept="image/*"
                                          onChange={(e) => {
                                            let imgs = [...this.state.imgs];
                                            let previewUrlArr = [
                                              ...this.state.previewUrlArr,
                                            ];
                                            if (
                                              formikProps.values.img.length >
                                              imgs.length
                                            ) {
                                              imgs.push(
                                                e.currentTarget.files[0]
                                              );
                                            }
                                            imgs.map(() => {
                                              imgs[idx] =
                                                e.currentTarget.files[0];
                                              previewUrlArr[idx] =
                                                URL.createObjectURL(
                                                  e.currentTarget.files[0]
                                                );
                                              this.props.getOnselectVal(imgs);
                                            });
                                            this.setState({
                                              imgs,
                                              previewUrlArr,
                                            });
                                          }}
                                          isEdit={isEdit}
                                          value={image}
                                          previewUrl={previewUrlArr[idx]}
                                        />
                                        <Image
                                          src={close}
                                          verticalAlign="middle"
                                          onClick={() => {
                                            remove(idx);
                                            this.removeImg(idx);
                                          }}
                                        />
                                      </Card>
                                    );
                                  })}
                                <Image
                                  src={Xclose}
                                  verticalAlign="middle"
                                  onClick={() => push("")}
                                />
                              </Card.Group>
                            )}
                          </FieldArray> */}
                        </div>
                        <br />
                        <br />
                        <Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Size</label>
                              <div className="formMargin">
                                <Field
                                  name="size"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange("size")}
                                  // validate={isRequired}
                                  placeholder="38"
                                  isTouched={formikProps.touched.size}
                                  isError={formikProps.errors.size}
                                  value={formikProps.values.size.replace(
                                    /[&\/\\#,+()$@~%.!~^`'":*?<>{}]/g,
                                    ""
                                  )}
                                />
                              </div>
                            </Grid.Column>
                            {/* <Grid.Column>
                              <label className="formLabel">Created By</label>
                              <div className="formMargin">
                                <Field
                                  name="created_by"
                                  component={RenderDropdown}
                                  onChange={formikProps.handleChange(
                                    "created_by"
                                  )}
                                  validate={isRequired}
                                  placeholder="Choose HQ Admin"
                                  isTouched={formikProps.touched.created_by}
                                  isError={formikProps.errors.created_by}
                                  options={hqAdmin}
                                  value={formikProps.values.created_by}
                                />
                              </div>
                            </Grid.Column> */}
                          </Grid.Row>
                        </Grid>
                        <Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Price</label>
                              <div className="formMargin">
                                <Field
                                  name="price"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange("price")}
                                  validate={isRequired}
                                  placeholder="$ 10.00"
                                  isTouched={formikProps.touched.price}
                                  isError={formikProps.errors.price}
                                  value={formikProps.values.price}
                                  type="number"
                                  min="0"
                                  max="1000000"
                                  maxLength="8"
                                  step="0.01"
                                />
                              </div>
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">
                                Stock Available
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="amount"
                                  component={RenderCounterInput}
                                  type="number"
                                  onChange={this.props.getCount}
                                  validate={isRequired}
                                  isEdit={isEdit}
                                  isTouched={formikProps.touched.amount}
                                  isError={
                                    this.props.count !== ""
                                      ? undefined
                                      : formikProps.errors.amount
                                  }
                                  value={
                                    isEdit
                                      ? formikProps.values.amount
                                      : (formikProps.values.amount =
                                          this.props.count)
                                  }
                                  {...formikProps}
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>

                        <br />
                        <div className="fullWidthCenterAlign">
                          <Button
                            content="Submit"
                            primary
                            size="large"
                            type="submit"
                            className="submitBtn"
                            onClick={() =>
                              this.props.handleSubmit(
                                formikProps.values,
                                this.state.imgs
                              )
                            }
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default MerchandiseForm;
