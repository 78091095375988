import React, { Component } from "react";
import { Grid, Header, Modal, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import thinking from "../../assets/images/card_thinking.png";
import Facilitators from "../../assets/images/card_female_teacher.png";
import Parents from "../../assets/images/card_family.png";
import Students from "../../assets/images/card_education.png";
import Total from "../../assets/images/card_team.png";
import { USERMANAGEMENT_ROUTE, USER_CREATE_ROUTE } from "../../routes";
import Card from "../../components/Card";
import Cross from "../../assets/images/cross.png";
import {
  getCentersClasses,
  getUser,
  getUsers,
  getMoreUsers,
  getUsersCount,
} from "../../store/actions";
import RenderLinker from "../../components/Linker";
import { usersType } from "../custom.js";
import RenderAdminList from "./admin";
import RenderFacilitatorList from "./Facilitator";
import RenderParentList from "./Parent";
import RenderStudentList from "./Student";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import { Dropdown, Message, Dimmer, Loader } from "semantic-ui-react";
import { toInteger } from "lodash";
import Xclose from "../../assets/images/Xclose.svg";
import admin from "./admin";
const initialState = {
  Modal: false,
  filtersUsers: [],
  user_type: "admin",
  usercount: [],
  calss_id: "r8RlrYPyeUTD6Ss4v6pY",
  centerList: [],
  centername: "",
  class: [],
  is_active: 0,
  isloading: false,
  haveData: false,
  isitemUpdateloading: false,
  pickedUsrId: "",
  centreid: "8XbfLsbWOz09wfRh2Lxb",
  centre_id: localStorage.getItem("centre_id") ?? "",
  modalImg: "",
  modalName: "",
  modalCentreType: "",
  modalEmail: "",
  typeInfo: {},
  class_id: "",
};
let values = {};
class UserManagementList extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    // let user_type = '';
    // let centre_id = '';
    localStorage.setItem("isAdmin", true);
    localStorage.setItem("user_type", "admin");
    localStorage.setItem("activePage", 1);
    let class_id = localStorage.getItem("class_id");
    let user_type =
      localStorage.getItem("user_type") !== undefined
        ? localStorage.getItem("user_type")
        : localStorage.setItem("user_type", "admin");
    let centre_id =
      localStorage.getItem("centre_id") !== undefined
        ? localStorage.getItem("centre_id")
        : localStorage.setItem("centre_id", "8XbfLsbWOz09wfRh2Lxb");
    values.user_type = user_type;
    values.centre_id = centre_id;
    values.class_id = class_id;
    this.props.getCentersClasses();
    if (localStorage.getItem("adminType") == "centre") {
      this.props.getUsers(user_type, localStorage.getItem("centre_Id"), "");
      this.props.getUsersCount("admin", localStorage.getItem("centre_Id"), "");
    } else {
      this.props.getUsers(user_type, "all", "");
      this.props.getUsersCount("admin", "all", class_id);
    }
  }

  // handleModalShow = (id) => {
  //   this.setState({ Modal: true });
  //   const user_type = localStorage.getItem("user_type");
  //   this.props.getUser(id, user_type);
  // };
  handleModalShow = (modalUser) => {
    this.setState({
      Modal: true,
      modalImg: modalUser.img,
      modalName: modalUser.name,
      modalCentreType: modalUser.centreType,
      modalEmail: modalUser.email,
    });
  };
  handleModalClose = () => {
    this.setState({ Modal: false });
  };

  handleFilter = (e, res) => {
    // console.log(new Date().toLocaleTimeString());
    // console.log("handleFilter...");

    if (res.name === "user_type") {
      localStorage.setItem("activePage", 1);
      this.setState({
        class_id: "",
      });
    }
    if (res.name === "centre_id") {
      localStorage.setItem("activePage", 1);
      this.setState({
        class_id: "",
      });
    }
    this.setState(
      {
        [res.name]: res.value,
      },
      () => {
        if (localStorage.getItem("adminType") == "centre") {
          values.user_type = this.state.user_type;
          values.centre_id = localStorage.getItem("centre_Id");
          values.class_id = this.state.class_id;
        } else {
          values.user_type = this.state.user_type;
          values.centre_id = this.state.centre_id;
          values.class_id = this.state.class_id;
        }

        if (res.value === "admin") {
          console.log("admin...");
          // if (this.props.userDetail.admin_type === "hq") {
          //   console.log("hq...");
          // }
          if (localStorage.getItem("adminType") === "hq") {
          } else {
            // values.centre_id = this.props.userDetail.centre[0].id;
            values.centre_id = localStorage.getItem("centre_Id");
          }
        }

        values.class_id = values.class_id === "all" ? "" : values.class_id;

        localStorage.setItem("class_id_user", values.class_id);
        this.setState({ typeInfo: values });
        if (values.user_type == "admin") {
          if (localStorage.getItem("adminType") == "centre") {
            this.props.getUsersCount(
              values.user_type,
              localStorage.getItem("centre_Id"),
              ""
            );
            this.props.getUsers(
              values.user_type,
              localStorage.getItem("centre_Id"),
              ""
            );
          } else {
            this.props.getUsersCount(values.user_type, values.centre_id, "");
            this.props.getUsers(values.user_type, values.centre_id, "");
          }
        }
        if (values.user_type == "parent") {
          if (localStorage.getItem("adminType") == "centre") {
            this.props.getUsersCount(
              values.user_type,
              // localStorage.getItem("centre_Id"),
              "all",
              ""
            );
            this.props.getUsers(
              values.user_type,
              // localStorage.getItem("centre_Id"),
              "all",
              ""
            );
          } else {
            this.props.getUsersCount(values.user_type, "all", "");
            this.props.getUsers(values.user_type, "all", "");
          }
        } else if (values.user_type == "facilitator") {
          if (localStorage.getItem("adminType") == "centre") {
            this.props.getUsersCount(
              values.user_type,
              localStorage.getItem("centre_Id"),
              values.class_id
            );
            this.props.getUsers(
              values.user_type,
              localStorage.getItem("centre_Id"),
              values.class_id
            );
          } else {
            this.props.getUsersCount(
              values.user_type,
              values.centre_id,
              values.class_id
            );
            this.props.getUsers(
              values.user_type,
              values.centre_id,
              values.class_id
            );
          }
        } else {
          if (localStorage.getItem("adminType") == "centre") {
            this.props.getUsersCount(
              values.user_type,
              localStorage.getItem("centre_Id"),
              values.class_id
            );
            this.props.getUsers(
              values.user_type,
              localStorage.getItem("centre_Id"),
              values.class_id
            );
          } else {
            this.props.getUsersCount(
              values.user_type,
              values.centre_id,
              values.class_id
            );
            this.props.getUsers(
              values.user_type,
              values.centre_id,
              values.class_id
            );
          }
        }

        localStorage.setItem("user_type", values.user_type);
        localStorage.setItem("centre_id", values.centre_id);
        localStorage.setItem("class_id", values.class_id);
      }
    );
    let cid = this.state.centre_id;
    //temshow
    firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.get("/user/count/", { cid }, token).then((res) => {
          if (res.message === "Success") {
            this.setState({
              usercount: res.data,
            });
          } else {
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };
  componentWillUnmount() {
    // localStorage.setItem("user_type", "admin");
    localStorage.removeItem("activePage");
    localStorage.removeItem("nextPage");
  }
  handleDisable = (id, active) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("is_active", active === 1 ? 0 : 1);
    let user_type = localStorage.getItem("user_type");
    let centre_id = localStorage.getItem("centre_id");
    this.setState({
      isloading: true,
    });

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post(`/user/${user_type}/`, formdata, token).then((res) => {
          if (res.message === "Success") {
            if (localStorage.getItem("user_type") === "parent") {
              this.props.getMoreUsers(
                localStorage.getItem("activePage") - 1,
                localStorage.getItem("user_type"),
                "",
                ""
              );
            } else if (localStorage.getItem("user_type") === "admin") {
              this.props.getMoreUsers(
                localStorage.getItem("activePage") - 1,
                localStorage.getItem("user_type"),
                localStorage.getItem("centre_id"),
                ""
              );
            } else if (localStorage.getItem("user_type") === "facilitator") {
              this.props.getMoreUsers(
                localStorage.getItem("activePage") - 1,
                localStorage.getItem("user_type"),
                localStorage.getItem("centre_id"),
                localStorage.getItem("class_id")
              );
            } else {
              this.props.getMoreUsers(
                localStorage.getItem("activePage") - 1,
                localStorage.getItem("user_type"),
                centre_id,
                localStorage.getItem("class_id_user")
              );
            }

            // else {
            //   console.log("second");
            //   this.props.getUsers(user_type, centre_id);
            // }

            this.props.getUser(id, user_type);
            this.setState({
              isloading: false,
            });
            this.props.dispatch(push(USERMANAGEMENT_ROUTE));
          } else {
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  render() {
    const {
      centerclasses = [],
      userscount = [],
      isloading,
      haveData,
      haveError,
      handleOnSelect,
      userDetail,
      haveusersData,
      usersList,
      haveusersError,
    } = this.props;

    const { modalImg, modalName, modalCentreType, modalEmail } = this.state;
    let centers = [],
      total =
        toInteger(userscount.total_admin) +
        toInteger(userscount.total_parent) +
        toInteger(userscount.total_student) +
        toInteger(userscount.total_facilitator),
      users = [],
      classes = [],
      goldObj = {},
      activeCenterObj = {},
      activeCenterId = "",
      centre_name = "",
      user_type = localStorage.getItem("user_type");

    if (localStorage.getItem("adminType") == "hq") {
      centers.push({ key: "", value: "all", text: "All Centres" });
    }

    if (!localStorage.getItem("isAdmin")) {
      if (userDetail !== undefined) {
        if (userDetail.admin_type === "centre") {
          localStorage.setItem("isAdmin", "true");
          localStorage.setItem("adminType", "centre");
          localStorage.setItem("admin_centre_name", userDetail.centre[0].name);
          localStorage.setItem(
            "admin_centre_id",
            this.props.userDetail.centre[0].id
          );
        } else if (this.props.userDetail.admin_type === "hq") {
          localStorage.setItem("isAdmin", "true");
          localStorage.setItem("adminType", "hq");
        } else {
          return;
        }
      }
    }

    let isAdmin = localStorage.getItem("isAdmin"),
      adminType = localStorage.getItem("adminType");

    if (isAdmin === "true") {
      usersType.map((data) => {
        users.push({
          key: data.id,
          text: data.value,
          value: data.name,
        });
      });

      if (adminType === "centre") {
        // centers.push({
        //   key: this.props.userDetail.centre[0].id,
        //   text: this.props.userDetail.centre[0].name,
        //   value: this.props.userDetail.centre[0].id,
        // });
        centers.push({
          key: localStorage.getItem("admin_centre_id"),
          text: localStorage.getItem("admin_centre_name"),
          value: localStorage.getItem("admin_centre_id"),
        });
      } else if (adminType === "hq") {
        if (localStorage.getItem("user_type") === "admin") centers.push();
        centerclasses.map((data) => {
          centers.push({
            key: data.id,
            text: data.name,
            value: data.id,
          });
        });
      }
    }

    // centerclasses.map((data) => {
    //   centers.push({
    //     key: data.id,
    //     text: data.name,
    //     value: data.id,
    //   });
    // }, this.state.user_type === "admin" && centers.push({ key: "", value: "all", text: "All Centres" }));
    centers.map((data) => {
      if (data.key === localStorage.getItem("centre_id")) {
        centre_name = data.text;
      }
    });

    // usersType.map((data) => {
    //   users.push({
    //     key: data.id,
    //     text: data.value,
    //     value: data.name,
    //   });
    // });

    if (localStorage.getItem("adminType") == "centre") {
      goldObj = centerclasses.find(
        (result) => result.id === localStorage.getItem("centre_Id")
        // (result) => result.id === localStorage.getItem("centre_id")
      );
    } else {
      goldObj = centerclasses.find(
        (result) => result.id === this.state.centre_id
        // (result) => result.id === localStorage.getItem("centre_id")
      );
    }

    classes.push({ key: "", text: "All Classes", value: "All Classes" });

    goldObj !== undefined &&
      goldObj.class.map((data) => {
        classes.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });

    activeCenterId =
      localStorage.getItem("centre_id") !== null
        ? localStorage.getItem("centre_id")
        : localStorage.getItem("centre_id", this.state.centre_id);
    activeCenterObj = centers.find((result) => result.value === activeCenterId);
    // console.log(this.props.ActiveToken);
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>

        {/* {userDetail !== undefined && userDetail.length !== 0 ? ( */}
        <Modal size={"mini"} dimmer={""} open={this.state.Modal}>
          <Modal.Header align="right" style={{ border: 0 }}>
            <Image
              src={Xclose}
              style={{ cursor: "pointer" }}
              onClick={() => this.handleModalClose()}
            />
          </Modal.Header>

          <Modal.Content align="center">
            <Image
              src={modalImg}
              verticalAlign="middle"
              className="user-setting-icon"
            />
            <p className="user-role">Admin</p>
            <Header as="h2" style={{ margin: 0 }}>
              {modalName}
            </Header>
            <p className="Bedok">{modalCentreType}</p>
            <p
              style={{
                width: "180px",
                fontWeight: "normal",
                color: "#1B1A1A",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {modalEmail}
            </p>
          </Modal.Content>
        </Modal>
        {/* ) : null} */}
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="noBottomMargin">
              <Grid.Column floated="left" width={5}>
                <Header as="h3" floated="left">
                  User Management
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                width={3}
                onClick={() => localStorage.setItem("user_type", "centre")}
              >
                <RenderLinker
                  floated="right"
                  className="primaryBtn Linker"
                  fluid={true}
                  btnText="Add new"
                  showIcon={true}
                  route={USER_CREATE_ROUTE}
                  obj={centerclasses}
                  haveData={haveData}
                />
              </Grid.Column>
            </Grid>
            <div
              className="adjustWidth "
              style={{
                justifyContent:
                  window.innerWidth > 1366 ? "space-between" : "flex-start",
              }}
            >
              <div>
                <Card
                  title="Admins"
                  image={thinking}
                  number={
                    localStorage.getItem("adminType") !== "hq"
                      ? userscount.total_centre_admin
                      : userscount.total_admin
                  }
                  text="people"
                />
              </div>
              <div width={3}>
                <Card
                  title="Facilitators"
                  image={Facilitators}
                  number={userscount.total_facilitator}
                  text="people"
                />
              </div>
              <div width={3}>
                <Card
                  title="Parents"
                  image={Parents}
                  number={userscount.total_parent}
                  text="people"
                />
              </div>
              <div width={3}>
                <Card
                  title="Students"
                  image={Students}
                  number={userscount.total_student}
                  text="people"
                />
              </div>
              {/* <Grid.Column width={3}>
                <Card
                  title="Total"
                  image={Total}
                  number={total}
                  text="people"
                /> */}

              <div width={3}>
                <Card
                  title="Total"
                  image={Total}
                  number={
                    localStorage.getItem("adminType") != "hq"
                      ? userscount.total_centre_admin +
                        userscount.total_facilitator +
                        userscount.total_parent +
                        userscount.total_student
                      : total
                  }
                  text="people"
                  total={true}
                />
              </div>
            </div>
            <Grid>
              <Grid.Column width={3}>
                <div>
                  <Dropdown
                    name="user_type"
                    onChange={this.handleFilter}
                    placeholder="Choose User"
                    selectOnBlur={false}
                    options={users}
                    value={user_type}
                    selection
                    fluid
                  />
                </div>
              </Grid.Column>
              {user_type !== "parent" && (
                <>
                  {localStorage.getItem("adminType") != "centre" && (
                    <Grid.Column width={3}>
                      <Dropdown
                        name="centre_id"
                        onChange={this.handleFilter}
                        placeholder="Choose Center"
                        options={centers.sort((a, b) =>
                          a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                        )}
                        // value={localStorage.getItem("centre_id")}
                        value={
                          this.state.centre_id
                            ? this.state.centre_id
                            : "All Center"
                        }
                        selection
                        fluid
                      />
                    </Grid.Column>
                  )}

                  <Grid.Column width={3}>
                    {user_type !== "admin" && (
                      <Dropdown
                        name="class_id"
                        onChange={this.handleFilter}
                        placeholder="Choose Class"
                        options={classes.sort((a, b) =>
                          a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                        )}
                        value={
                          this.state.class_id == ""
                            ? "All Classes"
                            : this.state.class_id
                        }
                        selection
                        fluid
                      />
                    )}
                  </Grid.Column>
                </>
              )}
            </Grid>
            {user_type === "admin" ? (
              <RenderAdminList
                filtersUsers={usersList}
                handleOnSelect={handleOnSelect}
                handleDisable={this.handleDisable}
                //centername={this.state.centername}
                centre_list={centers}
                handleModalShow={this.handleModalShow}
                selectedCenter={
                  activeCenterObj !== undefined && activeCenterObj.text
                }
                isloading={this.props.usersLoading}
                firstloading={isloading}
                haveData={haveusersData}
                haveError={haveusersError}
                values={values}
              />
            ) : user_type === "facilitator" ? (
              <RenderFacilitatorList
                filtersUsers={usersList}
                handleOnSelect={handleOnSelect}
                handleDisable={this.handleDisable}
                centre_list={centers}
                handleModalShow={this.handleModalShow}
                selectedCenter={
                  activeCenterObj !== undefined && activeCenterObj.text
                }
                isloading={this.props.usersLoading}
                firstloading={isloading}
                haveData={haveusersData}
                haveError={haveusersError}
                values={values}
              />
            ) : user_type === "parent" ? (
              <RenderParentList
                filtersUsers={usersList}
                handleOnSelect={handleOnSelect}
                handleDisable={this.handleDisable}
                centre_list={centers}
                //centername={this.state.centername}
                handleModalShow={this.handleModalShow}
                selectedCenter={
                  activeCenterObj !== undefined && activeCenterObj.text
                }
                isloading={this.props.usersLoading}
                firstloading={isloading}
                haveData={haveusersData}
                values={values}
              />
            ) : (
              <RenderStudentList
                filtersUsers={usersList}
                handleOnSelect={handleOnSelect}
                handleDisable={this.handleDisable}
                centre_list={centers}
                handleModalShow={this.handleModalShow}
                selectedCenter={
                  activeCenterObj !== undefined && activeCenterObj.text
                }
                isloading={this.props.usersLoading}
                firstloading={isloading}
                haveData={haveusersData}
                values={values}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  centerclasses: state.centersClasses.centers_classes.data,
  ActiveToken: state.firebase.auth.stsTokenManager.accessToken,
  isloading: state.centersClasses.loading,
  haveError: state.centersClasses.err,
  haveData: state.centersClasses.haveData,
  userDetail: state.user.user.data,
  usersList: state.users.user !== undefined ? state.users.user.data : [],
  userscount: state.usersCount.users_count.data,
  usersLoading: state.users.loading,
  haveusersData: state.users.haveData,
  haveusersError: state.users.err,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
  getUser: (id, user_type) => dispatch(getUser(id, user_type)),
  handleOnSelect: (id, user_type) => {
    //localStorage.setItem("id", id);
    dispatch(push(`${USERMANAGEMENT_ROUTE}/edit/${user_type}/${id}`));
  },
  getUsers: (user_type, centre_id, class_id) =>
    dispatch(getUsers(user_type, centre_id, class_id)),
  getUsersCount: (user_type, centre_id, class_id) =>
    dispatch(getUsersCount(user_type, centre_id, class_id)),
  getMoreUsers: (nextPage, user_type, centre_id, class_id) =>
    dispatch(getMoreUsers(nextPage, user_type, centre_id, class_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementList);
