import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS";
export const FETCH_HOLIDAYS_SUCCESS = "FETCH_HOLIDAYS_SUCCESS";
export const FETCH_HOLIDAYS_FAILURE = "FETCH_HOLIDAYS_FAILURE";

export const fetchHolidays = () => ({
  type: FETCH_HOLIDAYS,
});

export const fetchHolidaysSuccess = (data) => ({
  type: FETCH_HOLIDAYS_SUCCESS,
  data,
});

export const fetchHolidaysFailure = (err) => ({
  type: FETCH_HOLIDAYS_FAILURE,
  err,
});

export const getHolidays = () => {
  return (dispatch) => {
    dispatch(fetchHolidays());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/attendance/holiday/", null, token)
          .then((res) => {
            dispatch(fetchHolidaysSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchHolidaysFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
