import React from "react";
import { Image } from "semantic-ui-react";
import plus_Icon from "../assets/images/plus_Icon.png";
import { Link } from "react-router-dom";

const RenderLinker = (props) => {
  return ( 
      <Link
        to={{
          pathname: props.route,
          data: props.obj,
        }}
        className={props.className}
      active={props.active}
      fluid={''+props.fluid}
      >     
      {props.showIcon ? <Image src={plus_Icon} /> : null}
      {props.btnText}
      </Link>
 
  );
};

export default RenderLinker;
