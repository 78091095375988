import { FETCH_SCHEDULE_TYPES, FETCH_SCHEDULE_TYPES_SUCCESS, FETCH_SCHEDULE_TYPES_FAILURE } from '../actions';

const initialState = {
  scheduleTypes: [],
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_SCHEDULE_TYPES:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_SCHEDULE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        scheduleTypes: action.data
      };
    case FETCH_SCHEDULE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        scheduleTypes: []
      };
    default:
      return state;
  }
}