import { FETCH_DONATION, FETCH_DONATION_SUCCESS, FETCH_DONATION_FAILURE } from '../actions';

const initialState = {
  donation: {},
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_DONATION:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        donation: action.data
      };
    case FETCH_DONATION_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        donation: {}
      };
    default:
      return state;
  }
}