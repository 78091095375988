import React, { Component } from "react";
import {
  Grid,
  Header,
  Segment,
  Image,
  TransitionablePortal,
  Modal,
  Dropdown,
  Message,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import RenderButton from "../../components/Button";
import RenderCheckbox from "../../components/CheckBox";
import Charts from "../../components/Chart";
import empty_package from "../../assets/images/donor_package.png";
import ic_cases from "../../assets/images/ic_cases.svg";
import ic_assignment from "../../assets/images/ic_assignment.svg";
import ic_submission from "../../assets/images/ic_submission.svg";
import ic_task from "../../assets/images/ic_task.svg";
import { ADDDONATION_ROUTE } from "../../routes";
import { DONATION_ROUTE } from "../../routes";
import { EDITDONATION_ROUTE } from "../../routes";
import {
  getAllDonationlist,
  getAllDonorlist,
  getParentDetail,
  getCentersClasses,
  getGraph,
} from "../../store/actions";
import RenderDonorlist from "./Donorlist";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import Xclose from "../../assets/images/Xclose.svg";
import RenderLoader from "../../components/Loader";
import moment from "moment";

let label = [];
let GraphDatas = [];
let index = 0;
let people = [];

const customTooltip = (tooltips, data) => {
  // console.log(tooltips, data);
  return `$ ${data.datasets[0].data[tooltips[0].index]}`;
};

const months = [
  { key: 1, text: "January", value: 1 },
  { key: 2, text: "February", value: 2 },
  { key: 3, text: "March", value: 3 },
  { key: 4, text: "April", value: 4 },
  { key: 5, text: "May", value: 5 },
  { key: 6, text: "June", value: 6 },
  { key: 7, text: "July", value: 7 },
  { key: 8, text: "August", value: 8 },
  { key: 9, text: "September", value: 9 },
  { key: 10, text: "October", value: 10 },
  { key: 11, text: "November", value: 11 },
  { key: 12, text: "December", value: 12 },
];

const option = {
  tooltips: {
    callbacks: {
      title: customTooltip,
      label: function (tooltipItem, data) {
        // console.log(tooltipItem, data);
        return `${people[tooltipItem.index]} People`;
      },
    },
    backgroundColor: "#FFF",
    titleFontSize: 16,
    titleAlign: "center",
    bodyAlign: "center",
    titleFontColor: "#000",
    bodyFontColor: "#939494",
    bodyFontSize: 12,
    cornerRadius: 8,
    displayColors: false,
    caretPadding: 10,
  },
  borderColor: "#979797",
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: true,
        ticks: {
          suggestedMin: 0,
        },
      },
    ],
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
};

const admin_type = localStorage.getItem("adminType");
const user_centre_id = localStorage.getItem("centre_id");

class Donationdash extends Component {
  state = {
    selectedDonation: 0,
    open: false,
    SCFA: 0,
    centre_id: "",
    class_id: "",
    notiloading: false,
    donation_title: "",
    donation_description: "",
    donation_total_needs: 0,
    donation_duration: 0,
    donation_id: "",
    start_date: moment(new Date())
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end_date: moment(new Date()).clone().endOf("month").format("YYYY-MM-DD"),
    select_Date: new Date().getMonth() + 1,
    selectedDonationValue: null,
    loaderReFetch: false,
  };

  constructor(props) {
    super(props);
  }

  handleCreateRoute = () => {
    this.props.dispatch(push(ADDDONATION_ROUTE));
  };

  handleEditRoute = (id) => {
    // this.props.dispatch(push(EDITDONATION_ROUTE));
    this.props.dispatch(push(`${EDITDONATION_ROUTE}/${id}`));
  };

  truncate = (str) => {
    return str.length > 16 ? str.substring(0, 13) + "..." : str;
  };

  getRateForDonation = () => {
    const { donorlist } = this.props;

    var raised = 0;
    donorlist.length > 0 &&
      donorlist != undefined &&
      donorlist.map((ex) => {
        if (ex.status.toLowerCase() != "cancelled") {
          raised = raised + ex.amount;
        }
      });
    return Math.floor((raised / this.state.donation_total_needs) * 100);
  };

  getTotalRaisedAmount = () => {
    const { donorlist } = this.props;
    var raised = 0;
    donorlist != undefined &&
      donorlist.map((ex) => {
        if (ex.status.toLowerCase() !== "cancelled") {
          raised = raised + ex.amount;
        }
      });
    return raised;
  };

  getTotalPaidAmount = () => {
    const { donorlist } = this.props;
    let amount = 0;
    donorlist !== undefined &&
      donorlist.forEach((item) => {
        if (item.status.toLowerCase() === "paid") amount += item.amount;
      });
    return amount;
  };

  getTotalProcessingAmount = () => {
    const { donorlist } = this.props;
    let amount = 0;
    donorlist !== undefined &&
      donorlist.forEach((item) => {
        console.log(item.status);
        if (item.status.toLowerCase() === "payment verification")
          amount += item.amount;
      });
    return amount;
  };

  handleCallback = (donation) => {
    this.setState({
      selectedDonation:
        donation != undefined ? (donation.length > 0 ? donation[0].id : 0) : 0,
      donation_title:
        donation != undefined
          ? donation.length > 0
            ? donation[0].title
            : ""
          : "",
      donation_description:
        donation != undefined
          ? donation.length > 0
            ? donation[0].description
            : ""
          : "",
      donation_total_needs:
        donation != undefined
          ? donation.length > 0
            ? donation[0].total_needs
            : ""
          : "",
      donation_duration:
        donation != undefined
          ? donation.length > 0
            ? moment(donation[0].to_date).diff(
                moment(donation[0].from_date),
                "days"
              )
            : 0
          : 0,
    });
    this.props.getGraph(
      this.state.start_date,
      this.state.end_date,
      donation[0].id
    );
    this.setState({
      selectedDonationValue: donation[0].id,
    });
    this.props.getAllDonorlist(donation[0].id);
  };

  openmodal = () => {
    this.setState({ open: !this.state.open, centre_id: "", class_id: "" });
  };

  ChooseCentre = (e, res) => {
    this.setState({
      [res.name]: res.value,
    });
  };

  ChooseClass = (e, res) => {
    this.setState({ class_id: res.value });
  };

  handleCheck = () => {
    this.setState({ SCFA: !this.state.SCFA });
  };

  onChangeDate = (e, data) => {
    var tempMonth = data.value > 10 ? data.value : "0" + data.value;
    const tempDateString =
      moment().format("YYYY") + "-" + tempMonth + "-" + "01";
    const startDateOfMonth = moment(tempDateString)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDateOfMonth = moment(tempDateString)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");

    this.props.getGraph(
      startDateOfMonth,
      endDateOfMonth,
      this.state.selectedDonation
    );

    this.setState({ select_Date: data.value });
  };

  sendNoti = (t, d) => {
    let c = [
      {
        centre_id: admin_type === "hq" ? this.state.centre_id : user_centre_id,
        class_id: [this.state.class_id],
        role: ["parent"],
      },
    ];
    let fd = new FormData();
    fd.append("title", t);
    fd.append("message", d);
    // fd.append('', this.state.SCFA);"
    let receiver = [
      {
        centre_id: [
          admin_type === "hq" ? this.state.centre_id : user_centre_id,
        ],
        class_id: [this.state.class_id],
        role: ["parent"],
      },
    ];
    fd.append("ref_id", this.state.selectedDonationValue);
    fd.append("ref_type", "donation");
    fd.append("receiver", JSON.stringify(receiver));
    console.log("donVal", this.state.selectedDonationValue);
    console.log("selectedDonationValue", this.state.selectedDonationValue);
    let main_centre_id =
      admin_type === "hq" ? this.state.centre_id : user_centre_id;
    if (main_centre_id !== "" && this.state.class_id !== "") {
      this.setState({ notiloading: true });
      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api.post("/notification/", fd, token).then((res) => {
            if (res.message === "success") {
              this.props.dispatch(push(DONATION_ROUTE));
              this.setState({ notiloading: false });
            } else {
              this.setState({ open: !this.state.open });
              this.setState({ notiloading: false });
            }
          });
        } catch (err) {
          console.log(err);
          this.setState({ notiloading: false });
        }
      });
    } else {
      alert("Please choose center and class");
    }
  };

  async componentDidMount() {
    this.props.getAllDonationlist(true, this.handleCallback);
  }

  reFetchData = () => {
    this.props.getAllDonorlist(this.state.selectedDonationValue);
  };

  reFetchDataLoaderCall = (bool) => {
    this.setState({
      loaderReFetch: bool,
    });
  };

  onSelectDonation = (e, data) => {
    let search = this.props.donation.filter((d) => d.id === data.value);
    this.setState({
      selectedDonationValue: data.value,
      selectedDonation: search[0].id,
      donation_title: search[0].title,
      donation_description: search[0].description,
      donation_total_needs: search[0].total_needs,
      donation_duration: moment(search[0].to_date).diff(
        moment(search[0].from_date),
        "days"
      ),
    });

    this.props.getGraph(this.state.start_date, this.state.end_date, data.value);
    this.setState({ select_Date: new Date().getMonth() + 1 });
    this.props.getAllDonorlist(data.value);
  };

  GraphDataAdd = (data) => {
    let amount = 0;
    if (index < 31 && data !== undefined) {
      if (index !== 0) {
        const find = data.filter(
          (da) => new Date(da.created_ts).getDate() === index
        );
        if (find.length > 0) {
          find.map((fi) => {
            amount = amount + fi.total_amount;
          });
          if (
            index === 5 ||
            index === 10 ||
            index === 15 ||
            index === 20 ||
            index === 25 ||
            index === 30
          ) {
            label.push(JSON.stringify(index));
          } else {
            label.push("");
          }
          people.push(find.length);
          GraphDatas.push(amount);
          index++;
          this.GraphDataAdd(data);
        } else {
          if (
            index === 5 ||
            index === 10 ||
            index === 15 ||
            index === 20 ||
            index === 25 ||
            index === 30
          ) {
            label.push(JSON.stringify(index));
          } else {
            label.push("");
          }
          people.push(find.length);
          GraphDatas.push(0);
          index++;
          this.GraphDataAdd(data);
        }
      } else {
        index++;
        this.GraphDataAdd(data);
      }
    } else {
      label.push("Day");
    }
  };

  render() {
    const {
      donation = [],
      isloading,
      centerclasses = [],
      classes = [],

      haveDonorData,
      GraphData,
    } = this.props;
    let result = [];
    let donorlist = this.props.donorlist;

    if (GraphData !== undefined) {
      label = [];
      GraphDatas = [];
      index = 0;
      people = [];
      this.GraphDataAdd(GraphData);
    }
    // console.log("donorlist", donorlist);
    // console.log("GraphData", GraphData);

    const data = {
      labels: label,

      datasets: [
        {
          borderColor: "#4075FF",
          borderWidth: 1,
          fill: true,
          backgroundColor: "#E6F1FC",
          data: GraphDatas,
        },
      ],
    };

    let centers = [];
    let tempclass = [];

    if (admin_type === "hq") {
      centerclasses.forEach((data) => {
        centers.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
    }
    let formattedDefault;
    let formattedDonation = donation.map((e) => {
      const eater =
        window.innerWidth >= 1500
          ? 19
          : window.innerWidth >= 1400
          ? 20
          : window.innerWidth >= 1250
          ? 21
          : window.innerWidth >= 1200
          ? 22
          : window.innerWidth >= 1150
          ? 23
          : window.innerWidth >= 1100
          ? 23
          : window.innerWidth >= 1050
          ? 24
          : window.innerWidth >= 1000
          ? 25
          : window.innerWidth >= 950
          ? 26
          : window.innerWidth >= 900
          ? 27
          : window.innerWidth >= 850
          ? 28
          : 35;
      const wordCount = window.innerWidth / eater;
      const title =
        e.title.length > wordCount
          ? `${e.title.slice(0, wordCount)}...`
          : e.title;
      return {
        key: e.id,
        text: e.title,
        value: e.id,
      };
    });
    if (formattedDonation.length > 0) {
      formattedDefault = formattedDonation[0].value;
    }

    tempclass = centerclasses.find((result) =>
      (result.id === admin_type) === "hq"
        ? this.state.centre_id
        : user_centre_id
    );
    tempclass !== undefined &&
      tempclass.class.forEach((data) => {
        classes.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });

    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Dimmer active={this.state.bool} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="noBottomMargin">
              <Grid.Row>
                <Grid.Column floated="left" width={12}>
                  <Header as="h3" floated="left">
                    Call for Donation
                  </Header>
                </Grid.Column>
                <Grid.Column floated="right" width={4}>
                  <RenderButton
                    floated="right"
                    className="primaryBtn"
                    fluid={true}
                    btnText="Add new"
                    showIcon={true}
                    onClick={this.handleCreateRoute}
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Dropdown
                    options={formattedDonation}
                    selection
                    fluid
                    onChange={this.onSelectDonation}
                    placeholder="Select Donation"
                    defaultValue={formattedDefault}
                    value={
                      this.state.selectedDonationValue
                        ? this.state.selectedDonationValue
                        : formattedDefault
                    }
                  />
                </Grid.Column>
                {/* {donation.map((dn, idx) => (
                  <Grid.Column width={3} key={idx}>
                    <div style={{ marginBottom: 5 }}>
                      <RenderButton
                        btnText={this.truncate(dn.title)}
                        className="defaultBtn"
                        onClick={(e) => this.onSelectDonation(dn)}
                        active={this.state.selectedDonation === dn["id"]}
                        numberOfLines={1}
                        ellipsizeMode="head"
                      />
                    </div>
                  </Grid.Column>
                ))} */}
              </Grid.Row>
            </Grid>

            <Grid.Column width={16}>
              <Segment className="forms-listbox">
                <Grid>
                  <Grid.Row style={{ height: "330px" }}>
                    <Grid.Column
                      width={6}
                      style={{
                        position: "relative",
                        left: "15px",
                        top: "30px",
                        height: "275px",
                      }}
                    >
                      <div className="donateTitle">
                        <Grid.Row>
                          <Header>{this.state.donation_title}</Header>
                        </Grid.Row>
                        <br></br>
                        <Grid.Row>
                          <div>{this.state.donation_description}</div>
                        </Grid.Row>
                        <br></br>
                      </div>
                      <RenderButton
                        btnText="Edit"
                        className="donation-square"
                        onClick={() =>
                          this.handleEditRoute(this.state.selectedDonation)
                        }
                        active={true}
                      />
                      <RenderButton
                        btnText="Send Notification"
                        className="donation-square"
                        onClick={() => this.openmodal()}
                        active={true}
                      />
                    </Grid.Column>

                    <TransitionablePortal
                      open={this.state.open}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        size="tiny"
                        open={this.state.open}
                        onClose={() => this.openmodal()}
                      >
                        <Modal.Content>
                          <div align="right">
                            <Image
                              src={Xclose}
                              style={{ cursor: "pointer" }}
                              onClick={() => this.openmodal()}
                            />
                          </div>
                          <div
                            align="left"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            Send Notification
                          </div>
                          <br></br>
                          <div>{this.state.donation_description}</div>
                          <br></br>
                          <Grid className="activityTitle2">
                            <Grid.Row>
                              {localStorage.getItem("adminType") === "hq" ? (
                                <Grid.Column width={8}>
                                  <Header as="h5" color="black">
                                    Centre
                                  </Header>
                                  <Dropdown
                                    name="centre_id"
                                    className="dropdown2"
                                    onChange={this.ChooseCentre}
                                    placeholder="Choose Center"
                                    options={centers.sort((a, b) =>
                                      a.text !== b.text
                                        ? a.text < b.text
                                          ? -1
                                          : 1
                                        : 0
                                    )}
                                    selection
                                    fluid
                                  />
                                </Grid.Column>
                              ) : null}
                              <Grid.Column width={admin_type === "hq" ? 8 : 12}>
                                <Header as="h5" color="black">
                                  Class
                                </Header>
                                <Dropdown
                                  name="class_id"
                                  className="dropdown2"
                                  onChange={this.ChooseClass}
                                  placeholder="Choose Class"
                                  options={classes.sort((a, b) =>
                                    a.text !== b.text
                                      ? a.text < b.text
                                        ? -1
                                        : 1
                                      : 0
                                  )}
                                  selection
                                  fluid
                                />
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                              <Grid.Column width={16}>
                                <RenderCheckbox
                                  onChange={this.handleCheck}
                                  label="SCFA"
                                  value={true}
                                />
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                              <Grid.Column width={4}></Grid.Column>
                              <Grid.Column width={8}>
                                {/* <RenderButton
                                                btnText="Send"
                                                className="defaultBtn"
                                                onClick={()=>{this.sendNoti(title, description)}}
                                                active={true}
                                                fluid={true}
                                                /> */}
                                <Button
                                  content="Send"
                                  primary
                                  size="tiny"
                                  onClick={() => {
                                    this.sendNoti(
                                      this.state.donation_title,
                                      this.state.donation_description
                                    );
                                  }}
                                  className="submitBtn"
                                  loading={this.state.notiloading}
                                />
                              </Grid.Column>
                              <Grid.Column width={4}></Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Modal.Content>
                      </Modal>
                    </TransitionablePortal>

                    <Grid.Column width={10} style={{ height: "275px" }}>
                      <div
                        style={{
                          width: "150px",
                          position: "relative",
                          right: 0,
                          float: "right",
                        }}
                      >
                        <Dropdown
                          name="month"
                          onChange={this.onChangeDate}
                          customStyle={{ fontWeight: "bold" }}
                          options={months}
                          value={this.state.select_Date}
                          selection
                          fluid
                        />
                      </div>
                      <div style={{ height: "250px" }}>
                        <Charts type={"Line"} data={data} option={option} />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column width={16}>
              <Grid.Row>
                <br></br>
                <br></br>
              </Grid.Row>
            </Grid.Column>

            <Grid>
              <Grid.Row>
                <Grid.Column width={3} style={{ paddingRight: 2 }}>
                  <div className="pt24 pb24 border-light2 br8">
                    <div className="flex flex-align-center">
                      <div className="w24"></div>
                      <div className="w32">
                        <Image src={ic_task} style={{ width: "32px" }}></Image>
                      </div>
                      <p className="fs28 fw600 pl9">
                        ${this.state.donation_total_needs}
                      </p>
                    </div>
                    <b className="pl24">Total Needs</b>
                  </div>
                </Grid.Column>

                <Grid.Column width={3} style={{ paddingRight: 2 }}>
                  <div className="pt24 pb24 border-light2 br8">
                    <div className="flex flex-align-center">
                      <div className="w24"></div>
                      <div className="w32">
                        <Image
                          src={ic_submission}
                          style={{ width: "32px" }}
                        ></Image>
                      </div>
                      <p className="fs28 fw600 pl9">
                        {this.state.donation_duration}day
                      </p>
                    </div>
                    <b className="pl24">Duration</b>
                  </div>
                </Grid.Column>

                <Grid.Column width={3} style={{ paddingRight: 2 }}>
                  <div className="pt24 pb24 border-light2 br8">
                    <div className="flex flex-align-center">
                      <div className="w24"></div>
                      <div className="w32">
                        <Image src={ic_cases} style={{ width: "32px" }}></Image>
                      </div>
                      <p className="fs28 fw600 pl9">
                        {this.getRateForDonation()}%
                      </p>
                    </div>
                    <b className="pl24">Rate</b>
                  </div>
                </Grid.Column>
                <Grid.Column width={7}>
                  <div className="pt24 pb24 flex border-light2 br8">
                    <div style={{ width: "50%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="w24"></div>
                        <div className="w32">
                          <Image
                            src={ic_assignment}
                            style={{ width: "32px" }}
                          ></Image>
                        </div>
                        <p className="fs28 fw600 pl9">
                          ${this.getTotalRaisedAmount()}
                        </p>
                      </div>
                      <b className="pl24">Raised</b>
                    </div>
                    <div className="whalf text-ar pr24 fw600">
                      <div style={{ color: "#7CD227" }}>
                        Paid: ${this.getTotalPaidAmount()}
                      </div>
                      <div style={{ color: "#F3B329" }}>
                        Processing: ${this.getTotalProcessingAmount()}
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {donorlist.length === 0 || donorlist === null ? (
              <div
                className="form-list-empty-cart"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <Image
                    src={empty_package}
                    style={{
                      width: "52px",
                    }}
                  />
                </div>

                <p>Empty</p>
              </div>
            ) : (
              donorlist.length > 0 && (
                <RenderDonorlist
                  filtersUsers={donorlist}
                  isloading={this.props.donorloading}
                  haveData={haveDonorData}
                  reFetchDonorList={this.reFetchData}
                  loaderReFetch={this.reFetchDataLoaderCall}
                />
              )
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isloading: state.donation.loading,
  donation:
    state.donation.donation !== undefined ? state.donation.donation.data : [],
  donorlist: state.donors.donors !== undefined ? state.donors.donors : [],
  donorloading: state.donors.loading,
  haveDonorData: state.donors.haveData,
  parents: state.parent.parent !== undefined ? state.parent.parent.data : [],
  centerclasses: state.centersClasses.centers_classes.data,
  centreisloading: state.centersClasses.loading,
  haveError: state.centersClasses.err,
  haveData: state.centersClasses.haveData,
  GraphData: state.GraphData.donationGraph.data,
  // classes: state.classes.classes!== undefined? state.classes.classes.data: [],
});
const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getAllDonationlist: (isInitializing, handleCallback) =>
    dispatch(getAllDonationlist(isInitializing, handleCallback)),
  getAllDonorlist: (id) => dispatch(getAllDonorlist(id)),
  getParentDetail: (parentId) => dispatch(getParentDetail(parentId)),
  getCentersClasses: () => dispatch(getCentersClasses()),
  getGraph: (start_date, end_date, id) =>
    dispatch(getGraph(start_date, end_date, id)),
  // getClasses: (centre_id)=> dispatch(getClasses(centre_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Donationdash);
