import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_PARENT = "FETCH_PARENT";
export const FETCH_PARENT_SUCCESS = "FETCH_PARENT_SUCCESS";
export const FETCH_PARENT_FAILURE = "FETCH_PARENT_FAILURE";
export const CLEAR_PARENT = "CLEAR_PARENT";

export const fetchParent = (id) => ({
  type: FETCH_PARENT,
  id,
});

export const fetchParentSuccess = (data) => ({
  type: FETCH_PARENT_SUCCESS,
  data,
});

export const fetchParentFailure = () => ({
  type: FETCH_PARENT_FAILURE,
});

export const clearParent = () => ({
  type: CLEAR_PARENT,
});

export const getParentDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchParent());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/user/parent/", { id: id }, token)
          .then((res) => {
            dispatch(fetchParentSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchParentFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
