import React, { Component } from "react";
import {
  Grid,
  Header,
  Segment,
  Image,
  List,
  Modal,
  TransitionablePortal,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { FORM_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import ic_form1 from "../../assets/images/ic_form1.svg";
import ic_twoPerson from "../../assets/images/ic_twoPerson.svg";
import Barchart from "../../components/Chart";
import usr from "../../assets/images/usr.png";
import ic_form from "../../assets/images/ic_form.svg";
import { getFormDetail, getFormChart } from "../../store/actions";
import moment from "moment";
import Xclose from "../../assets/images/Xclose.svg";
import RenderLoader from "../../components/Loader";

const cmargin = {
  marginTop: 10,
};


const option = {
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Days",
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Amount",
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: true,
      },
    ],
  },
  maintainAspectRatio: false,
  legend: {
    // reverse: true,
    // align: "start"
    display: false,
  },
};
const initial = {
  open: false,
};
class Formview extends Component {
  constructor(props) {
    super(props);
    this.state = initial;
    this.index = "0";
  }
  componentDidMount() {
    // console.log(this.props);
    this.props.getFormDetail(this.props.location.state.id, 10);
    this.props.getFormChart(this.props.location.state.id, "Open", true);
  }
  handleFilter = (val) => {
    console.log(val);
  };

  handleOnChange = () => {
    console.log("handleOnChange hit");
  };

  handleSetting = () => {};

  validURL = (str) => {
    if (str !== undefined) {
      if (str.match("https://")) {
        return true;
      } else {
        return false;
      }
    }
  };

  openmodal = (idx) => {
    this.setState({ open: !this.state.open });
    this.index = idx;
  };
  closemodal = () => {
    this.setState({ open: !this.state.open });
  };

  _renderFormData = (form_setting) => {
    let form_settings = JSON.parse(form_setting);
    console.log(form_settings);
    return form_settings != undefined ? (
        <div>
        {
            form_settings.map((e,i)=>(
                <div>
                    <div style={cmargin}>
                        <h4 style={cmargin}>{`${i+1}. ${e.data}`}</h4>
                    </div>
                    <div style={cmargin}>
                        <p style={{marginLeft:15}}>{e.type == "Multiple choice" ? e.option[e.value] : e.value}</p>
                    </div>
                </div>
            ))
        }
        </div>
    )  : null;
  };

  render() {
    const {
      fd = null,
      fdhaveData,
      chart = null,
      havefderror,
      fdloading,
    } = this.props;
    let chartData = [];
    chart !== null &&
      Object.keys(chart).forEach(function (key) {
        chartData.push(chart[key]);
      });
    const data = {
      labels: [
        "",
        "",
        "",
        "",
        "5",
        "",
        "",
        "",
        "",
        "10",
        "",
        "",
        "",
        "",
        "15",
        "",
        "",
        "",
        "",
        "20",
        "",
        "",
        "",
        "",
        "25",
        "",
        "",
        "",
        "",
        "30",
      ],
      datasets: [
        {
          label: "People",
          fill: false,
          backgroundColor: "#3C73DC",
          barThickness: 6,
          data: [2,5,6,10,45],
        },
      ],
    };
    return (
      <Grid className="fullHeight">
        <Dimmer active={fdloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={FORM_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <Grid className="form-subcontainer">
              <Grid.Column width={16}>
                <div style={{ width: "65%", margin: "auto" }}>
                  <Grid className="activityTitle">
                    <Grid.Row>
                      <Grid.Column width={9}>
                        <Header as="h5" color="grey">
                          Form
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Header as="h5" color="grey">
                          Period
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Header as="h5" color="grey">
                          Status
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={1}>
                        <Header as="h5" color="grey">
                          Filled
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={1}>
                        <Header as="h5" color="grey"></Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {fdhaveData && (
                    <Segment className="forms-listbox">
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={9} verticalAlign="middle">
                            <p>{fd.name}</p>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <p style={{ marginTop: 5 }}>
                              {moment(fd.valid_from_date).format("DD MMM")} -{" "}
                              {moment(fd.valid_to_date).format("DD MMM")}
                            </p>
                          </Grid.Column>
                          <Grid.Column width={2}>
                            <p className="regStatusMessage nofontWeight">
                              {fd.status}
                            </p>
                          </Grid.Column>
                          <Grid.Column width={1} verticalAlign="middle">
                            <span style={{ fontWeight: "bold" }}>
                              {fd.submitted_forms.length}
                            </span>
                          </Grid.Column>
                          <Grid.Column
                            width={1}
                            floated="left"
                            verticalAlign="middle"
                          >
                            <div style={{ width: "20px" }}>
                              <Image src={ic_form1} alt="unable" />
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  )}
                  <Segment className="forms-listbox">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Barchart type={"Bar"} data={data} option={option} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>

                  <Grid className="activityTitle">
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <Header as="h5" color="black">
                          Filled form List
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={2} floated="right">
                        <Header as="h5" color="black">
                          <Image
                            src={ic_twoPerson}
                            alt="unable"
                            style={{ width: "16px", marginTop: "-2px" }}
                          />
                          {fd !== null && (
                            <span style={{ width: "16px", marginLeft: "5px" }}>
                              {fd.submitted_forms.length}
                            </span>
                          )}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {fd === [] ? (
                    <Message
                      header="Error loading users"
                      content="Error loading users. Please try again."
                      negative
                    />
                  ) : fd !== null && fd.submitted_forms.length === 0 ? (
                    <Message
                      header="No Record Found"
                      content=" There is no submitted form in this section."
                      warning
                    />
                  ) : (
                    <List celled className="peopleList">
                      {fd !== null &&
                        fd.submitted_forms.length !== 0 &&
                        fd.submitted_forms.map((data, idx) => (
                          <List.Item key={idx}>
                            <Image
                              avatar
                              src={
                                this.validURL(data.submitted_by_user.img) ===
                                true
                                  ? data.submitted_by_user.img
                                  : usr
                              }
                              verticalAlign="middle"
                              style={{
                                width: 48,
                                height: 48,
                                border: "2px solid #4075FF",
                              }}
                            />
                            <List.Content verticalAlign="middle">
                              <List.Description as="h5">
                                {data.submitted_by_user.name}
                              </List.Description>
                              <List.Description as="a">
                                {data.last_updated}
                              </List.Description>
                            </List.Content>
                            <List.Content
                              floated="right"
                              verticalAlign="middle"
                            >
                              <Image
                                src={ic_form}
                                style={{
                                  width: 24,
                                  height: 24,
                                  cursor: "pointer",
                                }}
                                onClick={() => this.openmodal(idx)}
                              />
                            </List.Content>
                          </List.Item>
                        ))}
                    </List>
                  )}
                  {fd !== null && fd.submitted_forms.length !== 0 && (
                    <TransitionablePortal
                      open={this.state.open}
                      transition={{ animation: "scale", duration: 400 }}
                    >
                      <Modal
                        size="tiny"
                        open={this.state.open}
                        onClose={() => this.closemodal()}
                      >
                        <Modal.Header style={{ border: 0 }}>
                          <div align="right">
                            <Image
                              src={Xclose}
                              style={{ cursor: "pointer" }}
                              onClick={() => this.closemodal()}
                            />
                          </div>
                          <div align="left"> Form Detail</div>
                        </Modal.Header>
                        <Modal.Content>
                          <List celled className="peopleList">
                            <List.Item>
                              <Image
                                avatar
                                src={
                                  this.validURL(
                                    fd.submitted_forms[this.index]
                                      .submitted_by_user.img
                                  ) === true
                                    ? fd.submitted_forms[this.index]
                                        .submitted_by_user.img
                                    : usr
                                }
                                // style={{ width: 48, height: 48 }}
                                style={{
                                  width: 48,
                                  height: 48,
                                  border: "2px solid #4075FF",
                                }}
                              />
                              {/* <Image
                                avatar
                                src="https://react.semantic-ui.com/images/avatar/small/helen.jpg"
                                className="child"
                              /> */}
                              <List.Content>
                                <List.Header>
                                  {
                                    fd.submitted_forms[this.index]
                                      .submitted_by_user.name
                                  }
                                  ・
                                  <span as="h3" style={{ color: "gray" }}>
                                    {
                                      fd.submitted_forms[this.index]
                                        .submitted_by_role
                                    }
                                  </span>
                                </List.Header>
                              </List.Content>
                            </List.Item>
                            <List.Item>
                              <div>
                                <h4>{fd.name}</h4>
                              </div>
                              <div style={cmargin}>
                                <p>{fd.description}</p>
                              </div>
                              {
                                  this._renderFormData(fd.submitted_forms[this.index].form_settings)
                              }
                            </List.Item>
                          </List>
                        </Modal.Content>
                      </Modal>
                    </TransitionablePortal>
                  )}
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  fdloading: state.formdetail.loading,
  fdhaveData: state.formdetail.haveData,
  fd:
    state.formdetail.formdetail !== undefined
      ? state.formdetail.formdetail.data
      : [],
  chart:
    state.formchart.formchart !== undefined
      ? state.formchart.formchart.data
      : [],
  havefderror: state.formdetail.err,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getFormChart: (id, status, isDetail) =>
    dispatch(getFormChart(id, status, isDetail)),
  getFormDetail: (id, size) => dispatch(getFormDetail(id, size)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Formview);
//   export default connect()(Formview);
