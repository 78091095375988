import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import {
  DONATION_ROUTE,
  ADDDONATION_ROUTE,
  EDITDONATION_ROUTE,
} from "../../routes";
import Donationdash from "./Donationdash";
import AddDonation from "./AddDonation";
import DonationEdit from "./DonationEdit";
import { firebase } from "../../config/firebase";
import { getCurrentLoginUser } from "../../store/actions";
import { connect } from "react-redux";
import AccessDeniedPage from "../error/AccessDeniedPage";

export const Donation = (props) => {
  const [isAccess, setIsAccess] = useState(true);
  useEffect(() => {
    firebase.reloadAuth().then((res) => {
      props.getCurrentLoginUser(res.uid, "admin");
      if (props.userDetail.admin_type !== "hq") {
        setIsAccess(false);
      } else {
        setIsAccess(true);
      }
    });
  }, []);
  if (isAccess) {
    return (
      <React.Fragment>
        <Route exact path={DONATION_ROUTE} component={Donationdash} />
        <Route exact path={ADDDONATION_ROUTE} component={AddDonation} />
        {/* <Route exact path={EDITDONATION_ROUTE} component={DonationEdit} /> */}
        <Route
          exact
          path={`${EDITDONATION_ROUTE}/:id`}
          component={DonationEdit}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Route exact path={DONATION_ROUTE} component={AccessDeniedPage} />
        <Route exact path={ADDDONATION_ROUTE} component={AccessDeniedPage} />
        <Route
          exact
          path={`${EDITDONATION_ROUTE}/:id`}
          component={AccessDeniedPage}
        />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => ({
  userDetail: state.currentLoginUser.currentLoginUser.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Donation);
