import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { firebaseReducer } from "react-redux-firebase";
import { reducer as centersClassesReducer } from "./centres_classes";
import { reducer as adminReducer } from "./admin";
import { reducer as dashboardNoti } from "./dashboard_noti";
import { reducer as facilitatorReducer } from "./facilitator";
import { reducer as parentReducer } from "./parent";
import { reducer as formtypeReducer } from "./formtype";
import { reducer as userReducer } from "./user";
import { reducer as currentLoginUserReducer } from "./current_login_user";
import { reducer as usersReducer } from "./users";
import { reducer as donationlist } from "./donation";
import { reducer as usersData } from "./UsersData";
import { reducer as donors } from "./donor";
import { reducer as merchandisesReducer } from "./merchandises";
import { reducer as merchandiseAllReducer } from "./merchandise_pagination";
import { reducer as merchandiseTypesReducer } from "./merchandisetypes";
import { reducer as recentOrderReducer } from "./recentorders";
import { reducer as merchandiseReducer } from "./merchandise";
import { reducer as usersCountReducer } from "./users_count";
import { reducer as donationHistoryReducer } from "./donationhistory";
import { reducer as merchandisetypeReducer } from "./merchandisetype";
import { reducer as classReducer } from "./class";
import { reducer as getMessageReducer } from "./getmessage";
import { reducer as getRoomlist } from "./room";
import { reducer as notificationReducer } from "./notifications";
import { reducer as attendanceReducer } from "./attendance";
import { reducer as attendancedetailReducer } from "./attendance_detail";
import { reducer as eventsReducer } from "./events";
import { reducer as eventReducer } from "./event";
import { reducer as formchartReducer } from "./formchart";
import { reducer as formlistReducer } from "./formlist";
import { reducer as formDetailsReducer } from "./formdetails";
import { reducer as scheduleColorReducer } from "./schedulecolors";
import { reducer as scheduleTypesReducer } from "./scheduletypes";
import { reducer as scheduleTypeReducer } from "./scheduletype";
import { reducer as scheduleItemsReducer } from "./scheduleitems";
import { reducer as scheduleItemReducer } from "./scheduleitem";
import { reducer as participantsReducer } from "./participants";
import { reducer as schedulesReducer } from "./schedules";
import { reducer as scheduleReducer } from "./schedule";
import { reducer as schedulesCalViewReducer } from "./schedulescalendarview";
import { reducer as quickaccessReducer } from "./quickaccess";
import { reducer as studentlistsReducer } from "./studentlists";
import { reducer as getLoginUserReducer } from "./getLoginUser";
import { reducer as GraphReducer } from "./donation_graph";
import { reducer as dashboard_donation } from "./dashboard_donation";
import { reducer as dashboard_activity } from "./dashboard_activity";
import { reducer as attendance_Data } from "./attendance_data_pagination";
import { reducer as maintenance_modeReducer } from "./maintenance_mode";
import { reducer as newsfeedReducer } from "./get_newsfeed";
import { reducer as holidaysReducer } from "./holidays";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    centersClasses: centersClassesReducer,
    user: userReducer,
    currentLoginUser: currentLoginUserReducer,
    admin: adminReducer,
    dashboardNoti: dashboardNoti,
    facilitator: facilitatorReducer,
    attendance_Data: attendance_Data,
    parent: parentReducer,
    users: usersReducer,
    dashboardDonation: dashboard_donation,
    dashboardActivity: dashboard_activity,
    donation: donationlist,
    donors: donors,
    usersData: usersData,
    GraphData: GraphReducer,
    merchandises: merchandisesReducer,
    merchandisetypes: merchandiseTypesReducer,
    recentorders: recentOrderReducer,
    merchandise: merchandiseReducer,
    merchandiseAll: merchandiseAllReducer,
    usersCount: usersCountReducer,
    donationhistory: donationHistoryReducer,
    merchandisetype: merchandisetypeReducer,
    classes: classReducer,
    gmessage: getMessageReducer,
    room: getRoomlist,
    notifications: notificationReducer,
    attendance: attendanceReducer,
    attendanceDetail: attendancedetailReducer,
    events: eventsReducer,
    event: eventReducer,
    formchart: formchartReducer,
    formdetail: formDetailsReducer,
    scheduleColors: scheduleColorReducer,
    scheduleTypes: scheduleTypesReducer,
    scheduleType: scheduleTypeReducer,
    scheduleItems: scheduleItemsReducer,
    scheduleItem: scheduleItemReducer,
    participants: participantsReducer,
    schedules: schedulesReducer,
    schedule: scheduleReducer,
    schedulescalendarView: schedulesCalViewReducer,
    quickaccess: quickaccessReducer,
    studentlist: studentlistsReducer,
    formtypeState: formtypeReducer,
    formlistState: formlistReducer,
    getLoginUserState: getLoginUserReducer,
    maintenanceModeState: maintenance_modeReducer,
    newsfeedState: newsfeedReducer,
    holidays: holidaysReducer,
  });

export default createRootReducer;
