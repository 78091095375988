import React from 'react';
import { Message, TextArea } from 'semantic-ui-react';

const RenderTextArea = (props) => {
  return (
    <div>
      <TextArea
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={props.value}
      />
      {props.isError && props.isTouched && <Message visible error content={props.isError}/>}
    </div>
  )
}

export default RenderTextArea;
