import React from 'react';
import { Route } from 'react-router'
import { NOTIFICATION_ROUTE, NOTIFICATION_CREATE_ROUTE } from '../../routes';
import NotificationList from './NotificationList';
import NotificationCreate from './NotificationCreate';

export const Notification = () => (
  <React.Fragment>
    <Route exact path={NOTIFICATION_ROUTE} component={NotificationList} />
    <Route exact path={NOTIFICATION_CREATE_ROUTE} component={NotificationCreate} />
    {/* <Route exact path="/activity/edit" component={ActivityEdit}/> */}
  </React.Fragment>
);

export default Notification;
