// import querystring from "querystring";

//const apiRoot = "https://asia-southeast2-morningstar-dev.cloudfunctions.net"; //DEV
//const apiRoot = "https://asia-southeast2-morningstar-qa.cloudfunctions.net"; //QA
const apiRoot = "https://asia-southeast2-morningstar-89ffa.cloudfunctions.net"; //Prod

/**
 * https://dev.to/eddieaich/create-an-awesome-js-api-interface-using-fetch-in-less-than-50-lines-3d65#final-code
 */

const get = (path, params, token) => {
  return doRequest(path, params, "GET", token);
};

const post = (path, params, token) => {
  return doRequest(path, params, "POST", token);
};

const put = (path, params, token) => {
  return doRequest(path, params, "PUT", token);
};

const del = (path, params, token) => {
  return doRequest(path, params, "DELETE", token);
};

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
};

const doRequest = async (path, params, method, activeToken) => {
  const options = { method, headers: {} };
  if (params) {
    if (method === "GET") {
      path += "?" + objectToQueryString(params);
    } else {
      options.body = params;
    }
  }
  if (activeToken) {
    options.headers = new Headers({
      Authorization: `Bearer ${activeToken}`,
    });
  }

  return fetch(apiRoot + path, options).then((response) => {
    if (response.status === 204) {
      return undefined;
    }
    if (response.status === 403) {
      activeToken = null;
    }
    return response.json().then((result) => {
      return result;
    });
  });
};
export default {
  get,
  post,
  put,
  del,
  apiRoot,
};
