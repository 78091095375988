import React, { Component } from "react";
import { Radio, Grid, Message, Image } from "semantic-ui-react";
import { Field } from "formik";

const boxStyle = {
  backgroundColor: "#E6F1FC",
  border: "1px solid #4075FF",
  borderRadius: 8,
  boxSizing: "border-box",
};
class MultipleRadioGroupDay extends Component {
  constructor(props) {
    super(props);
    let days = {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    };
    props.value.forEach((item) => {
      switch (item) {
        case "Sun":
          days.Sun = true;
          break;
        case "Mon":
          days.Mon = true;
          break;
        case "Tue":
          days.Tue = true;
          break;
        case "Wed":
          days.Wed = true;
          break;
        case "Thu":
          days.Thu = true;
          break;
        case "Fri":
          days.Fri = true;
          break;
        case "Sat":
          days.Sat = true;
          break;
      }
    });
    this.state = {
      Sun: days.Sun,
      Mon: days.Mon,
      Tue: days.Tue,
      Wed: days.Wed,
      Thu: days.Thu,
      Fri: days.Fri,
      Sat: days.Sat,
    };
    props.value.forEach((item) => {
      switch (item) {
        case "Sun":
          days.Sun = true;
          break;
        case "Mon":
          days.Mon = true;
          break;
        case "Tue":
          days.Tue = true;
          break;
        case "Wed":
          days.Wed = true;
          break;
        case "Thu":
          days.Thu = true;
          break;
        case "Fir":
          days.Fri = true;
          break;
        case "Sat":
          days.Sat = true;
          break;
      }
    });
    this.state = {
      Sun: days.Sun,
      Mon: days.Mon,
      Tue: days.Tue,
      Wed: days.Wed,
      Thu: days.Thu,
      Fri: days.Fri,
      Sat: days.Sat,
    };
  }

  _activeBlock(label) {
    this.setState({
      [label]: !this.state[label],
    });
  }

  render() {
    return (
      <div>
        <Grid divided="vertically">
          <Grid.Row columns={3}>
            {this.props.options.map((data, ix) => {
              //   console.log(data);
              let styleBox = {};
              if (this.state[data.label]) {
                styleBox = boxStyle;
              }
              return (
                <Grid.Column key={ix} className={"radio-toolbar"}>
                  <div
                    style={styleBox}
                    onClick={() => {
                      this._activeBlock(data.label);
                      this.props._onChangeForMultipleDay(
                        data.value,
                        this.props.formikProps
                      );
                    }}
                  >
                    <span>{data.label}</span>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
        {this.props.isError && this.props.isTouched && (
          <Message visible error content={this.props.isError} />
        )}
      </div>
    );
  }
}
export default MultipleRadioGroupDay;
