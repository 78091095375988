import React, { useEffect } from "react";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import { Loader } from "semantic-ui-react";

function Condtion(props) {
  // useEffect(() => {
  //   const query = new URLSearchParams(props.location.search);
  //   const ref_id = query.get("ref_id");
  //   const payerid = query.get("PayerID");

  //   const ref_type = query.get("ref_type");
  //   const token = query.get("token");

  //   var fd = new FormData();
  //   fd.append("token", token);
  //   return firebase.reloadAuth().then((res) => {
  //     const token = res.ya === null ? res.za : res.ya;
  //     api
  //       .post("/paypal/complete_payment", fd, token)
  //       .then((res) => {
  //         console.log("res:)", res);
  //         if (res.message == "success") {
  //           window.location.href = `/error/success/?ref_type=${ref_type}`;
  //         } else {
  //           console.log("not success");
  //           window.location.href = `/error/failed/?ref_type=${ref_type}`;
  //         }
  //       })
  //       .catch((err) => console.log("er", err));
  //   });
  // }, []);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        zIndex: 1000,
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      <Loader active size="huge" />
    </div>
  );
}

export default Condtion;
