import React from "react";
import { Button, Dimmer, Grid, Header, Image, Message, Modal } from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import { SIGNUP_ROUTE, PASSWORD_RESET_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import logo from "../../assets/images/logo1px.png";
import RenderInput from "../../components/TextInput";
import { isRequired, isEmail } from "../../validators";

const LoginForm = (props) => {
  return (
    <Grid textAlign="center" className="authFom" verticalAlign="middle">
      <Grid.Column
        style={{
          maxWidth: 400,
          maxHeight: 530,
          overflow: "auto",
          background: "#f9fbff",
          borderRadius: 20,
          padding: "20px 40px",
        }}
      >
        <Header as="h2" color="teal" textAlign="center">
          <Image src={logo} />
        </Header>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h3" floated="left">
              Login
            </Header>
          </Grid.Column>
        </Grid.Row>
        <br />
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={props.handleSubmit}
        >
          {(formikProps) => {
            return (
              <Form>
                <Header as="h4" textAlign="left">
                  Email
                </Header>
                <Field
                  name="email"
                  component={RenderInput}
                  onChange={formikProps.handleChange("email")}
                  validate={isEmail}
                  isTouched={formikProps.touched.email}
                  isError={formikProps.errors.email}
                />

                <Header as="h4" textAlign="left">
                  Password
                </Header>
                <Field
                  name="password"
                  type="password"
                  component={RenderInput}
                  onChange={formikProps.handleChange("password")}
                  validate={isRequired}
                  isTouched={formikProps.touched.password}
                  isError={formikProps.errors.password}
                />
                <div>
                  {props.message ? (
                    <Message style={{ color: "red" }}>{props.message}</Message>
                  ) : null}
                </div>
                <Button
                  content="Login"
                  primary
                  fluid
                  size="large"
                  type="submit"
                  loading={props.isLoading}
                />
              </Form>
            );
          }}
        </Formik>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column
              textAlign="left"
              width={6}
              style={{ padding: 0, display: "none" }}
            >
              <RoutingButton
                to={SIGNUP_ROUTE}
                className="simpleRouter"
                //btnText="Sign up"
              />
            </Grid.Column>

            <Grid.Column textAlign="center" width={16} style={{ padding: 0 }}>
              <RoutingButton
                to={PASSWORD_RESET_ROUTE}
                className="simpleRouter"
                btnText="Forgot password"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
