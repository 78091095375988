import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Progress,
  Image,
  Loader,
  Dimmer,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
// import RenderButton from '../../components/Button';
// import RenderDropDown from '../../components/DropDown';
import {
  getUser,
  getCurrentLoginUser,
  getQuickaccess,
  getEvents,
  getCentersClasses,
  getDashboardNoti,
  getAllDonationlist,
  getDashboardDonation,
  pushToken,
  getMessage,
  addMessage,
  getRoomlist,
} from "../../store/actions";
import {
  DASHBOARD_ROUTE,
  NOTIFICATION_ROUTE,
  ATTENDANCE_ROUTE,
  DONATION_ROUTE,
  ACTIVITY_ROUTE,
} from "../../routes";
import attendance from "../../assets/images/attendance.svg";
import date from "../../assets/images/date.svg";
import donation from "../../assets/images/donation.svg";
import messages from "../../assets/images/messages.svg";
import Charts from "../../components/Chart";
import UserSetting from "../../components/Usersetting";
import { firebase, db } from "../../config/firebase";
import dateFormat from "dateformat";
import moment from "moment";
import mark from "../../assets/images/mark.png";
import api from "../../config/api";
import Pf_Default from "../../assets/images/ic_account.png";
import {
  getAppMaintenance,
  getAppMaintenances,
} from "../../store/actions/get_maintenance_mode";

let Graph_label = [];
let donationGraph1 = [];
let donationGraph2 = [];
let donationGraph3 = [];
let index = 0;
let donationIndex = 0;
let Graph_data = [];

const months = [
  { key: 1, text: "January", value: 1 },
  { key: 2, text: "February", value: 2 },
  { key: 3, text: "March", value: 3 },
  { key: 4, text: "April", value: 4 },
  { key: 5, text: "May", value: 5 },
  { key: 6, text: "June", value: 6 },
  { key: 7, text: "July", value: 7 },
  { key: 8, text: "August", value: 8 },
  { key: 9, text: "September", value: 9 },
  { key: 10, text: "October", value: 10 },
  { key: 11, text: "November", value: 11 },
  { key: 12, text: "December", value: 12 },
];

const option = {
  plugins: [
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height = this.height + 50;
        };
      },
    },
  ],
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: true,
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          padding: 25,
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: true,
        ticks: {
          suggestedMin: 0,
        },
      },
    ],
  },
  maintainAspectRatio: false,
  padding: (5, 5, 5, 5),
  legend: {
    display: false,
    // reverse: true,
    // position: "top",
    // align: "start",
    // labels: {
    //   boxWidth: 13,
    // },
  },
};
const RenderListBox = (props) => {
  let centers = [],
    classes = [],
    goldObj = {},
    selectedCentre = {};
  props.centerclasses.map((data) => {
    centers.push({
      key: data.id,
      text: data.name,
      value: data.id,
    });
  });

  goldObj = props.centerclasses.find((result) => result.id === props.centre_id);
  goldObj !== undefined &&
    goldObj.class.map((data) => {
      classes.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });

  let centreAndClass = props.centerclasses.filter(
    (x) => x.id === props.centre_ids[0]
  )[0];
  var centreName = "";
  var className = "";
  if (centreAndClass !== undefined && centreAndClass !== {}) {
    centreName =
      props.centre_ids.length > 1 ? "All Centres" : centreAndClass.name;
    let temp = centreAndClass.class.filter(
      (e) => e.id === props.class_ids[0]
    )[0];
    if (temp !== undefined && temp !== {}) {
      className =
        props.class_ids.length > 1 &&
        props.class_ids.length > props.centre_ids.length
          ? "All Classes"
          : temp.name;
    }
  }

  const percentage = Math.round(
    (props.event_participants / props.max_vacancy) * 100
  );

  selectedCentre = classes.find((result) => result.key === props.class_id);
  return (
    <Segment className="listBox ">
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <Header as="h6" color="grey" className="noBottomMargin">
              {moment(props.reg_to_date).format("DD MMM")}
            </Header>
            <p>{props.title}</p>
          </Grid.Column>
          <Grid.Column width={3}>
            <p style={{ marginTop: 8 }}>{centreName + " • " + className}</p>
          </Grid.Column>
          <Grid.Column width={3}>
            <div
              style={{
                width: "100%",
                backgroundColor: `${
                  props.status === "active" ? "#D9EED5" : "#E6F4FF"
                }`,
                borderRadius: "16px",
                textAlign: "center",
              }}
            >
              {props.status !== "inactive" ? (
                <p className=" nofontWeight regOpenMessage">
                  Open for registration
                </p>
              ) : (
                <p className=" nofontWeight regCloseMessage">
                  Registration closed
                </p>
              )}
            </div>
          </Grid.Column>
          <Grid.Column width={2}>
            {props.scfa !== 1 ? (
              <p style={{ marginTop: "10px", marginLeft: "20px" }}>-</p>
            ) : (
              <Image
                src={mark}
                style={{ width: "22", marginTop: "10px", marginLeft: "20px" }}
              />
            )}
          </Grid.Column>
          <Grid.Column width={2}>
            <p
              style={{ fontWeight: "bold", fontSize: 12, marginBottom: "10px" }}
            >
              {props.event_participants}/{props.max_vacancy}
            </p>
            {((props.event_participants / props.max_vacancy) * 100).toFixed(
              3
            ) === 0 ? (
              <Progress percent={100} size="tiny" disabled />
            ) : (
              <Progress
                percent={(
                  (props.event_participants / props.max_vacancy) *
                  100
                ).toFixed(3)}
                size="tiny"
                color="blue"
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const RenderNoti = (props) => {
  return (
    <Grid.Row>
      <Grid.Column width={4}>
        <p className="noBottomMargin noti-datetime">
          {moment(props.created_at).format("DD MMM")}
        </p>
        <p className="noBottomMargin noti-datetime">
          {moment(props.created_at).format("h:mm a")}
        </p>
      </Grid.Column>
      <Grid.Column width={12}>
        <p className="noti-description">{props.message}</p>
      </Grid.Column>
    </Grid.Row>
  );
};

const initialState = {
  user_id: "",
  user_type: "admin",
  isLoading: false,
  setDate: "",
  start_date: new Date(),
  end_date: new Date(),
  from_date: new Date(),
  noti_date: new Date(),
  filtered_by: "admin_view",
  reg_status: "all",
  selected_date: new Date().getMonth(),
  Graph_start_date: moment(new Date())
    .clone()
    .startOf("month")
    .format("YYYY-MM-DD"),
  Graph_end_date: moment(new Date())
    .clone()
    .endOf("month")
    .format("YYYY-MM-DD"),
  select_Date: new Date().getMonth() + 1,
};
class Upcoming extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.props.getCentersClasses();
    this.props.getAppMaintenance();

    localStorage.setItem("isReload", false);

    // messaging.requestPermission().then(() => {
    //   return messaging.getToken()
    // }).then(token =>  {

    //   this.props.pushNoti(token)
    // })
    // .catch(err => console.log(err))
    // messaging.onMessage((payload) => {
    //   if(payload.data.room_id) {

    //     this.props.addMessage(payload.data)
    //   }

    //   // this.props.getMessage(payload.data.receiver,payload.data.sender, 10 )
    // });
    db.collection("comms")
      .where("receiver", "==", this.state.user_id)
      .onSnapshot((snapshot) => {
        getRoomlist(this.state.user_id, false);
      });
    firebase.reloadAuth().then((res) => {
      try {
        this.setState(
          {
            user_id: res.uid,
            user_type: "admin",
          },
          () => {
            localStorage.setItem("user_Id", this.state.user_id);
            const token = res.ya === null ? res.za : res.ya;

            api
              .get(
                `/user/${this.state.user_type}/`,
                { id: this.state.user_id },
                token
              )
              .then((res) => {
                console.log('upcoming : ', res);
                if(!res.data) return;
                localStorage.setItem("adminType", res.data.admin_type);
                localStorage.setItem("centre_Id", res.data.centre_id ? res.data.centre_id[0] : "");
                localStorage.setItem("user_Id", this.state.user_id);
                this.props.getDashboardNoti(
                  dateFormat(
                    new Date(new Date().getTime() - 5 * (3600 * 1000 * 24)),
                    "yyyy-mm-dd"
                  ),
                  dateFormat(new Date(), "yyyy-mm-dd"),
                  res.data.centre_id ? res.data.centre_id[0] : ""
                );

                getRoomlist(this.state.user_id, false);

                return res;
              });
            this.props.getAllDonationlist();
            this.props.getDashboardDonation(
              this.state.Graph_start_date,
              this.state.Graph_end_date
            );
            // this.props.getUser(this.state.user_id, this.state.user_type);
            this.props.getCurrentLoginUser(
              this.state.user_id,
              this.state.user_type
            );
            this.props.getQuickaccess(this.state.user_id, this.state.user_type);
            this.props.getEvents(
              dateFormat(new Date(), "yyyy-mm-dd"),
              dateFormat(
                new Date(new Date().getTime() + 7 * (3600 * 1000 * 24)),
                "yyyy-mm-dd"
                // new Date(date.getFullYear(), date.getMonth() + 1, 0),
                // "yyyy-mm-dd"
              ),
              this.state.filtered_by,
              this.state.reg_status
            );
            if (!localStorage.getItem("user_type"))
              localStorage.setItem("user_type", "admin");
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  }

  onChangeDate = (e, data) => {
    var tempMonth = data.value > 10 ? data.value : "0" + data.value;
    const tempDateString =
      moment().format("YYYY") + "-" + tempMonth + "-" + "01";
    const startDateOfMonth = moment(tempDateString)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDateOfMonth = moment(tempDateString)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");

    this.props.getDashboardDonation(startDateOfMonth, endDateOfMonth);

    this.setState({ select_Date: data.value });
    // this.props.getEvents(
    //   startDateOfMonth,
    //   endDateOfMonth,
    //   this.state.filtered_by,
    //   this.state.reg_status
    // );
    // this.setState(
    //   { start_date: date, selected_date: date },
    //   () =>
    //     this.props.getEvents(
    //       dateFormat(date, "yyyy-mm-dd"),
    //       dateFormat(
    //         new Date(date.getFullYear(), date.getMonth() + 1, 0),
    //         "yyyy-mm-dd"
    //       ),
    //     ),
    //   localStorage.setItem("start_date", dateFormat(date, "yyyy-mm-dd")),
    //   localStorage.setItem(
    //     "end_date",
    //     dateFormat(
    //       new Date(date.getFullYear(), date.getMonth() + 1, 0),
    //       "yyyy-mm-dd"
    //     )
    //   )
    // );
  };

  handleRoute = () => {
    this.props.dispatch(push(DASHBOARD_ROUTE));
  };

  handleOnChange = () => {
    console.log("handleOnChange hit");
  };

  handleSetting = () => {};

  handleLogout = () => {
    const { dispatch } = this.props;
    this.setState({
      isLoading: true,
    });
    try {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.setItem("isLogout", false);
          this.setState({
            isLoading: false,
          });
          window.location.assign(window.location.origin);
          // dispatch(push(LOGIN_ROUTE));
        })
        .catch((err) => {
          dispatch({
            type: "LOGGED_OUT_ERROR",
          });
        });
    } catch (err) {
      dispatch({
        type: "LOGGED_OUT_ERROR",
      });
    }
  };

  GraphData = (data) => {
    let amount = 0;
    if (
      index <= new Date(this.state.Graph_end_date).getDate() &&
      data !== undefined
    ) {
      if (index !== 0) {
        const find = data.filter(
          (da) => new Date(da.created_ts).getDate() === index
        );
        if (find.length > 0) {
          find.map((fi) => {
            amount = amount + fi.total_amount;
          });

          if (donationIndex === 0) {
            if (
              index === 5 ||
              index === 10 ||
              index === 15 ||
              index === 20 ||
              index === 25 ||
              index === 30
            ) {
              Graph_label.push(JSON.stringify(index));
            } else {
              Graph_label.push("");
            }
          }
          if (donationIndex === 0) {
            donationGraph1.push(amount);
          }
          if (donationIndex === 1) {
            donationGraph2.push(amount);
          }
          if (donationIndex === 2) {
            donationGraph3.push(amount);
          }
          index++;
          this.GraphData(data);
        } else {
          if (donationIndex === 0) {
            if (
              index === 5 ||
              index === 10 ||
              index === 15 ||
              index === 20 ||
              index === 25 ||
              index === 30
            ) {
              Graph_label.push(JSON.stringify(index));
            } else {
              Graph_label.push("");
            }
          }
          if (donationIndex === 0) {
            donationGraph1.push(0);
          }
          if (donationIndex === 1) {
            donationGraph2.push(0);
          }
          if (donationIndex === 2) {
            donationGraph3.push(0);
          }
          index++;
          this.GraphData(data);
        }
      } else {
        index++;
        this.GraphData(data);
      }
    } else {
      if (donationIndex === 0) {
        Graph_label.push("Day");
      }
      donationIndex++;
      this.donationDataGet(this.props.dashboardDonation);
    }
  };

  donationDataGet = (data) => {
    if (donationIndex === 1) {
      Graph_data.push({
        label: data !== undefined && data[0].title,
        fill: false,
        backgroundColor: "#9CDFB6",
        barThickness: 6,
        data: donationGraph1,
      });
    } else if (donationIndex === 2) {
      Graph_data.push({
        label: data !== undefined && data[1].title,
        backgroundColor: "#FF7A4F",
        barThickness: 6,
        data: donationGraph2,
      });
    } else if (donationIndex === 3) {
      Graph_data.push({
        label: data !== undefined && data[2].title,
        fill: false,
        backgroundColor: "#3C73DC",
        barThickness: 6,
        data: donationGraph3,
      });
    }
    if (donationIndex < data.length && data !== undefined) {
      index = 0;
      this.GraphData(data[donationIndex].donation_lists);
      // const id = data[donationIndex].id;
      // firebase.reloadAuth().then((res) => {
      //   try {
      //     api
      //       .get(
      //         "/donation/donation_graph",
      //         { from_date: start, to_date: end, id: id },
      //         res.za
      //       )
      //       .then((res) => {
      //         if (res.message === "success") {
      //           this.GraphData(res.data, start, end);
      //         } else {
      //           alert(`${res.message}`);
      //         }
      //       });
      //   } catch (err) {
      //     console.log(err);
      //   }
      // });
    }
  };

  render() {
    const {
      userDetail = {},
      haveData,
      loading,
      quickaccess = [],
      dashboardDonation,
      quickaccessloading,
      events,
      iseventsloading,
      eventshaveError,
      notiError,
      dashboardNoti = [],
      centerclasses = [],
    } = this.props;

    // console.log(events);
    if (localStorage.getItem("adminType") !== undefined) {
      if (
        localStorage.getItem("adminType") === "hq" &&
        centerclasses.length > 0
      ) {
        const center = centerclasses.sort((a, b) =>
          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
        );
        const classe = center[0].class.sort((a, b) =>
          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
        );

        localStorage.setItem("atten_center", center[0].id);
        localStorage.setItem("atten_class", classe[0].id);
      } else if (
        localStorage.getItem("adminType") === "centre" &&
        centerclasses.length > 0 &&
        userDetail.centre_id
      ) {
        const atten_center = centerclasses.find(
          (data) => data.id === userDetail.centre_id[0]
        );

        const classe = atten_center.class.sort((a, b) =>
          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
        );
        localStorage.setItem("atten_center", userDetail.centre_id[0]);
        localStorage.setItem("atten_class", classe[0].id);
      }
    }

    // if (userDetail.admin_type !== undefined) {
    //   if (userDetail.admin_type === "hq" && centerclasses.length > 0) {
    //     const center = centerclasses.sort((a, b) =>
    //       a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
    //     );
    //     const classe = center[0].class.sort((a, b) =>
    //       a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
    //     );

    //     localStorage.setItem("atten_center", center[0].id);
    //     localStorage.setItem("atten_class", classe[0].id);
    //   } else if (
    //     userDetail.admin_type === "centre" &&
    //     centerclasses.length > 0
    //   ) {
    //     const atten_center = centerclasses.find(
    //       (data) => data.id === userDetail.centre_id[0]
    //     );

    //     const classe = atten_center.class.sort((a, b) =>
    //       a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
    //     );
    //     localStorage.setItem("atten_center", userDetail.centre_id[0]);
    //     localStorage.setItem("atten_class", classe[0].id);
    //   }
    // }

    const notiData = dashboardNoti.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );

    if (dashboardDonation !== undefined) {
      Graph_label = [];
      donationGraph1 = [];
      donationGraph2 = [];
      donationGraph3 = [];
      Graph_data = [];
      index = 0;
      donationIndex = 0;
      this.donationDataGet(dashboardDonation);
    }

    const data = {
      labels: Graph_label,
      datasets: Graph_data,
      // datasets: [
      //   {
      //     label: dashboardDonation !== undefined && dashboardDonation[2].title,
      //     fill: false,
      //     backgroundColor: "#3C73DC",
      //     barThickness: 6,
      //     data: donationGraph3,
      //   },
      //   {
      //     label: dashboardDonation !== undefined && dashboardDonation[1].title,
      //     backgroundColor: "#FF7A4F",
      //     barThickness: 6,
      //     data: donationGraph2,
      //   },
      //   {
      //     label: dashboardDonation !== undefined && dashboardDonation[0].title,
      //     fill: false,
      //     backgroundColor: "#9CDFB6",
      //     barThickness: 6,
      //     data: donationGraph1,
      //   },
      // ],
    };

    if (userDetail) {
      if (userDetail.admin_type && userDetail.admin_type === "centre") {
        if (!localStorage.getItem("centre_id"))
          localStorage.setItem("centre_id", userDetail.centre[0].id);
      } else if (userDetail.admin_type && userDetail.admin_type === "hq") {
        if (!localStorage.getItem("centre_id"))
          localStorage.setItem("centre_id", "all");
      }
    }

    return (
      <Grid className="fullHeight">
        <Dimmer active={loading || quickaccessloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>

        <Grid.Row>
          <Grid.Column style={{ width: "66.5%" }}>
            {localStorage.getItem("adminType") !== "centre" && (
              <Grid className="noBottomMargin">
                <Grid.Column floated="left" width={12}>
                  <Header as="h3" floated="left">
                    Dashboard
                  </Header>
                </Grid.Column>

                <Grid.Column floated="left" width={10}>
                  <h4>Call for Donation</h4>
                </Grid.Column>
                {/* <Grid.Column floated='right' width={4}>
                   <RenderDropDown
                     onChange={this.handleOnChange}
                     customStyle={{ float: 'right' }}
                   />
   
   
                 </Grid.Column> */}

                <Grid.Column floated="right" width={5}>
                  {/* <DatePicker
                     selected={this.state.selected_date}
                     onChange={this.onChangeDate}
                     selectsStart
                     startDate={this.state.startDate}
                     endDate={this.state.endDate}
                     value={this.state.selected_date}
                     dateFormat="MMM "
                     showMonthYearPicker
                   />
   
                   <div className="input-iconnnn">
                   
                     <Icon className="chevron down" color="blue" />
                   </div> */}
                  <div
                    style={{
                      width: "150px",
                      position: "relative",
                      right: 0,
                      float: "right",
                    }}
                  >
                    <Dropdown
                      name="month"
                      onChange={this.onChangeDate}
                      options={months}
                      value={this.state.select_Date}
                      selection
                      fluid
                    />
                  </div>
                </Grid.Column>
              </Grid>
            )}

            {localStorage.getItem("adminType") !== "centre" && (
              <Segment className="listBox">
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {Graph_data.length !== 0 &&
                        Graph_data.map((d) => (
                          <p
                            style={{
                              display: "inline-flex",
                              paddingLeft: "20px",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: `${d.backgroundColor}`,
                                width: "12px",
                                height: "12px",
                                marginTop: "5px",
                              }}
                            ></div>
                            <p style={{ marginLeft: "5px", color: "gray" }}>
                              {d.label}
                            </p>
                          </p>
                        ))}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Charts type={"Bar"} data={data} option={option} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )}
            <Grid className="noBottomMargin">
              <Dimmer
                active={!(loading || quickaccessloading) && iseventsloading}
                inverted
              >
                <Loader />
              </Dimmer>
              <Grid.Column
                floated="left"
                width={5}
                style={{ marginTop: "20px" }}
              >
                <h4 className="subtitle">Upcoming Events </h4>
              </Grid.Column>
            </Grid>

            <Grid className="activityTitle2" style={{ marginLeft: "3px" }}>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Header as="h4" color="grey">
                    Event
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Centres/ Class
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Registration status
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h4" color="grey">
                    SCFA
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h4" color="grey">
                    No.people
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Dimmer active={!loading && iseventsloading} inverted>
              <Loader />
            </Dimmer>

            {eventshaveError ? (
              <Message
                header="Error loading events"
                content="Error loading events. Please try again."
                negative
              />
            ) : events !== undefined && events.length === 0 && !loading ? (
              <Message
                header="No event found"
                content=" There is no event in next 7 days."
                warning
              />
            ) : (
              events !== undefined &&
              events.map((event) => (
                <RenderListBox
                  {...event}
                  //handleOnSelect={handleOnSelect}
                  centerclasses={centerclasses}
                  key={event.id}
                />
              ))
            )}
          </Grid.Column>

          <Grid.Column className="grayBg">
            {haveData && (
              <Segment className="user-panel">
                <Grid style={{ float: "left" }}>
                  <div className="flex flex-align-center pos-abs top20">
                    <Image
                      src={userDetail.img === "" ? Pf_Default : userDetail.img}
                      verticalAlign="middle"
                      className="user-image"
                      as={"img"}
                      onError={(e) => (e.target.src = Pf_Default)}
                    />{" "}
                    <div className="text-turncation pl14">
                      <div className="text-white fw400 fs16 w200">
                        {window.innerWidth < 850
                          ? `${userDetail.name.slice(0, 9)}...`
                          : userDetail.name}
                      </div>
                      <div className="text-white fw700 fs12">
                        {userDetail.admin_type === "hq" ? "HQ " : "Centre "}
                        Admin
                      </div>
                    </div>
                  </div>
                </Grid>
                <div style={styles.settingContainer}>
                  <UserSetting
                    doAction={this.handleLogout}
                    isloading={this.state.isLoading}
                    detail={userDetail}
                  />
                </div>
              </Segment>
            )}
            <Grid>
              <Grid.Column floated="left" width={16}>
                <h4 className="subtitle">Quick Access</h4>
              </Grid.Column>
              <Grid.Column
                // width={8}
                width={window.innerWidth < 950 ? 16 : 8}
                onClick={() => this.props.dispatch(push(`${ATTENDANCE_ROUTE}`))}
              >
                <Segment
                  className="listBox listbox-border"
                  style={{ width: "100%", height: "120px", cursor: "pointer" }}
                >
                  <div>
                    <Grid.Column
                      style={{
                        width: "100%",
                        height: "120px",
                      }}
                    >
                      <p>Attendance</p>
                      <Grid
                        container
                        columns={16}
                        style={{ display: "inline-block" }}
                      >
                        <Grid.Column
                          style={{
                            width: "60%",
                            position: "absolute",
                            top: "30px",
                          }}
                        >
                          <img
                            className="ui image"
                            src={attendance}
                            alt="unavailable"
                          ></img>
                        </Grid.Column>
                        <Grid.Column
                          style={{
                            width: "20%",
                            position: "relative",
                            float: "right",
                            fontSize: "85%",
                            right: "14px",
                          }}
                        >
                          <h1>{quickaccess.attendance}</h1>Today
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </div>
                </Segment>
              </Grid.Column>

              <Grid.Column
                width={window.innerWidth < 950 ? 16 : 8}
                onClick={() =>
                  this.props.dispatch(push(`${NOTIFICATION_ROUTE}`))
                }
              >
                <Segment
                  className="listBox listbox-border"
                  style={{ width: "100%", height: "120px", cursor: "pointer" }}
                >
                  <div>
                    <Grid.Column style={{ width: "100%", height: "120px" }}>
                      <p>Notification</p>
                      <Grid
                        container
                        columns={16}
                        style={{ display: "inline-block" }}
                      >
                        <Grid.Column
                          style={{
                            width: "60%",
                            position: "absolute",
                            top: "30px",
                          }}
                        >
                          <img
                            className="ui image"
                            src={messages}
                            alt="unavailable"
                          ></img>
                        </Grid.Column>
                        <Grid.Column
                          style={{
                            width: "20%",
                            position: "relative",
                            float: "right",
                            fontSize: "85%",
                            right: "14px",
                          }}
                        >
                          <h1> {quickaccess.notification}</h1>msgs
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid>

            <Grid className="noBOttomMargin" style={{ marginTop: 0 }}>
              {localStorage.getItem("adminType") !== "centre" && (
                <Grid.Column
                  // width={8}
                  width={window.innerWidth < 950 ? 16 : 8}
                  onClick={() => this.props.dispatch(push(`${DONATION_ROUTE}`))}
                >
                  <Segment
                    className="listBox listbox-border"
                    style={{
                      width: "100%",
                      height: "120px",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <Grid.Column style={{ width: "100%", height: "120px" }}>
                        <p>Donation</p>
                        <Grid
                          container
                          columns={16}
                          style={{ display: "inline-block" }}
                        >
                          <Grid.Column
                            style={{
                              width: "60%",
                              position: "absolute",
                              top: "30px",
                            }}
                          >
                            <img
                              className="ui image"
                              src={donation}
                              alt="unavailable"
                            ></img>
                          </Grid.Column>
                          <Grid.Column
                            style={{
                              width: "20%",
                              position: "relative",
                              float: "right",
                              fontSize: "85%",
                              right: "14px",
                            }}
                          >
                            <h1>{quickaccess.donation} </h1>Item
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  </Segment>
                </Grid.Column>
              )}
              <Grid.Column
                // width={8}
                width={window.innerWidth < 950 ? 16 : 8}
                onClick={() => this.props.dispatch(push(`${ACTIVITY_ROUTE}`))}
              >
                <Segment
                  className="listBox listbox-border"
                  style={{ width: "100%", height: "120px", cursor: "pointer" }}
                >
                  <div>
                    <Grid.Column style={{ width: "100%", height: "120px" }}>
                      <p>Events</p>
                      <Grid
                        container
                        columns={16}
                        style={{ display: "inline-block" }}
                      >
                        <Grid.Column
                          style={{
                            width: "60%",
                            position: "absolute",
                            top: "30px",
                          }}
                        >
                          <img
                            className="ui image"
                            src={date}
                            alt="unavailable"
                          ></img>
                        </Grid.Column>
                        <Grid.Column
                          style={{
                            width: "20%",
                            position: "relative",
                            float: "right",
                            fontSize: "85%",
                            right: "14px",
                          }}
                        >
                          <h1>{quickaccess.event}</h1>events
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid>

            <Grid className="noBottomMargin">
              <Grid.Column floated="left" width={16}>
                <h4 className="subtitle">Notification</h4>
              </Grid.Column>
            </Grid>
            {!loading && (
              <Segment className="listBox listbox-border2">
                <Grid>
                  {notiError ? (
                    <Message
                      header="Error loading notification."
                      content="Error loading notification. Please try again."
                      negative
                    />
                  ) : notiData !== undefined &&
                    notiData.length === 0 &&
                    !loading ? (
                    <Message
                      style={{ width: "100%", borderRadius: "8px" }}
                      header="No notification found."
                      content="There is no notification in previous 5 days."
                      warning
                    />
                  ) : (
                    notiData !== undefined &&
                    notiData.map((notidata) => (
                      <RenderNoti {...notidata} key={notidata.id} />
                    ))
                  )}
                </Grid>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const styles = {
  settingContainer: {
    float: "right",
    position: "relative",
    left: "50px",
    transform:
      window.innerWidth < 850
        ? "translate(-65px, 50px)"
        : "translate(0px, 0px)",
  },
};
const mapStateToProps = (state) => ({
  // userDetail: state.user.user.data,
  userDetail: state.currentLoginUser.currentLoginUser.data,
  dashboardNoti: state.dashboardNoti.dashboardNoti.data,
  notiLoading: state.dashboardNoti.loading,
  loading: state.currentLoginUser.loading,
  // haveError: state.user.err,
  haveError: state.currentLoginUser.err,
  donationData: state.donation.donation.data,
  haveData: state.currentLoginUser.haveData,
  quickaccess: state.quickaccess.quickaccess.data,
  quickaccessloading: state.quickaccess.loading,
  quickaccesshaveError: state.quickaccess.err,
  quickaccesshaveData: state.quickaccess.haveData,
  events: state.events.events !== undefined ? state.events.events.data : [],
  iseventsloading: state.events.loading,
  eventshaveData: state.events.haveData,
  eventshaveError: state.events.err,
  notiError: state.dashboardNoti.err,
  centerclasses: state.centersClasses.centers_classes.data,
  dashboardDonation: state.dashboardDonation.dashboardDonation.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getUser: (user_id, user_type) => dispatch(getUser(user_id, user_type)),
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
  getQuickaccess: (user_id, user_type) =>
    dispatch(getQuickaccess(user_id, user_type)),
  getEvents: (start_date, end_date, filtered_by, reg_status) =>
    dispatch(getEvents(start_date, end_date, filtered_by, reg_status)),
  getCentersClasses: () => dispatch(getCentersClasses()),
  getDashboardNoti: (start_date, end_date, centre_id, class_id) =>
    dispatch(getDashboardNoti(start_date, end_date, centre_id, class_id)),
  getAllDonationlist: () => dispatch(getAllDonationlist()),
  getDashboardDonation: (start, end) =>
    dispatch(getDashboardDonation(start, end)),
  pushNoti: (token) => {
    dispatch(pushToken(token));
  },
  getMessage: (id, sender_id, size) =>
    dispatch(getMessage(id, sender_id, size)),
  addMessage: (data) => dispatch(addMessage(data)),
  getAppMaintenance: () => dispatch(getAppMaintenances()),
  getRoomlist: (senderid, refresh) => dispatch(getRoomlist(senderid, refresh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upcoming);
