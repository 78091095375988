import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Message,
  Icon,
  Modal,
  Dropdown,
  Dimmer,
  Loader,
  Checkbox,
  Card,
  Image,
  Divider,
} from "semantic-ui-react";
import { connect } from "react-redux";
import InAppMaintenance from "../../assets/images/app_maintenance.svg";
import { getAppMaintenances } from "../../store/actions/get_maintenance_mode";
import { postAppMaintenances } from "../../store/actions/app_maintenance";
import ExclamationIcon from "../../components/ExclamationIcon";

class MaintenanceDash extends Component {
  componentDidMount() {
    // this.props.getAppMaintenance();
  }

  state = {
    showModal: false,
  };

  render() {
    return (
      <Grid className="fullHeight">
        <Modal
          onClose={() => this.setState({ showModal: false })}
          onOpen={() => this.setState({ showModal: true })}
          open={this.state.showModal}
          // trigger={<Button>Show Modal</Button>}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: 500,
            // height: 305,
          }}
        >
          <Modal.Content>
            <div
              style={{ color: "#F3B329", textAlign: "center", marginTop: 63 }}
            >
              <ExclamationIcon width="120" height="120" />
            </div>
            <div
              style={{
                fontSize: 24,
                fontWeight: "700",
                textAlign: "center",
                marginTop: 40,
              }}
            >
              Do you want to turn {this.props.maintenance_mode ? "off" : "on"}{" "}
              the maintenance
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 40,
                justifyContent: "space-around",
              }}
            >
              <div
                onClick={() => this.setState({ showModal: false })}
                style={{
                  width: 195,
                  height: 48,
                  borderRadius: 9999,
                  border: "1px solid #4075FF",
                  display: "grid",
                  placeItems: "center",
                  fontSize: 14,
                  fontWeight: "600",
                  color: "#4075FF",
                  cursor: "pointer",
                  marginRight: 11,
                }}
              >
                Cancel
              </div>
              <div
                onClick={() => {
                  this.props.postAppMaintenance(!this.props.maintenance_mode);
                  this.setState({
                    showModal: false,
                  });
                }}
                style={{
                  width: 195,
                  height: 48,
                  borderRadius: 9999,
                  display: "grid",
                  placeItems: "center",
                  fontSize: 14,
                  fontWeight: "600",
                  color: "#fff",
                  backgroundColor: "#4075FF",
                  cursor: "pointer",
                }}
              >
                Yes
              </div>
            </div>
          </Modal.Content>
        </Modal>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="noBottomMargin">
              <Grid.Column floated="left" width={6}>
                <Header as="h3" floated="left">
                  App Maintenance
                </Header>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Row>
                <div className="appMaintenanceContainer card cardInApp">
                  <Image
                    src={InAppMaintenance}
                    style={{ height: 120, width: 120, marginTop: 20 }}
                    centered
                  />
                  <div
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingBottom: 20,
                    }}
                  >
                    <div style={{ height: 40 }}></div>
                    <p style={{ fontWeight: "bold", fontSize: 20 }}>
                      Maintenance mode
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      The Maintenance Mode page will display that{" "}
                    </p>
                    <p>the App is unavailble</p>
                  </div>
                  <Divider />
                  <div style={{ marginBottom: 10, marginLeft: 15 }}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={3}>
                          <Checkbox
                            toggle
                            checked={this.props.maintenance_mode}
                            onChange={() => {
                              this.setState({
                                showModal: true,
                              });
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <p>
                            {this.props.maintenance_mode === false
                              ? "Inactive"
                              : "Active"}
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    maintenance_mode: state.maintenanceModeState.maintenance_mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppMaintenance: () => dispatch(getAppMaintenances()),
    postAppMaintenance: (maintenance_mode) =>
      dispatch(postAppMaintenances(maintenance_mode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceDash);
