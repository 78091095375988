import React, { useEffect, useState } from "react";
import NewsfeedDash from "./NewsfeedDash";
import { Route } from "react-router";
import { NEWS_FEED_ROUTE } from "../../routes";
import { firebase } from "../../config/firebase";
import { getCurrentLoginUser } from "../../store/actions";
import { connect } from "react-redux";
import AccessDeniedPage from "../error/AccessDeniedPage";

function Newsfeed(props) {
  const [isAccess, setIsAccess] = useState(true);
  useEffect(() => {
    firebase.reloadAuth().then((res) => {
      props.getCurrentLoginUser(res.uid, "admin");
      if (props.userDetail.admin_type !== "hq") {
        setIsAccess(false);
      } else {
        setIsAccess(true);
      }
    });
  }, []);

  if (isAccess) {
    return <Route exact path={NEWS_FEED_ROUTE} component={NewsfeedDash} />;
  }
  return <Route exact path={NEWS_FEED_ROUTE} component={AccessDeniedPage} />;
}

const mapStateToProps = (state) => ({
  userDetail: state.currentLoginUser.currentLoginUser.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Newsfeed);
