import React, { Component } from "react";
import {
  Grid,
  Header,
  Segment,
  Image,
  Dropdown,
  Message,
  Dimmer,
  Loader,
  Pagination,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { FORM_CREATE_ROUTE, FORM_VIEW_ROUTE } from "../../routes";
import RenderButton from "../../components/Button";
import Charts from "../../components/Chart";
import ic_threedots from "../../assets/images/ic_threedots.svg";
import { getFormType, getFormLists } from "../../store/actions";
import RenderLoader from "../../components/Loader";
import moment from "moment";
import { firebase } from "../../config/firebase";
import api from "../../config/api";
import { Link } from "react-router-dom";
// const option={
//   scales: {
//     xAxes: [{
//           gridLines: {
//             color: "rgba(0, 0, 0, 0)",
//           },
//         stacked: true
//     }],
//     yAxes: [{
//           gridLines: {
//             color: "rgba(0, 0, 0, 0)",
//         },
//         stacked: true,
//     }]
//   },
//   maintainAspectRatio: false,
//   legend: {
//     reverse: true,
//     align: "start"
//   }
// }

const statusList = [
  {
    key: "Open",
    text: "Status: Open",
    value: "Open",
  },
  {
    key: "Closed",
    text: "Status: Closed",
    value: "Closed",
  },
];

class Formdash extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    selectedStatus: "Open",
    selectedFormType: "all",
    selectedPage: 1,
    is_posting: false,
  };

  componentDidMount() {
    this.props.getFormType();
    this.props.getFormLists(null, "Open", 0);
  }
  componentDidUpdate(prevProp) {
    if (this.props.formlists != prevProp.formlists) {
      this.setState({ selectedPage: 1 });
    }
  }
  handleCreateRoute = () => {
    this.props.dispatch(push(FORM_CREATE_ROUTE));
  };

  onChangeFormType = (e, data) => {
    this.setState({
      selectedFormType: data.value,
    });
    this.props.getFormLists(
      data.value == "all" ? null : data.value,
      this.state.selectedStatus,
      this.state.selectedPage - 1
    );
  };

  onChangeStatus = (e, data) => {
    // console.log(data);
    this.setState({
      selectedStatus: data.value,
    });
    this.props.getFormLists(
      this.state.selectedFormType == "all" ? null : this.state.selectedFormType,
      data.value,
      this.state.selectedPage - 1
    );
  };

  getFormTypes = () => {
    const { formtypes = [] } = this.props;
    let allFormTypes = [
      {
        key: "all",
        text: "All Forms",
        value: "all",
      },
    ];
    formtypes.map((x) => {
      allFormTypes.push({
        key: x.id,
        text: x.name,
        value: x.id,
      });
    });
    return allFormTypes;
  };

  updateStatus = (data, status) => {
    this.setState({
      is_posting: true,
    });
    let fd = new FormData();
    fd.append("id", data.id);
    fd.append("status", status);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/form/", fd, token).then((res) => {
          if (res.message === "Success") {
            // this.props.getAdminFormList(this.state.form_id, this.state.status, 10);
            this.props.getFormLists(
              this.state.selectedFormType == "all"
                ? null
                : this.state.selectedFormType,
              this.state.selectedStatus,
              this.state.selectedPage - 1
            );
            this.setState({
              is_posting: false,
            });
          } else {
            alert(`${res.message}`);
            this.setState({
              is_posting: false,
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  render() {
    const { formsloading, formlists, total_pages, next_page, is_loading } =
      this.props;
    return (
      <Grid className="fullHeight">
        <Dimmer active={is_loading || this.state.is_posting} inverted>
          <Loader inverted size="big" />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="noBottomMargin">
              <Grid.Row>
                <Grid.Column floated="left" width={12}>
                  <Header as="h3" floated="left">
                    Forms
                  </Header>
                </Grid.Column>
                <Grid.Column floated="right" width={4}>
                  <RenderButton
                    floated="right"
                    className="primaryBtn"
                    fluid={true}
                    btnText="Add new"
                    showIcon={true}
                    onClick={this.handleCreateRoute}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Dropdown
                    name="form_id"
                    selectOnBlur={false}
                    onChange={this.onChangeFormType}
                    customstyle={{ float: "right" }}
                    options={this.getFormTypes()}
                    defaultValue={"all"}
                    placeholder="Choose"
                    selection
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={window.innerWidth < 850 ? 4 : 3}>
                  <Dropdown
                    name="status"
                    // onChange={this.handleStatusChange}
                    selectOnBlur={false}
                    customstyle={{ float: "right" }}
                    options={statusList}
                    defaultValue={"Open"}
                    placeholder="Status"
                    onChange={this.onChangeStatus}
                    selection
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Grid.Column width={16}>
                        <Segment className="forms-listbox">
                            <Grid>
                            <Grid.Row>
                                <Grid.Column width={16} >
                                    <Charts type={"Bar"} data={data} option={option}/>
                                </Grid.Column>
                            </Grid.Row>
                            </Grid>
                        </Segment>
                </Grid.Column> */}
            <Header></Header>
            <Grid className="activityTitle2">
              {/* <Dimmer active={adminloading && !formsloading} inverted>
                <Loader inverted/>
              </Dimmer> */}
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h5" color="grey">
                    Form
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h5" color="grey">
                    Period
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h5" color="grey">
                    Status
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h5" color="grey">
                    Filled form
                  </Header>
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
              </Grid.Row>
            </Grid>

            {formlists.length === 0 &&
              !(is_loading || this.state.is_posting) && (
                <Message
                  header="No Record Found"
                  content=" There is no submitted form in this section."
                  warning
                />
              )}

            {formlists.map((data, idx) => (
              <Segment className="forms-listbox" key={idx}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8} verticalAlign="middle">
                      <p>{data.name}</p>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <p style={{ marginTop: 5 }}>
                        {moment(data.valid_from_date).format("DD MMM")} -{" "}
                        {moment(data.valid_to_date).format("DD MMM")}
                      </p>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <p className="regStatusMessage nofontWeight">
                        {data.status}
                      </p>
                    </Grid.Column>
                    <Grid.Column width={2} verticalAlign="middle">
                      <span style={{ fontWeight: "bold", marginLeft: "30px" }}>
                        {data.submitted_count}
                      </span>
                    </Grid.Column>
                    <Grid.Column
                      width={1}
                      verticalAlign="middle"
                      className="centerText"
                    >
                      <div className="ui simple dropdown item">
                        <div style={{ width: "20px" }}>
                          <Image src={ic_threedots} alt="unable" />
                        </div>
                        <div className="menu">
                          <div
                            onClick={() => {
                              this.props.dispatch(
                                push(`${FORM_VIEW_ROUTE}`, { id: data.id })
                              );
                            }}
                            className="item"
                          >
                            View
                          </div>
                          <Link
                            to={{
                              pathname: `/forms/editform/${data.id}`,
                              state: { data },
                            }}
                            className="item"
                          >
                            Edit
                          </Link>

                          <div
                            className="item"
                            onClick={() =>
                              this.updateStatus(
                                data,
                                data.status == "Open" ? "Closed" : "Open"
                              )
                            }
                          >
                            {" "}
                            {data.status === "Open" ? "Close" : "Open"}
                          </div>

                          <Link
                            to={{
                              pathname: `/forms/duplicate/`,
                              state: { data },
                            }}
                            className="item"
                          >
                            Duplicate
                          </Link>
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            ))}

            {total_pages > 1 && (
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}></Grid.Column>
                  <Grid.Column width={6} floated="right">
                    <Pagination
                      firstItem={null}
                      lastItem={null}
                      floated="right"
                      totalPages={total_pages}
                      siblingRange={3}
                      onPageChange={(e, data) => {
                        this.setState({
                          selectedPage: data.activePage,
                        });
                        this.props.getFormLists(
                          this.state.selectedFormType !== "all"
                            ? this.state.selectedFormType
                            : null,
                          this.state.selectedStatus,
                          data.activePage - 1
                        );
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  formtypes: state.formtypeState.formtypes,
  formlists: state.formlistState.formlists,
  is_loading: state.formlistState.is_loading,
  total_pages: state.formlistState.total_pages,
  next_page: state.formlistState.nextUrl,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getFormType: () => dispatch(getFormType()),
  getFormLists: (form_type_id, status, page) =>
    dispatch(getFormLists(form_type_id, status, page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Formdash);
