import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_RECENT_ORDERS = "FETCH_RECENT_ORDERS";
export const FETCH_RECENT_ORDERS_SUCCESS = "FETCH_RECENT_ORDERS_SUCCESS";
export const FETCH_RECENT_ORDERS_FAILURE = "FETCH_RECENT_ORDERS_FAILURE";

export const fetchRecentOrders = () => ({
  type: FETCH_RECENT_ORDERS,
});

export const fetchRecentOrdersSuccess = (data) => ({
  type: FETCH_RECENT_ORDERS_SUCCESS,
  data,
});

export const fetchRecentOrdersFailure = (err) => ({
  type: FETCH_RECENT_ORDERS_FAILURE,
  err,
});

export const getRecentOrders = (start_date, end_date) => {
  return (dispatch) => {
    dispatch(fetchRecentOrders());
    return firebase.reloadAuth().then((res) => {
      try {
        if (start_date === undefined && end_date === undefined) {
          start_date = localStorage.getItem("start-date");
          end_date = localStorage.getItem("end-date");
        }
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/merchandise/order_recent/",
            { start_date: start_date, end_date: end_date },
            token
          )
          .then((res) => {
            dispatch(fetchRecentOrdersSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchRecentOrdersFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
