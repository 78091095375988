import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const GET_APP_MAINTENANCE = "GET_APP_MAINTENANCE";
export const GET_APP_MAINTENANCE_SUCCESS = "GET_APP_MAINTENANCE_SUCCESS";
export const GET_APP_MAINTENANCE_FAILURE = "GET_APP_MAINTENANCE_FAILURE";

export const getAppMaintenance = () => ({
  type: GET_APP_MAINTENANCE,
});

export const getAppMaintenanceSuccess = (res) => ({
  type: GET_APP_MAINTENANCE_SUCCESS,
  data:res,
});

export const getAppMaintenanceFailure = () => ({
  type: GET_APP_MAINTENANCE_FAILURE,
});

export const getAppMaintenances = () => {
  return (dispatch) => {
    dispatch(getAppMaintenance());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        // https://asia-southeast2-morningstar-dev.cloudfunctions.net/system/maintenance_mode
        api
          .get(`/system/maintenance_mode`,null, token)
          .then((res) => {
            // console.log("doing", res);
            dispatch(getAppMaintenanceSuccess(res));
            return res;
          })
          .catch((err) => dispatch(getAppMaintenanceFailure()));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
