import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_SCHEDULE_COLORS = "FETCH_SCHEDULE_COLORS";
export const FETCH_SCHEDULE_COLORS_SUCCESS = "FETCH_SCHEDULE_COLORS_SUCCESS";
export const FETCH_SCHEDULE_COLORS_FAILURE = "FETCH_SCHEDULE_COLORS_FAILURE";

export const fetchScheduleColors = () => ({
  type: FETCH_SCHEDULE_COLORS,
});
export const fetchScheduleColorsSuccess = (data) => ({
  type: FETCH_SCHEDULE_COLORS_SUCCESS,
  data,
});

export const fetchScheduleColorsFailure = () => ({
  type: FETCH_SCHEDULE_COLORS_FAILURE,
});

export const getScheduleColors = () => {
  return (dispatch) => {
    dispatch(fetchScheduleColors());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/schedules/schedule_colors", null, token)
          .then((res) => {
            dispatch(fetchScheduleColorsSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchScheduleColorsFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
