import React from "react";
import { Message, Input } from "semantic-ui-react";

const DisabledTextInput = (props, { ...form }) => {
  return (
    <div style={{}}>
      <input
        id="disabled-input"
        onChange={props.onChange}
        placeholder="Answer Text"
        type={props.type}
        fluid
        size="small"
        value={props.value}
        min={props.min}
        step={props.step}
        maxLength={props.maxLength}
        max={props.max}
        disabled={props.disabled}
        style={{}}
      />
      {props.isError && props.isTouched && (
        <Message visible error content={props.isError} />
      )}
    </div>
  );
};

export default DisabledTextInput;
