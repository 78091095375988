import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const POST_NEWSFEED = "POST_NEWSFEED";
export const POST_NEWSFEED_SUCCESS = "POST_NEWSFEED_SUCCESS";
export const POST_NEWSFEED_FAILURE = "POST_NEWSFEED_FAILURE";

export const postNewsfeed = () => ({
  type: POST_NEWSFEED,
});

export const postNewsfeedSuccess = (res) => ({
  type: POST_NEWSFEED_SUCCESS,
  data:res,
});

export const postNewsfeedFailure = () => ({
  type: POST_NEWSFEED_FAILURE,
});

export const postNewsfeeds = (title,description,img_url,video_url,thumbnail_url,handleCallback) => {
  return (dispatch) => {
    dispatch(postNewsfeed());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        let formdata = new FormData();
        formdata.append("title",title);
        formdata.append("description",description);
        if(img_url !== null && img_url !== {} && img_url !== undefined){
            formdata.append("img_url",img_url);
        }
        video_url !== null && video_url !== {} && video_url !== undefined && formdata.append("video_url",video_url);
        thumbnail_url !== null && thumbnail_url !== {} && thumbnail_url !== undefined && formdata.append("thumbnail_url",thumbnail_url);

        api
          .post(`/newsfeeds/newsfeed`,formdata, token)
          .then((res) => {
            console.log("doing", res.data);
            dispatch(postNewsfeedSuccess(res));
            handleCallback !== undefined && handleCallback(true)
            return res;
          })
          .catch((err) => {
            dispatch(postNewsfeedFailure());
            handleCallback !== undefined && handleCallback(false)
            console.log("error", err);
          });
      } catch (err) {
        console.log(err);
      }
    });
  };
};
