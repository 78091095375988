import React from 'react';
import { Route } from 'react-router'
import { COMMS_ROUTE } from '../../routes';
import CommsList from './CommsList';


export const Comms =()=>(
  <React.Fragment>
    <Route exact path={COMMS_ROUTE} component={CommsList} />
  </React.Fragment>
)
export default Comms;
