import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import createAppStore from "./store";
import "semantic-ui-css/semantic.min.css";
import { firebase } from "./config/firebase";
import App from "./App";
import "./App.css";
import "normalize.css";
import "./index.css";

const history = createBrowserHistory();
const store = createAppStore({}, history);

const rrfConfig = {
  //userProfile: 'users'
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
