import React from 'react';
import { Route } from 'react-router'
import { ACTIVITY_ROUTE, ACTIVITY_CREATE_ROUTE } from '../../routes';
import ActivitiesList from './ActivitiesList';
import ActivityCreate from './ActivityCreate';
import ActivityEdit from './ActivityEdit';

const Activity = () => (
  <React.Fragment>
    <Route exact path={ACTIVITY_ROUTE} component={ActivitiesList} />
    <Route exact path={ACTIVITY_CREATE_ROUTE} component={ActivityCreate} />
    <Route exact path={`${ACTIVITY_ROUTE}/edit/:id`} component={ActivityEdit}/>
  </React.Fragment>
);

export default Activity;

