import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_FORM_LIST = "FETCH_FORM_LIST";
export const FETCH_FORM_LIST_SUCCESS = "FETCH_FORM_LIST_SUCCESS";
export const FETCH_FORM_LIST_FAILURE = "FETCH_FORM_LIST_FAILURE";

export const fetchFormList = () => ({
  type: FETCH_FORM_LIST,
});

export const fetchFormListSuccess = (data) => ({
  type: FETCH_FORM_LIST_SUCCESS,
  data: data.data,
  next: data.next,
  total_pages: data.total_pages,
});

export const fetchFormListFailure = (err) => ({
  type: FETCH_FORM_LIST_FAILURE,
  err,
});

export const getFormLists = (form_type_id, status, page) => {
  return (dispatch) => {
    dispatch(fetchFormList());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        if (form_type_id !== null) {
          api
            .get(
              "/form/",
              { form_type_id: form_type_id, status: status, page: page },
              token
            )
            .then((res) => {
              dispatch(fetchFormListSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchFormListFailure(err)));
        } else {
          api
            .get("/form/", { status: status, page: page }, token)
            .then((res) => {
              dispatch(fetchFormListSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchFormListFailure(err)));
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
};
