import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_ADMIN = "FETCH_ADMIN";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAILURE = "FETCH_ADMIN_FAILURE";
export const CLEAR_ADMIN = "CLEAR_ADMIN";

export const fetchAdmin = (id) => ({
  type: FETCH_ADMIN,
  id,
});

export const fetchAdminSuccess = (data) => ({
  type: FETCH_ADMIN_SUCCESS,
  data,
});

export const fetchAdminFailure = () => ({
  type: FETCH_ADMIN_FAILURE,
});

export const clearAdmin = () => ({
  type: CLEAR_ADMIN,
});

export const getAdminDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchAdmin());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/user/admin/", { id: id }, token)
          .then((res) => {
            dispatch(fetchAdminSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchAdminFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
