
import React, { useState } from "react";
import { Input, Message,Icon } from "semantic-ui-react";

const RenderPasswordInput = (props, { ...form }) => {
    const [ visible, setVisible ] = useState(false);
    return (
        <div style={{ marginBottom: 20, lineHeight: 40 }}>
        <Input
            onChange={props.onChange}
            placeholder={props.placeholder}
            type={visible === false ? props.type : 'text'}
            fluid
            icon
            size="small"
            value={props.value}
            min={props.min}
            step={props.step}
            maxLength={props.maxLength}
            max={props.max}
            disabled={props.disabled}
            className={props.styles && "range_input"}
        >
            <input/>
            <Icon name={visible === true ? "eye slash":"eye"} size="large" onClick={(event)=>setVisible(!visible)} link style={{
                color:'#4075FF',
            }}/>
            </Input>
        {props.isError && props.isTouched && (
            <Message visible error content={props.isError} />
        )}
        </div>
    );
};

export default RenderPasswordInput;
