import React, { useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import Navigation from "./components/Navigation";
import { Loader, Dimmer } from "semantic-ui-react";
import { Route, Switch } from "react-router";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import {
  DASHBOARD_ROUTE,
  ACTIVITY_ROUTE,
  USERMANAGEMENT_ROUTE,
  ATTENDANCE_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  PASSWORD_RESET_ROUTE,
  MERCHANDISE_ROUTE,
  FORM_ROUTE,
  DONATION_ROUTE,
  NOTIFICATION_ROUTE,
  CALENDAR_ROUTE,
  COMMS_ROUTE,
  PAYMENT_FAILED_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
  FORM_EDIT_ROUTE,
  ERROR_ROUTE,
  APP_MAINTENANCE_ROUTE,
  NEWS_FEED_ROUTE,
  CHANGE_PASSWORD,
} from "./routes";
import Dashboard from "./views/dashboard";
import AppMaintenance from "./views/appmaintenance";
import Activity from "./views/activity";
import UserManagement from "./views/user_management";
import Attendance from "./views/attendance";
import Login from "./views/auth/Login";
import SignUp from "./views/auth/SignUp";
import PasswordReset from "./views/auth/PasswordReset";
import NoPageFound from "./views/error/NoPageFound";
import { handleLogout } from "./store/actions/";
import Merchandise from "./views/merchandise";
import Notification from "./views/notification";
import { firebase } from "./config/firebase";
import Forms from "./views/forms";
import Donation from "./views/donation";
import Calendar from "./views/calendar";
import Comms from "./views/comms";
import PaySuccess from "./views/error/Success";
import PayError from "./views/error/Failed";
import FormEdit from "./views/forms/FormEdit";
import { messaging } from "./config/firebase";
import Condtion from "./views/error/condtion";
import Newsfeed from "./views/newsfeed";
import ChangePasswordScreen from "./views/auth/ChangePassword";

function App(props) {
  let location = useLocation();
  let selected = location.pathname.split("/")[1];
  const { auth, dispatch } = props;
  const [islogOut, setislogOut] = React.useState(false);

  let isLogout = localStorage.getItem("isLogout");

  if (!auth.isEmpty && isLogout === "false") {
    return (
      <div>
        <Navigation selected={selected} userid={auth.uid}>
          <Switch>
            <Route exact={true} path={DASHBOARD_ROUTE} component={Dashboard} />
            <Route path={ACTIVITY_ROUTE} component={Activity} />
            <Route path={USERMANAGEMENT_ROUTE} component={UserManagement} />
            <Route path={ATTENDANCE_ROUTE} component={Attendance} />
            <Route path={MERCHANDISE_ROUTE} component={Merchandise} />
            <Route path={NOTIFICATION_ROUTE} component={Notification} />
            <Route path={FORM_ROUTE} component={Forms} />
            <Route
              path={DONATION_ROUTE}
              component={Donation}
              userid={auth.uid}
            />
            <Route path={CALENDAR_ROUTE} component={Calendar} />
            <Route path={COMMS_ROUTE} component={Comms} />
            <Route exact path={ERROR_ROUTE} component={Condtion} />
            <Route path={PAYMENT_SUCCESS_ROUTE} component={PaySuccess} />
            <Route path={PAYMENT_FAILED_ROUTE} component={PayError} />
            <Route path={APP_MAINTENANCE_ROUTE} component={AppMaintenance} />
            <Route path={NEWS_FEED_ROUTE} component={Newsfeed} />
            <Route component={NoPageFound} />
          </Switch>
        </Navigation>
      </div>
    );
  } else {
    return (
      <div>
        <Route exact={true} path={LOGIN_ROUTE} component={Login} />
        <Route path={SIGNUP_ROUTE} exact strict component={SignUp} />
        <Route
          path={PASSWORD_RESET_ROUTE}
          exact
          strict
          component={PasswordReset}
        />
        <Route exact path={CHANGE_PASSWORD} component={ChangePasswordScreen} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  userInfo: state.getLoginUserState.user_info,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  handleLogout: () => {
    dispatch(handleLogout());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
