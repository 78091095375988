import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const FETCH_DASHBOARD_NOTI = "FETCH_DASHBOARD_NOTI";
export const FETCH_DASHBOARD_NOTI_SUCCESS = "FETCH_DASHBOARD_NOTI_SUCCESS";
export const FETCH_DASHBOARD_NOTI_FAILURE = "FETCH_DASHBOARD_NOTI_FAILURE";

export const fetchDashboardNoti = () => ({
  type: FETCH_DASHBOARD_NOTI,
});

export const fetchDashboardNotiSuccess = (data) => ({
  type: FETCH_DASHBOARD_NOTI_SUCCESS,
  data,
});

export const fetchDashboardNotiFailure = (err) => ({
  type: FETCH_DASHBOARD_NOTI_FAILURE,
  err,
});

export const getDashboardNoti = (start_date, end_date, centre_id, class_id) => {
  return (dispatch) => {
    dispatch(fetchDashboardNoti());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/notification/",
            {
              start_date: start_date,
              end_date: end_date,
              role: "admin",
              centre_id: centre_id,
              size: 5,
            },
            token
          )
          .then((res) => {
            dispatch(fetchDashboardNotiSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchDashboardNotiFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
