import api from "../../config/api";
import { firebase } from "../../config/firebase";
import { getDashboardNoti } from "./dashboard_noti";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const CLEAR_USER = "CLEAR_USER";

export const fetchUser = (id) => ({
  type: FETCH_USER,
  id,
});

export const fetchUserSuccess = (data) => ({
  type: FETCH_USER_SUCCESS,
  data,
});

export const fetchUserFailure = () => ({
  type: FETCH_USER_FAILURE,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

export const getUser = (id, selectedusrtype) => {
  return (dispatch) => {
    dispatch(fetchUser());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(`/user/${"" + selectedusrtype}/`, { id: id }, token)
          .then((res) => {
            // console.log("doing", res);
            dispatch(fetchUserSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchUserFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
