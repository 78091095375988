import React from "react";
import Background from "../../assets/images/access_denied.png";

function AccessDeniedPage() {
  return (
    <div className="full-container">
      <div className="fw600 fs36 text-notFound">Access Denied</div>
      <div className="mt23" style={{ maxWidth: 779, maxHeight: 382 }}>
        <img
          src={Background}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
}

export default AccessDeniedPage;
