import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const APP_MAINTENANCE = "APP_MAINTENANCE";
export const APP_MAINTENANCE_SUCCESS = "APP_MAINTENANCE_SUCCESS";
export const APP_MAINTENANCE_FAILURE = "APP_MAINTENANCE_FAILURE";

export const postAppMaintenance = (maintenance_mode) => ({
  type: APP_MAINTENANCE,maintenance_mode:maintenance_mode,
});

export const postAppMaintenanceSuccess = (res) => ({
  type: APP_MAINTENANCE_SUCCESS,
  data:res,
});

export const postAppMaintenanceFailure = () => ({
  type: APP_MAINTENANCE_FAILURE,
});

export const postAppMaintenances = (maintenance_mode) => {
  return (dispatch) => {
    dispatch(postAppMaintenance(maintenance_mode));
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        let m_mode = maintenance_mode === true ? "ON" :"OFF";
        let formdata = new FormData();
        formdata.append("maintenance_mode",m_mode);
        console.log(formdata);
        // https://asia-southeast2-morningstar-dev.cloudfunctions.net/system/maintenance_mode
        api
          .post(`/system/maintenance_mode`,formdata, token)
          .then((res) => {
            console.log("doing", res);
            dispatch(postAppMaintenanceSuccess(res));
            return res;
          })
          .catch((err) => {
            dispatch(postAppMaintenanceFailure());
            console.log("error", err);
          });
      } catch (err) {
        console.log(err);
      }
    });
  };
};
