import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from "../actions";

const initialState = {
  users: [],
  loading: false,
  err: null,
  haveData: false,
  usersNextpage: "",
  usersTotalPage: null,
};

export const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        user: action.data,
        usersNextpage: action.data.next,
        usersTotalPage: action.data.total_pages,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: true,
        user: [],
      };
    default:
      return state;
  }
};
