import { FETCH_USER_LOGIN,FETCH_USER_LOGIN_FAILURE,FETCH_USER_LOGIN_SUCCESS,CLEAR_USER_LOGIN } from '../actions/getLoginUser';

const initialState = {
  user_info: null,
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  //console.log(action)
  switch(action.type) {
    case FETCH_USER_LOGIN:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        user_info: action.data
      };
    case FETCH_USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        user_info: {}
      };
    case CLEAR_USER_LOGIN:
      return initialState;
    default:
      return state;
  }
}