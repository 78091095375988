import React, { Component } from "react";
import { Grid, Loader, Dimmer } from "semantic-ui-react";
import { connect } from "react-redux";
import { USERMANAGEMENT_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import Userfrom from "./UserForm";
import { getUser, clearUser, getCentersClasses } from "../../store/actions";
import RenderLoader from "../../components/Loader";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";

class UserEdit extends Component {
  componentDidMount() {
    this.props.getUser();
    this.props.getCentersClasses();
  }

  state = {
    previewUrl: "",
    imagefile: null,
    isloading: false,
    radioList: [
      {
        value: "centre",
        label: "Center Admin",
      },
      {
        value: "facilitator",
        label: "Facilitator",
      },
      {
        value: "parent",
        label: "Parent",
      },
      {
        value: "student",
        label: "Student",
      },
    ],
    imgError: false,
    isSubmitProcessing: false,
  };

  handleSubmit = (values) => {
    this.setState({ isSubmitProcessing: true });
    values.image = this.state.previewUrl;
    let user_type = localStorage.getItem("user_type");
    var arr = [];
    var arrfac = [];

    if (Array.isArray(values.children_id)) {
      values.children_id.map((v) => arr.push(v.value));
    }
    if (Array.isArray(values.facilitor_id)) {
      values.facilitor_id.map((v) => arrfac.push(v.value));
    }

    this.setState({
      isloading: true,
      hasError: false,
    });

    if (values.img == null) {
      return this.setState({ imgError: true, isSubmitProcessing: false });
    }

    this.setState({ imgError: false });
    //console.log(this.state.imagefile);
    let formdata = new FormData();
    formdata.append("id", this.props.match.params.id);
    formdata.append("name", values.name);
    user_type !== "parent" && formdata.append("centre_id", values.centre_id);

    user_type !== "student" && formdata.append("phone", values.phone);
    formdata.append(
      "img",
      this.state.imagefile != null ? this.state.imagefile : values.img
    );
    user_type !== "student" && formdata.append("email", values.email);
    (user_type === "admin" || user_type === "facilitator") &&
      formdata.append("employee_no", values.employee_no);
    user_type === "facilitator" &&
      formdata.append("class_id", JSON.stringify(arrfac));

    user_type !== "student" && formdata.append("class_id", values.class_id);
    user_type === "student" && formdata.append("class_id", values.class_id);
    user_type === "admin" &&
      formdata.append(
        "admin_type",
        values.centre_id === "all" ? "hq" : values.admin_type
      );

    // user_type === "parent" &&
    //   formdata.append("relationship", values.relationship);
    // user_type === "parent" &&
    //   formdata.append("children_id", values.children_id);
    user_type === "parent" &&
      formdata.append("children_id", JSON.stringify(arr));

    // user_type === "parent"  &&
    // formdata.append("scfa", values.scfa === "true" ? 1 : 0);
    user_type === "student" && formdata.append("school", values.school);
    user_type === "student" &&
      formdata.append("dob", localStorage.getItem("dob"));
    // user_type === "student" && formdata.append("student_no", values.student_no);
    user_type === "student" &&
      formdata.append(
        "scfa",
        values.scfa === "true" ? 1 : values.scfa === 1 ? 1 : 0
      );
    return firebase.reloadAuth().then((res) => {
      try {
        //this.setState({ isloading: true });
        const token = res.ya === null ? res.za : res.ya;

        api.post("/user/" + user_type + "/", formdata, token).then((res) => {
          if (res.message === "Success") {
            this.setState({ isSubmitProcessing: false });
            this.setState({ isloading: false });
            this.props.clearUser(this.props.match.params.id);
            this.props.dispatch(push(USERMANAGEMENT_ROUTE));
            localStorage.removeItem("id");
          } else {
            alert(`${res.message}`);
            this.setState({ isSubmitProcessing: false });
            this.setState({ isloading: false });
          }
        });
      } catch (err) {
        this.setState({ isSubmitProcessing: false });
        console.log(err);
      }
    });
  };

  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        imagefile: file,
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const {
      userDetail = {},
      centerclasses = [],
      haveData,
      loading,
    } = this.props;
    let centers = [],
      classes = [],
      goldObj = {};
    centerclasses.map((data) => {
      centers.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });

    localStorage.setItem("centre_list", JSON.stringify(centerclasses));
    goldObj = centerclasses.find(
      (result) => result.id === localStorage.getItem("centre_Id")
    );

    goldObj !== undefined &&
      goldObj.class.map((data) => {
        classes.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });

    return (
      <Grid className="fullHeight">
        <Dimmer active={loading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        {haveData && (
          <Grid.Row>
            <Grid.Column width={16}>
              <RoutingButton
                to={USERMANAGEMENT_ROUTE}
                className="backBtn"
                btnText="Back"
                showIcon={true}
                clearUser={() => clearUser(this.props.match.params.id)}
              />
              <Userfrom
                isSubmitProcessing={this.state.isSubmitProcessing}
                handleSubmit={this.handleSubmit}
                options={this.state.radioList}
                onChange={this.onChange}
                previewUrl={this.state.previewUrl}
                formTitle={"Edit User"}
                initialValues={userDetail}
                centers={centers}
                isEdit={true}
                classes={classes}
                imgError={this.state.imgError}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.user.user.data,
  loading: state.user.loading,
  haveError: state.user.err,
  haveData: state.user.haveData,
  centerclasses: state.centersClasses.centers_classes.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getUser: (id, user_type) =>
    dispatch(getUser(props.match.params.id, props.match.params.user_type)),
  clearUser: (id) => dispatch(clearUser(id)),
  getCentersClasses: () => dispatch(getCentersClasses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
