import React, { Component } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { NOTIFICATION_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import NotificationFrom from "./NotificationForm";
import { getCentersClasses } from "../../store/actions";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import api from "../../config/api";

class NotificationCreate extends Component {
  state = {
    isloading: false,
    radioList: [
      {
        value: "admin",
        label: "Admin",
      },
      {
        value: "facilitator",
        label: "Facilitator",
      },
      {
        value: "parent",
        label: "Parent",
      },
    ],
    attachmentFile: null,
    attachmentFileName: "",
    errors: {
      FileTypeError: false,
    }
  };
  componentDidMount() {
    this.props.getCentersClasses();
  }
  onChangePdf = (event) => {
    const file = event.target.files[0];
    
    if (file){
      const fileExtenstion = file.name.split(".")[1]
      console.log(fileExtenstion);
      if( fileExtenstion === "pdf"){
        this.setState({ attachmentFile: file, attachmentFileName: file.name,errors: { FileTypeError: false } });  
      }else this.setState({ attachmentFile: file, attachmentFileName: file.name,errors: { FileTypeError: true }});  
    }
    else this.setState({ attachmentFile: null, attachmentFileName: "" });
  };
  onRemovePdf = () => {
    this.setState({ attachmentFile: null, attachmentFileName: "", errors: {FileTypeError: false }});
  };
  IsError = () => {
    let errorCount = 0
    if(this.state.errors.FileTypeError) errorCount++;
    return errorCount>0
  }
  handleSubmit = (values) => {
    const receiver = values.receiver.map((item, index) => {
      if (item.centre_id === "all") {
        if (item.class_id.length === 0 || item.role.length === 0) return null;

        let classes = [];
        item.class_id.forEach((innerItem1) => {
          this.props.centerclasses.forEach((innerItem2) => {
            innerItem2.class.forEach((innerItem3) => {
              if (innerItem3.name.toLowerCase() === innerItem1.toLowerCase()) {
                classes.push(innerItem3.id);
              }
            });
          });
        });
        return {
          centre_id: [item.centre_id],
          class_id: classes,
          role: item.role,
        };
      } else if (
        item.centre_id.length === 0 ||
        item.class_id.length === 0 ||
        item.role.length === 0
      ) {
        return null;
      }

      return {
        centre_id: [item.centre_id],
        class_id: item.class_id,
        role: item.role,
      };
    });

    // Check receiver not to empty
    let receiverRequired = false;
    receiver.forEach((item) => {
      if (item === null) receiverRequired = true;
    });
    if (receiverRequired) return alert("Please fill in all required fields");
    let is_error = this.IsError();
    if (is_error) return 
    let formdata = new FormData();
    formdata.append("title", values.title);
    formdata.append("message", values.message);
    // formdata.append("receiver", JSON.stringify(values.receiver));
    formdata.append("receiver", JSON.stringify(receiver));
    if (this.state.attachmentFile !== null)
      formdata.append("attachment", this.state.attachmentFile);
    return firebase.reloadAuth().then((res) => {
      try {
        this.setState({ isloading: true });
        const token = res.ya === null ? res.za : res.ya;

        api.post("/notification/", formdata, token).then((res) => {
          console.log("response", res);
          //{"message": "name, centre_id, admin_type, email, phone, img, employee_no are required.", "data": []}
          if (res.message === "Success") {
            this.setState({ isloading: false });
            this.props.dispatch(push(NOTIFICATION_ROUTE));
          } else {
            this.setState({ isloading: false });
            // alert(`${res.message}`);
            console.error(res.message);
            alert("Please fill in all required fields");
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };
  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { userDetail } = this.props;
    // console.log("Noti Create ... ", new Date().toLocaleTimeString());
    // console.log(userDetail);
    const { centerclasses = [], isloading } = this.props;
    let user_type = localStorage.getItem("user_type");
    let centers = [];
    //activeCenterObj = {},
    //activeCenterId = "";

    if (localStorage.getItem("adminType") === "centre") {
      let d = centerclasses.find(
        (result) => result.id === localStorage.getItem("centre_Id")
      );

      if (d) {
        centers.push({
          key: d.id,
          text: d.name,
          value: d.id,
        });
      }
    } else {
      centerclasses.map((data) => {
        centers.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
    }
    // centerclasses.map((data) => {
    //   centers.push({
    //     key: data.id,
    //     text: data.name,
    //     value: data.id,
    //   });
    // });
    // if (userDetail && userDetail.admin_type === "centre") {
    // }
    localStorage.setItem("centre_list", JSON.stringify(centerclasses));
    return (
      <Grid className="fullHeight">
        <Dimmer active={isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={NOTIFICATION_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <NotificationFrom
              handleSubmit={this.handleSubmit}
              onChangePdf={this.onChangePdf}
              onRemovePdf={this.onRemovePdf}
              attachmentFileName={this.state.attachmentFileName}
              options={this.state.radioList}
              onChange={this.onChange}
              centers={centers}
              isloading={this.state.isloading}
              admin={this.state.radioList}
              previewUrl={this.state.previewUrl}
              formTitle={"Add Notification"}
              centerclasses={centerclasses}
              userDetail={userDetail}
              errors= {this.state.errors}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  centerclasses: state.centersClasses.centers_classes.data,
  usersList: state.users.user !== undefined ? state.users.user.data : [],
  haveData: state.centersClasses.haveData,
  isloading: state.centersClasses.loading,
  userDetail: state.user.user.data,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCreate);
