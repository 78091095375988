import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import {
  MERCHANDISE_ROUTE,
  MERCHANDISE_CREATE_ROUTE,
  MERCHANDISE_DETAIL_ROUTE,
  MERCHANDISE_EDIT_ROUTE,
  MERCHANDISE_TYPE_CREATE_ROUTE,
} from "../../routes";
import MerchandiseList from "./MerchandiseList";
import MerchandiseCreate from "./MerchandiseCreate";
import MerchandiseDetail from "./MerchandiseDetail";
import MerchandiseEdit from "./MerchandiseEdit";
import MerchandiseTypeCreate from "./MerchandiseTypeCreate";
import { firebase } from "../../config/firebase";
import { getCurrentLoginUser } from "../../store/actions";
import { connect } from "react-redux";
import AccessDeniedPage from "../error/AccessDeniedPage";

export const Merchandise = (props) => {
  const [isAccess, setIsAccess] = useState(true);
  useEffect(() => {
    firebase.reloadAuth().then((res) => {
      props.getCurrentLoginUser(res.uid, "admin");
      if (props.userDetail.admin_type !== "hq") {
        setIsAccess(false);
      } else {
        setIsAccess(true);
      }
    });
  }, []);

  if (isAccess) {
    return (
      <React.Fragment>
        <Route exact path={MERCHANDISE_ROUTE} component={MerchandiseList} />
        <Route
          exact
          path={MERCHANDISE_CREATE_ROUTE}
          component={MerchandiseCreate}
        />
        <Route
          exact
          path={`${MERCHANDISE_DETAIL_ROUTE}/:id`}
          component={MerchandiseDetail}
        />
        <Route
          exact
          path={`${MERCHANDISE_EDIT_ROUTE}/:id`}
          component={MerchandiseEdit}
        />
        <Route
          exact
          path={MERCHANDISE_TYPE_CREATE_ROUTE}
          component={MerchandiseTypeCreate}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Route exact path={MERCHANDISE_ROUTE} component={AccessDeniedPage} />
        <Route
          exact
          path={MERCHANDISE_CREATE_ROUTE}
          component={AccessDeniedPage}
        />
        <Route
          exact
          path={`${MERCHANDISE_DETAIL_ROUTE}/:id`}
          component={AccessDeniedPage}
        />
        <Route
          exact
          path={`${MERCHANDISE_EDIT_ROUTE}/:id`}
          component={AccessDeniedPage}
        />
        <Route
          exact
          path={MERCHANDISE_TYPE_CREATE_ROUTE}
          component={AccessDeniedPage}
        />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => ({
  userDetail: state.currentLoginUser.currentLoginUser.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchandise);
