import {
    FETCH_DASHBOARD_ACTIVITY,
    FETCH_DASHBOARD_ACTIVITY_SUCCESS,
    FETCH_DASHBOARD_ACTIVITY_FAILUTE,
  } from "../actions";
  
  const initialState = {
    dashboardActivity: [],
    loading: true,
    err: null,
    haveData: false,
  };
  
  export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DASHBOARD_ACTIVITY:
        return {
          ...state,
          loading: true,
          err: null,
          haveData: false,
        };
      case FETCH_DASHBOARD_ACTIVITY_SUCCESS:
        return {
          ...state,
          loading: false,
          haveData: true,
          dashboardActivity: action.data,
        };
      case FETCH_DASHBOARD_ACTIVITY_FAILUTE:
        return {
          ...state,
          loading: false,
          haveData: false,
          err: action.error,
          dashboardActivity: [],
        };
      default:
        return state;
    }
  };
  