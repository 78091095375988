import {
    APP_MAINTENANCE,
    APP_MAINTENANCE_SUCCESS,
    APP_MAINTENANCE_FAILURE,
    
  } from "../actions/app_maintenance";

import {
    GET_APP_MAINTENANCE,
    GET_APP_MAINTENANCE_SUCCESS,
    GET_APP_MAINTENANCE_FAILURE,
} from '../actions/get_maintenance_mode';
  
  const initialState = {
    maintenance_mode: false,
    loading: true,
    err: null,
    haveData: false,
  };
  
  export const reducer = (state = initialState, action) => {
    //console.log(action)
    switch (action.type) {
      case APP_MAINTENANCE:
        return {
          ...state,
          loading: true,
          err: null,
          haveData: false,
          maintenance_mode:action.maintenance_mode,
        };
      case APP_MAINTENANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          haveData: true,
          maintenance_mode: action.data.data.maintenance_mode === "ON" ? true : false,
        };
      case APP_MAINTENANCE_FAILURE:
        return {
          ...state,
          loading: false,
          haveData: false,
          err: action.error,
          maintenance_mode: false,
        };

    case GET_APP_MAINTENANCE:
        return {
          ...state,
          loading: true,
          err: null,
          haveData: false,
        };
      case GET_APP_MAINTENANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          haveData: true,
          maintenance_mode: action.data.data.maintenance_mode === "ON" ? true : false,
        };
      case GET_APP_MAINTENANCE_FAILURE:
        return {
          ...state,
          loading: false,
          haveData: false,
          err: action.error,
          maintenance_mode: false,
        };
      default:
        return state;
    }
  };
  