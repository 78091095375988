import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_SCHEDULES_CALENDAR_VIEW = "FETCH_SCHEDULES_CALENDAR_VIEW";
export const FETCH_SCHEDULES_CALENDAR_VIEW_SUCCESS =
  "FETCH_SCHEDULES_CALENDAR_VIEW_SUCCESS";
export const FETCH_SCHEDULES_CALENDAR_VIEW_FAILURE =
  "FETCH_SCHEDULES_CALENDAR_VIEW_FAILURE";

export const FETCH_SMALL_SCHEDULES_CALENDAR_VIEW =
  "FETCH_SMALL_SCHEDULES_CALENDAR_VIEW";
export const FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_SUCCESS =
  "FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_SUCCESS";
export const FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_FAILURE =
  "FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_FAILURE";

export const fetchSchedulesCalendarView = () => ({
  type: FETCH_SCHEDULES_CALENDAR_VIEW,
});

export const fetchSchedulesCalendarViewSuccess = (data) => ({
  type: FETCH_SCHEDULES_CALENDAR_VIEW_SUCCESS,
  data,
});

export const fetchSchedulesCalendarViewFailure = (err) => ({
  type: FETCH_SCHEDULES_CALENDAR_VIEW_FAILURE,
  err,
});

export const getSchedulesCalendarView = (
  from_date,
  to_date,
  centre_id,
  class_id,
  scfa
) => {
  return (dispatch) => {
    dispatch(fetchSchedulesCalendarView());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/schedules/all_calendar_views/",
            {
              from_date: "" + from_date,
              to_date: "" + to_date,
              centre_id: "" + centre_id,
              class_id: "" + class_id,
              scfa: "" + scfa,
            },
            token
          )
          .then((res) => {
            dispatch(fetchSchedulesCalendarViewSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchSchedulesCalendarViewFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};

export const fetchSmallSchedulesCalendarView = () => ({
  type: FETCH_SMALL_SCHEDULES_CALENDAR_VIEW,
});

export const fetchSmallSchedulesCalendarViewSuccess = (data) => ({
  type: FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_SUCCESS,
  data,
});

export const fetchSmallSchedulesCalendarViewFailure = (err) => ({
  type: FETCH_SMALL_SCHEDULES_CALENDAR_VIEW_FAILURE,
  err,
});

export const getSmallSchedulesCalendarView = (
  from_date,
  to_date,
  centre_id,
  class_id,
  scfa
) => {
  return (dispatch) => {
    console.log("from_date", from_date, "  to_date,", to_date);
    dispatch(fetchSmallSchedulesCalendarView());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/schedules/all_calendar_views/",
            {
              from_date: "" + from_date,
              to_date: "" + to_date,
              centre_id: "" + centre_id,
              class_id: "" + class_id,
              scfa: "" + scfa,
            },
            token
          )
          .then((res) => {
            dispatch(fetchSmallSchedulesCalendarViewSuccess(res));
            console.log("hmm", res);
            return res;
          })
          .catch((err) =>
            dispatch(fetchSmallSchedulesCalendarViewFailure(err))
          );
      } catch (err) {
        console.log(err);
      }
    });
  };
};
