import {
  FETCH_ATTENDANCE_DATA,
  FETCH_ATTENDANCE_DATA_SUCCESS,
  FETCH_ATTENDANCE_DATA_FAILURE,
} from "../actions";

const initialState = {
  attendance_Data: [],
  loading: true,
  err: null,
  haveData: false,
  attendancePercentage: "",
  attendanceTotalPage: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_DATA:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
        attendance_Data: [],
      };
    case FETCH_ATTENDANCE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        attendance_Data: action.data,
        attendancePercentage: action.data.attendance,
        attendanceTotalPage: action.data.total_pages,
      };
    case FETCH_ATTENDANCE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        attendance_Data: [],
      };
    default:
      return state;
  }
};
