import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import {
  Grid,
  Header,
  Image,
  Segment,
  Dropdown,
  Message,
  Dimmer,
  Loader,
  Button,
  Modal,
} from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import RenderInput from "../../components/TextInput";
// import RenderDropDown from '../../components/DropDown';
import { connect } from "react-redux";
import usr from "../../assets/images/usr.png";
// import ic_phone from '../../assets/images/ic_phone.svg';
import ic_image from "../../assets/images/ic_image.svg";
import ic_Group99 from "../../assets/images/send_ic.png";
import {
  getCentersClasses,
  getUser,
  getUsers,
  getMessage,
  getRoomlist,
  getUsersData,
  addMessage,
  readCommsAllMessage,
  refreshMessage,
  searchUser,
  getCurrentLoginUser,
} from "../../store/actions";
import { usersType } from "../custom.js";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import CommsImage from "../../components/CImage";
import { db } from "../../config/firebase";
import moment from "moment";
import { remove } from "lodash-es";
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from "react-infinite-scroll-component";
import No_Message from "../../assets/images/no_message.svg";
import New_Message from "../../assets/images/new_message.svg";
import Modal_Exit from "../../assets/images/modal_exit.svg";
import No_Chat from "../../assets/images/no_chat.svg";
import { TimeGrid } from "react-big-calendar";
import ReactPlayer from "react-player/lazy";
import S3 from "react-aws-s3";
import base64encode from "@wmakeev/base64encode";
import VideoPlayerIcon from "../../assets/images/video_play.svg";
import CrossImgIcon from "../../assets/images/cross.png";
import adminicon from "../../assets/images/ic_Admin.svg";
import parenticon from "../../assets/images/ic_Parents.svg";
import facilitatoricon from "../../assets/images/ic_Facilitator.svg";
import studenticon from "../../assets/images/ic_Students.svg";

const configS3 = {
  bucketName: "morning-star-dev",
  dirName: "comms-videos",
  region: "ap-southeast-1",
  //DEV
  // accessKeyId: "AKIAQFC7EOAWNZBPI7M6",
  // secretAccessKey: "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",
  //QA
  // accessKeyId: "AKIAQFC7EOAWMK5PYOKQ",
  // secretAccessKey: "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",
  accessKeyId: "AKIAQFC7EOAWH7DDHKEY",
  secretAccessKey: "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",
};

const initialState = {
  Modal: false,
  filtersUsers: [],
  usercount: [],
  centerList: [],
  centername: "",
  class: [],
  is_active: 0,
  isloading: false,
  haveData: false,
  isitemUpdateloading: false,
  pickedUsrId: "",
  centre_id: "",
  class_id: "",
  user_type: "admin",
  show_user_type: "",
  user_img: "",
  receiverid: "whsy1sOH05QCGjZY7JKm",
  receivername: "",
  sender: "",
  selected: "",
  imgs: null,
  previewUrl: ic_image,
  chat_date: [],
  size: 10,
  sendMessageLoading: false,
  selectUser: false,
  modalToggle: false,
  selectedImageModal: null,
  people_list_modal: false,
  serach: "",
  chatShow: false,
  videoUrl: null,
  video: null,
  isVideo: false,
  checkValueThumb: false,
  room_2Id: null,
  room_id: null,
  search: "",
};

class CommsList extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.sorted = [];
  }

  messagesEndRef = React.createRef();

  componentDidMount() {
    localStorage.removeItem("room_id");
    localStorage.setItem("commsPage", true);
    this.setState({
      room_2Id: null,
    });
    localStorage.removeItem("room_id");
    this.props.refreshMessage();
    this.setState({
      selectUser: false,
      isVideo: false,
      checkValueThumb: false,
    });
    firebase
      .reloadAuth()
      .then((data) => {
        this.setState({ sender: data.uid });
        this.props.getRoomlist(localStorage.getItem("user_Id"), true);
        // this.props.getCurrentLoginUser(data.uid, "admin");
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.getCentersClasses();
    if (localStorage.getItem("adminType") == "centre") {
      this.props.getUsers(
        this.state.user_type,
        localStorage.getItem("centre_Id"),
        this.state.class_id,
        true
      );
    } else {
      this.props.getUsers(
        this.state.user_type,
        this.state.centre_id,
        this.state.class_id,
        true
      );
    }

    if (localStorage.getItem("adminType") === "centre") {
      this.setState(
        {
          // centre_id: localStorage.getItem("admin_centre_id"),
          centre_id: localStorage.getItem("centre_Id"),
          admin_type: localStorage.getItem("adminType"),
        },
        () => {
          this.props.getUsersData(
            this.state.user_type,
            this.state.centre_id,
            this.state.class_id,
            this.state.admin_type,
            false,
            null
          );
        }
      );
    } else {
      this.setState({ admin_type: localStorage.getItem("adminType") });
      this.props.getUsersData(
        this.state.user_type,
        this.state.centre_id,
        this.state.class_id,
        localStorage.getItem("adminType"),
        false,
        null
      );
    }

    // this.props.getMessage(
    //   this.state.receiverid,
    //   this.state.sender,
    //   10,
    //   false,
    //   "",
    //   true
    // );
    db.collection("comms")
      .where("receiver", "==", localStorage.getItem("user_Id"))
      .onSnapshot((snapshot) => {
        this.props.getRoomlist(localStorage.getItem("user_Id"), false);
      });

    this.scrollToBottom();
  }
  componentWillUnmount() {
    localStorage.removeItem("room_id");
    this.props.getRoomlist("", true);
    this.props.refreshMessage();
    localStorage.removeItem("room_id");
    localStorage.removeItem("commsPage");

    this.setState({ chatShow: false, room_2Id: null, room_id: null });
  }
  componentDidUpdate() {
    //  this.scrollToBottom();
  }

  scrollToBottom = () => {
    // this.messagesEndRef.current.scrollIntoView();
    if (this.messagesEndRef && this.messagesEndRef.current)
      this.messagesEndRef.current.scrollIntoView();
  };

  handleOnChange = (val) => {
    console.log(val);
  };

  ChooseCentre = (e, res) => {
    this.scrollToBottom();
    let class_id;
    this.setState(
      {
        [res.name]: res.value,
      },
      () => {
        if (localStorage.getItem("adminType") == "centre") {
          this.props.getUsers(
            this.state.user_type,
            localStorage.getItem("centre_Id"),
            this.state.class_id,
            true
          );
          this.props.getUsersData(
            this.state.user_type,
            localStorage.getItem("centre_Id"),
            this.state.user_type === "admin" ? "" : this.state.class_id,
            this.state.admin_type,
            false,
            null
          );
        } else {
          this.props.getUsers(
            this.state.user_type,
            this.state.centre_id,
            this.state.class_id,
            true
          );
          this.props.getUsersData(
            this.state.user_type,
            this.state.centre_id,
            this.state.user_type === "admin" ? "" : this.state.class_id,
            this.state.admin_type,
            false,
            null
          );
        }
      }
    );
  };

  validURL = (str) => {
    if (str !== undefined) {
      if (str.match("https://")) {
        return true;
      } else {
        return false;
      }
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleChat(id, name, img, relationship, room_id) {
    this.setState(
      {
        receiverid: id,
        receivername: name,
        selected: id,
        show_user_type: this.state.user_type,
        user_img: img,
        selectUser: true,
        people_list_modal: false,
        chatShow: true,
        video: null,
        isVideo: false,
        imgs: null,
        previewUrl: ic_image,
        user_relation: relationship,
        room_id,
      },
      () => {
        this.props.getMessage(
          this.state.receiverid,
          this.state.sender,
          10,
          false,
          "",
          true
        );

        db.collection("comms")
          .where("receiver", "==", this.state.sender)
          .where("sender", "==", this.state.receiverid)
          .orderBy("created_at", "desc")
          .limit(1)
          .onSnapshot((shot) => {
            if (this.state.chatShow) {
              this.props.getMessage(
                this.state.receiverid,
                this.state.sender,
                10,
                false,
                "",
                false
              );
              if (localStorage.getItem("commsPage")) {
                this.props.readCommsAllMessage(
                  this.state.room_id,
                  localStorage.getItem("user_Id")
                );
              }
            }
          });

        // firebase.reloadAuth().then((res) => {
        //   const token = res.ya === null ? res.za : res.ya;

        //   api
        //     .get(
        //       "/comms",
        //       {
        //         receiver: this.state.receiverid,
        //         sender: this.state.sender,
        //         size: 1,
        //       },
        //       token
        //     )
        //     .then((res) => {
        //       this.props.readCommsAllMessage(
        //         res.room_id,
        //         localStorage.getItem("user_Id")
        //       );
        //       this.props.getRoomlist(localStorage.getItem("user_Id"), false);
        //     })
        //     .catch((err) => console.log(">>>", err));
        // });

        // this.props.getMessage(this.state.receiverid, this.state.sender, "10");
      }
    );
  }

  attach(event) {
    let reader = new FileReader();
    // video/mp4
    // image/
    let file = event.target.files[0];
    const fileExtension = file.name.slice(
      file.name.length - 3,
      file.name.length
    );
    const videoExtensions = [
      "3gp",
      "wmv",
      "mov",
      "mp4",
      "avi",
      "flv",
      "mkv",
      "ogg",
    ];

    if (fileExtension === "mp4") {
      // if (videoExtensions.find((item) => item === fileExtension)) {
      // if (file.type.includes("video/")) {
      reader.onloadend = () => {
        this.setState({ videoUrl: reader.result, isVideo: true });
      };

      reader.readAsDataURL(file);
      this.setState({
        video: event.target.files[0],
        imgs: null,
        checkValueThumb: true,
      });
      event.target.value = null;
    } else if (file.type.includes("image/")) {
      reader.onloadend = () => {
        this.setState({
          previewUrl: reader.result,
          isVideo: false,
          video: null,
        });
      };

      reader.readAsDataURL(file);
      this.setState({
        imgs: event.target.files[0],
        video: null,
        checkValueThumb: true,
      });
      event.target.value = null;
    } else {
      alert("Only allow for .mp4 file");
      this.setState({
        imgs: null,
        video: null,
        checkValueThumb: false,
        isVideo: false,
        previewUrl: ic_image,
      });
    }
  }

  handleSubmit = (values, { resetForm }) => {
    this.props.readCommsAllMessage(
      localStorage.getItem("room_id"),
      localStorage.getItem("user_Id")
    );

    if (
      this.state.imgs == null &&
      values.message === "" &&
      this.state.video === null
    ) {
      alert("Type something");
    } else {
      this.setState({
        sendMessageLoading: true,
      });
      var fd = new FormData();
      // fd.append("sender", localStorage.getItem("admin_centre_id"));
      if (this.state.video) {
        const ReactS3Client = new S3(configS3);
        const vName = this.state.video.name.split(".");
        const filename =
          vName[0] + "-" + moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
        const final_filename = base64encode(filename);

        let file = this.state.video;
        const VType = this.state.video.type.split("/");
        const VName = final_filename + "." + VType[1];

        ReactS3Client.uploadFile(file, VName).then((data) => {
          this.setState(
            {
              video: `https://d2t2athllyh1ku.cloudfront.net/${data.key}`,
              isVideo: false,
              checkValueThumb: false,
            },
            () => {
              fd.append("sender", localStorage.getItem("user_Id"));
              fd.append("receiver", this.state.receiverid);
              fd.append("message", values.message);
              fd.append("room_id", localStorage.getItem("room_id"));
              fd.append("video", this.state.video);
              fd.append("img", this.state.imgs);
              fd.append("has_read", true);
              resetForm({});
              this.setState({ previewUrl: ic_image });
              this.setState({ imgs: null });
              return firebase.reloadAuth().then((res) => {
                const token = res.ya === null ? res.za : res.ya;

                try {
                  api.post("/comms/", fd, token).then((res) => {
                    this.setState({
                      sendMessageLoading: false,
                    });
                    if (res.message == "success") {
                    } else {
                      // console.log("yes");
                      this.scrollToBottom();
                      this.props.addMessage(res.data);
                      // this.props.getMessage(
                      //   this.state.receiverid,
                      //   this.state.sender,
                      //   "10"
                      // );
                    }
                  });
                } catch (err) {
                  this.setState({
                    sendMessageLoading: false,
                  });
                  console.log(">", err);
                }
              });
            }
          );
        });
      } else {
        fd.append("sender", localStorage.getItem("user_Id"));
        fd.append("receiver", this.state.receiverid);
        fd.append("message", values.message);
        fd.append("room_id", localStorage.getItem("room_id"));
        fd.append("img", this.state.imgs);
        fd.append("has_read", true);
        resetForm({});
        this.setState({ previewUrl: ic_image, checkValueThumb: false });
        this.setState({ imgs: null });
        return firebase.reloadAuth().then((res) => {
          const token = res.ya === null ? res.za : res.ya;
          try {
            api.post("/comms/", fd, token).then((res) => {
              this.setState({
                sendMessageLoading: false,
                isVideo: false,
              });
              if (res.message == "success") {
              } else {
                // console.log("yes");
                this.scrollToBottom();
                this.props.addMessage(res.data);
                // this.props.getMessage(
                //   this.state.receiverid,
                //   this.state.sender,
                //   "10"
                // );
              }
            });
          } catch (err) {
            this.setState({
              sendMessageLoading: false,
            });
            console.log(">", err);
          }
        });
      }
    }
  };
  checkDate = (date) => {
    if (date) {
      date = new Date(date).getDate();
      if (this.state.chat_date.indexOf(date) < 0) {
        this.setState({
          chat_date: [...this.state.chat_date, date],
        });
      }
    }
  };
  titleDate = (date) => {
    let val = this.state.chat_date.find(
      (val) => val == new Date(date).getDate()
    );
  };
  groupedDays = (messages) => {
    return (
      messages &&
      messages.reduce((acc, el, i) => {
        const messageDay = moment(el.created_at, "YYYY MMM DD h:mm:ss").format(
          "YYYY-MM-DD"
        );
        // console.log('messageDay', messageDay)
        // console.log('el', el)
        if (acc[messageDay]) {
          return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
        }
        return { ...acc, [messageDay]: [el] };
      }, {})
    );
  };

  generateItems = (messages) => {
    if (
      messages == undefined ||
      messages.length < 0 ||
      messages == null ||
      messages == {}
    ) {
      return [];
    } else {
      var isNewShow = false;
      const days = this.groupedDays(messages);
      // console.log(JSON.stringify(days));

      const sortedDays = Object.keys(days).sort(
        (x, y) =>
          moment(y, "YYYY-MM-DD").unix() - moment(x, "YYYY-MM-DD").unix()
      );

      const items = sortedDays.reduce((acc, date) => {
        const sortedMessages = days[date].sort(
          (x, y) => new Date(y.created_at) - new Date(x.created_at)
        );
        let newArray = [...sortedMessages];
        //let index = sortedMessages.findIndex((ele, i)=> ele.has_read == true && i != 0);

        newArray = newArray.map((ele, i) => {
          var isShow;
          if (ele.has_read && !isNewShow) {
            isShow = true;
            isNewShow = true;
          } else {
            isShow = false;
          }

          return { ...ele, isNew: isShow };
        });

        return acc.concat([...newArray, { type: "day", date, id: date }]);
      }, []);

      return items;
    }
  };
  serachSubmit = (e) => {
    e.preventDefault();
    if (localStorage.getItem("adminType") == "centre") {
      this.props.searchUser(
        this.state.user_type,
        localStorage.getItem("centre_Id"),
        this.state.class_id,
        this.state.search
      );
    } else {
      this.props.searchUser(
        this.state.user_type,
        this.state.centre_id,
        this.state.class_id,
        this.state.search
      );
    }
  };

  checkRole = (title) => {
    if (title) {
      if (title.toLowerCase() == "parent") {
        return parenticon;
      } else if (title.toLowerCase() == "mother") {
        return parenticon;
      } else if (title.toLowerCase() == "father") {
        return parenticon;
      } else if (title.toLowerCase().includes("admin")) {
        return adminicon;
      } else if (title.toLowerCase() == "facilitator") {
        return facilitatoricon;
      } else if (title.toLowerCase() == "student") {
        return studenticon;
      } else {
        return usr;
      }
    } else {
      return usr;
    }
  };
  render() {
    const {
      centerclasses = [],
      isloading,
      msgloading,
      haveusersData,
      usersDataLoading,
      classes = [{ key: "", value: "all", text: "All Classes" }],
      message = [],
      usersData = [],
      room = [],
      havemessage,
      hasMoreMessage,
      userDataNext,
    } = this.props;
    let users = [];
    // this.Sorted = message.concat().reverse();
    // this.Sorted  = message.concat();
    this.Sorted = this.generateItems(message);

    // let message_show = [];
    // console.log(this.state.class_id);
    // message
    //   .concat()
    //   .reverse()
    //   .forEach((data) => message_show.push(data));

    // let usersDataFiltered = usersData.filter((val) => {
    //   if (this.state.serach === "") {
    //     return val;
    //   } else if (
    //     val.name.toLowerCase().includes(this.state.serach.toLowerCase())
    //   ) {
    //     return val;
    //   }
    // });

    let centers = [];
    let tempclass = [];
    centerclasses.forEach((data) => {
      centers.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    }, this.state.user_type === "admin" && centers.push({ key: "", value: "all", text: "All Centres" }));
    // }, this.state.user_type === "admin"  && centers.push({ key: "", value: "all", text: "All Centres" }));
    // centers.map((data) => {
    // if (data.key === this.state.centre_id) {
    //     centre_name = data.text
    // }
    // })

    tempclass = centerclasses.find(
      (result) => result.id === this.state.centre_id
    );
    tempclass !== undefined &&
      tempclass.class.forEach((data) => {
        classes.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });

    usersType
      .filter((u) => u.name != "student")
      .map((data) => {
        users.push({
          key: data.id,
          text: data.value,
          value: data.name,
        });
      });
    if (
      this.props.currentLoginUser &&
      this.props.currentLoginUser.admin_type === "hq"
    ) {
      users = users.filter((user) => user.value !== "parent");
    }

    // usersType.forEach((data) => {
    //   users.push({
    //     key: data.id,
    //     text: data.value,
    //     value: data.name,
    //   });
    // });

    //---------------------------- find room id from roomlist
    // let temproom=room.filter((eachObj) =>
    // eachObj.participants.find((data)=> data===this.state.receiverid));
    // // localStorage.setItem("room_id", message.length===0?undefined:message[0]['room_id'])
    // localStorage.setItem("room_id", temproom.length===0?undefined:temproom[0]['id']);
    // console.log("troom",temproom);
    //-----------------------------

    return (
      <Grid style={{ overflow: "hidden", height: "100vh" }}>
        <Grid.Row>
          <Grid.Column
            width={8}
            style={{
              marginTop: 30,
              height: "98vh",
              overflow: "hidden",
            }}
          >
            <Grid className="noBottomMargin">
              <Grid.Column floated="left" width={9}>
                <h1 floated="left" className="commsHeader">
                  Comms
                </h1>
              </Grid.Column>
              <Grid.Column floated="right" width={7}>
                <div
                  style={{
                    backgroundColor: "#4075FF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 999999,
                    height: 40,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      people_list_modal: true,
                    })
                  }
                >
                  <Image
                    src={New_Message}
                    width={16}
                    height={16}
                    style={{ marginRight: 10, marginTop: 3 }}
                  />
                  <div style={{ color: "#fff", fontWeight: 700 }}>
                    New Message
                  </div>
                </div>
              </Grid.Column>
              {/* <Grid.Column floated="right" width={6}>
                  {localStorage.getItem("adminType") === "centre" ? null : (
                    <Dropdown
                      name="centre_id"
                      onChange={this.ChooseCentre}
                      placeholder="Centre"
                      selectOnBlur={false}
                      options={centers}
                      // value={centre_id}
                      selection
                      fluid
                    />
                  )}
                </Grid.Column>
                <Grid.Column floated="right" width={5}>
                  <Dropdown
                    name="class_id"
                    onChange={this.ChooseCentre}
                    placeholder="Class"
                    value={this.state.class_id}
                    disabled={this.state.user_type === "admin" ? true : false}
                    options={classes.sort((a, b) =>
                      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                    )}
                    selection
                    fluid
                  />
                </Grid.Column>
                <Grid.Column floated="right" width={5}>
                  <Dropdown
                    name="user_type"
                    onChange={this.ChooseCentre}
                    placeholder="User"
                    value={this.state.user_type}
                    options={users}
                    selection
                    fluid
                  />
                </Grid.Column>
               */}
            </Grid>

            {/* Search */}
            <div className="only-tablet" style={styles.searchBarContainer}>
              <div
              // onSubmit={this.serachSubmit}
              >
                <div style={{ marginTop: 27 }}>
                  <div
                    className="ui icon input search"
                    style={{ width: "100%" }}
                  >
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        this.setState({ search: e.target.value });

                        // if (e.target.value == "") {
                        //   if (localStorage.getItem("adminType") == "centre") {
                        //     this.props.searchUser(
                        //       this.state.user_type,
                        //       localStorage.getItem("centre_Id"),
                        //       this.state.class_id,
                        //       ""
                        //     );
                        //   } else {
                        //     this.props.searchUser(
                        //       this.state.user_type,
                        //       this.state.centre_id,
                        //       this.state.class_id,
                        //       ""
                        //     );
                        //   }
                        // }
                      }}
                    />
                    <div
                      // type="submit"
                      style={{
                        background: "none",
                        border: "none",
                        outline: "none",
                        width: 50,
                        color: "#3C73DC",
                        fontSize: 20,
                        position: "absolute",
                        right: -5,
                        top: 10,
                        cursor: "pointer",
                      }}
                    >
                      <i
                        className="search icon"
                        style={{
                          color: "#3C73DC",
                          fontSize: 20,
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Search */}

            <div
              className="usercss2"
              style={{
                overflowY: room.length == 0 ? "hidden" : "scroll",
                overflowX: "hidden",
                marginTop: window.innerWidth < 1153 ? -30 : 20,
                height: "60vh",
              }}
            >
              {this.props.roomLoading ? (
                <div
                  style={{
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <>
                    {/* <Loader inverted active={this.props.roomLoading} /> */}
                    <Loader active={this.props.roomLoading} inline="centered" />
                  </>
                </div>
              ) : room.length == 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "50vh",
                  }}
                >
                  <Image src={No_Chat} />
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#BECAE4",
                      marginTop: 10,
                    }}
                  >
                    No chats yet, you may start a conversation by click "New
                    Message"
                  </div>
                </div>
              ) : (
                room
                  .filter((val) => {
                    if (this.state.search == "") {
                      console.log(this.state.search.toLowerCase());
                      return val;
                    } else if (
                      val.receiver.name
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((r) => {
                    return (
                      <Segment
                        onClick={() => {
                          this.handleChat(
                            r.receiver.id,
                            r.receiver.name,
                            r.receiver.img,
                            r.receiver.role ? r.receiver.role : null,
                            r.id
                          );
                        }}
                        className={
                          r.receiver.id === this.state.selected ? "custy" : ""
                        }
                        style={{ marginRight: 10 }}
                      >
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={3}>
                              {/* <Image
                              src={
                                this.validURL(r.receiver.img) === true
                                  ? r.receiver.img
                                  : usr
                              }
                              className="user-image-comms"
                            ></Image> */}
                              <Image
                                src={
                                  this.validURL(r.receiver.img) === true
                                    ? r.receiver.img
                                    : this.checkRole(r.receiver.role)
                                }
                                className="user-image-comms"
                              ></Image>
                            </Grid.Column>
                            <Grid.Column width={11} verticalAlign="middle">
                              <p>
                                <b>{r.receiver.name}・</b>
                                <span
                                  as="h3"
                                  style={{ color: "gray", fontWeight: 700 }}
                                >
                                  {/* {r.receiver.role} */}

                                  {r.receiver.relationship ? (
                                    r.receiver.children &&
                                    r.receiver.children.length > 0 ? (
                                      r.receiver.children.map((c, index) => {
                                        if (
                                          r.receiver.children.length - 1 ===
                                          index
                                        ) {
                                          return `${c.name}' ${r.receiver.relationship}`;
                                        } else {
                                          return `${c.name}, `;
                                        }
                                      })
                                    ) : (
                                      <>
                                        {r.relationship
                                          ? r.relationship
                                          : this.Capitalize(r.receiver.role)}
                                      </>
                                    )
                                  ) : (
                                    <>
                                      {r.receiver.role &&
                                        this.Capitalize(r.receiver.role)}
                                    </>
                                  )}
                                  {/* {r.receiver.relationship
                                  ? r.receiver.relationship
                                  : r.receiver.role} */}
                                </span>
                              </p>
                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign="middle">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  paddingRight: 10,
                                }}
                              >
                                <div style={{ color: "#939494" }}>
                                  {r.last_message_datetime && (
                                    <>
                                      {moment(r.last_message_datetime).format(
                                        "HH:mm"
                                      )}
                                    </>
                                  )}
                                </div>
                                {r.unread_count != 0 &&
                                  r.id != localStorage.getItem("room_id") && (
                                    <div
                                      style={{
                                        backgroundColor: "#F66460",
                                        color: "white",
                                        width: 20,
                                        height: 20,
                                        borderRadius: "50%",
                                        textAlign: "center",
                                        fontSize: 12,
                                        marginTop: 2,
                                      }}
                                    >
                                      <> {r.unread_count} </>
                                    </div>
                                  )}
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    );
                  })
              )}
            </div>
            {/* <Grid.Row>
                <Dimmer active={isloading || usersDataLoading} inverted>
                  <Loader inverted />
                </Dimmer>
              </Grid.Row> */}
            {/* <div className="usercss">
                {this.props.haveError ? (
                  <Message
                    header="Error loading users"
                    content="Error loading users. Please try again."
                    negative
                  />
                ) : usersData.length === 0 ? (
                  <Message
                    header="No user found"
                    content=" There is no user in this section."
                    warning
                  />
                ) : (
                  haveusersData && (
                    <InfiniteScroll
                      dataLength={usersData.length}
                      hasMore={userDataNext ? true : false}
                      loader={
                        <Loader
                          active
                          style={{ position: "relative", top: 50 }}
                          size="small"
                        />
                      }
                      height={window.innerHeight / 1.7}
                      next={() => {
                        this.props.getUsersData(
                          "",
                          "",
                          "",
                          "",
                          true,
                          userDataNext
                        );
                      }}
                      className="infinite-scroll"
                    >
                      {usersData
                        .filter((e) => e.id != localStorage.getItem("user_Id"))
                        .map((user, idx) => (
                          <Segment
                            key={user.id}
                            onClick={() =>
                              this.handleChat(user.id, user.name, user.img)
                            }
                            className={
                              user.id === this.state.selected ? "custy" : ""
                            }
                          >
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={3}>
                                  <Image
                                    src={
                                      this.validURL(user.img) === true
                                        ? user.img
                                        : usr
                                    }
                                    className="user-image-comms"
                                  ></Image>
                                </Grid.Column>
                                <Grid.Column width={13} verticalAlign="middle">
                                  <p>
                                    <b>{user.name}・</b>
                                    <span as="h3" style={{ color: "gray" }}>
                                      {this.Capitalize(this.state.user_type)}
                                    </span>
                                  </p>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        ))}
                    </InfiniteScroll>
                  )
                )}
              </div> */}
          </Grid.Column>

          <Grid.Column
            width={8}
            style={{
              overflow: "hidden",
              height: "100vh",
            }}
          >
            {!this.state.chatShow ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "82vh",
                  flexDirection: "column",
                }}
              >
                <Image src={No_Message} />
                <div
                  style={{
                    fontSize: 18,
                    color: "#000",
                    marginTop: 10,
                    fontWeight: "bolder",
                  }}
                >
                  It's nice to chat with someone
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: "#DCDCDC",
                    marginTop: 10,
                    fontWeight: "bold",
                  }}
                >
                  Pick a person from left menu <br />
                  and start your conversation
                </div>
              </div>
            ) : (
              <>
                <Grid>
                  <Grid.Row
                    style={{
                      position: "relative",
                      top: -50,
                      left: -30,
                      zIndex: 1,
                    }}
                  >
                    <Grid.Column width={3} verticalAlign="middle">
                      <Image
                        src={
                          this.state.user_img === ""
                            ? usr
                            : this.validURL(this.state.user_img) === true
                            ? this.state.user_img
                            : usr
                        }
                        className="user-image-comms"
                      ></Image>
                    </Grid.Column>
                    {this.state.show_user_type === "" ? null : (
                      <Grid.Column width={10} verticalAlign="middle">
                        <p>
                          <b>{this.state.receivername}</b>
                          <span as="h3" style={{ color: "gray" }}>
                            ・
                            {this.state.user_relation
                              ? this.state.user_relation
                              : this.state.show_user_type === "admin"
                              ? "Admin"
                              : this.state.show_user_type === "facilitator"
                              ? "Facilitators"
                              : this.state.show_user_type === "parent"
                              ? "Parent"
                              : this.state.show_user_type === "student"
                              ? "Student"
                              : ""}{" "}
                          </span>
                        </p>
                      </Grid.Column>
                    )}
                    <Grid.Column width={3} verticalAlign="middle"></Grid.Column>
                  </Grid.Row>
                </Grid>
                <div
                  style={{
                    backgroundColor: "#c4c4c4",
                    height: 1,
                    width: "300%",
                    position: "absolute",
                    left: 0,
                    top: 80,
                    zIndex: 999,
                  }}
                ></div>
                <div
                  // className="message-area"
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 50,
                    height: "100%",
                    width: window.innerWidth / 2.8,
                    right: window.innerWidth / 45,
                    margintTop: "20px",

                    display: "inline-block",
                    /* border-top: 1px solid #c4c4c4; */
                    /* border-bottom: 1px solid #c4c4c4; */
                  }}
                >
                  {/* <Grid.Row>
                  <Dimmer active={msgloading} inverted>
                    <Loader inverted />
                  </Dimmer>
                </Grid.Row> */}

                  <Loader
                    active={msgloading}
                    inline="centered"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                  {/* <Grid>
                                          <Grid.Column width={3} verticalAlign="middle">
                                          <Image src={usr} className="user-image-comms"></Image>
                                          </Grid.Column>
                                          <Grid.Column width={10} verticalAlign="middle" floated="left">
                                          <span>Mar 10 16:06</span>
                                          <div className="message-receive">
                                          Can we discuss with matter in blan.<br></br>
                                          </div>
                                          </Grid.Column>
                                        </Grid> */}
                  <div
                    style={{
                      width: "112%",
                      height: window.innerHeight / 1.3,
                      overflowY: "auto",
                      overflowX: "hidden",
                      display: "flex",
                      flexDirection: "column-reverse",
                      marginTop: 80,
                      position: "relative",
                      right: 60,
                    }}
                  >
                    {havemessage && (
                      <InfiniteScroll
                        dataLength={this.Sorted.length}
                        next={() => {
                          // this.props.addMessage(this.state.sender, "10")
                          // this.props.addMessage(this.Sorted.slice(-1)[0], this.state.sender, "10");
                          this.props.getMessage(
                            "",
                            this.state.sender_id,
                            10,
                            true,
                            this.props.hasMoreMessage,
                            false
                          );
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                        hasMore={hasMoreMessage ? true : false}
                        loader={
                          // <h4 style={{ position: "absolute", top: 0 }}>
                          //   Loading...
                          // </h4>
                          <Loader
                            active
                            style={{ position: "absolute", top: 50 }}
                            size="mini"
                          />
                        }
                        height={window.innerHeight / 1.3}
                        inverse={true}
                        scrollableTarget="scrollableDiv"
                        className="infinite-scroll"
                      >
                        <Loader
                          active={this.state.sendMessageLoading}
                          inline
                          size="mini"
                          color="blue"
                          className="message-loader"
                        />

                        {this.Sorted.length == 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh",
                              flexDirection: "column",
                            }}
                          >
                            <Image src={No_Message} />
                            <div
                              style={{
                                fontSize: 14,
                                color: "#DCDCDC",
                                marginTop: 10,
                                fontWeight: "bold",
                              }}
                            >
                              No message
                            </div>
                          </div>
                        ) : (
                          this.Sorted.map((msg, msgid) => {
                            this.checkDate(msg.created_at);

                            return (
                              <div
                                style={{
                                  backgroundColor: "white",
                                  paddingLeft: 30,
                                }}
                              >
                                {msg.date != undefined ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      color: "#939494",
                                      fontWeight: "bold",
                                      padding: "20px 0",
                                      borderTop: "1px solid #DCDDDF",
                                    }}
                                  >
                                    {moment(msg.date, "YYYY-MM-DD").format(
                                      "MMM DD"
                                    )}
                                  </div>
                                ) : localStorage.getItem("user_Id") ==
                                  msg.sender ? (
                                  <>
                                    {/* // <Grid key={msgid}>
                                  //   <Grid.Column

                                  //     verticalAlign="middle"
                                  //     floated='right'
                                  //   >
                                  //     <span>{msg.created_at}</span>
                                  //     {msg.img !== "" && (
                                  //       <div className="message-send">
                                  //         <Image src={msg.img}></Image>
                                  //       </div>
                                  //     )}
                                  //     <div className="message-send">
                                  //       {msg.message}
                                  //       <br></br>
                                  //     </div>
                                  //   </Grid.Column>
                                  // </Grid>
                                  // <div key={msgid} className="sender">
                                  //   <div>{msg.created_at}</div> <br /> */}
                                    <div
                                      key={msgid}
                                      className="sender"
                                      id="scrollableDiv"
                                      onClick={() => {
                                        if (msg.img !== "") {
                                          this.setState({
                                            modalToggle: true,
                                            selectedImageModal: msg.img,
                                          });
                                        }
                                      }}
                                    >
                                      <div style={{ marginRight: 50 }}>
                                        {moment(msg.created_at).format(
                                          "MMMM D h:mm"
                                        )}
                                      </div>{" "}
                                      <br />
                                      <div className="message-send">
                                        {msg.video ? (
                                          <ReactPlayer
                                            url={msg.video}
                                            width="300px"
                                            height="300px"
                                            controls={true}
                                          />
                                        ) : null}
                                        {msg.img !== "" && (
                                          <Image
                                            src={msg.img}
                                            onClick={() => {
                                              this.setState({
                                                modalToggle: true,
                                                selectedImageModal: msg.img,
                                              });
                                            }}
                                          ></Image>
                                        )}

                                        {msg.message}

                                        <br></br>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Grid key={msgid}>
                                      <Grid.Column
                                        width={7}
                                        verticalAlign="middle"
                                        floated="left"
                                        className="other_sender"
                                        id="scrollableDiv"
                                      >
                                        <span
                                          style={{
                                            color: "#939494",
                                            marginLeft: 50,
                                          }}
                                        >
                                          {moment(msg.created_at).format(
                                            "MMMM D h:mm"
                                          )}
                                        </span>

                                        <div>
                                          <Image
                                            src={
                                              this.state.user_img === ""
                                                ? usr
                                                : this.validURL(
                                                    this.state.user_img
                                                  ) === true
                                                ? this.state.user_img
                                                : usr
                                            }
                                            className="user-image-comms"
                                          ></Image>
                                        </div>

                                        <div className="message-receiver">
                                          {msg.video ? (
                                            <ReactPlayer
                                              url={msg.video}
                                              width="300px"
                                              height="300px"
                                              controls={true}
                                            />
                                          ) : null}
                                          <Image
                                            src={msg.img}
                                            onClick={() =>
                                              this.setState({
                                                modalToggle: true,
                                                selectedImageModal: msg.img,
                                              })
                                            }
                                          ></Image>
                                          {msg.message !== "" && (
                                            <>{msg.message}</>
                                          )}

                                          <br />
                                        </div>
                                      </Grid.Column>
                                    </Grid>
                                    {msg.isNew && msgid != 0 ? (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          color: "#F66460",
                                          fontWeight: "bold",
                                          padding: "20px 0",
                                          borderTop: "1px solid #F66460",
                                        }}
                                      >
                                        New
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            );
                          })
                        )}
                      </InfiniteScroll>
                    )}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        this.messagesEndRef = el;
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#c4c4c4",
                    height: 1,
                    width: "300%",
                    position: "absolute",
                    left: 0,
                    bottom: 80,
                  }}
                ></div>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
        {this.state.modalToggle && (
          <>
            <Modal
              basic
              onClose={() =>
                this.setState({
                  modalToggle: false,
                  selectedImageModal: null,
                })
              }
              onOpen={() => this.setState({ modalToggle: true })}
              open={this.state.modalToggle}
              className="modal_img"
            >
              <Grid image display="flex" centered>
                <Image size="big" src={this.state.selectedImageModal} wrapped />
              </Grid>
            </Modal>
            <div
              className="exit_modal"
              onClick={() =>
                this.setState({
                  modalToggle: false,
                  selectedImageModal: null,
                })
              }
            >
              X
            </div>
          </>
        )}
        {
          <Modal
            size="tiny"
            open={this.state.people_list_modal}
            onClose={() => this.setState({ people_list_modal: false })}
          >
            <div
              style={{
                height: window.innerHeight / 1.2,
                padding: 20,
              }}
            >
              {/* Header */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: 700, fontSize: 16 }}>Contact</div>
                <div
                  onClick={() => this.setState({ people_list_modal: false })}
                  style={{ cursor: "pointer" }}
                >
                  <Image src={Modal_Exit} width={24} />
                </div>
              </div>
              {/* End Header */}
              {/* Search */}
              <form onSubmit={this.serachSubmit}>
                <div style={{ marginTop: 27 }}>
                  <div
                    className="ui icon input search"
                    style={{ width: "100%" }}
                  >
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        this.setState({ search: e.target.value });
                        if (e.target.value == "") {
                          if (localStorage.getItem("adminType") == "centre") {
                            this.props.searchUser(
                              this.state.user_type,
                              localStorage.getItem("centre_Id"),
                              this.state.class_id,
                              ""
                            );
                          } else {
                            this.props.searchUser(
                              this.state.user_type,
                              this.state.centre_id,
                              this.state.class_id,
                              ""
                            );
                          }
                        }
                      }}
                    />
                    <button
                      type="submit"
                      style={{
                        background: "none",
                        border: "none",
                        outline: "none",
                        width: 50,
                        color: "#3C73DC",
                        fontSize: 20,
                        position: "absolute",
                        right: -5,
                        top: 10,
                        cursor: "pointer",
                      }}
                    >
                      <i
                        className="search icon"
                        style={{
                          color: "#3C73DC",
                          fontSize: 20,
                        }}
                      ></i>
                    </button>
                  </div>
                </div>
              </form>

              {/* End Search */}
              {/* DropDown */}
              <Grid style={{ marginTop: 1 }}>
                <Grid.Column floated="right" width={5}>
                  <Dropdown
                    name="user_type"
                    onChange={this.ChooseCentre}
                    placeholder="User"
                    value={this.state.user_type}
                    options={users}
                    selection
                    fluid
                    className="modal_dropdown"
                  />
                </Grid.Column>

                <Grid.Column floated="right" width={6}>
                  {localStorage.getItem("adminType") === "centre" ? null : (
                    <Dropdown
                      name="centre_id"
                      onChange={this.ChooseCentre}
                      placeholder="Centre"
                      selectOnBlur={false}
                      options={centers.sort((a, b) =>
                        a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                      )}
                      // value={centre_id}
                      selection
                      fluid
                      className="modal_dropdown"
                    />
                  )}
                </Grid.Column>
                <Grid.Column floated="right" width={5}>
                  <Dropdown
                    name="class_id"
                    onChange={this.ChooseCentre}
                    placeholder="Class"
                    value={this.state.class_id}
                    disabled={this.state.user_type === "admin" ? true : false}
                    options={classes.sort((a, b) =>
                      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                    )}
                    selection
                    fluid
                    className="modal_dropdown"
                  />
                </Grid.Column>
              </Grid>
              {/* End DropDown */}
              <Grid.Row>
                <Dimmer active={usersDataLoading} inverted>
                  <Loader inverted />
                </Dimmer>
              </Grid.Row>
              <div className="usercss">
                {this.props.haveError ? (
                  <Message
                    header="Error loading users"
                    content="Error loading users. Please try again."
                    negative
                  />
                ) : usersData.length === 0 ? (
                  <Message
                    header="No user found"
                    content=" There is no user in this section."
                    warning
                    style={{ position: "absolute", zIndex: 1, width: "100%" }}
                  />
                ) : (
                  haveusersData && (
                    <InfiniteScroll
                      dataLength={usersData.length}
                      hasMore={userDataNext ? true : false}
                      loader={
                        // <Loader
                        //   active
                        //   style={{ position: "relative", top: 0 }}
                        //   size="small"
                        // />
                        <Segment>
                          <Dimmer
                            active={this.props.userMoreDataLoading}
                            inverted
                          >
                            <Loader
                              inverted
                              size="small"
                              style={{
                                position: "absolute",
                                top: 50,
                              }}
                            />
                          </Dimmer>
                        </Segment>
                      }
                      height={window.innerHeight / 2}
                      next={() => {
                        this.props.getUsersData(
                          "",
                          "",
                          "",
                          "",
                          true,
                          userDataNext
                        );
                      }}
                      className="infinite-scroll"
                    >
                      {usersData.length == 0 ? (
                        // <div
                        //   style={{
                        //     backgroundColor: "red",
                        //     position: "absolute",
                        //     height: 50,
                        //     top: 10,
                        //     width: "100%",
                        //     zIndex: 9999999,
                        //   }}
                        // >
                        //   Not Found
                        // </div>
                        <Message
                          header="No user found"
                          content=" There is no user in this section."
                          warning
                          style={{
                            position: "absolute",
                            zIndex: 2,
                            top: 10,
                            width: "100%",
                          }}
                        />
                      ) : (
                        usersData
                          .filter(
                            (e) => e.id != localStorage.getItem("user_Id")
                          )
                          .map((user, idx) => (
                            <Segment
                              key={user.id}
                              onClick={() =>
                                this.handleChat(
                                  user.id,
                                  user.name,
                                  user.img,
                                  user.relationship
                                )
                              }
                              className={
                                user.id === this.state.selected ? "custy" : ""
                              }
                            >
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width={3}>
                                    {/* <Image
                                      src={
                                        this.validURL(user.img) === true
                                          ? user.img
                                          : usr
                                      }
                                      className="user-image-comms"
                                    ></Image> */}
                                    <Image
                                      src={
                                        this.validURL(user.img) === true
                                          ? user.img
                                          : this.checkRole(this.state.user_type)
                                      }
                                      className="user-image-comms"
                                    ></Image>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={13}
                                    verticalAlign="middle"
                                  >
                                    <p>
                                      <b>{user.name}・</b>
                                      <span
                                        as="h3"
                                        style={{
                                          color: "gray",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {this.state.user_type == "parent" ? (
                                          user.children &&
                                          user.children.length > 0 ? (
                                            user.children.map((c, index) => {
                                              if (
                                                user.children.length - 1 ===
                                                index
                                              ) {
                                                return `${c.name}' ${user.relationship}`;
                                              } else {
                                                return `${c.name}, `;
                                              }
                                            })
                                          ) : (
                                            <>
                                              {user.relationship
                                                ? user.relationship
                                                : this.Capitalize(
                                                    this.state.user_type
                                                  )}
                                            </>
                                          )
                                        ) : (
                                          <>
                                            {user.relationship
                                              ? user.relationship
                                              : this.Capitalize(
                                                  this.state.user_type
                                                )}
                                          </>
                                        )}

                                        {/* {this.Capitalize(this.state.user_type)} */}
                                      </span>
                                    </p>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          ))
                      )}
                    </InfiniteScroll>
                  )
                )}
              </div>
            </div>
          </Modal>
        }
        {this.state.selectUser ? (
          <div
            style={{
              position: "absolute",
              right:
                window.innerWidth < 870
                  ? 10
                  : window.innerWidth < 1200
                  ? 0
                  : -20,
              bottom: 0,
              zIndex: 111,
              width: window.innerWidth / 2.45,
              backgroundColor: "transparent",
            }}
          >
            <Formik
              initialValues={{
                message: "",
                type: "",
              }}
              onSubmit={this.handleSubmit}
            >
              {(formikProps) => {
                return (
                  <Form>
                    <Grid floated="right" style={{ backgroundColor: "gra" }}>
                      <Grid.Column
                        width={1}
                        style={{
                          padding: "20px 0px",
                        }}
                      >
                        {this.state.checkValueThumb && (
                          <div
                            style={{
                              position: "absolute",
                              width: 15,
                              height: 15,
                              top: 10,
                              right: -10,
                              cursor: "pointer",
                              zIndex: 9999,
                            }}
                            onClick={() =>
                              this.setState({
                                imgs: null,
                                video: null,
                                checkValueThumb: false,
                                previewUrl: ic_image,
                                isVideo: false,
                              })
                            }
                          >
                            <img
                              src={CrossImgIcon}
                              width="15px"
                              height="15px"
                            />
                          </div>
                        )}

                        {this.state.isVideo ? (
                          <Field
                            name="type"
                            component={CommsImage}
                            onChange={this.attach.bind(this)}
                            // accept="image/*"
                            previewUrl={VideoPlayerIcon}
                            isVideo={true}
                          />
                        ) : (
                          <Field
                            name="type"
                            component={CommsImage}
                            onChange={this.attach.bind(this)}
                            // accept="image/*"
                            previewUrl={this.state.previewUrl}
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column width={13} floated="right">
                        <Field
                          name="message"
                          component={RenderInput}
                          placeholder="Type Something"
                          value={formikProps.values.message}
                          onChange={formikProps.handleChange("message")}
                        />
                      </Grid.Column>

                      <Grid.Column width={2} floated="right">
                        <Button
                          content={<Image src={ic_Group99}></Image>}
                          primary
                          size="medium"
                          type="submit"
                          className="suBtn2"
                          style={{ marginRight: 100 }}
                        />
                      </Grid.Column>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 10,
              zIndex: 111,
              width: window.innerWidth / 2.45,
              height: 50,
              backgroundColor: "white",
            }}
          ></div>
        )}
      </Grid>
    );
  }
}

const styles = {
  searchBarContainer: {
    width: "92.5%",
    position: "absolute",
    paddingRight: 20,
  },
};

const mapStateToProps = (state) => ({
  currentLoginUser: state.currentLoginUser.currentLoginUser.data,
  centerclasses: state.centersClasses.centers_classes.data,
  usersData: state.usersData.usersData,
  userDataNext: state.usersData.userDataNext,
  ActiveToken: state.firebase.auth.stsTokenManager.accessToken,
  isloading: state.centersClasses.loading,
  haveError: state.centersClasses.err,
  haveData: state.centersClasses.haveData,
  usersList: state.users.user !== undefined ? state.users.user.data : [],
  usersDataLoading: state.usersData.loading,
  userMoreDataLoading: state.usersData.moreLoading,
  haveusersData: state.usersData.haveData,
  haveusersError: state.usersData.err,
  havemessage: state.gmessage.haveData,
  msgloading: state.gmessage.loading,
  message: state.gmessage.gmessage !== undefined ? state.gmessage.gmessage : [],
  hasMoreMessage: state.gmessage.nextMessageUrl,

  room: state.room.room.data !== undefined ? state.room.room.data : [],
  roomLoading: state.room.loading,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
  getUser: (id, user_type) => dispatch(getUser(id, user_type)),
  getCurrentLoginUser: (user_id, user_type) =>
    dispatch(getCurrentLoginUser(user_id, user_type)),
  getUsers: (user_type, centre_id, class_id, active) =>
    dispatch(getUsers(user_type, centre_id, class_id, active)),
  getUsersData: (user_type, centre_id, class_id, admin_type, isNext, nextUrl) =>
    dispatch(
      getUsersData(user_type, centre_id, class_id, admin_type, isNext, nextUrl)
    ),
  getMessage: (id, sender_id, size, isNext, nextUrl, refresh) =>
    dispatch(getMessage(id, sender_id, size, isNext, nextUrl, refresh)),
  getRoomlist: (senderid, refresh) => dispatch(getRoomlist(senderid, refresh)),
  addMessage: (data) => dispatch(addMessage(data)),
  readCommsAllMessage: (room_id, sender) =>
    dispatch(readCommsAllMessage(room_id, sender)),
  refreshMessage: () => dispatch(refreshMessage()),
  searchUser: (user_type, centre_id, class_id, search) =>
    dispatch(searchUser(user_type, centre_id, class_id, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommsList);
