import api from "../../config/api";
import { firebase } from "../../config/firebase";

export const FETCH_USERS_DATA = "FETCH_USERS_DATA";
export const FETCH_USERS_DATA_SUCCESS = "FETCH_USERS_DATA_SUCCESS";
export const FETCH_USERS_DATA_FAILURE = "FETCH_USERS_DATA_FAILURE";
export const FETCH_USERS_DATA_MORE_SUCCESS = "FETCH_USERS_MORE_SUCCESS";
export const FETCH_USERS_DATA_MORE_FAILURE = "FETCH_USERS_DATA_MORE_FAILURE";
export const FETCH_USERS_DATA_MORE = "FETCH_USERS_DATA_MORE";

export const fetchUsersData = (
  user_type,
  centre_id,
  class_id,
  admin_type,
  size
) => ({
  type: FETCH_USERS_DATA,
  payload: {
    user_type,
    centre_id,
    class_id,
    admin_type,
    size,
  },
});

export const fetchUsersDataSuccess = (data) => ({
  type: FETCH_USERS_DATA_SUCCESS,
  data,
});

export const fetchUsersDataFailure = () => ({
  type: FETCH_USERS_DATA_FAILURE,
});

export const fetchUsersMoreDataSuccess = (data) => ({
  type: FETCH_USERS_DATA_MORE_SUCCESS,
  data,
});

export const fetchUsersMoreData = () => ({
  type: FETCH_USERS_DATA_MORE,
});

export const fetchUsersMoreDataFailure = () => ({
  type: FETCH_USERS_DATA_MORE_FAILURE,
});

export const getUsersData = (
  user_type,
  centre_id,
  class_id,
  admin_type,
  isNext,
  nextUrl
) => {
  return (dispatch) => {
    if (centre_id == "all") {
      centre_id = "";
    }
    if (class_id == "all") {
      class_id = "";
    }

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        if (isNext) {
          dispatch(fetchUsersMoreData());
          fetch(api.apiRoot + nextUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((res) => {
              dispatch(fetchUsersMoreDataSuccess(res));
              console.log("resweweMore", res);
              return res;
            })
            .catch((err) => dispatch(fetchUsersMoreDataFailure(err)));
        } else if (user_type == "admin") {
          dispatch(fetchUsersData());
          api
            .get(
              "/user/",
              {
                user_type: user_type,
                centre_id: centre_id,
                class_id: "" + class_id,

                remove_inactive: 1,
              },
              token
            )
            .then((res) => {
              // console.log("reswewe", res);
              dispatch(fetchUsersDataSuccess(res));

              return res;
            })
            .catch((err) => dispatch(fetchUsersDataFailure(err)));
        } else {
          dispatch(fetchUsersData());
          api
            .get(
              "/user/",
              {
                user_type: user_type,
                centre_id: centre_id,
                class_id: "" + class_id,
                remove_inactive: 1,
              },
              token
            )
            .then((res) => {
              dispatch(fetchUsersDataSuccess(res));

              return res;
            })
            .catch((err) => dispatch(fetchUsersDataFailure(err)));
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
};
export const searchUser = (user_type, centre_id, class_id, search) => {
  return (dispatch) => {
    if (centre_id == "all") {
      centre_id = "";
    }
    if (class_id == "all") {
      class_id = "";
    }
    dispatch(fetchUsersData());
    return firebase.reloadAuth().then((res) => {
      const token = res.ya === null ? res.za : res.ya;
      if (user_type == "admin") {
        api
          .get(
            "/user/",
            {
              user_type,
              centre_id,
              class_id,
              search_string: search,
              remove_inactive: 1,
            },
            token
          )
          .then((res) => {
            dispatch(fetchUsersDataSuccess(res));

            return res;
          })
          .catch((err) => console.log("ers", err));
      } else if (user_type == "facilitator") {
        api
          .get(
            "/user/",
            {
              user_type,
              centre_id,
              class_id,
              search_string: search,
              remove_inactive: 1,
            },
            token
          )
          .then((res) => {
            dispatch(fetchUsersDataSuccess(res));

            return res;
          })
          .catch((err) => console.log("ers", err));
      } else if (user_type == "parent") {
        api
          .get(
            "/user/",
            {
              user_type,
              centre_id,
              class_id,
              search_string: search,
              remove_inactive: 1,
            },
            token
          )
          .then((res) => {
            dispatch(fetchUsersDataSuccess(res));

            return res;
          })
          .catch((err) => console.log("ers", err));
      }
    });
  };
};
