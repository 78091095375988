import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Progress,
  Icon,
  Image,
  Dropdown,
  Message,
  Modal,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import RenderButton from "../../components/Button";
import { ACTIVITY_CREATE_ROUTE, ACTIVITY_ROUTE } from "../../routes";
import mark from "../../assets/images/mark.png";
import { getEvents, getCentersClasses } from "../../store/actions";
import RenderLoader from "../../components/Loader";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import moment from "moment";
import ExclamationIcon from "../../components/ExclamationIcon";

const months = [
  { key: 1, text: "January", value: 1 },
  { key: 2, text: "February", value: 2 },
  { key: 3, text: "March", value: 3 },
  { key: 4, text: "April", value: 4 },
  { key: 5, text: "May", value: 5 },
  { key: 6, text: "June", value: 6 },
  { key: 7, text: "July", value: 7 },
  { key: 8, text: "August", value: 8 },
  { key: 9, text: "September", value: 9 },
  { key: 10, text: "October", value: 10 },
  { key: 11, text: "November", value: 11 },
  { key: 12, text: "December", value: 12 },
];

const RenderListBox = (props) => {
  let centreAndClass = props.centerclasses.filter(
    (x) => x.id === props.centre_ids[0]
  )[0];
  var centreName = "";
  var className = "";
  if (centreAndClass !== undefined && centreAndClass !== {}) {
    centreName =
      props.centre_ids.length > 1 ? "All Centres" : centreAndClass.name;
    let temp = centreAndClass.class.filter(
      (e) => e.id === props.class_ids[0]
    )[0];
    if (temp !== undefined && temp !== {}) {
      className =
        props.class_ids.length > 1 &&
        props.class_ids.length > props.centre_ids.length
          ? "All Classes"
          : temp.name;
    }
  }
  var status =
    moment().isBefore(moment(props.reg_to_date)) == true
      ? props.status === "inactive"
        ? "Closed"
        : "Open"
      : "Open";
  var status = props.status === "inactive" ? "Closed" : "Open";
  return (
    <Segment className="listBox lightenShadow">
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h6" color="grey" className="noBottomMargin">
              {moment(props.reg_to_date).format("DD MMM")}
            </Header>
            <span className="text-turncation">{props.title}</span>
          </Grid.Column>
          <Grid.Column width={3}>
            <p style={{ marginTop: 8 }}>{centreName + " • " + className}</p>
          </Grid.Column>
          <Grid.Column width={3}>
            {/* <p className="regOpen nofontWeight">
              Registration {status}
            </p> */}
            {status == "Open" ? (
              <p className="regOpenMessage fontWeight">Open for registration</p>
            ) : (
              <p className="regCloseMessage fontWeight">Registration closed</p>
            )}
          </Grid.Column>
          <Grid.Column width={1}>
            {props.scfa !== 1 ? (
              "-"
            ) : (
              <Image src={mark} style={{ width: "22" }} />
            )}
          </Grid.Column>
          <Grid.Column width={2}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: 12,
              }}
            >
              {props.event_participants}/{props.max_vacancy}
            </span>

            {((props.event_participants / props.max_vacancy) * 100).toFixed(
              3
            ) == 0 ? (
              <Progress percent={100} size="tiny" disabled />
            ) : (
              <Progress
                percent={(
                  (props.event_participants / props.max_vacancy) *
                  100
                ).toFixed(3)}
                size="tiny"
                color="blue"
              />
            )}
          </Grid.Column>
          <Grid.Column width={1} className="centerText">
            <div className="ui simple dropdown item">
              <Icon
                style={{ color: "#C4C4C4" }}
                name="ellipsis vertical"
                size="large"
              />
              <div className="menu">
                <div
                  onClick={() =>
                    props.dispatch(push(`${ACTIVITY_ROUTE}/edit/${props.id}`))
                  }
                  className="item"
                >
                  Edit
                </div>
                <div
                  className="item"
                  // onClick={() => props.onHandleCloseOrOpen(status, props.id)}
                  onClick={() => {
                    props.showModalHandle(status, props.id);
                  }}
                >
                  {status == "Open" ? "Close" : "Open"}
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

class ActivitiesList extends Component {
  state = {
    isactive: 1,
    selectedMonth: moment().format("MM"),
    setDate: "",
    start_date: new Date(),
    end_date: new Date(),
    selected_date: new Date(),
    showModal: false,
    status: "",
    id: "",
  };

  onHandleCloseOrOpen = (status, id) => {
    console.log(status);
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("status", status == "Open" ? "inactive" : "active");

    firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/events/event/", formdata, token).then((res) => {
          console.log(res);
          if (res.message === "success") {
            const tempDateString =
              moment().format("YYYY") +
              "-" +
              this.state.selectedMonth +
              "-" +
              "01";
            const startDateOfMonth = moment(tempDateString)
              .clone()
              .startOf("month")
              .format("YYYY-MM-DD");
            const endDateOfMonth = moment(tempDateString)
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD");
            this.props.getEvents(
              startDateOfMonth,
              endDateOfMonth,
              "admin_view",
              "all"
            );
            this.setState({ showModal: false });
          } else {
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };
  componentDidMount() {
    const startDateOfCurrentMonth = moment()
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDateOfCurrentMonth = moment()
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");
    this.props.getEvents(
      startDateOfCurrentMonth,
      endDateOfCurrentMonth,
      "admin_view",
      "all"
    );
    this.props.getCentersClasses();
  }

  handleRoute = () => {
    this.props.dispatch(push(ACTIVITY_CREATE_ROUTE));
  };

  onChangeMonth = (e, data) => {
    var tempMonth = data.value > 10 ? data.value : "0" + data.value;
    this.setState({
      selectedMonth: tempMonth,
    });
    const tempDateString =
      moment().format("YYYY") + "-" + tempMonth + "-" + "01";
    const startDateOfMonth = moment(tempDateString)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDateOfMonth = moment(tempDateString)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");
    this.props.getEvents(startDateOfMonth, endDateOfMonth, "admin_view", "all");
  };

  onTapChange = (value) => {
    const tempDateString =
      moment().format("YYYY") + "-" + this.state.selectedMonth + "-" + "01";
    const startDateOfMonth = moment(tempDateString)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDateOfMonth = moment(tempDateString)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");
    this.setState({
      isactive: value,
    });

    // this.props.getEvents(startDateOfMonth,endDateOfMonth,"admin_view","all");
  };

  getEventByStatus = () => {
    const { isactive } = this.state;
    const { events } = this.props;
    let openEvents = [];
    let closeEvents = [];
    if (isactive == 1) {
      return events;
    } else {
      // events.map((x) => {
      //   var status =
      //     moment().isBefore(moment(x.reg_to_date)) == true
      //       ? x.status == "active"
      //         ? "Open"
      //         : x.status == "inactive"
      //         ? "Closed"
      //         : "Open"
      //       : "Closed";

      //   if (status == "Open") {
      //     openEvents.push(x);
      //   } else {
      //     closeEvents.push(x);
      //   }
      //   console.log("EVnet", openEvents);
      //   console.log("closeEvents", closeEvents);
      // });
      var openEventsFilter = events.filter((e) => e.status != "inactive");
      var closeEventsFilter = events.filter((e) => e.status == "inactive");
      if (isactive == 2) {
        return closeEventsFilter;
      } else {
        return openEventsFilter;
      }
    }
  };

  render() {
    const {
      events,
      isloading,
      dispatch,
      haveError,
      handleOnSelect,
      centerclasses = [],
    } = this.props;
    const { isSaving } = this.state;
    // console.log(this.state.selectedMonth);
    // console.log('label', events)
    return (
      <Grid className="fullHeight activities">
        <RenderLoader isloading={isloading || isSaving} size="big" />
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid className="noBottomMargin">
              <Grid.Column floated="left" width={6}>
                <Header as="h3" floated="left">
                  Events
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                width={window.innerWidth < 850 ? 4 : 3}
              >
                <RenderButton
                  floated="right"
                  className="primaryBtn"
                  fluid={true}
                  btnText="Add new"
                  showIcon={true}
                  onClick={this.handleRoute}
                />
              </Grid.Column>
            </Grid>

            <Grid>
              <Grid.Column floated="left" width={11}>
                <RenderButton
                  btnText="All"
                  className="defaultBtn"
                  onClick={() => this.onTapChange(1)}
                  active={this.state.isactive == 1}
                />
                <RenderButton
                  btnText="Registration closed"
                  className="defaultBtn"
                  active={this.state.isactive == 2}
                  onClick={() => this.onTapChange(2)}
                />
                <RenderButton
                  btnText="Open for registration"
                  className="defaultBtn"
                  active={this.state.isactive == 3}
                  onClick={() => this.onTapChange(3)}
                />
              </Grid.Column>

              <Grid.Column floated="right" width={3}>
                <Dropdown
                  name="month"
                  onChange={this.onChangeMonth}
                  customStyle={{ float: "right", fontWeight: "bold" }}
                  // placeholder="Choose Month"
                  options={months}
                  value={parseInt(this.state.selectedMonth)}
                  selection
                  fluid
                />
              </Grid.Column>
            </Grid>

            <Grid className="listBoxTitle">
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as="h4" color="grey" style={{ paddingLeft: 10 }}>
                    Event
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Centres / Class
                  </Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" color="grey">
                    Registration status
                  </Header>
                </Grid.Column>
                <Grid.Column width={1}>
                  <Header as="h4" color="grey">
                    SCFA
                  </Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Header as="h4" color="grey">
                    No.people
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {haveError ? (
              <Message
                header="Error loading events"
                content="Error loading events. Please try again."
                negative
              />
            ) : this.getEventByStatus() !== undefined &&
              this.getEventByStatus().length === 0 &&
              !isloading ? (
              <Message
                header="No event found"
                content=" There is no event in this section."
                warning
              />
            ) : (
              this.getEventByStatus() !== undefined &&
              this.getEventByStatus().map((event) => (
                <RenderListBox
                  {...event}
                  dispatch={dispatch}
                  onHandleCloseOrOpen={this.onHandleCloseOrOpen}
                  centerclasses={centerclasses}
                  key={event.id}
                  showModalHandle={(status, id) => {
                    this.setState({ showModal: true, status, id });
                  }}
                />
              ))
            )}
          </Grid.Column>
        </Grid.Row>
        <Modal
          onClose={() => this.setState({ showModal: false })}
          onOpen={() => this.setState({ showModal: true })}
          open={this.state.showModal}
          // trigger={<Button>Show Modal</Button>}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: 500,
            // height: 305,
          }}
        >
          <Modal.Content>
            <div
              style={{ color: "#F3B329", textAlign: "center", marginTop: 63 }}
            >
              <ExclamationIcon width="120" height="120" />
            </div>
            <div
              style={{
                fontSize: 24,
                fontWeight: "700",
                textAlign: "center",
                marginTop: 40,
              }}
            >
              Do you want {this.state.status === "Open" ? "Close" : "Open"} the
              Event
            </div>
            {/* <p
              style={{
                fontSize: 14,
                fontWeight: "400",
                textAlign: "center",
                paddingTop: 10,
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
            <div
              style={{
                display: "flex",
                marginTop: 40,
                justifyContent: "space-around",
              }}
            >
              <div
                onClick={() => this.setState({ showModal: false })}
                style={{
                  width: 195,
                  height: 48,
                  borderRadius: 9999,
                  border: "1px solid #4075FF",
                  display: "grid",
                  placeItems: "center",
                  fontSize: 14,
                  fontWeight: "600",
                  color: "#4075FF",
                  cursor: "pointer",
                  marginRight: 11,
                }}
              >
                Cancel
              </div>
              <div
                onClick={() =>
                  this.onHandleCloseOrOpen(this.state.status, this.state.id)
                }
                style={{
                  width: 195,
                  height: 48,
                  borderRadius: 9999,
                  display: "grid",
                  placeItems: "center",
                  fontSize: 14,
                  fontWeight: "600",
                  color: "#fff",
                  backgroundColor: " #4075FF",
                  cursor: "pointer",
                }}
              >
                Yes
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.events.events !== undefined ? state.events.events.data : [],
  isloading: state.events.loading,
  haveData: state.events.haveData,
  haveError: state.events.err,
  centerclasses: state.centersClasses.centers_classes.data,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getEvents: (start_date, end_date, filtered_by, reg_status) =>
    dispatch(getEvents(start_date, end_date, filtered_by, reg_status)),
  //getEventsFilter: (start_date, end_date,filtered_by,reg_status) => dispatch(getEvents(start_date, end_date,filtered_by,reg_status)),
  getCentersClasses: () => dispatch(getCentersClasses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesList);
