import React from 'react';
import { Route } from 'react-router'
import { CALENDAR_ROUTE, SCHEDULE_CREATE_ROUTE, SCHEDULE_TYPE_CREATE_ROUTE, SCHEDULE_ITEM_CREATE_ROUTE } from '../../routes';
import CalendarHome from './CalendarHome';
import ScheduleCreate from './ScheduleCreate';
import ScheduleTypeCreate from './ScheduleTypeCreate';
import ScheduleItemCreate from './ScheduleItemCreate';

export const Calendar = () => (
  <React.Fragment>
    <Route exact path={CALENDAR_ROUTE} component={CalendarHome} />
    <Route exact path={SCHEDULE_CREATE_ROUTE} component={ScheduleCreate} />
    <Route exact path={SCHEDULE_TYPE_CREATE_ROUTE} component={ScheduleTypeCreate} />
    <Route exact path={SCHEDULE_ITEM_CREATE_ROUTE} component={ScheduleItemCreate} />
  </React.Fragment>
);

export default Calendar;
