import React, { Component } from "react";
import { Segment, Grid, Message, Image, Header } from "semantic-ui-react";
import usr from "../../assets/images/usr.png";
import RCModal from "./RecordModal";
import RenderLoader from "../../components/Loader";
import adminicon from "../../assets/images/ic_Admin.svg";
import parenticon from "../../assets/images/ic_Parents.svg";
import facilitatoricon from "../../assets/images/ic_Facilitator.svg";
import studenticon from "../../assets/images/ic_Students.svg";

const RenderListBox = (props) => {
  const checkRole = (title) => {
    if (title.toLowerCase() == "parent") {
      return parenticon;
    } else if (title.toLowerCase() == "mother") {
      return parenticon;
    } else if (title.toLowerCase() == "father") {
      return parenticon;
    } else if (title.toLowerCase().includes("admin")) {
      return adminicon;
    } else if (title.toLowerCase() == "facilitator") {
      return facilitatoricon;
    } else if (title.toLowerCase() == "student") {
      return studenticon;
    } else {
      return usr;
    }
  };

  return (
    <Segment className="forms-listbox">
      <Grid>
        <Grid.Row>
          <Grid.Column width={2} verticalAlign="middle">
            <span style={{ color: "#939494" }}>{props.date}</span>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign="middle">
            <Image
              // src={checkRole(props.parent_relationship)}
              src={
                props.parent_img
                  ? props.parent_img
                  : checkRole(props.parent_relationship)
              }
              avatar
              style={{ width: 38, height: 38, border: "2px solid #4075FF" }}
            ></Image>

            <span className="comms-user-name"> {props.parent_name} </span>
            <span
              className="comms-user-role"
              style={{
                color: "#939494",
                fontWeight: "bold",
                textAlign: "right",
                fontSize: 14,
              }}
            >
              ・{" "}
              {props.children_names.map((c, index) => {
                if (props.children_names.length - 1 === index) {
                  return `${c}'s ${props.parent_relationship} `;
                } else {
                  return `${c}, `;
                }
              })}
            </span>
          </Grid.Column>
          <Grid.Column width={2} verticalAlign="middle">
            <p className="nofontWeight">
              {props.parent_email.length > 15 ? (
                <>{props.parent_email.substring(0, 12)}...</>
              ) : (
                <>{props.parent_email}</>
              )}
            </p>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            <p
              style={{
                // background: "#F66460",
                // background: "#4075FF",
                background:
                  props.status.toLowerCase() == "paid" ||
                  props.status.toLowerCase() == "completed"
                    ? "#7CD227"
                    : props.status.toLowerCase() == "cancelled"
                    ? "#F66460"
                    : props.status.toLowerCase() == "payment verification"
                    ? "#4075FF"
                    : null,
                borderRadius: 999999,
                color:
                  props.status.toLowerCase() == "paid" ||
                  props.status.toLowerCase() == "cancelled" ||
                  props.status.toLowerCase() == "payment verification" ||
                  props.status.toLowerCase() == "completed"
                    ? "#fff"
                    : "#000",
                fontWeight: 500,
                fontSize: 12,
                width: "fit-content",
                textAlign: "center",
                padding: "5px 10px",
              }}
            >
              {/* Cancelled */}
              {/* Payment verfication */}
              {props.status}
              {/* Paid */}
            </p>
          </Grid.Column>
          <Grid.Column
            width={2}
            verticalAlign="middle"
            style={{ textAlign: "right" }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "#4075FF",
              }}
            >
              $ {props.amount}
            </span>
          </Grid.Column>
          <Grid.Column width={1} verticalAlign="middle" className="centerText">
            <RCModal
              parentID={props.parent_id}
              payment_id={props.payment_id}
              status={props.status}
              payment_method={props.payment_method}
              transaction_ref={props.transaction_ref}
              date={props.date}
              amount={props.amount}
              reFetchDonorList={props.reFetchDonorList}
              loaderReFetch={props.loaderReFetch}
              details={props}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
class Donorlist extends Component {
  render() {
    const { isloading, haveData } = this.props;
    return (
      <>
        <Grid>
          <Grid.Row>
            <RenderLoader isloading={isloading} />
          </Grid.Row>
          {this.props.filtersUsers !== null && (
            <Grid.Row className="list-title-container">
              <Grid.Column>
                <Grid className="activityTitle2">
                  <Grid.Row>
                    <Grid.Column width={2}>
                      <Header as="h5" color="grey">
                        Date
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Header as="h5" color="grey">
                        Contributor
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Header as="h5" color="grey">
                        Email
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Header as="h5" color="grey">
                        Status
                      </Header>
                    </Grid.Column>

                    <Grid.Column width={2} style={{ textAlign: "right" }}>
                      <Header as="h5" color="grey">
                        Donation
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={1}></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>

        {this.props.filtersUsers.map((donor, idx) => {
          return (
            <RenderListBox
              {...donor}
              key={idx}
              parid={donor.parent_id}
              Date={donor.timestamp}
              TotalAmount={donor.amount}
              payment_id={donor.payment_id}
              status={donor.status}
              payment_method={donor.payment_method}
              transaction_ref={donor.transaction_ref}
              date={donor.date}
              amount={donor.amount}
              reFetchDonorList={this.props.reFetchDonorList}
              loaderReFetch={this.props.loaderReFetch}
            />
          );
        })}
      </>
    );
  }
}
export default Donorlist;
