import React, { Component } from "react";
import {
  Grid,
  Header,
  Button,
  Dimmer,
  Loader,
  Visibility,
  Label,
} from "semantic-ui-react";
import { Formik, Field, Form, FieldArray } from "formik";
import RenderInput from "../../components/TextInput";
import RenderDropdown from "../../components/DropDown";
import { isRequired } from "../../validators";
import RenderCheckboxGroup from "../../components/CheckGroup";
import RenderTextArea from "../../components/RenderTextArea";
// import RenderDatePicker from '../../components/DatePicker';
// import RenderTimePicker from '../../components/TimePicker';
import RenderPButtonGroup from "../../components/PButtonGroup";
import CrossInfo from "../../assets/images/crossinfo.png";

class NotificationFrom extends Component {
  handleRemove(arrayHelpers, index) {
    arrayHelpers.remove(index);
  }
  render() {
    const { centerclasses } = this.props;
    let centres = this.props.centers;
    if (localStorage.getItem("adminType") !== "centre") {
      centres.push({ key: "all", text: "All", value: "all" });
    }
    return (
      <Grid className="subContainer">
        <Dimmer active={this.props.isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ width: "50%", margin: "auto" }}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h2" floated="left">
                    {this.props.formTitle}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <div style={{ marginTop: 36 }}>
                <Formik
                  initialValues={{
                    title: "",
                    message: "",
                    receiver: [
                      {
                        role: [],
                        centre_id: [],
                        class_id: [],
                      },
                    ],
                  }}
                  onSubmit={this.props.handleSubmit}
                >
                  {(formikProps) => {
                    // console.log("formik values", formikProps.values);
                    return (
                      <Form>
                        <Grid>
                          <Grid.Column>
                            <label className="formLabel">Title</label>
                            <Grid>
                              <Grid.Column>
                                <div className="formMargin">
                                  <Field
                                    name="title"
                                    component={RenderInput}
                                    onChange={formikProps.handleChange("title")}
                                    validate={isRequired}
                                    placeholder="Title"
                                    isTouched={formikProps.touched.title}
                                    isError={formikProps.errors.title}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                        </Grid>
                        <label className="formLabel">Description</label>
                        <div className="formMargin">
                          <Field
                            name="message"
                            component={RenderTextArea}
                            onChange={formikProps.handleChange("message")}
                            validate={isRequired}
                            placeholder="Description"
                            isTouched={formikProps.touched.message}
                            isError={formikProps.errors.message}
                          />
                        </div>
                        <div>
                          <label className="formLabel">
                            Attachment upload{" "}
                            <span className="fw400">(PDF file)</span>
                          </label>
                        </div>
                        {this.props.attachmentFileName && (
                          <div className="border-light2 mt16 br8 pl14 pr10 h44 flex flex-align-center flex-justify-space-between text-blue fs14 fw600 text-underline">
                            {this.props.attachmentFileName}
                            <div
                              className="pointer"
                              onClick={this.props.onRemovePdf}
                            >
                              <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="13"
                                  cy="13"
                                  r="12"
                                  fill="white"
                                  stroke="#4075FF"
                                />
                                <path
                                  d="M13.8787 13.0098L17.6168 9.27165C17.8601 9.0285 17.8601 8.63535 17.6168 8.3922C17.3736 8.14905 16.9805 8.14905 16.7373 8.3922L12.9992 12.1304L9.26112 8.3922C9.01785 8.14905 8.62482 8.14905 8.38167 8.3922C8.1384 8.63535 8.1384 9.0285 8.38167 9.27165L12.1197 13.0098L8.38167 16.748C8.1384 16.9911 8.1384 17.3843 8.38167 17.6274C8.50285 17.7487 8.66218 17.8096 8.82139 17.8096C8.98061 17.8096 9.13983 17.7487 9.26112 17.6274L12.9992 13.8893L16.7373 17.6274C16.8586 17.7487 17.0178 17.8096 17.1771 17.8096C17.3363 17.8096 17.4955 17.7487 17.6168 17.6274C17.8601 17.3843 17.8601 16.9911 17.6168 16.748L13.8787 13.0098Z"
                                  fill="#4075FF"
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                        <div style={styles.uploadContainer}>
                          <input
                            id="file-upload"
                            type="file"
                            accept=".pdf"
                            onChange={this.props.onChangePdf}                           
                          />
                          <span style={styles.uploadIcon}>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.7268 3.17738C4.82597 3.37513 5.0278 3.49996 5.24887 3.49996L6.41555 3.49996L6.41555 9.3333C6.41555 9.6553 6.6763 9.91663 6.99888 9.91663C7.32145 9.91663 7.58221 9.6553 7.58221 9.3333L7.58221 3.49998L8.74889 3.49998C8.96996 3.49998 9.17181 3.37516 9.27096 3.17741C9.36896 2.97966 9.34796 2.74341 9.21554 2.56666L7.46553 0.23334C7.45796 0.223414 7.44628 0.219914 7.4381 0.21059C7.3996 0.165089 7.3541 0.13009 7.30335 0.0980156C7.28235 0.0851915 7.26603 0.0682648 7.24328 0.0577648C7.1686 0.022765 7.08695 1.50625e-05 6.99885 1.50548e-05C6.91075 1.50471e-05 6.8291 0.0227649 6.75385 0.0577648C6.73168 0.0682647 6.71478 0.0851915 6.69435 0.0980156C6.6436 0.13009 6.5981 0.165089 6.5596 0.21059C6.55203 0.219914 6.53978 0.223414 6.53218 0.23334L4.78217 2.56666C4.6492 2.74338 4.6282 2.97963 4.7268 3.17738Z"
                                fill="#4075FF"
                              />
                              <path
                                d="M13.4167 8.74988C13.0941 8.74988 12.8334 9.0112 12.8334 9.3332V11.0832C12.8334 12.048 12.0482 12.8332 11.0834 12.8332H2.91668C1.95185 12.8332 1.16668 12.048 1.16668 11.0832V9.3332C1.16668 9.0112 0.905926 8.74988 0.583352 8.74988C0.26075 8.74988 0 9.0112 0 9.3332V11.0832C0 12.6915 1.30843 13.9999 2.91668 13.9999H11.0834C12.6916 13.9999 14 12.6915 14 11.0832V9.3332C14 9.0112 13.7393 8.74988 13.4167 8.74988Z"
                                fill="#4075FF"
                              />
                            </svg>
                          </span>
                          {this.props.errors.FileTypeError&& (
                          <span style={{position: "absolute",bottom: 12,color: "red"}}> Invalid File Type </span>
                          )}                                               
                        </div>
                        <div></div>
                        <FieldArray
                          name="receiver"
                          render={(arrayHelpers) => (
                            <div>
                              <label className="formLabel">Sent To</label>
                              {formikProps.values.receiver.map(
                                (sent, index) => {
                                  let classes = [],
                                    goldObj = {};

                                  if (
                                    this.props.userDetail &&
                                    this.props.userDetail.admin_type ===
                                      "centre"
                                  ) {
                                    goldObj = centerclasses.find(
                                      (result) =>
                                        result.id ===
                                        this.props.userDetail.centre[0].id
                                    );
                                  } else {
                                    goldObj = centerclasses.find(
                                      (result) => result.id === sent.centre_id
                                    );
                                  }

                                  if (sent.centre_id === "all") {
                                    classes.push(
                                      { key: "p1", text: "P1", value: "p1" },
                                      { key: "p2", text: "P2", value: "p2" },
                                      { key: "p3", text: "P3", value: "p3" },
                                      { key: "p4", text: "P4", value: "p4" },
                                      { key: "p5", text: "P5", value: "p5" },
                                      { key: "p6", text: "P6", value: "p6" }
                                    );
                                  } else {
                                    goldObj !== undefined &&
                                      goldObj.class.map((data) => {
                                        classes.push({
                                          key: data.id,
                                          text: data.name,
                                          value: data.id,
                                        });
                                      });
                                  }

                                  // goldObj = centerclasses.find(
                                  //   (result) => result.id === '8XbfLsbWOz09wfRh2Lxb'
                                  // );

                                  //   goldObj.class.map((data) => {
                                  //     classes.push({
                                  //       key: data.id,
                                  //       text: data.name,
                                  //       value: data.id,
                                  //     });
                                  //   })

                                  return (
                                    <div key={index}>
                                      <div className="formMargin infofield">
                                        {formikProps.values.receiver.length !==
                                        1 ? (
                                          <img
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            style={{
                                              float: "right",
                                              width: "24px",
                                              height: "24px",
                                              marginTop: "44px",
                                              marginRight: "10px",
                                            }}
                                            className=""
                                            src={CrossInfo}
                                          ></img>
                                        ) : (
                                          <img
                                            onClick={() =>
                                              alert("can not remove this item!")
                                            }
                                            style={{
                                              float: "right",
                                              width: "24px",
                                              height: "24px",
                                              marginTop: "44px",
                                              marginRight: "10px",
                                            }}
                                            className=""
                                            src={CrossInfo}
                                          ></img>
                                        )}
                                        <Grid divided="vertically">
                                          <Grid.Row columns={16}>
                                            <Grid.Column width={6}>
                                              <Field
                                                name={"centre_id"}
                                                component={RenderDropdown}
                                                onChange={formikProps.handleChange(
                                                  `receiver[${index}].centre_id`
                                                )}
                                                placeholder="Choose centre"
                                                options={centres.sort((a, b) =>
                                                  a.text !== b.text
                                                    ? a.text < b.text
                                                      ? -1
                                                      : 1
                                                    : 0
                                                )}
                                                isTouched={
                                                  formikProps.touched.centre_id
                                                }
                                                isError={
                                                  formikProps.errors.centre_id
                                                }
                                                value={
                                                  formikProps.values.receiver[
                                                    `${index}`
                                                  ].centre_id.length !== 0
                                                    ? formikProps.values
                                                        .receiver[`${index}`]
                                                        .centre_id
                                                    : ""
                                                  // : localStorage.getItem(
                                                  //     "centre_id"
                                                  //   )
                                                }
                                              />
                                            </Grid.Column>

                                            <Grid.Column width={10}>
                                              <Field
                                                name={`receiver[${index}].class_id`}
                                                component={RenderPButtonGroup}
                                                onChange={formikProps.handleChange(
                                                  `receiver[${index}].class_id`
                                                )}
                                                //validate={isRequired}
                                                options={classes.sort((a, b) =>
                                                  a.text !== b.text
                                                    ? a.text < b.text
                                                      ? -1
                                                      : 1
                                                    : 0
                                                )}
                                                isTouched={
                                                  formikProps.touched.chkboxarr
                                                }
                                                isError={
                                                  formikProps.errors.chkboxarr
                                                }
                                                value={
                                                  formikProps.values.receiver[
                                                    `${index}`
                                                  ].class_id
                                                }
                                              />
                                            </Grid.Column>
                                          </Grid.Row>

                                          <Field
                                            name={`receiver[${index}].role`}
                                            component={RenderCheckboxGroup}
                                            options={this.props.admin}
                                            onChange={formikProps.handleChange(
                                              `receiver[${index}].role`
                                            )}
                                            //validate={isRequired}
                                            isTouched={formikProps.touched.role}
                                            isError={formikProps.errors.role}
                                            value={
                                              formikProps.values.receiver[
                                                `${index}`
                                              ].role
                                            }
                                          />
                                        </Grid>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                              <p
                                style={{
                                  color: "#4075FF",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  float: "right",
                                  marginTop: "-18px",
                                }}
                                onClick={() => {
                                  arrayHelpers.push({
                                    centre_id: [],
                                    class_id: [],
                                    role: [],
                                  });
                                }}
                              >
                                {" "}
                                Add
                              </p>
                            </div>
                          )}
                        />

                        {/* 
                          <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                              <Grid.Column>
                                <label className="formLabel">Sent Date</label>
                                <Field
                                  name="sentdate"
                                  component={RenderDatePicker}
                                  onChange={formikProps.handleChange('sentdate')}
                                  validate={isRequired}
                                  isTouched={formikProps.touched.sentdate}
                                  isError={formikProps.errors.sentdate}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <label className="formLabel">Sent Time</label>
                                <Field
                                  name="senttime"
                                  component={RenderTimePicker}
                                  onChange={formikProps.handleChange('senttime')}
                                  validate={isRequired}
                                  isTouched={formikProps.touched.senttime}
                                  isError={formikProps.errors.senttime}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid> */}

                        <div className="fullWidthCenterAlign">
                          <Button
                            content="Send"
                            primary
                            size="large"
                            type="submit"
                            className="submitBtn"
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const styles = {
  uploadContainer: {
    position: "relative",
    display: "flex",
  },
  uploadIcon: { position: "absolute", left: 37, top: 30 },
  uploadText: {
    paddingTop: 38,
    width: "71%",
    paddingRight: 10,
  },
};

export default NotificationFrom;
