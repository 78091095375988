import React, { Component } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import RoutingButton from "../../components/RoutingButton";
import { DONATION_ROUTE } from "../../routes";
import DonationEditForm from "./DonationEditForm";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import { connect } from "react-redux";
import moment from "moment";
import S3 from "react-aws-s3";
import base64encode from "@wmakeev/base64encode";
import { getAllDonationlist } from "../../store/actions";

const config = {
  bucketName: "morning-star-dev",
  dirName: "videos",
  region: "ap-southeast-1",
  //DEV
  // accessKeyId: "AKIAQFC7EOAWNZBPI7M6",
  // secretAccessKey: "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",
  //QA
  // accessKeyId: "AKIAQFC7EOAWMK5PYOKQ",
  // secretAccessKey: "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",
  accessKeyId: "AKIAQFC7EOAWH7DDHKEY",
  secretAccessKey: "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",
};

class EditDonation extends Component {
  state = {
    media: [
      { value: "Image", label: "Image" },
      { value: "Video", label: "Video" },
    ],
    previewUrl: undefined,
    previewVideoUrl: undefined,
    previewDate: new Date(),
    previewDateTo: new Date(),
    timeFrom: "",
    timeTo: "",
    imgs: null,
    video: null,
    date: null,
    dateFromChange: false,
    dateToChange: false,
    isloading: false,
  };

  componentDidMount = () => {
    if (this._isMounted === true) {
      this.props.getAllDonationlist();
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleSubmit = (values) => {
    this.setState({ isloading: true });
    console.log(this.state.previewDate);
    console.log(this.state.previewDateTo);
    if (values.media_type === "Video") {
      if (this.state.video === null) {
        let donid = this.props.match.params.id;
        let formdata = new FormData();
        if (values.total_needs.length > 6) {
          alert("Total needs should be 6 characters or less");
        }
        // else if (this.state.previewDate < this.state.previewDateTo) {
        else {
          // console.log(4);
          formdata.append("id", donid);
          formdata.append("title", values.title);
          formdata.append("description", values.description);
          formdata.append("duration", "50");
          formdata.append("range", values.range);
          formdata.append("total_needs", values.total_needs);
          formdata.append("from_date", localStorage.getItem("dateFromSend"));
          formdata.append("to_date", localStorage.getItem("dateToSend"));
          formdata.append("from_time", "00:00:00");
          formdata.append("to_time", "00:00:00");
          formdata.append("video_url", values.video_url);
          // formdata.append('media_type', values.media_type)
          // console.log(...formdata);
          return firebase.reloadAuth().then((res) => {
            try {
              const token = res.ya === null ? res.za : res.ya;

              api.post("/donation", formdata, token).then((res) => {
                if (res.message === "success") {
                  this.props.dispatch(push(DONATION_ROUTE));
                  this.setState({ isloading: false });
                } else {
                  alert(`${res.message}`);
                  this.setState({ isloading: false });
                }
              });
            } catch (err) {
              console.log(err);
            }
          });
        }
      }
      //  else {
      //   console.log(5);
      //   alert("date to must greater than date from");
      // }
      if (this.state.video !== null) {
        const vName = this.state.video.name.split(".");
        const filename =
          vName[0] + "-" + moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
        const final_filename = base64encode(filename);
        console.log(final_filename);
        const VType = this.state.video.type.split("/");
        const VName = final_filename + "." + VType[1];
        let file = this.state.video;
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, VName).then((data) => {
          // console.log(data);
          this.setState(
            {
              video: `https://d2t2athllyh1ku.cloudfront.net/${data.key}`,
            },
            () => {
              const img = localStorage.getItem("img_url");
              let donid = this.props.match.params.id;
              let formdata = new FormData();
              if (values.total_needs.length > 6) {
                alert("Totalneeds should be 6 characters or less");
              } else if (
                new Date(localStorage.getItem("dateFromSend")) <
                new Date(localStorage.getItem("dateToSend"))
              ) {
                formdata.append("id", donid);
                formdata.append("title", values.title);
                formdata.append("description", values.description);
                formdata.append("duration", "50");
                formdata.append("range", values.range);
                formdata.append("total_needs", values.total_needs);
                formdata.append(
                  "from_date",
                  localStorage.getItem("dateFromSend")
                );
                formdata.append("to_date", localStorage.getItem("dateToSend"));
                formdata.append("from_time", "00:00:00");
                formdata.append("to_time", "00:00:00");
                formdata.append("video_url", this.state.video);
                // formdata.append('media_type', values.media_type)
                return firebase.reloadAuth().then((res) => {
                  try {
                    const token = res.ya === null ? res.za : res.ya;

                    api.post("/donation", formdata, token).then((res) => {
                      if (res.message === "success") {
                        this.props.dispatch(push(DONATION_ROUTE));
                        this.setState({ isloading: false });
                      } else {
                        alert(`${res.message}`);
                        this.setState({ isloading: false });
                      }
                    });
                  } catch (err) {
                    console.log(err);
                  }
                });
              } else {
                alert("date to must greater than date from");
              }
            }
          );
        });
      }
    } else {
      let donid = this.props.match.params.id;
      let formdata = new FormData();
      if (values.total_needs.length > 6) {
        alert("Total needs should be 6 characters or less");
      }
      // else if (this.state.previewDate < this.state.previewDateTo) {
      else {
        // console.log(4);
        const link =
          values.link === values.img_url ? values.img_url : values.link;
        formdata.append("id", donid);
        formdata.append("title", values.title);
        formdata.append("description", values.description);
        formdata.append("duration", "50");
        formdata.append("range", values.range);
        formdata.append("total_needs", values.total_needs);
        formdata.append("from_date", localStorage.getItem("dateFromSend"));
        formdata.append("to_date", localStorage.getItem("dateToSend"));
        formdata.append("from_time", "00:00:00");
        formdata.append("to_time", "00:00:00");
        formdata.append(
          "img_url",
          this.state.imgs === null ? link : this.state.imgs
        );
        return firebase.reloadAuth().then((res) => {
          try {
            const token = res.ya === null ? res.za : res.ya;

            api.post("/donation", formdata, token).then((res) => {
              if (res.message === "success") {
                this.props.dispatch(push(DONATION_ROUTE));
                this.setState({ isloading: false });
              } else {
                alert(`${res.message}`);
                this.setState({ isloading: false });
              }
            });
          } catch (err) {
            console.log(err);
          }
        });
      }
    }
  };

  onChange = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    this.setState({ imgs: event.target.files[0] });
  };

  handleChange = (event) => {
    console.log(event.target.files[0]);
    if (event.target.files[0] !== undefined) {
      this.setState({
        previewVideoUrl: URL.createObjectURL(event.target.files[0]),
        video: event.target.files[0],
      });
    }
  };

  onDateFromChange = (date) => {
    localStorage.setItem("dateFromSend", moment(date).format("YYYY-MM-DD"));
    this.setState({ previewDate: date, dateFromChange: true });
  };
  onDateToChange = (date) => {
    localStorage.setItem("dateToSend", moment(date).format("YYYY-MM-DD"));
    this.setState({ previewDateTo: date, dateToChange: true });
  };
  render() {
    // console.log(this.props.match.params);
    // console.log(this.props);

    const { donation } = this.props;
    // console.log(donation);

    // const { donation = [] } = this.props;
    let oneRecord = [];
    oneRecord =
      donation !== [] &&
      donation.find((result) => result.id === this.props.match.params.id);

    // oneRecord =
    // donation !== [] &&
    // donation.find((result) => result.id === localStorage.getItem("id"));

    return (
      <Grid className="fullHeight">
        <Dimmer active={this.state.isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={DONATION_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <DonationEditForm
              handleSubmit={this.handleSubmit}
              options={this.state.media}
              onChange={this.onChange}
              previewUrl={this.state.previewUrl}
              previewVideoUrl={this.state.previewVideoUrl}
              previewDate={this.state.previewDate}
              handleChange={this.handleChange}
              previewDateTo={this.state.previewDateTo}
              formtitle={"Edit Donation"}
              onDateChange={this.onDateFromChange}
              onDateToChange={this.onDateToChange}
              initialValues={oneRecord}
              isEdit={true}
              changeDateFrom={this.state.dateFromChange}
              changeDateTo={this.state.dateToChange}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  donation:
    state.donation.donation !== undefined ? state.donation.donation.data : [],
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getAllDonationlist: () => dispatch(getAllDonationlist()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDonation);
