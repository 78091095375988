import React from "react";
import {
  Button,
  Modal,
  Header,
  Image,
  TransitionablePortal,
  Icon,
  Grid,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { LOGIN_ROUTE, USERMANAGEMENT_ROUTE } from "../routes";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import usr from "../assets/images/usr.png";
import Group from "../assets/images/Group.svg";
import Xclose from "../assets/images/Xclose.svg";
import { reduce } from "lodash-es";
import Pf_Default from "../assets/images/ic_account.png";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer, size: action.size };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

function UserSetting(props) {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
    size: undefined,
  });
  const EditReducer = (id) => {
    const { dispatch } = props;
    dispatch(push(`${USERMANAGEMENT_ROUTE}/edit/admin/${id}`));
    localStorage.setItem("user_type", "admin");
  };
  // function  EditReducer  (id)  {
  //   const { dispatch} = this.props;
  //   dispatch(push(`${USERMANAGEMENT_ROUTE}/edit/admin/${id}`));
  // }
  const { open, dimmer, size } = state;
  // console.log(state);
  const detail = props.detail;
  const _logout = () => {
    localStorage.clear();
    props.doAction();
    // dispatch(push(LOGIN_ROUTE));
  };
  // console.log("Usersetting");
  // console.log("props", props);
  return (
    <div>
      <Button
        onClick={() =>
          dispatch({ type: "OPEN_MODAL", dimmer: "", size: "mini" })
        }
        className="user-setting"
      >
        Setting <Icon name="angle right" />
      </Button>
      <TransitionablePortal
        open={open}
        transition={{ animation: "scale", duration: 400 }}
      >
        <Modal
          size={size}
          dimmer={dimmer}
          open={open}
          onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        >
          <Modal.Header align="right" style={{ border: 0 }}>
            <Image
              src={Xclose}
              style={{ cursor: "pointer" }}
              onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            />
          </Modal.Header>
          <Modal.Content align="center">
            <Image
              src={detail.img === "" ? Pf_Default : detail.img}
              verticalAlign="middle"
              className="user-setting-icon"
              as={"img"}
              onError={(e) => (e.target.src = Pf_Default)}
            />
            <p className="user-role">Admin</p>
            <Header as="h2" style={{ margin: 0 }}>
              {detail.name}{" "}
              <Image
                src={Group}
                onClick={() => EditReducer(detail.id)}
                verticalAlign="top"
                style={{ width: 24, height: 24 }}
              ></Image>
            </Header>
            <p className="Bedok">
              {detail.centre ? detail.centre.length > 1 ? "All Centre" : detail.centre[0].name : ""}
            </p>
            <p>{detail.email}</p>
            <Button
              className="button-logout"
              onClick={_logout}
              loading={props.isloading}
            >
              Logout
            </Button>
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    </div>
  );
}

export default connect()(UserSetting);
