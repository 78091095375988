import { FETCH_MERCHANDISE_TYPE, FETCH_MERCHANDISE_TYPE_SUCCESS, FETCH_MERCHANDISE_TYPE_FAILURE, CLEAR_MERCHANDISE_TYPE } from '../actions';

const initialState = {
  merchandisetype: {},
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_MERCHANDISE_TYPE:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_MERCHANDISE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        merchandisetype: action.data
      };
    case FETCH_MERCHANDISE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        merchandisetype: {}
      };
    case CLEAR_MERCHANDISE_TYPE:
      return initialState;
    default:
      return state;
  }
}