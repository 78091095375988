import api from "../../config/api";
import { firebase } from "../../config/firebase";
import dateFormat from "dateformat";
export const FETCH_ATTENDANCE = "FETCH_ATTENDANCE";
export const FETCH_ATTENDANCE_SUCCESS = "FETCH_ATTENDANCE_SUCCESS";
export const FETCH_ATTENDANCE_FAILURE = "FETCH_ATTENDANCE_FAILURE";
export const FETCH_ATTENDANCE_LOAD = "FETCH_ATTENDANCE_LOAD";
export const FETCH_ATTENDANCE_LOAD_SUCCESS = "FETCH_ATTENDANCE_LOAD_SUCCESS";
export const FETCH_ATTENDANCE_LOAD_FAILURE = "FETCH_ATTENDANCE_LOAD_FAILURE";

export const fetchAttendance = (
  start_date,
  end_date,
  class_id,
  centre_id,
  size,
  scfa
) => ({
  type: FETCH_ATTENDANCE,
  payload: {
    start_date,
    end_date,
    centre_id,
    class_id,
    size,
    scfa,
  },
});

export const fetchAttendanceSuccess = (data) => ({
  type: FETCH_ATTENDANCE_SUCCESS,
  data,
});

export const fetchAttendanceFailure = () => ({
  type: FETCH_ATTENDANCE_FAILURE,
});

export const fetchLoadMoreSuccess = (data) => ({
  type: FETCH_ATTENDANCE_LOAD_SUCCESS,
  data,
});

export const fetchLoadMoreFailure = () => ({
  type: FETCH_ATTENDANCE_LOAD_FAILURE,
});

export const getMoreAttenadance = (link, nextPage) => {
  return (dispatch) => {
    dispatch(fetchAttendance());
    return firebase.reloadAuth().then((res) => {
      let start_date;
      let end_date;
      let centre_id;
      let class_id;
      try {
        if (
          (start_date === undefined || start_date === null) &&
          (end_date === undefined || end_date === null)
        ) {
          let date = new Date();
          start_date = dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          );
          end_date = dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 1),
            "yyyy-mm-dd"
          );
        }
        //console.log('start', centre_id,class_id)
        if (centre_id === undefined && class_id === undefined) {
          centre_id =
            localStorage.getItem("centre_id") !== null
              ? localStorage.getItem("centre_id")
              : "8XbfLsbWOz09wfRh2Lxb";
          class_id =
            localStorage.getItem("class_id") !== null
              ? localStorage.getItem("class_id") === "All"
                ? ""
                : localStorage.getItem("class_id")
              : "";
        }
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/attendance/",
            {
              start_date: "" + start_date,
              end_date: "" + end_date,
              centre_id: "" + centre_id,
              class_id: "" + class_id,
              page: nextPage,
              size: 10,
            },
            token
          )
          .then((res) => {
            dispatch(fetchAttendanceSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchLoadMoreFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};

export const getAttendace = (
  start_date,
  end_date,
  centre_id,
  class_id,
  scfa
) => {
  console.log(start_date, end_date, centre_id, class_id, scfa);
  let classid = class_id === "All" ? "" : class_id ? class_id : "";
  return (dispatch) => {
    dispatch(fetchAttendance());
    return firebase.reloadAuth().then((res) => {
      try {
        //api.get('/user/', {user_type: user_type, centre_id: centre_id, class_id: class_id, admin_type: admin_type, size: size }, res.za)
        if (
          (start_date === undefined || start_date === null) &&
          (end_date === undefined || end_date === null)
        ) {
          let date = new Date();
          start_date = dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          );
          end_date = dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 1),
            "yyyy-mm-dd"
          );
        }
        //console.log('start', centre_id,class_id)
        if (centre_id === undefined && class_id === undefined) {
          centre_id =
            localStorage.getItem("centre_id") !== null
              ? localStorage.getItem("centre_id")
              : "8XbfLsbWOz09wfRh2Lxb";
          class_id =
            localStorage.getItem("class_id") !== null
              ? localStorage.getItem("class_id")
              : "VmVezm1hvKczeM1j6YYN";
        }
        const token = res.ya === null ? res.za : res.ya;

        if (scfa === 3 || scfa === undefined) {
          api
            .get(
              "/attendance/",
              {
                start_date: "" + start_date,
                end_date: "" + end_date,
                centre_id: "" + centre_id,
                class_id: "" + classid,
                page: 0,
                size: 10,
              },
              token
            )
            .then((res) => {
              dispatch(fetchAttendanceSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchAttendanceFailure(err)));
        } else {
          api
            .get(
              "/attendance/",
              {
                start_date: "" + start_date,
                end_date: "" + end_date,
                centre_id: "" + centre_id,
                class_id: "" + classid,
                scfa: "" + scfa,
              },
              token
            )
            .then((res) => {
              dispatch(fetchAttendanceSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchAttendanceFailure(err)));
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
};
