import { FETCH_SCHEDULE_ITEMS, FETCH_SCHEDULE_ITEMS_SUCCESS, FETCH_SCHEDULE_ITEMS_FAILURE } from '../actions';

const initialState = {
  scheduleItems: [],
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_SCHEDULE_ITEMS:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_SCHEDULE_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        scheduleItems: action.data
      };
    case FETCH_SCHEDULE_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        scheduleItems: []
      };
    default:
      return state;
  }
}