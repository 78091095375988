import React from 'react';
import { Route } from 'react-router'
import { USERMANAGEMENT_ROUTE, USER_CREATE_ROUTE } from '../../routes';
import UserManagementList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

export const UserManagement = () => (
  <React.Fragment>
    <Route exact path={USERMANAGEMENT_ROUTE} component={UserManagementList} />
    <Route exact path={USER_CREATE_ROUTE} component={UserCreate} />
    <Route exact path="/user_management/edit/:user_type/:id" component={UserEdit}/>
  </React.Fragment>
);

export default UserManagement;
