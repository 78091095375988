import React, { Component } from "react";
import {
  Grid,
  Header,
  Button,
  Container,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import RenderInput from "../../components/TextInput";
//import RenderDropdown from "../../components/DropDown";
import RenderDropdown from "../../components/DropDown";
import { isRequired, isEmail, isPhone } from "../../validators";
import RenderRadioGroup from "../../components/RadioGroup";
import RenderProfileImgUpload from "../../components/ProfileImage";
import RenderDatePicker from "../../components/DatePickerSF";
import RenderCheckbox from "../../components/CheckBox";
import { getUsers } from "../../store/actions";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { firebase } from "../../config/firebase";
import api from "../../config/api";

const inputStyle = {
  width: "100%",
  padding: 10,
  borderRadius: 8,
  borderWidth: 1,
  borderColor: "rgba(34,36,38,.15)",
  color: "#DADADA",
  backgroundColor: "white",
};

const initialState = {};
let userType = "";
class UserFrom extends Component {
  state = {
    dob:
      this.props.userDetail && this.props.userDetail.dob
        ? dateFormat(this.props.userDetail.dob, "yyyy mmm dd")
        : dateFormat(new Date(), "yyyy mmm dd"),
    classid: "",
    arr: [],
    arrfacilitor: [],
    selectedCentreId: [],
    page: 1,
    studentOptions: [],
    studentNextPage: null,
  };

  onChange = (e, data) => {
    this.setState({
      arr: [],
      arrfacilitor: [],
    });
    if (data.value == "centre") {
      localStorage.setItem("user_type", "admin");
    } else {
      localStorage.setItem("user_type", data.value);
    }
  };
  resetFacArr = () => {
    return this.setState({
      arr: [],
      arrfacilitor: [],
    });
  };

  handleChangeSelectedCentre = (data) => {
    this.setState({
      selectedCentreId: data,
    });
  };

  componentDidMount() {
    localStorage.removeItem("class_id");
    // this.props.getUsers(
    //   "student",
    //   "KNSLmqsAF7gWWXdudWGM",
    //   "11Pxr6cnodcS0qsf4MaG"
    // );
    userType = localStorage.getItem("user_type");
    localStorage.setItem("dob", dateFormat(new Date(), "yyyy mmm dd"));
    this.setState({ dob: localStorage.getItem("dob") });
    if (this.props.initialValues && this.props.initialValues.centre) {
      if (this.props.initialValues.centre.length == 1) {
        this.setState({
          selectedCentreId: this.props.initialValues.centre[0].id,
        });
      } else {
        this.setState({
          selectedCentreId: "all",
        });
      }
    }

    if (
      this.props.userDetail &&
      this.props.userDetail != undefined &&
      this.props.userDetail.class
    ) {
      this.setState({
        classid: this.props.userDetail.class[0].id,
        dob: dateFormat(this.props.userDetail.dob, "yyyy mmm dd"),
      });
    }
    if (
      this.props.initialValues &&
      this.props.initialValues.children != undefined &&
      this.props.initialValues.children
    ) {
      let maped = this.props.initialValues.children.map((m) => {
        return {
          value: m.id,
          label: m.name,
        };
      });
      var joinedInitail = this.state.arr.concat(maped);
      this.setState({ arr: joinedInitail });
    } else {
    }
    if (
      this.props.initialValues &&
      this.props.initialValues.class != undefined &&
      this.props.initialValues.class
    ) {
      let mapedFacClass = this.props.initialValues.class.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      });
      var joinedInitailFacilitor =
        this.state.arrfacilitor.concat(mapedFacClass);
      this.setState({ arrfacilitor: joinedInitailFacilitor });
    } else {
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userDetail != prevProps.userDetail) {
    }
  }

  componentWillUnmount() {
    // localStorage.removeItem("user_type");
    localStorage.removeItem("class_id");
    localStorage.removeItem("child_names");
    // localStorage.setItem("user_type", userType);
    localStorage.removeItem("dob");
  }
  SelectdDate = (date) => {
    this.setState({ dob: date }, () => {
      localStorage.setItem("dob", dateFormat(date, "yyyy mmm dd"));
    });
  };

  childnamesHandle = (data) => {
    var joined = this.state.arr.concat(data);
    this.setState({ arr: data });
  };

  facilitorClass = (data) => {
    var joinedfac = this.state.arrfacilitor.concat(data);

    this.setState({ arrfacilitor: data });
  };

  loadStudentOptions = async (searchQuery, loadedOptions, { page }) => {
    return firebase
      .reloadAuth()
      .then(async (res) => {
        const token = res.ya === null ? res.za : res.ya;

        const response = await api.get(
          "/user",
          {
            user_type: "student",
            // centre_id: this.state.selectedCentreId,
            // class_id: localStorage.getItem("class_id"),
            centre_id:
              localStorage.getItem("adminType") == "centre"
                ? localStorage.getItem("centre_Id")
                : "",
            class_id: "",
            page: page - 1,
            admin_type: "",
            search_string: searchQuery,
          },
          token
        );
        const options = [];
        response.data.map((data) => {
          options.push({
            key: data.id,
            value: data.id,
            label: data.name,
          });
        });
        this.setState({
          studentOptions: options,
        });
        return {
          options: this.state.studentOptions,
          hasMore: response.next,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        };
      })
      .catch((err) => console.log("errS", err));
  };

  getStudent = () => {
    //this.props.getUsers(user_type,centre_id,class_id)
    // localStorage.getItem("admin_centre_id"),
    this.props.getUsers(
      "student",
      this.state.selectedCentreId,
      localStorage.getItem("class_id"),
      0
    );
    //this.componentDidMount;
  };
  render() {
    const { initialValues, isEdit, usersList, userDetail } = this.props;
    let user_type = localStorage.getItem("user_type");
    let center_list = JSON.parse(localStorage.getItem("centre_list")),
      classes = [],
      list = [],
      students = [];

    if (initialValues !== undefined) {
      initialValues.admin_type =
        user_type === "admin" || user_type === null ? "centre" : user_type;
    }

    // if (initialValues !== undefined && initialValues.admin_type != "centre") {
    //   this.setState({
    //     classid: initialValues.class[0].centre_id,
    //   });
    // }

    return (
      <Grid className="subContainer">
        <Dimmer active={this.props.isloading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ width: "50%", margin: "auto" }}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h2" floated="left">
                    {this.props.formTitle}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <div style={{ marginTop: 36 }}>
                <Formik
                  initialValues={
                    isEdit
                      ? initialValues
                      : {
                          admin_type:
                            user_type === "admin" || user_type === null
                              ? "centre"
                              : user_type,
                          //admin_type: "",
                          employee_no: "",
                          centre_id:
                            localStorage.getItem("admin_centre_id") || [],
                          img: "",
                          name: "",
                          student_no: "",
                          email: "",
                          school: "",
                          scfa: "",
                          children_id: [],
                          facilitor_id: [],
                          dob: "",
                          relationship: "",
                          phone: "",
                          class_id: "",
                        }
                  }
                  onSubmit={this.props.handleSubmit}
                >
                  {(formikProps) => {
                    let centres_list = [];

                    // if (localStorage.getItem("adminType") === "centre") {
                    //   centres_list.push({
                    //     key: userDetail.centre[0].id,
                    //     text: userDetail.centre[0].name,
                    //     value: userDetail.centre[0].id,
                    //   });

                    // } else {
                    //   this.props.centers.map((data) => {
                    //     centres_list.push({
                    //       key: data.key,
                    //       text: data.text,
                    //       value: data.value,
                    //     });
                    //   }, formikProps.values.admin_type === "centre" && centres_list.push({ key: "1", value: "all", text: "All Centres" }));
                    // }

                    if (this.props.centers) {
                      if (localStorage.getItem("adminType") == "centre") {
                        this.props.centers
                          .filter(
                            (c) => c.value == localStorage.getItem("centre_Id")
                          )
                          .map((data) => {
                            centres_list.push({
                              key: data.key,
                              text: data.text,
                              value: data.value,
                            });
                          }, formikProps.values.admin_type === "centre" && centres_list.push({ key: "1", value: "all", text: "All Centres" }));
                      } else {
                        this.props.centers.map((data) => {
                          centres_list.push({
                            key: data.key,
                            text: data.text,
                            value: data.value,
                          });
                        }, formikProps.values.admin_type === "centre" && centres_list.push({ key: "1", value: "all", text: "All Centres" }));
                      }
                    }
                    // this.props.centers.push({ key: "", text: "All Centres", value: "all" }):[], this.props.centers
                    students = [];
                    usersList !== undefined &&
                      usersList.map((data) => {
                        students.push({
                          key: data.id,
                          value: data.id,
                          label: data.name,
                        });
                      });
                    if (localStorage.getItem("adminType") == "centre") {
                      list = center_list.find(
                        (data) => data.id === localStorage.getItem("centre_Id")
                      );
                    } else {
                      list = center_list.find(
                        (data) => data.id === this.state.selectedCentreId
                        // (formikProps.values.centre_id === ""
                        //   ? "8XbfLsbWOz09wfRh2Lxb"
                        //   : typeof formikProps.values.centre_id === "object"
                        //   ? formikProps.values.centre_id[0]
                        //   : formikProps.values.centre_id)
                      );
                    }

                    classes = [];

                    if (localStorage.getItem("adminType") == "centre") {
                      user_type == "facilitator"
                        ? list !== undefined &&
                          list.class.map((data) => {
                            classes.push({
                              key: data.id,
                              value: data.id,
                              label: data.name,
                              // text: data.name,
                            });
                          })
                        : list !== undefined &&
                          list.class.map((data) => {
                            classes.push({
                              key: data.id,
                              value: data.id,

                              text: data.name,
                            });
                          });
                    } else {
                      user_type == "facilitator"
                        ? list !== undefined &&
                          list.class.map((data) => {
                            classes.push({
                              key: data.id,
                              value: data.id,
                              label: data.name,
                              // text: data.name,
                            });
                          })
                        : list !== undefined &&
                          list.class.map((data) => {
                            classes.push({
                              key: data.id,
                              value: data.id,
                              text: data.name,
                            });
                          });
                    }

                    isEdit &&
                      localStorage.getItem("user_type") != "parent" &&
                      formikProps.values.centre_id &&
                      formikProps.values.centre_id.length > 1 &&
                      (formikProps.values.centre_id = "all");
                    return (
                      <Form>
                        <label className="formLabel">Role</label>
                        <div className="formMargin user_management_role">
                          <Field
                            name="admin_type"
                            component={RenderRadioGroup}
                            options={this.props.options}
                            onClick={this.onChange}
                            onChange={formikProps.handleChange("admin_type")}
                            validate={isRequired}
                            disabled={isEdit ? true : false}
                            isTouched={formikProps.touched.admin_type}
                            isError={formikProps.errors.admin_type}
                            value={formikProps.values.admin_type}
                            chgBg={true}
                          />
                        </div>
                        {formikProps.values.admin_type === "centre" &&
                          localStorage.getItem("adminType") == "centre" && (
                            <Grid.Column>
                              <label className="formLabel">Employee ID</label>
                              <div className="formMargin">
                                <Field
                                  name="employee_no"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange(
                                    "employee_no"
                                  )}
                                  validate={isRequired}
                                  placeholder="Enter Employee ID"
                                  isTouched={formikProps.touched.employee_no}
                                  isError={formikProps.errors.employee_no}
                                  value={formikProps.values.employee_no}
                                />
                              </div>
                            </Grid.Column>
                          )}
                        {formikProps.values.admin_type !== "parent" &&
                          formikProps.values.admin_type !== "student" && (
                            <Grid columns={2}>
                              <Grid.Row>
                                {localStorage.getItem("adminType") == "hq" && (
                                  <Grid.Column>
                                    <label className="formLabel">
                                      Employee ID
                                    </label>
                                    <div className="formMargin">
                                      <Field
                                        name="employee_no"
                                        component={RenderInput}
                                        onChange={formikProps.handleChange(
                                          "employee_no"
                                        )}
                                        validate={isRequired}
                                        placeholder="Enter Employee ID"
                                        isTouched={
                                          formikProps.touched.employee_no
                                        }
                                        isError={formikProps.errors.employee_no}
                                        value={formikProps.values.employee_no}
                                      />
                                    </div>
                                  </Grid.Column>
                                )}

                                {localStorage.getItem("adminType") == "hq" &&
                                  formikProps.values.admin_type ===
                                    "facilitator" && (
                                    <Grid.Column>
                                      <div className="formMargin">
                                        <label className="formLabel2">
                                          Centre
                                        </label>
                                        <Field
                                          name="centre_id"
                                          component={RenderDropdown}
                                          // onChange={formikProps.handleChange(
                                          //   "centre_id"
                                          // )}
                                          onChange={
                                            this.handleChangeSelectedCentre
                                          }
                                          validate={isRequired}
                                          //onBlur= {this.props.loadChange}
                                          placeholder="Choose Center"
                                          isEdit={isEdit}
                                          isTouched={
                                            formikProps.touched.centre_id
                                          }
                                          isError={formikProps.errors.centre_id}
                                          options={centres_list.sort((a, b) =>
                                            a.text !== b.text
                                              ? a.text < b.text
                                                ? -1
                                                : 1
                                              : 0
                                          )}
                                          value={
                                            (formikProps.values.centre_id =
                                              this.state.selectedCentreId)
                                          }
                                          selectedValue={
                                            formikProps.values.centre_id
                                          }
                                          resetfacacc={this.resetFacArr}
                                          resetCheck={true}
                                        />
                                      </div>
                                    </Grid.Column>
                                  )}

                                {formikProps.values.admin_type === "centre" &&
                                  localStorage.getItem("adminType") == "hq" && (
                                    <Grid.Column>
                                      <div className="formMargin">
                                        <div>
                                          <label className="formLabel2">
                                            Centre
                                          </label>
                                          <Field
                                            name="centre_id"
                                            component={RenderDropdown}
                                            // onChange={formikProps.handleChange(
                                            //   "centre_id"
                                            // )}
                                            onChange={
                                              this.handleChangeSelectedCentre
                                            }
                                            validate={isRequired}
                                            //onBlur= {this.props.loadChange}
                                            placeholder="Choose Center"
                                            isEdit={isEdit}
                                            isTouched={
                                              formikProps.touched.centre_id
                                            }
                                            isError={
                                              formikProps.errors.centre_id
                                            }
                                            options={centres_list.sort((a, b) =>
                                              a.text !== b.text
                                                ? a.text < b.text
                                                  ? -1
                                                  : 1
                                                : 0
                                            )}
                                            value={
                                              (formikProps.values.centre_id =
                                                this.state.selectedCentreId)
                                            }
                                            selectedValue={
                                              formikProps.values.centre_id
                                            }
                                          />
                                        </div>
                                      </div>
                                    </Grid.Column>
                                  )}
                              </Grid.Row>
                            </Grid>
                          )}
                        {formikProps.values.admin_type === "centre" &&
                          localStorage.getItem("adminType") == "centre" && (
                            <Grid.Column>
                              <div className="formMargin">
                                <div>
                                  <label className="formLabel2">Centre</label>
                                  <Field
                                    name="centre_id"
                                    component={RenderDropdown}
                                    // onChange={formikProps.handleChange(
                                    //   "centre_id"
                                    // )}
                                    onChange={this.handleChangeSelectedCentre}
                                    validate={isRequired}
                                    //onBlur= {this.props.loadChange}
                                    placeholder="Choose Center"
                                    isEdit={isEdit}
                                    isTouched={formikProps.touched.centre_id}
                                    isError={formikProps.errors.centre_id}
                                    options={centres_list.sort((a, b) =>
                                      a.text !== b.text
                                        ? a.text < b.text
                                          ? -1
                                          : 1
                                        : 0
                                    )}
                                    value={
                                      (formikProps.values.centre_id =
                                        this.state.selectedCentreId)
                                    }
                                    selectedValue={formikProps.values.centre_id}
                                  />
                                </div>
                              </div>
                            </Grid.Column>
                          )}

                        {formikProps.values.admin_type !== "centre" &&
                          formikProps.values.admin_type !== "facilitator" &&
                          formikProps.values.admin_type !== "parent" && (
                            <Grid columns={2}>
                              <Grid.Row>
                                <Grid.Column>
                                  <div>
                                    <label className="formLabel2">Centre</label>
                                    <Field
                                      name="centre_id"
                                      component={RenderDropdown}
                                      // onChange={formikProps.handleChange(
                                      //   "centre_id"
                                      // )}
                                      onChange={this.handleChangeSelectedCentre}
                                      validate={
                                        formikProps.values.admin_type ==
                                          "student" && isRequired
                                      }
                                      //onBlur= {this.props.loadChange}
                                      placeholder="Choose Center"
                                      isEdit={isEdit}
                                      isTouched={
                                        formikProps.values.admin_type ==
                                          "student" &&
                                        formikProps.touched.centre_id
                                      }
                                      isError={
                                        formikProps.values.admin_type ==
                                          "student" &&
                                        formikProps.errors.centre_id
                                      }
                                      // options={centres_list.filter(
                                      //   (centre) =>
                                      //     centre.key ===
                                      //     formikProps.values.centre_id[0]
                                      // )}
                                      options={centres_list.sort((a, b) =>
                                        a.text !== b.text
                                          ? a.text < b.text
                                            ? -1
                                            : 1
                                          : 0
                                      )}
                                      value={
                                        (formikProps.values.centre_id =
                                          this.state.selectedCentreId)
                                      }
                                      selectedValue={
                                        formikProps.values.centre_id
                                      }
                                    />
                                  </div>
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: -5 }}>
                                  <div>
                                    <label className="formLabel">Class </label>
                                    <div className="formMargin2">
                                      <Field
                                        name="class_id"
                                        component={RenderDropdown}
                                        // onChange={formikProps.handleChange("class_id")}
                                        onChange={(data) => {
                                          this.setState({ classid: data });
                                          localStorage.setItem(
                                            "class_id",
                                            data
                                          );
                                          this.getStudent();
                                        }}
                                        validate={
                                          formikProps.values.admin_type ==
                                            "student" && isRequired
                                        }
                                        //onBlur={this.getStudent()}
                                        placeholder="Choose Class"
                                        isTouched={
                                          formikProps.values.admin_type ==
                                            "student" &&
                                          formikProps.touched.class_id
                                        }
                                        isError={
                                          formikProps.values.admin_type ==
                                            "student" &&
                                          formikProps.errors.class_id
                                        }
                                        isEdit={isEdit}
                                        options={classes.sort((a, b) =>
                                          a.text !== b.text
                                            ? a.text < b.text
                                              ? -1
                                              : 1
                                            : 0
                                        )}
                                        value={
                                          (formikProps.values.class_id =
                                            this.state.classid)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          )}

                        {formikProps.values.admin_type === "facilitator" &&
                          localStorage.getItem("adminType") == "centre" && (
                            <Grid.Column>
                              <label className="formLabel">Employee ID</label>
                              <div className="formMargin">
                                <Field
                                  name="employee_no"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange(
                                    "employee_no"
                                  )}
                                  validate={isRequired}
                                  placeholder="Enter Employee ID"
                                  isTouched={formikProps.touched.employee_no}
                                  isError={formikProps.errors.employee_no}
                                  value={formikProps.values.employee_no}
                                />
                              </div>
                            </Grid.Column>
                          )}

                        {localStorage.getItem("adminType") == "centre" &&
                          formikProps.values.admin_type === "facilitator" && (
                            <Grid.Column>
                              <label className="formLabel">Class</label>
                              <div className="formMargin">
                                <Field
                                  name="facilitor_id"
                                  component={RenderDropdown}
                                  // onChange={formikProps.handleChange("class_id")}
                                  // onChange={(data) => {
                                  //   this.setState({ classid: data });
                                  //   localStorage.setItem(
                                  //     "class_id",
                                  //     data
                                  //   );
                                  //   this.getStudent();
                                  // }}
                                  onChange={this.facilitorClass}
                                  validate={isRequired}
                                  //onBlur={this.getStudent()}
                                  placeholder="Choose Class"
                                  // isTouched={formikProps.touched.facilitor_id}
                                  // isError={
                                  //   this.state.arrfacilitor == 0 &&
                                  //   formikProps.errors.facilitor_id
                                  // }
                                  // isEdit={isEdit}
                                  options={classes.sort((a, b) =>
                                    a.label !== b.label
                                      ? a.label < b.label
                                        ? -1
                                        : 1
                                      : 0
                                  )}
                                  value={
                                    (formikProps.values.facilitor_id =
                                      this.state.arrfacilitor)
                                  }
                                  multiple={true}
                                  values={this.state.arrfacilitor}
                                />
                                {/* <div style={{ marginTop: 10 }}>
                                          {this.state.arrfacilitor.map((a) => (
                                            <a class="ui label" value={a.value}>
                                              {a.label}
                                              <i
                                                aria-hidden="true"
                                                class="delete icon"
                                                onClick={() =>
                                                  this.setState({
                                                    arrfacilitor:
                                                      this.state.arrfacilitor.filter(
                                                        (f) =>
                                                          f.value !== a.value
                                                      ),
                                                  })
                                                }
                                              ></i>
                                            </a>
                                          ))}
                                        </div> */}
                              </div>
                            </Grid.Column>
                          )}

                        {localStorage.getItem("adminType") == "hq" &&
                          formikProps.values.admin_type == "facilitator" && (
                            <>
                              <label className="formLabel">Class</label>

                              <div className="formMargin">
                                <Field
                                  name="facilitor_id"
                                  component={RenderDropdown}
                                  validate={isRequired}
                                  onChange={this.facilitorClass}
                                  placeholder="Choose Class"
                                  // isTouched={formikProps.touched.facilitor_id}
                                  // isError={
                                  //   this.state.arrfacilitor == 0 &&
                                  //   formikProps.errors.facilitor_id
                                  // }
                                  // isEdit={isEdit}
                                  value={
                                    (formikProps.values.facilitor_id =
                                      this.state.arrfacilitor)
                                  }
                                  options={classes.sort((a, b) =>
                                    a.label !== b.label
                                      ? a.label < b.label
                                        ? -1
                                        : 1
                                      : 0
                                  )}
                                  multiple={true}
                                  values={this.state.arrfacilitor}
                                />

                                {/* <div style={{ marginTop: 10 }}>
                                  {this.state.arrfacilitor.map((a) => (
                                    <a class="ui label" value={a.value}>
                                      {a.label}
                                      <i
                                        aria-hidden="true"
                                        class="delete icon"
                                        onClick={() =>
                                          this.setState({
                                            arrfacilitor:
                                              this.state.arrfacilitor.filter(
                                                (f) => f.value !== a.value
                                              ),
                                          })
                                        }
                                      ></i>
                                    </a>
                                  ))}
                                </div> */}
                              </div>
                            </>
                          )}
                        {/* {formikProps.values.admin_type === "student" && (
                          <div>
                            <label className="formLabel">Student No</label>
                            <div className="formMargin">
                              <Field
                                name="student_no"
                                component={RenderInput}
                                onChange={formikProps.handleChange(
                                  "student_no"
                                )}
                                validate={isRequired}
                                placeholder="Enter Employee No"
                                isTouched={formikProps.touched.student_no}
                                isError={formikProps.errors.student_no}
                                value={formikProps.values.student_no}
                              />
                            </div>
                          </div>
                        )} */}
                        <Grid>
                          <Grid.Column>
                            <Field
                              name="img"
                              component={RenderProfileImgUpload}
                              onChange={this.props.onChange}
                              previewUrl={
                                isEdit
                                  ? this.props.previewUrl == ""
                                    ? formikProps.values.img
                                    : this.props.previewUrl
                                  : this.props.previewUrl
                              }
                            />
                          </Grid.Column>
                        </Grid>
                        {this.props.imgError && (
                          <div class="ui error visible message">
                            <div class="content">
                              <p>Profile image is required</p>
                            </div>
                          </div>
                        )}
                        {formikProps.values.admin_type != "parent" &&
                          formikProps.values.admin_type != "student" && (
                            <>
                              <label className="formLabel">Name</label>
                              <div className="formMargin">
                                <Field
                                  name="name"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange("name")}
                                  validate={isRequired}
                                  placeholder="Name"
                                  isTouched={formikProps.touched.name}
                                  isError={formikProps.errors.name}
                                  value={formikProps.values.name}
                                />
                              </div>
                              {this.props.adminNameError && (
                                <div class="ui error visible message">
                                  <div class="content">
                                    <p>Name don't allow special character.</p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                        {/* <div className="formMargin">
                          {isEdit &&
                          localStorage.getItem("adminType") === "centre" ? (
                            <div>
                              <label className="formLabel">Centre</label>
                              <Field
                                name="centre_id"
                                component={RenderDropdown}
                                onChange={formikProps.handleChange("centre_id")}
                                validate={isRequired}
                                //onBlur= {this.props.loadChange}
                                placeholder="Choose Center"
                                isEdit={isEdit}
                                isTouched={formikProps.touched.centre_id}
                                isError={formikProps.errors.centre_id}
                                options={centres_list.filter(
                                  (centre) =>
                                    centre.key ===
                                    formikProps.values.centre_id[0]
                                )}
                                value={formikProps.values.centre_id}
                                selectedValue={formikProps.values.centre_id}
                              />
                            </div>
                          ) : (
                            <div style={{ display: "none" }}>
                              <Field
                                name="centre_id"
                                component={RenderDropdown}
                                onChange={formikProps.handleChange("centre_id")}
                                validate={isRequired}
                                //onBlur= {this.props.loadChange}
                                placeholder="Choose Center"
                                isEdit={isEdit}
                                isTouched={formikProps.touched.centre_id}
                                isError={formikProps.errors.centre_id}
                                options={centres_list.sort((a, b) =>
                                  a.text !== b.text
                                    ? a.text < b.text
                                      ? -1
                                      : 1
                                    : 0
                                )}
                                value={formikProps.values.centre_id}
                                selectedValue={formikProps.values.centre_id}
                              />
                            </div>
                          )}
                        </div>
                         */}
                        {formikProps.values.admin_type === "parent" && (
                          <Grid columns={1}>
                            <Grid.Row>
                              <Grid.Column>
                                <label className="formLabel">Name</label>
                                <div className="formMargin">
                                  <Field
                                    name="name"
                                    component={RenderInput}
                                    onChange={formikProps.handleChange("name")}
                                    validate={isRequired}
                                    placeholder="Name"
                                    isTouched={formikProps.touched.name}
                                    isError={formikProps.errors.name}
                                    value={formikProps.values.name}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        )}
                        {formikProps.values.admin_type === "parent" && (
                          <Grid.Column>
                            <div>
                              <label className="formLabel">
                                Child Name(s){" "}
                              </label>

                              <div className="formMargin">
                                <Field
                                  name="children_id"
                                  component={RenderDropdown}
                                  validate={isRequired}
                                  // onChange={formikProps.handleChange(
                                  //   "children_id"
                                  // )}
                                  onChange={this.childnamesHandle}
                                  placeholder="Child Name"
                                  isTouched={formikProps.touched.children_id}
                                  isError={
                                    this.state.arr == 0 &&
                                    formikProps.errors.children_id
                                  }
                                  isEdit={isEdit}
                                  value={
                                    (formikProps.values.children_id =
                                      this.state.arr)
                                  }
                                  options={students}
                                  moreAsyncMultiple={true}
                                  values={this.state.arr}
                                  loader={this.props.usersLoading}
                                  selectedCentreId={this.state.selectedCentreId}
                                  loadStudentOptions={this.loadStudentOptions}
                                  page={this.state.page}
                                />
                                {/* <div style={{ marginTop: 10 }}>
                                  {this.state.arr.map((a) => (
                                    <a class="ui label" value={a.value}>
                                      {a.label}
                                      <i
                                        aria-hidden="true"
                                        class="delete icon"
                                        onClick={() =>
                                          this.setState({
                                            arr: this.state.arr.filter(
                                              (f) => f.value !== a.value
                                            ),
                                          })
                                        }
                                      ></i>
                                    </a>
                                  ))}
                                </div> */}
                              </div>
                            </div>
                          </Grid.Column>
                        )}

                        {formikProps.values.admin_type === "student" && (
                          <Grid columns={2}>
                            <Grid.Row>
                              <Grid.Column>
                                <label className="formLabel">Name</label>
                                <div className="formMargin">
                                  <Field
                                    name="name"
                                    component={RenderInput}
                                    onChange={formikProps.handleChange("name")}
                                    validate={isRequired}
                                    placeholder="Name"
                                    isTouched={formikProps.touched.name}
                                    isError={formikProps.errors.name}
                                    value={formikProps.values.name}
                                  />
                                  {this.props.adminNameError && (
                                    <div class="ui error visible message">
                                      <div class="content">
                                        <p>
                                          Name don't allow special character.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Grid.Column>
                              <Grid.Column>
                                <div>
                                  <label className="formLabel">DOB</label>
                                  <Field
                                    name="dob"
                                    component={RenderDatePicker}
                                    onChange={this.SelectdDate}
                                    //validate={isRequired}
                                    isEdit={isEdit}
                                    selected={formikProps.values.dob}
                                    isTouched={formikProps.touched.dob}
                                    isError={formikProps.errors.dob}
                                    // value={
                                    //   isEdit
                                    //     ? this.state.dob === null
                                    //       ? formikProps.values.dob
                                    //       : this.state.dob
                                    //     : this.state.dob === null
                                    //     ? new Date()
                                    //     : this.state.dob
                                    // }
                                    value={
                                      isEdit
                                        ? this.state.dob === null
                                          ? (formikProps.values.dob = new Date(
                                              formikProps.values.dob
                                            ))
                                          : this.state.dob
                                        : this.state.dob === null
                                        ? new Date()
                                        : this.state.dob
                                    }
                                  />
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        )}
                        {/* {formikProps.values.admin_type === "parent" && (
                          <div>
                            <label className="formLabel">Relationship</label>
                            <div className="formMargin">
                              <Field
                                name="relationship"
                                component={RenderInput}
                                onChange={formikProps.handleChange(
                                  "relationship"
                                )}
                                validate={isRequired}
                                placeholder="Relationship"
                                isTouched={formikProps.touched.relationship}
                                isError={formikProps.errors.relationship}
                                value={formikProps.values.relationship}
                              />
                            </div>
                          </div>
                        )} */}
                        {formikProps.values.admin_type !== "student" && (
                          <Container>
                            <label className="formLabel">
                              {" "}
                              {formikProps.values.admin_type === "parent"
                                ? "Contact Email"
                                : "Email"}
                            </label>
                            <div className="formMargin">
                              {isEdit ? (
                                <Field
                                  name="email"
                                  value={formikProps.values.email}
                                  disabled
                                  style={inputStyle}
                                />
                              ) : (
                                <Field
                                  name="email"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange("email")}
                                  validate={isEmail}
                                  placeholder={
                                    formikProps.values.admin_type === "parent"
                                      ? "Email"
                                      : "Email"
                                  }
                                  isTouched={formikProps.touched.email}
                                  isError={formikProps.errors.email}
                                  value={formikProps.values.email}
                                />
                              )}
                              {/* <Field
                                name="email"
                                component={RenderInput}
                                onChange={formikProps.handleChange("email")}
                                validate={isEmail}
                                placeholder={
                                  formikProps.values.admin_type === "parent"
                                    ? "Email"
                                    : "Business Email"
                                }
                                isTouched={formikProps.touched.email}
                                isError={formikProps.errors.email}
                                value={formikProps.values.email}
                              /> */}
                            </div>

                            <label className="formLabel">
                              {formikProps.values.admin_type === "parent"
                                ? "Contact No"
                                : "Contact No"}{" "}
                            </label>
                            <div className="formMargin">
                              <Field
                                name="phone"
                                component={RenderInput}
                                onChange={formikProps.handleChange("phone")}
                                validate={isPhone}
                                placeholder={
                                  formikProps.values.admin_type === "parent"
                                    ? "Contact No"
                                    : "Contact No"
                                }
                                isTouched={formikProps.touched.phone}
                                isError={formikProps.errors.phone}
                                value={formikProps.values.phone}
                              />
                            </div>
                          </Container>
                        )}
                        {formikProps.values.admin_type === "student" && (
                          <Container>
                            <label className="formLabel">school</label>
                            <div className="formMargin">
                              <Field
                                name="school"
                                component={RenderInput}
                                onChange={formikProps.handleChange("school")}
                                validate={isRequired}
                                placeholder="school"
                                isTouched={formikProps.touched.school}
                                isError={formikProps.errors.school}
                                value={formikProps.values.school}
                              />
                            </div>
                            <Field
                              name="scfa"
                              component={RenderCheckbox}
                              onChange={formikProps.handleChange("scfa")}
                              //validate={isRequired}
                              isTouched={formikProps.touched.scfa}
                              isError={formikProps.errors.scfa}
                              value={formikProps.values.scfa}
                              label="SCFA"
                            />
                          </Container>
                        )}
                        {/* {formikProps.values.admin_type === "parent" && (
                          <Field
                            name="scfa"
                            component={RenderCheckbox}
                            onChange={formikProps.handleChange("scfa")}
                            //validate={isRequired}
                            isTouched={formikProps.touched.scfa}
                            isError={formikProps.errors.scfa}
                            value={formikProps.values.scfa}
                            label="SCFA"
                          />
                        )} */}
                        <br />
                        <div className="fullWidthCenterAlign">
                          <Button
                            disabled={this.props.isSubmitProcessing}
                            primary
                            size="large"
                            type="submit"
                            className="submitBtn"
                          >
                            {this.props.isSubmitProcessing ? (
                              <Loader size="small" active inline="centered" />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  usersList: state.users.user !== undefined ? state.users.user.data : [],
  userscount: state.usersCount.users_count.data,
  usersLoading: state.users.loading,
  haveusersData: state.users.haveData,
  userDetail: state.user.user.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  getUsers: (user_type, centre_id, class_id, active) =>
    dispatch(getUsers(user_type, centre_id, class_id, active)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFrom);
