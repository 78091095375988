import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Grid, Image } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import ic_calendar from "../assets/images/ic_calendar1.svg";

class DonationDatePicker extends Component {
  state = {
    startDate: new Date(),
  };
  render() {
    const { onChange, value } = this.props;
    const previewDate =
      this.props.previewDate !== undefined ? this.props.previewDate : value;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div className="formMargin">
              <DatePicker
                selected={previewDate}
                onChange={onChange}
                selectsStart
                startDate={this.state.startDate}
                // dateFormat="MM/dd/yyyy"
                dateFormat="yyyy MMM dd"
              />
              <div className="input-iconnn">
                <h5>From</h5>
              </div>
              <div className="input-iconnnn">
                <Image src={ic_calendar}></Image>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default DonationDatePicker;
