import React, { useEffect } from "react";
import { Route } from "react-router";
import { DASHBOARD_ROUTE } from "../../routes";
import Upcoming from "./Upcoming";

export const Dashboard = () => (
  <React.Fragment>
    <Route exact path={DASHBOARD_ROUTE} component={Upcoming} />
  </React.Fragment>
);
export default Dashboard;
