import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const fetchUsers = (
  user_type,
  centre_id,
  class_id,
  admin_type,
  size
) => ({
  type: FETCH_USERS,
  payload: {
    user_type,
    centre_id,
    class_id,
    admin_type,
    size,
  },
});
export const fetchUsersSuccess = (data) => ({
  type: FETCH_USERS_SUCCESS,
  data,
});

export const fetchUsersFailure = () => ({
  type: FETCH_USERS_FAILURE,
});

export const getMoreUsers = (nextPage, user_type, centre_id, class_id) => {
  return (dispatch) => {
    dispatch(fetchUsers());
    return firebase.reloadAuth().then((res) => {
      const token = res.ya === null ? res.za : res.ya;
      try {
        if (user_type == "admin") {
          if (centre_id == "all") {
            centre_id = "";
          }
          api
            .get(
              "/user",
              {
                user_type,
                centre_id,
                admin_type:
                  localStorage.getItem("adminType") == "centre" ? "centre" : "",
                page: nextPage,
                size: 10,
              },
              token
            )
            .then((res) => {
              dispatch(fetchUsersSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchUsersFailure(err)));
        } else if (user_type == "facilitator") {
          if (class_id == "All Classes") {
            class_id = "";
          }
          if (centre_id == "all") {
            centre_id = "";
          }
          api
            .get(
              "/user",
              { user_type, centre_id, class_id, page: nextPage, size: 10 },
              token
            )
            .then((res) => {
              console.log("res", res);
              dispatch(fetchUsersSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchUsersFailure(err)));
        } else if (user_type == "parent") {
          if (centre_id == "all") {
            centre_id = "";
          }
          api
            .get(
              "/user",
              {
                user_type,
                centre_id,
                class_id: "",
                page: nextPage,
                size: 10,
              },
              token
            )
            .then((res) => {
              dispatch(fetchUsersSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchUsersFailure(err)));
        } else if (user_type == "student") {
          if (class_id == "All Classes") {
            class_id = "";
          }
          if (centre_id == "all") {
            centre_id = "";
          }
          api
            .get(
              "/user",
              { user_type, centre_id, class_id, page: nextPage, size: 10 },
              token
            )
            .then((res) => {
              dispatch(fetchUsersSuccess(res));
              return res;
            })
            .catch((err) => dispatch(fetchUsersFailure(err)));
        }
      } catch (err) {
        return dispatch(fetchUsersFailure(err));
      }
    });
  };
};

export const getUsers = (user_type, centre_id, class_id, active) => {
  return (dispatch) => {
    dispatch(fetchUsers());
    return firebase.reloadAuth().then((res) => {
      const token = res.ya === null ? res.za : res.ya;
      try {
        if (user_type == "admin") {
          if (centre_id == "all") {
            centre_id = "";
          }
          if (localStorage.getItem("adminType") === "centre") {
            api
              .get(
                "/user",
                {
                  user_type,
                  centre_id,
                  admin_type: "centre",
                  remove_inactive: active ? 1 : "",
                },
                token
              )
              .then((res) => {
                dispatch(fetchUsersSuccess(res));
                return res;
              })
              .catch((err) => dispatch(fetchUsersFailure(err)));
          } else {
            api
              .get(
                "/user",
                {
                  user_type,
                  centre_id,
                  remove_inactive: active ? 1 : "",
                },
                token
              )
              .then((res) => {
                dispatch(fetchUsersSuccess(res));

                return res;
              })
              .catch((err) => dispatch(fetchUsersFailure(err)));
          }
        } else if (user_type == "facilitator") {
          if (class_id == "All Classes") {
            class_id = "";
          }
          if (centre_id == "all") {
            centre_id = "";
          }

          api
            .get(
              "/user",
              {
                user_type,
                centre_id,
                class_id,
                remove_inactive: active ? 1 : "",
              },
              token
            )
            .then((res) => {
              dispatch(fetchUsersSuccess(res));

              return res;
            })
            .catch((err) => dispatch(fetchUsersFailure(err)));
        } else if (user_type == "parent") {
          if (centre_id == "all") {
            centre_id = "";
          }
          api
            .get(
              "/user",
              {
                user_type,
                centre_id,
                class_id: "",
                remove_inactive: active ? 1 : "",
              },
              token
            )
            .then((res) => {
              dispatch(fetchUsersSuccess(res));

              return res;
            })
            .catch((err) => dispatch(fetchUsersFailure(err)));
        } else if (user_type == "student") {
          if (class_id == "All Classes") {
            class_id = "";
          }
          if (class_id == "all") {
            class_id = "";
          }
          if (centre_id == "all") {
            centre_id = "";
          }
          // console.log("good", user_type, centre_id, class_id);
          api
            .get(
              "/user",
              {
                user_type,
                centre_id,
                class_id,
                remove_inactive: active ? 1 : "",
              },
              token
            )
            .then((res) => {
              dispatch(fetchUsersSuccess(res));
              // console.log("resS", res);
              return res;
            })
            .catch((err) => dispatch(fetchUsersFailure(err)));
        }
      } catch (err) {
        return dispatch(fetchUsersFailure(err));
      }
    });
  };
};

export const getHQAdmin = (user_type, centre_id, admin_type) => {
  return (dispatch) => {
    dispatch(fetchUsers());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get(
            "/user/",
            {
              user_type: "" + user_type,
              centre_id: "" + centre_id,
              admin_type: "" + admin_type,
            },
            token
          )
          .then((res) => {
            dispatch(fetchUsersSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchUsersFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
