import React, { Component } from "react";
import { Grid, Header, Button, Image, Dimmer, Loader } from "semantic-ui-react";
import { Formik, Field, Form, FieldArray } from "formik";
import RenderInput from "../../components/TextInput";
import RenderTextArea from "../../components/RenderTextArea";
import FormDropDown from "../../components/FormDropDown";
import RenderImage from "../../components/Image";
import RenderDropdown from "../../components/DropDown";
import { isRequired } from "../../validators";
import RoutingButton from "../../components/RoutingButton";
import RenderRadioGroup from "../../components/RadioGroup";
import { FORM_ROUTE } from "../../routes";
import Xclose from "../../assets/images/Xclose.svg";
import DonationDatePicker from "../../components/DonationDateFrom";
import DonationDateTo from "../../components/DonationDateTo";
import RenderFile from "../../components/File";
import moment from "moment";
import { firebase } from "../../config/firebase";
import { getCentersClasses, getFormType } from "../../store/actions";
import api from "../../config/api";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const roles = [
  {
    value: "facilitator",
    label: "Facilitator",
  },
  {
    value: "parent",
    label: "Parent",
  },
];

var date = new Date();

let dfrom = date;
let dto = date;
let file = "";

class FormDuplicate extends Component {
  getAllCentres = () => {
    const { centerclasses = [] } = this.props;
    let allCentres = [];

    centerclasses.map((data) => {
      allCentres.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    allCentres.push({
      key: "all",
      text: "All",
      value: "all",
    });
    return allCentres.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  getAllClasses = (centre_id) => {
    const { centerclasses = [] } = this.props;
    let allClasses = [];

    if (centre_id === "all") {
      allClasses.push(
        { key: "all", text: "All", value: "all" },
        { key: "p1", text: "P1", value: "p1" },
        { key: "p2", text: "P2", value: "p2" },
        { key: "p3", text: "P3", value: "p3" },
        { key: "p4", text: "P4", value: "p4" },
        { key: "p5", text: "P5", value: "p5" },
        { key: "p6", text: "P6", value: "p6" }
      );
    } else {
      if (centre_id === "" || centre_id === "all") {
        centerclasses.map((ce) => {
          ce.class.map((cl) => {
            allClasses.push({
              key: cl.id,
              text: cl.name,
              value: cl.id,
            });
          });
        });
      } else {
        let tempClasses = centerclasses.filter((x) => x.id === centre_id)[0]
          .class;
        tempClasses.map((xx) => {
          allClasses.push({
            key: xx.id,
            text: xx.name,
            value: xx.id,
          });
        });
      }
    }

    return allClasses.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  constructor(props) {
    console.log("props constructor", props);
    super(props);
    this.state = {
      Element: JSON.parse(this.props.location.state.data.form_settings),
      Multi: JSON.parse(this.props.location.state.data.form_settings),
      Date: [],
      File: [],
      AddQuestion: JSON.parse(this.props.location.state.data.form_settings),
      increment: JSON.parse(this.props.location.state.data.form_settings)
        .length,
      datefrom: new Date(this.props.location.state.data.valid_from_date),
      dateto: new Date(this.props.location.state.data.valid_to_date),
      timefrom: new Date(),
      timeto: new Date(),
      previewUrl: this.props.location.state.data.img,
      createdby: "",
      isloading: false,
    };
  }

  getFormTypes = () => {
    const { formtypes = [] } = this.props;
    let allFormTypes = [];
    formtypes.map((x) => {
      allFormTypes.push({
        key: x.id,
        text: x.name,
        value: x.id,
      });
    });
    return allFormTypes;
  };

  componentDidMount() {
    this.props.getCentersClasses();
    this.props.getFormType();

    dfrom = moment(this.props.location.state.data.valid_from_date).format(
      "YYYY-MM-DD"
    );
    dto = moment(this.props.location.state.data.valid_to_date).format(
      "YYYY-MM-DD"
    );
    let addquestions = [];
    this.state.AddQuestion.map((t, idx) => {
      addquestions.push({ key: idx, type: t.type });
    });
    this.setState({ AddQuestion: addquestions });

    let elements = [];
    this.state.Element.map((e, idx) => {
      elements.push({ type: e.type, key: idx, data: e.data, option: [] });
    });

    this.setState({ Element: elements });
    let multi = [];

    this.state.Multi.map((m, idx) => {
      multi.push({ type: m.type, key: idx, option: [...m.option] });
    });
    this.setState({ Multi: multi });
    firebase
      .reloadAuth()
      .then((data) => {
        this.setState({ createdby: data.uid });
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.data) {
      this.setState({
        previewUrl: this.props.data.img,
      });
    }
  }
  handleAdd = (index, value) => {
    this.setState({ increment: this.state.increment + 1 }, () => {
      if (value === "1") {
        this.setState(
          (prevState) => ({
            AddQuestion: [
              ...prevState.AddQuestion,
              { key: this.state.increment },
            ],
            Element: [
              ...prevState.Element,
              {
                type: "Short answer",
                key: this.state.increment,
                value: RenderInput,
                data: "",
                option: [],
              },
            ],
          }),
          () => {
            console.log("aa", this.state.AddQuestion);
            console.log("ee", this.state.Element);
          }
        );
      } else if (value === "2") {
        // this.setState(prevState=>({
        //     AddQuestion: [...prevState.AddQuestion[index], {"ShortAnswer": ''}]
        // }),()=>{
        //     console.log(this.state.AddQuestion)
        // });
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };

        el[index] = {
          type: "Short answer",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
        };
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "3") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Paragraph",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
        };
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "4") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        // let mul=this.state.Multi.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Multiple choice",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
        };
        for (let i = 0; i < 4; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Multi: [
              ...pre.Multi,
              {
                key: this.state.increment,
                value: RenderInput,
                data: "",
                label: "Item " + op,
                dex: op,
                ty: "Multiple choice",
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "5") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Checkbox",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
        };
        for (let i = 0; i < 4; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Multi: [
              ...pre.Multi,
              {
                key: this.state.increment,
                value: RenderInput,
                data: "",
                label: "Item " + op,
                dex: op,
                ty: "Checkbox",
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "6") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Dropdown",
          key: this.state.increment,
          value: RenderInput,
          data: "",
          option: [],
        };
        for (let i = 0; i < 4; i++) {
          let op = i + 1;
          this.setState((pre) => ({
            Multi: [
              ...pre.Multi,
              {
                key: this.state.increment,
                value: RenderInput,
                data: "",
                label: "Item " + op,
                dex: op,
                ty: "Dropdown",
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else if (value === "7") {
        // const newQues=this.state.AddQuestion.slice();
        // newQues[index] = {"FileUpload":''};
        // this.setState({AddQuestion: newQues});
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "File upload",
          key: this.state.increment,
          value: RenderInput,
        };
        this.setState({
          AddQuestion: newQues,
          Element: el,
          Multi: [
            ...this.state.Multi,
            {
              key: this.state.increment,
              value: RenderInput,
              data: "",
              label: "Size",
              dex: 1,
              ty: "Dropdown",
            },
          ],
        });
      } else if (value === "8") {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Linear scale",
          key: this.state.increment,
          value: RenderInput,
        };
        for (let i = 0; i < 2; i++) {
          this.setState((pre) => ({
            Multi: [
              ...pre.Multi,
              {
                key: this.state.increment,
                value: RenderInput,
                data: "",
                label: i === 0 ? "Min" : "Max",
                dex: i,
                ty: "Linear scale",
              },
            ],
          }));
        }
        this.setState({ AddQuestion: newQues, Element: el });
      } else {
        const newQues = this.state.AddQuestion.slice();
        const el = this.state.Element.slice();
        newQues[index] = { key: this.state.increment };
        el[index] = {
          type: "Date",
          key: this.state.increment,
          value: RenderInput,
        };
        this.setState({
          AddQuestion: newQues,
          Element: el,
          // Multi: [...this.state.Multi, {key: this.state.increment, value: DonationDatePicker}]
        });
      }
    });
  };

  handleRemove = (key, idx) => {
    const remQues = this.state.AddQuestion;
    const remElement = this.state.Element;
    const remMulti = this.state.Multi;
    const result = remQues
      .slice(0, idx)
      .concat(remQues.slice(idx + 1, remQues.length));
    const newInput = remElement.filter((item) => item["key"] !== key);
    // const newMulti = remMulti.filter(item=> !idx.indexOf(item));
    // above statement remove the array by index.
    // console.log(newMulti);
    this.setState({ AddQuestion: result, Element: newInput });
  };

  FDchange = (date) => {
    if (date < this.state.dateto) {
      dfrom = moment(date).format("YYYY-MM-DD");
      this.setState({ datefrom: date });
    } else {
      alert("date from must less than date to");
    }
  };
  TDchange = (date) => {
    if (date > this.state.datefrom) {
      dto = moment(date).format("YYYY-MM-DD");
      this.setState({ dateto: date });
    } else {
      alert("date to must be greater than date from");
    }
  };

  handleElement = (index, value) => {
    let elm = this.state.Element.slice();
    if (elm[index].value === RenderFile) {
    } else {
      elm[index].data = value;
      this.setState({ Element: elm });
    }
  };

  handleMultiOne = (index, dex, value) => {
    let mul = this.state.Multi.slice();
    mul[index].option[dex] = value;
    this.setState({ Multi: mul });
  };
  // handleMulti = (index, dex, value) => {
  //   let mul = this.state.Multi.slice();
  //   let el = this.state.Element.slice();

  //   el[index].option[dex] = value;
  //   this.setState({ Element: mul });
  //   console.log("hanTwo", this.state.Element);
  // };

  handleMulti = (index, dex, value) => {
    let mul = this.state.Multi.slice();
    const m = mul.findIndex((p) => p.key == index && p.dex == dex);
    mul[m].data = value;
    // if (index === -1) {
    //   return console.log('nth')
    // } else {
    //   mul[index] = newPerson;
    // }
    // mul[index].data = value;
    this.setState({ Multi: mul });
    console.log("mul", mul);
  };

  onChange = (event) => {
    let reader = new FileReader();
    file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    this.setState({ imgs: event.target.files[0] });
  };

  handleSubmit = (values) => {
    console.log("values", values);
    let fd = new FormData();
    let elem = this.state.Element;
    let mu = this.state.Multi;
    let options = [];
    let result = [];

    elem.forEach((e) => {
      options = [];
      mu.forEach((m) => {
        if (m.key === e.key) {
          if (m.data) {
            options = [...options, m.data];
            console.log("data", options);
          }
          if (m.option) {
            console.log("option", m.option);
            options = [...options, ...m.option];
          }
        }
      });
      result = [...result, { ...e, option: options }];
    });

    if (!this.props.location.state.data.id) {
      return alert("id is required");
    }
    if (dfrom < dto) {
      fd.append("name", values.title);
      fd.append("description", values.description);
      fd.append("form_type_id", values.form_type_id);
      fd.append("valid_from_date", dfrom);
      fd.append("valid_from_time", "00:00");
      fd.append("valid_to_time", "00:00");
      fd.append("valid_to_date", dto);
      fd.append("centre_id", values.centre_id);
      fd.append("class_id", values.class_id);
      fd.append("role", values.user_type);
      fd.append("img", file === "" ? values.image : file);
      fd.append("created_by", this.state.createdby);
      if (result.length > 0) {
        fd.append("form_settings", JSON.stringify(result));
      } else {
        fd.append(
          "form_settings",
          this.props.location.state.data.form_settings
        );
      }
      console.log("form", ...fd);

      this.setState({ isloading: true });
      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;
          console.log(token);
          api.post("/form/", fd, token).then((res) => {
            if (res.message === "Success") {
              this.props.dispatch(push(FORM_ROUTE));
              this.setState({ isloading: false });
            } else {
              alert(`${res.message}`);
              this.setState({ isloading: false });
            }
          });
        } catch (err) {
          console.log(err);
          this.setState({ isloading: false });
        }
      });
    } else {
      alert("invalid date");
    }
  };

  render() {
    const { data } = this.props.location.state;
    return (
      <Grid className="fullHeight">
        <Grid.Row>
          <Grid.Column width={16}>
            <Dimmer active={this.state.isloading} inverted>
              <Loader size="big" />
            </Dimmer>
            <RoutingButton
              to={FORM_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <Grid className="form-subcontainer">
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ width: "50%", margin: "auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h2" floated="left">
                          Add Form
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <div style={{ marginTop: 36 }}>
                      <Formik
                        initialValues={{
                          title: data.name,
                          description: data.description,
                          user_type: data.role,
                          image: data.img,
                          FD: "",
                          TD: "",
                          TF: "",
                          TT: "",
                          centre_id:
                            data.centre_id.length === 1
                              ? data.centre_id[0]
                              : "all",
                          class_id:
                            data.class_id.length === 1
                              ? data.class_id[0]
                              : "all",
                          form_type_id: data.form_type_id,
                          regDate: "",
                          scfacheckbox: "",
                          AddQuestion: JSON.parse(data.form_settings),
                          Dropdown: [],
                          Question: [""],
                        }}
                        onSubmit={this.handleSubmit}
                      >
                        {(formikProps) => {
                          return (
                            <Form>
                              <Grid>
                                <label className="formLabel">
                                  <b>Send to</b>
                                </label>
                                <Grid.Row style={{ marginLeft: 15 }}>
                                  <Field
                                    name="user_type"
                                    component={RenderRadioGroup}
                                    options={roles}
                                    // onClick={this.onChange}
                                    onChange={formikProps.handleChange(
                                      "user_type"
                                    )}
                                    validate={isRequired}
                                    isTouched={formikProps.touched.user_type}
                                    isError={formikProps.errors.user_type}
                                    value={formikProps.values.user_type}
                                  />
                                </Grid.Row>
                              </Grid>

                              <Grid>
                                <Grid.Row columns={2}>
                                  {localStorage.getItem("adminType") ==
                                  "centre" ? null : (
                                    <Grid.Column>
                                      <label className="formLabel">
                                        <b>Centre</b>
                                      </label>
                                      <div style={{ height: 10 }}></div>
                                      <Field
                                        name="centre_id"
                                        component={RenderDropdown}
                                        options={this.getAllCentres()}
                                        placeholder="Choose centre"
                                        // onClick={this.onChange}
                                        onChange={formikProps.handleChange(
                                          "centre_id"
                                        )}
                                        validate={isRequired}
                                        isTouched={
                                          formikProps.touched.centre_id
                                        }
                                        isError={formikProps.errors.centre_id}
                                        value={formikProps.values.centre_id}
                                      />
                                    </Grid.Column>
                                  )}

                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Class</b>
                                    </label>
                                    <div style={{ height: 10 }}></div>
                                    <Field
                                      name="class_id"
                                      placeholder="Choose class"
                                      component={RenderDropdown}
                                      options={this.getAllClasses(
                                        formikProps.values.centre_id
                                      )}
                                      // onClick={this.onChange}
                                      onChange={formikProps.handleChange(
                                        "class_id"
                                      )}
                                      isTouched={formikProps.touched.class_id}
                                      isError={formikProps.errors.class_id}
                                      value={formikProps.values.class_id}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>

                              <Grid>
                                <Grid.Row columns={1}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Form Type</b>
                                    </label>
                                    <div style={{ height: 10 }}></div>
                                    <Field
                                      name="form_type_id"
                                      placeholder="Select form type"
                                      component={RenderDropdown}
                                      options={this.getFormTypes()}
                                      // onClick={this.onChange}
                                      onChange={formikProps.handleChange(
                                        "form_type_id"
                                      )}
                                      validate={isRequired}
                                      isTouched={
                                        formikProps.touched.form_type_id
                                      }
                                      isError={formikProps.errors.form_type_id}
                                      value={formikProps.values.form_type_id}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>

                              <Grid>
                                <Grid.Row columns={2}>
                                  <Grid.Column>
                                    <label className="formLabel">
                                      <b>Period Date</b>
                                    </label>
                                    <Field
                                      name="FD"
                                      component={DonationDatePicker}
                                      onChange={(date) => this.FDchange(date)}
                                      // validate={isRequired}
                                      isTouched={formikProps.touched.FD}
                                      isError={formikProps.errors.FD}
                                      previewDate={this.state.datefrom}
                                    />
                                  </Grid.Column>
                                  <Grid.Column>
                                    <div style={{ height: 20 }}></div>
                                    <Field
                                      name="TD"
                                      component={DonationDateTo}
                                      onChange={(date) => this.TDchange(date)}
                                      // validate={isRequired}
                                      isTouched={formikProps.touched.TD}
                                      isError={formikProps.errors.TD}
                                      previewDateTo={this.state.dateto}
                                    />
                                  </Grid.Column>
                                  {/* </Grid.Row> */}
                                </Grid.Row>
                              </Grid>

                              {/* <Grid divided='vertically'> */}

                              {/* </Grid> */}

                              <label className="formLabel">
                                <b>Image</b>
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="image"
                                  component={RenderImage}
                                  //onChange={formikProps.handleChange('image')}
                                  onChange={this.onChange}
                                  isTouched={formikProps.touched.image}
                                  isError={formikProps.errors.image}
                                  previewUrl={this.state.previewUrl}
                                />
                              </div>

                              <label className="formLabel">
                                <b>Title</b>
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="title"
                                  component={RenderInput}
                                  onChange={formikProps.handleChange("title")}
                                  validate={isRequired}
                                  placeholder="Travel Declaration Form"
                                  isTouched={formikProps.touched.title}
                                  isError={formikProps.errors.title}
                                  value={formikProps.values.title}
                                />
                              </div>

                              <label className="formLabel">
                                <b>Description</b>
                              </label>
                              <div className="formMargin">
                                <Field
                                  name="description"
                                  component={RenderTextArea}
                                  onChange={formikProps.handleChange(
                                    "description"
                                  )}
                                  validate={isRequired}
                                  placeholder="Description"
                                  isTouched={formikProps.touched.description}
                                  isError={formikProps.errors.description}
                                  value={formikProps.values.description}
                                />
                              </div>

                              <FieldArray
                                name="AddQuestion"
                                render={(arrayHelpers) => (
                                  <div>
                                    {this.state.AddQuestion.map((Qu, index) => (
                                      <div key={index}>
                                        <Grid divided="vertically">
                                          <Grid.Row
                                            columns={3}
                                            verticalAlign="middle"
                                          >
                                            <Grid.Column width={7}>
                                              <label className="formLabel">
                                                <b>Question {index + 1}</b>
                                              </label>
                                            </Grid.Column>
                                            <Grid.Column
                                              align="right"
                                              width={7}
                                            >
                                              <Field
                                                name="centers"
                                                component={FormDropDown}
                                                onChange={this.handleAdd.bind(
                                                  this,
                                                  index
                                                )}
                                                text={Qu.type}
                                                // validate={isRequired}
                                                placeholder="Select"
                                                isTouched={
                                                  formikProps.touched.centers
                                                }
                                              />
                                              {/* <Dropdown
                                                text={Qu.type}
                                                disabled
                                              /> */}
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                              {index === 0 &&
                                              this.state.AddQuestion.length ===
                                                1 ? (
                                                <Image
                                                  src={Xclose}
                                                  disabled={true}
                                                ></Image>
                                              ) : (
                                                <Image
                                                  src={Xclose}
                                                  onClick={() => {
                                                    this.handleRemove(
                                                      Qu.key,
                                                      index
                                                    );
                                                  }}
                                                ></Image>
                                              )}
                                            </Grid.Column>
                                          </Grid.Row>
                                        </Grid>
                                        {/* // <Input
                                                  //   fluid
                                                  //   placeholder={et.type}
                                                  //   onChange={(e) =>
                                                  //     console.log(
                                                  //       e.target.value,
                                                  //       et.type
                                                  //     )
                                                  //   }
                                                  // /> 
                                                  .toLowerCase() == "short answer" || et.type.toLowerCase()  == "paragraph" || et.type.toLowerCase() == "multiple choice" || et.type.toLowerCase() == "checkboxes" || et.type.toLowerCase() == "checkbox" || et.type.toLowerCase() == "dropdown" || et.type.toLowerCase() == "file upload" || et.type.toLowerCase() == "linear scale" || et.type.toLowerCase() == "date" 
                                                  */}
                                        {this.state.Element.map((et, idx) => {
                                          if (et["key"] === Qu["key"]) {
                                            return (
                                              <>
                                                {et.type ? (
                                                  <div
                                                    style={{
                                                      marginBottom: 20,
                                                      lineHeight: 40,
                                                    }}
                                                  >
                                                    <div class="ui small fluid input">
                                                      <input
                                                        name="Question"
                                                        placeholder={et.type}
                                                        type="text"
                                                        value={et.data}
                                                        onChange={(e) =>
                                                          this.handleElement(
                                                            idx,
                                                            e.target.value
                                                          )
                                                        }
                                                        istouched={
                                                          formikProps.touched
                                                            .title
                                                        }
                                                        iserror={
                                                          formikProps.errors
                                                            .title
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="formMargin"
                                                    key={idx}
                                                  >
                                                    <Field
                                                      name="Question"
                                                      component={
                                                        et.key === Qu["key"] &&
                                                        et.value
                                                      }
                                                      onChange={(e) =>
                                                        this.handleElement(
                                                          idx,
                                                          e.target.value
                                                        )
                                                      }
                                                      // validate={isRequired}
                                                      placeholder={
                                                        et.type + " Question"
                                                      }
                                                      istouched={
                                                        formikProps.touched
                                                          .title
                                                      }
                                                      iserror={
                                                        formikProps.errors.title
                                                      }
                                                    />
                                                  </div>
                                                )}
                                                {/* <div className="formMargin" key={idx}>
                                                <Field
                                                  name="Question"
                                                  component={
                                                    et.key === Qu["key"] &&
                                                    et.value
                                                  }
                                                  onChange={(e) =>
                                                    this.handleElement(
                                                      idx,
                                                      e.target.value
                                                    )
                                                  }
                                                  // validate={isRequired}
                                                  placeholder={
                                                    et.type + " Question"
                                                  }
                                                  istouched={
                                                    formikProps.touched.title
                                                  }
                                                  iserror={
                                                    formikProps.errors.title
                                                  }
                                                />
                                              </div> */}
                                              </>
                                            );
                                          }
                                        })}

                                        {/* for like drop down */}
                                        {this.state.Multi.length > 0 &&
                                          this.state.Multi.map((map, dex) => {
                                            if (map["key"] === Qu["key"]) {
                                              return (
                                                <>
                                                  {/* <Grid.Row columns={16} verticalAlign="middle"> */}
                                                  {map.type ? (
                                                    <>
                                                      {map.option.map(
                                                        (t, d) => {
                                                          return (
                                                            <>
                                                              {t != null && (
                                                                <div className="grid ui">
                                                                  <div
                                                                    style={{
                                                                      paddingTop: 10,
                                                                    }}
                                                                  >
                                                                    Item {d + 1}
                                                                  </div>
                                                                  <div className="multi">
                                                                    <div
                                                                      style={{
                                                                        marginBottom: 20,
                                                                        lineHeight: 40,
                                                                      }}
                                                                    >
                                                                      <div class="ui small fluid input">
                                                                        <input
                                                                          name="Question"
                                                                          placeholder="enter value"
                                                                          type="text"
                                                                          value={
                                                                            t
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            this.handleMultiOne(
                                                                              index,
                                                                              d,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                    <Grid>
                                                      <div
                                                        style={{
                                                          paddingTop: 10,
                                                        }}
                                                      >
                                                        {map.label}
                                                      </div>
                                                      <div className="multi">
                                                        <Field
                                                          name="Question"
                                                          component={
                                                            map.key ===
                                                              Qu["key"] &&
                                                            map.value
                                                          }
                                                          onChange={(e) =>
                                                            this.handleMulti(
                                                              map.key,
                                                              map.dex,
                                                              e.target.value,
                                                              map.ty
                                                            )
                                                          }
                                                          // validate={isRequired}
                                                          placeholder="enter value"
                                                        />
                                                        {/* <Field
                                                          name="Question"
                                                          component={
                                                            map.key ===
                                                              Qu["key"] &&
                                                            map.value
                                                          }
                                                          onChange={(e) =>
                                                            this.handleMulti(
                                                              index,
                                                              map.dex,
                                                              e.target.value
                                                            )
                                                          }
                                                          // validate={isRequired}
                                                          placeholder="enter value"
                                                        /> */}
                                                      </div>
                                                    </Grid>
                                                  )}
                                                  {/* <div style={{ paddingTop: 10 }}>
                                                  {map.label}
                                                </div>
                                                <div className="multi">
                                                  <Field
                                                    name="Question"
                                                    component={
                                                      map.key === Qu["key"] &&
                                                      map.value
                                                    }
                                                    onChange={(e) =>
                                                      this.handleMulti(
                                                        index,
                                                        e.target.value
                                                      )
                                                    }
                                                    // validate={isRequired}
                                                    placeholder="enter value"
                                                  />
                                                </div> */}
                                                </>
                                              );
                                            }
                                          })}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              />

                              <div className="fullWidthCenterAlign">
                                <Button
                                  content="Publish"
                                  primary
                                  size="large"
                                  type="submit"
                                  className="submitBtn"
                                  loading={this.state.isloading}
                                />
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    centerclasses: state.centersClasses.centers_classes.data,
    formtypes: state.formtypeState.formtypes,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getCentersClasses: () => dispatch(getCentersClasses()),
  getFormType: () => dispatch(getFormType()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FormDuplicate);
