import {
  FETCH_DONOR,
  FETCH_DONOR_SUCCESS,
  FETCH_DONOR_FAILURE,
} from "../actions";

const initialState = {
  donors: [],
  loading: true,
  err: null,
  haveData: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DONOR:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false,
      };
    case FETCH_DONOR_SUCCESS:
      return {
        ...state,
        loading: false,
        haveData: true,
        donors: action.data.data.donors,
        // donors: action.data
      };
    case FETCH_DONOR_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        donors: [],
      };
    default:
      return state;
  }
};
