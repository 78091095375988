import React, { Component } from "react";
import { Grid, Header, Button, Card, Image, Icon } from "semantic-ui-react";
import { Formik, Field, Form, FieldArray } from "formik";
import RenderInput from "../../components/TextInput";
import RenderTextArea from "../../components/RenderTextArea";
import RenderImage from "../../components/Image";
import { isRequired } from "../../validators";
import RenderRadioGroup from "../../components/DonationRadioGroup";
import ic_add from "../../assets/images/ic_add.svg";
import DonationDatePicker from "../../components/DonationDateFrom";
import DonationDateTo from "../../components/DonationDateTo";
import defaultimage from "../../assets/images/defaultimage.png";
import moment from "moment";
import ReactPlayer from "react-player";
import { initial } from "lodash-es";
import corssImage from "../../assets/images/X.svg";

let values_Edit = {};

class DonationEditForm extends Component {
  state = {
    pushNewRanges: false,
  };
  componentDidMount() {
    const { initialValues } = this.props;
    values_Edit = {
      title: initialValues.title,
      description: initialValues.description,
      img_url: initialValues.img_url === "" ? "" : initialValues.img_url,
      video_url:
        initialValues.video_url === undefined ? "" : initialValues.video_url,
      from_date: Date.parse(initialValues.from_date),
      to_date: Date.parse(initialValues.to_date),
      total_needs: initialValues.total_needs,
      link: initialValues.img_url,
      media_type: initialValues.video_url === undefined ? "Image" : "Video",
      range: initialValues.range === undefined ? [""] : initialValues.range,
    };
  }
  render() {
    const { initialValues, isEdit } = this.props;
    // console.log(initialValues);
    // console.log("Donation EditForm render...");
    // console.log(this.props);

    var from = this.props.changeDateFrom
      ? moment(this.props.previewDate).format("YYYY-MM-DD")
      : initialValues !== undefined && initialValues.from_date;
    var to = this.props.changeDateTo
      ? moment(this.props.previewDateTo).format("YYYY-MM-DD")
      : initialValues !== undefined && initialValues.to_date;
    localStorage.setItem("dateFromSend", from);
    localStorage.setItem("dateToSend", to);
    localStorage.setItem(
      "img_url",
      initialValues !== undefined && initialValues.img_url
    );

    return (
      <Grid className="fullHeight">
        <Grid.Row>
          {console.log(initialValues)}
          <Grid.Column width={16}>
            <Grid className="form-subcontainer">
              <Grid.Row>
                <Grid.Column width={16}>
                  <div style={{ width: "50%", margin: "auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h2" floated="left">
                          {this.props.formtitle}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <div style={{ marginTop: 36 }}>
                      {initialValues !== undefined && (
                        <Formik
                          initialValues={{
                            title: initialValues.title,
                            description: initialValues.description,
                            img_url:
                              initialValues.img_url === ""
                                ? ""
                                : initialValues.img_url,
                            video_url:
                              initialValues.video_url === undefined
                                ? ""
                                : initialValues.video_url,
                            from_date: Date.parse(initialValues.from_date),
                            to_date: Date.parse(initialValues.to_date),
                            total_needs: initialValues.total_needs,
                            link: initialValues.img_url,
                            media_type:
                              initialValues.video_url === undefined
                                ? "Image"
                                : "Video",
                            range:
                              initialValues.range === undefined
                                ? [""]
                                : initialValues.range,
                          }}
                          onSubmit={this.props.handleSubmit}
                        >
                          {(formikProps, setFieldValue) => {
                            return (
                              <Form>
                                {console.log(values_Edit)}
                                <Grid>
                                  <Grid.Row columns={1}>
                                    <Grid.Column>
                                      <label className="formLabel">
                                        <b>Title</b>
                                      </label>
                                      <div className="formMargin">
                                        <Field
                                          name="title"
                                          component={RenderInput}
                                          onChange={formikProps.handleChange(
                                            "title"
                                          )}
                                          validate={isRequired}
                                          placeholder="Donate an Education for Children"
                                          isTouched={formikProps.touched.title}
                                          isError={formikProps.errors.title}
                                          value={formikProps.values.title}
                                        />
                                      </div>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row columns={1}>
                                    <Grid.Column>
                                      <label className="formLabel">
                                        <b>Description</b>
                                      </label>
                                      <div className="formMargin">
                                        <Field
                                          name="description"
                                          component={RenderTextArea}
                                          onChange={formikProps.handleChange(
                                            "description"
                                          )}
                                          validate={isRequired}
                                          placeholder="Description"
                                          isTouched={
                                            formikProps.touched.description
                                          }
                                          isError={
                                            formikProps.errors.description
                                          }
                                          value={formikProps.values.description}
                                        />
                                      </div>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row columns={2}>
                                    <Grid.Column>
                                      <label className="formLabel">
                                        <b>Date</b>
                                      </label>
                                      <Field
                                        name="from_date"
                                        component={DonationDatePicker}
                                        onChange={(date) =>
                                          this.props.onDateChange(date)
                                        }
                                        previewDate={
                                          this.props.changeDateFrom
                                            ? this.props.previewDate
                                            : formikProps.values.from_date
                                        }
                                        value={formikProps.values.from_date}
                                      />
                                    </Grid.Column>
                                    <Grid.Column>
                                      <label className="formLabel">
                                        <b>&nbsp;</b>
                                      </label>
                                      <Field
                                        name="to_date"
                                        component={DonationDateTo}
                                        onChange={(date) =>
                                          this.props.onDateToChange(date)
                                        }
                                        previewDateTo={
                                          this.props.changeDateTo
                                            ? this.props.previewDateTo
                                            : formikProps.values.to_date
                                        }
                                        value={formikProps.values.to_date}
                                      />
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row columns={1}>
                                    <Grid.Column>
                                      <label className="formLabel">
                                        <b>Total needs</b>
                                      </label>
                                      <div className="formMargin">
                                        <Field
                                          name="total_needs"
                                          type="number"
                                          min="0"
                                          max="1000000"
                                          maxLength="8"
                                          step="0.01"
                                          component={RenderInput}
                                          onChange={formikProps.handleChange(
                                            "total_needs"
                                          )}
                                          validate={isRequired}
                                          placeholder="SGD $"
                                          isTouched={
                                            formikProps.touched.total_needs
                                          }
                                          isError={
                                            formikProps.errors.total_needs
                                          }
                                          value={formikProps.values.total_needs}
                                          min={0}
                                        />
                                      </div>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>

                                <label className="formLabel">
                                  <b>Range</b>
                                </label>
                                <div className="formMargin"></div>
                                <FieldArray
                                  name="range"
                                  render={(arrayHelpers) => {
                                    return (
                                      <Grid>
                                        {formikProps.values.range.length > 0 &&
                                          formikProps.values.range.map(
                                            (range, idx) => (
                                              <Grid.Column
                                                width={3}
                                                style={{ paddingRight: 0 }}
                                              >
                                                <Field
                                                  name={`range.${idx}`}
                                                  component={RenderInput}
                                                  onChange={formikProps.handleChange(
                                                    `range.${idx}`
                                                  )}
                                                  value={
                                                    formikProps.values.range[
                                                      idx
                                                    ]
                                                  }
                                                  styles={true}
                                                />
                                                {formikProps.values.range
                                                  .length > 1 && (
                                                  <div
                                                    onClick={() =>
                                                      arrayHelpers.remove(idx)
                                                    }
                                                    style={{
                                                      position: "absolute",
                                                      top: 27,
                                                      right: 3,
                                                      width: 18,
                                                      height: 18,
                                                    }}
                                                  >
                                                    <Image src={corssImage} />
                                                  </div>
                                                )}
                                              </Grid.Column>
                                            )
                                          )}
                                        <div
                                          style={{
                                            paddingLeft: 2,
                                            paddingRight: 0,
                                            paddingTop: 25,
                                            width: "25px",
                                            height: "40px",
                                          }}
                                        >
                                          <Image
                                            src={ic_add}
                                            verticalAlign="middle"
                                            // onClick={() => push("")}
                                            onClick={() =>
                                              arrayHelpers.push("")
                                            }
                                          />{" "}
                                        </div>
                                      </Grid>
                                    );
                                  }}
                                />

                                <label className="formLabel">
                                  <b>Media</b>
                                </label>
                                <div className="formMargin">
                                  <Field
                                    name="media_type"
                                    component={RenderRadioGroup}
                                    options={this.props.options}
                                    onChange={formikProps.handleChange(
                                      "media_type"
                                    )}
                                    validate={isRequired}
                                    isTouched={formikProps.touched.media_type}
                                    isError={formikProps.errors.media_type}
                                    value={formikProps.values.media_type}
                                  />
                                </div>

                                <label className="formLabel">
                                  <b>
                                    {formikProps.values.media_type === "Video"
                                      ? "Upload Video"
                                      : "Upload Image"}
                                  </b>
                                </label>
                                <div className="formMargin">
                                  {formikProps.values.media_type === "Video" ? (
                                    <div>
                                      <label
                                        style={{
                                          width: "150px",
                                          display: "inline-block",
                                          padding: "8px 12px",
                                          cursor: "pointer",
                                          borderRadius: "4px",
                                          backgroundColor: "#41CEC3",
                                          fontSize: "14px",
                                          marginBottom: "10px",
                                          color: "#fff",
                                        }}
                                      >
                                        <span aria-hidden="true">
                                          <Icon name="upload" /> Upload Video
                                        </span>
                                        <input
                                          name="video"
                                          onChange={this.props.handleChange}
                                          type="file"
                                          id="video"
                                          style={{
                                            marginBottom: "10px",
                                            display: "none",
                                          }}
                                        />
                                      </label>
                                      {console.log(
                                        formikProps.values.video_url
                                      )}
                                      {this.props.previewVideoUrl ===
                                        undefined &&
                                      formikProps.values.video_url !== "" ? (
                                        <ReactPlayer
                                          style={{
                                            maxWidth: "485px",
                                            maxHeight: "270px",
                                          }}
                                          url={formikProps.values.video_url}
                                          controls={true}
                                        />
                                      ) : (
                                        this.props.previewVideoUrl !==
                                          undefined && (
                                          <ReactPlayer
                                            style={{
                                              maxWidth: "485px",
                                              maxHeight: "270px",
                                            }}
                                            url={this.props.previewVideoUrl}
                                            controls={true}
                                          />
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {this.props.previewUrl === undefined ? (
                                        <Field
                                          name="image"
                                          component={RenderImage}
                                          onChange={this.props.onChange}
                                          //accept="image/*"
                                          previewUrl={
                                            formikProps.values.img_url !==
                                            undefined
                                              ? formikProps.values.img_url
                                              : defaultimage
                                          }
                                          isTouched={
                                            formikProps.touched.img_url
                                          }
                                          isError={formikProps.errors.img_url}
                                        />
                                      ) : (
                                        <Field
                                          name="image"
                                          component={RenderImage}
                                          onChange={this.props.onChange}
                                          //accept="image/*"
                                          previewUrl={this.props.previewUrl}
                                          isTouched={
                                            formikProps.touched.img_url
                                          }
                                          isError={formikProps.errors.img_url}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                                {console.log(formikProps.values.img_url)}
                                {formikProps.values.media_type !== "Video" && (
                                  <div>
                                    <label className="formLabel">
                                      <b>Or input the image link</b>
                                    </label>
                                    <div className="formMargin">
                                      <Field
                                        name="link"
                                        component={RenderInput}
                                        onChange={formikProps.handleChange(
                                          "link"
                                        )}
                                        isTouched={formikProps.touched.link}
                                        isError={formikProps.errors.link}
                                        placeholder="https://"
                                        value={formikProps.values.link}
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className="fullWidthCenterAlign">
                                  <Button
                                    content="Publish"
                                    primary
                                    size="large"
                                    type="submit"
                                    className="submitBtn"
                                  />
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      )}
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default DonationEditForm;
