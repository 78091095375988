import { FETCH_SCHEDULES, FETCH_SCHEDULES_SUCCESS, FETCH_SCHEDULES_FAILURE } from '../actions';

const initialState = {
  schedules: [],
  loading: true,
  err: null,
  haveData: false
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_SCHEDULES:
      return {
        ...state,
        loading: true,
        err: null,
        haveData: false
      };
    case FETCH_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,   
        haveData: true,     
        schedules: action.data
      };
    case FETCH_SCHEDULES_FAILURE:
      return {
        ...state,
        loading: false,
        haveData: false,
        err: action.error,
        schedules: []
      };
    default:
      return state;
  }
}