import React, { Component } from "react";
import { Grid, Header, Button } from "semantic-ui-react";
import { Formik, Field, Form } from "formik";
import RenderInput from "../../components/TextInput";
import RenderTextArea from "../../components/RenderTextArea";
import RenderDropdown from "../../components/DropDown";
import RenderImage from "../../components/Image";
import RenderCheckbox from "../../components/CheckBox";
import { isRequired } from "../../validators";
// import RenderRadioGroup from "../../components/RadioGroup";
import RenderDatePicker from "../../components/DatePicker";
import RenderTimePicker from "../../components/TimePicker";
import CrossImgIcon from "../../assets/images/cross.png";
import PdfFileIcon from "../../assets/images/pdffile.svg";
// import moment from "moment";
import ReactTooltip from "react-tooltip";
import Exclamation from "../../assets/images/exclamation.svg";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

class ActivityEditForm extends Component {
  state = {
    centre_id: "",
    regtime: null,
    eventDate: null,
    from_time: null,
    to_time: null,
    eventCon: false,
    errorMax_number: false,
  };

  componentDidMount() {
    this.props.changePdfTitle(this.props.initialValues.event_file_name);
    if (
      this.props.initialValues.event_file_name &&
      this.props.initialValues.event_file
    ) {
      this.props.checkPdfFileExist();
    }
  }

  getAllCentres = () => {
    const { centerclasses = [] } = this.props;
    let allCentres = [];

    centerclasses.map((data) => {
      allCentres.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    allCentres.push({
      key: "all",
      text: "All",
      value: "all",
    });
    return allCentres.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  getAllClasses = (centre_id) => {
    const { centerclasses = [] } = this.props;
    let allClasses = [];
    if (centre_id[0] === "all" || centre_id === "all") {
      allClasses.push(
        { key: "all", text: "All", value: "all" },
        { key: "p1", text: "P1", value: "p1" },
        { key: "p2", text: "P2", value: "p2" },
        { key: "p3", text: "P3", value: "p3" },
        { key: "p4", text: "P4", value: "p4" },
        { key: "p5", text: "P5", value: "p5" },
        { key: "p6", text: "P6", value: "p6" }
      );
    } else {
      let tempClasses;
      if (typeof centre_id === "string") {
        tempClasses = centerclasses.filter((x) => x.id === centre_id)[0].class;
      } else {
        tempClasses = centerclasses.filter((x) => x.id === centre_id[0])[0]
          .class;
      }
      tempClasses.forEach((xx) => {
        allClasses.push({
          key: xx.id,
          text: xx.name,
          value: xx.id,
        });
      });
    }
    return allClasses.sort((a, b) =>
      a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
    );
  };

  render() {
    const { isEdit, centers, centerclasses } = this.props;
    const initialValues = { ...this.props.initialValues };
    const originalCentreIds = [...initialValues.centre_ids];
    const originalClassIds = initialValues.class_ids;
    initialValues.max_number = initialValues.max_vacancy;
    initialValues.centre_ids =
      originalCentreIds.length > 1 ? "all" : originalCentreIds;
    if (originalCentreIds.length > 1) {
      if (originalCentreIds.length < originalClassIds.length)
        initialValues.class_ids = "all";
      else {
        let searchedClassId = "";
        centerclasses[0].class.forEach((classItem1) => {
          originalClassIds.forEach((classItem2) => {
            if (classItem2 === classItem1.id)
              searchedClassId = classItem1.name.toLowerCase();
          });
        });
        initialValues.class_ids = searchedClassId;
      }
    }

    if (typeof initialValues.date === "string") {
      initialValues.date = new Date(initialValues.date);
      initialValues.reg_to_date = new Date(initialValues.reg_to_date);
      let from_time = new Date(initialValues.date);
      from_time.setHours(initialValues.from_time.slice(0, 2));
      from_time.setMinutes(initialValues.from_time.slice(3, 5));
      from_time.setSeconds("00");
      initialValues.from_time = from_time;
      let to_time = new Date(initialValues.date);
      to_time.setHours(initialValues.to_time.slice(0, 2));
      to_time.setMinutes(initialValues.to_time.slice(3, 5));
      to_time.setSeconds("00");
      initialValues.to_time = to_time;
    }

    const { centre_id } = this.state;
    let classes = [],
      goldObj = {},
      selectedCentreId = "";
    if (isEdit) {
      selectedCentreId = centre_id !== "" ? centre_id : initialValues.centre_id;
      goldObj = centerclasses.find((result) => result.id === selectedCentreId);
      goldObj !== undefined &&
        goldObj.class.map((data) => {
          classes.push({
            key: data.id,
            text: data.name,
            value: data.id,
          });
        });
    } else {
      goldObj = centerclasses.find((result) => result.id === centre_id);
      goldObj !== undefined &&
        goldObj.class.map((data) => {
          classes.push({
            key: data.id,
            text: data.name,
            value: data.id,
          });
        });
    }
    return (
      <Grid className="subContainer" style={{ paddingBottom: 100 }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <div style={{ width: "50%", margin: "auto" }}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h2" floated="left">
                    {this.props.formTitle}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <div style={{ marginTop: 36 }}>
                <Formik
                  initialValues={
                    isEdit
                      ? initialValues
                      : {
                          title: "",
                          description: "",
                          centre_id: "",
                          class_id: "",
                          img_url: null,
                          //shiftwork: "",
                          //activityDate: "",
                          //activityTime: "",
                          register_link: "",
                          reg_to_date: "",
                          scfa: 0,
                          event_date: "",
                          max_number: null,
                          from_time: "",
                          to_time: "",
                        }
                  }
                  onSubmit={this.props.handleSubmit}
                >
                  {(formikProps) => {
                    // formikProps.values.reg_to_date = new Date();

                    return (
                      <Form>
                        <label className="formLabel">Title</label>
                        <div className="formMargin">
                          <Field
                            name="title"
                            component={RenderInput}
                            onChange={formikProps.handleChange("title")}
                            validate={isRequired}
                            placeholder="Activity Title"
                            isTouched={formikProps.touched.title}
                            isError={formikProps.errors.title}
                            value={formikProps.values.title}
                          />
                        </div>

                        <label className="formLabel">Description</label>
                        <div className="formMargin">
                          <Field
                            name="description"
                            component={RenderTextArea}
                            onChange={formikProps.handleChange("description")}
                            validate={isRequired}
                            placeholder="Description"
                            isTouched={formikProps.touched.description}
                            isError={formikProps.errors.description}
                            value={formikProps.values.description}
                          />
                        </div>

                        <Grid style={{ marginTop: 0 }}>
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Center</label>
                              <div className="formMargin">
                                <Field
                                  name="centre_ids"
                                  component={RenderDropdown}
                                  onChange={(id) => {
                                    this.setState({
                                      centre_id: id,
                                    });
                                  }}
                                  validate={isRequired}
                                  placeholder="Choose Center"
                                  isTouched={formikProps.touched.centre_ids}
                                  isError={
                                    this.state.centre_id !== ""
                                      ? undefined
                                      : formikProps.errors.centre_ids
                                  }
                                  //value={this.state.centre_id !== "" ? this.state.centre_id : formikProps.values.centre_id}
                                  value={
                                    this.state.centre_id !== ""
                                      ? (formikProps.values.centre_ids =
                                          this.state.centre_id)
                                      : formikProps.values.centre_ids
                                  }
                                  // value
                                  options={this.getAllCentres()}
                                  selection
                                />
                              </div>
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">Class</label>
                              <div className="formMargin">
                                <Field
                                  name="class_ids"
                                  component={RenderDropdown}
                                  onChange={formikProps.handleChange(
                                    "class_ids"
                                  )}
                                  validate={isRequired}
                                  placeholder="Choose Center"
                                  isTouched={formikProps.touched.class_ids}
                                  isError={formikProps.errors.class_ids}
                                  value={formikProps.values.class_ids}
                                  options={this.getAllClasses(
                                    formikProps.values.centre_ids
                                  )}
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Event Date</label>
                              <div className="specific">
                                <Field
                                  name="event_date"
                                  component={RenderDatePicker}
                                  onChange={(time) => {
                                    this.setState({
                                      eventDate: time,
                                    });
                                  }}
                                  // dateFormat="YYYY MMM DD"
                                  // validate={isRequired}
                                  // isTouched={formikProps.touched.event_date}
                                  // isError={
                                  //   !this.state.eventDate &&
                                  //   "This inforimation is required"
                                  // }
                                  value={
                                    (formikProps.values.date =
                                      this.state.eventDate === null
                                        ? formikProps.values.date
                                        : this.state.eventDate)
                                  }
                                  {...formikProps}
                                />
                              </div>
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">
                                Max number of participant
                              </label>
                              <div className="formMargin">
                                <Field
                                  component={RenderInput}
                                  name="max_vacancy"
                                  onChange={formikProps.handleChange(
                                    "max_vacancy"
                                  )}
                                  validate={(value) => {
                                    if (!value) {
                                      this.setState({ errorMax_number: true });
                                    }

                                    if (/^\d{1,}$/.test(value)) {
                                      this.setState({ errorMax_number: false });
                                    } else {
                                      this.setState({ errorMax_number: true });
                                    }
                                  }}
                                  isTouched={formikProps.touched.max_vacancy}
                                  isError={
                                    this.state.errorMax_number
                                      ? "Number must be positive"
                                      : formikProps.errors.max_number
                                  }
                                  value={formikProps.values.max_vacancy}
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>

                        <Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Event Time</label>
                              <div className="formMargin">
                                <Field
                                  name="from_time"
                                  fromtoProps="From"
                                  component={RenderTimePicker}
                                  fromtoProps="From"
                                  onChange={(time) => {
                                    this.setState({
                                      from_time: time,
                                    });
                                  }}
                                  // validate={isRequired}
                                  // isTouched={formikProps.touched.from_time}
                                  // isError={
                                  //   !this.state.from_time
                                  //     ? "This information is required"
                                  //     : "Please set after the current time"
                                  // }
                                  // fromtoProps="From"
                                  value={
                                    this.state.from_time === null
                                      ? formikProps.values.from_time
                                      : (formikProps.values.from_time =
                                          this.state.from_time)
                                  }
                                  dateFormat="hh:mm a"
                                  minTime={setHours(
                                    setMinutes(new Date(), 0),
                                    7
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 0),
                                    23
                                  )}
                                  // value={formikProps.values.from_time}
                                />
                              </div>
                            </Grid.Column>
                            <Grid.Column>
                              <div
                                className="ui hidden divider"
                                style={{ marginTop: 14 }}
                              ></div>
                              <div className="">
                                <Field
                                  name="to_time"
                                  fromtoProps="To"
                                  component={RenderTimePicker}
                                  fromtoProps="To"
                                  onChange={(time) => {
                                    this.setState({
                                      to_time: time,
                                    });
                                  }}
                                  // validate={isRequired}
                                  // isTouched={formikProps.touched.to_time}
                                  // isError={
                                  //   // this.state.to_time > new Date()
                                  //   //   ? undefined
                                  //   //   : this.state.to_time >
                                  //   //     this.state.from_time
                                  //   //   ? "Event to time must be greater than from time"
                                  //   //   : formikProps.errors.to_time
                                  //   this.state.from_time &&
                                  //   this.state.to_time &&
                                  //   this.state.from_time.getTime() >
                                  //     this.state.to_time.getTime()
                                  //     ? "Event 'to time' must be greater than 'from time'"
                                  //     : !this.state.to_time &&
                                  //       "This information is required"
                                  // }
                                  // fromtoProps="To"
                                  value={
                                    this.state.to_time === null
                                      ? formikProps.values.to_time
                                      : (formikProps.values.to_time =
                                          this.state.to_time)
                                  }
                                  dateFormat="hh:mm a"
                                  minTime={setHours(
                                    setMinutes(new Date(), 0),
                                    7
                                  )}
                                  maxTime={setHours(
                                    setMinutes(new Date(), 0),
                                    23
                                  )}
                                  // value={formikProps.values.to_time}
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>

                        {/*
                        <label className="formLabel">Shift work</label>
                        <div className="formMargin">
                          <Field
                            name="shiftwork"
                            component={RenderRadioGroup}
                            options={this.props.options}
                            onChange={formikProps.handleChange("shiftwork")}
                            validate={isRequired}
                            isTouched={formikProps.touched.shiftwork}
                            isError={formikProps.errors.shiftwork}
                          />
                        </div>*/}

                        {/*<Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">Activity Date</label>
                              <Field
                                name="activityDate"
                                component={RenderPairDatePicker}
                                onChange={formikProps.handleChange(
                                  "activityDate"
                                )}
                                //validate={isRequired}
                                isTouched={formikProps.touched.activityDate}
                                isError={formikProps.errors.activityDate}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">Activity Time</label>
                              <Field
                                name="activityTime"
                                component={RenderPairTimePicker}
                                onChange={formikProps.handleChange(
                                  "activityTime"
                                )}
                                //validate={isRequired}
                                isTouched={formikProps.touched.activityTime}
                                isError={formikProps.errors.activityTime}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>*/}

                        <Grid.Row>
                          <Grid.Column>
                            <label className="formLabel">
                              Closing date for Registration
                            </label>
                            <div className="formMargin specific">
                              <Field
                                name="reg_to_date"
                                component={RenderDatePicker}
                                onChange={(time) => {
                                  this.setState({
                                    regtime: time,
                                  });
                                }}
                                // dateFormat="YYYY MMM DD"
                                validate={isRequired}
                                isTouched={formikProps.touched.reg_to_date}
                                isError={
                                  // this.state.regtime > new Date()
                                  //   ? undefined
                                  //   : formikProps.errors.reg_to_date
                                  this.state.eventDate &&
                                  this.state.regtime &&
                                  (this.state.eventDate.getTime() <
                                  this.state.regtime.getTime()
                                    ? "Must be less than registration date"
                                    : !this.state.eventDate &&
                                      "This information is required")
                                }
                                //isError={formikProps.errors.reg_to_date}
                                //value={isEdit ? formikProps.values.reg_to_date : formikProps.values.reg_to_date=this.state.regtime}
                                value={
                                  isEdit
                                    ? this.state.regtime > new Date()
                                      ? (formikProps.values.reg_to_date =
                                          this.state.regtime)
                                      : new Date(formikProps.values.reg_to_date)
                                    : this.state.regtime > new Date()
                                    ? (formikProps.values.reg_to_date =
                                        this.state.regtime)
                                    : this.state.regtime
                                }
                                // fromtoProps="From"
                                {...formikProps}
                              />
                            </div>
                          </Grid.Column>
                          <Grid.Column></Grid.Column>
                        </Grid.Row>
                        <label className="formLabel">Attachments</label>
                        <img
                          src={Exclamation}
                          width="20px"
                          height="20px"
                          style={{
                            position: "relative",
                            top: 3,
                            marginLeft: 10,
                          }}
                          data-for="main"
                          data-tip="Only allowed to attached file in .pdf format."
                          data-iscapture="true"
                        />
                        <ReactTooltip
                          id="main"
                          place="right"
                          type="info"
                          effect="float"
                          multiline={true}
                        />
                        <div
                          className="formMargin"
                          style={{
                            position: "relative",
                            marginBottom: this.props.pdfFileCheck ? 60 : 80,
                            background: "#F9FBFF",
                            color: "#4075FF",
                            width: window.innerWidth / 2.7,
                            height: 40,
                            borderRadius: 8,
                            border: " 1px solid #DADADA",
                            paddingLeft: 20,
                            paddingRight: 10,
                          }}
                        >
                          <div>
                            <input
                              type="file"
                              onChange={this.props.onChangePdf}
                              style={{
                                display: "none",
                              }}
                              id="pdfFile"
                              accept=".pdf"
                            />
                          </div>
                          <label
                            for="pdfFile"
                            style={{
                              position: "absolute",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <img
                                src={PdfFileIcon}
                                style={{ marginTop: 5, marginRight: 20 }}
                              />
                              <div
                                style={{
                                  marginTop: 10,
                                  borderBottom: "1px solid #4075FF",
                                }}
                              >
                                {" "}
                                {this.props.defaultPdfFileName.length > 50
                                  ? `${this.props.defaultPdfFileName.substring(
                                      0,
                                      50
                                    )}...`
                                  : this.props.defaultPdfFileName}
                              </div>
                            </div>
                          </label>
                          {this.props.showRemovePdfIcon && (
                            <div
                              style={{
                                position: "absolute",
                                right: 10,
                                top: 8,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.deletePdf();
                                document.getElementById("pdfFile").value = "";
                              }}
                            >
                              <img
                                src={CrossImgIcon}
                                width="24px"
                                height="24px"
                              />
                            </div>
                          )}
                        </div>

                        {this.props.pdfFileCheck && (
                          <div className="visible ui error message">
                            PDF file only allowed
                          </div>
                        )}

                        <label className="formLabel">Image</label>
                        <div className="formMargin">
                          <Field
                            name="img_url"
                            component={RenderImage}
                            onChange={this.props.onChange}
                            isTouched={formikProps.touched.image}
                            isError={
                              !this.props.imgReq
                                ? "This information is required"
                                : formikProps.errors.image
                            }
                            validate={isRequired}
                            previewUrl={
                              isEdit
                                ? this.props.previewUrl !== ""
                                  ? this.props.previewUrl
                                  : formikProps.values.img_url
                                : this.props.previewUrl
                            }
                          />
                          {/* {!this.props.imgReq && (
                            <div className="visible ui error message">
                              This information is required
                            </div>
                          )} */}
                        </div>

                        <label className="formLabel">Register link</label>
                        <div className="formMargin">
                          <Field
                            name="register_link"
                            // validate={isRequired}
                            component={RenderInput}
                            onChange={formikProps.handleChange("register_link")}
                            isTouched={formikProps.touched.register_link}
                            isError={formikProps.errors.register_link}
                            placeholder="https://"
                            value={formikProps.values.register_link}
                          />
                        </div>

                        {/*<Grid divided="vertically">
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <label className="formLabel">
                                Registration Date
                              </label>
                              <Field
                                name="regDate"
                                component={RenderPairDatePicker}
                                onChange={formikProps.handleChange("regDate")}
                                //validate={isRequired}
                                isTouched={formikProps.touched.regDate}
                                isError={formikProps.errors.regDate}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <label className="formLabel">
                                Registration Time
                              </label>
                              <Field
                                name="regTime"
                                component={RenderPairTimePicker}
                                onChange={formikProps.handleChange("regTime")}
                                //validate={isRequired}
                                isTouched={formikProps.touched.regTime}
                                isError={formikProps.errors.regTime}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>*/}

                        <Field
                          name="scfa"
                          component={RenderCheckbox}
                          onChange={formikProps.handleChange("scfa")}
                          //validate={isRequired}
                          isTouched={formikProps.touched.scfa}
                          isError={formikProps.errors.scfa}
                          value={formikProps.values.scfa === 1}
                          label="SCFA"
                        />
                        <br />

                        <div className="fullWidthCenterAlign">
                          <Button
                            content="Submit"
                            primary
                            size="large"
                            type="submit"
                            className="submitBtn"
                            disabled={this.props.isSaving}
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ActivityEditForm;
