import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Grid, Image } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import ic_time from '../assets/images/ic_time.svg';

class FormTime extends Component{
    state={
        startTime: new Date(),
    }
render(){
    const {onChange, value}=this.props;
    const previewTime = this.props.previewTime !== undefined ? this.props.previewTime : value;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className="formMargin">
            <DatePicker
              selected={previewTime}
              onChange={onChange}
              selectsStart
              startDate={this.state.startTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm"
              />
              <div className="input-iconnn">
                <h5>From</h5>
              </div>
              <div className="input-iconnnn">
                <Image src={ic_time}></Image>
              </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

}

export default FormTime;
