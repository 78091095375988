import React, { Component } from "react";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { MERCHANDISE_ROUTE } from "../../routes";
import RoutingButton from "../../components/RoutingButton";
import defaultimage from "../../assets/images/defaultimage.png";
import MerchandiseForm from "./MerchandiseForm";
import api from "../../config/api";
import { push } from "react-router-redux";
import { firebase } from "../../config/firebase";
import { connect } from "react-redux";
import { getMerchandiseTypes } from "../../store/actions";
import { getHQAdmin } from "../../store/actions";

class MerchandiseCreate extends Component {
  state = {
    previewUrl: defaultimage,
    count: "",
    imgs: [],
    previewUrlArr: [],
    onselectVal: [],
  };

  componentDidMount() {
    let centre_id = "8XbfLsbWOz09wfRh2Lxb";
    let user_type = "admin";
    let admin_type = "hq";

    this.props.getMerchandiseTypes();
    this.props.getHQAdmin(user_type, centre_id, admin_type);
  }

  handleSubmit = (values, img) => {
    if (
      values.name !== "" &&
      values.description !== "" &&
      values.price !== "" &&
      values.amount !== "" &&
      values.merchandise_type_id !== "" &&
      img.length > 0
    ) {
      let formdata = new FormData();
      this.setState({
        isSaving: true,
        hasError: false,
      });
      values.amount = this.state.count;
      img.map((img, idx) => {
        formdata.append(`img${idx + 1}`, img);
      });
      formdata.append("name", values.name);
      formdata.append("description", values.description);
      formdata.append("price", values.price);
      formdata.append("size", values.size);
      formdata.append("amount", values.amount);
      formdata.append("merchandise_type_id", values.merchandise_type_id);
      formdata.append("created_by", localStorage.getItem("user_Id"));
      formdata.append("number_of_images", img.length);
      console.log(this.state.onselectVal);
      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api.post("/merchandise/", formdata, token).then((res) => {
            if (res.message === "Success") {
              console.log(res);
              this.setState({
                isSaving: false,
                hasError: false,
              });
              this.props.dispatch(push(MERCHANDISE_ROUTE));
            } else {
              this.setState({
                isSaving: false,
                hasError: true,
              });
              alert(`${res.message}`);
            }
          });
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  onChange = (event) => {
    //https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        previewUrlArr: [, ...this.state.previewUrlArr, reader.result],
        imgs: [...this.state.imgs, file],
      });
    };
    reader.readAsDataURL(file);
  };

  getCount = (count) => {
    this.setState({
      count: count,
    });
  };

  getOnselectVal = (value) => {
    this.setState({
      onselectVal: value,
    });
  };

  render() {
    const { merchandisetypes = [], hqadmin = [] } = this.props;
    const { isSaving } = this.state;
    let hqAdmin = [],
      types = [];
    merchandisetypes.map((data) => {
      types.push({
        key: data.id,
        text: data.name,
        value: data.id,
      });
    });
    hqadmin.data !== undefined &&
      hqadmin.data.map((hqadmdata) => {
        hqAdmin.push({
          key: hqadmdata,
          text: hqadmdata.name,
          value: hqadmdata.id,
        });
      });
    return (
      <Grid className="fullHeight">
        <Dimmer active={isSaving} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Grid.Column width={16}>
            <RoutingButton
              to={MERCHANDISE_ROUTE}
              className="backBtn"
              btnText="Back"
              showIcon={true}
            />
            <MerchandiseForm
              handleSubmit={this.handleSubmit}
              types={types}
              onChange={this.onChange}
              getCount={this.getCount}
              previewUrl={this.state.previewUrl}
              previewUrlArr={this.state.previewUrlArr}
              formTitle={"Add Item"}
              count={this.state.count}
              hqAdmin={hqAdmin}
              imgs={this.state.imgs}
              getOnselectVal={this.getOnselectVal}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  merchandisetypes: state.merchandisetypes.merchandisetypes.data,
  hqadmin: state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMerchandiseTypes: () => dispatch(getMerchandiseTypes()),
  getHQAdmin: (user_type, centre_id, admin_type) =>
    dispatch(getHQAdmin(user_type, centre_id, admin_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchandiseCreate);
