import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Image,
  Modal,
  List,
  TransitionablePortal,
  Message,
  Icon,
  Dropdown,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import RenderButton from "../../components/Button";
import Exclamation from "../../assets/images/exclamation.png";
import {
  SCHEDULE_CREATE_ROUTE,
  SCHEDULE_TYPE_CREATE_ROUTE,
  SCHEDULE_ITEM_CREATE_ROUTE,
  CALENDAR_ROUTE,
} from "../../routes";
import "react-big-calendar/lib/css/react-big-calendar.css";
import checkIn from "../../assets/images/checkIn.png";
import morningGame from "../../assets/images/morningGame.png";
import breakfast from "../../assets/images/breakfast.png";
import reading from "../../assets/images/reading.png";
import homework from "../../assets/images/homework.png";
import lunch from "../../assets/images/lunch.png";
import emptyBox from "../../assets/images/ic_empty.svg";
import checkOut from "../../assets/images/checkOut.png";
import nap from "../../assets/images/nap.png";
import {
  getEvents,
  getEventDetail,
  getCentersClasses,
  getSchedules,
  getSchedule,
  getScheduleItems,
  getSchedulesCalendarView,
  getScheduleTypes,
  getSmallSchedulesCalendarView,
  getHolidays,
} from "../../store/actions";
import ClosedToday from "../../assets/images/closedToday.svg";
import RenderLoader from "../../components/Loader";
import arrow_right from "../../assets/images/arrow_right.svg";
import arrow_left from "../../assets/images/arrow_left.svg";
import RadioToggle from "../../components/RadioToggle";
import ScheduleForm from "./ScheduleForm";
import Xclose from "../../assets/images/Xclose.svg";
import { months, days, types, dateTypes } from "../custom.js";
import api from "../../config/api";
import { firebase } from "../../config/firebase";
import Edit from "../../assets/images/Union.svg";
import { arrayPop, formValueSelector, Form } from "redux-form";
import No_Schudule from "../../assets/images/no_schedule.png";
import dateformat from "dateformat";
const localizer = momentLocalizer(moment);
// var Holidays = require("date-holidays");
// var hd = new Holidays("SG");
let meal_Index = 0;
let mealList = "";
let meal_delete_Index = 0;
let centre_Index = 0;
let class_ids = "";
let centres_ids = "";

const holidayCToolBar = (props) => {
  const { view, views, onNavigate, label } = props;
  return (
    <ul className="holidayCToolBar">
      <li>
        <Image src={arrow_left} width={12} onClick={() => onNavigate("PREV")} />
      </li>
      <li style={{ fontSize: 17, fontWeight: 800, width: 143 }}>
        {props.label.split(" ")[0]}
      </li>
      <li>
        <Image
          src={arrow_right}
          width={12}
          onClick={() => onNavigate("NEXT")}
        />
      </li>
    </ul>
  );
};

const now = new Date();
class CalendarHome extends Component {
  state = {
    closedToday: false,
    selectedDate: new Date(),
    classActivities: [
      {
        backgroundColor: "#2190dc",
        icon: checkIn,
        text: "Check-in",
        startTime: "08:30 AM",
      },
      {
        backgroundColor: "#939494",
        icon: morningGame,
        text: "Morning game",
        startTime: "08:30 AM",
      },
      {
        backgroundColor: "#c697df",
        icon: breakfast,
        text: "Breakfast",
        startTime: "08:45 AM",
      },
      {
        backgroundColor: "#f66460",
        icon: reading,
        text: "Reading",
        startTime: "09:30 AM",
      },
      {
        backgroundColor: "#065ca4",
        icon: homework,
        text: "Homework",
        startTime: "10:00 AM",
      },
      {
        backgroundColor: "#11aee3",
        icon: lunch,
        text: "Lunch",
        startTime: "12:00 PM",
      },
      {
        backgroundColor: "#41cec3",
        icon: nap,
        text: "Nap",
        startTime: "1:00 PM",
      },
      {
        backgroundColor: "#f3b329",
        icon: checkOut,
        text: "Check-out",
        startTime: "03:10 PM",
      },
    ],
    isEditModalOpen: false,
    isholidayShow: true,
    holidays: [],
    scfas: [
      {
        text: "All",
        value: "all",
      },
      {
        text: "SCFA",
        value: 1,
      },
      {
        text: "No SCFA",
        value: 0,
      },
    ],
    Classes: [],
    classId: "",
    centerId: "",
    scheduleId: "",
    from: moment(new Date()).startOf("week").format("YYYY-MM-DD"),
    type: [
      { value: "subject", label: "Subject" },
      { value: "meal", label: "Meal" },
      { value: "centre_closed", label: "Centre Closed" },
    ],
    Loading: false,
    meal: [],
    delete_id: "",
    modal: false,
    closureConfirm: false,
    closureExistModa: false,
    formikValues: null,
    duplicateModal: false,
  };

  componentDidMount() {
    this.props.getCentersClasses();
    this.props.getSchedules();
    this.props.getScheduleTypes();
    this.props.getScheduleItems();
    this.props.getHolidays();
  }

  handleFilter = (e, res) => {
    const { centerclasses = [] } = this.props;
    // console.log(res);
    let classes = [],
      goldObj = {};
    this.setState(
      {
        [res.name]: res.value,
      },
      () => {
        let values = {};
        values.centre_id = this.state.centre_id;
        values.class_id = this.state.class_id;
        values.scfa = this.state.scfa;

        goldObj = centerclasses.find(
          (result) => result.id === values.centre_id
        );
        goldObj !== undefined &&
          goldObj.class.map((data) => {
            classes.push({
              key: data.id,
              text: data.name,
              value: data.id,
            });
            this.setState({
              Classes: classes,
            });
          });

        /**
         *  Functions to filter Schedules In Calendar
         *  schedulescalendarview array after api fixed
         */
        let from = moment().startOf("week");
        let to = moment().endOf("week");
        let fromdate = moment(from).format("YYYY-MM-DD");
        let todate = moment(to).format("YYYY-MM-DD");
        const { from_date, to_date } = this.state;
        if (from_date !== undefined && to_date !== undefined) {
          this.props.getSchedulesCalendarView(
            from_date,
            to_date,
            values.centre_id,
            values.class_id,
            values.scfa
          );
        } else {
          this.props.getSchedulesCalendarView(
            fromdate,
            todate,
            values.centre_id,
            values.class_id,
            values.scfa
          );
        }
        let formatedDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
        if (new Date(this.state.selectedDate).getDay() === 0) {
          this.setState({
            closedToday: true,
          });
        } else {
          this.setState({
            closedToday: false,
          });
        }

        this.props.getSmallSchedulesCalendarView(
          formatedDate,
          formatedDate,
          this.state.centre_id,
          this.state.class_id,
          this.state.scfa
        );
      }
    );
  };

  renderCustomEventTitle = () => <h1>{this.props.events[0].name}</h1>;

  handleEditEvent = (props) => {
    // console.log(props);
    if (props.title !== "Centre_Closed") {
      this.props.getSchedule(props.id);
      this.setState({
        scheduleId: props.id,
      });
      this.toggleEditModal();
    }
  };

  // handleDelete = (id) => {
  //   this.setState({ modal: true, delete_id: id });
  // };

  handleDelete = (id) => {
    this.setState({ Loading: true });
    let delete_schedule = new FormData();
    delete_schedule.append("id", id);
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        api.del("/schedules/schedule/", delete_schedule, token).then((res) => {
          if (res.message === "success") {
            this.setState({
              isSaving: false,
              hasError: false,
              Loading: false,
            });
            // this.props.dispatch(push(CALENDAR_ROUTE));
            this.toggleEditModal();
            let from = moment().startOf("week");
            let to = moment().endOf("week");
            let fromdate = moment(from).format("YYYY-MM-DD");
            let todate = moment(to).format("YYYY-MM-DD");
            const { from_date, to_date } = this.state;
            if (from_date !== undefined && to_date !== undefined) {
              this.props.getSchedulesCalendarView(
                from_date,
                to_date,
                this.state.centre_id,
                this.state.class_id,
                this.state.scfa
              );
            } else {
              this.props.getSchedulesCalendarView(
                fromdate,
                todate,
                this.state.centre_id,
                this.state.class_id,
                this.state.scfa
              );
            }
            this.props.getScheduleItems();
          } else {
            this.setState({
              isSaving: false,
              hasError: true,
              Loading: false,
            });
            alert(`${res.message}`);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  handleSubmit = (values, meal_list, meal_list_edit, meal_delete) => {
    console.log(values);
    this.setState({ Loading: true });
    class_ids = "";
    centres_ids = "";
    centre_Index = 0;
    // console.log(values.specific_date.toString());
    // const centre = this.props.centerclasses.filter(
    //   (center) => center.id === values.centsre_ids
    // );
    // let class_ids = [];

    // values.class_ids.forEach((class_id) => {
    //   const temp_class_ids = centre[0].class.filter(
    //     (centre_class) => centre_class.name === class_id
    //   );
    //   class_ids.push(temp_class_ids[0].id);
    // });
    // values.class_ids_modified = class_ids;

    const from_hours =
      values.from_time.getHours() < 10
        ? `0${values.from_time.getHours()}`
        : `${values.from_time.getHours()}`;
    const from_minutes =
      values.from_time.getMinutes() < 10
        ? `0${values.from_time.getMinutes()}`
        : `${values.from_time.getMinutes()}`;
    const from_seconds =
      values.from_time.getSeconds() < 10
        ? `0${values.from_time.getSeconds()}`
        : `${values.from_time.getSeconds()}`;
    const from_time = from_hours + ":" + from_minutes + ":" + from_seconds;
    const to_hours =
      values.to_time.getHours() < 10
        ? `0${values.to_time.getHours()}`
        : `${values.to_time.getHours()}`;
    const to_minutes =
      values.to_time.getMinutes() < 10
        ? `0${values.to_time.getMinutes()}`
        : `${values.to_time.getMinutes()}`;
    const to_seconds =
      values.to_time.getSeconds() < 10
        ? `0${values.to_time.getSeconds()}`
        : `${values.to_time.getSeconds()}`;
    const to_time = to_hours + ":" + to_minutes + ":" + to_seconds;
    values.from_tim = from_time;
    values.to_tim = to_time;
    if (values.type === "V6tRBBDYhArEcVVjY9oT") {
      mealList = "";
      meal_Index = 0;
      meal_delete_Index = 0;
      this.AddScheduleItem(values, meal_list, meal_list_edit, meal_delete);
    } else {
      this.centreClassHandler(values);
    }
  };

  AddScheduleItem = (values, meal_list, meal_list_edit, meal_delete) => {
    console.log(mealList);
    if (meal_list.length > meal_Index) {
      const find_meal = meal_list_edit.find(
        (data) => data.id === meal_list[meal_Index].id
      );
      console.log(find_meal, meal_list[meal_Index]);
      if (find_meal) {
        if (
          find_meal.name === meal_list[meal_Index].title &&
          find_meal.img_url === meal_list[meal_Index].img
        ) {
          console.log("find");
          console.log(mealList);
          mealList += (mealList === "" ? "" : ",") + find_meal.id;
          meal_Index = meal_Index + 1;
          this.AddScheduleItem(values, meal_list, meal_list_edit, meal_delete);
        } else {
          console.log("not");
          let editMeal = new FormData();
          editMeal.append("id", meal_list[meal_Index].id);
          editMeal.append("name", meal_list[meal_Index].title);
          editMeal.append("img_url", meal_list[meal_Index].img);
          editMeal.append("thumbnail", meal_list[meal_Index].img);
          return firebase.reloadAuth().then((res) => {
            try {
              const token = res.ya === null ? res.za : res.ya;

              api
                .post("/schedules/schedule_item/", editMeal, token)
                .then((res) => {
                  if (res.message === "success") {
                    mealList +=
                      (mealList === "" ? "" : ",") + meal_list[meal_Index].id;
                    meal_Index = meal_Index + 1;
                    this.AddScheduleItem(
                      values,
                      meal_list,
                      meal_list_edit,
                      meal_delete
                    );
                  }
                });
            } catch (err) {
              console.log(err);
            }
          });
        }
      } else {
        console.log("notFind");
        let createMeal = new FormData();
        createMeal.append("name", meal_list[meal_Index].title);
        createMeal.append("img_url", meal_list[meal_Index].img);
        createMeal.append("thumbnail", meal_list[meal_Index].img);
        return firebase.reloadAuth().then((res) => {
          try {
            const token = res.ya === null ? res.za : res.ya;

            api
              .post("/schedules/schedule_item/", createMeal, token)
              .then((res) => {
                if (res.message === "success") {
                  mealList += (mealList === "" ? "" : ",") + res.data.id;
                  meal_Index = meal_Index + 1;
                  this.AddScheduleItem(
                    values,
                    meal_list,
                    meal_list_edit,
                    meal_delete
                  );
                }
              });
          } catch (err) {
            console.log(err);
          }
        });
      }
    } else if (meal_list.length === meal_Index) {
      if (values.meal_title !== "" && values.meal_img !== "") {
        if (values.meal_id !== "") {
          console.log("values not null");
          const find_edit_meal = meal_list_edit.find(
            (data) => data.id === values.meal_id
          );
          if (
            find_edit_meal.name === values.meal_title &&
            find_edit_meal.img_url === values.meal_img
          ) {
            mealList += (mealList === "" ? "" : ",") + values.meal_id;
            meal_Index = meal_Index + 1;
            this.AddScheduleItem(
              values,
              meal_list,
              meal_list_edit,
              meal_delete
            );
          } else {
            let editMeal = new FormData();
            editMeal.append("id", values.meal_id);
            editMeal.append("name", values.meal_title);
            editMeal.append("img_url", values.meal_img);
            editMeal.append("thumbnail", values.meal_img);
            return firebase.reloadAuth().then((res) => {
              try {
                const token = res.ya === null ? res.za : res.ya;

                api
                  .post("/schedules/schedule_item/", editMeal, token)
                  .then((res) => {
                    if (res.message === "success") {
                      mealList += (mealList === "" ? "" : ",") + values.meal_id;
                      meal_Index = meal_Index + 1;
                      this.AddScheduleItem(
                        values,
                        meal_list,
                        meal_list_edit,
                        meal_delete
                      );
                    }
                  });
              } catch (err) {
                console.log(err);
              }
            });
          }
        } else {
          console.log("id null");
          let createMeal = new FormData();
          createMeal.append("name", values.meal_title);
          createMeal.append("img_url", values.meal_img);
          createMeal.append("thumbnail", values.meal_img);
          return firebase.reloadAuth().then((res) => {
            try {
              const token = res.ya === null ? res.za : res.ya;
              api
                .post("/schedules/schedule_item/", createMeal, token)
                .then((res) => {
                  if (res.message === "success") {
                    mealList += (mealList === "" ? "" : ",") + res.data.id;
                    meal_Index = meal_Index + 1;
                    this.AddScheduleItem(
                      values,
                      meal_list,
                      meal_list_edit,
                      meal_delete
                    );
                  }
                });
            } catch (err) {
              console.log(err);
            }
          });
        }
      } else {
        this.deleteSchedule(values, meal_list, meal_list_edit, meal_delete);
      }
    } else {
      console.log("null");
      this.deleteSchedule(values, meal_list, meal_list_edit, meal_delete);
    }
  };
  deleteSchedule = (values, meal_list, meal_list_edit, meal_delete) => {
    console.log(meal_delete);
    if (meal_delete.length > meal_delete_Index) {
      let delete_meal = new FormData();
      delete_meal.append("id", meal_delete[meal_delete_Index].id);
      return firebase.reloadAuth().then((res) => {
        try {
          const token = res.ya === null ? res.za : res.ya;

          api
            .del("/schedules/schedule_item/", delete_meal, token)
            .then((res) => {
              if (res.message === "success") {
                meal_delete_Index = meal_delete_Index + 1;
                this.deleteSchedule(
                  values,
                  meal_list,
                  meal_list_edit,
                  meal_delete
                );
              }
            });
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      this.centreClassHandler(values);
    }
  };

  centreClassHandler = (values) => {
    if (values.centre_ids.length > centre_Index) {
      const centre = this.props.centerclasses.find(
        (centre) => centre.id === values.centre_ids[centre_Index]
      );
      if (centre) {
        values.class_ids.forEach((classes) => {
          const find_class = centre.class.find(
            (class_find) => class_find.name === classes
          );
          class_ids += (class_ids === "" ? "" : ",") + find_class.id;
        });
        centre_Index = centre_Index + 1;
        this.centreClassHandler(values);
      }
    } else {
      values.centre_ids.forEach((data) => {
        centres_ids += (centres_ids === "" ? "" : ",") + data;
      });
      if (values.type === "7BFBdPuRsBkFoxlDnqaP") {
        this.centreClosureCheck(values);
      } else {
        this.EditSchedule(values);
      }
    }
  };

  centreClosureCheck = (values, meal_list) => {
    let formData = new FormData();
    formData.append("id", values.id);
    formData.append("centre_ids", centres_ids);
    formData.append("class_ids", class_ids);
    if (values.dateTypes === "routine") {
      formData.append("months", values.months);
      formData.append("days", values.days);
    } else {
      // formData.append(
      //   "from_date",
      //   moment(values.specific_from).format("YYYY-MM-DD")
      // );
      // formData.append(
      //   "to_date",
      //   moment(values.specific_to).format("YYYY-MM-DD")
      // );
      formData.append("date_lists", values.specific_date.toString());
    }
    formData.append("schedule_type_id", values.type);
    formData.append("occurrence", values.dateTypes);
    formData.append("description", values.description);

    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;
        api.post("/schedules/check_closure", formData, token).then((res) => {
          console.log(res);
          if (
            res.message.has_schedules === true &&
            res.message.existing_closure === true
          ) {
            this.setState({ closureExistModal: true });
          } else if (
            res.message.has_schedules === true &&
            res.message.existing_closure === false
          ) {
            this.setState({
              closureConfirm: true,
              formikValues: values,
            });
          } else {
            this.EditSchedule(values);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  EditSchedule = (values) => {
    let formdata = new FormData();
    this.setState({
      isSaving: true,
      hasError: false,
      Loading: true,
    });
    formdata.append("id", values.id);
    formdata.append("centre_ids", centres_ids);
    formdata.append("class_ids", class_ids);
    if (values.dateTypes === "routine") {
      formdata.append("days", values.days);
      formdata.append("months", values.months);
      formdata.append("from_time", values.from_tim);
      formdata.append("to_time", values.to_tim);
    }
    if (values.dateTypes === "specific_time") {
      formdata.append("occurrence", values.dateTypes);
      formdata.append(
        "from_time",
        values.specific_time_from.getHours() +
          ":" +
          values.specific_time_from.getMinutes() +
          ":" +
          values.specific_time_from.getSeconds()
      );
      formdata.append(
        "to_time",
        values.specific_time_to.getHours() +
          ":" +
          values.specific_time_to.getMinutes() +
          ":" +
          values.specific_time_to.getSeconds()
      );
      // formdata.append(
      //   "from_date",
      //   moment(values.specific_from).format("YYYY-MM-DD")
      // );
      // formdata.append(
      //   "to_date",
      //   moment(values.specific_to).format("YYYY-MM-DD")
      // );
      formdata.append("date_lists", values.specific_date.toString());
    }
    formdata.append(
      "title",
      values.type === "7BFBdPuRsBkFoxlDnqaP" ? "" : values.title
    );
    if (values.type === "7BFBdPuRsBkFoxlDnqaP") {
      formdata.append("description", values.description);
    }
    formdata.append("schedule_type_id", values.type);
    if (values.type === "V6tRBBDYhArEcVVjY9oT") {
      formdata.append(
        "icon_img",
        "https://d2t2athllyh1ku.cloudfront.net/images/icons_/VmVjdG9yMjAyMS0wNi0wM1QxMDoyMToyN1o=.png"
      );
    }
    if (values.type !== "V6tRBBDYhArEcVVjY9oT") {
      formdata.append("icon_img", values.icon_img);
    }
    formdata.append("scfa", values.scfa);
    if (values.type === "V6tRBBDYhArEcVVjY9oT") {
      formdata.append("schedule_item_ids", mealList);
    }
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api.post("/schedules/schedule/", formdata, token).then((res) => {
          if (res.message === "success") {
            console.log(res);
            this.setState({
              isSaving: false,
              hasError: false,
              Loading: false,
            });
            // this.props.dispatch(push(CALENDAR_ROUTE));
            this.toggleEditModal();
            let from = moment().startOf("week");
            let to = moment().endOf("week");
            let fromdate = moment(from).format("YYYY-MM-DD");
            let todate = moment(to).format("YYYY-MM-DD");
            const { from_date, to_date } = this.state;
            if (from_date !== undefined && to_date !== undefined) {
              this.props.getSchedulesCalendarView(
                from_date,
                to_date,
                this.state.centre_id,
                this.state.class_id,
                this.state.scfa
              );
            } else {
              this.props.getSchedulesCalendarView(
                fromdate,
                todate,
                this.state.centre_id,
                this.state.class_id,
                this.state.scfa
              );
            }
            this.props.getScheduleItems();
          } else {
            this.setState({
              isSaving: false,
              hasError: true,
              Loading: false,
              duplicateModal: true,
            });
            alert(`${res.message}`);
            console.log(res);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  centreClosuerConfirm = () => {
    this.setState({ closureConfirm: false });
    this.EditSchedule(this.state.formikValues);
  };

  toggleEditModal = () => {
    this.setState({
      isEditModalOpen: !this.state.isEditModalOpen,
    });
  };

  toggleHoliday = () => {
    this.setState({
      isholidayShow: !this.state.isholidayShow,
    });
  };

  getAllCalendarEvents = (fromdate, todate, classId, centreId, scfa) => {
    //call api here
  };

  getStartDate = (from, to) => {
    let fromDate = new Date(from - 7 * (3600 * 1000 * 24));
    let toDate = new Date(to - 7 * (3600 * 1000 * 24));
    let from_date = moment(fromDate).format("YYYY-MM-DD");
    let to_date = moment(toDate).format("YYYY-MM-DD");
    this.setState({
      from_date: from_date,
      to_date: to_date,
    });
    this.props.getSchedulesCalendarView(
      from_date,
      to_date,
      this.state.centre_id,
      this.state.class_id,
      this.state.scfa
    );
  };
  getEndDate = (from, to) => {
    let fromDate = new Date(from + 7 * (3600 * 1000 * 24));
    let toDate = new Date(to + 7 * (3600 * 1000 * 24));
    let from_date = moment(fromDate).format("YYYY-MM-DD");
    let to_date = moment(toDate).format("YYYY-MM-DD");
    this.setState({
      from_date: from_date,
      to_date: to_date,
    });
    this.props.getSchedulesCalendarView(
      from_date,
      to_date,
      this.state.centre_id,
      this.state.class_id,
      this.state.scfa
    );
  };

  header = (props) => {
    const label = props.label.split(" ");
    return (
      <div>
        <p className="calendar_header">
          {label[1] === "Sun"
            ? "Sunday"
            : label[1] === "Mon"
            ? "Monday"
            : label[1] === "Tue"
            ? "Tuesday"
            : label[1] === "Wed"
            ? "Wednesday"
            : label[1] === "Thu"
            ? "Thursday"
            : label[1] === "Fri"
            ? "Friday"
            : "Saturday"}
        </p>
        <p className="calendar_header_date">
          <span style={{ paddingTop: "4px", width: "25px", height: "25px" }}>
            {label[0]}
          </span>
        </p>
      </div>
    );
  };

  eventColor = (title) => {
    if (title !== null) {
      if (
        title.toLowerCase().includes("check-in") ||
        title.toLowerCase().includes("check in")
      ) {
        return "#F5FBFF";
      } else if (title.toLowerCase().includes("game")) {
        return "#F1F0F0";
      } else if (title.toLowerCase().includes("breakfast")) {
        return " #FBF3FF";
      } else if (title.toLowerCase().includes("reading")) {
        return "#FFE6E6";
      } else if (title.toLowerCase().includes("homework")) {
        return "#E6F1FC";
      } else if (title.toLowerCase().includes("work shop")) {
        return "##F9FFF2";
      } else if (title.toLowerCase().includes("lunch")) {
        return "#F5FBFF";
      } else if (title.toLowerCase().includes("nap")) {
        return "#E7FFFD";
      } else if (
        title.toLowerCase().includes("check-out") ||
        title.toLowerCase().includes("check out")
      ) {
        return "#FFFDF9";
      } else {
        return "#FAFAFA";
      }
    }
  };
  eventColorLetter = (title) => {
    if (title !== null) {
      if (
        title.toLowerCase().includes("check-in") ||
        title.toLowerCase().includes("check in")
      ) {
        return "#2190DC";
      } else if (title.toLowerCase().includes("game")) {
        return "#939494";
      } else if (title.toLowerCase().includes("breakfast")) {
        return " #C697DF";
      } else if (title.toLowerCase().includes("reading")) {
        return "#F66460";
      } else if (title.toLowerCase().includes("homework")) {
        return "#065CA4";
      } else if (title.toLowerCase().includes("work shop")) {
        return "#7CD227";
      } else if (title.toLowerCase().includes("lunch")) {
        return "#2190DC";
      } else if (title.toLowerCase().includes("nap")) {
        return "#41CEC3";
      } else if (
        title.toLowerCase().includes("check-out") ||
        title.toLowerCase().includes("check out")
      ) {
        return "#F3B329";
      } else {
        return "#939494";
      }
    }
  };

  customEvent = (props) => {
    // console.log(props);
    const startTime = moment(props.event.start).format("hh:mm");
    const endTime = moment(props.event.end).format("hh:mm A");
    if (props.title !== "" && props.title !== "Centre_Closed") {
      return (
        <div
          style={{
            height: "100%",
            backgroundColor: this.eventColor(props.title),
            minHeight: "50px",
            border: `1px solid ${this.eventColorLetter(props.title)}`,
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              position: "absolute",
              right: "11px",
              top: "8px",
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 132 132"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M109.837 0.800411C111.784 0.11531 113.857 -0.134547 115.911 0.0684422C120.028 0.451628 123.877 2.28129 126.773 5.23263C129.716 8.13219 131.538 11.9801 131.915 16.0945C132.114 18.1491 131.863 20.2224 131.178 22.1697C130.493 24.117 129.391 25.8913 127.95 27.3688L78.8763 76.4369C78.0054 77.3123 76.8635 77.8668 75.6369 78.0098L57.9885 80.1051C57.7732 80.1315 57.5565 80.1443 57.3395 80.1436C56.5625 80.1436 55.7944 79.979 55.0856 79.6605C54.3769 79.3421 53.7437 78.8771 53.2277 78.2961C52.7117 77.7152 52.3247 77.0315 52.0921 76.2902C51.8595 75.5488 51.7867 74.7666 51.8784 73.995L53.9738 56.3466C54.1167 55.12 54.6712 53.9781 55.5467 53.1073L104.642 4.0337C106.116 2.58919 107.89 1.48551 109.837 0.800411ZM72.4691 67.3129L120.195 19.5868C122.016 17.7664 120.481 14.4611 118.991 12.9872C117.5 11.5133 114.212 10.0228 112.424 11.8322L64.6926 59.5584L63.6422 68.3578L72.4691 67.3129ZM93.4944 132H16.499C12.1232 132 7.92661 130.262 4.83245 127.168C1.73828 124.073 0 119.877 0 115.501V33.0061C0 28.6302 1.73828 24.4337 4.83245 21.3395C7.92661 18.2453 12.1232 16.507 16.499 16.507H63.3562C64.8148 16.507 66.2137 17.0865 67.245 18.1179C68.2764 19.1493 68.8559 20.5481 68.8559 22.0067C68.8559 23.4653 68.2764 24.8642 67.245 25.8956C66.2137 26.927 64.8148 27.5064 63.3562 27.5064H16.499C15.0404 27.5064 13.6415 28.0858 12.6102 29.1172C11.5788 30.1486 10.9993 31.5475 10.9993 33.0061V115.501C10.9993 116.96 11.5788 118.359 12.6102 119.39C13.6415 120.421 15.0404 121.001 16.499 121.001H93.4944C94.953 121.001 96.3518 120.421 97.3832 119.39C98.4146 118.359 98.994 116.96 98.994 115.501V74.1436C98.994 72.685 99.5735 71.2861 100.605 70.2547C101.636 69.2233 103.035 68.6439 104.494 68.6439C105.952 68.6439 107.351 69.2233 108.383 70.2547C109.414 71.2861 109.993 72.685 109.993 74.1436V115.501C109.993 119.877 108.255 124.073 105.161 127.168C102.067 130.262 97.8702 132 93.4944 132Z"
                fill={this.eventColorLetter(props.title)}
              />
            </svg>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "13px",
              color: this.eventColorLetter(props.title),
            }}
          >
            <p
              style={{
                margin: "0px",
                color: this.eventColorLetter(props.title),
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "16px",
              }}
            >
              {props.title}
            </p>
            <p
              style={{
                color: this.eventColorLetter(props.title),
                fontFamily: "Open Sans",
                fontSize: "8px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "11px",
              }}
            >
              {startTime} ~ {endTime}
            </p>
          </div>
        </div>
      );
    } else if (props.title === "Centre_Closed") {
      return (
        <div
          style={{
            height: "100%",
            minHeight: "50px",
            border: "1px solid #939494",
            backgroundColor: "#FAFAFA",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              position: "absolute",
              right: "11px",
              top: "8px",
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 132 132"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M109.837 0.800411C111.784 0.11531 113.857 -0.134547 115.911 0.0684422C120.028 0.451628 123.877 2.28129 126.773 5.23263C129.716 8.13219 131.538 11.9801 131.915 16.0945C132.114 18.1491 131.863 20.2224 131.178 22.1697C130.493 24.117 129.391 25.8913 127.95 27.3688L78.8763 76.4369C78.0054 77.3123 76.8635 77.8668 75.6369 78.0098L57.9885 80.1051C57.7732 80.1315 57.5565 80.1443 57.3395 80.1436C56.5625 80.1436 55.7944 79.979 55.0856 79.6605C54.3769 79.3421 53.7437 78.8771 53.2277 78.2961C52.7117 77.7152 52.3247 77.0315 52.0921 76.2902C51.8595 75.5488 51.7867 74.7666 51.8784 73.995L53.9738 56.3466C54.1167 55.12 54.6712 53.9781 55.5467 53.1073L104.642 4.0337C106.116 2.58919 107.89 1.48551 109.837 0.800411ZM72.4691 67.3129L120.195 19.5868C122.016 17.7664 120.481 14.4611 118.991 12.9872C117.5 11.5133 114.212 10.0228 112.424 11.8322L64.6926 59.5584L63.6422 68.3578L72.4691 67.3129ZM93.4944 132H16.499C12.1232 132 7.92661 130.262 4.83245 127.168C1.73828 124.073 0 119.877 0 115.501V33.0061C0 28.6302 1.73828 24.4337 4.83245 21.3395C7.92661 18.2453 12.1232 16.507 16.499 16.507H63.3562C64.8148 16.507 66.2137 17.0865 67.245 18.1179C68.2764 19.1493 68.8559 20.5481 68.8559 22.0067C68.8559 23.4653 68.2764 24.8642 67.245 25.8956C66.2137 26.927 64.8148 27.5064 63.3562 27.5064H16.499C15.0404 27.5064 13.6415 28.0858 12.6102 29.1172C11.5788 30.1486 10.9993 31.5475 10.9993 33.0061V115.501C10.9993 116.96 11.5788 118.359 12.6102 119.39C13.6415 120.421 15.0404 121.001 16.499 121.001H93.4944C94.953 121.001 96.3518 120.421 97.3832 119.39C98.4146 118.359 98.994 116.96 98.994 115.501V74.1436C98.994 72.685 99.5735 71.2861 100.605 70.2547C101.636 69.2233 103.035 68.6439 104.494 68.6439C105.952 68.6439 107.351 69.2233 108.383 70.2547C109.414 71.2861 109.993 72.685 109.993 74.1436V115.501C109.993 119.877 108.255 124.073 105.161 127.168C102.067 130.262 97.8702 132 93.4944 132Z"
                fill="#939494"
              />
            </svg>
          </div>
          <div
            style={{
              color: "#939494",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div style={{ width: "48px", height: "40px" }}>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 48 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M43.9999 9.99992H33.414L25.9014 2.48728C25.9427 2.32725 26 2.17275 26 1.99996C26 0.897467 25.1025 0 24 0C22.8976 0 22.0001 0.897467 22.0001 1.99996C22.0001 2.17265 22.0574 2.32725 22.0987 2.48728L14.5861 9.99992H4.00002C1.7939 9.99992 0 11.7939 0 13.9999V35.9999C0 38.206 1.7939 40 4.00002 40H44C46.206 40 48 38.2061 48 35.9999V13.9999C47.9999 11.7939 46.206 9.99992 43.9999 9.99992ZM17.414 9.99992L23.5126 3.9013C23.6727 3.94265 23.8272 3.99993 24 3.99993C24.1726 3.99993 24.3272 3.94265 24.4873 3.9013L30.5859 9.99992H17.414Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M45.9999 36C45.9999 37.1025 45.1024 37.9999 43.9999 37.9999H3.99996C2.89747 37.9999 2 37.1025 2 36V14C2 12.8975 2.89747 12 3.99996 12H43.9999C45.1024 12 45.9999 12.8975 45.9999 14V36Z"
                  fill="#FAFAFA"
                />
                <path
                  d="M36.0001 21.9998C36.5528 21.9998 37.0001 21.5525 37.0001 20.9998C37.0001 20.447 36.5528 19.9998 36.0001 19.9998H32C31.4473 19.9998 31 20.447 31 20.9998V28.9998C31 29.5526 31.4473 29.9999 32 29.9999H36.0001C36.5528 29.9999 37.0001 29.5526 37.0001 28.9998C37.0001 28.4471 36.5528 27.9998 36.0001 27.9998H33.0001V25.9998H35C35.5528 25.9998 36.0001 25.5526 36.0001 24.9998C36.0001 24.4471 35.5528 23.9998 35 23.9998H33.0001V21.9998H36.0001Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M20 20C18.3457 20 17 21.3457 17 23V27C17 28.6543 18.3457 30 20 30C21.6543 30 23 28.6543 23 27V23C23 21.3457 21.6543 20 20 20ZM21 27C21 27.5517 20.5518 28 20 28C19.4483 28 19 27.5518 19 27V23C19 22.4483 19.4482 22 20 22C20.5517 22 21 22.4482 21 23V27Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M6.9999 22.0007C7.55162 22.0007 7.99993 22.4489 7.99993 23.0007C7.99993 23.5535 8.44721 24.0008 8.99996 24.0008C9.55271 24.0008 9.99999 23.5535 9.99999 23.0007C9.99999 21.3464 8.6543 20.0007 6.99999 20.0007C5.34568 20.0007 4 21.3464 4 23.0007V27.0007C4 28.6551 5.34568 30.0007 6.99999 30.0007C8.6543 30.0007 9.99999 28.6551 9.99999 27.0007C9.99999 26.448 9.55271 26.0007 8.99996 26.0007C8.44721 26.0007 7.99993 26.448 7.99993 27.0007C7.99993 27.5525 7.55171 28.0008 6.9999 28.0008C6.44818 28.0008 5.99987 27.5526 5.99987 27.0007V23.0007C5.99987 22.449 6.44809 22.0007 6.9999 22.0007Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M27 21.9998H29C29.5527 21.9998 30 21.5525 30 20.9998C30 20.447 29.5527 19.9998 29 19.9998H27C25.3457 19.9998 24 21.3454 24 22.9998C24 24.6541 25.3457 25.9997 27 25.9997C27.5517 25.9997 28 26.448 28 26.9998C28 27.5515 27.5518 27.9998 27 27.9998H25C24.4473 27.9998 24 28.4471 24 28.9998C24 29.5526 24.4473 29.9999 25 29.9999H27C28.6543 29.9999 30 28.6542 30 26.9999C30 25.3456 28.6543 23.9999 27 23.9999C26.4483 23.9999 26 23.5517 26 22.9998C26 22.4481 26.4483 21.9998 27 21.9998Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M41 20H39C38.4473 20 38 20.4473 38 21V29C38 29.5527 38.4473 30 39 30H41C42.6543 30 44 28.6543 44 27V23C44 21.3457 42.6543 20 41 20ZM42 27C42 27.5517 41.5518 28 41 28H40V22.0001H41C41.5517 22.0001 42 22.4483 42 23.0001V27Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M16.0001 27.9998H13.0001V20.9998C13.0001 20.447 12.5528 19.9998 12 19.9998C11.4473 19.9998 11 20.447 11 20.9998V28.9998C11 29.5526 11.4473 29.9999 12 29.9999H16.0001C16.5528 29.9999 17.0001 29.5526 17.0001 28.9998C17 28.4471 16.5528 27.9998 16.0001 27.9998Z"
                  fill="#DCDCDC"
                />
              </svg>
            </div>
            <p
              style={{
                margin: "0px",
                marginTop: "10px",
                color: "#939494",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "16px",
                textAlign: "center",
              }}
            >
              Centre Closed
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: "100%",
            minHeight: "50px",
            border: "1px solid #939494",
            backgroundColor: "#FAFAFA",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              position: "absolute",
              right: "11px",
              top: "8px",
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 132 132"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M109.837 0.800411C111.784 0.11531 113.857 -0.134547 115.911 0.0684422C120.028 0.451628 123.877 2.28129 126.773 5.23263C129.716 8.13219 131.538 11.9801 131.915 16.0945C132.114 18.1491 131.863 20.2224 131.178 22.1697C130.493 24.117 129.391 25.8913 127.95 27.3688L78.8763 76.4369C78.0054 77.3123 76.8635 77.8668 75.6369 78.0098L57.9885 80.1051C57.7732 80.1315 57.5565 80.1443 57.3395 80.1436C56.5625 80.1436 55.7944 79.979 55.0856 79.6605C54.3769 79.3421 53.7437 78.8771 53.2277 78.2961C52.7117 77.7152 52.3247 77.0315 52.0921 76.2902C51.8595 75.5488 51.7867 74.7666 51.8784 73.995L53.9738 56.3466C54.1167 55.12 54.6712 53.9781 55.5467 53.1073L104.642 4.0337C106.116 2.58919 107.89 1.48551 109.837 0.800411ZM72.4691 67.3129L120.195 19.5868C122.016 17.7664 120.481 14.4611 118.991 12.9872C117.5 11.5133 114.212 10.0228 112.424 11.8322L64.6926 59.5584L63.6422 68.3578L72.4691 67.3129ZM93.4944 132H16.499C12.1232 132 7.92661 130.262 4.83245 127.168C1.73828 124.073 0 119.877 0 115.501V33.0061C0 28.6302 1.73828 24.4337 4.83245 21.3395C7.92661 18.2453 12.1232 16.507 16.499 16.507H63.3562C64.8148 16.507 66.2137 17.0865 67.245 18.1179C68.2764 19.1493 68.8559 20.5481 68.8559 22.0067C68.8559 23.4653 68.2764 24.8642 67.245 25.8956C66.2137 26.927 64.8148 27.5064 63.3562 27.5064H16.499C15.0404 27.5064 13.6415 28.0858 12.6102 29.1172C11.5788 30.1486 10.9993 31.5475 10.9993 33.0061V115.501C10.9993 116.96 11.5788 118.359 12.6102 119.39C13.6415 120.421 15.0404 121.001 16.499 121.001H93.4944C94.953 121.001 96.3518 120.421 97.3832 119.39C98.4146 118.359 98.994 116.96 98.994 115.501V74.1436C98.994 72.685 99.5735 71.2861 100.605 70.2547C101.636 69.2233 103.035 68.6439 104.494 68.6439C105.952 68.6439 107.351 69.2233 108.383 70.2547C109.414 71.2861 109.993 72.685 109.993 74.1436V115.501C109.993 119.877 108.255 124.073 105.161 127.168C102.067 130.262 97.8702 132 93.4944 132Z"
                fill="#939494"
              />
            </svg>
          </div>
          <div
            style={{
              color: "#939494",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div style={{ width: "48px", height: "40px" }}>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 48 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M43.9999 9.99992H33.414L25.9014 2.48728C25.9427 2.32725 26 2.17275 26 1.99996C26 0.897467 25.1025 0 24 0C22.8976 0 22.0001 0.897467 22.0001 1.99996C22.0001 2.17265 22.0574 2.32725 22.0987 2.48728L14.5861 9.99992H4.00002C1.7939 9.99992 0 11.7939 0 13.9999V35.9999C0 38.206 1.7939 40 4.00002 40H44C46.206 40 48 38.2061 48 35.9999V13.9999C47.9999 11.7939 46.206 9.99992 43.9999 9.99992ZM17.414 9.99992L23.5126 3.9013C23.6727 3.94265 23.8272 3.99993 24 3.99993C24.1726 3.99993 24.3272 3.94265 24.4873 3.9013L30.5859 9.99992H17.414Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M45.9999 36C45.9999 37.1025 45.1024 37.9999 43.9999 37.9999H3.99996C2.89747 37.9999 2 37.1025 2 36V14C2 12.8975 2.89747 12 3.99996 12H43.9999C45.1024 12 45.9999 12.8975 45.9999 14V36Z"
                  fill="#FAFAFA"
                />
                <path
                  d="M36.0001 21.9998C36.5528 21.9998 37.0001 21.5525 37.0001 20.9998C37.0001 20.447 36.5528 19.9998 36.0001 19.9998H32C31.4473 19.9998 31 20.447 31 20.9998V28.9998C31 29.5526 31.4473 29.9999 32 29.9999H36.0001C36.5528 29.9999 37.0001 29.5526 37.0001 28.9998C37.0001 28.4471 36.5528 27.9998 36.0001 27.9998H33.0001V25.9998H35C35.5528 25.9998 36.0001 25.5526 36.0001 24.9998C36.0001 24.4471 35.5528 23.9998 35 23.9998H33.0001V21.9998H36.0001Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M20 20C18.3457 20 17 21.3457 17 23V27C17 28.6543 18.3457 30 20 30C21.6543 30 23 28.6543 23 27V23C23 21.3457 21.6543 20 20 20ZM21 27C21 27.5517 20.5518 28 20 28C19.4483 28 19 27.5518 19 27V23C19 22.4483 19.4482 22 20 22C20.5517 22 21 22.4482 21 23V27Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M6.9999 22.0007C7.55162 22.0007 7.99993 22.4489 7.99993 23.0007C7.99993 23.5535 8.44721 24.0008 8.99996 24.0008C9.55271 24.0008 9.99999 23.5535 9.99999 23.0007C9.99999 21.3464 8.6543 20.0007 6.99999 20.0007C5.34568 20.0007 4 21.3464 4 23.0007V27.0007C4 28.6551 5.34568 30.0007 6.99999 30.0007C8.6543 30.0007 9.99999 28.6551 9.99999 27.0007C9.99999 26.448 9.55271 26.0007 8.99996 26.0007C8.44721 26.0007 7.99993 26.448 7.99993 27.0007C7.99993 27.5525 7.55171 28.0008 6.9999 28.0008C6.44818 28.0008 5.99987 27.5526 5.99987 27.0007V23.0007C5.99987 22.449 6.44809 22.0007 6.9999 22.0007Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M27 21.9998H29C29.5527 21.9998 30 21.5525 30 20.9998C30 20.447 29.5527 19.9998 29 19.9998H27C25.3457 19.9998 24 21.3454 24 22.9998C24 24.6541 25.3457 25.9997 27 25.9997C27.5517 25.9997 28 26.448 28 26.9998C28 27.5515 27.5518 27.9998 27 27.9998H25C24.4473 27.9998 24 28.4471 24 28.9998C24 29.5526 24.4473 29.9999 25 29.9999H27C28.6543 29.9999 30 28.6542 30 26.9999C30 25.3456 28.6543 23.9999 27 23.9999C26.4483 23.9999 26 23.5517 26 22.9998C26 22.4481 26.4483 21.9998 27 21.9998Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M41 20H39C38.4473 20 38 20.4473 38 21V29C38 29.5527 38.4473 30 39 30H41C42.6543 30 44 28.6543 44 27V23C44 21.3457 42.6543 20 41 20ZM42 27C42 27.5517 41.5518 28 41 28H40V22.0001H41C41.5517 22.0001 42 22.4483 42 23.0001V27Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M16.0001 27.9998H13.0001V20.9998C13.0001 20.447 12.5528 19.9998 12 19.9998C11.4473 19.9998 11 20.447 11 20.9998V28.9998C11 29.5526 11.4473 29.9999 12 29.9999H16.0001C16.5528 29.9999 17.0001 29.5526 17.0001 28.9998C17 28.4471 16.5528 27.9998 16.0001 27.9998Z"
                  fill="#DCDCDC"
                />
              </svg>
            </div>
            <p
              style={{
                margin: "0px",
                marginTop: "10px",
                color: "#939494",
                fontFamily: "Open Sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "16px",
                textAlign: "center",
              }}
            >
              Centre Closed
            </p>
            <p
              style={{
                margin: "0px",
                marginTop: "5px",
                color: "#939494",
                fontFamily: "Open Sans",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "14px",
                textAlign: "center",
              }}
            >
              {props.event.description}
            </p>
          </div>
        </div>
      );
    }
  };

  cToolBar = (props) => {
    const { view, views, onNavigate, label } = props;
    const { centerclasses = [] } = this.props;
    const { scfas, Classes } = this.state;

    let centers = [],
      classes = [],
      goldObj = {};
    if (localStorage.getItem("adminType") === "centre") {
      let d = centerclasses.find(
        (result) => result.id === localStorage.getItem("centre_Id")
      );
      if (d) {
        centers.push({
          key: d.id,
          text: d.name,
          value: d.id,
        });
      }

      // centers.push({
      //   key: userDetail.centre[0].id,
      //   text: userDetail.centre[0].name,
      //   value: userDetail.centre[0].id,
      // });
    } else {
      centerclasses.map((data) => {
        centers.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
    }
    // centerclasses.map((data) => {
    //   centers.push({
    //     key: data.id,
    //     text: data.name,
    //     value: data.id,
    //   });
    // });

    let from = moment(props.date).startOf("week");
    let to = moment(props.date).endOf("week");
    return (
      <Grid style={{ margin: "10px 0" }} className="WeekCalendar">
        <Grid.Column
          style={{
            marginRight: "0%",
            // marginLeft: "4%"
            marginLeft: window.innerWidth < 1230 && window.innerWidth>1025 ? "5px" : "4%",
          }}
          mobile={7} computer={window.innerWidth<1400? 2 : 3} tablet={3}
          // className="Toolbar_width"
        >
          <label style={{ lineHeight: "40px", fontWeight: 800 }}>
            {moment(this.state.from_date).format("MMMM YYYY")}
          </label>
        </Grid.Column>
        <Grid.Column
          className="calendar_filter"
          mobile={8} computer={4} tablet={4}
          style={{
            marginRight: window.innerWidth > 1400 && "2%",
          }}
        >
          <ul className="holidayCToolBar">
            <li>
              <Image
                src={arrow_left}
                width={12}
                onClick={() => {
                  onNavigate("PREV");
                  this.getStartDate(from, to);
                }}
                style={{ marginTop: 12 }}
              />
            </li>
            <li style={{ fontWeight: "bold" }}>
              {label.substr(label.indexOf(" ") + 1)}
            </li>
            <li>
              <Image
                src={arrow_right}
                width={12}
                onClick={() => {
                  onNavigate("NEXT");
                  this.getEndDate(from, to);
                }}
                style={{ marginTop: 12 }}
              />
            </li>
          </ul>
        </Grid.Column>
        <Grid.Column 
          mobile={16} tablet={8} computer={3}
          style={{
            minWidth: window.innerWidth < 1400 && "160px",
          }}
          // width={window.innerWidth < 850 ? 4 : 3}
        >
          <Dropdown
            name="centre_id"
            onChange={this.handleFilter}
            placeholder="Centers"
            selectOnBlur={false}
            options={centers.sort((a, b) =>
              a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
            )}
            value={this.state.centre_id}
            selection
            fluid
          />
        </Grid.Column>
        <Grid.Column
          mobile={16} tablet={8} computer={2}
          style={{
            minWidth: window.innerWidth < 1400 && "110px",
          }}
          // width={window.innerWidth < 850 ? 4 : window.innerWidth < 1000 ? 3 : 2}
        >
          <Dropdown
            name="class_id"
            id="input0"
            onChange={this.handleFilter}
            placeholder={
              window.innerWidth < 1200 && window.innerWidth > 1100
                ? "Class..."
                : "Classes"
            }
            options={Classes.sort((a, b) =>
              a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
            )}
            value={this.state.class_id}
            selection
            fluid
          />
        </Grid.Column>
        <Grid.Column
          mobile={16} tablet={8} computer={3}
        >
          <Dropdown
            name="scfa"
            onChange={this.handleFilter}
            placeholder="SCFA: All"
            options={scfas}
            value={this.state.scfa}
            selection
            fluid
          />
        </Grid.Column>
      </Grid>
    );
  };

  getcentreId = (value) => {
    console.log(value);
    this.setState({
      centre_id: value,
    });
  };

  checkActivityImage = (title) => {
    if (
      title.toLowerCase().includes("check-in") ||
      title.toLowerCase().includes("check in")
    ) {
      return checkIn;
    } else if (title.toLowerCase().includes("game")) {
      return morningGame;
    } else if (title.toLowerCase().includes("breakfast")) {
      return breakfast;
    } else if (
      title.toLowerCase().includes("read") ||
      title.toLowerCase().includes("reading")
    ) {
      return reading;
    } else if (title.toLowerCase().includes("homework")) {
      return homework;
    } else if (title.toLowerCase().includes("lunch")) {
      return lunch;
    } else if (
      title.toLowerCase().includes("nap") ||
      title.toLowerCase().includes("sleep")
    ) {
      return nap;
    } else if (
      title.toLowerCase().includes("check-out") ||
      title.toLowerCase().includes("check out")
    ) {
      return checkOut;
    } else {
      return false;
    }
  };
  checkActivityColor = (title) => {
    if (
      title.toLowerCase().includes("check-in") ||
      title.toLowerCase().includes("check in")
    ) {
      return "#2190dc";
    } else if (title.toLowerCase().includes("game")) {
      return "#939494";
    } else if (title.toLowerCase().includes("breakfast")) {
      return "#c697df";
    } else if (title.toLowerCase().includes("read")) {
      return "#f66460";
    } else if (title.toLowerCase().includes("homework")) {
      return "#065ca4";
    } else if (title.toLowerCase().includes("lunch")) {
      return "#11aee3";
    } else if (
      title.toLowerCase().includes("nap") ||
      title.toLowerCase().includes("sleep")
    ) {
      return "#41cec3";
    } else if (
      title.toLowerCase().includes("check-out") ||
      title.toLowerCase().includes("check out")
    ) {
      return "#f3b329";
    } else {
      return "#E9EEF1";
    }
  };

  render() {
    const { classActivities, isholidayShow } = this.state;
    const {
      events = [],
      haveData,
      isloading,
      centerclasses = [],
      schedules = [],
      schedule = {},
      scheduleItem = {},
      loading,
      haveScheduleDate,
      isCenterLoading,
      schedulescalendarview = [],
      scheduletypes = [],
      scheduleItems,
      schedulescalendarViewLoading,
      smallschedulescalendarViews = [],
    } = this.props;
    // console.log(this.state.from);
    // console.log(schedulescalendarview);
    const today = new Date();
    let centreClosed = [];
    if (smallschedulescalendarViews.length !== 0) {
      if (smallschedulescalendarViews[0].schedules !== undefined) {
        let find = smallschedulescalendarViews[0].schedules.find(
          (data) => data.schedule_type_id === "7BFBdPuRsBkFoxlDnqaP"
        );
        if (find) {
          centreClosed.push(find);
        }
      }
    }
    // console.log(centreClosed);
    let schedulesforCalendar = [],
      modifySgHoliday = [],
      breakfast = [],
      lunch = [];
    /**
     * To show schedules in Calendar UI
     *  use schedulescalendarview array after api fixed,
     */

    smallschedulescalendarViews.map((s) => {
      s.schedules.map((m) => {
        let from_time = moment(m.from_time).format("HH");
        let to_time = moment(m.to_time).format("HH");
        if (from_time <= 12) {
          m.schedule_item_ids.map((i) => {
            breakfast.push(i);
          });
        } else {
          m.schedule_item_ids.map((i) => {
            lunch.push(i);
          });
        }
      });
    });

    if (schedulescalendarview.length === 0) {
      schedulesforCalendar = [];
      let date = this.state.from.split("-");
      schedulesforCalendar.push({
        id: 1,
        title: "Centre_Closed",
        start: new Date(date[0], date[1] - 1, date[2], "7", "00", "00"),
        end: new Date(date[0], date[1] - 1, date[2], "23", "0", "00"),
      });
    }

    if (schedulescalendarview[0] !== undefined) {
      if (schedulescalendarview[0].schedules.length === 0) {
        schedulesforCalendar = [];
        let date = schedulescalendarview[0].date.split("-");
        schedulesforCalendar.push({
          id: 1,
          title: "Centre_Closed",
          start: new Date(date[0], date[1] - 1, date[2], "7", "00", "00"),
          end: new Date(date[0], date[1] - 1, date[2], "23", "0", "00"),
        });
      }
      schedulescalendarview.map((sch) => {
        let createDate = sch.date.split("-");
        sch.schedules.map((data) => {
          // let fromTime = moment(data.from_time).format("hh:mm:ss");
          // let toTime = moment(data.to_time).format("hh:mm:ss");
          let fromTime = data.from_time.split(" ");
          let toTime = data.to_time.split(" ");
          let startTime =
            fromTime[3] !== undefined
              ? fromTime[3].split(":")
              : data.from_time.split(":");
          let endTime =
            toTime[3] !== undefined
              ? toTime[3].split(":")
              : data.to_time.split(":");
          schedulesforCalendar.push({
            id: data.id,
            title: data.title === undefined ? "" : data.title,
            description: data.description === undefined ? "" : data.description,
            start: new Date(
              createDate[0],
              createDate[1] - 1,
              createDate[2],
              startTime[0],
              startTime[1],
              startTime[2]
            ),
            end: new Date(
              createDate[0],
              createDate[1] - 1,
              createDate[2],
              endTime[0],
              endTime[1],
              endTime[2]
            ),
          });
        });
        // if (sch.title && sch.end && sch.start)
        //   this.setState({
        //     events: [
        //       ...schedulesforCalendar,
        //       {
        //         start: sch.start,
        //         end: sch.end,
        //         title: sch.title,
        //       },
        //     ],
        //   });
      });
    }

    let type = [];
    const typeSort = scheduletypes.sort((a, b) => {
      return b.name.localeCompare(a.name);
    });
    typeSort.map((data) => {
      type.push({
        key: data.id,
        label: data.name,
        value: data.id,
      });
    });

    // schedules.map((schedule) => {
    //   let fromTime = moment(schedule.from_time).format("hh:mm:ss");
    //   let toTime = moment(schedule.to_time).format("hh:mm:ss");

    //   let startTime = fromTime.split(":");
    //   let endTime = toTime.split(":");

    //   let createdTime = moment(schedule.created_ts).format("YYYY-MM-DD");
    //   let createdtime = createdTime.split("-");

    //   schedulesforCalendar.push({
    //     id: schedule.schedule_id,
    //     title: schedule.title,
    //     start: new Date(
    //       createdtime[0],
    //       createdtime[1],
    //       createdtime[2],
    //       startTime[0],
    //       startTime[1],
    //       startTime[2]
    //     ),
    //     end: new Date(
    //       createdtime[0],
    //       createdtime[1],
    //       createdtime[2],
    //       endTime[0],
    //       endTime[1],
    //       endTime[2]
    //     ),
    //   });
    //   if (schedule.title && schedule.end && schedule.start)
    //     this.setState({
    //       events: [
    //         ...schedulesforCalendar,
    //         {
    //           start: schedule.start,
    //           end: schedule.end,
    //           title: schedule.title,
    //         },
    //       ],
    //     });
    // });

    let centers = [];
    centerclasses.map((data) => {
      centers.push({
        key: data.id,
        label: data.name,
        value: data.id,
      });
    });

    // let sgHolidays = hd.getHolidays(2021);
    // sgHolidays.map((sgholiday) => {
    //   modifySgHoliday.push({
    //     end: sgholiday.end,
    //     type: sgholiday.type,
    //     name: sgholiday.name,
    //     rule: sgholiday.rule,
    //     start: sgholiday.end,
    //   });
    // });

    // for Holiday Events
    this.props.holidays !== undefined &&
      this.props.holidays.map((holiday) => {
        modifySgHoliday.push({
          end: new Date(holiday.date),
          type: holiday.name,
          name: holiday.name,
          rule: holiday.name,
          start: new Date(holiday.date),
        });
      });

    let goldObj = {},
      classes = [];
    goldObj = centerclasses.find(
      (result) => result.id === this.state.centre_id || centers[0].value
    );
    goldObj !== undefined &&
      goldObj.class.map((data) => {
        classes.push({
          key: data.id,
          label: data.name,
          value: data.name,
        });
      });
    return (
      <Grid className="fullHeight calendar">
        <Dimmer active={loading} inverted>
          <Loader inverted size={"big"} />
        </Dimmer>
        <Grid.Row>
          <Modal
            dimmer={"blurring"}
            open={this.state.closureExistModal}
            style={{ width: "25%" }}
            onClose={() => this.setState({ closureExistModal: false })}
          >
            <Modal.Header>Duplicate Centre CLosure!</Modal.Header>
            <Modal.Content>
              Choose another date. This date is already exist!
            </Modal.Content>
            <Modal.Actions
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                positive
                style={{
                  width: "120px",
                  borderRadius: "20px",
                  backgroundColor: "#4075ff",
                  marginLeft: "0px",
                }}
                onClick={() =>
                  this.setState({ closureExistModal: false, Loading: false })
                }
              >
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            dimmer={"blurring"}
            open={this.state.duplicateModal}
            style={{ width: "25%" }}
            onClose={() =>
              this.setState({ duplicateModal: false, Loading: false })
            }
          >
            <Modal.Header>Duplicate schedule!</Modal.Header>
            <Modal.Content>
              There is an existing schedule, you have to delete it before could
              proceed to edit this schedule.
            </Modal.Content>
            <Modal.Actions
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                positive
                style={{
                  width: "120px",
                  borderRadius: "20px",
                  backgroundColor: "#4075ff",
                  marginLeft: "0px",
                }}
                onClick={() =>
                  this.setState({ duplicateModal: false, Loading: false })
                }
              >
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            dimmer={"blurring"}
            open={this.state.closureConfirm}
            style={{ width: "25%", height: 430 }}
            onClose={() => this.setState({ closureConfirm: false })}
          >
            <img
              src={Exclamation}
              style={{
                width: "20%",
                textAlign: "center",
                position: "relative",
                left: "40%",
                top: "50px",
              }}
            ></img>
            <h1
              style={{
                textAlign: "center",
                marginTop: "85px",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "24px",
                lineHeight: "33px",
              }}
            >
              Are you sure?
            </h1>
            <p
              style={{
                textAlign: "center",
                width: "65%",
                position: "relative",
                left: "19%",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "19px",
              }}
            >
              There is existing schedules available for the day, please kindly
              help to confirm your action. You can't undo this action.
            </p>
            <Button
              positive
              style={{
                position: "relative",
                left: "12%",
                width: "37%",
                top: "50px",
                borderRadius: "20px",
                height: "48px",
                backgroundColor: "#FFF",
                border: "1px solid #4075FF",
                color: "#4075FF",
              }}
              onClick={() => this.setState({ closureConfirm: false })}
            >
              Cancel
            </Button>
            <Button
              positive
              style={{
                position: "relative",
                left: "13%",
                width: "37%",
                top: "50px",
                borderRadius: "20px",
                height: "48px",
                backgroundColor: "#4075FF",
              }}
              onClick={this.centreClosuerConfirm}
            >
              Yes
            </Button>
          </Modal>
          {/* <Modal
            dimmer={"blurring"}
            open={this.state.modal}
            style={{ width: "30%" }}
            onClose={() => this.setState({ modal: false, delete_id: "" })}
          >
            <Modal.Header>Delete!</Modal.Header>
            <Modal.Content>Do you want to delete this schedule?</Modal.Content>
            <Modal.Actions>
              <Button
                positive
                onClick={() => this.setState({ modal: false, delete_id: "" })}
              >
                Cancel
              </Button>
              <Button
                negative
                onClick={() => this.deleteScheduleData(this.state.delete_id)}
              >
                Ok
              </Button>
            </Modal.Actions>
          </Modal> */}
          {/* {console.log(schedulesforCalendar)} */}
          <Grid.Column width={11}>
            <Grid>
              <Grid.Column width={4}>
                <Header as="h3">Calendar</Header>
              </Grid.Column>
              <Grid.Column floated="right" width={8} xs={12}>
                <div style={styles.btnContainer}>
                  <RenderButton
                    className="primaryBtn"
                    fluid={true}
                    btnText="Add Schedule"
                    showIcon={true}
                    onClick={() =>
                      this.props.dispatch(push(SCHEDULE_CREATE_ROUTE))
                    }
                  />
                </div>
              </Grid.Column>
            </Grid>

            <Grid className="calendarContainer">
              <Grid.Row>
                <Grid.Column>
                  <Calendar
                    localizer={localizer}
                    events={schedulesforCalendar}
                    views={["week"]}
                    defaultView={Views.WEEK}
                    defaultDate={new Date()}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    onSelectEvent={(event) => this.handleEditEvent(event)}
                    components={{
                      toolbar: this.cToolBar,
                      header: this.header,
                      event: this.customEvent,
                    }}
                    timeslots={1}
                    step={30}
                    min={
                      new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate(),
                        7,
                        0,
                        0
                      )
                    }
                    max={
                      new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate(),
                        23,
                        0,
                        0
                      )
                    }
                  />
                </Grid.Column>
              </Grid.Row>

              <TransitionablePortal
                open={this.state.isEditModalOpen}
                transition={{ animation: "scale", duration: 400 }}
                style={{ padding: "20px 40px" }}
              >
                <Modal
                  open={this.state.isEditModalOpen}
                  size="small"
                  className="editScheduleModal"
                  onClose={() => this.toggleEditModal()}
                >
                  <div align="right" className="closeBtn">
                    <Image
                      src={Xclose}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.toggleEditModal()}
                    />
                  </div>
                  <br />

                  {haveScheduleDate ? (
                    <ScheduleForm
                      centers={centers}
                      months={months}
                      days={days}
                      dateTypes={dateTypes}
                      type={type}
                      centerclasses={centerclasses}
                      formTitle={"Edit Schedule"}
                      getcentreId={this.getcentreId}
                      isEdit={true}
                      initialValues={schedule}
                      loading={loading}
                      Loading={this.state.Loading}
                      classes={classes}
                      scheduleItems={scheduleItems}
                      handleSubmit={this.handleSubmit}
                      handleDelete={this.handleDelete}
                    />
                  ) : (
                    <Message>
                      <Icon name="circle notched" loading />
                      <Message.Content>
                        <Message.Header>Just one second</Message.Header>
                        We are fetching that data for you.
                      </Message.Content>
                    </Message>
                  )}
                </Modal>
              </TransitionablePortal>
            </Grid>
          </Grid.Column>

          <Grid.Column
            width={5}
            style={{ marginTop: -44, backgroundColor: "red !important" }}
          >
            <div
              style={{
                width: "auto",
                height: 350,
                marginLeft: -40,
                marginRight: -26,
                background: "#ffff",
              }}
            >
              <Calendar
                selectable
                localizer={localizer}
                events={isholidayShow ? modifySgHoliday : this.state.holidays}
                views={["month"]}
                defaultView={Views.MONTH}
                // defaultDate={this.state.selectedDate}
                onNavigate={(date) => {
                  // console.log("date", new Date(date).getDay());
                  this.setState({ selectedDate: date });
                  let formatedDate = moment(date).format("YYYY-MM-DD");
                  if (new Date(date).getDay() === 0) {
                    this.setState({
                      closedToday: true,
                    });
                  } else {
                    this.setState({
                      closedToday: false,
                    });
                  }

                  this.props.getSmallSchedulesCalendarView(
                    formatedDate,
                    formatedDate,
                    this.state.centre_id,
                    this.state.class_id,
                    this.state.scfa
                  );
                }}
                date={this.state.selectedDate}
                scrollToTime={new Date(1970, 1, 1, 6)}
                components={{ toolbar: holidayCToolBar }}
                // onSelectSlot={(e) => this.setState({ selectedDate: e.start })}
                className={
                  this.state.selectedDate
                    ? "calendar_small_active"
                    : "calendar_small"
                }
              />
              <Segment className="holidayCtrl">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ float: "lef" }}>
                    Include Singapore Public Holiday
                  </span>
                  <div style={{ alignSelf: "center" }}>
                    <RadioToggle
                      onClick={this.toggleHoliday}
                      isChk={this.state.isholidayShow}
                    />
                  </div>
                </div>
              </Segment>
            </div>

            <Header
              as="h5"
              style={{
                opacity: 0,
              }}
            >
              Class Activities
            </Header>
            {/* // here */}
            {this.state.closedToday ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 400,
                    flexDirection: "column",
                  }}
                >
                  <Image src={ClosedToday} style={{ width: 100, height: 80 }} />
                  <div
                    style={{
                      color: "#DCDCDC",
                      fontSize: 14,
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    Closed Today
                  </div>
                </div>
              </>
            ) : centreClosed.length !== 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 400,
                    flexDirection: "column",
                  }}
                >
                  <Image src={ClosedToday} style={{ width: 100, height: 80 }} />
                  <div
                    style={{
                      color: "#DCDCDC",
                      fontSize: 14,
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    Closed Today
                  </div>
                  <div
                    style={{
                      margin: "0px",
                      marginTop: "5px",
                      color: "#939494",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      lineHeight: "19px",
                      textAlign: "center",
                    }}
                  >
                    {centreClosed[0].description}
                  </div>
                </div>
              </>
            ) : smallschedulescalendarViews.length > 0 ? (
              <>
                <div
                  style={{
                    backgroundColor: "#F7FAFF",
                    width: "100%",
                    // height: "87.3%",
                    height: "auto",
                    position: "absolute",
                    left: 0,
                    zIndex: 1,
                  }}
                ></div>
                {smallschedulescalendarViews[0].schedules.length > 0 ? (
                  <Header
                    as="h5"
                    style={{
                      fontSize: 20,

                      borderTop: "1px solid #ddd",
                      paddingTop: 26,
                      zIndex: 2,
                      position: "relative",
                    }}
                  >
                    Class Activities
                  </Header>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 250,
                        flexDirection: "column",
                        position: "relative",
                        zIndex: 2,
                      }}
                    >
                      <Image
                        src={No_Schudule}
                        style={{ width: 100, height: 80 }}
                      />
                      <div
                        style={{
                          color: "#DCDCDC",
                          fontSize: 14,
                          fontWeight: "bold",
                          marginTop: 10,
                          zIndex: 2,
                          position: "relative",
                        }}
                      >
                        No Schedule
                      </div>
                    </div>
                  </>
                )}
                {/* {console.log("small", smallschedulescalendarViews)} */}
                {smallschedulescalendarViews.map((classActivity, ix) => {
                  return (
                    <div
                      style={{
                        zIndex: 2,
                        position: "relative",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      {classActivity.schedules.map((e) => {
                        return (
                          <>
                            {e.schedule_type_id == "knuZrNEdOqQ2M6bF8RUv" && (
                              <List
                                divided
                                relaxed
                                key={e.id}
                                style={{
                                  background: "white",
                                  border: "none",
                                  boxShadow: "0 0 2px #9DA5F1",
                                  borderRadius: 20,
                                }}
                              >
                                <List.Item style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      backgroundColor: e.icon_color,
                                      // padding: 10,
                                      borderRadius: 8,
                                      width: 48,
                                      height: 48,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={e.icon_img}
                                      style={{
                                        borderRadius: 8,
                                        width: 48,
                                        height: 48,
                                        filter: "opacity(1) brightness(200%)",
                                        textAlign: "center",
                                      }}
                                    />
                                  </div>

                                  <div className="wfull flex flex-align-center flex-justify-space-between pl10 pr10">
                                    <span className="fw600">{e.title}</span>
                                    <span className="text-gray fw600 fs12">
                                      {moment(e.from_time).format("LT")}
                                    </span>
                                  </div>
                                </List.Item>
                              </List>
                            )}
                          </>
                        );
                      })}
                    </div>
                  );
                })}
                {breakfast.length > 0 || lunch.length > 0 ? (
                  <h5
                    style={{
                      fontWeight: 700,
                      fontSize: 20,
                      marginLeft: -25,
                      zIndex: 2,
                      position: "relative",
                    }}
                  >
                    Food & nutrition
                  </h5>
                ) : null}

                {breakfast.length > 0 || lunch.length > 0 ? (
                  <div>
                    <div
                      style={{
                        padding: "20px",
                        // border:
                        //   breakfast.length > 0
                        //     ? "1px solid #DADADA"
                        //     : lunch.length > 0
                        //     ? "1px solid #DADADA"
                        //     : null,
                        background:
                          breakfast.length > 0
                            ? "#fff"
                            : lunch.length > 0
                            ? "#fff"
                            : null,
                        width: "120%",
                        borderRadius: 8,
                        marginLeft: -25,
                        zIndex: 2,
                        position: "relative",
                        border: "none",
                        boxShadow: "0 0 2px #9DA5F1",
                        borderRadius: 20,
                      }}
                    >
                      {/*  BreakFast */}
                      {breakfast.length > 0 && (
                        <div
                          style={{
                            borderBottom:
                              lunch.length > 0 ? "2px solid #F6F6F6" : null,
                            paddingBottom: 20,
                            marginBottom: 20,
                          }}
                        >
                          <div style={{ fontWeight: "bolder", fontSize: 14 }}>
                            Breakfast
                          </div>

                          {/* Card */}
                          {breakfast.map((e) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  marginTop: 10,
                                }}
                              >
                                <div>
                                  <img
                                    src={e.img_url}
                                    className="nutrition_img"
                                  />
                                </div>
                                <div>{e.name}</div>
                              </div>
                            );
                          })}

                          {/* End Card */}
                        </div>
                      )}
                      {/* Lunch */}
                      {lunch.length > 0 && (
                        <div
                          style={{
                            paddingBottom: 20,
                            marginBottom: 20,
                          }}
                        >
                          <h5 style={{ fontWeight: "bolder", fontSize: 14 }}>
                            Lunch
                          </h5>

                          {/* Card */}
                          {lunch.map((e) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  marginTop: 10,
                                }}
                              >
                                <div>
                                  <img
                                    src={e.img_url}
                                    className="nutrition_img"
                                  />
                                </div>
                                <div style={{ color: "#1B1A1A", fontSize: 14 }}>
                                  {e.name}
                                </div>
                              </div>
                            );
                          })}

                          {/* End Card */}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {/* // return (
                //   <List divided relaxed key={ix}>
                //     <List.Item>
                //       {
                //         this.checkActivityImage(classActivity.title) ? (
                //           <Image
                //           src={this.checkActivityImage(classActivity.title)}
                //           style={{
                //             backgroundColor: this.checkActivityColor(
                //               classActivity.title
                //             ),
                //             padding: 10,
                //             borderRadius: 8,
                //           }}
                //         />
                //         ) : (
                //           <Image
                //           src={placeHolder}

                //        style={{

                //           backgroundColor: this.checkActivityColor(
                //           classActivity.title
                //           ),
                //           padding: 10,
                //       borderRadius: 8, }}
                //         />
                //         )
                //       }

                //       <List.Content>
                //         <List.Header as="h4">{classActivity.title}</List.Header>
                //         <List.Description as="a">
                //           {moment(classActivity.start).format("LT")}
                //         </List.Description>
                //       </List.Content>
                //     </List.Item>
                //   </List>
                // ); */}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 250,
                    flexDirection: "column",
                  }}
                >
                  <Image src={No_Schudule} style={{ width: 100, height: 80 }} />
                  <div
                    style={{
                      color: "#DCDCDC",
                      fontSize: 14,
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    No Schedule
                  </div>
                </div>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const styles = {
  btnContainer: {
    width: 236,
    marginLeft: "auto",
  },
};

const mapStateToProps = (state) => ({
  events: state.events.events !== undefined ? state.events.events.data : [],
  isloading: state.events.loading,
  haveData: state.events.haveData,
  loading: state.schedules.loading,
  haveError: state.events.err,
  centerclasses: state.centersClasses.centers_classes.data,
  isCenterLoading: state.centersClasses.loading,
  schedules: state.schedules.schedules.data,
  schedule: state.schedule.schedule.data,
  haveScheduleDate: state.schedule.haveData,
  scheduletypes: state.scheduleTypes.scheduleTypes.data,
  schedulescalendarview:
    state.schedulescalendarView.schedulescalendarViews.data,
  schedulescalendarViewLoading: state.schedulescalendarView.loading,
  scheduleItems: state.scheduleItems.scheduleItems.data,
  smallschedulescalendarViews:
    state.schedulescalendarView.smallschedulescalendarViews,
  holidays: state.holidays.holidays.data,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getHolidays: () => dispatch(getHolidays()),
  getEvents: () => dispatch(getEvents()),
  getEventDetail: (id) => dispatch(getEventDetail(id)),
  getCentersClasses: () => dispatch(getCentersClasses()),
  getSchedules: () => dispatch(getSchedules()),
  getScheduleTypes: () => dispatch(getScheduleTypes()),
  getSchedule: (id) => dispatch(getSchedule(id)),
  getSchedulesCalendarView: (from_date, to_date, centre_id, class_id, scfa) =>
    dispatch(
      getSchedulesCalendarView(from_date, to_date, centre_id, class_id, scfa)
    ),
  getSmallSchedulesCalendarView: (
    from_date,
    to_date,
    centre_id,
    class_id,
    scfa
  ) =>
    dispatch(
      getSmallSchedulesCalendarView(
        from_date,
        to_date,
        centre_id,
        class_id,
        scfa
      )
    ),

  getScheduleItems: () => dispatch(getScheduleItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarHome);
