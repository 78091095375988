import React from "react";
const Card = (props) => {
  return (
    <div
      className="card"
      style={{ minWidth: window.innerWidth / 7, margin: "5px 5px" }}
    >
      <div className="content">
        <div className="card-title">{props.title}</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginRight: 10 }}>
            <img className="card-image" src={props.image} />
          </div>
          <div>
            {/* {props.number} */}
            <p className="card-number">{props.number} </p>
            <p className="card-text" as="h4">
              {props.text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
