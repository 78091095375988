export const adminType = [
  {
    id: Math.random().toString().substr(2),
    name: "centre",
    value: "Centre Admin",
  },
  {
    id: Math.random().toString().substr(2),
    name: "hq",
    value: "HQ Admin",
  },
];

export const usersType = [
  {
    id: Math.random().toString().substr(2),
    name: "admin",
    value: "Admin",
  },
  {
    id: Math.random().toString().substr(2),
    name: "facilitator",
    value: "Facilitator",
  },
  {
    id: Math.random().toString().substr(2),
    name: "parent",
    value: "Parent",
  },
  {
    id: Math.random().toString().substr(2),
    name: "student",
    value: "Student",
  },
];
export const usersTypeNoti = [
  {
    id: Math.random().toString().substr(2),
    name: "admin",
    value: "Admin",
  },
  {
    id: Math.random().toString().substr(2),
    name: "facilitator",
    value: "Facilitator",
  },
  {
    id: Math.random().toString().substr(2),
    name: "parent",
    value: "Parent",
  },
];

export const months = [
  { value: "Jan", label: "Jan" },
  { value: "Feb", label: "Feb" },
  { value: "Mar", label: "Mar" },
  { value: "Apr", label: "Apr" },
  { value: "May", label: "May" },
  { value: "Jun", label: "June" },
  { value: "Jul", label: "July" },
  { value: "Aug", label: "Aug" },
  { value: "Sep", label: "Sep" },
  { value: "Oct", label: "Oct" },
  { value: "Nov", label: "Nov" },
  { value: "Dec", label: "Dec" },
];

export const days = [
  { value: "Sun", label: "Sun" },
  { value: "Mon", label: "Mon" },
  { value: "Tue", label: "Tue" },
  { value: "Wed", label: "Wed" },
  { value: "Thu", label: "Thu" },
  { value: "Fri", label: "Fri" },
  { value: "Sat", label: "Sat" },
];

export const dateTypes = [
  {
    value: "routine",
    label: "Routine",
  },
  {
    value: "specific_time",
    label: "Specified Time",
  },
];

export const types = [
  { value: "subject", label: "Subject" },
  { value: "meal", label: "Meal" },
  //{ value: "newsFeed", label: "NewsFeed" },
  { value: "centre_closed", label: "Center Closed" },
];
