import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_FACILITATOR = "FETCH_FACILITATOR";
export const FETCH_FACILITATOR_SUCCESS = "FETCH_FACILITATOR_SUCCESS";
export const FETCH_FACILITATOR_FAILURE = "FETCH_FACILITATOR_FAILURE";
export const CLEAR_FACILITATOR = "CLEAR_FACILITATOR";

export const fetchFacilitator = (id) => ({
  type: FETCH_FACILITATOR,
  id,
});

export const fetchFacilitatorSuccess = (data) => ({
  type: FETCH_FACILITATOR_SUCCESS,
  data,
});

export const fetchFacilitatorFailure = () => ({
  type: FETCH_FACILITATOR_FAILURE,
});

export const clearFacilitator = () => ({
  type: CLEAR_FACILITATOR,
});

export const getFacilitatorDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchFacilitator());
    return firebase.reloadAuth().then((res) => {
      try {
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/user/facilitator/", { id: id }, token)
          .then((res) => {
            dispatch(fetchFacilitatorSuccess(res));
            return res;
          })
          .catch((err) => dispatch(fetchFacilitatorFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
