import React, { Component } from "react";
import { Radio, Grid, Message, Image } from "semantic-ui-react";
import { Field } from "formik";

const boxStyle = {
  backgroundColor: "#E6F1FC",
  border: "1px solid #4075FF",
  borderRadius: 8,
  boxSizing: "border-box",
};
class MultipleRadioGroup extends Component {
  constructor(props) {
    super(props);
    let classes = {
      P1: false,
      P2: false,
      P3: false,
      P4: false,
      P5: false,
      P6: false,
    };
    props.value.forEach((item) => {
      switch (item) {
        case "P1":
          classes.P1 = true;
          break;
        case "P2":
          classes.P2 = true;
          break;
        case "P3":
          classes.P3 = true;
          break;
        case "P4":
          classes.P4 = true;
          break;
        case "P5":
          classes.P5 = true;
          break;
        case "P6":
          classes.P6 = true;
          break;
      }
    });

    this.state = {
      P1: classes.P1,
      P2: classes.P2,
      P3: classes.P3,
      P4: classes.P4,
      P5: classes.P5,
      P6: classes.P6,
    };
    props.value.forEach((item) => {
      switch (item) {
        case "P1":
          classes.P1 = true;
          break;
        case "P2":
          classes.P2 = true;
          break;
        case "P3":
          classes.P3 = true;
          break;
        case "P4":
          classes.P4 = true;
          break;
        case "P5":
          classes.P5 = true;
          break;
        case "P6":
          classes.P6 = true;
          break;
      }
    });

    this.state = {
      P1: classes.P1,
      P2: classes.P2,
      P3: classes.P3,
      P4: classes.P4,
      P5: classes.P5,
      P6: classes.P6,
    };
  }

  _activeBlock(label) {
    this.setState({
      [label]: !this.state[label],
    });
  }

  render() {
    return (
      <div>
        <Grid divided="vertically">
          <Grid.Row columns={3}>
            {this.props.options.map((data, ix) => {
              let styleBox = {};
              if (this.state[data.label]) {
                styleBox = boxStyle;
              }
              return (
                <Grid.Column key={ix} className={"radio-toolbar"}>
                  <div
                    style={styleBox}
                    onClick={() => {
                      this._activeBlock(data.label);
                      // this.props._onChangeForMultiple(
                      //   data.key,
                      //   this.props.formikProps
                      // );
                      this.props._onChangeForMultiple(
                        data.label,
                        this.props.formikProps
                      );
                    }}
                  >
                    <span>{data.label}</span>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
        {this.props.isError && this.props.isTouched && (
          <Message visible error content={this.props.isError} />
        )}
      </div>
    );
  }
}
export default MultipleRadioGroup;
