import api from "../../config/api";
import { firebase } from "../../config/firebase";
export const FETCH_CLASSES = "FETCH_CLASSES";
export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSES_FAILURE = "FETCH_CLASSES_FAILURE";

export const fetchClasses = (centre_id) => ({
  type: FETCH_CLASSES,
  payload: {
    centre_id,
  },
});

export const fetchClassesSuccess = (data) => ({
  type: FETCH_CLASSES_SUCCESS,
  data,
});

export const fetchClassesFailure = (err) => ({
  type: FETCH_CLASSES_FAILURE,
  err,
});

export const getClasses = (centre_id) => {
  return (dispatch) => {
    dispatch(fetchClasses());
    return firebase.reloadAuth().then((res) => {
      try {
        if (centre_id === "") {
          centre_id = localStorage.getItem("centre_id");
        }
        const token = res.ya === null ? res.za : res.ya;

        api
          .get("/user/centre/", null, token)
          .then((res) => {
            //console.log('centerid',centre_id)
            let result = res.data.filter((element) => element.id === centre_id);
            dispatch(fetchClassesSuccess(result[0].class));
            return result[0].class;
          })
          .catch((err) => dispatch(fetchClassesFailure(err)));
      } catch (err) {
        console.log(err);
      }
    });
  };
};
